import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DatePicker, Dropdown, DropdownButton, DropdownMenu } from '@adeccoux/tag-ds';
import { Box } from '@mui/material';

interface TableFiltersProps {
  dateFilter: {
    startDate: any;
    setStartDate: (date: any) => void;
  };
  statusFilter: {
    status: string;
    setStatus: (value: string) => void;
  };
}

const TableFilters: React.FC<TableFiltersProps> = ({ dateFilter, statusFilter }) => {
  const { t } = useTranslation(['recruiter']);

  const tzOffset = new Date().getTimezoneOffset() * 60;

  const statusOptions = [
    { name: t('recruiter:GENERAL.CANDIDATES_OVERVIEW.all'), value: 'ALL' },
    { name: t('recruiter:GENERAL.IMPORT.COMPLETED'), value: 'COMPLETED' },
    { name: t('recruiter:GENERAL.IMPORT.IN_PROGRESS'), value: 'IN_PROGRESS' },
    { name: t('recruiter:GENERAL.IMPORT.FAILED'), value: 'FAILED' },
  ];

  return (
    <div className="tag-ds" style={{ marginBottom: 24 }}>
      <Box display="flex" alignItems="flex-end">
        <div style={{ width: 200, marginRight: 32 }}>
          <DatePicker
            name="datepicker"
            onChange={(date) => {
              const sDate = date ? moment(date).startOf('day').add(-tzOffset, 'seconds').toISOString() : '';

              dateFilter.setStartDate(sDate);
            }}
            label={t('GENERAL.IMPORT.headerStartDate')}
            maxDate={new Date().getTime()}
            dateFormat="dd/MM/yyyy"
            value={dateFilter.startDate ? moment(dateFilter.startDate).toDate().getTime() : null}
          />
        </div>
        <Dropdown className="feature-admin-import__dropdown">
          <DropdownButton>
            <div className="chip-icon_right feature-admin-import__filter-button">
              <span className="feature-admin-import__arrow-down">{t('recruiter:GENERAL.IMPORT.import-status')}</span>
              <span className="material-icons feature-admin-import__arrow-down">expand_more</span>
            </div>
          </DropdownButton>
          <DropdownMenu className="feature-admin-import__filter-list">
            {statusOptions.map((x) => {
              return (
                <div className="checkbox-container dropdown-item" key={x.name}>
                  <input
                    checked={x.value === statusFilter.status}
                    type="checkbox"
                    onChange={(e) => statusFilter.setStatus(x.value)}
                  />
                  <label>{x.name}</label>
                </div>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </Box>
    </div>
  );
};

export default TableFilters;
