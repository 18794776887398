import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionContent, AccordionHeader } from '@adeccoux/tag-ds';
import { Box } from '@mui/material';

import styles from '../UserPermissions.module.scss';

interface UserConfigurationPanelProps {
  userId: string;
  data: { email?: string; role?: string; accessStatus?: string };
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
}

const accessStatusDropdownItems = [
  { value: 'active', text: 'Active' },
  { value: 'deactivated', text: 'Deactivated' },
];

const UserConfigurationPanel: React.FC<UserConfigurationPanelProps> = ({
  userId,
  data,
  handleInputChange,
  handleSave,
}) => {
  const { t } = useTranslation(['recruiter']);
  const roleDropdownItems = [
    { value: 'adeccoAdmin', text: t('GENERAL.PERMISSIONS.adeccoAdmin') },
    { value: 'adeccoStaff', text: t('GENERAL.PERMISSIONS.adeccoStaff') },
    { value: 'adeccoStaffAndAdmin', text: t('GENERAL.PERMISSIONS.adeccoStaffAndAdmin') },
    { value: 'adeccoConfig', text: t('GENERAL.PERMISSIONS.adeccoConfig') },
  ];

  return (
    <>
      <Box mb={4}>
        <div className="tag-ds input-wrapper   ">
          <label className="caption">{t('GENERAL.IMPORT.headerEmail')}</label>
          <input
            className=""
            placeholder="Email"
            type="text"
            name="email"
            disabled={userId !== '0'}
            defaultValue={userId !== '0' ? data?.email : ''}
            onChange={handleInputChange}
          />
        </div>
      </Box>
      <Box>
        <Accordion className={styles.accordionContainer}>
          <AccordionHeader className={styles.accordionHeader}>{t('GENERAL.PERMISSIONS.headerRole')}</AccordionHeader>
          <AccordionContent className={styles.accordionContent}>
            <div className="radio-group">
              {roleDropdownItems?.map((i) => (
                <div className="tag-ds radio-container" key={i.text}>
                  <input
                    id={i.value}
                    type="radio"
                    value={i.value}
                    name="role"
                    checked={data?.role === i.value}
                    onChange={handleInputChange}
                  />
                  <label htmlFor={i.value}>{i.text}</label>
                </div>
              ))}
            </div>
          </AccordionContent>
        </Accordion>
      </Box>
      <div className="separator" />
      <Box>
        <Accordion className={styles.accordionContainer}>
          <AccordionHeader className={styles.accordionHeader}>
            {t('GENERAL.PERMISSIONS.headerAccessStatus')}
          </AccordionHeader>
          <AccordionContent>
            {accessStatusDropdownItems?.map((i) => (
              <div className="tag-ds radio-container" key={i.text}>
                <input
                  id={i.value}
                  type="radio"
                  value={i.value}
                  name="accessStatus"
                  checked={data?.accessStatus === i.value}
                  onChange={handleInputChange}
                />
                <label htmlFor={i.value}>{i.text}</label>
              </div>
            ))}
          </AccordionContent>
        </Accordion>
      </Box>
      <div className="separator" />
      <Box className={styles.adminPermissionDetails__saveButton}>
        <button className="button-primary_large" onClick={handleSave} disabled={!data.email || !data.role}>
          {t(`candidate_recruiter:GENERAL.GENERIC.save`)}
        </button>
      </Box>
    </>
  );
};

export default UserConfigurationPanel;
