import { injectable } from 'inversify';
import { IFileConfig } from '@components/app/DocumentSteps/typings';

@injectable()
export class UploadDocumentContex {
  private fileConfig: IFileConfig | undefined | null = null;

  public tryRemoveFile() {
    if (this.fileConfig) {
      this.fileConfig.file = null;
    }
  }
}
