export enum ConfigurationSource {
  SEEDS = 'SEEDS',
  SELF_SERVICE = 'SELF_SERVICE',
  UI = 'UI',
}

export interface Configuration {
  id: number;
  tenantId: number;
  clientOrganizationId: string;
  candidateName: string;
  recruiterName: string;
  source: ConfigurationSource;
}
