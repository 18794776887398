import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { TabItem, Tabs } from '@adeccoux/tag-ds';
import { Drawer } from '@mui/material';
import { RootState } from '@store/rootReducer';

import ActivityHistoryFilter from './ActivityHistoryFilter';
import ActivitySection from './ActivitySection';

import styles from './ConfigurationActions.module.scss';

interface Props {
  isActive: boolean;
  handleDrawer: (isActive: boolean) => void;
}

const ActivityHistory: React.FC<Props> = ({ isActive, handleDrawer }) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('All');

  const { configurationChangeStack } = useSelector((store: RootState) => store.selfService);

  const sortChanges = useMemo(() => {
    const removeDuplicates = configurationChangeStack.reduce((acc: any[], curr) => {
      const changeExists = acc.some(
        (x: any) =>
          x.typeOfChange === curr.typeOfChange &&
          x.change.name === curr.change.name &&
          _.isEqual(x.changeList, curr.changeList),
      );

      if (!changeExists) {
        acc.push(curr);
      }

      return acc;
    }, []);

    const sortByCategory = removeDuplicates.reduce(
      (
        acc,
        curr: { typeOfChange: 'add' | 'change' | 'delete'; change: any; featureType: string; changeList: string[] },
      ) => {
        if (!acc[curr.featureType]) {
          acc[curr.featureType] = {};
        }

        if (!acc[curr.featureType][curr.typeOfChange]) {
          acc[curr.featureType][curr.typeOfChange] = [];
        }

        acc[curr.featureType][curr.typeOfChange].push({ change: curr.change, changeList: curr.changeList });

        return acc;
      },
      {} as any,
    );

    return sortByCategory;
  }, [configurationChangeStack]);

  const renderTabs = [
    {
      title: 'Personal info',
      field: 'PERSONAL_INFO',
    },
    {
      title: 'Contact info',
      field: 'CONTACT_INFO',
    },
    {
      title: 'General info',
      field: 'GENERAL_INFO',
    },
    {
      title: 'Document upload info',
      field: 'UPLOAD_DOCUMENTS',
      collapsed: true,
    },
    {
      title: 'Documents to sign info',
      field: 'DOCUMENT_TO_SIGN',
      collapsed: true,
    },
  ];

  const renderSections = (activeFilter: string, field: string) => {
    switch (activeFilter) {
      case 'All':
        return (
          <>
            <ActivitySection sectionName="Removed" options={sortChanges?.[field]?.delete} />
            <ActivitySection sectionName="Added" options={sortChanges?.[field]?.add} />
            <ActivitySection sectionName="Other changes" options={sortChanges?.[field]?.change} />
          </>
        );
      case 'Removed':
        return <ActivitySection sectionName="Removed" options={sortChanges?.[field]?.delete} />;
      case 'Added':
        return <ActivitySection sectionName="Added" options={sortChanges?.[field]?.add} />;
      case 'Other changes':
        return <ActivitySection sectionName="Other changes" options={sortChanges?.[field]?.change} />;
      default:
        return (
          <>
            <ActivitySection sectionName="Removed" options={sortChanges?.[field]?.delete} />
            <ActivitySection sectionName="Added" options={sortChanges?.[field]?.add} />
            <ActivitySection sectionName="Other changes" options={sortChanges?.[field]?.change} />
          </>
        );
    }
  };

  return (
    <Drawer anchor="right" open={isActive} onClose={() => handleDrawer(false)}>
      <div className={clsx(styles.activityHistory__drawer, 'tag-ds')}>
        <div className={clsx(styles['activityHistory__drawer-heading'], 'tag-ds')}>
          <p className="large">Activity History</p>
          <span className="material-icons" onClick={() => handleDrawer(false)}>
            close
          </span>
        </div>
        <Tabs className={styles.activityHistory__tabs}>
          {renderTabs.map((tab, i) => {
            return (
              <TabItem key={i} id={`tab${i}`} title={tab.title} collapsed={tab.collapsed}>
                <ActivityHistoryFilter selectedFilter={selectedFilter} handleChange={(val) => setSelectedFilter(val)} />
                {renderSections(selectedFilter, tab.field)}
              </TabItem>
            );
          })}
        </Tabs>
      </div>
    </Drawer>
  );
};

export default ActivityHistory;
