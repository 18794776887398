import { FormikContextType } from 'formik';

import { AddressHistoryCurrent } from '../calculationClasses/AddressHistoryCurrent';
import { ConvertCaseToUpper } from '../calculationClasses/ConvertCaseToUpper';
import { EmploymentHistoryCurrentJob } from '../calculationClasses/EmploymentHistoryCurrentJob';
import { FrPostCodeCheck } from '../calculationClasses/FrPostCodeCheck';
import { RecruiterRequirementsIT1 } from '../calculationClasses/RecruiterRequirementsIT1';
import { RecruiterRequirementsIT2 } from '../calculationClasses/RecruiterRequirementsIT2';
import { SpainNationalityHandler } from '../calculationClasses/SpainNationalityHandler';
import { StartDateBeforeAvailabilityDate } from '../calculationClasses/StartDateBeforeAvailabilityDate';
import { StartDateBeforeEndDate } from '../calculationClasses/StartDateBeforeEndDate';
import { WorkingHoursCalculator } from '../calculationClasses/WorkingHoursCalculator';
import { Component, ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export const useCalculationClassUI = (
  calcClass: { name: string } | undefined | null,
  control: OOControlModelInterface,
  otherControls: OOControlModelInterface[] | undefined,
  formikContext: FormikContextType<Record<string, any>>,
  setManualErrors?: (errors: Map<string, any>) => void,
): ICalculationClassUI | null => {
  if ((!calcClass && control.component !== Component.TransformTextToUppercase) || !formikContext) {
    return null;
  }

  if (control.component === Component.TransformTextToUppercase) {
    return new ConvertCaseToUpper(control, otherControls, formikContext);
  }

  switch (calcClass?.name ?? '') {
    case 'WorkingHoursCalculator':
      return new WorkingHoursCalculator(control, otherControls, formikContext);

    case 'StartDateBeforeAvailabilityDate':
      return new StartDateBeforeAvailabilityDate(control, otherControls, formikContext);

    case 'ConvertCaseToUpper':
      return new ConvertCaseToUpper(control, otherControls, formikContext);

    case 'RecruiterRequirementsIT1':
      return new RecruiterRequirementsIT1(control, otherControls, formikContext);

    case 'RecruiterRequirementsIT2':
      return new RecruiterRequirementsIT2(control, otherControls, formikContext);

    case 'FrPostCodeCheck':
      return new FrPostCodeCheck(control, otherControls, formikContext, setManualErrors);

    case 'SpainNationalityHandler':
      return new SpainNationalityHandler(control, otherControls, formikContext);

    case 'StartDateBeforeEndDate':
      return new StartDateBeforeEndDate(control, otherControls, formikContext);

    case 'EmploymentHistoryCurrentJob':
      return new EmploymentHistoryCurrentJob(control, otherControls, formikContext);
    case 'AddressHistoryCurrent':
      return new AddressHistoryCurrent(control, otherControls, formikContext);
  }

  return null;
};
