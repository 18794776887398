import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';

import { AppBottomBoxFixed } from './AppBottomBoxFixed/AppBottomBoxFixed';
import { Header, HeaderProps } from './Header/Header';

import scssVars from '../../styles/theme1/_variables.scss';

export interface MainTemplateProps {
  headerConfig?: HeaderProps;
  headerColor?: 'c1' | 'c2' | 'c3' | 'white' | string;
  footer?: boolean;
  children: React.ReactNode;
  bottomBoxFixed?: boolean;
  timeLeft?: number;
}

export const MainTemplate: React.FC<MainTemplateProps> = ({
  headerConfig,
  headerColor = 'white',
  footer,
  timeLeft,
  bottomBoxFixed,
  children,
}) => {
  const [height, setHeight] = useState<number>(0);
  const [bottomBoxHeight, setBottomBoxHeight] = useState<number>(0);
  useEffect(() => {
    if (headerConfig) {
      const header = document.getElementById('header');
      if (header) {
        setHeight(header.offsetHeight);
      }
    }
    if (bottomBoxFixed) {
      const bottomBox = document.getElementById('bottom-box-fixed');
      if (bottomBox) {
        setBottomBoxHeight(bottomBox.offsetHeight);
      }
    }
  }, [bottomBoxFixed, headerConfig]);
  if (headerColor === 'c1') {
    headerColor = scssVars.adeccoRed;
  }
  if (headerColor === 'c2') {
    headerColor = scssVars.dust;
  }
  if (headerColor === 'c3') {
    headerColor = scssVars.adeccoTwilight;
  }
  if (headerColor === 'white') {
    headerColor = scssVars.white;
  }
  return (
    <Container maxWidth="lg" disableGutters className="main-template">
      <div
        id="header"
        className="main-template__header-wrapper"
        style={{
          background: headerColor,
        }}
      >
        {headerConfig && <Header {...headerConfig} />}
      </div>
      <div
        className="main-template__main-wrapper"
        style={{
          height: `calc((100% - ${height}px) - ${bottomBoxHeight}px)`,
          overflow: 'visible',
        }}
      >
        {children}
      </div>
      {footer && <div>footer</div>}
      {bottomBoxFixed && timeLeft && <AppBottomBoxFixed timeLeft={timeLeft} />}
    </Container>
  );
};
