import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SelectDropdown } from '@components/app/SelectDropdown/SelectDropdown';
import { BoxedButton } from '@components/shared/BoxedButton/BoxedButton';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { AuthHelper } from '@helpers/authHelper';
import { setMomentLocale } from '@helpers/date-locales.helper';
import { getBlobData } from '@helpers/fileUpload.helper';
import {
  getClientOrganizationId,
  getConfigurationId,
  getTenantCountryCode,
  getTenantDefaultLanguage,
  getTenantInfo,
  getTenantLanguageList,
} from '@helpers/tenant.helper';
import { useFetchSelectedConfiguration } from '@hooks/apiHooks';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { useTheme } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { authAppSlice } from '@store/slices/authApp.slice';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';

export interface CheckResult {
  email: string;
  b2cIdExists: boolean;
  legalRequiredConsentAccepted: boolean;
}

export const StartPage = () => {
  const aamBackendApi = useInjection(AamBackendApi);
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['candidate_recruiter', 'candidate']);
  const authHelper = useInjection(AuthHelper);
  const { b2c, appSettings } = useSelector((state: RootState) => state.featureConfiguration);
  const { userId, sessionExpired } = useSelector((state: RootState) => state.authApp);
  const { tenantId } = getTenantInfo();
  const { oneOnboarding } = useSelector((state: RootState) => state);
  const { languages } = oneOnboarding;
  const [image, setImage] = useState<any>(null);
  const [dataFlowLoaded, setDataFlowLoaded] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(getTenantDefaultLanguage(tenantId));
  const { search } = useLocation();
  const candidateIdUrlParam = new URLSearchParams(search).get('candidateId');
  const clientOrganizationIdUrlParam = new URLSearchParams(search).get('clientOrganizationId');
  const configurationIdUrlParam = new URLSearchParams(search).get('configurationId');
  const applicationId = decodeURIComponent(new URLSearchParams(search).get('applicationId') || '');
  const [addData, setAddData] = useState<any>(null);
  const [checkResult, setCheckResult] = useState<CheckResult>();
  const clientOrganizationId = useMemo(() => {
    if (clientOrganizationIdUrlParam) {
      return clientOrganizationIdUrlParam;
    }
    if (oneOnboarding.clientOrganizationId) {
      return oneOnboarding.clientOrganizationId;
    }
    return getClientOrganizationId() || '';
  }, [clientOrganizationIdUrlParam, oneOnboarding.clientOrganizationId]);

  const configurationId = useMemo(() => {
    if (configurationIdUrlParam) {
      return configurationIdUrlParam;
    }
    if (oneOnboarding.selectedConfigurationId) {
      return oneOnboarding.selectedConfigurationId;
    }
    return getConfigurationId() || '';
  }, [configurationIdUrlParam, oneOnboarding.selectedConfigurationId]);

  const candidateId = useMemo(() => {
    if (candidateIdUrlParam) {
      return candidateIdUrlParam;
    }
    if (userId) {
      return userId;
    }
    if (oneOnboarding.userId) {
      return oneOnboarding.userId;
    }
  }, [candidateIdUrlParam, userId, oneOnboarding.userId]);
  const { createTelemetryUnauthenticated } = useTelemetry();

  const changeLanguageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lang = e.target.value;
    setSelectedLanguage(lang);
    setMomentLocale(tenantId, lang);
    if (i18n?.services?.languageUtils) {
      i18n.changeLanguage(lang);
    }
  };

  useEffect(() => {
    if (candidateId && userId && candidateId !== userId) {
      dispatch(authAppSlice.actions.clear());
    }
  });

  useEffect(() => {
    if (i18n?.services?.languageUtils) {
      let lang = localStorage.getItem('i18nextLng');
      lang = getTenantLanguageList(tenantId).find((x) => x === lang) ? lang : null;
      lang = lang || getTenantDefaultLanguage(tenantId);
      i18n.changeLanguage(lang);
      setSelectedLanguage(lang);
      setMomentLocale(tenantId, lang);
    }
  }, [tenantId]);

  const { isFetching = true } = useQuery({
    queryKey: ['checkUser', tenantId, clientOrganizationId, configurationId, candidateId],
    queryFn: () => aamBackendApi.checkUser(tenantId, candidateId, clientOrganizationId, configurationId),
    enabled: !!candidateId,
    onSuccess: (data: CheckResult) => {
      localStorage.setItem('clientOrganizationId', clientOrganizationId || '');
      if (candidateId && candidateId !== 'null' && candidateId !== 'undefined') {
        localStorage.setItem('applicationId', applicationId || '');
        localStorage.setItem('importClientOrganizationId', clientOrganizationId || '');
        localStorage.setItem('selectedConfigurationId', configurationId || '');
        setCheckResult(data);
      }
    },
  });

  const { isInitialLoading: flowLoading = true, data: selectedConfiguration } = useFetchSelectedConfiguration(
    clientOrganizationId,
    configurationId,
    (data) => {
      if (data.flow) {
        const flow = OOFlowWrapper.create(data.flow);
        const registrationStep = flow.steps.find((itm) => itm.name.toLowerCase().includes('registr'));
        const idpOptions =
          registrationStep?.elements.length &&
          registrationStep.elements.filter((element) => element.name === 'IDP_OPTIONS')[0]?.additionalData;
        setAddData(idpOptions);
        setDataFlowLoaded(true);
      }
    },
  );

  const b2cSignIn = async (email?: string) => {
    await createTelemetryUnauthenticated({
      actor: TelemetryActor.CANDIDATE,
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: configurationId,
      eventType: EventType.LINK_OPENED,
      tenantId: tenantId,
      targetName: TargetName.CandidateInvitation,
      candidateId: candidateIdUrlParam,
    });

    const signInUrl = await authHelper.getAuthorizeUrlSignIn(b2c.signIn, history.location, { email }, addData);

    authHelper.b2cNavigation(
      b2c.signIn,
      signInUrl + '&ui_locales=' + selectedLanguage + '&country=' + getTenantCountryCode(tenantId),
      history,
      appSettings.useExternalBrowser,
    );
  };
  const b2cSignUp = async (email?: string) => {
    await createTelemetryUnauthenticated({
      actor: TelemetryActor.CANDIDATE,
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: configurationId,
      eventType: EventType.LINK_OPENED,
      tenantId: tenantId,
      targetName: TargetName.CandidateInvitation,
      candidateId: candidateIdUrlParam || '',
    });
    const lang = getTenantDefaultLanguage(tenantId);
    let signUpUrlSuffix = '';
    if (lang === 'fr') {
      i18n.changeLanguage(lang);
      setSelectedLanguage(lang);
      setMomentLocale(tenantId, lang);
    }
    if (selectedConfiguration?.hasLanguageSelector) {
      signUpUrlSuffix = '/language-select';
    } else {
      signUpUrlSuffix = '/preregister';
    }
    history.push(`/oo${signUpUrlSuffix}`, { email });
  };

  useEffect(() => {
    if (checkResult && !flowLoading && dataFlowLoaded) {
      if (addData || checkResult.b2cIdExists) {
        b2cSignIn(checkResult.email);
      } else {
        b2cSignUp(checkResult.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addData, checkResult, flowLoading, dataFlowLoaded]);

  useEffect(() => {
    const downloadImage = async () => {
      try {
        if (!image) {
          const { data, headers } = await aamBackendApi.downloadLogo(
            tenantId,
            themeAdditionalData.startPageLogo || 'startPageLogo.svg',
          );
          setImage(getBlobData(data, headers['content-type']).imageUrl);
        }
      } finally {
        // setIsLoading(false);
      }
    };

    downloadImage();
  }, [aamBackendApi, tenantId, themeAdditionalData, image]);

  useEffect(() => {
    const completeRegistrationStepIfNeeded = async () => {
      if ((userId && !candidateId) || (candidateId && candidateId === userId)) {
        await aamBackendApi.completeRegistrationStep();
        history.push('/oo/flow');
      }
    };
    completeRegistrationStepIfNeeded();
  }, [aamBackendApi, candidateId, userId, history]);

  if ((userId && !candidateId) || (candidateId && candidateId === userId)) {
    return <></>;
  }

  if (sessionExpired || new URLSearchParams(window.location.search).get('sessionExpired')) {
    dispatch(
      snackbarSlice.actions.showError({
        message: t('candidate_recruiter:GENERAL.GENERIC.sessionExpired'),
      }),
    );
  }

  if (isFetching || flowLoading) {
    return <Loader />;
  }
  return (
    <>
      <div
        className="loginSignin"
        style={{
          background: themeAdditionalData.startPageBackground || theme.palette.primary.main,
        }}
      >
        <div className="loginSignin__select-language">
          <div className="header__select-language-box">
            <SelectDropdown
              title={t('candidate:GENERAL.LANGUAGE.selectLanguage')}
              options={
                languages.length !== 0
                  ? languages.map(({ code }) => ({ name: code, value: code }))
                  : getTenantLanguageList(tenantId).map((x) => ({ name: x, value: x }))
              }
              onChange={changeLanguageHandler}
              value={selectedLanguage}
              name="selectedLanguage"
            />
          </div>
        </div>
        <div className="loginSignin__main-content">
          <div className="adeccoLogo">{image && <img src={image} alt="Dialog" />}</div>

          <BoxedButton
            fullWidth={false}
            variant="contained"
            onClick={() => b2cSignIn()}
            text={t('candidate:GENERAL.REGISTRATION.signin')}
            className="signInButton generalButton"
            style={{ ...themeAdditionalData.signInButton }}
          />
        </div>
      </div>
    </>
  );
};
