import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface MailDetailsProps {
  mailDetails: any;
  isActive: boolean;
  closeModal: () => void;
}

const MailDetails: React.FC<MailDetailsProps> = ({ mailDetails, isActive, closeModal }) => {
  const { t } = useTranslation(['recruiter']);

  return (
    <Dialog open={isActive} onClose={closeModal} BackdropProps={{ style: { backgroundColor: 'rgba(28,48,78,48%)' } }}>
      <DialogTitle style={{ padding: '40px 40px 16px 40px' }}>
        <p className="tag-ds  h4">{t('recruiter:GENERAL.CANDIDATE_DETAILS.Details')}</p>
      </DialogTitle>
      <DialogContent style={{ padding: '0 40px 40px 40px' }}>
        <Box style={{ wordBreak: 'break-word' }}>{mailDetails ? JSON.stringify(mailDetails) : ''}</Box>
      </DialogContent>
      <DialogActions style={{ padding: '0 40px 40px 40px' }}>
        <div className="tag-ds ">
          <button
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(mailDetails));
            }}
          >
            {t('recruiter:GENERAL.MAILS.copyText')}
            <span className="material-icons right">content_copy</span>
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MailDetails;
