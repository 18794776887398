import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface AdminPromptProps {
  isVisible: boolean;
  content: {
    title: string;
    description?: any;
  };
  action: { onClick: () => void; text: string; disabled?: boolean; cancelText?: string };
  onClose: () => void;
}

const AdminPrompt: React.FC<AdminPromptProps> = ({ isVisible, content, action, onClose }) => {
  const { t } = useTranslation(['candidate_recruiter']);

  return (
    <Dialog onClose={onClose} open={isVisible}>
      <DialogTitle>
        <p className="tag-ds  h4">{content.title}</p>
      </DialogTitle>
      {content.description && <DialogContent>{content.description}</DialogContent>}
      <DialogActions style={{ margin: '0 auto' }}>
        <Box display="flex" justifyContent="space-evenly" width={375}>
          <button onClick={action.onClick} className="tag-ds button-primary" disabled={action.disabled}>
            {action.text}
          </button>
          <button onClick={onClose} className="tag-ds button-secondary">
            {t(action.cancelText || 'candidate_recruiter:GENERAL.GENERIC.no')}
          </button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AdminPrompt;
