class Node {
  entity: any;
  pathSegment: string[];
  parent?: Node;

  constructor(entity: any, pathSegment: string[], parent?: Node) {
    this.entity = entity;
    this.pathSegment = pathSegment;
    this.parent = parent;
  }
}

abstract class BasePathHelper {
  protected tail: Node;

  constructor(entity?: any, previousNode?: Node) {
    this.tail = previousNode ?? new Node(entity, []);
  }

  getPath(): string[] {
    const pathParts: string[] = [];
    let curNode: Node | undefined = this.tail;
    while (curNode) {
      pathParts.push(...curNode.pathSegment);
      curNode = curNode.parent;
    }

    return pathParts.reverse();
  }

  getObject(): any {
    return this.tail.entity;
  }

  protected findInArray(array: any[] | undefined, name: string): { object: any; objectIndex: number } {
    let objectIndex = 0;

    const object = array?.find((s: any, i: number) => {
      if (s.name === name) {
        objectIndex = i;
        return true;
      }

      return false;
    });

    return { object, objectIndex };
  }
}

export class PathHelper extends BasePathHelper {
  features(name: string): PathHelperFeature {
    const newParent = this.tail;

    const { object, objectIndex } = this.findInArray(newParent.entity.features, name);

    if (!object) {
      throw new Error(`Feature ${name} does not exist in configuration`);
    }

    this.tail = new Node(object, [`${objectIndex}`, 'features'], newParent);
    return new PathHelperFeature(undefined, this.tail);
  }

  signingFeature(): PathHelperFeature {
    const newParent = this.tail;

    const signingFeature = newParent.entity.features.find((f: any) => {
      if (f.steps.find((s: any) => s.documentsForSignature?.length > 0)) {
        return f;
      }

      return null;
    });

    if (!signingFeature) {
      throw new Error(`Signing feature does not exist in the configuration`);
    }

    const { object, objectIndex } = this.findInArray(newParent.entity.features, signingFeature.name);

    this.tail = new Node(object, [`${objectIndex}`, 'features'], newParent);
    return new PathHelperFeature(undefined, this.tail);
  }
}

class PathHelperFeature extends BasePathHelper {
  steps(name: string): PathHelperStep {
    const newParent = this.tail;

    const { object, objectIndex } = this.findInArray(newParent.entity.steps, name);

    if (!object) {
      throw new Error(`Step ${name} does not exist in feature`);
    }

    this.tail = new Node(object, [`${objectIndex}`, 'steps'], newParent);
    return new PathHelperStep(undefined, this.tail);
  }

  signingStep(): PathHelperStep {
    const newParent = this.tail;

    const signingStep = newParent.entity.steps.find((s: any) => s.documentsForSignature?.length > 0);

    if (!signingStep) {
      throw new Error(`Signing step does not exist in the feature`);
    }

    const { object, objectIndex } = this.findInArray(newParent.entity.steps, signingStep.name);

    this.tail = new Node(object, [`${objectIndex}`, 'steps'], newParent);
    return new PathHelperStep(undefined, this.tail);
  }
}

class PathHelperStep extends BasePathHelper {
  pages(name: string): PathHelperStep {
    const newParent = this.tail;

    const { object, objectIndex } = this.findInArray(newParent.entity.pages, name);

    if (!object) {
      throw new Error(`Page ${name} does not exist in step`);
    }

    this.tail = new Node(object, [`${objectIndex}`, 'pages'], newParent);
    return this;
  }

  documentsForSignature(name: string): PathHelperStep | null {
    const newParent = this.tail;

    const { object, objectIndex } = this.findInArray(newParent.entity.documentsForSignature, name);

    if (!object) {
      return null;
    }

    this.tail = new Node(object, [`${objectIndex}`, 'documentsForSignature'], newParent);
    return this;
  }
}
