import { FC, useState } from 'react';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';

import { PageStepContext } from './PageStepContext';

export const PageStepProvider: FC = ({ children }) => {
  const [context, setContext] = useState<{ page: OOPageModel | undefined; step: OOStepModel | undefined }>({
    page: undefined,
    step: undefined,
  });

  const { page, step } = context;

  return (
    <PageStepContext.Provider value={{ page, step, setPageStepContext: setContext }}>
      {children}
    </PageStepContext.Provider>
  );
};
