import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import { locales } from '@features/oneOnboarding/helpers';
import { ImportLine } from '@features/oneOnboarding/models/Import';
import { getTenantDefaultLanguage } from '@helpers/tenant.helper';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';

import { Loader } from '../../shared/Loader/Loader';
import { AdminButton } from '../AdminButton/AdminButton';
import { getComparator, stableSort } from './kpi/helpers';

const columns = [
  { field: 'importData.FIRST_NAME.value', headerName: 'headerFirstName', sorting: true },
  { field: 'importData.LAST_NAME.value', headerName: 'headerLastName', sorting: true },
  { field: 'importData.EMAIL.value', headerName: 'headerEmail', sorting: true },
  { field: 'importData.LOCAL_APPLICATION_ID.value', headerName: 'headerLocalApplicationId', sorting: true },
  { field: 'createdAt', headerName: 'headerStartDate', sorting: true },
  { field: 'status', headerName: 'headerStatus', sorting: true },
  { field: 'actions', headerName: 'headerActions', sorting: false },
];

interface AsyncImportTableProps {
  items: ImportLine[];
  order: any;
  setOrder: any;
  orderBy: any;
  setOrderBy: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  page: any;
  setPage: any;
  total: number;
  tenantId: any;
  rowsPerPageOptions: Array<number>;
  onTriggerImport: (item: ImportLine) => void;
  triggeringItem: ImportLine | undefined;
  reimportDisabled?: boolean;
  hideReimportButton?: boolean;
}

export const AsyncImportLinesTable: React.FC<AsyncImportTableProps> = ({
  items,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  total,
  tenantId,
  rowsPerPageOptions,
  onTriggerImport,
  triggeringItem,
  reimportDisabled,
  hideReimportButton,
}) => {
  const { t } = useTranslation(['recruiter']);
  const [errorItem, setErrorItem] = useState<ImportLine>();

  const onRequestSort = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [selectedLanguage] = useState<string>(
    () => localStorage.getItem('i18nextLng') ?? getTenantDefaultLanguage(tenantId),
  );

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => onRequestSort(event, property);

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const selectedLocale = locales[selectedLanguage] || enUS;

  const formatTableDate = (date?: Date | string) =>
    date ? format(typeof date === 'string' ? parseISO(date) : date, 'MMM dd yyyy', { locale: selectedLocale }) : '';
  const formatTableTime = (date?: Date | string) =>
    date ? format(typeof date === 'string' ? parseISO(date) : date, 'hh:mm a', { locale: selectedLocale }) : '';

  return (
    <>
      <TableContainer style={{ marginBottom: 8 }}>
        {items === null && <Loader />}

        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          className="feature-dashboard__table"
        >
          <TableHead className="feature-dashboard__table-head">
            <TableRow>
              {columns.map(({ field, headerName, sorting }) =>
                sorting ? (
                  <TableCell key={field}>
                    <TableSortLabel
                      onClick={createSortHandler(field)}
                      active={orderBy === field}
                      direction={orderBy === field ? order : 'asc'}
                    >
                      {t(`GENERAL.IMPORT.${headerName}`)}
                    </TableSortLabel>
                  </TableCell>
                ) : (
                  <TableCell key={field}>{headerName ? t(`GENERAL.IMPORT.${headerName}`) : ''}</TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              stableSort(items, getComparator(order, orderBy)).map((item: ImportLine) => (
                <TableRow
                  hover
                  key={item.id}
                  style={{
                    opacity: item.id === triggeringItem?.id ? '0.5' : '',
                  }}
                >
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        {item.importData?.FIRST_NAME?.value ?? item.importData?.FIRST_NAME}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        {item.importData?.LAST_NAME?.value ?? item.importData?.LAST_NAME}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        {item.importData?.EMAIL?.value ?? item.importData?.EMAIL}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        {item.importData?.LOCAL_APPLICATION_ID?.value ??
                          item.importData?.LOCAL_APPLICATION_ID ??
                          item.importData?.APPLICATION_ID}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        <span>{formatTableDate(item.createdAt)}</span>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="feature-dashboard__table-small-text">
                          {formatTableTime(item.createdAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{item.status ? t(`GENERAL.IMPORT.${item.status}`) : ''}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {item.status === 'FAILED' && (
                      <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <AdminButton
                          text={t('GENERAL.IMPORT.showErrorMessage')}
                          onClick={() => setErrorItem(item)}
                          disabled={item.id === triggeringItem?.id}
                        />
                        {!hideReimportButton && (
                          <AdminButton
                            variant="outlined"
                            text={
                              item.id === triggeringItem?.id ? (
                                <CircularProgress size={20} />
                              ) : (
                                <span>{t('GENERAL.IMPORT.reImport')}</span>
                              )
                            }
                            onClick={() => onTriggerImport(item)}
                            disabled={item.id === triggeringItem?.id || reimportDisabled}
                          />
                        )}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={total + rowsPerPage || 0}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        labelRowsPerPage={t('GENERAL.CANDIDATES_OVERVIEW.rowsPerPage')}
      />

      <Dialog fullWidth maxWidth="sm" open={errorItem !== undefined}>
        <DialogTitle>
          <Box justifyContent="space-between" display="flex">
            <Typography variant="h3">{t('GENERAL.CANDIDATES_OVERVIEW.errorMessage')}</Typography>
            <IconButton onClick={() => setErrorItem(undefined)} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box />
          {errorItem?.errorMessage ? (
            <Box>{t(errorItem.errorMessage, errorItem.additionalData?.errorMessageDependencies)}</Box>
          ) : (
            <Box>No error</Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button fullWidth={false} onClick={() => setErrorItem(undefined)}>
            {t('candidate_recruiter:GENERAL.GENERIC.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
