import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { RootState } from '@store/rootReducer';

import { composeTranslationPath } from '../../../../../helpers/translationHelper';
import DocumentControl from './DocumentControl';

import controlsStyle from '../Controls.module.scss';
import styles from './DocumentsControls.module.scss';

interface Props {
  value: { label: string; component: string }[];
  setFieldValue: (name: string, value: any) => void;
  translations: any[];
  setTranslations: (a: any) => void;
  fieldProps: any;
  disabled: boolean;
}

const DocumentsControls: React.FC<Props> = ({
  value,
  setFieldValue,
  translations,
  setTranslations,
  fieldProps,
  disabled,
}) => {
  const [controls, setControls] = useState<any[] | null>(null);
  const [isExtended, setIsExtended] = useState(false);

  const { t } = useTranslation(['documents']);
  const { selectedConfiguration, selectedFeature } = useSelector((store: RootState) => store.selfService);

  const availableLanguages = selectedConfiguration?.languages;
  const getPath = composeTranslationPath(selectedFeature?.featureName ?? '', selectedFeature?.stepName ?? '');

  useEffect(() => {
    if (controls) return;

    const allowedControls = ['Datepicker', 'Textfield', 'Checkbox', 'RadioGroup', 'Consent', 'Switch'];

    const withAllowedComponents = value?.filter((x) => allowedControls.includes(x.component));
    const withUuid = withAllowedComponents?.map((x: any) => ({ ...x, uuid: uuid() }));

    setControls(withUuid);
  }, [value, controls, t, availableLanguages?.supported]);

  useEffect(() => {
    if (value) {
      setIsExtended(true);
    }
  }, [value]);

  const handleFormikValue = (data: any) => {
    const withoutUuid = data.map((x: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { uuid, ...rest } = x;

      return rest;
    });

    setFieldValue('controls', withoutUuid);
  };

  const handleDelete = (index: number) => {
    if (!controls || disabled) return;

    const newControls = [...controls];
    newControls.splice(index, 1);

    setControls(newControls);
    handleFormikValue(newControls);
  };

  const handleChange = (index: number, key: string, value: any) => {
    if (!controls || disabled) return;

    const typeOfControl = (oldType: string) => {
      if (key === 'component') {
        switch (value) {
          case 'Datepicker':
            return 'date';
          case 'Textfield':
            return 'text';
          case 'Boolean':
            return 'boolean';
          default:
            return 'text';
        }
      }

      return oldType;
    };

    const newControls = (newCtrl: any) =>
      newCtrl.map((item: any, i: number) => {
        if (i !== index) return item;

        const isValueObject = typeof value === 'object';
        const keyConversion = isValueObject ? { ...value } : { [key]: value };

        const resetProperties = () => {
          const keysForDeletion = [
            'min',
            'max',
            'minOffset',
            'maxOffset',
            'minMaxValidationError',
            'regexValidation',
            'regExpValidationError',
            'customControlHeaders',
          ];

          keysForDeletion.forEach((x) => {
            delete item[x];
          });
        };

        if (key === 'component') {
          resetProperties();
        }

        const sourceField = `metadata.${item.name.toLowerCase()}`;

        return {
          ...item,
          ...keyConversion,
          type: typeOfControl(item.type),
          sourceField: sourceField,
        };
      });

    setControls((state: any) => {
      handleFormikValue(newControls(state));
      return newControls(state);
    });
  };

  const handleNewControl = () => {
    if (!controls || disabled) return;

    const newControls = [...controls];
    const newTranslations = [...translations];
    newControls.push({
      name: '',
      component: 'Textfield',
      isMandatory: false,
      isEditableCandidate: false,
      isEditableRecruiter: false,
      isVisibleCandidate: false,
      isVisibleRecruiter: false,
      uuid: uuid(),
      sequence: controls[controls.length - 1]?.sequence + 10 || 10,
      version: 1,
      category: 'document',
      type: 'text',
      sourceClass: 'UserDocument',
      sourceField: '',
      integrationTarget: 'Candidate-and-Onboarding',
      parameters: [],
    });

    newTranslations.push({
      name: '',
    });

    setControls(newControls);
    setTranslations(newTranslations);
    handleFormikValue(newControls);
  };

  if (!controls) return null;

  const handleTranslation = (name: string, key: string, value: { [key: string]: string } | null, newKey?: string) => {
    const newTranslations = (currState: any) =>
      currState.map((t: any) => {
        if (t.name === name) {
          if (newKey) {
            return {
              ...t,
              [key]: newKey,
            };
          }

          return {
            ...t,
            [key]: {
              ...t[key],
              ...value,
            },
          };
        }

        return t;
      });

    setTranslations((state: any) => newTranslations(state));
  };

  const isAddButtonVisible = controls.length === 0;
  const errorList = fieldProps?.meta?.error;

  return (
    <div>
      <div className={styles.heading}>
        <label className="subtitle">Document controls</label>
        {isAddButtonVisible ? (
          <div
            className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
            onClick={handleNewControl}
          >
            <span className="material-icons">add</span>
          </div>
        ) : (
          <div>
            <span
              className="material-icons"
              onClick={() => setIsExtended((state) => !state)}
              style={{ cursor: 'pointer', transform: isExtended ? 'rotate(180deg)' : 'none' }}
            >
              keyboard_arrow_up
            </span>
          </div>
        )}
      </div>
      <div style={{ display: isExtended ? 'block' : 'none' }}>
        {controls?.map((item, index) => {
          return (
            <DocumentControl
              key={item.uuid}
              item={item}
              index={index}
              handleDelete={() => handleDelete(index)}
              handleChange={(fieldName: string, value: any) => handleChange(index, fieldName, value)}
              handleTranslation={handleTranslation}
              getPath={getPath}
              translations={translations}
              error={errorList?.[index]}
              disabled={disabled}
            />
          );
        })}
        {!isAddButtonVisible && !disabled && (
          <button type="button" onClick={handleNewControl}>
            Add new document control
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentsControls;
