import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './FoldableTreeNode.module.scss';

// Created as replacement for "<TreeViewElement />" component from tag-ds team
interface Props {
  name: string;
  disabled: boolean;
  icon?: string;
  className: string;
  onClick: () => void;
  arrow?: 'right';
  children?: React.ReactElement;
  isCollapsed?: boolean;
}

const FoldableTreeNode: React.FC<Props> = ({
  name,
  icon,
  className,
  onClick,
  disabled,
  arrow,
  children,
  isCollapsed,
}) => {
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (isCollapsed !== undefined) {
      setShowChildren(isCollapsed);
    }
  }, [isCollapsed]);

  const handleOnClick = useCallback(() => {
    setShowChildren((state) => !state);

    if (!disabled) onClick();
  }, [onClick, disabled]);

  return (
    <>
      <li
        className={clsx(className, styles.wrapper, 'tree-element', { [styles.disabled]: disabled })}
        onClick={handleOnClick}
      >
        {icon && <span className={clsx('material-icons', styles.icon)}>{icon}</span>}
        {name}
        {arrow && children && (
          <span className={clsx('material-icons', 'tree-element-icon-right', showChildren ? styles.arrowActive : '')}>
            keyboard_arrow_down
          </span>
        )}
      </li>
      {children && showChildren && <ul className="tree-container">{children}</ul>}
    </>
  );
};

export default FoldableTreeNode;
