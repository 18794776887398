import React, { FC } from 'react';

import styles from './AdminImport.module.scss';

interface AdminLinkButtonProps {
  onClick: any;
  label?: string;
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
  style?: any;
}
export const AdminLinkButton: FC<AdminLinkButtonProps> = ({
  onClick,
  label,
  className,
  disabled,
  children,
  ...rest
}) => (
  <span
    className={
      styles.linkButton + (disabled ? ' ' + styles.linkButtonDisabled : '') + (className ? ' ' + className : '')
    }
    onClick={!disabled ? onClick : null}
    {...rest}
  >
    {label || children}
  </span>
);
