import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { StepStatusEnum } from '@features/oneOnboarding/interfaces';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import FoldableTreeNode from '@features/oneOnboardingAdmin/components/FoldableTreeNode/FoldableTreeNode';
import { ItemProps } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/CustomCandidateProfileSidebar';
import { RecruiterSidebarItem } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { useStepItemState } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/hooks/useStepItemState';
import { SidebarItems } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/SidebarItems';
import { StepSeparator } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/StepSeparator';
import { PageStepContext } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/PageStepContext';

import { StepRecruiterSidebarItem } from './handlerClasses/types';

import styles from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/CustomCandidateProfileSidebar.module.css';

export type StepItemProps = ItemProps & {
  item: StepRecruiterSidebarItem;
};
export const getStepAndPage = (
  item: RecruiterSidebarItem,
  steps: OOStepModel[],
): { step: OOStepModel | undefined; page: OOPageModel | undefined } => {
  let step: OOStepModel | undefined;
  let page: OOPageModel | undefined;
  if ('step' in item) {
    step = steps.find((s) => s.name === item.step);
    if (step === undefined) {
      step = steps.find((s) => s.pages?.find((p) => p.name === item.step));
    }
    page = step?.pages?.find((p) => p.name === item.step);
  }
  return { step, page };
};

export const StepItem: FC<StepItemProps> = ({
  item,
  index,
  steps,
  flowWrapper,
  onboarding,
  currentStep,
  currentPage,
}) => {
  const { step, page } = getStepAndPage(item, steps);
  const { setPageStepContext } = useContext(PageStepContext);
  const { t } = useT('stepper');

  const { stepStatus, isActive, disabled } = useStepItemState({
    item,
    flowWrapper,
  });

  const children = item.children?.length ? (
    <>
      <StepSeparator />
      <SidebarItems
        items={item.children}
        steps={steps}
        flowWrapper={flowWrapper}
        onboarding={onboarding}
        currentStep={currentStep}
        currentPage={currentPage}
      />
    </>
  ) : undefined;

  return (
    <FoldableTreeNode
      key={index}
      name={t(item.label)}
      icon={stepStatus === StepStatusEnum.completed ? 'check_circle' : 'radio_button_unchecked'}
      className={clsx(styles['tree-view-element'], {
        [styles.success]: stepStatus === StepStatusEnum.completed,
        [styles.inProgress]: stepStatus === StepStatusEnum.inProgress || isActive,
      })}
      disabled={isActive ? false : disabled}
      arrow={item.children?.length ? 'right' : undefined}
      onClick={() => {
        if (!item.children?.length) {
          setPageStepContext({ page, step });
        }
      }}
    >
      {children}
    </FoldableTreeNode>
  );
};
