import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createEditor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';
import { RootState } from '@store/rootReducer';

import { checkForTranslationInConfig } from '../../../../../helpers/translationHelper';
import { Toolbar } from './components';
import BlockButton from './components/BlockButton';
import Element from './components/Element';
import Leaf from './components/Leaf';
import LinkButton from './components/LinkButton';
import MarkButton from './components/MarkButton';
import TextStyle from './components/TextStyle';
import withLinks from './plugins/withLink';
import { options, parseFromHtml, parseToHtml } from './utils';

import style from './RichTextEditor.module.scss';

interface Props {
  translationValues: { [key: string]: string };
  htmlValue: string;
  setFieldValue: any;
  language: string;
  name: string;
  label: string;
  disabled: boolean;
}

const initialValue = [
  {
    type: 'span',
    children: [{ text: '' }],
  },
];

const RichTextEditor: React.FC<Props> = ({
  htmlValue,
  setFieldValue,
  translationValues,
  language,
  name,
  label,
  disabled,
}) => {
  const [editor] = useState(() => withReact(withHistory(withLinks(createEditor()))));
  const [value, setValue] = useState<any>(initialValue);

  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const { t } = useTranslation(['entry']);

  useEffect(() => {
    if (!htmlValue || !Transforms || !selectedConfiguration) return;

    const translationFromConfig = checkForTranslationInConfig(label, language, selectedConfiguration);

    const getValue = () => {
      if (translationFromConfig) {
        return translationFromConfig.value;
      } else if (label === htmlValue) {
        return t(htmlValue, { lng: language });
      } else {
        return htmlValue;
      }
    };

    const document = new DOMParser().parseFromString(getValue(), 'text/html');
    const parseHtml = parseFromHtml(document.body);

    if (isEqual(value, initialValue)) {
      editor.insertFragment(parseHtml);
      setValue(parseHtml);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, htmlValue]);

  const handleEditorChange = (newValue: any) => {
    if (!selectedFeature || disabled) return;
    const availableLanguages: any = selectedConfiguration?.languages.supported;

    setValue(newValue);

    const serializeData = parseToHtml(editor);

    const payload = { ...translationValues, [language]: serializeData };

    for (const lang of availableLanguages) {
      if (!(lang in payload)) {
        payload[lang] = undefined;
      }
    }

    setFieldValue('staticTextTranslations', payload);
  };

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <div className={style.editorWrapper}>
      <Slate editor={editor} initialValue={value} onChange={handleEditorChange}>
        <Toolbar>
          {options.map((option, index) => {
            if (option.type === 'block') {
              return <BlockButton key={index} format={option.format} icon={option.icon} disabled={disabled} />;
            } else if (option.type === 'link') {
              return <LinkButton key={index} editor={editor} icon={option.icon} disabled={disabled} />;
            } else if (option.type === 'textStyle' && option.options) {
              return (
                <TextStyle
                  key={index}
                  editor={editor}
                  icon={option.icon}
                  options={option.options}
                  disabled={disabled}
                />
              );
            } else {
              return <MarkButton key={index} format={option.format} icon={option.icon} disabled={disabled} />;
            }
          })}
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          className={style.contentEditable}
          disabled={disabled}
          readOnly={disabled}
        />
      </Slate>
    </div>
  );
};

export default RichTextEditor;
