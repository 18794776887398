import React from 'react';
import { ProgressLine } from '@features/oneOnboarding/components/ProgressLine';
import { OOStepper } from '@features/oneOnboarding/controls/Stepper';

export const getStepperComponent = (type: string, data: any = {}) => {
  switch (type) {
    case 'none':
      return <></>;
    case 'line':
      return <ProgressLine />;
    case 'points':
      return <OOStepper {...data} />;
    default:
      return <ProgressLine />;
  }
};
