import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import ConditionSign from '@features/selfService/components/ConditionSign/ConditionSign';
import { Box } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { findPageInConfiguration, selfServiceSlice } from '@store/slices/selfService.slice';

import { translationHelper } from '../../../../helpers/translationHelper';

import styles from '../../ConfigurationSetup.module.scss';

interface ControlListItemProps {
  name: string;
  label: string;
  setControlDetails: () => void;
  isActive: boolean;
  staticText?: string;
  warning?: boolean;
  text?: string;
  isDisabled: boolean;
  isHavingCondition: boolean;
  component: string;
}

const ControlListItem: React.FC<ControlListItemProps> = ({
  name,
  label,
  setControlDetails,
  staticText,
  isActive,
  warning,
  text,
  isDisabled,
  isHavingCondition,
  component,
}) => {
  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['entry', 'control-items', 'approval']);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const getTranslation = (key: string) => translationHelper(key, i18n, t, selectedConfiguration ?? undefined);

  const handleDeleteControl = (controlName: string) => {
    if (!selectedFeature || !selectedConfiguration) return;

    const currentPage = findPageInConfiguration(
      {
        featureName: selectedFeature?.featureName,
        stepName: selectedFeature?.stepName,
        pageName: selectedFeature?.pageName,
      },
      selectedConfiguration,
    );

    const newControls = currentPage.controls
      .filter((x: any) => x.name !== controlName)
      .map((y: any, i: number) => ({ ...y, sequence: i * 10 }));

    const controlHeadersExist = selectedConfiguration?.controlHeaders.find((x: any) => x.name === controlName);

    dispatch(
      selfServiceSlice.actions.handlePageModify({
        ...currentPage,
        featureName: selectedFeature.featureName,
        stepName: selectedFeature.stepName,
        pageName: selectedFeature.pageName,
        controls: newControls,
        modification: { type: 'delete', property: currentPage.controls.find((x: any) => x.name === controlName) },
      }),
    );

    if (controlHeadersExist) {
      dispatch(
        selfServiceSlice.actions.handleTopLevelProperties({
          propertyName: 'controlHeaders',
          propertyValue: selectedConfiguration.controlHeaders.filter((x) => x.name !== controlName),
        }),
      );
    }
  };

  const renderLabel = () => {
    if (label) {
      return getTranslation(label || name || (text ?? ''));
    } else if (text) {
      return getTranslation(text);
    } else if (staticText && component !== 'Html') {
      return getTranslation(staticText);
    }
    return name;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        color: isDisabled ? '#C4C4C4' : '',
        position: 'relative',
      }}
      onClick={setControlDetails}
    >
      <div
        key={name}
        className={styles.controlListItem}
        style={{ border: isActive ? '1px solid #009ACC' : '', flex: 1 }}
      >
        <Box display="flex" alignItems="center">
          {!isViewOnly && (
            <div className="material-icons" style={{ fontSize: 15 }}>
              drag_indicator
            </div>
          )}
          <p className={`subtitle ${styles.controlListItem__label}`}>{renderLabel()}</p>
        </Box>
        {!isViewOnly && (
          <div
            className={styles.controlListItem__button}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteControl(name);
            }}
          >
            <span className="material-icons-outlined">delete</span>
          </div>
        )}
      </div>
      {warning && (
        <div className="material-icons" style={{ fontSize: 22, marginLeft: 9, color: '#E87A71' }}>
          warning
        </div>
      )}
      {isHavingCondition && <ConditionSign />}
    </div>
  );
};

export default ControlListItem;
