import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ClientStoreState {
  clientId: string;
  clients: any[];
}

const initialState: any = {
  clientId: null,
  clients: [],
};

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState as ClientStoreState,
  reducers: {
    setClient: (state, action: PayloadAction<{ clientId: string | null }>) => {
      state.clientId! = action.payload.clientId!;
      return state;
    },
    setClients: (state, action: PayloadAction<{ clients: any[] }>) => {
      state.clients = action.payload.clients;
      return state;
    },
  },
});
