import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { Box, FormControl, FormLabel, MenuItem, Select } from '@mui/material';

const DocumentTypePicker = () => {
  const {
    document,
    setDocument,
    documentationModel,
    setDocumentationModel,
    setInitialDocumentType,
    initialDocumentType,
  } = useContext(OODocumentContext);
  const { t } = useTranslation(['candidate_recruiter']);

  const documentation = useMemo(() => documentationModel, [documentationModel])!;

  const onDocumentFamilyChange = (event?: any) => {
    const documentType = event?.target?.value;

    if (!documentType) return;
    if (!document?.name) throw new Error('Missing step or document name');

    if (!initialDocumentType) {
      setInitialDocumentType(document.name);
    }

    setDocumentationModel(documentation);
    setDocument(documentation.documents.find((d) => d.name === documentType)!);
  };

  const renderDocumentFamilyItems = useMemo(() => {
    const hasRejectedDocuments = documentation.documents.some(
      (d) => d.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED,
    );
    const hasMandatoryDocumentUploaded = documentation.documents.some((d) => d.isMandatory && d.userDocument);
    const documentsFamily = documentation.documents.filter((d) => {
      if (hasMandatoryDocumentUploaded && !hasRejectedDocuments) {
        return (d.isMandatory && d?.userDocument) || !d.isMandatory;
      } else {
        return d;
      }
    });

    return documentsFamily.map((d) => (
      <MenuItem key={d.name} value={d.name}>
        {t(`documents:${d.label}`)} {d.isMandatory ? '*' : ''}
      </MenuItem>
    ));
  }, [documentation.documents, t]);

  return (
    <FormControl>
      <Box sx={{ paddingBottom: '0; !important' }}>
        <FormLabel>{t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.documentType')} *</FormLabel>
      </Box>
      <Select
        label="DOCUMENT TYPE"
        value={document?.name}
        MenuProps={{
          PaperProps: {
            sx: {
              overflowX: 'auto',
            },
          },
        }}
        onChange={onDocumentFamilyChange}
      >
        {renderDocumentFamilyItems}
      </Select>
    </FormControl>
  );
};

export default DocumentTypePicker;
