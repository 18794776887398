import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCampaigns, useFetchBranches, useSites } from '@hooks/apiHooks';

export const useBranchOptions = (enabled: boolean) => {
  const { data: branches } = useFetchBranches(enabled);

  const branchesOptions = useMemo(() => {
    if (!branches) return [];

    return branches
      .sort((a: any, b: any) => a.sid.localeCompare(b.sid))
      .map((i) => ({
        name: i.name,
        id: i.id,
        label: `${i.sid} (${i.siteId ? `${i.sid} ` : ''}${i.name})`,
      }));
  }, [branches]);

  return branchesOptions;
};
export const useSiteOptions = (enabled: boolean) => {
  const { data: sites } = useSites(enabled);

  const siteOptions = useMemo(() => {
    if (!sites) return [];

    return sites.map((x) => ({
      name: x.name,
      id: x.id,
      label: `${x.name} - ${x.code}`,
    }));
  }, [sites]);

  return { siteOptions, sites };
};
export const useCampaignOptions = (enabled: boolean) => {
  const { data: campaigns } = useCampaigns(enabled);
  const { t } = useTranslation('control-items');

  const campaignOptions = useMemo(() => {
    if (campaigns) {
      const items = campaigns.map((x) => ({ name: x.name, id: x.url }));
      return [
        { name: t('GENERAL.STEP_FILTERS.NO_CAMPAIGN.label'), id: 'no campaign' },
        { name: t('GENERAL.STEP_FILTERS.ANY_CAMPAIGN.label'), id: 'any campaign' },
        ...items,
      ];
    } else {
      return [];
    }
  }, [campaigns, t]);

  return campaignOptions;
};

export const useUpdateQueryStringValueWithoutNavigation = (serializedFilters: string) => {
  useEffect(() => {
    // Skip delete defines which one should not be deleted on opening dashboard sidenav
    const SKIP_DELETE = ['onboardingStartDate', 'onboardingEndDate'];
    const currentSearchParams = new URLSearchParams(window.location.search);
    const newSearchParams = new URLSearchParams(serializedFilters);

    const deleteParamWithGuard = (key: string) => {
      if (SKIP_DELETE.includes(key)) return;

      currentSearchParams.delete(key);
    };

    let hasChanged = false;

    for (const [key, value] of newSearchParams.entries()) {
      if (currentSearchParams.get(key) !== value) {
        hasChanged = true;

        if (value) {
          currentSearchParams.set(key, value);
        } else {
          deleteParamWithGuard(key);
        }
      }
    }

    for (const key of currentSearchParams.keys()) {
      if (!newSearchParams.has(key)) {
        hasChanged = true;
        deleteParamWithGuard(key);
      }
    }

    if (hasChanged) {
      const newUrl = [window.location.pathname, currentSearchParams.toString()].filter(Boolean).join('?');
      window.history.replaceState(null, '', newUrl);
    }
  }, [serializedFilters]);
};
