import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';

export const Loader: React.FC<{ isLoading?: boolean } & CircularProgressProps> = ({ isLoading = true, ...rest }) => {
  if (!isLoading) return null;

  return (
    <div className="loader-centred">
      <CircularProgress {...rest} />
    </div>
  );
};
