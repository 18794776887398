import { ThemeOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    fullHd?: true;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    adeccoRed: string;
  }
}

export const defaultTheme: Partial<ThemeOptions> = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 672,
      md: 768,
      lg: 1024,
      xl: 1440,
      fullHd: 1920,
    },
  },
  palette: {
    common: {
      black: '#030C18',
      white: '#fff',
      adeccoRed: '#DA291C',
    },
    background: {
      default: '#fff',
    },
    primary: {
      main: '#1C304B',
      contrastText: '#fff',
      light: '#5CB8B2',
    },
    secondary: {
      main: '#CED7E4',
      contrastText: '#fff',
    },
    text: {
      primary: '#273446',
      secondary: '#5C6573',
      disabled: '#CED7E4',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    info: {
      main: '#367a9b',
      dark: '#107B9E',
    },
    success: {
      main: '#73D85A',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '16px !important',
          border: '1px solid #1C304B !important',
          '&::placeholder': {
            color: '#5C6573 !important',
          },
          '& .MuiAutocomplete-inputRoot': {
            border: 'none !important',
          },
          borderRadius: '8px',
          ':disabled': {
            color: '#ADADB5 !important',
            WebkitTextFillColor: '#ADADB5',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#1C304B',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          marginRight: '16px',
        },
        select: {
          borderRadius: '8px',
          '&:focus': {
            borderRadius: '8px',
          },
          '&.Mui-disabled': {
            color: '#ADADB5 !important',
            WebkitTextFillColor: '#ADADB5',
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        style: {
          transform: 'none',
          backgroundColor: 'transparent',
        },
        shrink: true,
      },
      styleOverrides: {
        formControl: {
          transform: 'none',
          marginTop: '-8px !important',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: '#5C6573 !important',
          },
        },
        underline: {
          '&:before': {
            border: 'none !important',
          },
          '&:after': {
            border: 'none !important',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&.Mui-checked': {
            color: '#222',
          },
        },
        track: {
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: '#111',
            opacity: 0.6,
          },
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        style: {
          fontSize: '14px !important',
        },
      },
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          paddingTop: '16px',
          paddingBottom: '16px',
          '& .MuiSwitch-root': {
            marginTop: '-7px',
          },
          '& .MuiRadio-root, & .MuiCheckbox-root': {
            marginTop: '-10px',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            '& $line': {
              borderColor: '#73D85A',
            },
          },
          '&.Mui-completed': {
            '& $line': {
              borderColor: '#73D85A',
            },
          },
          '&.Mui-line': {
            borderColor: '#1C304B',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiAutocomplete-inputRoot': {
            paddingRight: '0 !important',
          },
          '& .MuiAutocomplete-endAdornment': {
            paddingRight: '16px',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { marginLeft: 0, marginBottom: 4 },
        contained: { marginLeft: 0, marginRight: 0 },
      },
      defaultProps: {
        style: {
          fontWeight: 'normal',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-text': {
            display: 'none',
          },
          '&.Mui-active': {
            color: 'transparent !important',
            border: '1px solid #1C304B !important',
            borderRadius: '50%',
          },
          '&.Mui-root': {
            color: 'transparent',
            border: '1px solid #CED7E4 !important',
            borderRadius: '50%',
          },
          '&.Mui-completed': {
            color: '#73D85A !important',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        fullWidth: true,
        style: {
          borderRadius: '8px',
          padding: '16px',
        },
        disableElevation: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true,
        style: {
          border: '1px solid #030C18 !important',
          borderRadius: '8px !important',
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiContainer: {
      defaultProps: {
        style: {
          padding: 0,
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#273446',
      fontWeight: 400,
      letterSpacing: 0,
    },
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontSize: 64,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 2.8,
    },
    h2: {
      fontSize: 40,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.4,
      letterSpacing: 2,
    },
    h3: {
      fontSize: 24,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0,
    },
    h4: {
      fontSize: 18,
      fontWeight: 900,
      lineHeight: 1.22,
      letterSpacing: 1,
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 1.29,
      letterSpacing: 0.7,
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    h6: {
      fontSize: 12,
      fontWeight: 'bold',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: 1.35,
      letterSpacing: 0.5,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.25,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.33,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 'normal',
    },
    body2: {
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
    },
    caption: {
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0.2,
    },
  },
};
