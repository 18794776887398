import { Version } from '@features/oneOnboardingAdmin/components/VersionPicker/VersionPicker';
import {
  FeatureConfigurationData,
  FeatureConfigurationModel,
  FeatureConfigurationName,
} from '@models/featureConfiguration.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiCallStatus } from '../apiCallStatus';

interface FeatureConfigurationStoreState {
  b2c: FeatureConfigurationData<FeatureConfigurationName.b2c>;
  adeccoAd: FeatureConfigurationData<FeatureConfigurationName.adeccoAd>;
  appSettings: FeatureConfigurationData<FeatureConfigurationName.appSettings>;
  documents: FeatureConfigurationData<FeatureConfigurationName.documents>;
  fetchFeatureConfigurationsStatus: ApiCallStatus;
}

const initialState: any = {
  b2c: {},
  adeccoAd: {},
  appSettings: {},
  fetchFeatureConfigurationStatus: ApiCallStatus.none,
};

export const featureConfigurationSlice = createSlice({
  name: 'featureConfiguration',
  initialState: initialState as FeatureConfigurationStoreState,
  reducers: {
    setStatus: (state, action: PayloadAction<{ name: string; value: ApiCallStatus }>) => {
      (state as any)[action.payload.name] = action.payload.value;
      return state;
    },
    setFeatureConfigurations: (state, action: PayloadAction<FeatureConfigurationModel[]>) => {
      action.payload.forEach((fc) => {
        switch (fc.name) {
          case FeatureConfigurationName.b2c:
            state.b2c = fc.data;
            break;
          case FeatureConfigurationName.adeccoAd:
            state.adeccoAd = fc.data;
            break;
          case FeatureConfigurationName.appSettings:
            state.appSettings = fc.data;
            break;
          case FeatureConfigurationName.documents:
            state.documents = fc.data;
            break;
          default:
        }
      });

      return state;
    },
    setDashboardVersion: (state, action: PayloadAction<Version>) => {
      state.appSettings.searchOnboardingEndpointVersion = action.payload;
      return state;
    },
  },
});
