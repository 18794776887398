import React from 'react';

interface SortingButtonProps {
  onClick: () => void;
  direction: string;
  active: boolean;
}

export const SortingButton: React.FC<SortingButtonProps> = ({ onClick, direction, active }) => {
  const isActive = active ? 'feature-dashboard__table-sorting--active' : 'feature-dashboard__table-sorting';

  return (
    <span
      className={`material-icons ${isActive}`}
      style={{
        transform: direction === 'asc' ? 'rotate(180deg)' : 'rotate(0)',
        position: 'absolute',
        left: 2,
      }}
      onClick={onClick}
    >
      arrow_downward
    </span>
  );
};
