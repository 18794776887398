import React from 'react';
import LabelWithTooltip from '@features/selfService/components/LabelWithTooltip/LabelWithTooltip';

interface Props {
  value: string;
  setFieldValue: (value: string) => void;
  onChange: (value: string) => void;
  disabled: boolean;
}

const MultiSelectType: React.FC<Props> = ({ value, setFieldValue, onChange, disabled }) => {
  const options = [
    {
      value: 'CheckboxMultiSelect',
      label: 'Checkboxes',
    },
    {
      value: 'MultipleSelect',
      label: 'Drop down',
    },
  ];

  return (
    <div>
      <LabelWithTooltip tooltip="Choose a type of Multiple select" labelProps={{ classname: 'subtitle' }}>
        Multiselect type
      </LabelWithTooltip>
      <div className="tag-ds">
        {options.map((option, index) => {
          return (
            <div key={index} className="radio-container">
              <input
                id={option.value}
                type="radio"
                onChange={(e: any) => {
                  onChange(e);
                  setFieldValue(e.target.value);
                }}
                name="multipleSelectComponentType"
                checked={option.value === value}
                value={option.value}
                disabled={disabled}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelectType;
