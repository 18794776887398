import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contacts } from '@features/app/ContactUs/useContactDetails';
import { Box } from '@mui/material';

interface ContactDetailsProps {
  contactUs: Contacts | undefined;
  help: string;
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({ contactUs, help }) => {
  const { t } = useTranslation('candidate');
  return (
    <>
      {contactUs?.name && (
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Box display="flex" style={{ alignItems: 'center', paddingRight: '10px' }}>
            <span className="material-icons" style={{ marginRight: 8 }}>
              badge
            </span>
            <p className="tag-ds  base">{t('candidate:GENERAL.CONTACT_US.name')}</p>
          </Box>
          <Box>
            <p className="tag-ds" style={{ fontWeight: 500 }}>
              {contactUs.name}
            </p>
          </Box>
        </Box>
      )}
      {contactUs?.address && (
        <>
          <div className="separator" />
          <Box display="flex" style={{ alignItems: 'center' }}>
            <Box display="flex" style={{ alignItems: 'center', paddingRight: '10px' }}>
              <span className="material-icons" style={{ marginRight: 8 }}>
                home
              </span>
              <p className="tag-ds  base">{t('candidate:GENERAL.CONTACT_US.address')}</p>
            </Box>
            <Box>
              <p className="tag-ds" style={{ fontWeight: 500 }}>
                {contactUs.address}
              </p>
            </Box>
          </Box>
          <div className="separator" />
        </>
      )}
      {contactUs?.phoneNumber && (
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Box display="flex" style={{ alignItems: 'center', paddingRight: '10px' }}>
            <span className="material-icons" style={{ marginRight: 8 }}>
              phone
            </span>
            <p className="tag-ds  base">{t('candidate:GENERAL.CONTACT_US.phoneNumber')}</p>
          </Box>
          <Box>
            <a href={`tel:${contactUs.phoneNumber}`} className="header__link">
              {contactUs.phoneNumber}
            </a>
          </Box>
        </Box>
      )}
      <div className="separator" />
      {contactUs?.email && (
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Box display="flex" style={{ alignItems: 'center', paddingRight: '28px' }}>
            <span className="material-icons" style={{ marginRight: 8 }}>
              email
            </span>
            <p className="tag-ds  base">{t(`candidate:GENERAL.CONTACT_US.${help ? 'help' : 'email'}`)}</p>
          </Box>
          <Box>
            <>
              {help ? (
                <a
                  href={`${help}`}
                  className="header__link"
                  target="_blank"
                  style={{ textTransform: 'none' }}
                  rel="noreferrer"
                >
                  {help}
                </a>
              ) : (
                <a href={`mailto:${contactUs.email}`} className="header__link" style={{ textTransform: 'none' }}>
                  {contactUs.email}
                </a>
              )}
            </>
          </Box>
        </Box>
      )}
      <div className="separator" />
      {contactUs?.url.href && (
        <Box display="flex" style={{ alignItems: 'center' }}>
          <Box display="flex" style={{ alignItems: 'center', paddingRight: '10px' }}>
            <span className="material-icons" style={{ marginRight: 8 }}>
              web
            </span>
            <p className="tag-ds  base">{t('candidate:GENERAL.CONTACT_US.website')}</p>
          </Box>
          <Box>
            <a
              href={
                contactUs?.url?.href?.startsWith('https://') ? contactUs?.url?.href : `https://${contactUs?.url?.href}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="header__link"
              style={{ textTransform: 'none' }}
            >
              {contactUs?.url?.label}
            </a>
          </Box>
        </Box>
      )}
    </>
  );
};
