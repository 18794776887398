import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { Divider } from '@mui/material';
import { store } from '@store/index';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';

import { OOFormControl } from '../../../oneOnboarding/controls/FormControl';
import { Component } from '../../../oneOnboarding/interfaces';
import { OOControlModel } from '../../../oneOnboarding/models/ControlModel';
import { OOPageModel } from '../../../oneOnboarding/models/FormModel';
import { OOFlowWrapper } from '../../../oneOnboarding/wrappers/FlowWrapper';
import { catalog } from './catalog';
import { OOImageComponentMOCK } from './mocks/OOImageComponentMOCK';

import style from './ControlCatalog.module.scss';

const ControlCatalog = () => {
  const [uiForm, setUiForm] = useState<{ form: any; flowWrapper: any }>({ form: null, flowWrapper: null });
  const [searchTerm, setSearchTerm] = useState('');

  const history = useHistory();
  const formikRef = useRef<any>();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'p-env') {
      history.push('/oo/recruiter');
    }
  }, [history]);

  useEffect(() => {
    store.dispatch(oneOnboardingSlice.actions.setClientOrganizationId('00000000-0000-4000-0000-000000000001'));
    store.dispatch(oneOnboardingSlice.actions.setConfigurationId('00000000-0000-4000-0000-000000000001'));
  }, []);

  useEffect(() => {
    const handleFlowWrapper = (sc: any) => {
      if (!sc) return;

      const getAllSteps = sc?.features.flatMap((x: any) => {
        return x.steps.map((y: any) => y);
      });

      const wrap = OOFlowWrapper.create(getAllSteps);

      const findForm = wrap?.steps
        .find((x: any) => x.name === 'CANDIDATE_DETAILS')
        ?.pages.find((y: any) => y.name === 'PERSONAL_INFO');

      return {
        form: findForm,
        flowWrapper: wrap,
      };
    };

    const getUIform = () => handleFlowWrapper(catalog);

    setUiForm(getUIform() as any);
  }, []);

  const page = useMemo(() => {
    return new OOPageModel({ ...uiForm.form, isLastPage: false, isMandatory: true });
  }, [uiForm.form]);

  const isCandidateControl = (control: OOControlModel) => {
    return (
      control.isVisibleCandidate &&
      !control.component?.toLowerCase().includes('submit') &&
      !control.component?.includes(Component.DismissButton)
    );
  };

  const mockedControls = (control: any) => [
    { name: Component.Image, component: <OOImageComponentMOCK controlModel={control} /> },
  ];

  const renderValue = (value: any) => {
    switch (typeof value) {
      case 'string':
        return value;
      case 'number':
        return value.toString();
      case 'boolean':
        return value.toString();
      case 'object':
        return JSON.stringify(value);
      default:
        return value;
    }
  };

  const controlList = useMemo(() => {
    const list = page.controls;

    if (searchTerm) {
      return list.filter((x) => x.component?.toLowerCase().includes(searchTerm.toLowerCase()));
    }

    return list;
  }, [searchTerm, page.controls]);

  return (
    <PageWrapper
      pageName="control-catalog"
      featureName="control-catalog"
      headerConfig={{
        pageName: 'Control Catalog',
        onBack: () => {
          history.push('/oo/recruiter');
        },
      }}
    >
      <div className={style.container}>
        <div className={`"tag-ds input-wrapper" ${style.searchComponent}`}>
          <label className="caption">Search for component</label>
          <div className="input-container">
            <input
              className=""
              placeholder="Search for component"
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <span className="material-icons input-icon-box">search</span>
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          innerRef={formikRef}
          validateOnChange={true}
          initialValues={{}}
          onSubmit={() => {
            return;
          }}
        >
          <div>
            {uiForm &&
              uiForm.flowWrapper &&
              controlList
                .filter((c) => isCandidateControl(c))
                .map((control: OOControlModel, index) => {
                  const controlSetting: any = catalog.features[0].steps[0].pages[0].controls.find(
                    (x) => x.name === control.name,
                  );

                  const mock = mockedControls(control).find((x) => x.name === control.component);

                  return (
                    <div className={style.item} key={index}>
                      <p style={{ marginTop: 0 }}>{control.component}</p>
                      <Divider />
                      <div style={{ display: 'flex', marginTop: 16 }}>
                        <div style={{ flex: 1, marginRight: 32 }}>
                          {mock ? (
                            mock.component
                          ) : (
                            <OOFormControl
                              control={control}
                              otherFormControls={page.controls}
                              filterSubControls={(control) => isCandidateControl(control)}
                            />
                          )}
                        </div>
                        {controlSetting && (
                          <div style={{ width: 400 }}>
                            {Object.keys(controlSetting).map((x: any, index) => {
                              return (
                                <p style={{ marginBottom: 4, marginTop: 0, wordBreak: 'break-all' }} key={index}>
                                  <strong>{x}</strong>: {renderValue(controlSetting[x])}
                                </p>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        </Formik>
      </div>
    </PageWrapper>
  );
};

export default ControlCatalog;
