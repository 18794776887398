import React, { useEffect, useState } from 'react';
import { Toggle } from '@adeccoux/tag-ds';
import LabelWithTooltip from '@features/selfService/components/LabelWithTooltip/LabelWithTooltip';

interface Props {
  value: string;
  setFieldValue: (name: string, value: any, shouldValidate?: boolean) => void;
  customHandler?: (value: { regexValidation: string; regExpValidationError: string }) => void;
  disabled: boolean;
}

export enum RegexValues {
  names = "^^[a-zA-ZÀ-ž '.-]+$",
  emails = '^[w-.]+@([w-]+.)+[w-]{2,4}$',
  phoneNumbers = '^s*(?:+?(d{1,3}))?([-. (](d{3})[-. )])?((d{3})[-. ](d{2,4})(?:[-.x ](d+))?)s*$',
  numbers = '^[0-9]*$',
}

const RegexValidation: React.FC<Props> = ({ value, setFieldValue, customHandler, disabled }) => {
  const [extended, setExtended] = useState(false);

  useEffect(() => {
    if (value) {
      setExtended(true);
    }
  }, [value]);

  const options = [
    {
      value: 'names',
      label: 'Names',
      onChange: function (val: any) {
        setFieldValue('regexValidation', RegexValues.names, true);
        setFieldValue('regExpValidationError', 'Wrong format', true);

        customHandler?.({ regexValidation: RegexValues.names, regExpValidationError: 'Wrong format' });
      },
    },
    {
      value: 'emails',
      label: 'Emails',
      onChange: function (val: any) {
        setFieldValue('regexValidation', RegexValues.emails, true);
        setFieldValue('regExpValidationError', 'Email address must include @ and the domain', true);
        customHandler?.({
          regexValidation: RegexValues.emails,
          regExpValidationError: 'Email address must include @ and the domain',
        });
      },
    },
    {
      value: 'phoneNumbers',
      label: 'Phone numbers',
      onChange: function (val: any) {
        setFieldValue('regexValidation', RegexValues.phoneNumbers, true);
        setFieldValue('regExpValidationError', 'Wrong format of phone number', true);

        customHandler?.({
          regexValidation: RegexValues.phoneNumbers,
          regExpValidationError: 'Wrong format of phone number',
        });
      },
    },
    {
      value: 'numbers',
      label: 'Numbers only',
      onChange: function (val: any) {
        setFieldValue('regexValidation', RegexValues.numbers, true);
        setFieldValue('regExpValidationError', 'Only numbers are allowed', true);

        customHandler?.({
          regexValidation: RegexValues.numbers,
          regExpValidationError: 'Only numbers are allowed',
        });
      },
    },
  ];

  function getKeyByValue(value: string) {
    const keys = Object.keys(RegexValues) as (keyof typeof RegexValues)[];

    const foundKey = keys.find((key) => {
      return value === RegexValues[key];
    });

    return foundKey || '';
  }

  return (
    <div>
      <div className="tag-ds" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <LabelWithTooltip
          tooltip="Select a preset type of input that the candidate is allowed to enter."
          labelProps={{ classname: 'subtitle' }}
        >
          Validation with regular expression
        </LabelWithTooltip>
        <Toggle
          aria-label="meaningful label for accessibility purposes"
          checked={extended}
          id="defaultChecked"
          onChange={(val) => {
            if (val === false) {
              setFieldValue('regexValidation', '', true);
              setFieldValue('regExpValidationError', '', true);
            }
            setExtended(val);
          }}
          disabled={disabled}
        />
      </div>
      {extended && (
        <div style={{ marginTop: 12 }}>
          <label className="caption">Validation with regular expression</label>
          <div>
            {options.map((option, index) => {
              return (
                <div key={index} className="radio-container">
                  <input
                    id={option.value}
                    type="radio"
                    onChange={(e) => option.onChange(e)}
                    name="regexValidation"
                    checked={option.value === getKeyByValue(value)}
                    value={option.value}
                    disabled={disabled}
                  />
                  <label htmlFor={option.value}>{option.label}</label>
                </div>
              );
            })}
            <div className="radio-container" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="radio-container" style={{ marginTop: 0 }}>
                <input
                  id="customRegex"
                  type="radio"
                  onChange={(e) => {
                    setFieldValue('regexValidation', '', true);
                    setFieldValue('regExpValidationError', 'Wrong format', true);

                    customHandler?.({
                      regexValidation: '',
                      regExpValidationError: 'Wrong format',
                    });
                  }}
                  name="regexValidation"
                  checked={getKeyByValue(value) === ''}
                  value="customRegex"
                  disabled={disabled}
                />
                <label htmlFor="customRegex">Custom regular expression</label>
              </div>
              {getKeyByValue(value) === '' && (
                <input
                  placeholder="Custom regular expression"
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setFieldValue('regexValidation', e.target.value, true);
                    customHandler?.({
                      regexValidation: e.target.value,
                      regExpValidationError: 'Wrong format',
                    });
                  }}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegexValidation;
