import React from 'react';

interface Props {
  item: { type: string; value: string };
}

const StaticComponent: React.FC<Props> = ({ item }) => {
  const getIcon = (icon: string) => {
    const hash: { [key: string]: string } = {
      info: 'info',
    };

    if (hash[icon]) return hash[icon];

    return '';
  };

  return (
    <div style={{ marginBottom: 32, display: 'flex' }}>
      <div style={{ marginRight: 8 }}>
        <span className="material-icons">{getIcon(item.type)}</span>
      </div>
      <div>
        <span className="medium">{item.value}</span>
      </div>
    </div>
  );
};

export default StaticComponent;
