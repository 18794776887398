import React from 'react';
import { TextField } from '@mui/material';

export interface OptionProps {
  name: string;
  value: string | number;
}

interface SelectDropdownProps {
  value: string | number;
  onChange: (e: any) => void;
  options?: OptionProps[];
  title: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  id?: string;
  optional?: boolean;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  value,
  onChange,
  options = [],
  title,
  name,
  disabled = false,
  error = false,
  helperText,
  id,
  optional = false,
}) => {
  return (
    <TextField
      select
      value={value ?? ''}
      name={name}
      onChange={onChange}
      SelectProps={{ native: true }}
      disabled={disabled}
      error={error}
      helperText={helperText}
      id={id}
    >
      <option disabled={!optional} value="">
        {title}
      </option>
      {options.map((el: OptionProps, index: number) => (
        <option key={el.value + '-' + index} value={el.value}>
          {el.name}
        </option>
      ))}
    </TextField>
  );
};
