import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

import RichTextEditor from './RichTextEditor';

interface Props {
  translationValues: { [key: string]: string };
  htmlValue: string;
  setFieldValue: any;
  name: string;
  label: string;
  disabled: boolean;
}

const RichTextEditorContainer: React.FC<Props> = ({
  htmlValue,
  setFieldValue,
  name,
  translationValues,
  label,
  disabled,
}) => {
  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  const availableLanguages = selectedConfiguration?.languages;

  return (
    <div>
      {availableLanguages?.supported.map((l, i) => {
        return (
          <div key={i}>
            <label className="caption">Value for {l}</label>
            <RichTextEditor
              key={i}
              setFieldValue={setFieldValue}
              htmlValue={htmlValue}
              translationValues={translationValues}
              language={l}
              name={name}
              label={label}
              disabled={disabled}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RichTextEditorContainer;
