import _ from 'lodash';

import { ConfigurationDetails } from '../models/configurationModels';

export const getNextSequentialUuid = (currentUuid: string | undefined) => {
  if (!currentUuid) {
    currentUuid = '00000000-0000-4000-0000-000000000000';
  }

  const guidParts = currentUuid ? currentUuid?.split('-') : [];
  const lastDozen = guidParts[guidParts.length - 1];
  const nextValue = (+lastDozen + 1).toString().padStart(12, '0');
  const newParts = [...guidParts.slice(0, guidParts.length - 1)];
  newParts.push(nextValue);
  return newParts.join('-');
};

export const getDefaultStartingUuid = () => {
  return '00000000-0000-4000-0000-000000000001';
};

export const findDifference = (a: any, b: any) => {
  return _.reduce(
    a,
    function (result, value, key) {
      return _.isEqual(value, b[key]) ? result : result.concat(key as any);
    },
    [],
  );
};

// Used for filling redux with informations that are requried for loading configuration in FormBuilder
export const remapValuesForConfigurationLevel = (formValues: { id: string; name: string; configurationId: string }) => {
  const result: ConfigurationDetails = {
    clientOrganization: {
      id: formValues.id,
      name: formValues.name,
      displayName: formValues.name,
    },
    configurationId: formValues.configurationId,
    candidateName: formValues.name,
    recruiterName: formValues.name,
  };

  return result;
};
