import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { store } from '@store/index';

import { editableCondition } from '../../hooks/useRenderCondition';
import { OODateControlModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { OODatePicker } from './OODatePicker';

export const DatePickerComponent: React.VoidFunctionComponent<OODateControlModelInterface> = (props) => {
  const {
    isEditableRecruiter,
    isEditableCandidate,
    name,
    label,
    maxOffset,
    minOffset,
    isMandatory,
    onChangeCallback,
    defaultValue,
  } = props;
  const flowData = store.getState().oneOnboarding.flow;
  const formik = useFormikContext<Record<string, any>>();

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isEditable = editableCondition(props, flowWrapper, formik.values) ?? true;

  return (
    <OODatePicker
      disabled={!(flowWrapper?.isControlEditable(isEditableCandidate, isEditableRecruiter) ?? true) || !isEditable}
      validationKey={name}
      name={name}
      label={label}
      maxOffset={maxOffset}
      minOffset={minOffset}
      isMandatory={isMandatory}
      onChangeCallback={onChangeCallback}
      defaultValue={defaultValue}
    />
  );
};
