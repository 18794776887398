import React from 'react';
import clsx from 'clsx';

import styles from './DocumentToSignControls.module.scss';

interface Props {
  item: any;
  index: number;
  handleDelete: () => void;
  handleChange: (fieldName: string, value: any) => void;

  error: any;
}

const controlFields = [
  { key: 'name', displayName: 'Name', type: 'text' },
  { key: 'category', displayName: 'Category', type: 'text' },
  { key: 'isMandatory', displayName: 'Is mandatory', type: 'checkbox' },
  { key: 'type', displayName: 'Type', type: 'text' },
  { key: 'component', displayName: 'Component', type: 'text' },
  { key: 'sourceClass', displayName: 'Source class', type: 'text' },
  { key: 'sourceField', displayName: 'Source field', type: 'text' },
];

const DocumentToSignControl: React.FC<Props> = ({ item, index, handleDelete, handleChange, error }) => {
  const handleError = (fieldName: string) => {
    if (!error)
      return {
        isError: false,
        errorMessage: '',
      };

    return {
      isError: error[fieldName] ? true : false,
      errorMessage: error[fieldName],
    };
  };

  return (
    <div style={{ marginTop: 16, position: 'relative' }} className={styles.item}>
      <div className={styles.item__header}>
        <span className={clsx('subtitle', styles['item__header-title'])}>Control {index + 1}</span>
        <div className={styles.item__icon} onClick={handleDelete}>
          <span className="material-icons-outlined" style={{ fontSize: 16 }}>
            delete
          </span>
        </div>
      </div>
      <div>
        {controlFields.map((control, i) => {
          return (
            <div style={{ marginBottom: 12, width: 320 }} key={i}>
              <label>{control.displayName}</label>
              <input
                type={control.type}
                value={item[control.key]}
                onChange={(e) => handleChange(control.key, e.target.value)}
              />
              {handleError(control.key).isError && (
                <p className="input-helper-text" style={{ margin: 0 }}>
                  {handleError(control.key).errorMessage}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentToSignControl;
