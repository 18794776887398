import { useMemo } from 'react';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { CandidateJourney } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/InformationList';
import { useGetCountryById, useGetJourneysByTenant, useGetMuncipalities, useGetProvinces } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { UserProfileModel } from '@models/userProfile.model';

interface UseUserInfoResult {
  phoneNumber: string;
  address: string;
  client: string;
  clientDisplayName: string;
  journey: CandidateJourney;
  dateOfBirth: Date | null;
}

export function useUserInfo(
  tenantId: number,
  flowWrapper: OOFlowWrapper,
  userProfile: UserProfileModel | undefined,
): UseUserInfoResult {
  const { clientOrganizationId, configurationId } = useDecodedParams();
  const countryId = useMemo(() => flowWrapper.getUserValueOrDefault(['COUNTRY', 'COUNTRY_OF_BIRTH'], 'string'), [
    flowWrapper,
  ]) as string;
  const { data: country } = useGetCountryById(countryId);
  const { data: provinces } = useGetProvinces({}, clientOrganizationId, configurationId);
  const { data: journeys } = useGetJourneysByTenant(tenantId);

  const muncipalityId = flowWrapper.getUserValueOrDefault('MUNICIPALITY', 'string');
  const provinceId = flowWrapper.getUserValueOrDefault('PROVINCE', 'string');

  const { data: muncipalities } = useGetMuncipalities(
    { 'customData.province': provinceId, initialValue: muncipalityId },
    clientOrganizationId,
    configurationId,
  );

  const phoneNumber = useMemo(() => {
    const phoneCode = flowWrapper.getUserValueOrDefault('PHONE_CODE', 'string') as string;
    const phoneStr = flowWrapper.getUserValueOrDefault('PHONE', 'string') as string;
    const phoneFloat = Number.parseFloat(phoneStr);
    const phoneValue = Number.isNaN(phoneFloat) ? phoneStr : phoneFloat.toString(10);

    return phoneCode
      ? phoneCode?.startsWith('+')
        ? `${phoneCode} ${phoneValue}`
        : `+${phoneCode} ${phoneValue}`
      : phoneValue;
  }, [flowWrapper]) as string;
  const muncipalityLabel = muncipalities?.find((m) => m.value === muncipalityId)?.label;
  const provinceLabel = provinces?.find((p) => p.value === provinceId)?.label;
  const countryLabel = useMemo(() => country?.[0]?.label || '', [country]);

  const street = flowWrapper.getUserValueOrDefault(['STREET', 'STREET_STRING'], 'string');
  const streetNumber = flowWrapper.getUserValueOrDefault('STREET_NUMBER', 'string');
  const addressLine1 = flowWrapper.getUserValueOrDefault(['ADDRESSLINE1', 'ADDRESSLINE2'], 'string');
  const streetAddress = street && streetNumber ? `${street} ${streetNumber}` : street || addressLine1;
  const postCode = flowWrapper.getUserValueOrDefault(['POST_CODE', 'ZIP_CODE', 'POST_CODE_STRING'], 'string');
  const city = flowWrapper.getUserValueOrDefault(['CITY', 'TOWN', 'CITY_STRING'], 'string') || userProfile?.city;
  const dateOfBirth = flowWrapper.getUserValueOrDefault('DATE_OF_BIRTH', 'date') || userProfile?.dateOfBirth;

  const houseNumber = flowWrapper.getUserValueOrDefault('HOUSE_NUMBER', 'string')
    ? ` ${flowWrapper.getUserValueOrDefault('HOUSE_NUMBER', 'string')}`
    : '';
  const address = useMemo(
    () =>
      streetAddress +
      (houseNumber ? ' ' + houseNumber : '') +
      (postCode ? ', ' + postCode : '') +
      (!muncipalityLabel ? (city ? ', ' + city : '') : '') +
      (muncipalityLabel ? ', ' + muncipalityLabel : '') +
      (provinceLabel ? ', ' + provinceLabel : '') +
      (countryLabel ? ', ' + countryLabel : ''),
    [houseNumber, streetAddress, postCode, city, countryLabel, muncipalityLabel, provinceLabel],
  );

  const clientOrganization: any = useMemo(
    () => journeys?.find((c: any) => c.clientOrganizationId === clientOrganizationId && c.id === configurationId),
    [clientOrganizationId, configurationId, journeys],
  );
  const journey = useMemo(() => clientOrganization?.recruiterName || '', [clientOrganization]);

  const client = useMemo(() => clientOrganization?.clientOrganization?.name || '', [clientOrganization]);
  const clientDisplayName = useMemo(() => clientOrganization?.clientOrganization?.displayName || '', [
    clientOrganization,
  ]);

  return {
    phoneNumber,
    address,
    client,
    clientDisplayName,
    journey,
    dateOfBirth,
  };
}
