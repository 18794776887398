import { OOUserDocumentModel } from '@features/oneOnboarding/models/UserDocumentModel';

import { OODocumentations, OOPages, OOSteps, StepStatusEnum } from '../interfaces';
import { OODocumentationModel } from './DocumentationModel';
import { OOPageModel } from './FormModel';
import { OOStepElementModel } from './StepElementModel';

export enum StepRole {
  candidate = 'candidate',
  recruiter = 'recruiter',
  recruiterOrCandidate = 'candidate-or-recruiter',
  system = 'system',
}

export interface DocumentsForSignatureModel {
  name: string;
  isRecruiterSignatureRequired: boolean;
  type: string;
  label: string;
  stepId: string;
  controls: any[];
  sequence: number;
  description: string;
  signatureAnchors: any[];
  needPersonalization: boolean;
  sendIfHasNoSignature: boolean;
  isCandidateSignatureRequired: boolean;
  userDocuments: OOUserDocumentModel[];
  additionalData?: any;
}

interface ConstructorParams {
  name: OOSteps;
  label: string;
  recruiterLabel: string;
  nextSteps: OOSteps[];
  elements: OOStepElementModel[];
  feature: string;
  duration: number;
  durationLabel: string;
  description: string;
  pages?: OOPageModel[];
  documentations?: OODocumentationModel[];
  documentsForSignature?: any[];
  role: StepRole;
  hideStatus: boolean;
  skipStep?: boolean;
  stepAutoComplete?: boolean;
  isVisibleCandidate?: boolean;
  isVisibleRecruiter?: boolean;
  minOptionalDocumentations?: number;
  uploadEarly?: boolean;
  completeCondition?: Array<Record<string, any>>;
  minOptionalDocumentationErrorMessage?: string;
}

export class OOStepModel {
  public name: OOSteps;
  public label: string;
  public recruiterLabel: string;
  public nextSteps: OOSteps[];
  public feature: string;
  public duration: number;
  public durationLabel: string;
  public description: string;
  public elements: OOStepElementModel[];
  public pages: OOPageModel[];
  public documentations: OODocumentationModel[];
  public documentsForSignature: DocumentsForSignatureModel[];
  public role: StepRole;
  public hideStatus: boolean;
  public status?: StepStatusEnum;
  public hide?: boolean;
  public skipStep?: boolean;
  public stepAutoComplete?: boolean;
  public isVisibleCandidate?: boolean;
  public isVisibleRecruiter?: boolean;
  public uploadEarly?: boolean;
  public minOptionalDocumentations?: number;
  public completeCondition?: Array<Record<string, any>>;
  public minOptionalDocumentationErrorMessage?: string;

  constructor({
    name,
    label,
    recruiterLabel,
    nextSteps,
    feature,
    description,
    elements,
    pages,
    documentations,
    documentsForSignature,
    role,
    hideStatus,
    duration,
    durationLabel,
    skipStep,
    stepAutoComplete,
    isVisibleCandidate,
    isVisibleRecruiter,
    minOptionalDocumentations,
    uploadEarly,
    completeCondition,
    minOptionalDocumentationErrorMessage,
  }: ConstructorParams) {
    this.name = name;
    this.label = label;
    this.recruiterLabel = recruiterLabel;
    this.nextSteps = nextSteps;
    this.skipStep = skipStep;
    this.feature = feature;
    this.description = description;
    this.elements = elements;
    this.duration = duration;
    this.durationLabel = durationLabel;
    this.pages = pages ?? [];
    this.documentations = documentations ?? [];
    this.documentsForSignature = documentsForSignature ?? [];
    this.role = role;
    this.hideStatus = hideStatus;
    this.stepAutoComplete = stepAutoComplete;
    this.isVisibleCandidate = isVisibleCandidate;
    this.isVisibleRecruiter = isVisibleRecruiter;
    this.minOptionalDocumentations = minOptionalDocumentations;
    this.uploadEarly = uploadEarly;
    this.completeCondition = completeCondition;
    this.minOptionalDocumentationErrorMessage = minOptionalDocumentationErrorMessage;
  }

  public static fromJson(json: any) {
    const {
      name,
      label,
      recruiterLabel,
      nextSteps,
      feature,
      description,
      elements,
      role,
      documentsForSignature,
      hideStatus,
      duration,
      durationLabel,
      skipStep,
      stepAutoComplete,
      isVisibleCandidate,
      isVisibleRecruiter,
      minOptionalDocumentations,
      uploadEarly,
      completeCondition,
      minOptionalDocumentationErrorMessage,
    } = json;
    const pages = (json.pages || []).map(OOPageModel.fromJson);
    const documentations = (json.documentations || []).map(OODocumentationModel.fromJson);
    return new OOStepModel({
      name,
      label,
      recruiterLabel,
      nextSteps,
      feature,
      description,
      elements,
      pages: pages,
      documentations,
      role,
      documentsForSignature,
      hideStatus,
      duration,
      durationLabel,
      skipStep,
      stepAutoComplete,
      isVisibleCandidate,
      isVisibleRecruiter,
      minOptionalDocumentations,
      uploadEarly,
      completeCondition,
      minOptionalDocumentationErrorMessage,
    });
  }

  public getForm(formId: any) {
    return this.pages.find((form) => form.name === formId);
  }

  public getFirstForm() {
    if (this.pages.length === 0) return null;
    return this.pages[0];
  }

  public getLastForm() {
    if (this.pages.length === 0) {
      return null;
    }
    return this.pages[this.pages.length - 1];
  }

  public getNextForm(formName: OOPages | null) {
    if (!formName) return null;
    const index = this.pages.findIndex((form) => form.name === formName);
    if (index === -1 || index === this.pages.length - 1) {
      return null;
    }
    return this.pages[index + 1];
  }

  public getPreviousForm(formName: OOPages | null) {
    if (!formName) return null;
    const index = this.pages.findIndex((form) => form.name === formName);
    if (index === -1 || index === 0) {
      return null;
    }
    return this.pages[index - 1];
  }

  public getFirstDocument() {
    if (this.documentations.length === 0) return null;
    return this.documentations[0];
  }

  public getLastDocument() {
    if (this.documentations.length === 0) {
      return null;
    }
    return this.documentations[this.documentations.length - 1];
  }

  public getNextDocument(docName: OODocumentations | null) {
    if (!docName) return null;
    const index = this.documentations.findIndex((doc) => doc.name === docName);
    if (index === -1 || index === this.documentations.length - 1) {
      return null;
    }
    return this.pages[index + 1];
  }

  public getPreviousDocument(docName: OODocumentations | null) {
    if (!docName) return null;
    const index = this.documentations.findIndex((doc) => doc.name === docName);
    if (index === -1 || index === 0) {
      return null;
    }
    return this.pages[index - 1];
  }
}
