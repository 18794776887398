import escapeHtml from 'escape-html';
import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';

import style from './RichTextEditor.module.scss';

export const options = [
  {
    format: 'textStyle',
    icon: 'text_format',
    type: 'textStyle',
    options: [
      {
        label: 'Paragraph',
        tag: 'p',
        style: style['textStyle__item-p'],
      },
      {
        label: 'Heading 1',
        tag: 'h1',
        style: style['textStyle__item-h1'],
      },
      {
        label: 'Heading 2',
        tag: 'h2',
        style: style['textStyle__item-h2'],
      },
      {
        label: 'Heading 3',
        tag: 'h3',
        style: style['textStyle__item-h3'],
      },
    ],
  },
  {
    format: 'bold',
    icon: 'format_bold',
    type: 'mark',
  },
  {
    format: 'italic',
    icon: 'format_italic',
    type: 'mark',
  },
  {
    format: 'textStyle',
    icon: 'more_vert',
    type: 'textStyle',
    options: [
      {
        label: 'Strikethrough',
        tag: 'strikethrough',
        style: 'base',
        optionIcon: 'format_underlined',
      },
      {
        label: 'Underline',
        tag: 'underline',
        style: 'base',
        optionIcon: 'format_strikethrough',
      },
    ],
  },
  {
    format: 'link',
    icon: 'add_link',
    type: 'link',
  },
  {
    format: 'bulleted-list',
    icon: 'format_list_bulleted',
    type: 'block',
  },
  {
    format: 'numbered-list',
    icon: 'format_list_numbered',
    type: 'block',
  },
];

export const LIST_TYPES: any = ['numbered-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

interface Node {
  bold: string;
  italic: string;
  strikethrough: string;
  underline: string;
  h1: string;
  h2: string;
  h3: string;
  p: string;
  children: any[];
  type: string;
  href: string;
}

export const parseToHtml = (node: Node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    const isBold: any = node.bold;
    const isItalic = node.italic;
    const isStrikeThrough = node.strikethrough;
    const isUnderline = node.underline;
    const isH1 = node.h1;
    const isH2 = node.h2;
    const isH3 = node.h3;
    const isP = node.p;

    if (isBold) {
      string = `<strong>${string}</strong>`;
    }
    if (isItalic) {
      string = `<em>${string}</em>`;
    }
    if (isStrikeThrough) {
      string = `<strike>${string}</strike>`;
    }
    if (isUnderline) {
      string = `<u>${string}</u>`;
    }
    if (isH1) {
      string = `<h1>${string}</h1>`;
    }
    if (isH2) {
      string = `<h2>${string}</h2>`;
    }
    if (isH3) {
      string = `<h3>${string}</h3>`;
    }
    if (isP) {
      string = `<p>${string}</p>`;
    }
    return string;
  }

  const children: any = node?.children.map((n: any) => parseToHtml(n)).join('');

  switch (node.type) {
    case 'list-item':
      return `<li>${children}</li>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.href)}">${children}</a>`;
    default:
      return children;
  }
};

export const parseFromHtml = (el: any, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes: any = { ...markAttributes };

  // Text elements
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'ITALIC':
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'H1':
      nodeAttributes.h1 = true;
      break;
    case 'H2':
      nodeAttributes.h2 = true;
      break;
    case 'H3':
      nodeAttributes.h3 = true;
      break;
    case 'P':
      nodeAttributes.p = true;
      break;
    case 'STRIKE':
      nodeAttributes.strikethrough = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
    case 'DEL':
      nodeAttributes.strikethrough = true;
      break;
  }

  const children: any = Array.from(el.childNodes)
    .map((node) => parseFromHtml(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  // Block elements
  switch (el.nodeName) {
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children);
    default:
      return children;
  }
};
