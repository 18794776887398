import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ContentSwitcher, ContentSwitcherItem } from '@adeccoux/tag-ds';
import { apiQuery } from '@store/apiQuery';

import { useT } from '../../../hooks/useT';
import { OOControlModelInterface } from '../../../interfaces';
import { FormControlLoader } from '../../FormControlLoader';

interface OOContentSwitcherProps {
  controlModel: OOControlModelInterface;
}

export const OOContentSwitcher: React.FC<OOContentSwitcherProps> = ({ controlModel }) => {
  const { t, translationsLoading } = useT('entry', 'control-items');

  const formik = useFormikContext<Record<string, any>>();

  const { data, isFetching } = apiQuery.useGetMultiselectValuesQuery({
    name: controlModel.controlHeaderName ? controlModel.controlHeaderName : controlModel.name.toLowerCase(),
  });

  const handleChange = (value: string) => formik.setFieldValue(controlModel.name, value);

  let defaultActiveItem = controlModel?.value;

  if (!defaultActiveItem && data && data.length > 0) {
    defaultActiveItem = data[0].value;
  }

  useEffect(() => {
    formik.setFieldValue(controlModel.name, defaultActiveItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultActiveItem]);

  if (translationsLoading || isFetching) {
    return <FormControlLoader />;
  }

  return (
    <ContentSwitcher
      className="tag-ds"
      onChangeItem={handleChange}
      defaultActiveItem={defaultActiveItem}
      style={{ width: '100%', marginBottom: 0 }}
    >
      {data?.map((x) => (
        <ContentSwitcherItem id={x.value} key={x.value} title={t(x.label)} />
      ))}
    </ContentSwitcher>
  );
};

export default OOContentSwitcher;
