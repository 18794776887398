import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { Searchbox } from '@adeccoux/tag-ds';
import { useDebounce } from '@components/shared/customHooks/useDebounce';
import { useInjection } from '@context/inversify-context-provider';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { SelfServiceItemType, SelfServiceSearchResult } from '@models/selfService.model';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQuery } from '@tanstack/react-query';

import style from './AddItemFromLibrary.module.scss';

interface Props {
  type: SelfServiceItemType;
  createNewControl?: () => void;
  addItemFromLibrary: (item: any, name: string, id: string) => void;
}

const AddItemFromLibrary: React.FC<Props> = ({ type, createNewControl, addItemFromLibrary }) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedValue = useDebounce(searchTerm, 400);
  const aamBackendApi = useInjection(AamBackendApi);
  const dispatch = useDispatch();
  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  const detailsPerType: { [key in SelfServiceItemType]: { [key: string]: string | boolean } } = {
    [SelfServiceItemType.CONTROL]: {
      queryKey: 'searchControl',
      description: 'Add control from library',
      createNewControlLabel: 'Create New Field',
    },
    [SelfServiceItemType.DOCUMENTATION]: {
      queryKey: 'searchDocumentation',
      description: 'Add documentation from library',
      createNewControlLabel: '',
    },
    [SelfServiceItemType.DOCUMENT]: {
      queryKey: 'searchDocument',
      description: 'Add document from library',
      createNewControlLabel: '',
      isSmall: true,
    },
    [SelfServiceItemType.DOCUMENT_FOR_SIGNATURE]: {
      queryKey: 'searchDocumentForSignature',
      description: 'Add document from library',
      createNewControlLabel: 'Create New Document',
    },
  };

  const selectedType = detailsPerType[type];

  const { data: availableControls } = useQuery({
    queryKey: [selectedType.queryKey, selectedFeature?.featureName, selectedFeature?.stepName, debouncedValue, type],
    queryFn: () => {
      if (debouncedValue === '') {
        return [];
      }

      return aamBackendApi.searchControlByName(
        selectedFeature?.featureName || '',
        selectedFeature?.stepName || '',
        type,
        debouncedValue,
      );
    },
    onError: () => {
      dispatch(
        snackbarSlice.actions.showError({
          message: 'Failed to load items!',
        }),
      );
    },
    enabled: !!selectedConfiguration,
  });

  const selectControl = async (id: string, name: string) => {
    try {
      const result = await aamBackendApi.getControlByName(id, name);
      const defaultControl = result.selfServiceLibraryItems[0].data;

      addItemFromLibrary(defaultControl, name, id);
    } catch (err) {
      dispatch(
        snackbarSlice.actions.showError({
          message: 'Failed to load item!',
        }),
      );
    }
  };

  return (
    <div className={clsx(style.addNewControlBtn, selectedType.isSmall ? style['addNewControlBtn--small'] : '')}>
      <div className={style.titleContainer} onClick={() => setIsListVisible((state) => !state)}>
        <span className={selectedType.isSmall ? 'small-title' : 'subtitle'}>{selectedType.description}</span>
        <div className="material-icons">arrow_drop_down</div>
      </div>
      {isListVisible && (
        <div style={{ marginTop: 16 }}>
          <Searchbox
            className="tag-ds input-container "
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search library"
            value={searchTerm}
            style={{ width: 328, border: ' 1px solid black', backgroundColor: 'white' }}
            small={!!selectedType.isSmall}
          />
          <div className={style.list}>
            {availableControls?.map((x: SelfServiceSearchResult, i: number) => {
              return (
                <div
                  key={x.name + i}
                  className={style.listItem}
                  onClick={() => {
                    selectControl(x.selfServiceLibraryId, x.name);
                    setSearchTerm('');
                  }}
                >
                  <span className={selectedType.isSmall ? 'small' : 'base'} style={{ wordBreak: 'break-all' }}>
                    {x.name}
                  </span>
                </div>
              );
            })}
          </div>
          {createNewControl && (
            <div className={style.listButton}>
              <span className="material-icons" style={{ color: '#107B9E' }}>
                add
              </span>
              <span style={{ color: '#0E6987', fontWeight: 500 }} onClick={createNewControl}>
                {selectedType.createNewControlLabel}
              </span>
              <SelfServiceTooltip hoverContent="Before creating a new field, check out our library here (link) to add existing fields." />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddItemFromLibrary;
