import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { store } from '@store/index';

import { editableCondition } from '../../hooks/useRenderCondition';
import { OOControlModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { OOSwitch } from './OOSwitch';

export const OODefaultSwitch: React.VoidFunctionComponent<OOControlModelInterface> = (props) => {
  const flowData = store.getState().oneOnboarding.flow;
  const formik = useFormikContext<Record<string, any>>();

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isEditable = editableCondition(props, flowWrapper, formik.values) ?? true;
  return (
    <OOSwitch
      validationKey={props.name}
      label={props.label}
      description={props.description}
      mandatory={props.isMandatory}
      checkboxProps={{
        disabled:
          !(flowWrapper?.isControlEditable(props.isEditableCandidate, props.isEditableRecruiter) ?? true) ||
          !isEditable,
      }}
      defaultValue={props.defaultValue}
      value={props.value}
    />
  );
};
