import React from 'react';
import clsx from 'clsx';
import { FieldProps } from 'formik';
import { useSelector } from 'react-redux';
import LabelWithTooltip from '@features/selfService/components/LabelWithTooltip/LabelWithTooltip';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { RootState } from '@store/rootReducer';

import { composeTranslationPath } from '../../../../helpers/translationHelper';
import { DocumentPreview } from '../documentsToSign/DocumentPreview';
import BooleanComponentType from './booleanComponentType/ BooleanComponentType';
import ConditionProperty from './conditionProperty/ConditionProperty';
import DocumentParts from './documentParts/DocumentParts';
import DocumentsControls from './documentsControls/DocumentsControls';
import DocumentToSignControls from './documentToSignControls/DocumentToSignControls';
import MaximumCharacters from './maximumCharacters/MaximumCharacters';
import MinimumCharacters from './minimumCharacters/MinimumCharacters';
import MinMaxValidationError from './minMaxValidationError/MinMaxValidationError';
import MultiSelectType from './multiSelectType/MultiSelectType';
import RegexValidation from './regexValidation/RegexValidation';
import RichTextEditorContainer from './richTextEditor/RichTextEditorContainer';
import SignatureAnchors from './signatureAnchors/SignatureAnchors';
import SingleSelectOptions from './singleSelectOptions/SingleSelectOptions';
import SingleSelectType from './singleSelectType/SingleSelectType';
import TextFieldSelector from './textFieldSelector/TextFieldSelector';
import TranslationInput from './translationInput/TranslationInput';
import TypeOfDate from './typeOfDate/TypeOfDate';

import controlStyle from './Controls.module.scss';

interface Props {
  fieldProps: FieldProps;
  documentControlTranslations?: any[];
  setDocumentControlTranslations?: any;
  resetInitialValues?: (componentName: string) => void;
  fieldConfiguration?: {
    disabled: boolean;
  };
  isViewOnly: boolean;
  isFromLibrary?: boolean;
}

const Controls: React.FC<Props> = ({
  fieldProps,
  documentControlTranslations,
  setDocumentControlTranslations,
  resetInitialValues,
  fieldConfiguration,
  isViewOnly,
  isFromLibrary,
}) => {
  const { selectedFeature } = useSelector((store: RootState) => store.selfService);

  const { name, value, onChange, onBlur } = fieldProps.field;
  //   Use for custom handlers
  const { setFieldValue, setFieldTouched, values: formikValues } = fieldProps.form;

  const isError = fieldProps.meta.error && fieldProps.meta.touched;

  const isErrorCustom = (fieldName: string) => {
    const form = fieldProps.form;

    return {
      isError: form.errors[fieldName] && form.touched[fieldName],
      errorDescription: form.errors[fieldName],
    };
  };

  const handleErrorArray = () => {
    if (!isError) return;

    if (Array.isArray(fieldProps.meta.error)) {
      return fieldProps.meta.error.map((x, i) => {
        return (
          <p className="input-helper-text" style={{ margin: 0 }} key={i}>
            {x.name}
          </p>
        );
      });
    }
    return (
      <p className="input-helper-text" style={{ margin: 0 }}>
        {fieldProps.meta.error}
      </p>
    );
  };

  const isDocumentControl = selectedFeature?.featureName === 'DOCUMENTS';

  // Declare it here
  // const commonProperties = {};

  const map: any = {
    label: () => (
      <div className={clsx('tag-ds input-wrapper', controlStyle.control__container, controlStyle.control__mid)}>
        <label className="caption">Field Label</label>
        <input
          type="text"
          value={value}
          onChange={onChange}
          name={name}
          className={isError ? 'error' : ''}
          onBlur={onBlur}
          disabled
          style={{ width: 320 }}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </div>
    ),
    labelTranslations: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__mid)}>
        <TranslationInput
          description="Label translation for"
          fieldName="labelTranslations"
          fieldPropsMeta={fieldProps.meta}
          setFieldValue={setFieldValue}
          value={value}
          disabled={isViewOnly}
        />
      </div>
    ),
    description: () => (
      <div className={clsx('tag-ds input-wrapper', controlStyle.control__container, controlStyle.control__mid)}>
        <label className="caption">Description</label>
        <input
          type="text"
          value={value}
          onChange={onChange}
          name={name}
          className={isError ? 'error' : ''}
          onBlur={onBlur}
          style={{ width: 320 }}
          disabled
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </div>
    ),
    descriptionTranslations: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__bot)}>
        <TranslationInput
          description="Description translation for"
          fieldName="descriptionTranslations"
          fieldPropsMeta={fieldProps.meta}
          setFieldValue={setFieldValue}
          value={value}
          disabled={isViewOnly}
        />
      </div>
    ),
    name: () => (
      <div
        className={clsx('tag-ds input-wrapper', controlStyle.control__container, controlStyle.control__top)}
        style={{ textTransform: 'uppercase' }}
      >
        <label className="caption">{isDocumentControl ? 'Document group name' : 'Field name'}</label>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8, width: 320 }}>
          <input
            type="text"
            value={value}
            onChange={(e) => {
              if (!selectedFeature) return;

              setFieldValue(name, e.target.value.toUpperCase(), true);
              setFieldTouched(name, true, false);

              const getPath = composeTranslationPath(
                selectedFeature?.featureName,
                selectedFeature?.stepName,
                selectedFeature?.pageName,
              );

              if (formikValues.label !== undefined) {
                setFieldValue('label', `${getPath}${e.target.value.toLowerCase()}.label`);
              }

              if (formikValues.staticText !== undefined) {
                setFieldValue('staticText', `${getPath}${e.target.value.toLowerCase()}.label`);
              }

              if (formikValues.minMaxValidationError !== undefined) {
                setFieldValue(
                  'minMaxValidationError',
                  `${getPath}${e.target.value.toLowerCase()}.minMaxValidationError`,
                );
              }

              if (formikValues.description !== undefined) {
                setFieldValue('description', `${getPath}${e.target.value.toLowerCase()}.description`);
              }
            }}
            name={name}
            className={isError ? 'error' : ''}
            disabled={fieldConfiguration?.disabled || isViewOnly}
          />
          <SelfServiceTooltip
            hoverContent={
              isDocumentControl
                ? "This is the technical name of this document group. It's not the name shown to the candidate in the UI"
                : 'These are the named attributes where candidates can provide input on data you require, such as their name, date of birth, etc.'
            }
          />
        </div>
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </div>
    ),
    isMandatory: () => (
      <div
        className={clsx('tag-ds', controlStyle.control__container, controlStyle.control__bot)}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <input
          id="isMandatory"
          type="checkbox"
          checked={value}
          onChange={(e) => {
            onChange(e);
          }}
          style={{ margin: 0, marginRight: 8 }}
          disabled={isViewOnly}
        />
        <label htmlFor="isMandatory">Mandatory</label>
        <SelfServiceTooltip
          hoverContent={
            isDocumentControl
              ? 'If ticked, then candidate must upload at least one document in this group'
              : 'When this is checked, this field will be mandatory to be filled by the candidate'
          }
          position="bottom"
        />
      </div>
    ),
    isCandidateSignatureRequired: () => (
      <div
        className={clsx('tag-ds', controlStyle.control__container, controlStyle.control__mid)}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <input
          id="isCandidateSignatureRequired"
          type="checkbox"
          checked={value}
          onChange={(e) => {
            onChange(e);
          }}
          style={{ margin: 0, marginRight: 8 }}
          disabled={isViewOnly}
        />
        <label htmlFor="isCandidateSignatureRequired">Candidate signature required</label>
      </div>
    ),
    isRecruiterSignatureRequired: () => (
      <div
        className={clsx('tag-ds', controlStyle.control__container, controlStyle.control__bot)}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <input
          id="isRecruiterSignatureRequired"
          type="checkbox"
          checked={value}
          onChange={(e) => {
            onChange(e);
          }}
          style={{ margin: 0, marginRight: 8 }}
          disabled={isViewOnly}
        />
        <label htmlFor="isRecruiterSignatureRequired">Recruiter signature required</label>
      </div>
    ),
    typeOfDate: () => (
      <div
        className={clsx('tag-ds input-wrapper', controlStyle.control__container)}
        style={{ marginBottom: 16, marginTop: 16, padding: 24, borderRadius: 8 }}
      >
        <TypeOfDate name={name} setFieldValue={setFieldValue} value={value} onChange={onChange} disabled={isViewOnly} />
      </div>
    ),
    staticText: () => (
      <div className={clsx('tag-ds input-wrapper', controlStyle.control__container)} style={{ paddingTop: 24 }}>
        <label className="caption">Label</label>
        <input
          type="text"
          value={value}
          onChange={onChange}
          name={name}
          className={isError ? 'error' : ''}
          onBlur={onBlur}
          disabled
          style={{ width: 320 }}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </div>
    ),
    staticTextTranslations: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__bot)}>
        <TranslationInput
          description="Label translation for"
          fieldName="staticTextTranslations"
          fieldPropsMeta={fieldProps.meta}
          setFieldValue={setFieldValue}
          value={value}
          disabled={isViewOnly}
        />
      </div>
    ),
    richTextEditor: () => (
      <div
        className={clsx(controlStyle.control__container, controlStyle.control__bot)}
        style={{
          paddingTop: 24,
          paddingBottom: 24,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          marginBottom: 16,
        }}
      >
        <div style={{ paddingBottom: 16 }}>
          <LabelWithTooltip tooltip="Add any text you'd like to display to your candidate such as administrative information">
            Rich text editor
          </LabelWithTooltip>
        </div>
        <RichTextEditorContainer
          setFieldValue={setFieldValue}
          htmlValue={value}
          translationValues={formikValues.staticTextTranslations}
          name={formikValues.name}
          label={formikValues.staticText}
          disabled={isViewOnly}
        />
        {isErrorCustom('staticTextTranslations').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {isErrorCustom('staticTextTranslations').errorDescription}
          </p>
        )}
      </div>
    ),
    // Move this to a separate component when you get chance
    minMax: () => (
      <div
        className={controlStyle.control__container}
        style={{ marginTop: 16, paddingTop: 24, paddingBottom: 24, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
      >
        <label className="subtitle">Set Character Limit</label>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 10, width: 320, marginTop: 12 }}>
          <div style={{ width: '50%' }}>
            <MinimumCharacters
              value={formikValues.min}
              onChange={(e) => setFieldValue('min', e.target.value)}
              name="min"
              isError={isError}
              onBlur={(e) => setFieldValue('min', e.target.value)}
              fieldProps={fieldProps}
              disabled={isViewOnly}
            />
            {isErrorCustom('min').isError && (
              <p className="input-helper-text" style={{ margin: 0 }}>
                {isErrorCustom('min').errorDescription}
              </p>
            )}
          </div>
          <div style={{ width: '50%' }}>
            <MaximumCharacters
              value={formikValues.max}
              onChange={(e) => setFieldValue('max', e.target.value)}
              name="max"
              isError={isError}
              onBlur={(e) => setFieldValue('max', e.target.value)}
              fieldProps={fieldProps}
              disabled={isViewOnly}
            />

            {isErrorCustom('max').isError && (
              <p className="input-helper-text" style={{ margin: 0 }}>
                {isErrorCustom('max').errorDescription}
              </p>
            )}
          </div>
        </div>
      </div>
    ),
    min: () => (
      <div className="tag-ds input-wrapper" style={{ marginBottom: 24 }}>
        <MinimumCharacters
          value={value}
          onChange={onChange}
          name={name}
          isError={isError}
          onBlur={onBlur}
          fieldProps={fieldProps}
          disabled={isViewOnly}
        />
      </div>
    ),
    max: () => (
      <div className="tag-ds input-wrapper" style={{ marginBottom: 24 }}>
        <MaximumCharacters
          value={value}
          onChange={onChange}
          name={name}
          isError={isError}
          onBlur={onBlur}
          fieldProps={fieldProps}
          disabled={isViewOnly}
        />
      </div>
    ),
    minMaxValidationError: () => (
      <div className={clsx('tag-ds input-wrapper', controlStyle.control__container)}>
        <MinMaxValidationError
          value={value}
          onChange={onChange}
          name={name}
          isError={isError}
          onBlur={onBlur}
          fieldProps={fieldProps}
        />
      </div>
    ),
    minMaxValidationErrorTranslations: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__bot)}>
        <TranslationInput
          description="Validation translation for"
          fieldName="minMaxValidationErrorTranslations"
          fieldPropsMeta={fieldProps.meta}
          setFieldValue={setFieldValue}
          value={value}
          callback={() => {
            if (formikValues.minMaxValidationError !== undefined && formikValues.minMaxValidationError === '') {
              if (!selectedFeature) return;

              const getPath = composeTranslationPath(
                selectedFeature?.featureName,
                selectedFeature?.stepName,
                selectedFeature?.pageName,
              );

              setFieldValue(
                'minMaxValidationError',
                `${getPath}${formikValues.name.toLowerCase()}.minMaxValidationError`,
              );
            }
          }}
          disabled={isViewOnly}
        />
      </div>
    ),
    regexValidation: () => (
      <div
        className={clsx('tag-ds input-wrapper', controlStyle.control__container)}
        style={{ marginBottom: 16, paddingTop: 24, paddingBottom: 24, borderRadius: 8 }}
      >
        <RegexValidation value={value} setFieldValue={setFieldValue} disabled={isViewOnly} />
      </div>
    ),
    renderCondition: () => (
      <>
        <ConditionProperty
          control="Show if"
          value={value}
          setFieldValue={setFieldValue}
          fieldName={name}
          disabled={isViewOnly}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </>
    ),
    mandatoryCondition: () => (
      <>
        <ConditionProperty
          control="Mandate fields"
          value={value}
          setFieldValue={setFieldValue}
          fieldName={name}
          disabled={isViewOnly}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </>
    ),
    // NOT IN USE
    // condition: () => (
    //   <ConditionProperty control="Condition" value={value} setFieldValue={setFieldValue} fieldName={name} />
    // ),
    candidateSignatureRequired: () => (
      <div className={clsx('tag-ds checkbox-container', controlStyle.control__container, controlStyle.control__solo)}>
        <label htmlFor="candidateSignatureRequired" className="subtitle" style={{ marginBottom: 12 }}>
          Candidate's signature required
        </label>
        <input
          id="candidateSignatureRequired"
          type="checkbox"
          checked={value}
          onChange={onChange}
          disabled={fieldConfiguration?.disabled || isViewOnly}
        />
      </div>
    ),
    singleSelectComponentType: () => (
      <div
        className={controlStyle.control__container}
        style={{ paddingTop: 24, paddingBottom: 24, marginTop: 16, borderRadius: 8 }}
      >
        <SingleSelectType
          value={value}
          setFieldValue={(val) => setFieldValue('singleSelectComponentType', val, true)}
          onChange={onChange}
          disabled={isViewOnly}
        />
      </div>
    ),
    multipleSelectComponentType: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <MultiSelectType
          value={value}
          setFieldValue={(val) => setFieldValue('multipleSelectComponentType', val, true)}
          onChange={onChange}
          disabled={isViewOnly}
        />
      </div>
    ),
    booleanComponentType: () => (
      <div className={clsx('tag-ds input-wrapper', controlStyle.control__container, controlStyle.control__solo)}>
        <BooleanComponentType name={name} value={value} setFieldValue={setFieldValue} disabled={isViewOnly} />
      </div>
    ),
    selectOptions: () => (
      <div className="tag-ds input-wrapper" style={{ marginBottom: 24 }}>
        <SingleSelectOptions
          value={value}
          selectOptionsTranslations={formikValues.selectOptionsTranslations}
          setFieldValue={setFieldValue}
          nameValue={formikValues.name}
          fieldProps={fieldProps}
          disabled={isViewOnly}
          template={formikValues.component}
          defaultValue={formikValues.defaultValue}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
        {isErrorCustom('selectOptionsTranslations').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {isErrorCustom('selectOptionsTranslations').errorDescription}
          </p>
        )}
      </div>
    ),
    parts: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <DocumentParts
          value={value}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          disabled={isViewOnly}
        />
        {handleErrorArray()}
      </div>
    ),
    controls: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <DocumentsControls
          value={value}
          setFieldValue={setFieldValue}
          translations={documentControlTranslations ?? []}
          setTranslations={setDocumentControlTranslations}
          fieldProps={fieldProps}
          disabled={isViewOnly}
        />
      </div>
    ),
    documentCondition: () => (
      <>
        <ConditionProperty
          control="Show document if..."
          value={value}
          setFieldValue={setFieldValue}
          fieldName={name}
          disabled={isViewOnly}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </>
    ),
    textFieldSelector: () => (
      <TextFieldSelector
        value={formikValues.component}
        onChange={(val) => {
          if (isViewOnly) return;

          setFieldValue('component', val, true);
          resetInitialValues?.(val);
        }}
        disabled={isViewOnly}
      />
    ),
    documentToSignControls: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <DocumentToSignControls
          value={value}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          fieldProps={fieldProps}
          disabled={isViewOnly}
        />
      </div>
    ),
    uploadedDocument: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <DocumentPreview
          selectedDoc={value}
          selectedDocName={formikValues?.name}
          isPdfDocument={!!formikValues?.signatureAnchors?.length}
          isFromLibrary={isFromLibrary}
          isDocumentUploaded={formikValues.uploadedDocument}
          uploadedDocumentInfo={formikValues.uploadedDocumentInfo}
        />
        {isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {fieldProps.meta.error}
          </p>
        )}
      </div>
    ),
    signatureAnchors: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <SignatureAnchors value={value} setFieldValue={setFieldValue} disabled={isViewOnly} />
      </div>
    ),
    disyDocumentName: () => (
      <div className={clsx(controlStyle.control__container, controlStyle.control__solo)}>
        <label htmlFor="disyDocumentName" className="subtitle" style={{ marginBottom: 12 }}>
          Disy document name
        </label>
        <input
          id="disyDocumentName"
          type="text"
          value={value}
          onChange={onChange}
          disabled={fieldConfiguration?.disabled || isViewOnly}
        />
        {isErrorCustom('disyDocumentName').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {isErrorCustom('disyDocumentName').errorDescription}
          </p>
        )}
      </div>
    ),
  };

  if (!map[name]) {
    return <div>Unknown field name: {name}</div>;
  }

  return map[name]();
};

export default Controls;
