import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import XLSX from 'xlsx';
import { TabItem, Tabs } from '@adeccoux/tag-ds';
import OOTableContainer from '@components/admin/Table/OOTableContainer';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { getClientOrganizationIdImport } from '@helpers/tenant.helper';
import { Utils } from '@helpers/utils';
import { useAllConfigurationsForTenant, useFetchBranches, useFetchImportUsers } from '@hooks/apiHooks';
import { Box, CircularProgress, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { setClientOrganizationIdImport, setConfigurationId } from '@store/slices/oneOnboarding.slice';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { Import } from '../../../oneOnboarding/models/Import';
import CampaignsTable from './components/CampaignsTable';
import { ConfigurationList } from './components/ConfigurationList';
import { ImportNotifications, ImportNotificationsType } from './components/ImportNotifications';
import ImportTable from './components/ImportTable';
import NewCampaign from './components/NewCampaign';
import TableFilters from './components/TableFilters';
import UploadCandidate from './components/UploadCandidate';

export enum tabPage {
  fileImport = 'FILE_IMPORT',
  campaign = 'CAMPAIGN',
  newCampaign = 'NEWCAMPAIGN',
}
const FILE_EXTENSION = 'xlsx';
const NOTIFICATION_RESET_TIMEOUT = 5000;
const REFETCH_INTERVAL = 2000;

const AdminImportUsers = () => {
  const [selectedConfiguration, setSelectedConfiguration] = useState<string | undefined>();
  const [refetchInterval, setRefetchInterval] = useState(0);
  const [statusField, setStatusField] = useState<string>('');
  const [importError, setImportError] = useState<string>('');
  const [startDateField, setStartDateField] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<string>('desc');
  const [lastImportId, setLastImportId] = useState<string>();
  const [orderBy, setOrderBy] = useState<string>('startDate');
  const [notificationType, setNotificationType] = useState<ImportNotificationsType>(ImportNotificationsType.NONE);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get('activeTab');

  const [tab, setTab] = useState<tabPage>(
    activeTab?.toUpperCase() === tabPage.campaign ? tabPage.campaign : tabPage.fileImport,
  );

  useEffect(() => {
    if (startDateField && order === 'desc') {
      setOrder('asc');
    }
    // eslint-disable-next-line
  }, [startDateField]);

  const { t } = useTranslation(['recruiter', 'control-items']);

  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { importClientOrganizationId, selectedConfigurationId } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const aamBackendApi = useInjection(AamBackendApi);
  const dispatch = useDispatch();
  const { data: allConfigurationsForTenant, isLoading: isConfigurationsLoading } = useAllConfigurationsForTenant(
    tenantId,
  );

  const handleImportsUpdate = (imports: { results: Import[]; total: number }): void => {
    if (!lastImportId) {
      return;
    }

    const currentImport = imports.results.find((importItem) => importItem.id === lastImportId);

    if (currentImport && currentImport.status === 'COMPLETED') {
      const { failedLines, actualLines, expectedLines } = currentImport;

      if (failedLines) {
        setNotificationType(ImportNotificationsType.ERROR);
      } else if (actualLines === expectedLines) {
        setNotificationType(ImportNotificationsType.SUCCESS);
      } else if (actualLines < expectedLines) {
        setNotificationType(ImportNotificationsType.WARNING);
      }

      setLastImportId('');
    }
  };

  const { data, isFetching, isRefetching, refetch: refetchList } = useFetchImportUsers(
    selectedConfiguration,
    selectedConfigurationId,
    importClientOrganizationId,
    setRefetchInterval,
    refetchInterval,
    {
      page,
      limit: rowsPerPage,
      startDate: startDateField,
      importStatus: statusField,
      order: {
        direction: order,
        field: orderBy,
      },
    },
    handleImportsUpdate,
  );

  const { data: allBranchs } = useFetchBranches();

  useEffect(() => {
    refetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConfiguration]);

  const clientOrganizationId = getClientOrganizationIdImport();

  const configurationList = useMemo(
    () =>
      allConfigurationsForTenant
        ? allConfigurationsForTenant
            .map((i) =>
              i.selectedConfigurations?.map((x) => ({ ...x, displayConfigurationName: i.displayName ?? i.name })),
            )
            .flat()
        : [],
    [allConfigurationsForTenant],
  );

  useEffect(() => {
    if (configurationList.length === 1) {
      dispatch(setClientOrganizationIdImport(configurationList[0].clientOrganizationId));
      dispatch(setConfigurationId(configurationList[0].id));
      setSelectedConfiguration(
        JSON.stringify({
          clientOrganizationId: configurationList[0].clientOrganizationId,
          configurationId: configurationList[0].id,
        }),
      );
    } else {
      if (!!clientOrganizationId && !!selectedConfigurationId) {
        const choosenConfig = configurationList?.find(
          (i) => i.clientOrganizationId === clientOrganizationId && i.id === selectedConfigurationId,
        );
        if (choosenConfig) {
          setSelectedConfiguration(
            JSON.stringify({
              clientOrganizationId,
              configurationId: selectedConfigurationId,
            }),
          );
        }
      }
    }
  }, [clientOrganizationId, dispatch, selectedConfigurationId, configurationList]);

  const resetFilterValues = () => {
    setStartDateField(null);
    setPage(0);
    setOrder('desc');
    setOrderBy('startDate');
    setStatusField('');
  };

  const handleConfigurationChange = (value: string) => {
    if (!value) {
      dispatch(setClientOrganizationIdImport(null));
      dispatch(setConfigurationId(null));
      return;
    }
    const selectedDropdownValue = JSON.parse(value);
    const selectedConfigurationId = selectedDropdownValue.configurationId;
    const clientOrganizationId = selectedDropdownValue.clientOrganizationId;

    setSelectedConfiguration(value);

    dispatch(setClientOrganizationIdImport(clientOrganizationId));
    dispatch(setConfigurationId(selectedConfigurationId));

    resetFilterValues();
  };

  const isValidExcelFile = (filename: string): boolean => {
    return filename.slice(filename.lastIndexOf('.') + 1) === FILE_EXTENSION;
  };

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    try {
      const { error, result, failedUsers } = await aamBackendApi.ooImportCandidatesAsync(formData);
      if (error) {
        setNotificationType(ImportNotificationsType.ERROR);
        setImportError(error);
      } else {
        if (result?.id) {
          setLastImportId(result.id);
        }

        setNotificationType(ImportNotificationsType.INFO);

        if (!result?.id && failedUsers?.length > 0) {
          if (failedUsers[0]?.reason) {
            const message = JSON.parse(failedUsers[0]?.reason)?.DATE_OF_BIRTH?.error;
            if (message && message === 'INVALID_DATE') {
              setNotificationType(ImportNotificationsType.DATE);
            }
          } else {
            setNotificationType(ImportNotificationsType.ERROR);
          }
        }
      }

      setTimeout(() => {
        resetNotifications();
      }, NOTIFICATION_RESET_TIMEOUT);

      refetchList();
      setRefetchInterval(REFETCH_INTERVAL);
    } catch (err) {
      Utils.consoleLog('Error uploading file:', err);
    }
  };

  const resetNotifications = () => {
    setNotificationType(ImportNotificationsType.NONE);
    setImportError('');
  };

  const candidatesFileHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const { name } = file;

    if (isValidExcelFile(name)) {
      const emailCount = await countCandidatesInExcelImportFile(file);
      const isConfirmed = window.confirm(
        t('recruiter:GENERAL.IMPORT:NumberOfCandidates', { numberOfCandidates: emailCount }),
      );

      if (isConfirmed) {
        setLastImportId('');
        handleFileUpload(file);
      }
    }
  };

  const countCandidatesInExcelImportFile = async (file: File) => {
    const table = XLSX.read(await file.arrayBuffer(), { type: 'array' });
    const sheet = table.Sheets[table.SheetNames[0]];
    const range = XLSX.utils.decode_range(sheet['!ref']!);

    const emailRegex = /\S+@\S+\.\S+/; //NOSONAR

    let emailCount = 0;

    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellref = XLSX.utils.encode_cell({ c: C, r: R });
        if (!sheet[cellref]) {
          continue;
        }
        const cell = sheet[cellref];
        if (emailRegex.test(cell.w)) {
          emailCount++;
        }
      }
    }

    return emailCount;
  };

  const isLoading = isFetching && !isRefetching;

  const importDisabledConfigurations = configurationList
    .filter((sc) => {
      const firstStep = sc.flow?.[0];
      return !(firstStep?.importFields?.length > 0 || firstStep?.importControls?.length > 0);
    })
    .map((itm) => JSON.stringify({ clientOrganizationId: itm.clientOrganizationId, configurationId: itm.id }));

  const backNewCampaign = () => {
    setTab(tabPage.campaign);
  };

  const renderTables = () => {
    switch (tab) {
      case tabPage.fileImport:
        return (
          <OOTableContainer>
            <ImportNotifications
              type={notificationType}
              onClose={() => setNotificationType(ImportNotificationsType.NONE)}
              error={importError}
            />
            <TableFilters
              dateFilter={{
                startDate: startDateField,
                setStartDate: (date) => {
                  setPage(0);
                  setStartDateField(date);
                },
              }}
              statusFilter={{
                status: statusField,
                setStatus: (value) => {
                  setPage(0);
                  if (value === 'ALL') {
                    setStatusField('');
                  } else {
                    setStatusField(value);
                  }
                },
              }}
            />
            {isLoading ? (
              <div className="feature-admin-import__loader">
                <CircularProgress />
              </div>
            ) : (
              <ImportTable
                importList={data}
                order={order}
                orderBy={orderBy}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                pagination={{ setRowsPerPage, setPage, rowsPerPage, page }}
              />
            )}
          </OOTableContainer>
        );
      case tabPage.campaign:
        return (
          <OOTableContainer>
            <div style={{ marginRight: '60px' }}>
              <Box style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <button onClick={() => setTab(tabPage.newCampaign)} className="tag-ds button-primary">
                  {t(`GENERAL.IMPORT.newCampaign`)} <span className="material-icons">add</span>
                </button>
              </Box>
              <CampaignsTable />
            </div>
          </OOTableContainer>
        );
      case tabPage.newCampaign:
        return (
          <Grid item xs={9} className="feature-admin-import__right-side tag-ds ">
            <NewCampaign
              back={backNewCampaign}
              clientOrganizations={allConfigurationsForTenant ? allConfigurationsForTenant : []}
              branchs={allBranchs ? allBranchs : []}
            />
          </Grid>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="admin">
        <PageWrapper
          featureName="admin-import"
          pageName="admin-import"
          headerConfig={{ pageName: t('recruiter:GENERAL.IMPORT.import'), languagePicker: true }}
          sideMenu
        >
          <Grid container className="feature-admin-import__container">
            <Grid item xs={3} className="feature-admin-import__left-side">
              <Box
                className="tag-ds"
                style={{ paddingTop: '20px', width: '100% !important' }}
                display="flex"
                justifyContent="space-between"
              >
                <Tabs
                  onChangeTab={(e) => {
                    setTab(e as tabPage);
                  }}
                  defaultActiveTab={tab}
                >
                  <TabItem id={tabPage.fileImport} title={t(`GENERAL.IMPORT.importFile`)}>
                    <div className="scrollConfigList">
                      <ConfigurationList
                        configurationList={configurationList.sort((a, b) =>
                          (a.recruiterName || '').localeCompare(b.recruiterName),
                        )}
                        selectedConfiguration={selectedConfiguration}
                        handleConfigurationChange={handleConfigurationChange}
                        isLoading={isConfigurationsLoading}
                      />
                      <UploadCandidate
                        candidatesFileHandler={candidatesFileHandler}
                        disabled={
                          isRefetching ||
                          (selectedConfiguration !== undefined &&
                            importDisabledConfigurations.includes(selectedConfiguration))
                        }
                      />
                    </div>
                  </TabItem>
                  <TabItem id={tabPage.campaign} title={t(`GENERAL.IMPORT.campaigns`)} />
                  <TabItem id={tabPage.newCampaign} title="" />
                </Tabs>
              </Box>
            </Grid>
            <Grid item flex={1} style={{ overflow: 'scroll' }} className="tag-ds">
              {renderTables()}
            </Grid>
          </Grid>
        </PageWrapper>
      </div>
    </>
  );
};

export default AdminImportUsers;
