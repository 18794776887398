import { injectable } from 'inversify';

@injectable()
export class FiscalCodeValidationITHelper {
  //Validation code
  private sAsciiZero = '0';
  private sAsciiNine = '9';
  private sAsciiA = 'A';
  private sAsciiZ = 'Z';
  private sAsciia = 'a';
  private sAsciiz = 'z';
  public isEmpty(s: string) {
    return s === null || s.length === 0;
  }
  private sCero = '0';
  private sNueve = '9';
  private sA = 'A';
  private sZ = 'Z';
  //Check sFiscalCode to be a correct Fiscal Code
  public bCheckFiscalCode(sFiscalCode: string) {
    let iWeightPari;
    let iWeightDispari;
    let i;
    let sTmpCar;
    let iWeight: number = 0;

    if (!this.bCheckStructure(sFiscalCode)) return false;

    iWeightPari = 0;
    iWeightDispari = 0;
    i = 1;
    // Calculate the weight of even caracters
    while (i <= 13) {
      sTmpCar = sFiscalCode.charAt(i);
      iWeightPari = iWeightPari + this.getPesiPari(sTmpCar);
      i = i + 2;
    }
    // Calculate the weight of odd caracters
    i = 0;
    while (i <= 14) {
      sTmpCar = sFiscalCode.charAt(i);
      iWeightDispari = iWeightDispari + this.getPesiDispari(sTmpCar);
      i = i + 2;
    }
    //Make the sum of the weight
    iWeight = iWeightPari + iWeightDispari;
    /*
		The check caracter is obtained as summing the weight module 26
		(n. of letters in the alphabet) to the ASCII caracter "A"
		*/
    return String.fromCharCode(65 + (iWeight % 26)) === sFiscalCode.slice(-1);
  }
  //Aux function for checkFiscalCode
  private getPesiPari(sChar: string) {
    if (sChar.charCodeAt(0) >= this.sCero.charCodeAt(0) && sChar.charCodeAt(0) <= this.sNueve.charCodeAt(0)) {
      return sChar.charCodeAt(0) - this.sCero.charCodeAt(0);
    } else if (sChar.charCodeAt(0) >= this.sA.charCodeAt(0) && sChar.charCodeAt(0) <= this.sZ.charCodeAt(0)) {
      return sChar.charCodeAt(0) - this.sA.charCodeAt(0);
    }
    return 0;
  }
  //Aux function for checkFiscalCode
  private getPesiDispari(sChar: string) {
    const pesiDispari = [
      1,
      0,
      5,
      7,
      9,
      13,
      15,
      17,
      19,
      21,
      1,
      0,
      5,
      7,
      9,
      13,
      15,
      17,
      19,
      21,
      2,
      4,
      18,
      20,
      11,
      3,
      6,
      8,
      12,
      14,
      16,
      10,
      22,
      25,
      24,
      23,
    ];
    // tablella lookup pesi dispari
    if (sChar.charCodeAt(0) >= this.sCero.charCodeAt(0) && sChar.charCodeAt(0) <= this.sNueve.charCodeAt(0)) {
      return pesiDispari[sChar.charCodeAt(0) - this.sCero.charCodeAt(0)];
    } else if (sChar.charCodeAt(0) >= this.sA.charCodeAt(0) && sChar.charCodeAt(0) <= this.sZ.charCodeAt(0)) {
      return pesiDispari[sChar.charCodeAt(0) - this.sA.charCodeAt(0) + 10];
    }
    return 0;
  }
  private bCheckStructure(sFiscalCode: string) {
    let bResult = true;
    //checking if the length is 16
    if (sFiscalCode.length !== 16) {
      bResult = false;
    }
    if (bResult) {
      //checking if the first 6 are letters
      let i = 0;
      while (i < 6 && bResult) {
        if (
          (sFiscalCode.charAt(i).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
            sFiscalCode.charAt(i).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
          (sFiscalCode.charAt(i).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
            sFiscalCode.charAt(i).charCodeAt(0) < this.sAsciia.charCodeAt(0))
        ) {
          bResult = false;
        }
        i++;
      }
    }
    //checking if the next two are numbers corresponding to the year of birth
    if (bResult) {
      if (
        (sFiscalCode.charAt(6).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(6).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(6).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(6).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(6).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(6).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    if (bResult) {
      if (
        (sFiscalCode.charAt(7).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(7).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(7).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(7).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(7).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(7).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    //checking if the following is not a letter corresponding to the month of birth
    if (bResult) {
      if (
        (sFiscalCode.charAt(8).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(8).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(8).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(8).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    //checking if the next two are numbers corresponding to the day of birth
    if (bResult) {
      if (
        (sFiscalCode.charAt(9).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(9).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(9).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(9).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(9).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(9).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    if (bResult) {
      if (
        (sFiscalCode.charAt(10).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(10).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(10).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(10).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(10).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(10).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    //The next 4 digits are the code of the province. The first must be a letter.
    if (bResult) {
      if (
        (sFiscalCode.charAt(11).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(11).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(11).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(11).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    //The remaining 3 must be numbers
    if (bResult) {
      if (
        (sFiscalCode.charAt(12).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(12).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(12).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(12).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(12).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(12).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    if (bResult) {
      if (
        (sFiscalCode.charAt(13).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(13).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(13).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(13).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(13).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(13).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    if (bResult) {
      if (
        (sFiscalCode.charAt(14).charCodeAt(0) < this.sAsciiZero.charCodeAt(0) ||
          sFiscalCode.charAt(14).charCodeAt(0) > this.sAsciiNine.charCodeAt(0)) &&
        (sFiscalCode.charAt(14).charCodeAt(0) > this.sAsciiZ.charCodeAt(0) ||
          sFiscalCode.charAt(14).charCodeAt(0) < this.sAsciiA.charCodeAt(0)) &&
        (sFiscalCode.charAt(14).charCodeAt(0) > this.sAsciiz.charCodeAt(0) ||
          sFiscalCode.charAt(14).charCodeAt(0) < this.sAsciia.charCodeAt(0))
      ) {
        bResult = false;
      }
    }
    return bResult;
  }
}
