import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../libs/aamBackendApi';

interface AppTextInputProps {
  name: string;
  placeholder: string;
  onChange: (e: any) => void;
  value: any;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  id?: string;
  headerTextFormat?: string;
}

const getLocalizedUtils = (headerTextFormat = 'EEE, d MMMM') => {
  return class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date: any) {
      return format(date, headerTextFormat, { locale: this.locale });
    }
  };
};

export const AppDatePicker: React.FC<AppTextInputProps> = ({
  name,
  placeholder,
  onChange,
  value,
  label,
  minDate,
  maxDate,
  disabled = false,
  error = false,
  helperText = false,
  id,
  headerTextFormat,
}) => {
  const aamBackendApi = useInjection(AamBackendApi);
  const { selectedConfigurationId, clientOrganizationId } = useSelector((state: RootState) => state.oneOnboarding);
  const [dateFormat, dateFormatSet] = useState<string>('');
  const LocalizedUtils = getLocalizedUtils(headerTextFormat);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (clientOrganizationId && selectedConfigurationId) {
      const fetchDisplayDateFormat = async () => {
        const format = (await aamBackendApi.getOtherConfiguration(clientOrganizationId, selectedConfigurationId))?.find(
          (d) => d.name === 'displayDateFormat',
        )?.value;

        format ? dateFormatSet(format) : dateFormatSet('dd/MM/yyyy');
      };
      fetchDisplayDateFormat();
    }
  }, [clientOrganizationId, selectedConfigurationId, aamBackendApi]);

  return (
    <LocalizationProvider utils={LocalizedUtils} dateAdapter={AdapterDateFns}>
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={label}
        inputFormat={dateFormat ? dateFormat : 'dd/MM/yyyy'}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : undefined}
        value={value}
        onChange={(value: any) => {
          onChange({
            target: {
              name,
              value,
            },
          });
        }}
        disabled={disabled}
        renderInput={(props) => (
          <TextField
            {...props}
            inputProps={{
              ...props.inputProps,
              label,
              placeholder,
            }}
            onClick={(e) => setOpen(true)}
            placeholder={placeholder}
            label={label}
          />
        )}
      />
    </LocalizationProvider>
  );
};
