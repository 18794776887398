import React from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ArrowForwardIcon } from '../../shared/customIcons/app/ArrowForwardIcon';

export interface AppBottomBoxFixedProps {
  timeLeft: number;
}

export const AppBottomBoxFixed: React.FC<AppBottomBoxFixedProps> = ({ timeLeft, ...buttonConfig }) => {
  function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
      <Box position="absolute" display="inline-flex" className="bottom-box-fixed__time-counter-box">
        <CircularProgress
          variant="determinate"
          color="primary"
          className="bottom-box-fixed__time-counter-box__time-left"
          {...props}
        />
        <CircularProgress
          variant="determinate"
          value={100}
          size={80}
          thickness={2}
          className="bottom-box-fixed__time-counter-box__time-left-background"
        />
      </Box>
    );
  }
  const timeLeftText = (timeLeft: number) => {
    if (timeLeft === 0) {
      return 'Continue';
    } else {
      return `${timeLeft} minutes left`;
    }
  };
  return (
    <div id="bottom-box-fixed" className="bottom-box-fixed">
      <Button
        {...buttonConfig}
        fullWidth={false}
        variant="contained"
        color="primary"
        className="bottom-box-fixed__button-next"
      >
        <ArrowForwardIcon color="secondary" />
        <CircularProgressWithLabel value={timeLeft} size={80} thickness={2} />
      </Button>
      <Typography variant="body2" component="div" color="textPrimary">
        <b>{timeLeftText(timeLeft)}</b>
      </Typography>
    </div>
  );
};
