import { UserPermissionModel } from './userPermission.model';
import { UserProfileModel } from './userProfile.model';

export enum UserRole {
  user = 'user',
  adeccoStaff = 'adeccoStaff',
  adeccoAdmin = 'adeccoAdmin',
  adeccoConfig = 'adeccoConfig',
  adeccoStaffAndAdmin = 'adeccoStaffAndAdmin',
}

export enum AcccessStatus {
  active = 'active',
  deactivated = 'deactivated',
}

export interface UserModel {
  clientId: string | null;
  createdAt: string;
  deletionDate: string | null;
  email: string | null;
  emailValidated: boolean | null;
  emailValidationCode: string | null;
  toBeDeleted: boolean | null;
  toBeDeletedReason: CandidateDeletedReason | null;
  emailValidationCodeSentAt: string | null;
  id: string | null;
  invalidLoginCount: number;
  locked: boolean;
  lockedAt: Date | null;
  passwordChangedAt: Date | null;
  requirePasswordUpdate: boolean;
  resetCode: string | null;
  resetCodeSentAt: Date | null;
  role: UserRole;
  tenantId: number | null;
  userProfile?: UserProfileModel;
  deletedAt: string | null;
  userPermission?: UserPermissionModel;
  lastLoginAt?: Date | null;
  accessStatus?: AcccessStatus | null;
}

export const CANDIDATE_EMAIL_STATUS_IMPORTED = 'imported';
export const CANDIDATE_EMAIL_STATUS_REGISTERED = 'registered';
export const CANDIDATE_EMAIL_STATUS_NOT_FOUND = 'not-found';

export type CandidateEmailStatus =
  | typeof CANDIDATE_EMAIL_STATUS_IMPORTED
  | typeof CANDIDATE_EMAIL_STATUS_REGISTERED
  | typeof CANDIDATE_EMAIL_STATUS_NOT_FOUND;

export const CANDIDATE_DELETED_REASON_INACTIVITY = 'inactivity';
export const CANDIDATE_DELETED_REASON_DELETION = 'deletion';
export const CANDIDATE_DELETED_REASON_REJECTION = 'rejection';
export const CANDIDATE_DELETED_REASON_COMPLETION = 'completion';

export type CandidateDeletedReason =
  | typeof CANDIDATE_DELETED_REASON_INACTIVITY
  | typeof CANDIDATE_DELETED_REASON_DELETION
  | typeof CANDIDATE_DELETED_REASON_REJECTION
  | typeof CANDIDATE_DELETED_REASON_COMPLETION;

export interface CheckUser {
  tenantId: number;
  userId: string;
  applicationId: string;
  clientOrganizationId: string;
  configurationId: string;
  rejectedAt: Date | null;
}
