import { useEffect } from 'react';
import { isEqual } from 'lodash';
import { Dispatch } from 'redux';
import { SiteModel } from '@models/site.model';
import { AdminDashboardFilters, setAdminDashboardFilters } from '@store/slices/oneOnboarding.slice';

import { OOConfiguration } from '../../../oneOnboarding/interfaces';
const mapToIds = (selectedOptionsArray: any) => selectedOptionsArray?.map((item: any) => item?.id);
const mapToValue = (selectedOptionsArray: any) => selectedOptionsArray?.map((item: any) => item?.value);
const mapToStatus = (selectedOptionsArray: any) => selectedOptionsArray?.map((item: any) => item?.status);
const useUpdateAdminDashboardFilters = (
  selectedValues: any,
  adminDashboardFilters: AdminDashboardFilters,
  sites: SiteModel[] | undefined,
  selectedConfiguration: OOConfiguration | undefined,
  storeDispatch: Dispatch<any>,
) => {
  useEffect(() => {
    const {
      regions,
      steps,
      branches,
      applicationId,
      deletedReason,
      onboardingStatuses,
      locations,
      clientOrganizationId,
      journeyId,
      campaigns,
      jobTypes,
      sites,
      ...rest
    } = selectedValues;

    if (!isEqual(adminDashboardFilters, selectedValues)) {
      const filterParameters = {
        ...rest,
        locations: mapToIds(locations),
        clientOrganizationId: clientOrganizationId || '',
        journeyId: journeyId || '',
        campaigns: campaigns || '',
        regions: mapToIds(regions),
        branches: mapToIds(branches),
        jobTypeIds:
          selectedConfiguration?.customFilters?.find((filter) => filter.name.includes('JOB_TYPE'))?.value &&
          selectedConfiguration?.customFilters?.find((filter) => filter.name.includes('JOB_TYPE'))?.value === 'value'
            ? mapToValue(jobTypes)
            : mapToIds(jobTypes),
        deletedReason: mapToStatus(deletedReason),
        onboardingStatuses: mapToStatus(onboardingStatuses),
        steps: steps
          ?.map((item: any) => item?.name)
          .flatMap((item: any) => item)
          .filter((item: any) => item),
        applicationId,
        sites: mapToIds(sites),
      };
      storeDispatch(setAdminDashboardFilters(filterParameters));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);
};

export default useUpdateAdminDashboardFilters;
