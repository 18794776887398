import { OOUserDocumentModel } from '@features/oneOnboarding/models/UserDocumentModel';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useRecruiterSignsHiringDocuments = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const step = steps.find((x) => x.name === 'CANDIDATE_SIGN_DOCUMENTS');
  const documentsForSignature = step?.documentsForSignature.filter((x) => x.isRecruiterSignatureRequired) || [];
  const userDocuments: OOUserDocumentModel[] = [];
  documentsForSignature.forEach((i) => {
    const sortedUserDocuments = [...(i.userDocuments || [])].sort(
      (a, b) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime(),
    );
    const userDocument = sortedUserDocuments[sortedUserDocuments.length - 1];
    if (userDocument) {
      userDocuments.push(userDocument);
    }
  });

  const isStepCompleted = flowWrapper.isStepCompleted('RECRUITER_SIGN_DOCUMENTS');
  const completed =
    isStepCompleted &&
    userDocuments.length > 0 &&
    (userDocuments?.every((x) => x.additionalData?.signedByRecruiter === true) ||
      userDocuments?.every((x) => x.additionalData?.signed === true));
  const active = currentStep === 'RECRUITER_SIGN_DOCUMENTS';
  const disabled = active || completed ? false : !active;

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      const step = steps.find((x) => x.name === 'CANDIDATE_SIGN_DOCUMENTS');
      setPageStepContext({ step, page: undefined });
    },
  };
};
