import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { HeaderLanguages, Option } from '@adeccoux/tag-ds';
import { translationHelper } from '@features/selfService/helpers/translationHelper';

import styles from './ConditionProperty.module.scss';

interface Props {
  value: any;
  handleChange: (e: any) => void;
  isError: boolean;
  isDisabled: boolean;
  options?: any[];
}

export const SelectCondition: React.FC<Props> = ({ value, handleChange, isError, isDisabled = false, options }) => {
  const { t, i18n } = useTranslation(['control-items', 'entry']);

  return (
    <HeaderLanguages
      className={clsx('tag-ds', styles.item__input, isError && 'error')}
      name="select"
      onChange={(e) => handleChange(e)}
      placeholder="Select an option"
      value={value}
      disabled={isDisabled}
    >
      {options?.map((v: any, i: number) => {
        return <Option key={i} label={translationHelper(v.label, i18n, t)} value={v.value} />;
      })}
    </HeaderLanguages>
  );
};

export const NumberCondition: React.FC<Props> = ({ value, handleChange, isError, isDisabled = false, options }) => {
  return (
    <input
      value={value}
      type="number"
      onChange={(e) => handleChange(e)}
      disabled={isDisabled}
      className={isError ? 'error' : ''}
    />
  );
};

export const TextCondition: React.FC<Props> = ({ value, handleChange, isError, isDisabled = false, options }) => {
  return (
    <input
      value={value}
      type="text"
      onChange={(e) => handleChange(e)}
      disabled={isDisabled}
      className={isError ? 'error' : ''}
    />
  );
};

export const DateCondition: React.FC<any> = ({ handleChange }) => {
  return (
    <div className="tag-ds">
      <div className="radio-container ">
        <input id="anyDate" type="radio" value="anyDay" onChange={(e) => handleChange(e)} />
        <label htmlFor="anyDate">Any date</label>
      </div>
      <div className="radio-container ">
        <input id="future" value="future" type="radio" onChange={(e) => handleChange(e)} />
        <label htmlFor="future">Future, starting tomorrow</label>
      </div>
      <div className="radio-container ">
        <input id="past" value="past" type="radio" onChange={(e) => handleChange(e)} />
        <label htmlFor="past">Past, up to today</label>
      </div>
      <div className="radio-container ">
        <input id="olderThan18" value="olderThan18" type="radio" onChange={(e) => handleChange(e)} />
        <label htmlFor="olderThan18">Birth date: older than 18</label>
      </div>
    </div>
  );
};

export const BooleanCondition: React.FC<Props> = ({ value, handleChange, isError, isDisabled = false, options }) => {
  return (
    <div className={styles['condition__boolean-container']}>
      <div
        className={clsx(
          styles['condition__boolean-item'],
          styles['condition__boolean-item--left'],
          value === 'true' && styles['condition__boolean-item--active'],
        )}
        onClick={(e) => {
          if (isDisabled) return;

          handleChange('true');
        }}
      >
        TRUE
      </div>
      <div
        className={clsx(
          styles['condition__boolean-item'],
          styles['condition__boolean-item--right'],
          value === 'false' && styles['condition__boolean-item--active'],
        )}
        onClick={(e) => {
          if (isDisabled) return;

          handleChange('false');
        }}
      >
        FALSE
      </div>
    </div>
  );
};

export const OperatorCondition: React.FC<Props> = ({ value, handleChange, isError, isDisabled = false, options }) => {
  const availableOptions = ['', 'AND', 'OR'];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '32px 0' }}>
      <HeaderLanguages
        className={clsx('tag-ds', isError && 'error')}
        name="select"
        onChange={(e) => handleChange(e)}
        placeholder="Operator"
        value={value}
        disabled={isDisabled}
        style={{ width: '120px' }}
      >
        {availableOptions.map((v: any, i: number) => {
          return <Option key={i} label={v} value={v} />;
        })}
      </HeaderLanguages>
    </div>
  );
};
