import React, { useEffect, useRef, useState } from 'react';
import { Editor, Path, Range, Transforms } from 'slate';

import controlsStyle from '../../Controls.module.scss';
import style from '../RichTextEditor.module.scss';

//This hook returns if the click was inside the popUp ref or outside it .
const usePopup = (popupRef: any) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const clickedComponent = e.target;
      if (!popupRef?.current?.contains(clickedComponent)) {
        setShowPopup(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [showPopup, setShowPopup];
};

const createLinkNode = (href: string, showInNewTab: boolean, text: string) => ({
  type: 'link',
  href,
  target: showInNewTab ? '_blank' : '_self',
  children: [{ text }],
});

export const removeLink = (editor: any) => {
  Transforms.unwrapNodes(editor, {
    match: (n: any) => !Editor.isEditor(n) && (Element as any).isElement(n) && n.type === 'link',
  });
};

const insertLink = (editor: any, { url, showInNewTab }: any) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, showInNewTab, 'Link');
  if (selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);
    if ((parent as any).type === 'link') {
      removeLink(editor);
    }

    if (editor.isVoid(parent)) {
      Transforms.insertNodes(editor, { type: 'paragraph', children: [link] } as any, {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
    }
  } else {
    Transforms.insertNodes(editor, { type: 'paragraph', children: [link] } as any);
  }
};

export const LinkButton: React.FC<{ editor: any; icon: string; disabled: boolean }> = ({ editor, icon, disabled }) => {
  const [url, setUrl] = useState('');
  const [showInNewTab, setShowInNewTab] = useState(false);
  const [selection, setSelection] = useState<any>();
  const linkInputRef = useRef(null);
  const [showInput, setShowInput] = usePopup(linkInputRef) as any;

  const handleInsertLink = () => {
    Transforms.select(editor, selection);
    insertLink(editor, { url, showInNewTab });
    setUrl('');
    setShowInput((prev: any) => !prev);
    setShowInNewTab(false);
  };

  const toggleLink = () => {
    if (disabled) return;

    setSelection(editor.selection);
    setShowInput((prev: any) => !prev);
  };

  const handleInputChange = ({ target }: any) => {
    if (target.type === 'checkbox') {
      setShowInNewTab((prev) => !prev);
    } else {
      setUrl(target.value);
    }
  };

  return (
    <span ref={linkInputRef}>
      <span
        className={`${style.editorOptions__item} ${showInput ? style.editorOptions__itemActive : ''} ${
          disabled ? controlsStyle['control--disabled'] : ''
        }`}
        onClick={toggleLink}
        style={{ position: 'relative' }}
      >
        <span className="material-icons">{icon}</span>
      </span>
      <span className={style.link__wrapper}>
        {showInput && (
          <div className={style.link__popup}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
              <input type="text" placeholder="https://google.com" value={url} onChange={handleInputChange} />
              <div onClick={handleInsertLink}>
                <div className="material-icons">save</div>
              </div>
            </div>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <input type="checkbox" checked={showInNewTab} onChange={handleInputChange} />
              <span style={{ fontSize: '0.8em' }}>Open in new tab</span>
            </label>
          </div>
        )}
      </span>
    </span>
  );
};

export default LinkButton;
