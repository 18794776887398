export const getImageFileFromBlob = (blob: any) => {
  if (blob instanceof ArrayBuffer) {
    // Not using TextDecoder as it's unsupported in Edge browsers
    // while DataViewer is supported in older IE
    const dataViewer = new DataView(blob);
    const first4LettersInBlob = String.fromCharCode(
      dataViewer.getInt8(0),
      dataViewer.getInt8(1),
      dataViewer.getInt8(2),
      dataViewer.getInt8(3),
    );
    if (first4LettersInBlob === '%PDF') {
      return new File([blob], `pdf_${new Date().getTime()}.pdf`, { type: 'application/pdf' });
    }
  }
  return new File([blob], `camera_${new Date().getTime()}.jpeg`, { type: 'image/jpeg' });
};
