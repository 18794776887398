import React from 'react';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';

interface Props {
  setFieldValue?: (name: string, value: any, shouldValidate?: boolean) => void;
  onChange?: (val: string) => void;
  customHandler?: (value: { minOffset: any; maxOffset: any }) => void;
  name: string;
  value: string;
  disabled: boolean;
}

const TypeOfDate: React.FC<Props> = ({ name, value, setFieldValue, onChange, customHandler, disabled }) => {
  const options = [
    {
      value: 'anyDate',
      label: 'Any date',
      onChange: function (val: any) {
        setFieldValue?.('minOffset', null, true);
        setFieldValue?.('maxOffset', null, true);
        customHandler?.({ minOffset: null, maxOffset: null });
        onChange?.(val);
      },
      tooltip: 'Candidate can select end date',
    },
    {
      value: 'future',
      label: 'Future, starting tomorrow',
      onChange: function (val: any) {
        setFieldValue?.('maxOffset', null, true);
        setFieldValue?.('minOffset', 1, true);
        customHandler?.({ minOffset: 1, maxOffset: null });
        onChange?.(val);
      },
      tooltip: 'Candidate can only select dates in the future',
    },
    {
      value: 'past',
      label: 'Past, up to today',
      onChange: function (val: any) {
        setFieldValue?.('minOffset', null, true);
        setFieldValue?.('maxOffset', -1, true);
        customHandler?.({ minOffset: null, maxOffset: -1 });
        onChange?.(val);
      },
      tooltip: 'Candidate can only select dates in the past',
    },
    {
      value: 'olderThan18',
      label: 'Birth date: older than 18',
      onChange: function (val: any) {
        setFieldValue?.('maxOffset', null, true);
        setFieldValue?.('minOffset', -6575, true);
        customHandler?.({ minOffset: -6575, maxOffset: null });
        onChange?.(val);
      },
      tooltip: 'Set this rule if you require a candidate over the age of 18.',
    },
  ];

  return (
    <>
      <label className="subtitle">Type of date</label>
      <div>
        {options.map((option, index) => {
          if (customHandler) {
            return (
              <div key={index} className="radio-container">
                <input
                  type="radio"
                  onChange={(e) => option.onChange(e)}
                  checked={option.value === value}
                  value={option.value}
                  style={{ marginTop: 8 }}
                  disabled={disabled}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label>{option.label}</label>
                  <SelfServiceTooltip hoverContent={option.tooltip} position="right" />
                </div>
              </div>
            );
          }
          return (
            <div key={index} className="radio-container">
              <input
                type="radio"
                onChange={(e) => option.onChange(e)}
                checked={option.value === value}
                value={option.value}
                name="typeOfDate"
                style={{ marginTop: 8 }}
                disabled={disabled}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>{option.label}</label>
                <SelfServiceTooltip hoverContent={option.tooltip} position="right" />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TypeOfDate;
