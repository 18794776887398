import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { Box, Button, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import BottomBox from '../../../../components/shared/BottomBox/BottomBox';
import { CandidatePageLayout } from '../../components/CandidatePageLayout/CandidatePageLayout';
import StepsOverview from '../../controls/StepsOverview';

export const OOSignDocumentStart: React.FC<any> = ({ close }) => {
  const { t } = useTranslation('candidate');
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);

  return (
    <CandidatePageLayout hideOverview={true}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        minHeight="80vh"
        sx={{ margin: '16px auto 0 auto' }}
      >
        <Box m={2}>
          {getStepperComponent(themeAdditionalData.stepper)}
          <Grid container spacing={3} direction="column">
            <StepsOverview />
          </Grid>
        </Box>
        <BottomBox py={2}>
          <Button variant="contained" color="primary" onClick={() => close()}>
            {t('candidate:GENERAL.PAGES.nextPage')}
          </Button>
        </BottomBox>
      </Box>
    </CandidatePageLayout>
  );
};
