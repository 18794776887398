import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { AdminButton } from '@components/admin/AdminButton/AdminButton';
import { AdminDropdown } from '@components/admin/AdminDropdown/AdminDropdown';
import { AdminInput } from '@components/admin/AdminInput/AdminInput';
import { MainTemplate } from '@components/app/MainTemplate';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { UserRole } from '@models/user.model';
import { UserInvitationModel } from '@models/userInvitation.model';
import { CircularProgress } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useMutation, useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../libs/aamBackendApi';

const roleDropdownItems = [
  { value: UserRole.adeccoAdmin, text: 'Admin' },
  { value: UserRole.adeccoStaff, text: 'Staff' },
  { value: UserRole.adeccoConfig, text: 'Config' },
  { value: UserRole.adeccoStaffAndAdmin, text: 'Staff + Admin' },
];

export const UserInvitationPage = () => {
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useSelector((state: RootState) => state.tenant.tenantId);
  const [createInvitationSelectedRole, setCreateInvitationSelectedRole] = useState<UserInvitationModel['role'] | null>(
    null,
  );
  const [createInvitationEmail, setCreateInvitationEmail] = useState<UserInvitationModel['email'] | null>(null);

  const { refetch: refetchUserInvitations, data: userInvitations, isInitialLoading: userInvitationsLoading } = useQuery(
    {
      queryKey: ['userInvitations', tenantId],
      queryFn: () => aamBackendApi.fetchUserInvitations(tenantId),
    },
  );

  const { mutate: apiCreateInvitation } = useMutation({
    mutationFn: (data: Pick<UserInvitationModel, 'email' | 'role'>) =>
      aamBackendApi.createUserInvitation(tenantId, data),
    onSuccess: () => {
      refetchUserInvitations();
    },
  });
  const { mutate: apiDeleteInvitation } = useMutation({
    mutationFn: (invitation: UserInvitationModel) => aamBackendApi.deleteUserInvitation(tenantId, invitation),
    onSuccess: () => {
      refetchUserInvitations();
    },
  });

  const createInvitation = () => {
    apiCreateInvitation({ email: createInvitationEmail!, role: createInvitationSelectedRole! });
  };

  const deleteInvitation = (invitation: UserInvitationModel) => {
    apiDeleteInvitation(invitation);
  };

  const renderSpinnerIfNeeded = () => {
    if (userInvitationsLoading) return <CircularProgress />;
    return <></>;
  };

  const renderActions = () => {
    if (userInvitationsLoading) return <></>;
    return (
      <>
        <div>
          <h1>Create invitation</h1>
          <AdminInput helperText="email" onChange={(e) => setCreateInvitationEmail(e.target.value)} />
          <AdminDropdown
            items={roleDropdownItems}
            disableNone={true}
            onChange={(e: any) => {
              setCreateInvitationSelectedRole(e.target.value);
            }}
          />
          <AdminButton text="Create invitation" onClick={createInvitation} />
        </div>
      </>
    );
  };

  const renderTable = () => {
    if (userInvitationsLoading) return <></>;
    if (!userInvitations || userInvitations.length === 0) return <>No invitations</>;
    return (
      <>
        {userInvitations.map((x, i) => (
          <div key={'invitation-' + i}>
            Email: {x.email} Role: {x.role} {x.usedAt && format(parseISO(x.usedAt), 'dd/MM/yyyy')}
            <AdminButton onClick={() => deleteInvitation(x)} text="Delete invitation" size="small" />{' '}
          </div>
        ))}
      </>
    );
  };

  return (
    <MainTemplate>
      <PageWrapper pageName="admin-user-invitation__main" featureName="admin-user-invitation">
        {renderSpinnerIfNeeded()}
        {renderActions()}
        {renderTable()}
      </PageWrapper>
    </MainTemplate>
  );
};
