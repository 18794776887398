import { configureStore } from '@reduxjs/toolkit';

import { apiQuery } from './apiQuery';
import { rootReducer } from './rootReducer';
import { StoreKeeper } from './storeKeeper';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiQuery.middleware),
});

StoreKeeper.store = store;
