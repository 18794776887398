import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';

const AdminAutocomplete = (props: any) => {
  const { name, options, label, disabled, placeholder, helperText, error, value, ...rest } = props;
  const disabledAttr = disabled ? { disabled: true } : {};

  const { t } = useTranslation('candidate_recruiter');

  const numberOfSelectedOptions = `${value.length} ${t('GENERAL.CONTROLS.selectedOptions')}`;

  return (
    <>
      <Autocomplete
        className="admin-autocomplete"
        {...disabledAttr}
        id={'sa-' + name}
        options={options}
        noOptionsText={t('GENERAL.CONTROLS.noOptions')}
        closeText={t('GENERAL.GENERIC.close')}
        clearText={t('GENERAL.GENERIC.clear')}
        openText={t('GENERAL.GENERIC.open')}
        {...rest}
        renderInput={(params) => (
          <FormControl>
            <TextField
              {...params}
              helperText={helperText}
              error={error}
              name={name}
              label={label}
              placeholder={value.length > 0 ? numberOfSelectedOptions : placeholder}
            />
          </FormControl>
        )}
        renderTags={() => {
          return <></>;
        }}
      />
      {value.length > 0 && (
        <Chip
          label={numberOfSelectedOptions.toUpperCase()}
          onDelete={() => {
            props.clearValues();
          }}
          className="admin-autocomplete__chip"
        />
      )}
    </>
  );
};

export default AdminAutocomplete;
