import noop from 'lodash/noop';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { useRecruiterUploadsWorkContract } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterUploadsWorkContract';

export const useCandidateUploadsPaymentDetails = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { step: currentStep } = params.flowWrapper.getCurrentStepAndPage();
  const recruiterUploadsWorkContract = useRecruiterUploadsWorkContract(params);
  const active =
    recruiterUploadsWorkContract.active || currentStep === 'DOCUMENT_UPLOAD2' || currentStep === 'DOCUMENT_VALIDATION2';
  const completed = recruiterUploadsWorkContract.completed;
  const disabled = !active && !completed;

  return {
    active,
    disabled,
    completed,
    onClick: () => noop(),
  };
};
