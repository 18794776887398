import React from 'react';
import { Container } from '@mui/material';

import styles from './AdminContainer.module.scss';

interface AdminContainerProps {
  children: React.ReactNode;
}

const AdminContainer: React.FC<AdminContainerProps> = ({ children }) => (
  <Container className={styles.container}>{children}</Container>
);

export default AdminContainer;
