import React from 'react';
import TreeViewElement from './treeViewElement';
export {default as TreeViewElement} from './treeViewElement';

interface ITree {
  /**
   * Alternative className for Tree
   */
  className?: string;
  children: React.ReactComponentElement<typeof TreeViewElement> | React.ReactComponentElement<typeof TreeViewElement>[];
  [other: string]: any;
}

const Tree: React.FC<ITree> = ({children, className, ...rest}) => {
  return (
    <ul className={`tree-container ${className || ''}`} {...rest}>
      {children}
    </ul>
  );
};

export default Tree;
