import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ListType } from './NestedListSelector';

import styles from '../UserPermissions.module.scss';

interface CollapseButtonProps {
  handleCollapse: (
    type: ListType,
    itemId: string,
  ) => {
    set: any;
    setAllCollapsed: any;
    get: any;
    isEverythingCollapsed: boolean;
  };
  listType: ListType;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({ handleCollapse, listType }) => {
  const { t } = useTranslation(['recruiter']);

  const configCollapse = handleCollapse(listType, '0');

  return (
    <Box className={styles.collapseButton} display="flex" onClick={configCollapse.setAllCollapsed}>
      <span className="material-icons" style={{ fontSize: 15, marginRight: 5 }}>
        unfold_less
      </span>
      <span>
        {configCollapse.isEverythingCollapsed ? t('GENERAL.PERMISSIONS.expand') : t('GENERAL.PERMISSIONS.collapse')}
      </span>
    </Box>
  );
};

export default CollapseButton;
