import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TabItem, Tabs } from '@adeccoux/tag-ds';
import { RootState } from '@store/rootReducer';

interface MailListProps {
  selectedMailType: { mailTemplateId: string | null; id: string | null };
  setSelectedMailType: (mail: { mailTemplateId: string; id: string }) => void;
  activeTab: string;
  setActiveTab: (value: string) => void;
  setShowCustomEmail: (value: boolean) => void;
}

const MailList: React.FC<MailListProps> = ({
  selectedMailType,
  setSelectedMailType,
  activeTab,
  setActiveTab,
  setShowCustomEmail,
}) => {
  const { mails } = useSelector((state: RootState) => state.oneOnboarding);
  const { t } = useTranslation(['recruiter', 'recruiter-deprecated']);

  const mailTypes = {
    manual: [
      ...new Map(
        mails
          .filter((m: any) => m.ruleType === 'Manual')
          .map((item: any) => [item.mailTemplateId.replace(/\s/g, ''), item]),
      ).values(),
    ],
    automatic: [
      ...new Map(
        mails.filter((m) => m.ruleType !== 'Manual').map((item) => [item.mailTemplateId.replace(/\s/g, ''), item]),
      ).values(),
    ],
  };

  return (
    <div className="tag-ds ">
      <Tabs className="feature-mail-overview__tabs-container" onChangeTab={(e) => setActiveTab(e)}>
        <TabItem id="Automatic" title={t('recruiter:GENERAL.MAILS.Automatic')}>
          <div className="checkbox-group-container">
            {mailTypes['automatic'].map(({ mailTemplateId, id }) => {
              const translate = mailTemplateId.replace(/\s/g, '');
              return (
                <div
                  key={mailTemplateId + id}
                  className="checkbox-container feature-mail-overview__item"
                  onClick={() => {
                    setShowCustomEmail(mailTemplateId === 'Custom');
                    setSelectedMailType({ mailTemplateId, id });
                  }}
                >
                  <input
                    id={mailTemplateId}
                    type="radio"
                    checked={selectedMailType.mailTemplateId === mailTemplateId}
                    onChange={() => setSelectedMailType({ mailTemplateId, id })}
                  />
                  <label htmlFor={mailTemplateId}>
                    {t('recruiter-deprecated:CANDIDATE_PROFILE.Mails.' + translate)}
                  </label>
                </div>
              );
            })}
          </div>
        </TabItem>
        <TabItem collapsed={activeTab === 'Custom'} id="Manual" title={t('recruiter:GENERAL.MAILS.Manual')}>
          <div className="checkbox-group-container">
            {mailTypes['manual'].map(({ mailTemplateId, id, label }) => {
              const translate = mailTemplateId.replace(/\s/g, '');
              return (
                <div
                  key={mailTemplateId + id}
                  className="checkbox-container feature-mail-overview__item"
                  onClick={() => {
                    setSelectedMailType({ mailTemplateId, id });
                    setShowCustomEmail(mailTemplateId === 'Custom');
                  }}
                >
                  <input
                    id={mailTemplateId}
                    type="radio"
                    checked={selectedMailType.mailTemplateId === mailTemplateId}
                    onChange={() => setSelectedMailType({ mailTemplateId, id })}
                  />
                  <label htmlFor={mailTemplateId}>
                    {t('recruiter-deprecated:CANDIDATE_PROFILE.Mails.' + translate)}
                  </label>
                </div>
              );
            })}
          </div>
        </TabItem>
      </Tabs>
    </div>
  );
};

export default MailList;
