import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { DataTable, DataTableTopToolbar, Searchbox, TabItem, Tabs } from '@adeccoux/tag-ds';
import OOTableContainer from '@components/admin/Table/OOTableContainer';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import TelemetryChart from './components/TelemetryChart';
import { TelemetryTable } from './components/TelemetryTable';

enum Tab {
  candidateEvents = 'candidateEvents',
  chart = 'chart',
}

export const TelemetryPage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [candidateId, setCandidateId] = useState('');
  const [activeTab, setActiveTab] = useState<Tab>(Tab.candidateEvents);

  const aamBackendApi = useInjection(AamBackendApi);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { pathname, search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const tabFromUrl = new URLSearchParams(search).get('tabName');

    if (tabFromUrl) {
      setActiveTab(tabFromUrl as Tab);
    }
  }, [search]);

  useEffect(() => {
    const searchValue = new URLSearchParams(search).get('search');

    if (searchValue) {
      setCandidateId(searchValue);
    }
  }, [search]);

  const data = { page, limit: rowsPerPage, candidateId };

  const isUuid = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const handleTabChange = (tabName: string) => {
    const idFromUrl = new URLSearchParams(search).get('search');

    if (idFromUrl) {
      history.replace({ pathname: pathname, search: `search=${idFromUrl}&tabName=${tabName}` });
    } else {
      history.replace({ pathname: pathname, search: 'tabName=' + tabName });
    }
  };

  const { data: responseTelemetryData } = useQuery({
    queryKey: ['telemetryData', tenantId, data],
    queryFn: () => aamBackendApi.getTelemetryEvents(tenantId, data),
    refetchOnMount: true,
    enabled: isUuid.test(candidateId),
  });

  const handleSearch = (candidateId: string) => {
    setCandidateId(candidateId);
    history.replace({ pathname: pathname, search: 'search=' + candidateId });
  };

  return (
    <div className="feature-dashboard">
      <PageWrapper
        featureName="telemetry"
        pageName="telemetry"
        sideMenu
        headerConfig={{ pageName: 'Telemetry', languagePicker: false }}
      >
        <OOTableContainer>
          <div className="tag-ds App feature-dashboard">
            <DataTable>
              <DataTableTopToolbar className="tag-ds" style={{ justifyContent: 'flex-start' }}>
                <Tabs onChangeTab={handleTabChange} defaultActiveTab={activeTab}>
                  <TabItem id={Tab.candidateEvents} title="Candidate events" />
                  <TabItem id={Tab.chart} title="Aggregation chart" />
                </Tabs>
                <div style={{ display: activeTab === Tab.chart ? 'none' : 'block', width: '100%' }}>
                  <Searchbox
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder="Search"
                    small
                    value={candidateId}
                  />
                </div>
              </DataTableTopToolbar>
            </DataTable>
            {activeTab === Tab.candidateEvents && (
              <TelemetryTable
                telemetryList={responseTelemetryData}
                pagination={{ setRowsPerPage, setPage, rowsPerPage, page }}
              />
            )}
            {activeTab === Tab.chart && <TelemetryChart />}
          </div>
        </OOTableContainer>
      </PageWrapper>
    </div>
  );
};
