import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Table, TBody, TElement, THead, TRow } from '@adeccoux/tag-ds';

interface MailTableProps {
  mailHistory: any[];
  dateFormat: any;
  resend: (templateId: string, mailId: string, id: string) => void;
  openDetails: (id: string) => void;
}

const MailTable: React.FC<MailTableProps> = ({ mailHistory, dateFormat, resend, openDetails }) => {
  const { t } = useTranslation(['recruiter', 'recruiter-deprecated']);
  const getTranslationId = (templateId: any) => templateId?.replace(/\s/g, '');

  const renderTagClass = (status: 'Failed' | 'Succeeded' | 'RetryStopped') => {
    const tags = {
      Failed: 'status-tag error ',
      Succeeded: 'status-tag_success ',
      RetryStopped: 'tag-ds status-tag',
    };

    if (!tags[status]) return 'tag-ds status-tag';

    return tags[status];
  };

  return (
    <div className="tag-ds ">
      <Table>
        <THead>
          <TRow>
            <TElement>{t('recruiter-deprecated:CANDIDATE_PROFILE.Mails.Sent')}</TElement>
            <TElement>{t('recruiter-deprecated:CANDIDATE_PROFILE.Mails.Mail')}</TElement>
            <TElement>{t('recruiter-deprecated:CANDIDATE_PROFILE.Mails.Type')}</TElement>
            <TElement>{t('recruiter-deprecated:CANDIDATE_PROFILE.Mails.status')}</TElement>
            <TElement />
          </TRow>
        </THead>
        <TBody>
          {mailHistory.map((mail, index) => {
            const translationId = getTranslationId(mail.mailTemplateId);
            return (
              <TRow key={mail.id + index}>
                <TElement>{format(new Date(mail.createdAt), `${dateFormat} - HH:mm:ss`)}</TElement>
                <TElement>{t(`recruiter-deprecated:CANDIDATE_PROFILE.Mails.${translationId}`)}</TElement>
                <TElement>{t(`recruiter-deprecated:CANDIDATE_PROFILE.Mails.${mail.type}`)}</TElement>
                <TElement>
                  <div className={renderTagClass(mail.status)}>
                    {t(`recruiter-deprecated:CANDIDATE_PROFILE.Mails.${mail.status}`)}
                  </div>
                </TElement>

                <TElement>
                  <div className="tag-ds ">
                    <button
                      className="button-secondary_small"
                      onClick={() => {
                        openDetails(mail.id);
                      }}
                      style={{ marginRight: 16 }}
                    >
                      <span className="material-icons">notes</span>
                    </button>
                  </div>
                  <div className="tag-ds ">
                    <button
                      className="button-secondary_small"
                      onClick={() => {
                        resend(mail.mailTemplateId, mail.mailId, mail.id);
                      }}
                    >
                      <span className="material-icons">send</span>
                    </button>
                  </div>
                </TElement>
              </TRow>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
};

export default MailTable;
