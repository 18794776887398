import React, { useContext, useEffect, useState, VoidFunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Notification as NotificationTagDs, NotificationBody, NotificationHeader } from '@adeccoux/tag-ds';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { Loader } from '@components/shared/Loader/Loader';
import { OOPages } from '@features/oneOnboarding/interfaces';
import CustomCandidateProfileSidebar from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/CustomCandidateProfileSidebar';
import { useCandidateProfileData } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/hooks';
import { PageStepContext } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/PageStepContext';
import { PageStepProvider } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/PageStepProvider';
import { useGetUserFlow } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { useT } from '../../../oneOnboarding/hooks/useT';
import { OOPageModel } from '../../../oneOnboarding/models/FormModel';
import { OOFlowWrapper } from '../../../oneOnboarding/wrappers/FlowWrapper';
import { CandidateProfileSelectedPage } from './CandidateProfileSelectedPage/CandidateProfileSelectedPage';
import { CandidateProfileSidebar } from './CandidateProfileSidebar/CandidateProfileSidebar';

import styles from './NewCandidateProfilePage.module.scss';

const CandidateProfilePageComponent: VoidFunctionComponent = () => {
  const { t } = useT('stepper', 'recruiter');
  const { page, step, setPageStepContext } = useContext(PageStepContext);
  const [isInvalidStep, setIsInvalidStep] = useState<boolean>(false);
  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();

  const removeJumpConditionFalseSteps = (steps: any, stepName: any): any => {
    return steps?.map((step: any) => {
      if (step.children) {
        step.children = step.children.filter((child: any) => !stepName.includes(child.step));
      }
      return step;
    });
  };

  const stepsRemovedCouseOfJumpCondition = (steps: any): any => {
    const result: string[] = [];
    steps?.forEach((step: any) => {
      for (let i = 0; i < step.pages.length; i++) {
        const page = step.pages[i];
        if (page.jumpCondition) {
          const jumpConditions = page.jumpCondition.conditions;
          const conditionNotMet = jumpConditions.every((condition: any) => condition.value === condition.control.value);

          if (conditionNotMet) {
            for (let k = i + 1; k < step.pages.length; k++) {
              result.push(step.pages[k].name);
            }
            break;
          }
        }
      }
    });

    return result;
  };

  const { data, isLoading } = useGetUserFlow(clientOrganizationId, configurationId, userId, applicationId, false);
  let recruiterSidebar = isLoading ? [] : data?.selectedConfiguration?.recruiterSidebar;
  const {
    steps,
    allSteps,
    currentStep,
    flowWrapper,
    userFlow,
    fetchFlowLoading,
    currentPage,
  } = useCandidateProfileData();
  const tenantId = useSelector((state: RootState) => state.tenant).tenantId;

  const { createTelemetryAuthenticated } = useTelemetry();

  const stepsToRemove = stepsRemovedCouseOfJumpCondition(allSteps);
  recruiterSidebar = removeJumpConditionFalseSteps(recruiterSidebar, stepsToRemove);

  useEffect(() => {
    createTelemetryAuthenticated({
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: configurationId,
      eventType: EventType.PAGE_LOADED,
      targetName: TargetName.RecruiterCandidateDetails,
      tenantId: tenantId,
      candidateId: userId,
      actor: TelemetryActor.RECRUITER,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (steps.length && flowWrapper) {
      if (currentStep && step === undefined) {
        if (currentStep.name === 'DOCUMENT_UPLOAD' || currentStep.name === 'DOCUMENT_UPLOAD2') {
          const documentUploadStep = flowWrapper.getStep(currentStep.name);
          setPageStepContext({ step: Object.assign({}, step, documentUploadStep), page: undefined });
        } else if (currentStep.name === 'DOCUMENT_VALIDATION2') {
          setPageStepContext({ step: flowWrapper.getStep('DOCUMENT_UPLOAD2'), page: undefined });
        } else {
          setPageStepContext({ step: currentStep, page: page ?? currentPage ?? currentStep.pages[0] });
        }
      }
      const checkIsValidStep = OOFlowWrapper.isStepValid(userFlow?.selectedConfiguration?.flow, userFlow?.currentStep);
      if (!checkIsValidStep) {
        setIsInvalidStep(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowWrapper, steps.length]);

  if (fetchFlowLoading || flowWrapper === undefined) {
    return <Loader />;
  }

  return (
    <>
      {isInvalidStep && (
        <NotificationTagDs
          icon={<span className="material-icons">info_outline</span>}
          iconClose
          onClose={() => setIsInvalidStep(false)}
          error
          show
          className={`tag-ds ${styles.notification}`}
        >
          <NotificationHeader>{t('recruiter:GENERAL.CANDIDATES_OVERVIEW.error')}</NotificationHeader>
          <NotificationBody>
            <Typography>{t('recruiter:GENERAL.CANDIDATES_OVERVIEW.errorInvalidStep')}</Typography>
          </NotificationBody>
        </NotificationTagDs>
      )}
      <PageWrapper
        featureName="candidate-profile"
        pageName="new-candidates-profile"
        sideMenu
        headerConfig={{ pageName: t('recruiter:GENERAL.CANDIDATE_DETAILS.Candidate details'), languagePicker: true }}
      >
        <div className={`${styles.candidateProfilePage} admin`}>
          {!!flowWrapper || isLoading ? (
            <>
              {recruiterSidebar && recruiterSidebar.length > 0 ? (
                <CustomCandidateProfileSidebar
                  items={recruiterSidebar}
                  flowWrapper={flowWrapper}
                  steps={allSteps}
                  currentStep={currentStep?.name || 'CANDIDATE_DETAILS'}
                  currentPage={currentPage?.name as OOPages}
                />
              ) : (
                <CandidateProfileSidebar
                  onSelect={(step, page) => {
                    setPageStepContext({ step, page });
                  }}
                  flowWrapper={flowWrapper}
                  steps={steps}
                  allSteps={allSteps}
                  currentStep={currentStep?.name || 'CANDIDATE_DETAILS'}
                />
              )}
            </>
          ) : (
            <Loader />
          )}

          <div className={styles.content}>
            <CandidateProfileSelectedPage
              page={page}
              step={step}
              flowWrapper={flowWrapper}
              setPage={(page: OOPageModel) => setPageStepContext({ page, step })}
            />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export const CandidateProfilePage: VoidFunctionComponent = () => (
  <PageStepProvider>
    <CandidateProfilePageComponent />
  </PageStepProvider>
);
