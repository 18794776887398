interface DecodeApplicationIdPayload {
  [key: string]: any;
  applicationId: string;
}

export const onceEncodeURIComponent = (text: string): string => {
  return text ? encodeURIComponent(text) : '';
};

export const doubleEncodeURIComponent = (text: string): string => {
  return text ? encodeURIComponent(encodeURIComponent(text)) : '';
};

export const doubleDecodeURIComponent = (text: string): string => {
  return text ? decodeURIComponent(decodeURIComponent(text)) : '';
};

export const decodeApplicationId = (obj: DecodeApplicationIdPayload): DecodeApplicationIdPayload => {
  return obj?.applicationId
    ? {
        ...obj,
        applicationId: doubleDecodeURIComponent(obj?.applicationId),
      }
    : obj;
};
