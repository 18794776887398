import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownButton, DropdownMenu } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { mutateDraftConfiguration } from '@hooks/apiHooks';
import { useGetUrlParameter } from '@hooks/useGetUrlParameter';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';
import ActivityHistory from './ActivityHistory';

const ConfigurationActions = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activityHistoryActive, setActivityHistoryActive] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { selectedConfiguration, configurationChangeStack, isPublished } = useSelector(
    (store: RootState) => store.selfService,
  );
  const draftId = useGetUrlParameter('draftId') || '';
  const clientOrganizationId = useGetUrlParameter('clientOrganizationId') || '';
  const configurationId = useGetUrlParameter('configurationId') || '';
  const history = useHistory();

  const dispatch = useDispatch();
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = localStorage.getItem('tenantId');
  const { mutate, isLoading: isLoadingMutation } = mutateDraftConfiguration(
    selectedConfiguration?.clientOrganization?.id || '',
    draftId,
    queryClient,
  );
  const { t } = useTranslation(['candidate_recruiter', 'recruiter']);

  const downloadConfiguration = async () => {
    try {
      const fileName = `${selectedConfiguration?.candidateName}-${new Date()}.json`;

      const url = window.URL.createObjectURL(
        new Blob([JSON.stringify(selectedConfiguration, null, 2)], { type: 'application/json' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      dispatch(
        snackbarSlice.actions.showSuccess({
          message: 'Successfully exported configuration',
        }),
      );
    } catch (err) {
      let msg;

      if (err.response?.data?.error?.data) {
        const { message } = err.response.data.error.data;
        if (message) {
          msg = message;
        }
      }

      dispatch(
        snackbarSlice.actions.showError({
          message: 'Configuration export error: ' + (msg || err),
        }),
      );
    }
  };

  const uploadConfiguration = async () => {
    if (!selectedConfiguration) return;

    setIsLoading(true);
    const formData: any = new FormData();

    const objJsonStr = JSON.stringify(selectedConfiguration);

    formData.append('file', objJsonStr);

    const payload: any = {
      fromClientOrganizationId: clientOrganizationId || '',
      fromConfigurationId: configurationId || '',
      toClientOrganizationId: selectedConfiguration.clientOrganization?.id || '',
      toConfigurationId: selectedConfiguration.configurationId || '',
      isUpdate: isPublished,
      clientOrganizationName: selectedConfiguration.recruiterName,
    };

    Object.keys(payload).forEach((x) => {
      formData.append(x, payload[x]);
    });

    try {
      const response = await aamBackendApi.importCsvSelfService(Number(tenantId), formData);

      if (response?.error) {
        throw new Error(response?.error);
      }

      dispatch(
        snackbarSlice.actions.showSuccess({
          message: 'Successfully uploaded configuration',
        }),
      );
      setIsLoading(false);

      history.push('/oo/recruiter/self-service');
    } catch (err) {
      let msg;

      if (err.response?.data?.error?.data) {
        const { message } = err.response.data.error.data;
        if (message) {
          msg = message;
        }
      }

      dispatch(
        snackbarSlice.actions.showError({
          message: 'Configuration upload error: ' + (msg || err),
        }),
      );
      setIsLoading(false);
    }
  };

  const onSave = () => {
    mutate({
      data: {
        configuration: selectedConfiguration,
        clientOrganizationId: selectedConfiguration?.clientOrganization?.id || '',
        configurationId: selectedConfiguration?.configurationId || '',
        configurationChangeStack: configurationChangeStack,
      },
      tenantId,
    });
  };

  return (
    <div className="tag-ds" style={{ display: 'flex', gap: '1rem' }}>
      <Dropdown>
        <DropdownButton className="button-secondary">
          <span className="material-icons">more_horiz</span>
        </DropdownButton>
        <DropdownMenu>
          <p
            className="base"
            style={{ display: 'flex', alignItems: 'center', padding: '8px 16px', margin: 0, cursor: 'pointer' }}
            onClick={downloadConfiguration}
          >
            <span className="material-icons-outlined" style={{ fontSize: 16, marginRight: 8 }}>
              download
            </span>
            Export Client Form
          </p>
          <p
            className="base"
            style={{ display: 'flex', alignItems: 'center', padding: '8px 16px', margin: 0, cursor: 'pointer' }}
            onClick={() => setActivityHistoryActive(true)}
          >
            <span className="material-icons" style={{ fontSize: 16, marginRight: 8 }}>
              history
            </span>
            Activity History
          </p>
        </DropdownMenu>
      </Dropdown>
      <button className="button-secondary" onClick={onSave} disabled={isLoadingMutation}>
        <span style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          {t('recruiter:GENERAL.SELF_SERVICE.saveChanges', 'Save changes')}
        </span>
      </button>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          onClick={uploadConfiguration}
          disabled={isLoading}
          className={clsx('button', isLoading && 'button_loading')}
        >
          Publish Client Form
          {isLoading && <div className="spinner" style={{ marginLeft: 16 }} />}
        </button>
        <SelfServiceTooltip
          hoverContent="Create and add your desired field steps for your candidate(s) to fill out."
          position="top"
        />
      </div>

      <ActivityHistory
        handleDrawer={(isActive: boolean) => setActivityHistoryActive(isActive)}
        isActive={activityHistoryActive}
      />
    </div>
  );
};

export default ConfigurationActions;
