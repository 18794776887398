import React, { FC, useEffect, useReducer, useRef, useState } from 'react';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { useStore } from 'react-redux';
import { OOFormControl } from '@features/oneOnboarding/controls/FormControl';
import { parseValidationErrors } from '@features/oneOnboarding/helpers';
import { mandatoryCondition } from '@features/oneOnboarding/hooks/useRenderCondition';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OOControlModel } from '@features/oneOnboarding/models/ControlModel';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { mutateCompleteStep, mutateStepData } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { RootState } from '@store/rootReducer';
import { setUnsavedChangesExist } from '@store/slices/oneOnboarding.slice';
import { useQueryClient } from '@tanstack/react-query';

import Notification, { useNotification } from '../../../../../components/admin/Notification/Notification';

import styles from './HireCandidate.module.scss';

export interface GeneraPageComponentProps {
  page: OOPageModel;
  stepName: OOStepsEnum;
  flowWrapper: OOFlowWrapper;
  completeStep: boolean;
}
export const HireCandidate: FC<GeneraPageComponentProps> = ({ page, flowWrapper, stepName, completeStep }) => {
  const [initValue, setInitValue] = useState(page.getInitialValues(flowWrapper));
  const [manualErrors, setManualErrors] = useState<Map<string, any>>(new Map());

  const { t } = useT('recruiter', 'hiring', 'candidate_recruiter');
  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const queryClient = useQueryClient();

  const { isActive, notificationType, notificationDetails, triggerNotification, closeNotification } = useNotification();

  const { mutate } = mutateStepData(clientOrganizationId, configurationId, userId, applicationId, queryClient);
  const formikRef = useRef<any>();
  const [changeCounter, setChangeCounter] = useState<number>(0);
  const { mutate: completeStepForUser } = mutateCompleteStep(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    stepName,
    queryClient,
  );
  const { createTelemetryAuthenticated } = useTelemetry();

  useEffect(() => {
    createTelemetryAuthenticated({
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: configurationId,
      eventType: EventType.PAGE_LOADED,
      targetName: TargetName.RecruiterHiringData,
      tenantId: store.getState().tenant.tenantId,
      candidateId: userId,
      actor: TelemetryActor.RECRUITER,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageControlsValues = (page: any) => {
    return page.controls.reduce((acc: any, control: any) => {
      acc[control.name] = control.value;
      return acc;
    }, {} as Record<string, any>);
  };
  const validationSchema = page.getValidationSchema(flowWrapper, t);
  // https://stackoverflow.com/a/66436476
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const onControlChanged = () => {
    setInitValue(pageControlsValues(page));
    forceUpdate();
  };
  const [dynamicValidationSchema, setDynamicValidationSchema] = React.useState(validationSchema);
  const store = useStore<RootState>();
  useEffect(() => {
    store.dispatch(setUnsavedChangesExist(!isEqual(formikRef.current?.values, formikRef.current?.initialValues)));
    setDynamicValidationSchema(
      page.getValidationSchema(flowWrapper, t, formikRef.current?.values, undefined, manualErrors),
    );
    if (formikRef.current) {
      formikRef.current.validateForm(formikRef.current?.values);
    }
  }, [changeCounter, flowWrapper, page, store, t, manualErrors]);
  const onFormChange = () => {
    setChangeCounter((prevValue: number) => prevValue + 1);
  };
  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      innerRef={formikRef}
      validateOnChange={true}
      initialValues={initValue}
      validationSchema={dynamicValidationSchema}
      onSubmit={async (values, actions) => {
        const data = {
          data: { ...values },
          currentStep: stepName,
          currentPage: page.name,
        };
        actions.setSubmitting(true);
        OOFlowWrapper.prepareValues(page.controls, data.data);

        try {
          await mutate(data);
          if (completeStep) {
            await completeStepForUser();
          }
        } catch (err) {
          if (err?.response?.data?.error?.data?.message) {
            const parsed = parseValidationErrors(t, err);
            if (parsed.errors) {
              actions.setErrors(parsed.errors);
            }
          }
        } finally {
          triggerNotification('success', { header: t('candidate_recruiter:GENERAL.GENERIC.successfullSaveMessage') });
          actions.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values, errors, setTouched, touched }) => (
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className={styles.form}
          onChange={(e) => {
            const key = (e?.target as any)?.name as string;
            const value = (e?.target as any)?.value as any;
            if (key && formikRef.current.controls && formikRef.current.controls.length) {
              const newToched = formikRef.current.controls
                ?.filter(
                  (c: any) => c.mandatoryCondition && mandatoryCondition(c, flowWrapper, { ...values, [key]: value }),
                )
                ?.map((c: any) => c.name);

              const prevToched = formikRef.current.controls
                ?.filter(
                  (c: any) =>
                    c.mandatoryCondition && !newToched.includes(c.name) && mandatoryCondition(c, flowWrapper, values),
                )
                ?.map((c: any) => c.name);

              if (newToched.length || prevToched.length) {
                const addTouched = {} as Record<string, any>;
                const removeTouched = {} as Record<string, any>;
                prevToched.forEach((key: string) => {
                  removeTouched[key] = false;
                });
                newToched.forEach((key: string) => {
                  addTouched[key] = true;
                });
                setTouched({ ...touched, ...removeTouched, ...addTouched });
              }
            }
            onFormChange();
          }}
          onClick={(e) => {
            onFormChange();
            const addTouched = {} as Record<string, any>;
            Object.keys(errors).forEach((key: string) => {
              addTouched[key] = true;
            });
            if (!isEmpty(addTouched) && (e.target as any).type === 'submit') {
              setTouched({ ...touched, ...addTouched }, true);
            }
          }}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <>
            <div className={styles.submit} />

            {page.controls
              .filter((c) => c.isVisibleRecruiter)
              .map((control: OOControlModel, index) => (
                <OOFormControl
                  control={control}
                  key={control.name + index + stepName}
                  otherFormControls={page.controls}
                  onChangeCallback={onControlChanged}
                  setManualErrors={setManualErrors}
                />
              ))}
          </>
          <div className="tag-ds">
            <button type="submit">{t(`candidate_recruiter:GENERAL.GENERIC.submit`)} </button>
          </div>
          <Notification
            type={notificationType}
            details={notificationDetails}
            isActive={isActive}
            hide={closeNotification}
          />
        </form>
      )}
    </Formik>
  );
};
