import { addSeconds } from 'date-fns';
import { UserModel } from '@models/user.model';
import { UserProfileModel } from '@models/userProfile.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthStoreState } from './auth.interfaces';

export const localStorageKey = 'redux_app_auth_state';

const localStorageData = localStorage.getItem(localStorageKey);
const lsd = localStorageData ? JSON.parse(localStorageData) : undefined;

const initialState: any = {
  authMethod: lsd?.authMethod ?? null,
  idToken: lsd?.idToken ?? null,
  refreshToken: lsd?.refreshToken ?? null,
  idTokenExpiresAt: lsd?.idTokenExpiresAt ?? null,
  notBefore: lsd?.notBefore ?? null,
  profileInfo: lsd?.profileInfo ?? null,
  refreshTokenExpiresAt: lsd?.refreshTokenExpiresAt ?? null,
  scope: lsd?.scope ?? null,
  tokenType: lsd?.tokenType ?? null,
  userId: lsd?.userId,
  user: null,
  userProfile: null,
  randomFlag: false,
  sessionExpired: lsd?.sessionExpired ?? false,
  logo: null,
};

const saveToLocalStorage = (state: AuthStoreState) => {
  if (state.authMethod === null && state.idToken === null) {
    localStorage.removeItem(localStorageKey);
    return;
  }
  localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      authMethod: state.authMethod,
      idToken: state.idToken,
      idTokenExpiresAt: state.idTokenExpiresAt ? state.idTokenExpiresAt.valueOf() : null,
      notBefore: state.notBefore ? state.notBefore.valueOf() : null,
      profileInfo: state.profileInfo,
      refreshToken: state.refreshToken,
      refreshTokenExpiresAt: state.refreshTokenExpiresAt ? state.refreshTokenExpiresAt.valueOf() : null,
      scope: state.scope,
      tokenType: state.tokenType,
      userId: state.userId,
      sessionExpired: state.sessionExpired,
    }),
  );
};

export const authAppSlice = createSlice({
  name: 'authApp',
  initialState: initialState as AuthStoreState,
  reducers: {
    authB2C: (
      state,
      action: PayloadAction<{
        data: {
          idToken: string;
          refreshToken: string;
          idTokenExpiresIn: number;
          notBefore: number;
          profileInfo: string;
          refreshTokenExpiresIn: number;
          scope: string;
          tokenType: string;
        };
        userId: string;
      }>,
    ) => {
      state.authMethod = 'b2c';
      state.userId = action.payload.userId;
      state.idToken = action.payload.data.idToken;
      state.idTokenExpiresAt = addSeconds(new Date(), action.payload.data.idTokenExpiresIn).valueOf();
      state.notBefore = action.payload.data.notBefore * 1000;
      state.profileInfo = action.payload.data.profileInfo;
      state.refreshToken = action.payload.data.refreshToken;
      state.refreshTokenExpiresAt = addSeconds(new Date(), action.payload.data.refreshTokenExpiresIn).valueOf();
      state.scope = action.payload.data.scope;
      state.tokenType = action.payload.data.tokenType;
      saveToLocalStorage(state);
      saveToLocalStorage(state);
    },
    refreshRandomFlag: (state) => {
      state.randomFlag = !state.randomFlag;
      return state;
    },
    refreshB2C: (
      state,
      action: PayloadAction<{
        data: {
          idToken: string;
          refreshToken: string;
          idTokenExpiresIn: number;
          notBefore: number;
          profileInfo: string;
          refreshTokenExpiresIn: number;
          scope: string;
          tokenType: string;
        };
      }>,
    ) => {
      state.authMethod = 'b2c';
      state.idToken = action.payload.data.idToken;
      state.idTokenExpiresAt = addSeconds(new Date(), action.payload.data.idTokenExpiresIn).valueOf();
      state.notBefore = action.payload.data.notBefore * 1000;
      state.profileInfo = action.payload.data.profileInfo;
      state.refreshToken = action.payload.data.refreshToken;
      state.refreshTokenExpiresAt = addSeconds(new Date(), action.payload.data.refreshTokenExpiresIn).valueOf();
      state.scope = action.payload.data.scope;
      state.tokenType = action.payload.data.tokenType;
      saveToLocalStorage(state);
    },
    authAdeccoAD: (
      state,
      action: PayloadAction<{
        data: any;
        userId: string;
      }>,
    ) => {
      state.userId = action.payload.userId;
      state.authMethod = 'adeccoAD';
      state.idToken = action.payload.data.accessToken;
      state.idTokenExpiresAt = addSeconds(new Date(), action.payload.data.idTokenExpiresIn).valueOf();
      state.notBefore = action.payload.data.notBefore * 1000;
      state.profileInfo = action.payload.data.profileInfo;
      state.refreshToken = action.payload.data.refreshToken;
      state.refreshTokenExpiresAt = addSeconds(new Date(), action.payload.data.refreshTokenExpiresIn).valueOf();
      state.scope = action.payload.data.scope;
      state.tokenType = action.payload.data.tokenType;
      saveToLocalStorage(state);
    },
    refreshAdeccoAD: (
      state,
      action: PayloadAction<{
        data: any;
      }>,
    ) => {
      state.authMethod = 'adeccoAD';
      state.idToken = action.payload.data.access_token;
      state.idTokenExpiresAt = addSeconds(new Date(), action.payload.data.expires_in).valueOf();
      state.notBefore = action.payload.data.not_before * 1000;
      state.refreshToken = action.payload.data.refresh_token;
      state.scope = action.payload.data.scope;
      state.tokenType = action.payload.data.token_type;
      saveToLocalStorage(state);
    },
    authApp20: (
      state,
      action: PayloadAction<{
        idToken: string;
        refreshToken: string;
        userId: string;
        user: UserModel;
        userProfile: UserProfileModel;
      }>,
    ) => {
      state.user = action.payload.user;
      state.userProfile = action.payload.userProfile;
      state.userId = action.payload.user.id;
      state.authMethod = 'app20';
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      saveToLocalStorage(state);
    },
    userData: (
      state,
      action: PayloadAction<{
        user: UserModel;
        userProfile: UserProfileModel;
      }>,
    ) => {
      state.user = action.payload.user;
      state.userProfile = action.payload.userProfile;
    },
    clear: (state, action: PayloadAction<undefined | boolean>) => {
      state.authMethod = null;
      state.idToken = null;
      state.refreshToken = '';
      state.idTokenExpiresAt = null;
      state.notBefore = null;
      state.profileInfo = null;
      state.refreshTokenExpiresAt = null;
      state.scope = null;
      state.tokenType = null;
      state.userId = null;
      state.user = null;
      state.userProfile = null;
      state.sessionExpired = action.payload;
      state.logo = null;
      saveToLocalStorage(state);
    },
    noop: (state, action: PayloadAction<UserModel | undefined>) => state,
    updateEmail: (state, action: PayloadAction<string>) => {
      state.user!.email = action.payload;
      return state;
    },
    updateLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
      return state;
    },
    updateUserProfile: (state, action: PayloadAction<UserProfileModel>) => {
      state.userProfile = action.payload;
      return state;
    },
  },
});
