import React from 'react';
import { BranchModel } from '@models/branch.model';
import { RegionModel } from '@models/region.model';
import { Collapse } from '@mui/material';

import { CheckedRegionsAndBranches } from '../UserPermissionDetailsPage';
import { ListType } from './NestedListSelector';

import styles from '../UserPermissions.module.scss';

export type RegionsAndBranches = {
  regionId: string;
  regionDetails: RegionModel;
  branches: BranchModel[];
};

interface RegionsAndBranchesListProps {
  options: RegionsAndBranches[];
  checkedRegionsAndBranches: CheckedRegionsAndBranches;
  handleChange: (regionId: string, branchId: string | null) => void;
  isDisabled: boolean;
  handleCollapse: (
    type: ListType,
    itemId: string,
  ) => {
    set: any;
    get: any;
  };
  userSearchBranches?: string;
}

const RegionsAndBranchesList: React.FC<RegionsAndBranchesListProps> = ({
  options,
  checkedRegionsAndBranches,
  handleChange,
  isDisabled,
  handleCollapse,
  userSearchBranches,
}) => {
  return (
    <div className={styles.nestedList}>
      {options.map((x) => {
        const isRegionChecked = checkedRegionsAndBranches.regionIds.find((y) => y === x.regionId);
        const getAllBranchesForRegion = options.find((y) => y.regionId === x.regionId)?.branches;
        const compareBranches = getAllBranchesForRegion?.filter((y) => {
          return checkedRegionsAndBranches.branchIds.includes(y.id);
        });
        const collapseConfig = handleCollapse(ListType.REGIONS_AND_BRANCHES, x.regionId);
        const isCollapsed = collapseConfig.get.includes(x.regionId);

        const isIntermediate = getAllBranchesForRegion?.length !== compareBranches?.length;

        return (
          <div key={x.regionId} className={styles.nestedListParent}>
            <div className="tag-ds checkbox-container" style={{ margin: 0 }}>
              <span
                className="material-icons"
                onClick={collapseConfig.set}
                style={{ transform: isCollapsed ? 'rotate(180deg)' : '' }}
              >
                arrow_drop_down
              </span>
              <input
                id={x.regionId}
                type="checkbox"
                checked={!!isRegionChecked}
                onChange={() => handleChange(x.regionId, null)}
                className={isIntermediate ? 'intermediate' : ''}
                disabled={isDisabled}
              />
              <label htmlFor={x.regionId} className={styles.checkboxLabel}>
                {x.regionDetails.name}
              </label>
            </div>
            <Collapse in={!isCollapsed}>
              {x.branches
                .filter((x) =>
                  !userSearchBranches
                    ? x
                    : x.name.match(new RegExp(userSearchBranches, 'i')) ||
                      x.sid?.match(new RegExp(userSearchBranches, 'i')),
                )
                .map((z) => {
                  const isBranchChecked = checkedRegionsAndBranches.branchIds.find((j) => j === z.id);
                  return (
                    <div className="tag-ds checkbox-container" key={z.id + x.regionId} id={styles.childContainer}>
                      <input
                        id={z.id + x.regionId}
                        type="checkbox"
                        checked={!!isBranchChecked}
                        onChange={() => handleChange(x.regionId, z.id)}
                        disabled={isDisabled}
                      />
                      <label htmlFor={z.id + x.regionId} className={styles.checkboxLabel}>
                        {z.name + ' (' + z.sid + ')'}
                      </label>
                    </div>
                  );
                })}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default RegionsAndBranchesList;
