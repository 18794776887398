import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { Searchbox } from '@adeccoux/tag-ds';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { useGetUrlParameter } from '@hooks/useGetUrlParameter';
import { CircularProgress } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import NewConfigurationActions from './components/NewConfigurationActions';

import styles from './NewConfiguration.module.scss';

const ConfigurationBase = () => {
  const [selectedTemplateConfiguration, setSelectedTemplateConfiguration] = useState<null | {
    id: string;
    clientOrganizationId: string;
    recruiterName: string;
  }>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const history = useHistory();
  const aamBackendApi = useInjection(AamBackendApi);
  const draftId = useGetUrlParameter('draftId');
  const { data, isLoading } = useQuery({
    queryKey: ['allConfigurationsForAllTenants'],
    queryFn: () => aamBackendApi.fetchAllConfigurationsForAllTenants('x'),
    refetchOnMount: true,
  });

  const currentTenantConfigs = data?.filter((c) => c.tenantId === tenantId);

  const renderedData = useMemo(() => {
    if (!searchTerm) return currentTenantConfigs;

    return currentTenantConfigs?.filter((configuration) =>
      configuration.recruiterName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [currentTenantConfigs, searchTerm]);

  return (
    <PageWrapper
      pageName="newConfiguration"
      featureName="newConfiguration"
      sideMenu
      headerConfig={{
        pageName: 'Create new configuration',
        languagePicker: true,
        onBack: () => {
          history.push('/oo/recruiter/self-service');
        },
      }}
    >
      {isLoading ? (
        <CircularProgress className={styles.loader} />
      ) : (
        <div className={styles.base__container}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 32, marginTop: 32 }}>
            <p className="tag-ds subtitle">Select Base Configuration</p>
            <SelfServiceTooltip hoverContent="There are XX types of templates for xyz use case" position="right" />
          </div>
          <Searchbox
            className="tag-ds input-container "
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search template"
            value={searchTerm}
            small
          />
          <div style={{ marginBottom: 70 }}>
            {currentTenantConfigs &&
              !isLoading &&
              renderedData
                ?.sort((a, b) => (a.recruiterName > b.recruiterName ? 1 : -1))
                .map((x: any) => {
                  const uniqueId = x.id + x.clientOrganizationId;
                  const selectedId = selectedTemplateConfiguration?.id || '';
                  const selectedClientOrganizationId = selectedTemplateConfiguration?.clientOrganizationId || '';

                  return (
                    <div key={uniqueId} className={clsx('tag-ds radio-container', styles.base__option)}>
                      <input
                        id={uniqueId}
                        type="radio"
                        onChange={() => setSelectedTemplateConfiguration(x)}
                        value={uniqueId}
                        checked={selectedId + selectedClientOrganizationId === x.id + x.clientOrganizationId}
                        disabled={!!draftId}
                      />
                      <label htmlFor={uniqueId}>
                        <span className="tag-ds medium">{x.recruiterName}</span>
                      </label>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
      <NewConfigurationActions
        actions={[
          {
            disabled: !selectedTemplateConfiguration,
            icon: 'chevron_right',
            text: 'NEXT',
            onClick: () =>
              history.push(
                `/oo/recruiter/self-service/new-configuration/configuration-name?clientOrganizationId=${selectedTemplateConfiguration?.clientOrganizationId}&configurationId=${selectedTemplateConfiguration?.id}&configurationName=${selectedTemplateConfiguration?.recruiterName}`,
              ),
          },
        ]}
      />
    </PageWrapper>
  );
};

export default ConfigurationBase;
