import jwt from 'jsonwebtoken';
import { authAdminSlice } from '@store/slices/authAdmin.slice';
import { authAppSlice } from '@store/slices/authApp.slice';
import { featureConfigurationSlice } from '@store/slices/featureConfiguration.slice';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';

import { initLocalization } from '../i18n';
import { container } from '../inversify';
import { AamBackendApi } from '../libs/aamBackendApi';
import { store } from '../store';
import {
  getClientOrganizationId,
  getSelectedConfigurationId,
  getTenantByDomain,
  getTenantInfo,
  setSelectedConfigurationId,
  storeClientOrganization,
  storeTenant,
} from './tenant.helper';
import { Utils } from './utils';

export const initApp = async () => {
  const { tenantId } = getTenantByDomain() || getTenantInfo();
  storeTenant(tenantId);

  const authApp = store.getState().authApp;
  const aamBackendApi = container.get(AamBackendApi);
  const utils = container.get(Utils);

  await aamBackendApi.fetchFeatureConfigurations({
    dispatch: store.dispatch,
    setStatusAction: featureConfigurationSlice.actions.setStatus,
    setStatusName: 'fetchFeatureConfigurationsStatus',
    dataUpdateAction: featureConfigurationSlice.actions.setFeatureConfigurations,
  });

  const app20IdToken = utils.getQueryString('idToken');
  const app20RefreshToken = utils.getQueryString('refreshToken');
  let app20UserId;
  // Set token if provided from query string
  if (app20IdToken) {
    const payload = jwt.decode(app20IdToken);
    if (typeof payload?.sub === 'string') {
      app20UserId = payload?.sub;
    }
  }

  const appUserId = app20UserId || authApp.userId || null;
  const adminUserId = store.getState().authAdmin.userId;

  const onAdmin = window.location.pathname.startsWith('/admin');
  const onApp = window.location.pathname.startsWith('/app');
  const fetchAppUserPromise =
    appUserId && onApp ? aamBackendApi.fetchUser(tenantId, appUserId) : Promise.resolve(undefined);
  const fetchAppUserProfilePromise =
    appUserId && onApp ? aamBackendApi.fetchUserProfile(tenantId, appUserId) : Promise.resolve(undefined);
  const fetchAdminUserPromise =
    adminUserId && onAdmin ? aamBackendApi.fetchUser(tenantId, adminUserId) : Promise.resolve(undefined);
  const fetchAdminUserProfilePromise =
    adminUserId && onAdmin ? aamBackendApi.fetchUserProfile(tenantId, adminUserId) : Promise.resolve(undefined);

  try {
    const [appUser, appUserProfile, adminUser, adminUserProfile] = await Promise.all([
      fetchAppUserPromise,
      fetchAppUserProfilePromise,
      fetchAdminUserPromise,
      fetchAdminUserProfilePromise,
    ]);

    const queryParams = new URLSearchParams(window.location.search);
    const clientOrganizationIdFromQuery = queryParams.get('clientOrganizationId') || null;
    const configurationIdFromQuery = queryParams.get('configurationId') || null;
    const clientOrganizationId = clientOrganizationIdFromQuery ?? getClientOrganizationId();
    const selectedConfigurationId = configurationIdFromQuery ?? getSelectedConfigurationId();
    const campaignURLFromQuery = queryParams.get('campaignUrl') || null;
    if (campaignURLFromQuery) {
      const campaign = await aamBackendApi.fetchCampaign(campaignURLFromQuery);
      storeTenant(campaign.tenantId);
    }
    if (clientOrganizationId) {
      storeClientOrganization(clientOrganizationId);
      let languages: any[] = [];
      if (selectedConfigurationId) {
        setSelectedConfigurationId(selectedConfigurationId);
        languages = await aamBackendApi.fetchSelectedLanguages(clientOrganizationId, selectedConfigurationId, {
          dispatch: store.dispatch,
          setStatusName: 'fetchSelectedLanguages',
          dataUpdateAction: oneOnboardingSlice.actions.setLanguages,
        });
      } else {
        languages = await aamBackendApi.fetchClientOrganizationSelectedLanguages(clientOrganizationId, {
          dispatch: store.dispatch,
          setStatusName: 'fetchSelectedLanguages',
          dataUpdateAction: oneOnboardingSlice.actions.setLanguages,
        });
      }
      await initLocalization([...languages]);
    } else {
      await initLocalization();
    }

    if (!appUser || !appUserProfile) {
      if (onApp) {
        store.dispatch(authAppSlice.actions.clear());
      }
    } else {
      // if we had app20 tokens in qs override auth data
      if (app20UserId) {
        store.dispatch(
          authAppSlice.actions.authApp20({
            idToken: app20IdToken,
            refreshToken: app20RefreshToken,
            userId: app20UserId,
            user: appUser,
            userProfile: appUserProfile,
          }),
        );
      } else {
        store.dispatch(
          authAppSlice.actions.userData({
            user: appUser,
            userProfile: appUserProfile,
          }),
        );
      }
    }

    if (!adminUser || !adminUserProfile) {
      if (onAdmin) {
        store.dispatch(authAdminSlice.actions.clear());
      }
    } else {
      store.dispatch(
        authAdminSlice.actions.userData({
          user: adminUser,
          userProfile: adminUserProfile,
        }),
      );
    }
  } catch (err) {
    if (err.response.status === 400 && err.response.data.error.name === 'NoAuthHeaderError') {
      if (onApp) {
        store.dispatch(authAppSlice.actions.clear());
      }
      if (onAdmin) {
        store.dispatch(authAdminSlice.actions.clear());
      }
    }
  }
};
