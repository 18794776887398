import React from 'react';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';

import { CandidateInfo } from '../CandidateInfo/CandidateInfo';
import DocumentDetails from '../DocumentDetails/DocumentDetails';
import { GeneralPageComponent } from '../GeneralPageComponent/GeneralPageComponent';
import { HireCandidate } from '../HireCandidate/HireCandidate';
import { RequestConfirmation } from '../RequestConfirmation/RequestConfirmation';
import { SignDocuments } from '../SignDocuments/SignDocuments';

import styles from './CandidateProfileSelectedPage.module.scss';

interface CandidateProfileSelectedPageProps {
  page: OOPageModel | undefined;
  step: OOStepModel | undefined;
  flowWrapper: OOFlowWrapper;
  setPage: any;
}

export const CandidateProfileSelectedPage: React.FC<CandidateProfileSelectedPageProps> = ({
  page,
  flowWrapper,
  step,
  setPage,
}) => {
  const renderPageStep = (page: OOPageModel) => {
    switch (page?.name) {
      case 'CONTRACT_INFO':
        return (
          <>
            <HireCandidate flowWrapper={flowWrapper} page={page} stepName={OOStepsEnum.hire} completeStep={false} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );
      case 'HIRE_LIST_INFO':
        return (
          <>
            <HireCandidate flowWrapper={flowWrapper} page={page} stepName={OOStepsEnum.matchHire} completeStep={true} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );
      default:
        return (
          <>
            <GeneralPageComponent flowWrapper={flowWrapper} page={page} step={step} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );
    }
  };
  const renderDocumentationStep = () => {
    switch (step?.name) {
      case 'DOCUMENT_UPLOAD':
        return (
          <>
            <DocumentDetails flowWrapper={flowWrapper} step={step} setPage={setPage} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );
      case 'DOCUMENT_UPLOAD2':
        return (
          <>
            <DocumentDetails flowWrapper={flowWrapper} step={step} setPage={setPage} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );
      case 'CANDIDATE_SIGN_DOCUMENTS':
        return (
          <>
            <SignDocuments flowWrapper={flowWrapper} step={step} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );
      case 'CANDIDATE_CONFIRMS_DOCUMENTS':
        return (
          <>
            <SignDocuments flowWrapper={flowWrapper} step={step} />
            <CandidateInfo flowWrapper={flowWrapper} />
          </>
        );

      default:
        return <div />;
    }
  };
  const renderRequestConfirmationStep = (step: OOStepModel) => {
    return <RequestConfirmation step={step} />;
  };
  if (page && !page.skip) {
    return <div className={styles.candidateProfileSelectedPage}>{renderPageStep(page)}</div>;
  }
  if (step && step?.name === OOStepsEnum.requestConfirmation) {
    return <div className={styles.candidateProfileSelectedPage}>{renderRequestConfirmationStep(step)}</div>;
  }
  if (step && (step.documentations?.length > 0 || step.documentsForSignature.length > 0)) {
    return <div className={styles.candidateProfileSelectedPage}>{renderDocumentationStep()}</div>;
  }
  return (
    <div className={styles.candidateProfileImportPage}>
      <CandidateInfo flowWrapper={flowWrapper} />
    </div>
  );
};
