import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

import ConditionPropertyItem from './ConditionPropertyItem';

import controlsStyle from '../Controls.module.scss';
import styles from './ConditionProperty.module.scss';

interface Props {
  control: string;
  value: any;
  setFieldValue: any;
  fieldName: string;
  disabled: boolean;
}

const ConditionProperty: React.FC<Props> = ({ control, value, setFieldValue, fieldName, disabled }) => {
  const [operator, setOperator] = useState('AND');

  useEffect(() => {
    if (value?.[0]) {
      setOperator(value[0].operator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (newControl: any, index: number) => {
    let dest = [...value];

    if (index === 1) {
      const operatorValue = newControl.operator;
      setOperator(operatorValue);

      dest = dest.map((x) => {
        const ag = { ...x };

        return { ...ag, operator: operatorValue };
      });
    }

    dest[index] = newControl;

    setFieldValue(fieldName, dest, true);
  };

  const addEmptyCondition = () => {
    if (disabled) return;

    const dest = value ? [...value] : [];

    const newConditionControl: any = {
      operator: operator,
      value: '',
      notValue: '',
      control: {
        name: '',
        category: '',
        type: '',
        component: '',
        sequence: '',
        version: '',
      },
    };

    dest.push(newConditionControl);

    setFieldValue(fieldName, dest, true);
  };

  const handleNewCondition = (control: any, index: number) => {
    const { name, category, type, component, sequence, version } = control;

    const newConditionControl: any = {
      operator: operator,
      value: '',
      notValue: '',
      control: {
        name: name,
        category: category,
        type: type,
        component: component,
        sequence: sequence,
        version: version,
      },
    };

    if (control.sourceClass) {
      newConditionControl.control.sourceClass = control.sourceClass;
    }

    if (control.sourceField) {
      newConditionControl.control.sourceField = control.sourceField;
    }

    if (control.controlHeaderName) {
      newConditionControl.control.controlHeaderName = control.controlHeaderName;
    }

    const newArray = cloneDeep(value);
    newArray[index] = newConditionControl;

    setFieldValue(fieldName, newArray, true);
  };

  const handleRemoveCondition = (index: number) => {
    let dest: any[] | null = [...value];

    dest.splice(index, 1);

    if (dest.length === 0) {
      dest = null;
    }

    setFieldValue(fieldName, dest, true);
  };

  const isAddButtonVisible = !value || value?.length === 0;

  return (
    <div className={clsx('tag-ds input-wrapper', styles.container)}>
      <div className={styles.heading}>
        <label className="subtitle">{control}</label>
        {isAddButtonVisible && (
          <div
            className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
            onClick={addEmptyCondition}
          >
            <span className={clsx('material-icons')}>add</span>
          </div>
        )}
      </div>
      {value?.length > 0 && (
        <div style={{ marginTop: 16 }}>
          {value.map((x: any, index: number) => (
            <ConditionPropertyItem
              key={index}
              option={x}
              onChange={(control: any) => onChange(control, index)}
              onDelete={() => handleRemoveCondition(index)}
              handleNewCondition={(control: any) => handleNewCondition(control, index)}
              indexValue={index}
              operatorValue={operator}
              disabled={disabled}
            />
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 16, marginTop: 16 }}>
            <div
              className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
              onClick={addEmptyCondition}
            >
              <span className="material-icons" style={{ fontSize: 16 }}>
                add
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConditionProperty;
