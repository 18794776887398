import React from 'react';
import { Toggle } from '@adeccoux/tag-ds';

import styles from './ToggleFitler.module.scss';

interface Props {
  checked: boolean;
  label: string;
  title?: string;
  onChange: (val: boolean) => void;
}

const ToggleFilter: React.FC<Props> = ({ checked, label, title, onChange }) => {
  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <div className="tag-ds">
      {title && (
        <p className="subtitle" style={{ marginBottom: 8 }}>
          {title}
        </p>
      )}
      <div className={styles.toggleContainer}>
        <span className="large" style={{ fontSize: 16 }}>
          {label}
        </span>
        <Toggle aria-label={label} checked={checked} onChange={handleChange} />
      </div>
    </div>
  );
};

export default ToggleFilter;
