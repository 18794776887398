import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '@context/inversify-context-provider';
import { ExpandMore } from '@mui/icons-material';
import { Collapse, Typography } from '@mui/material';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { assetExtensions } from './AdminImport';
import { getConfigurationAssetsPrefix } from './adminImportHelpers';
import { AdminSimpleButton } from './AdminSimpleButton';
import { AdminUploadButton } from './AdminUploadButton';

import styles from './AdminImport.module.scss';
interface AssetDirectoryProps {
  dir: string;
  strId: string;
  clientOrganizationId?: string;
  configurationId?: string;
  className?: string;
  assetBoxes: string[];
  toggleAssetBox: (strId: string) => void;
  handleAssetPreview: (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => void;
  handleAssetUpload: (
    e: any,
    targetDir: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => Promise<void>;
  handleAssetDownload: (e: any, dir: string, clientOrganizationId?: string, configurationId?: string) => void;
  handleAssetRemove: (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => Promise<void>;
}
export const AdminAssetDirectory: FC<AssetDirectoryProps> = ({
  dir,
  strId,
  clientOrganizationId,
  configurationId,
  className,
  assetBoxes,
  toggleAssetBox,
  handleAssetPreview,
  handleAssetUpload,
  handleAssetDownload,
  handleAssetRemove,
}) => {
  const { t } = useTranslation(['recruiter']);

  const assetBoxOpen = assetBoxes.includes(strId);
  const assetPrefix = getConfigurationAssetsPrefix(dir, clientOrganizationId, configurationId);
  const aamBackendApi = useInjection(AamBackendApi);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[] | undefined>(undefined);
  const [refetchAssetList, setRefetchAssetList] = useState<boolean>(false);
  useEffect(() => {
    const getFileList = async () => {
      if (isExpanded && (fileList === undefined || refetchAssetList)) {
        const lst = await aamBackendApi.fetchFileProps(clientOrganizationId ?? '', true);
        setFileList(lst.filter((tt: any) => tt.startsWith(assetPrefix)));
        if (refetchAssetList) {
          setRefetchAssetList(false);
        }
      }
    };
    getFileList();
  }, [isExpanded, refetchAssetList, assetPrefix, setFileList, fileList, aamBackendApi, clientOrganizationId]);

  const handleAndRefetchAssetList = async (action: (...actionArguments: any[]) => Promise<void>, args: any[]) => {
    await action(...args);
    setRefetchAssetList(true);
  };

  return (
    <div className={styles.assetHolder + (className ? ' ' + className : '')}>
      <div
        className={styles.flexHolder + ' ' + styles.assetHeader}
        onClick={() => {
          toggleAssetBox(strId);
          setIsExpanded(!isExpanded);
        }}
      >
        <Typography variant="h4">
          {clientOrganizationId
            ? t('GENERAL.CONFIGURATION.ConfigurationAssets')
            : t('GENERAL.CONFIGURATION.TenantAssets')}{' '}
          "{t(`GENERAL.CONFIGURATION.${dir}`)}"
        </Typography>
        <ExpandMore style={!assetBoxOpen ? { transform: 'rotate(180deg)' } : {}} />
      </div>
      <Collapse in={assetBoxOpen} timeout="auto" unmountOnExit>
        <div className={styles.assetBox}>
          <div className={styles.assetDir} key={'asset-dir-' + strId}>
            {assetBoxOpen &&
              fileList?.map((tt: string, j: number) => (
                <div key={'tf-' + j} className={styles.assetListItem}>
                  <span>
                    <span>&bull; &nbsp;</span>
                    <span
                      className={styles.assetLabel}
                      onClick={(e) =>
                        handleAssetPreview(e, dir, tt.replace(assetPrefix, ''), clientOrganizationId, configurationId)
                      }
                    >
                      {tt.replace(assetPrefix, '')}
                    </span>
                  </span>
                  <AdminSimpleButton
                    label="x"
                    onClick={(e: any) => {
                      if (window.confirm(t('GENERAL.CONFIGURATION.AssetRemovalConfirmation'))) {
                        handleAndRefetchAssetList(handleAssetRemove, [
                          e,
                          dir,
                          tt.replace(assetPrefix, ''),
                          clientOrganizationId,
                          configurationId,
                        ]);
                      }
                    }}
                    className={styles.mini}
                    style={{ flexBasis: '30px' }}
                  />
                </div>
              ))}
            <div className={styles.flexHolder} style={{ padding: '4px 0' }}>
              <AdminUploadButton
                label={t('GENERAL.CONFIGURATION.AssetUpload')}
                extension={assetExtensions.join(',.')}
                multiple
                onChange={(e: any) => {
                  handleAndRefetchAssetList(handleAssetUpload, [e, dir, clientOrganizationId, configurationId]);
                }}
              />
              {fileList && fileList.length > 0 && (
                <AdminSimpleButton
                  label={t('GENERAL.CONFIGURATION.AssetDownloadAll')}
                  onClick={(e: any) => {
                    handleAssetDownload(e, dir, clientOrganizationId, configurationId);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
