import { differenceInYears } from 'date-fns';
import { FormikContextType } from 'formik';

import { OOControlModelInterface } from '../interfaces';

export class RecruiterRequirementsIT {
  age: number | null;
  control: OOControlModelInterface;
  recruiterRequirements1?: OOControlModelInterface;
  recruiterRequirements2?: OOControlModelInterface;
  formik: FormikContextType<Record<string, any>>;
  allControls: OOControlModelInterface[];

  protected UNDER_25 = 'under 25';
  protected OVER_50 = 'over 50';

  protected get isUnder25() {
    return !!(this.age && this.age < 25);
  }

  protected get isOver50() {
    return !!(this.age && this.age > 50);
  }

  protected setUnder25(): void {
    const formikValues = this.formik.values[this.control.name] ?? [];
    this.formik.setFieldValue(this.control.name, [...formikValues, this.UNDER_25]);
    this.control.value = [...(this.control.value ?? []), this.UNDER_25];
    this.unsetOver50();
  }

  protected unsetUnder25(): void {
    if (!this.recruiterRequirements2?.value?.includes(this.UNDER_25)) {
      return;
    }

    const values = this.formik.values[this.recruiterRequirements2.name] ?? [];
    this.formik.setFieldValue(
      this.recruiterRequirements2.name,
      values.filter((name: string) => name !== this.UNDER_25),
      true,
    );
    this.recruiterRequirements2.value = this.recruiterRequirements2?.value.filter(
      (name: string) => name !== this.UNDER_25,
    );
  }

  protected setOver50(): void {
    const formikValues = this.formik.values[this.control.name] ?? [];
    this.formik.setFieldValue(this.control.name, [...formikValues, this.OVER_50]);
    this.control.value = [...(this.control.value ?? []), this.OVER_50];
    this.unsetUnder25();
  }

  protected unsetOver50(): void {
    if (!this.recruiterRequirements1?.value?.includes(this.OVER_50)) {
      return;
    }

    const values = this.formik.values[this.recruiterRequirements1.name] ?? [];
    this.formik.setFieldValue(
      this.recruiterRequirements1.name,
      values.filter((name: string) => name !== this.OVER_50),
      true,
    );
    this.recruiterRequirements1.value = this.recruiterRequirements1?.value.filter(
      (name: string) => name !== this.OVER_50,
    );
  }

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.allControls = formControls ?? [];
    this.formik = formik;

    this.recruiterRequirements1 = formControls?.find((fc) => ['RECRUITER_REQUIREMENTS_1'].includes(fc.name));
    this.recruiterRequirements2 = formControls?.find((fc) => ['RECRUITER_REQUIREMENTS_2'].includes(fc.name));
    const dateOfBirth = formControls?.find((fc) => ['DATE_OF_BIRTH_HIDDEN'].includes(fc.name))?.value ?? null;
    this.age = dateOfBirth ? differenceInYears(new Date(), new Date(dateOfBirth)) : null;
  }
}
