import * as yup from 'yup';

import { conditionValidation } from '../../pageFeature/renderControls/templates';

interface Template {
  // Properties that are rendered in UI, defined by business which fields should be editable
  visible: string[];
  initialValues: { [key: string]: any };
  //   // Properties that are not editable by business
  hidden: { [key: string]: any };
  validation: yup.ObjectSchema | null;
  //   // Properties that should not exists in configuration, but they exists in UI for some other purpose
  hideForConfiguration?: string[];
  //   // Used for converting configuration object to UI object
  converterForUi?: (configurationObject: { [key: string]: any }) => { [key: string]: any };
  convertForConfiguration?: (configurationObject: { [key: string]: any }) => { [key: string]: any };
}

export const DocumentTemplate: Template = {
  visible: ['name', 'label', 'labelTranslations', 'isMandatory', 'documentCondition'],
  initialValues: {
    name: '',
    label: '',
    isMandatory: false,
    condition: null,
    labelTranslations: {},
  },
  hidden: {},
  converterForUi: (configurationObject) => {
    return {
      ...configurationObject,
      documentCondition: configurationObject.condition,
    };
  },
  convertForConfiguration: (configurationObject) => {
    return {
      ...configurationObject,
      condition: configurationObject.documentCondition,
    };
  },
  hideForConfiguration: ['documentCondition'],
  validation: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    condition: conditionValidation('condition'),
  }),
};

export const DocumentsTemplate: Template = {
  visible: ['name', 'label', 'labelTranslations', 'isMandatory', 'parts', 'controls', 'documentCondition'],
  initialValues: {
    name: '',
    label: '',
    isMandatory: false,
    condition: null,
    labelTranslations: {},
    parts: [],
  },
  hidden: {
    description: '',
    isDownloadSampleEnabled: false,
  },
  converterForUi: (configurationObject) => {
    return {
      ...configurationObject,
      documentCondition: configurationObject.condition,
    };
  },
  convertForConfiguration: (configurationObject) => {
    return {
      ...configurationObject,
      condition: configurationObject.documentCondition,
    };
  },
  hideForConfiguration: ['documentCondition', 'labelTranslations'],
  validation: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    condition: conditionValidation('condition'),
    parts: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required('Name is required'),
          isMandatory: yup.boolean().required('isMandatory is required'),
          sequence: yup.number().required('Sequence is required'),
          description: yup.string().notRequired(),
        }),
      )
      .min(1, 'At least one item is required'),
    controls: yup.array().of(
      yup.object().shape({
        component: yup.string().required('Component is required'),
        name: yup
          .string()
          .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
          .required('Name is required!'),
        label: yup.string().required('Label is required!'),
        // labelTranslations: translationValidation,
        description: yup.string().notRequired(),
        min: yup.string().when('component', {
          is: 'Textfield',
          then: yup.string().required('Min is required'),
          otherwise: yup.string().notRequired(),
        }),
        max: yup.string().when('component', {
          is: 'Textfield',
          then: yup.string().required('Max is required'),
          otherwise: yup.string().notRequired(),
        }),
        minMaxValidationError: yup.string().when('component', {
          is: 'Textfield',
          then: yup.string().required('Min Max Validation Error is required!'),
          otherwise: yup.string().notRequired(),
        }),
        // minMaxValidationErrorTranslations: yup.mixed().when('component', {
        //   is: 'Textfield',
        //   then: translationValidation,
        //   otherwise: yup.mixed().notRequired(),
        // }),
      }),
    ),
  }),
};
