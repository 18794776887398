import React from 'react';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
  errorInfo: string | null;
}

const SSErrorBoundaryFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary, errorInfo }) => {
  return (
    <div className="tag-ds" style={{ padding: 32 }}>
      <h4 style={{ marginBottom: 8 }}>Something went wrong. Please create a ticket with a screenshot of the error.</h4>
      <details style={{ whiteSpace: 'pre-wrap', cursor: 'pointer', marginBottom: 16 }}>
        {error && error.toString()} <span>{error.message}</span>
        <br />
        {errorInfo}
      </details>
      <button onClick={resetErrorBoundary}>Reload component</button>
    </div>
  );
};

export default SSErrorBoundaryFallback;
