import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getBlobData } from '@helpers/fileUpload.helper';
import { useDownloadLogo } from '@hooks/apiHooks';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { authAppSlice } from '@store/slices/authApp.slice';

import AdminHeader from '../../../features/oneOnboardingAdmin/components/AdminHeader/AdminHeader';
import AdminSideNavigation from '../../admin/AdminSideNavigation/AdminSideNavigation';

interface Props {
  pageName: string;
  featureName: string;
  children: React.ReactNode;
  sideMenu?: boolean;
  sidebarLogo?: string;
  headerConfig?: {
    pageName: string;
    languagePicker?: boolean;
    onBack?: () => any;
    tooltip?: () => JSX.Element;
  };
}

export const PageWrapper: React.FC<Props> = ({
  pageName,
  featureName,
  children,
  sideMenu,
  headerConfig,
  sidebarLogo,
}) => {
  const { tenantId } = useSelector((state: RootState) => state.tenant)!;
  const { logo } = useSelector((state: RootState) => state.authApp)!;
  const { data: response, isLoading } = useDownloadLogo(tenantId, sidebarLogo);

  useEffect(() => {
    if (response) {
      const { data, headers } = response;
      const logoBlob = getBlobData(data, headers['content-type']).imageUrl;
      store.dispatch(authAppSlice.actions.updateLogo(logoBlob));
    }
  }, [response]);

  return (
    <>
      <div>
        {headerConfig?.pageName && (
          <AdminHeader
            pageName={headerConfig.pageName}
            languagePicker={headerConfig.languagePicker}
            onBack={headerConfig.onBack}
            tooltip={headerConfig?.tooltip}
          />
        )}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {sideMenu && (
          <div
            style={{
              backgroundColor: '#F8F8F9',
              height: '80px',
              width: '80px',
              display: 'flex',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
            }}
          >
            {logo && !isLoading && <img src={logo} alt="Dialog" height="16px" style={{ padding: '0 8px' }} />}
          </div>
        )}
        {sideMenu && <AdminSideNavigation />}
        <div
          className={`feature feature-${featureName} page page-${pageName}`}
          style={{
            marginLeft: sideMenu ? 80 : 0,
            height: headerConfig?.pageName ? 'calc(100% - 80px)' : '100%',
            width: '100%',
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};
