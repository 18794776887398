import noop from 'lodash/noop';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

import { useCandidateInfo } from './useCandidateInfo';
import { useContactInfo } from './useContactInfo';
import { useGeneralInfo } from './useGeneralInfo';

export const useCandidateDetails = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const personalInfoState = useCandidateInfo(params);
  const contactInfoState = useContactInfo(params);
  const generalInfoState = useGeneralInfo(params);

  const allStates = [personalInfoState, contactInfoState, generalInfoState];
  const active = personalInfoState.active || contactInfoState.active || generalInfoState.active;

  const completed = personalInfoState.completed && contactInfoState.completed && generalInfoState.completed;
  const disabled = allStates.every((x) => x.disabled);

  return {
    active,
    disabled,
    completed,
    onClick: () => noop(),
  };
};
