import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Footer } from '@components/app/Footer/Footer';
import { Loader } from '@components/shared/Loader/Loader';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { useAllApplicationsForUserAndClientOrgUploadLimitSize } from '@hooks/apiHooks';
import { Box, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { CandidatePageLayout } from '../components/CandidatePageLayout/CandidatePageLayout';
import { OONavigationContext } from '../context/NavigationContext';
import { OOStep } from '../controls/Step';
import { useFlowWrapper } from '../hooks/useFlowWrapper';

export const OOFormDriver: React.FC = () => {
  const navigationContext = React.useContext(OONavigationContext);
  const { themeAdditionalData, step: currentStep, form } = useSelector((state: RootState) => state.oneOnboarding);
  const flowWrapper = useFlowWrapper();
  const step = flowWrapper?.getStep(currentStep);
  const currentPage = flowWrapper?.getCurrentStepAndFormFromNavigationPage(navigationContext.currentPage);
  const userId = useSelector((state: RootState) => state.authApp.userId);
  const { data = [], isFetching } = useAllApplicationsForUserAndClientOrgUploadLimitSize(userId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  if (!flowWrapper || (isFetching && !data.length)) {
    return <Loader />;
  }

  if (!step) {
    return <div>No step found</div>;
  }

  const isLastPage = step?.pages.find((p) => p.isLastPage)?.name === currentPage?.form && currentPage?.form === form;

  return (
    <CandidatePageLayout>
      <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {step.elements.map((x) => x.name).includes('STEPPER') && !isLastPage
          ? getStepperComponent(themeAdditionalData.formStepper, { isNameVisible: true })
          : null}
        <Grid container spacing={3} style={{ height: '100%' }}>
          <Grid item style={{ width: '100%' }}>
            <OOStep step={step} flowWrapper={flowWrapper} />
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </CandidatePageLayout>
  );
};
