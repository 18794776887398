import React from 'react';
import {
  Notification as NotificationTagDs,
  NotificationBody,
  NotificationFooter,
  NotificationHeader,
} from '@adeccoux/tag-ds';

export type NotificationType = 'success' | 'error' | 'info' | 'warning';

export interface NotificationDetails {
  header: any;
  body?: any;
  footer?: any;
}

interface NotificationProps {
  type: NotificationType;
  details: NotificationDetails;
  isActive: boolean;
  hide: () => void;
}

const Notification: React.FC<NotificationProps> = ({ type, details, isActive, hide }) => {
  return (
    <div className="tag-ds ">
      <NotificationTagDs
        icon={<span className="material-icons">info_outline</span>}
        onClose={hide}
        show={isActive}
        success={type === 'success'}
        error={type === 'error'}
        info={type === 'info'}
        warning={type === 'warning'}
      >
        {details.header && <NotificationHeader>{details.header}</NotificationHeader>}
        {details.body && <NotificationBody>{details.body}</NotificationBody>}
        {details.footer && <NotificationFooter>{details.footer}</NotificationFooter>}
      </NotificationTagDs>
    </div>
  );
};

export function useNotification() {
  const [isActive, setIsActive] = React.useState(false);
  const [notificationType, setNotificationType] = React.useState<NotificationType>('info');
  const [notificationDetails, setNotificationDetails] = React.useState<NotificationDetails>({
    header: '',
    body: '',
    footer: '',
  });

  React.useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        setIsActive(false);
      }, 3000);
    }
  }, [isActive]);

  const triggerNotification = (type: NotificationType, details: NotificationDetails) => {
    setIsActive(true);
    setNotificationType(type);
    setNotificationDetails(details);
  };

  const closeNotification = () => {
    setIsActive(false);
  };

  return { isActive, notificationType, notificationDetails, triggerNotification, closeNotification };
}

export default Notification;
