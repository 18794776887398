import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActionHistoryItemList from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/ActionHistoryItemList/ActionHistoryItemList';
import { useGetUserActionHistory } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';

interface ActionHistoryProps {
  userId: any;
  isActive: boolean;
  onClose: () => void;
}

const ActionHistory: FC<ActionHistoryProps> = ({ isActive, onClose, userId }) => {
  const { t } = useTranslation(['general']);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { clientOrganizationId, applicationId, configurationId } = useDecodedParams();
  const { data, isLoading } = useGetUserActionHistory(
    tenantId,
    userId,
    applicationId,
    clientOrganizationId,
    configurationId,
  );

  return (
    <Drawer
      open={isActive}
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(248, 248, 249, 1)',
          width: '470px',
        },
      }}
    >
      <Box className="admin" style={{ height: '100%' }} mb={4} ml={4} mt={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{t('recruiter:GENERAL.CANDIDATE_DETAILS.actionHistory')}</Typography>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon color="primary" />
          </IconButton>
        </Box>
        <Grid>
          <Box
            className="tag-ds"
            style={{ paddingTop: '20px', width: '100% !important' }}
            display="flex"
            justifyContent="space-between"
          />
        </Grid>
        <Grid>
          <ActionHistoryItemList data={data} isLoading={isLoading} />
        </Grid>
      </Box>
    </Drawer>
  );
};

export default ActionHistory;
