export enum FilterType {
  selector = 'selector',
  date = 'date',
}

export enum FilterName {
  JOB_TYPE = 'JOB_TYPE',
  IS_STUDENT = 'IS_STUDENT',
  IS_EUCITIZEN = 'IS_EUCITIZEN',
  UNREAD_MESSAGES = 'UNREAD_MESSAGES',
  FAILED_MAILS = 'FAILED_MAILS',
  START_DATE = 'START_DATE',
  BRANCH = 'BRANCH',
  LOCATION = 'LOCATION',
  REGION1 = 'REGION1',
  SITE_AS_LOCATION = 'SITE_AS_LOCATION',
  APPLICATION = 'APPLICATION',
}

export const actionTypes: { [key: string]: string } = {
  CLIENT_ORGANIZATION: 'CLIENT_ORGANIZATION_UPDATE',
  APPLICATION: 'APPLICATION_UPDATE',
  JOURNEY: 'JOURNEY_UPDATE',
  REGION1: 'REGION_UPDATE',
  REGION2: 'REGION_UPDATE',
  REGION3: 'REGION_UPDATE',
  JOB_TYPE: 'JOB_TYPE_UPDATE',
  START_DATE: 'START_DATE_UPDATE',
  AVAILABLE_FROM: 'AVAILABLE_FROM_UPDATE',
  AFFILIATION: 'AFFILIATION_UPDATE',
  LOCATION: 'LOCATION_UPDATE',
  DELETED_REASON: 'DELETED_REASON_UPDATE',
  ONBOARDING_STATUS: 'ONBOARDING_STATUS_UPDATE',
  BRANCH: 'BRANCH_UPDATE',
  SITE: 'SITE_UPDATE',
  CAMPAIGN: 'CAMPAIGN_UPDATE',
  SEARCH_TERM_UPDATE: 'SEARCH_TERM_UPDATE',
};

export const stateKeys: { [key: string]: string } = {
  CLIENT_ORGANIZATION: 'clientOrganizationId',
  JOURNEY: 'journeyId',
  AFFILIATION: 'affiliation',
  START_DATE: 'startDate',
  AVAILABLE_FROM: 'availableFrom',
  REGION1: 'regions',
  REGION2: 'regions',
  REGION3: 'regions',
  JOB_TYPE: 'jobTypes',
  LOCATION: 'locations',
  DELETED_REASON: 'deletedReason',
  BRANCH: 'branches',
  ONBOARDING_STATUS: 'onboardingStatuses',
  SITE: 'sites',
  APPLICATION: 'applicationId',
};

export const multipleSelectors = [
  'REGION1',
  'REGION2',
  'REGION3',
  'JOB_TYPE',
  'LOCATION',
  'ONBOARDING_STATUS',
  'DELETED_REASON',
  'BRANCH',
  'SITE',
];

export const headerNames: { [key: string]: string } = {
  CLIENT_ORGANIZATION: 'CLIENT_ORGANIZATION',
  JOB_TYPE: 'JOB_TYPE',
  REGION1: 'region1',
  REGION2: 'region2',
  REGION3: 'region3',
  LOCATION: 'location',
  AFFILIATION: 'AFFILIATION',
  BRANCH: 'BRANCH',
};

export const translationKeys: { [key: string]: string } = {
  CLIENT_ORGANIZATION: 'filterClientOrganization',
  APPLICATION: 'filterApplication',
  JOURNEY: 'filterJourney',
  REGION1: 'filterRegion',
  REGION2: 'filterRegion',
  REGION3: 'filterRegion',
  JOB_TYPE: 'filterTime',
  AFFILIATION: 'affiliation',
  AVAILABLE_FROM: 'availableFromLabel',
  START_DATE: 'startDate',
  LOCATION: 'filterLocation',
  DELETED_REASON: 'filterDeletedReason',
  ONBOARDING_STATUS: 'onboardingStatuses',
  BRANCH: 'branches',
};

export const fieldToTranslationKey: { [key: string]: string } = {
  clientOrganizationId: 'clientOrganizations',
  onboardingStatuses: 'onboardingStatuses',
  regions: 'filterRegion',
  deletedReason: 'deletionReason',
  journeyId: 'journies',
  applicationId: 'applications',
  applications: 'applications',
  jobTypes: 'filterTime',
  steps: 'steps',
  withIntegrationStatus: 'integrationStateStatus',
  blobExportStatus: 'blobExportStatus',
  unread: 'unreadMessages',
  failedMails: 'failedMails',
  startDate: 'startDate',
  searchQuery: 'Candidate details',
  branches: 'branches',
  locations: 'filterLocation',
  campaigns: 'campaigns',
  jobTypeIds: 'filterTime',
  // Case where we show sites in place of locations
  sites: 'siteAsLocation',
  importByMe: 'importByMe',
};
