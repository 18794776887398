import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Box, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';
import { apiQuery } from '@store/apiQuery';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';

import OOSeparator from '../../../../../components/shared/Separator/Separator';
import { checkboxControlError, checkboxErrorLabel } from '../../../helpers';
import { editableCondition } from '../../../hooks/useRenderCondition';
import { useT } from '../../../hooks/useT';
import { OOControlModelInterface } from '../../../interfaces';
import { OOFlowWrapper } from '../../../wrappers/FlowWrapper';
import { FormControlLoader } from '../../FormControlLoader';
import RadioButton from './components/RadioButton';

import styles from './MultipleValuesRadio.module.scss';

interface MultipleValuesRadioProps extends OOControlModelInterface {
  name: string;
  controlHeaderName?: string | undefined;
  iconType?: 'INFO' | 'HELP';
  isMandatory: boolean;
  label: string;
  expandOnSelect?: boolean;
}

export const MultipleValuesRadio: React.VoidFunctionComponent<MultipleValuesRadioProps> = (props) => {
  const { t, translationsLoading } = useT('entry', 'candidate_recruiter');
  const formik = useFormikContext<Record<string, any>>();
  const flowData = store.getState().oneOnboarding.flow;
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, val: any) => {
    formik.setFieldValue(props.name, val);
  };
  const validationKey = props.name;
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);
  const { data: multipleValues } = apiQuery.useGetMultiselectValuesQuery({
    name: props.controlHeaderName ? props.controlHeaderName : props.name.toLowerCase(),
  });

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const renderLabel = useMemo(() => {
    const isFontBold = themeAdditionalData?.isFontBoldForMultipleValuesRadio;

    return (
      <FormLabel>
        <span
          className={clsx({
            [styles.asterisk]: props.isMandatory,
            [styles.bolded]: isFontBold,
          })}
        >
          {t(props.label)}
        </span>
      </FormLabel>
    );
  }, [themeAdditionalData, props, t]);

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  const renderDescriptionOrError = (error?: string) => {
    if (error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };

  const isEditable = editableCondition(props, flowWrapper, formik.values) ?? true;

  return (
    <FormControl {...checkboxControlError(fieldMeta)} variant="outlined">
      {t(props.label) && <Box pb={1}>{renderLabel}</Box>}
      <RadioGroup name={validationKey} value={fieldProps.value || ''}>
        {multipleValues?.map((option, index) => {
          const isLast = index === multipleValues.length - 1;
          return (
            <>
              <RadioButton
                onChange={(e: any) => handleChange(e, option?.value as string)}
                checked={fieldProps.value === option.value}
                name={validationKey}
                value={option.value ? 'true' : 'false'}
                label={t(option.label)}
                key={index}
                iconType={props.iconType}
                disabled={!isEditable}
                description={option.description}
                optionValue={option.value}
                expandOnSelect={props.expandOnSelect}
              />
              {!isLast && <OOSeparator />}
            </>
          );
        })}
      </RadioGroup>
      {renderDescriptionOrError(checkboxErrorLabel(fieldMeta))}
    </FormControl>
  );
};
