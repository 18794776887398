import React from 'react';
import { useFormikContext } from 'formik';
import { Toggle } from '@adeccoux/tag-ds';
import { CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { checkboxControlError, checkboxErrorLabel } from '../../helpers';
import { useT } from '../../hooks/useT';
import { FormControlLoader } from '../FormControlLoader';

export type OOSwitchProps = {
  validationKey: string;
  label: string;
  description: string;
  mandatory: boolean;
  checkboxProps: CheckboxProps;
  defaultValue?: string;
  value?: boolean | undefined;
};

export const OOSwitch: React.FC<OOSwitchProps> = (props) => {
  const { t, translationsLoading } = useT('entry');
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(props.validationKey);
  const fieldMeta = formik.getFieldMeta(props.validationKey);

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  const control = (
    <div className="tag-ds ">
      <Toggle
        {...fieldProps}
        {...props.checkboxProps}
        checked={fieldProps.value === undefined ? props.defaultValue === 'true' : fieldProps.value}
        onChange={(e) => {
          formik.setFieldValue(props.validationKey, e);
        }}
      />
    </div>
  );
  const renderDescriptionOrError = (error?: string) => {
    if (props.description) {
      return <FormHelperText>{t(props.description)}</FormHelperText>;
    }
    if (error) {
      return <FormHelperText>{t(error)}</FormHelperText>;
    }
  };
  return (
    <FormControl {...checkboxControlError(fieldMeta)} className="toggle-container">
      <FormControlLabel
        label={t(props.label) + (props.mandatory ? ' *' : '')}
        control={control}
        style={{ marginLeft: 0, marginRight: 0, padding: 0 }}
      />
      {renderDescriptionOrError(checkboxErrorLabel(fieldMeta))}
    </FormControl>
  );
};
