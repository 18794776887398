import { adminDashboardFilters } from '@store/slices/oneOnboarding.slice';

import { FilterAction } from './DashboardFilterSelect';
const storedAdminDashboardFilters = JSON.parse(localStorage.getItem('adminDashboardFilters') || '{}') || {
  ...adminDashboardFilters,
};
const handleClientOrganizationUpdate = (state: any, action: FilterAction) => {
  const journeys = action?.options?.filter((j: any) => j.id === action.payload).map((x) => x.journeys)[0] || [];
  const journeyId = journeys.length === 1 ? journeys[0].id : undefined;
  return { ...state, clientOrganizationId: action.payload, journeyId };
};

const handleJourneyUpdate = (state: any, action: FilterAction) => {
  const { id, clientOrganizationId } = JSON.parse(action.payload);
  return { ...state, journeyId: id || '', clientOrganizationId: clientOrganizationId || '' };
};

export const selectedValuesReducer = (state: any, action: FilterAction) => {
  switch (action.type) {
    case 'CLIENT_ORGANIZATION_UPDATE':
      return handleClientOrganizationUpdate(state, action);
    case 'APPLICATION_UPDATE':
      return { ...state, applicationId: action.payload };
    case 'JOURNEY_UPDATE':
      return handleJourneyUpdate(state, action);
    case 'LOCATION_UPDATE':
      return { ...state, locations: action.payload };
    case 'SITE_UPDATE':
      return { ...state, sites: action.payload };
    case 'REGION_UPDATE':
      return { ...state, regions: action.payload };
    case 'JOB_TYPE_UPDATE':
      return { ...state, jobTypes: action.payload };
    case 'ONBOARDING_STATUS_UPDATE':
      return { ...state, onboardingStatuses: action.payload };
    case 'STEPS_UPDATE':
      return { ...state, steps: action.payload };
    case 'SEARCH_TERM_UPDATE':
      return { ...state, searchQuery: action.payload };
    case 'CAMPAIGN_UPDATE':
      return { ...state, campaigns: action.payload };
    case 'START_DATE_UPDATE':
      return { ...state, startDate: action.payload };
    case 'AVAILABLE_FROM_UPDATE':
      return { ...state, availableFrom: action.payload };
    case 'AFFILIATION_UPDATE':
      return { ...state, affiliation: action.payload?.value ?? '' };
    case 'DELETED_REASON_UPDATE':
      return { ...state, deletedReason: action.payload };
    case 'UNREAD_MESSAGES_UPDATE':
      return { ...state, unread: action.payload };
    case 'FAILED_MAILS_UPDATE':
      return { ...state, failedMails: action.payload };
    case 'IS_STUDENT_UPDATE':
      return { ...state, isStudent: action.payload };
    case 'IS_EUCITIZEN_UPDATE':
      return { ...state, isEUCitizen: action.payload };
    case 'INTEGRATION_STATUS_UPDATE':
      return { ...state, withIntegrationStatus: action.payload };
    case 'BLOB_EXPORT_STATUS':
      return { ...state, blobExportStatus: action.payload };
    case 'RESET_FILTERS':
      return { ...adminDashboardFilters };
    case 'BRANCH_UPDATE':
      return { ...state, branches: action.payload };
    case 'IMPORT_BY_ME':
      return { ...state, importByMe: action.payload };
    case 'RESTORE_FILTERS':
      return { ...storedAdminDashboardFilters };
    case 'SYNC_FILTERS':
      return { ...state, ...action.payload };
    case 'REMOVE_FILTER':
      // there's no simple initial state for object arrays in filter form,
      // SYNC_FILTERS approach above ignores those and breaks the form (if mentioned arrays remain active in the filter),
      // so we "reset" only param in question by type
      return {
        ...state,
        [action.payload]:
          state[action.payload] && typeof state[action.payload] === 'object' && (state[action.payload].length || 0) > 0
            ? []
            : undefined,
      };
    default:
      return state;
  }
};
