import React from 'react';
import { Box, BoxProps } from '@mui/material';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button';

export interface AdminButtonProps extends ButtonProps {
  text: any;
  boxProps?: BoxProps;
}

export const AdminButton: React.FC<AdminButtonProps> = ({ text, boxProps, ...buttonProps }) => {
  const clientBtnProps = { ...buttonProps };
  if (!clientBtnProps.variant) {
    clientBtnProps.variant = 'contained';
  }
  if (!clientBtnProps.color) {
    clientBtnProps.color = 'primary';
  }
  if (boxProps) {
    return (
      <Box {...boxProps}>
        <Button {...clientBtnProps}>{text}</Button>
      </Box>
    );
  }
  return <Button {...clientBtnProps}>{text}</Button>;
};
