import React, { FC } from 'react';
import DOMPurify from 'dompurify';

import styles from './ToolTip.module.scss';

interface ToolTipButtonProps {
  onClick: () => void;
  isActive: boolean;
}

export const ToolTipButton: FC<ToolTipButtonProps> = ({ onClick, isActive }) => {
  return (
    <span className={`material-icons ${styles.button}`} onClick={onClick}>
      {isActive ? 'help' : 'help_outline'}
    </span>
  );
};

interface ToolTipContentAreaProps {
  content: string;
}

export const ToolTipContentArea: FC<ToolTipContentAreaProps> = ({ content }) => {
  const ALLOWED_TAGS = ['p', 'ul', 'li', 'strong'];

  const safeHTML = DOMPurify.sanitize(content, {
    ALLOWED_TAGS,
  });

  return <div className={styles.content} dangerouslySetInnerHTML={{ __html: safeHTML }} />;
};
