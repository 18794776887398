import React from 'react';
import { useFormikContext } from 'formik';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { ControlItems } from '@store/apiQuery';

import { checkboxErrorLabel } from '../../helpers';
import { useT } from '../../hooks/useT';
import { FormControlLoader } from '../FormControlLoader';

type OOMultiSelectProps = {
  validationKey: string;
  label: string;
  description: string;
  data?: ControlItems;
  mandatory: boolean;
  loading: boolean;
  defaultValue?: string | undefined;
  onChangeCallback?: (event: any) => void;
  itemModificationFunctions?: Map<string, (items: ControlItems) => ControlItems>;
  disable?: boolean;
};

export const OOMultiSelect: React.FC<OOMultiSelectProps> = ({
  label,
  mandatory,
  data,
  description,
  loading,
  validationKey,
  defaultValue,
  onChangeCallback,
  itemModificationFunctions,
  disable,
}) => {
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);
  const { t, translationsLoading } = useT('control-items', 'entry');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let items: Array<string> = [...(fieldProps.value ?? [])];
    if (event.target.checked) {
      items.push(event.target.name);
    } else {
      items = items.filter((i) => i !== event.target.name);
    }
    formik.setFieldValue(validationKey, items);

    if (onChangeCallback) {
      onChangeCallback(items);
    }
  };

  if (itemModificationFunctions && data) {
    for (const func of itemModificationFunctions) {
      data = func[1](data);
    }
  }

  const renderError = (error?: string, validationKey?: string) => {
    if (error && validationKey) {
      return (
        <FormHelperText error={true}>
          {t(`entry:validations.${validationKey}`) !== `validations.${validationKey}`
            ? t(`entry:validations.${validationKey}`)
            : t(`entry:validations.${error}`) !== `validations.${error}`
            ? t(`entry:validations.${error}`)
            : error}
        </FormHelperText>
      );
    }
  };

  if (translationsLoading || loading) {
    return <FormControlLoader />;
  }
  return (
    <FormControl variant="outlined">
      {description && <FormLabel>{`${t(description)} ${mandatory ? '*' : ''}`}</FormLabel>}
      <FormGroup>
        {data?.map((i, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={
                  fieldProps.value === undefined
                    ? defaultValue === i.value
                    : fieldProps.value?.includes(i.value)
                    ? true
                    : false
                }
                onChange={handleChange}
                disabled={i.disabled || !disable}
                name={i.value}
              />
            }
            label={t(i.label)}
          />
        ))}
      </FormGroup>
      {renderError(checkboxErrorLabel(fieldMeta), validationKey)}
    </FormControl>
  );
};
