import React, { useMemo, useState } from 'react';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { mutateActivateCampaign } from '@hooks/apiHooks';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { CampaignModel } from '../../../oneOnboarding/models/CampaignModel';
import ActivateDeactivateCampaignModal from '../AdminImportUsers/components/ActivateDeactvateCampaignModal';

import styles from './CampaignDetails.module.scss';

const CampaignDetails = () => {
  const { t } = useTranslation(['recruiter', 'control-items']);

  const params = useParams<{
    campaignUrl: string;
  }>();

  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const [isDeactivateCampaignModalActive, setIsDeactivateCampaignModalActive] = useState<boolean>(false);
  const [isActiveteCampaignModalActive, setIsActiveteCampaignModalActive] = useState<boolean>(false);
  const [campaignChange, setCampaignChange] = useState<CampaignModel>();
  const history = useHistory();

  const aamBackendApi = useInjection(AamBackendApi);

  const { data, isLoading, refetch: refetchData } = useQuery({
    queryKey: ['fetchCampaign', params.campaignUrl],
    queryFn: () => aamBackendApi.fetchCampaign(params.campaignUrl),
    onSuccess(data: any) {
      return data;
    },
    retry: false,
    refetchOnMount: true,
  });

  const campaignUrlFull = useMemo(() => {
    return `${window.location.origin}/oo/campaign?campaignUrl=${data?.url}`;
  }, [data]);

  const { data: allBranchs } = useQuery({
    queryKey: ['fetchBranchsByTenantAsyncImport'],
    queryFn: () => aamBackendApi.fetchBranches(),
    refetchOnMount: true,
  });

  const { mutate: activateCampaign, isLoading: activatingCampaign } = mutateActivateCampaign(
    tenantId,
    campaignChange,
    () => {
      setIsActiveteCampaignModalActive(false);
      setIsDeactivateCampaignModalActive(false);
      refetchData();
    },
  );

  const { data: allConfigurations, isInitialLoading: configurationsAreLoading } = useQuery({
    queryKey: ['getJourneysByTenant', tenantId],
    queryFn: () => aamBackendApi.getJourneysByTenant(tenantId),
    onSuccess(data: any) {
      return data;
    },
    retry: false,
    refetchOnMount: true,
  });

  const onBack = () => {
    history.push('/oo/recruiter/import-users?activeTab=campaign');
  };

  const openActivateDeactivateModal = () => {
    if (data?.enabled) {
      setCampaignChange({ ...data, enabled: false });
      setIsDeactivateCampaignModalActive(true);
    } else {
      setCampaignChange({ ...data, enabled: true });
      setIsActiveteCampaignModalActive(true);
    }
  };

  const downloadPNG = () => {
    const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrcode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (isLoading || configurationsAreLoading) {
    return <Loader />;
  }

  const duration = moment.duration(moment(data.endDate).diff(data.startDate));
  const durationYears = duration.years();
  const durationMonths = duration.months();
  const durationDays = duration.days();

  const selectedConfiguration = allConfigurations?.find(
    (item: any) =>
      item.clientOrganization?.id === data?.clientOrganizationId && item.id === data?.selectedConfigurationId,
  );

  const clientOrganization = allConfigurations?.find(
    (item: any) => item.clientOrganization?.id === data?.clientOrganizationId,
  )?.clientOrganization;

  return (
    <div className="campaign">
      <PageWrapper
        featureName="campaign-details"
        pageName="campaign-details"
        headerConfig={{ pageName: data?.name || '', languagePicker: true, onBack }}
        sideMenu={true}
      >
        <div className={`${styles.featureCampaignDetails} tag-ds`}>
          <div className={`${styles.featureCampaignDetailsSummary} tag-ds`}>
            <div className={styles.featureCampaignDetailsSummaryHeader}>
              <p className="tag-ds  subtitle">{t('GENERAL.IMPORT.campaignDetails')}</p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => history.push(`/oo/recruiter/import-users/campaigns/edit/${data.url}`)}
                className={`${styles.featureCampaignDetailsSummaryHeaderEditButton} tag-ds`}
              >
                {t('GENERAL.IMPORT.editCampaign')}
                <span className="material-icons right" style={{ fontSize: '12px' }}>
                  edit
                </span>
              </a>
            </div>
            <div className={styles.featureCampaignDetailsSummaryContent}>
              <div
                className={`${styles.featureCampaignDetailsSummaryContentStatusTag} tag-ds status-tag${
                  data?.enabled ? '_info' : '_alert'
                }`}
              >
                {data?.enabled
                  ? `${t('GENERAL.IMPORT.activeFrom')} ${moment(data?.startDate).format('DD.MM.YYYY')}`
                  : t('GENERAL.IMPORT.deactivated')}
              </div>

              <div className={`${styles.featureCampaignDetailsSummaryContentItem} tag-ds`}>
                <div className={`${styles.featureCampaignDetailsSummaryContentItemContainer}`}>
                  <span className={`${styles.featureCampaignDetailsSummaryContentItemIcon} material-icons`}>
                    campaign
                  </span>
                  <div>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemLabel}`}>
                      {t('GENERAL.IMPORT.campaignName')}
                    </span>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemValue}`}>{data?.name}</span>
                  </div>
                </div>
              </div>

              <div className={`${styles.featureCampaignDetailsSummaryContentItem} tag-ds`}>
                <div className={`${styles.featureCampaignDetailsSummaryContentItemContainer}`}>
                  <span className={`${styles.featureCampaignDetailsSummaryContentItemIcon} material-icons`}>
                    business_center
                  </span>
                  <div>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemLabel}`}>
                      {t('GENERAL.IMPORT.headerClient')}
                    </span>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemValue}`}>
                      {clientOrganization?.name}
                    </span>
                  </div>
                </div>
              </div>

              <div className={`${styles.featureCampaignDetailsSummaryContentItem} tag-ds`}>
                <div className={`${styles.featureCampaignDetailsSummaryContentItemContainer}`}>
                  <span className={`${styles.featureCampaignDetailsSummaryContentItemIcon} material-icons`}>route</span>
                  <div>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemLabel}`}>
                      {t('GENERAL.IMPORT.headerJourney')}
                    </span>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemValue}`}>
                      {selectedConfiguration?.recruiterName}
                    </span>
                  </div>
                </div>
              </div>

              <div className={`${styles.featureCampaignDetailsSummaryContentItem} tag-ds`}>
                <div className={`${styles.featureCampaignDetailsSummaryContentItemContainer}`}>
                  <span className={`${styles.featureCampaignDetailsSummaryContentItemIcon} material-icons`}>
                    corporate_fare
                  </span>
                  <div>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemLabel}`}>
                      {t('GENERAL.IMPORT.headerBranch')}
                    </span>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemValue}`}>
                      {data?.branchIds?.length > 0 && allBranchs?.find((x) => x.id === data?.branchIds[0])?.name}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.featureCampaignDetailsSummaryContentItem} tag-ds`}
                style={{ borderBottom: 'none' }}
              >
                <div className={`${styles.featureCampaignDetailsSummaryContentItemContainer}`}>
                  <span className={`${styles.featureCampaignDetailsSummaryContentItemIcon} material-icons`}>
                    calendar_today
                  </span>
                  <div>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemLabel}`}>
                      {t('GENERAL.IMPORT.duration')}
                    </span>
                    <span className={`${styles.featureCampaignDetailsSummaryContentItemValue}`}>
                      {durationYears
                        ? t('GENERAL.IMPORT.alwaysActive')
                        : durationMonths
                        ? `${durationMonths} ${t('GENERAL.IMPORT.months')}`
                        : `${durationDays} ${t('GENERAL.IMPORT.days')}`}
                    </span>
                  </div>
                </div>
              </div>
              <div className={`${styles.featureCampaignDetailsSummaryContentDeactivateButtonContainer} tag-ds`}>
                <button
                  onClick={openActivateDeactivateModal}
                  className={`${styles.featureCampaignDetailsSummaryContentDeactivateButton} button_small`}
                >
                  {data?.enabled ? t('GENERAL.IMPORT.deactivateCampaign') : t('GENERAL.IMPORT.reactivateCampaign')}
                </button>
              </div>
            </div>
          </div>
          <div className={`${styles.featureCampaignDetailsQr} tag-ds`}>
            <span className={`${styles.featureCampaignDetailsQrLabel}`}>{t('GENERAL.IMPORT.qrCode')}</span>
            <div className={`${styles.featureCampaignDetailsQrImageContainer}`}>
              <QRCode id="qrcode" value={campaignUrlFull} renderAs="canvas" />
            </div>
            <div className={`${styles.featureCampaignDetailsQrCopyButtonContainer} tag-ds`}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`${styles.featureCampaignDetailsQrCopyButton} tag-ds`}
                onClick={() => navigator.clipboard.writeText(campaignUrlFull)}
              >
                {t('GENERAL.IMPORT.copyUrl')}
                <span className="material-icons right" style={{ fontSize: '12px' }}>
                  link
                </span>
              </a>
            </div>
            <div className={`${styles.featureCampaignDetailsQrDownloadButtonContainer} tag-ds`}>
              <button
                className={`${styles.featureCampaignDetailsQrDownloadButton} button-secondary_small`}
                onClick={downloadPNG}
              >
                {t('GENERAL.IMPORT.downloadPng')}
                <span className="material-icons right">download</span>
              </button>
            </div>
          </div>
        </div>
        <ActivateDeactivateCampaignModal
          activating={activatingCampaign}
          activateCampaign={() => activateCampaign()}
          buttonText={t('GENERAL.IMPORT.reactivateCampaign')}
          bodyText={t(`GENERAL.IMPORT.reactivateCampaignMessage`)}
          headerText={t('recruiter:GENERAL.IMPORT.reactivateCampaign')}
          cancelButtonText={t(`GENERAL.IMPORT.dontChange`)}
          cancelButtonModal={() => undefined}
          isActiveModal={isActiveteCampaignModalActive}
          setIsActiveModal={(value) => setIsActiveteCampaignModalActive(value)}
        />
        <ActivateDeactivateCampaignModal
          activating={activatingCampaign}
          activateCampaign={() => activateCampaign()}
          buttonText={t('GENERAL.IMPORT.deactivateCampaign')}
          bodyText={t(`GENERAL.IMPORT.deactivateCampaignMessage`)}
          headerText={t('recruiter:GENERAL.IMPORT.changeCampaignStatus')}
          cancelButtonText={t(`GENERAL.IMPORT.dontChange`)}
          cancelButtonModal={() => undefined}
          isActiveModal={isDeactivateCampaignModalActive}
          setIsActiveModal={(value) => setIsDeactivateCampaignModalActive(value)}
        />
      </PageWrapper>
    </div>
  );
};

export default CampaignDetails;
