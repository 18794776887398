import XLSX, { writeFile } from 'xlsx';
import { Tenant } from '@hooks/useComponentMapping';

import { container } from '../inversify';
import { AamBackendApi } from './aamBackendApi';

const aamBackendApi = container.get(AamBackendApi);

export class TranslationsManager {
  private tenantId: null | Tenant = null;

  constructor(tenantId: Tenant) {
    this.tenantId = tenantId;
  }

  public async export(cbSuccess: () => void, cbFail: () => void) {
    try {
      const data = await aamBackendApi.exportTranslations(this.tenantId as number, 'no');
      const timeStamp = new Date().toISOString();

      const workbook = XLSX.read(data.data, { type: 'buffer' });

      writeFile(workbook, `translations_${this.tenantId}_${timeStamp}.xlsx`);

      cbSuccess();
    } catch (err) {
      cbFail();
    }
  }

  public async import(e: any, cbSuccess: () => void, cbFail: () => void) {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const { name } = file;

      try {
        const fd = new FormData();
        fd.append('file', file, name);

        await aamBackendApi.importTranslations(fd, this.tenantId as number);

        cbSuccess();

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (err) {
        cbFail();
      }
    }
  }
}
