import { FormikContextType } from 'formik';
import { ControlItems } from '@store/apiQuery';

import { ICalculationClassUI, OOControlModelInterface, OOMultiSelectModelInterface } from '../interfaces';
import { RecruiterRequirementsIT } from './RecruiterRequirementsIT';

export class RecruiterRequirementsIT1 extends RecruiterRequirementsIT implements ICalculationClassUI {
  relevantControls: OOControlModelInterface[];
  requirements2Control: OOMultiSelectModelInterface;

  isOver50Checked: boolean = false;
  disableUnder25 = (items: ControlItems) => {
    const modifiedItems: ControlItems = [];
    for (const item of items) {
      const modifiedItem = { ...item };
      if (item.value === this.UNDER_25) {
        modifiedItem.disabled = this.isOver50Checked || this.isUnder25;
      }
      modifiedItems.push(modifiedItem);
    }

    return modifiedItems;
  };

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    super(control, formControls, formik);
    this.relevantControls = formControls?.filter((fc) => ['RECRUITER_REQUIREMENTS_2'].includes(fc.name)) ?? [];

    const multi = this.control as OOMultiSelectModelInterface;
    multi.itemModificationFunctions =
      multi.itemModificationFunctions ?? new Map<string, (items: ControlItems) => ControlItems>();

    this.requirements2Control = this.relevantControls[0] as OOMultiSelectModelInterface;
    this.isOver50Checked = this.checkOver50(this.control.value);
    this.requirements2Control?.itemModificationFunctions?.set('disableUnder25', this.disableUnder25);
  }

  onChange = (items: any) => {
    if (!this.requirements2Control) {
      return;
    }

    this.control.value = items;
    this.isOver50Checked = this.checkOver50(this.control.value);
    this.requirements2Control?.itemModificationFunctions?.set('disableUnder25', this.disableUnder25);

    this.formik.validateForm();
  };

  checkOver50 = (value: string[]) => {
    const formikValues = this.formik.values[this.control.name];

    if (this.isOver50 && !formikValues?.includes(this.OVER_50)) {
      this.setOver50();
    }

    return formikValues?.includes(this.OVER_50) ?? value?.includes(this.OVER_50);
  };
}
