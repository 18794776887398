import React from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import * as yup from 'yup';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { useAllDraftConfigurations } from '@hooks/apiHooks';
import { useGetUrlParameter } from '@hooks/useGetUrlParameter';
import { DraftConfiguration } from '@models/draftConfiguration.model';
import { CircularProgress } from '@mui/material';
import { store } from '@store/index';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { handlePublishedStatus, selfServiceSlice } from '@store/slices/selfService.slice';
import { useQuery } from '@tanstack/react-query';

import {
  getDefaultStartingUuid,
  getNextSequentialUuid,
  remapValuesForConfigurationLevel,
} from '../../helpers/commonFunctions';
import NewConfigurationActions from './components/NewConfigurationActions';

import styles from './NewConfiguration.module.scss';

type FormValues = {
  name: string;
  id: string;
  displayName: string;
  recruiterName: string;
  candidateName: string;
  configurationId: string;
};

const ConfigurationInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const draftId = useGetUrlParameter('draftId');
  const selectedConfigurationId = useGetUrlParameter('configurationId');
  const selectedClientOrganizationId = useGetUrlParameter('clientOrganizationId');
  const configuratioName = useGetUrlParameter('configurationName');

  const aamBackendApi = useInjection(AamBackendApi);

  const { data: allDraftConfigurations } = useAllDraftConfigurations('x');
  const selectedDraftConfiguration = allDraftConfigurations?.find((draft) => draft.id === draftId);
  const savedConfiguration = selectedDraftConfiguration ? selectedDraftConfiguration.data : undefined;

  const { data: allConfigs, isLoading } = useQuery({
    queryKey: ['allConfigurationsForAllTenants'],
    queryFn: () => aamBackendApi.fetchAllConfigurationsForAllTenants('x'),
    refetchOnMount: true,
  });

  const getNewUuid = (items: string[] | undefined) => {
    const draftClientOrganizationIds = allDraftConfigurations?.map((x) => x.clientOrganizationId);

    if (!items || !draftClientOrganizationIds) return;

    const sorted = [...items, ...draftClientOrganizationIds]
      ?.filter((id) => id.match('^00000000'))
      .sort((a: string, b: string) => (a < b ? -1 : 1));
    const lastUuid = sorted && sorted.length > 0 ? sorted[sorted?.length - 1] : undefined;
    return getNextSequentialUuid(lastUuid);
  };

  const getNextConfigId = (drafts: DraftConfiguration[] | undefined) => {
    if (!selectedConfigurationId) {
      return undefined;
    }

    if (!drafts || drafts.length === 0) {
      return getDefaultStartingUuid();
    }

    const confIds = drafts
      .filter((d) => d.clientOrganizationId === selectedClientOrganizationId)
      .map((d) => d.data)
      .map((d) => d.configurationId)
      .sort((a: string, b: string) => (a < b ? -1 : 1));

    const lastUuid = confIds[confIds.length - 1];
    return getNextSequentialUuid(lastUuid);
  };

  const newConfId = getNextConfigId(allDraftConfigurations);
  const newClientId = getNewUuid(allConfigs?.map((c) => c.clientOrganizationId.toString()));

  const initialValues = {
    id: newClientId,
    name: savedConfiguration?.configuration.clientOrganization.name || '',
    configurationId: newConfId,
  };

  const textErrors = (meta: any) => ({
    error: meta.touched && Boolean(meta.error),
    helperText: meta.touched && meta.error,
  });

  const validationSchema = yup.object().shape({
    id: yup.string().required('ID is required!').uuid(),
    configurationId: yup.string().required('ID is required!').uuid(),
    name: yup.string().required('Name is required!').min(2, 'Name is at least 2 characters'),
  });

  const handleResume = (name?: string) => {
    if (!savedConfiguration) return;
    let payload = savedConfiguration;
    const nameChanged = savedConfiguration.configuration.recruiterName !== name;

    if (nameChanged) {
      payload = {
        ...payload,
        configuration: {
          ...payload.configuration,
          candidateName: name,
          recruiterName: name,
          clientOrganization: {
            ...payload.configuration.clientOrganization,
            name: name,
            displayName: name,
          },
        },
      };
    }

    const clientOrganizationId = savedConfiguration.configuration.clientOrganization.id || '';
    const configurationId = savedConfiguration.configuration.configurationId || '';
    history.push(
      `/oo/recruiter/self-service/configuration-setup?clientOrganizationId=${clientOrganizationId}&configurationId=${configurationId}&draftId=${draftId}&saved=true`,
    );
    store.dispatch(selfServiceSlice.actions.initializeConfiguration(payload as any));
    store.dispatch(oneOnboardingSlice.actions.setClientOrganizationId(clientOrganizationId));
    store.dispatch(oneOnboardingSlice.actions.setConfigurationId(draftId));
  };

  return (
    <PageWrapper
      pageName="newConfiguration"
      featureName="newConfiguration"
      sideMenu
      headerConfig={{
        pageName: 'Assign a name',
        languagePicker: true,
        onBack: () => {
          if (draftId) {
            history.push('/oo/recruiter/self-service');
          } else {
            history.push('/oo/recruiter/self-service/new-configuration/configuration-base');
          }
        },
      }}
    >
      {isLoading ? (
        <CircularProgress className={styles.loader} />
      ) : (
        <div className={styles.configurationDetails__container}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              dispatch(handlePublishedStatus(false));
              history.push(
                `/oo/recruiter/self-service/configuration-setup?clientOrganizationId=${selectedClientOrganizationId}&configurationId=${selectedConfigurationId}`,
                {
                  configurationInfo: remapValuesForConfigurationLevel(values as any),
                },
              );
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {(props: FormikProps<any>) => (
              <form onSubmit={props.handleSubmit}>
                <div className={styles.info__container}>
                  <Field name="name">
                    {(fieldProps: FieldProps<any, FormValues>) => {
                      const { error, helperText } = textErrors(fieldProps.meta);

                      return (
                        <div className={`tag-ds ${error && 'input-wrapper_error'}`} style={{ flex: 1 }}>
                          <label className="caption">Client Form name</label>
                          <div className="input-container">
                            <input
                              className="large"
                              type="text"
                              value={fieldProps.field.value}
                              onChange={fieldProps.field.onChange}
                              onBlur={fieldProps.field.onBlur}
                              name={fieldProps.field.name}
                            />
                          </div>
                          {helperText && <p className="input-helper-text">{helperText}</p>}
                          <p className="body medium">
                            Configuration {fieldProps.field.value} is based on <strong>{configuratioName}</strong>
                          </p>
                        </div>
                      );
                    }}
                  </Field>
                </div>
                {draftId ? (
                  <NewConfigurationActions
                    actions={[
                      {
                        text: 'Resume',
                        disabled: false,
                        onClick: () => handleResume(props.values['name']),
                      },
                    ]}
                  />
                ) : (
                  <NewConfigurationActions
                    actions={[
                      {
                        text: 'NEXT',
                        icon: 'chevron_right',
                        disabled: !(props.isInitialValid || (props.dirty && props.isValid)),
                        onClick: props.submitForm,
                      },
                    ]}
                  />
                )}
              </form>
            )}
          </Formik>
        </div>
      )}
    </PageWrapper>
  );
};

export default ConfigurationInfo;
