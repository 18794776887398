import { FormikContextType } from 'formik';
import moment from 'moment';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class StartDateBeforeEndDate implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.allControls = formControls ?? [];
    this.formik = formik;
  }

  onChange = (endDate: Date) => {
    const startDate = this.allControls?.find(
      (control) =>
        control.name === 'START_DATE' ||
        control.name.includes('ADDRESS_FROM') ||
        control.name.includes('EMPLOYMENT_HISTORY_FROM'),
    );

    if (startDate) {
      const today = moment(moment().format('YYYY-MM-DD'));
      const selectedDay = moment(moment(endDate).format('YYYY-MM-DD'));

      const dayDifference = today.diff(selectedDay, 'days');
      startDate.maxOffset = selectedDay.isAfter(today) ? Math.abs(dayDifference) : -Math.abs(dayDifference);
    }

    this.control.value = endDate;
  };
}
