import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationBody, NotificationFooter, NotificationHeader } from '@adeccoux/tag-ds';
import { Link } from '@mui/material';

import styles from '../AdminImportUsers.module.scss';

export enum ImportNotificationsType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  NONE = 'NONE',
  DATE = 'DATE',
}
export interface ImportNotificationsProps {
  type: ImportNotificationsType;
  onClose: () => void;
  error?: string;
}
export const ImportNotifications: FC<ImportNotificationsProps> = ({ type, onClose, error }) => {
  const { t } = useTranslation(['recruiter']);
  switch (type) {
    case ImportNotificationsType.INFO:
      return (
        <Notification
          icon={<span className="material-icons">info_outline</span>}
          info
          className={styles.notification}
          onClose={onClose}
          show={true}
        >
          <NotificationHeader>{t('recruiter:GENERAL.IMPORT.notificationHeader.info')}</NotificationHeader>
          <NotificationBody>{t('recruiter:GENERAL.IMPORT.notificationContentInfo.info')}</NotificationBody>
          <NotificationFooter>
            <Link className="link_small" href="/oo/recruiter">
              {t('recruiter:GENERAL.IMPORT.notificationLink')}
            </Link>
          </NotificationFooter>
        </Notification>
      );
    case ImportNotificationsType.ERROR:
      return (
        <Notification
          icon={<span className="material-icons">error_outline</span>}
          error
          className={styles.notification}
          onClose={onClose}
          show={true}
        >
          <NotificationHeader>{t('recruiter:GENERAL.IMPORT.notificationHeader.error')}</NotificationHeader>
          <NotificationBody>
            {t('recruiter:GENERAL.IMPORT.notificationContentInfo.error')}

            {error}
          </NotificationBody>
          <NotificationFooter>
            <Link className="link_small" href="/oo/recruiter">
              {t('recruiter:GENERAL.IMPORT.notificationLink')}
            </Link>
          </NotificationFooter>
        </Notification>
      );
    case ImportNotificationsType.DATE:
      return (
        <Notification
          icon={<span className="material-icons">error_outline</span>}
          error
          className={styles.notification}
          onClose={onClose}
          show={true}
        >
          <NotificationHeader>{t('recruiter:GENERAL.IMPORT.notificationHeader.error')}</NotificationHeader>
          <NotificationBody>{t('recruiter:GENERAL.IMPORT.notificationContentInfo.date')}</NotificationBody>
          <NotificationFooter>
            <Link className="link_small" href="/oo/recruiter">
              {t('recruiter:GENERAL.IMPORT.notificationLink')}
            </Link>
          </NotificationFooter>
        </Notification>
      );
    case ImportNotificationsType.SUCCESS:
      return (
        <Notification
          icon={<span className="material-icons">error_outline</span>}
          success
          className={styles.notification}
          onClose={onClose}
          show={true}
        >
          <NotificationHeader>{t('recruiter:GENERAL.IMPORT.notificationHeader.success')}</NotificationHeader>
          <NotificationBody>{t('recruiter:GENERAL.IMPORT.notificationContentInfo.success')}</NotificationBody>
          <NotificationFooter>
            <Link className="link_small" href="/oo/recruiter">
              {t('recruiter:GENERAL.IMPORT.notificationLink')}
            </Link>
          </NotificationFooter>
        </Notification>
      );
    case ImportNotificationsType.NONE:
      return <></>;
    default:
      return <></>;
  }
};
