import React from 'react';
import { Loader } from '@components/shared/Loader/Loader';
import { FormControl } from '@mui/material';

export const FormControlLoader: React.VoidFunctionComponent = () => {
  return (
    <FormControl variant="outlined">
      <Loader />
    </FormControl>
  );
};
