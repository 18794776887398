import React from 'react';
import clsx from 'clsx';
import { Box, Container } from '@mui/material';

import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <Container className={clsx('tag-ds', styles.container)}>
        <Box textAlign="left">
          <img src="/images/not-found/not-found.png" alt="404" />
          <h2>404 - Page not found</h2>
          <p className={clsx('large', styles.description)}>
            The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
          </p>
          <button className={styles.btn} onClick={() => window.history.back()}>
            Go back
          </button>
        </Box>
      </Container>
    </div>
  );
};

export default NotFound;
