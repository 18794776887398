import React from 'react';
import { addSeconds, format, isAfter, startOfDay, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DatePicker } from '@adeccoux/tag-ds';
import { Box } from '@mui/material';
import { RootState } from '@store/rootReducer';

interface AdminAppointmentPickerProps {
  dateFormat?: string;
  onboardingCreatedAtInterval: {
    onboardingCreatedAtEndDate: any;
    onboardingCreatedAtStartDate: any;
  };
  setOnboardingCreatedAtInterval: any;
}

const AdminAppointmentPicker: React.FC<AdminAppointmentPickerProps> = ({
  dateFormat,
  onboardingCreatedAtInterval,
  setOnboardingCreatedAtInterval,
}) => {
  const { t } = useTranslation(['recruiter']);

  const tzOffset = new Date().getTimezoneOffset() * 60;
  const appointmentStartOffset = useSelector(
    (state: RootState) => state.featureConfiguration.appSettings.appointmentStartOffset,
  );

  const defaultDateFormat = 'yyyy-MM-dd';

  const startDateValue = onboardingCreatedAtInterval.onboardingCreatedAtStartDate
    ? addSeconds(new Date(onboardingCreatedAtInterval.onboardingCreatedAtStartDate), +tzOffset)
    : null;

  const endDateValue = onboardingCreatedAtInterval.onboardingCreatedAtEndDate?.length
    ? addSeconds(new Date(onboardingCreatedAtInterval.onboardingCreatedAtEndDate), +tzOffset)
    : null;

  const addToUrl = (key: string, value: string | null) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (value === null) {
      currentSearchParams.delete(key);
    } else {
      currentSearchParams.set(key, value);
    }

    const newUrl = [window.location.pathname, currentSearchParams.toString()].filter(Boolean).join('?');

    window.history.replaceState(null, '', newUrl);
  };

  const getMaxDateStartDate = () => {
    if (!onboardingCreatedAtInterval.onboardingCreatedAtEndDate) return undefined;

    return isAfter(subDays(new Date(onboardingCreatedAtInterval.onboardingCreatedAtEndDate), 1), new Date())
      ? Date.now()
      : subDays(new Date(onboardingCreatedAtInterval.onboardingCreatedAtEndDate), 1).getTime();
  };

  return (
    <div className="tag-ds ">
      <Box display="flex">
        <div className="onboarding-created-at-interval">
          <DatePicker
            dateFormat={dateFormat || defaultDateFormat}
            name="datepicker"
            placeholder="dd/mm/yyyy"
            onChange={(date: any) => {
              const onboardingCreatedAtStartDate = date ? format(date, defaultDateFormat) : null;
              setOnboardingCreatedAtInterval({
                ...onboardingCreatedAtInterval,
                onboardingCreatedAtStartDate,
              });

              addToUrl('onboardingStartDate', onboardingCreatedAtStartDate);
            }}
            maxDate={getMaxDateStartDate()}
            label={t('GENERAL.CANDIDATES_OVERVIEW.appointmentStart')}
            defaultValue={
              startDateValue
                ? startDateValue.getTime()
                : addSeconds(startOfDay(subDays(new Date(), appointmentStartOffset || 14)), -tzOffset).getTime()
            }
          />
        </div>
        <div className="onboarding-created-at-interval">
          <DatePicker
            dateFormat={dateFormat || defaultDateFormat}
            name="datepicker"
            placeholder="dd/mm/yyyy"
            label={t('GENERAL.CANDIDATES_OVERVIEW.appointmentEnd')}
            minDate={new Date(onboardingCreatedAtInterval.onboardingCreatedAtStartDate).getTime()}
            onChange={(date: any) => {
              const onboardingCreatedAtEndDate = date ? format(date, defaultDateFormat) : null;
              setOnboardingCreatedAtInterval({
                ...onboardingCreatedAtInterval,
                onboardingCreatedAtEndDate: onboardingCreatedAtEndDate,
              });

              addToUrl('onboardingEndDate', onboardingCreatedAtEndDate);
            }}
            defaultValue={endDateValue ? endDateValue.getTime() : Date.now()}
          />
        </div>
      </Box>
    </div>
  );
};

export default AdminAppointmentPicker;
