import { FormikContextType } from 'formik';
import { ControlItems } from '@store/apiQuery';

import { ICalculationClassUI, OOControlModelInterface, OOMultiSelectModelInterface } from '../interfaces';
import { RecruiterRequirementsIT } from './RecruiterRequirementsIT';

export class RecruiterRequirementsIT2 extends RecruiterRequirementsIT implements ICalculationClassUI {
  relevantControls: OOControlModelInterface[];
  requirements1Control: OOMultiSelectModelInterface;
  fiscalCodeControl: OOControlModelInterface | undefined;

  isUnder25Checked: boolean = false;

  disableOver50 = (items: ControlItems) => {
    const modifiedItems: ControlItems = [];
    for (const item of items) {
      const modifiedItem = { ...item };
      if (item.value === this.OVER_50) {
        modifiedItem.disabled = this.isUnder25Checked || this.isOver50;
      }
      modifiedItems.push(modifiedItem);
    }

    return modifiedItems;
  };

  disableGenderDisparity = (items: ControlItems) => {
    const modifiedItems: ControlItems = [];
    for (const item of items) {
      const modifiedItem = { ...item };
      if (item.value === 'Gender disparity') {
        modifiedItem.disabled = true;
      }
      modifiedItems.push(modifiedItem);
    }
    return modifiedItems;
  };

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    super(control, formControls, formik);
    this.relevantControls =
      formControls?.filter((fc) => ['RECRUITER_REQUIREMENTS_1', 'FISCAL_CODE_HIDDEN'].includes(fc.name)) ?? [];

    const multi = this.control as OOMultiSelectModelInterface;
    multi.itemModificationFunctions =
      multi.itemModificationFunctions ?? new Map<string, (items: ControlItems) => ControlItems>();

    this.fiscalCodeControl = formControls?.find(
      (rc) => rc.name === 'FISCAL_CODE_HIDDEN',
    ) as OOMultiSelectModelInterface;

    if (this.fiscalCodeControl && this.isMale(this.fiscalCodeControl.value)) {
      multi.itemModificationFunctions.set('disableGenderDisparity', this.disableGenderDisparity);
    }

    this.requirements1Control = this.relevantControls.find(
      (rc) => rc.name === 'RECRUITER_REQUIREMENTS_1',
    ) as OOMultiSelectModelInterface;

    this.isUnder25Checked = this.checkUnder25(this.control.value);
    this.requirements1Control?.itemModificationFunctions?.set('disableOver50', this.disableOver50);
  }

  onChange = (items: any) => {
    if (!this.requirements1Control) {
      return;
    }

    this.control.value = items;
    this.isUnder25Checked = this.checkUnder25(this.control.value);
    this.requirements1Control?.itemModificationFunctions?.set('disableOver50', this.disableOver50);

    this.formik.validateForm();
  };

  checkUnder25 = (value: string[]) => {
    const formikValues = this.formik.values[this.control.name];

    if (this.isUnder25 && !formikValues?.includes(this.UNDER_25)) {
      this.setUnder25();
    }

    return formikValues?.includes(this.UNDER_25) ?? value?.includes(this.UNDER_25);
  };

  isMale = (value: string) => {
    if (!value || value.length < 11) {
      return false;
    }

    const gender = parseInt(value.substring(9, 11));
    if (isNaN(gender)) {
      return false;
    }

    return gender <= 40;
  };
}
