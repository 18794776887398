import { useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, Formik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Header } from '@components/app/Header/Header';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { getRegistrationStepInfo } from '@helpers/authHelper';
import { getApplicationId, getClientOrganizationId, getSelectedConfigurationId } from '@helpers/tenant.helper';
import { mutateOneOnboarding, useGetUser } from '@hooks/apiHooks';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { Button, TextField } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { CustomLogo } from '../controls/CustomLogo';
import { OOFormControl } from '../controls/FormControl';
import { parseValidationErrors } from '../helpers';
import { OOControlModel } from '../models/ControlModel';
import { OOPageModel } from '../models/FormModel';
import { OOStepModel } from '../models/StepModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';

export const Consents = () => {
  const aamBackendApi = useInjection(AamBackendApi);
  const userId = useSelector((state: RootState) => state.authApp.userId) ?? '';
  const tokenApp = useSelector((state: RootState) => state.authApp.idToken);
  const authMethodApp = useSelector((state: RootState) => state.authApp.authMethod);
  const history = useHistory();
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { data: user } = useGetUser(tenantId, userId);
  const userEmail = user?.email;
  const { t } = useTranslation(['entry', 'candidate', 'candidate_recruiter']);
  const { oneOnboarding } = useSelector((state: RootState) => state);
  const [fw, setFw] = useState<OOFlowWrapper | null>(null);
  const [logo, setLogo] = useState<string>('');
  const [consentStep, setConsentStep] = useState<OOStepModel>();
  const [consentPage, setConsentPage] = useState<OOPageModel>();
  const { createTelemetryAuthenticated } = useTelemetry();
  const queryClient = useQueryClient();

  const clientOrganizationId = useMemo(() => {
    if (oneOnboarding.clientOrganizationId) {
      return oneOnboarding.clientOrganizationId;
    }
    return getClientOrganizationId() || '';
  }, [oneOnboarding.clientOrganizationId]);
  const configurationId = useMemo(() => {
    if (oneOnboarding.selectedConfigurationId) {
      return oneOnboarding.selectedConfigurationId;
    }
    return getSelectedConfigurationId() || '';
  }, [oneOnboarding.selectedConfigurationId]);

  const applicationId = useMemo(() => {
    if (oneOnboarding.applicationId) {
      return oneOnboarding.applicationId;
    }
    return getApplicationId() || '';
  }, [oneOnboarding.applicationId]);

  const { mutate } = mutateOneOnboarding(
    tenantId,
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    queryClient,
    () => history.push('dashboard'),
  );

  const validationSchema = useMemo(() => {
    if (!fw) return null;

    return Yup.object().shape({
      ...consentPage?.getValidationObject(fw, t),
    });
  }, [fw, consentPage, t]);

  const { isInitialLoading: flowLoading = true, data = null } = useQuery({
    queryKey: ['flowData', clientOrganizationId, configurationId],
    queryFn: () => aamBackendApi.fetchSelectedConfiguration(clientOrganizationId, configurationId),
    enabled: !!clientOrganizationId,
  });

  useEffect(() => {
    if (data) {
      const flow = OOFlowWrapper.create(data.flow);
      setLogo(data.logo || '');
      setFw(flow);
      const [regStepName, regPageName] =
        flow && flow.steps ? getRegistrationStepInfo({ ...flow, flow: flow.steps }) : [];
      const step = flow && flow.steps ? flow.getStep(regStepName) : null;
      if (step) setConsentStep(step);
      const page = step?.pages?.find((itm) => itm.name === regPageName);
      if (page) setConsentPage(page);
    } else if (data != null && !flowLoading) {
      history.push('/oo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, flowLoading]);

  useEffect(() => {
    createTelemetryAuthenticated({
      eventType: EventType.PAGE_LOADED,
      targetName: TargetName.CandidateRegistration,
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: configurationId,
      tenantId: tenantId,
      actor: TelemetryActor.CANDIDATE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (flowLoading) {
    return <Loader />;
  }
  const consentPageInitialValues = fw ? consentPage?.getInitialValues(fw) : {};
  const initialValues = { email: userEmail, ...consentPageInitialValues };

  const prepareConsents = (values: any) => {
    const result: any = {};

    const controls = consentPage?.controls ?? [];
    for (const control of controls) {
      const controllerName = Object.keys(values)?.find((key) => key === control.name);
      let { sourceField } = control;
      sourceField = sourceField?.split('.')[1];
      const isDateController = control.type === 'date' ? true : false;
      if (controllerName && !isDateController && sourceField) {
        result[sourceField] = values[controllerName];
      } else if (isDateController) {
        result[sourceField] = moment.utc();
      } else if (sourceField) {
        result[sourceField] = false;
      }
    }

    return result;
  };

  return (
    <div className="preRegister">
      <Header
        isAuthenticated={Boolean(tokenApp) && authMethodApp === 'b2c'}
        {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }}
        isOnPreRegisterPage={false}
      />
      {userEmail && (
        <div className="innerContainer">
          <h1 className="primaryHeader">{t('ENTRY.REGISTRATION.CONSENTS.label')}</h1>

          <div className="row">
            <p>{t('candidate:GENERAL.CONTACT_US.email')}</p>
          </div>

          <div>
            <Formik
              validateOnMount={true}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                const consentsValues = prepareConsents(values);
                const data = {
                  legalConsent: consentsValues,
                  currentStep: consentStep?.name,
                  currentPage: consentPage?.name,
                };

                try {
                  await mutate(data);
                } catch (err) {
                  if (err?.response?.data?.error?.data?.message) {
                    const parsed = parseValidationErrors(t, err);
                    if (parsed.errors) {
                      actions.setErrors(parsed.errors);
                    }
                  }
                }
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit} style={{ display: 'grid', gap: 16 }}>
                  <Field name="email">
                    {({ field, meta }: FieldProps<string, any>) => (
                      <TextField
                        {...field}
                        placeholder={t('candidate:GENERAL.REGISTRATION.emailFieldPlaceholder')}
                        disabled={!!userEmail}
                      />
                    )}
                  </Field>

                  {consentPage?.controls
                    .filter((control) => control.isVisibleCandidate)
                    .map((control: OOControlModel) => (
                      <OOFormControl control={control} key={control.name} />
                    ))}
                  <Button type="submit" variant="contained" color="primary" disabled={!props.isValid}>
                    {t('candidate_recruiter:GENERAL.GENERIC.submit')}
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};
