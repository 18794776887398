import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Utils } from '@helpers/utils';
import CameraAlt from '@mui/icons-material/CameraAlt';
import { Box, Button, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import { useUserMedia } from '../hooks/useUserMedia';

const CAPTURE_OPTIONS_INIT = {
  audio: false,
  video: { facingMode: 'environment' },
};

const CAPTURE_OPTIONS_DYNAMIC = {
  video: {
    facingMode: 'environment', //back camera
    deviceId: {
      exact: '',
    },
  },
};

const isDeviceSupport = () => 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices;

interface ICamera {
  onCapture: (blob: any) => void;
  defaultFrontCamera?: boolean;
  defaultFacingMode: 'user' | 'environment';
}

export const Camera: React.FC<ICamera> = ({ onCapture, defaultFrontCamera = false, defaultFacingMode }) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const [facingMode, setFacingMode] = useState<string>(defaultFacingMode);
  const [stream, setStream] = useState<MediaStream>();
  const [inited, setInited] = useState(false);
  const [selectCamera, setSelectCamera] = useState('');
  const [cameraList, setCameraList] = useState<
    Array<{
      id: string;
      text: string;
      facingMode: string;
    }>
  >([]);

  interface CameraObject {
    id: string;
    text: string;
    facingMode: string;
  }

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isLandscapeMode, setIsLandscapeMode] = useState(false);

  const mediaStream = useUserMedia({ ...CAPTURE_OPTIONS_INIT, video: { ...CAPTURE_OPTIONS_INIT.video, facingMode } });

  if (inited) {
    Utils.consoleLog('Camera initialized.');
  }

  useEffect(() => {
    if (!isDeviceSupport())
      alert(
        'This page has no permissions to access camera. Please allow permissions for the camera in browser settings',
      );
    else {
      setInited(true);
    }
  }, []);

  const fillCameraList = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      const res = [];
      for (const device of devices) {
        if (device.kind === 'videoinput') {
          res.push({
            id: device.deviceId,
            text: device.label,
            facingMode: device.label.includes('front') || device.label.includes('Front') ? 'user' : 'environment',
          });
        }
      }

      setCameraList(res.filter((device) => device.facingMode === facingMode));
      res.reverse();

      if (res.length > 0) {
        const frontCamera: CameraObject | undefined = res.find((item: CameraObject) => item.facingMode === 'user');
        const backCamera: CameraObject | undefined = res.find(
          (item: CameraObject) => item.facingMode === 'environment',
        );

        if (defaultFrontCamera && frontCamera) {
          setFacingMode('user');
          setSelectCamera(frontCamera.id);
        } else if (!defaultFrontCamera && backCamera) {
          setFacingMode('environment');
          setSelectCamera(backCamera.id);
        } else {
          setFacingMode(res[0].facingMode);
          setSelectCamera(res[0].id);
        }
      }
    });
  }, [defaultFrontCamera, facingMode]);

  useEffect(() => {
    if (mediaStream) {
      fillCameraList();
    }
  }, [mediaStream, fillCameraList]);

  const updateDimensions = useCallback(async () => {
    if (isVideoPlaying) {
      stream?.getTracks().forEach((track) => {
        track.stop();
      });
      setIsVideoPlaying(false);
      setContainer({
        width: 0,
        height: 0,
      });

      CAPTURE_OPTIONS_DYNAMIC.video.deviceId.exact = selectCamera;
      CAPTURE_OPTIONS_DYNAMIC.video.facingMode = facingMode;
      const streamNew = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS_DYNAMIC);
      if (streamNew && videoRef.current) {
        (videoRef.current as any).srcObject = streamNew;
        setStream(streamNew);
      }
    }
  }, [stream, isVideoPlaying, selectCamera, facingMode]);

  const updateDimensionsOnResize = useCallback(async () => {
    if (isVideoPlaying) {
      // stream?.getTracks().forEach((track) => {
      //   track.stop();
      // });
      // setIsVideoPlaying(false);
      // setContainer({
      //   width: 0,
      //   height: 0,
      // });

      CAPTURE_OPTIONS_DYNAMIC.video.deviceId.exact = selectCamera;
      CAPTURE_OPTIONS_DYNAMIC.video.facingMode = facingMode;
      const streamNew = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS_DYNAMIC);
      if (streamNew && videoRef.current) {
        (videoRef.current as any).srcObject = streamNew;
        setStream(streamNew);
      }
    }
  }, [isVideoPlaying, selectCamera, facingMode]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensionsOnResize);
    return () => window.removeEventListener('resize', updateDimensionsOnResize);
  }, [updateDimensionsOnResize]);

  useEffect(() => {
    window.addEventListener('orientationchange', updateDimensions);
    return () => window.removeEventListener('orientationchange', updateDimensions);
  }, [updateDimensions]);

  const onStart = useCallback(async () => {
    CAPTURE_OPTIONS_DYNAMIC.video.deviceId.exact = selectCamera;
    CAPTURE_OPTIONS_DYNAMIC.video.facingMode = facingMode;
    const stream = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS_DYNAMIC);
    if (stream && videoRef.current) {
      (videoRef.current as any).srcObject = stream;
      setStream(stream);
    }
    // eslint-disable-next-line
  }, [defaultFrontCamera, selectCamera, facingMode, defaultFacingMode]);

  const onStop = useCallback(() => {
    stream?.getTracks().forEach((track) => {
      track.stop();
    });
    setIsVideoPlaying(false);
    const res = {
      width: 0,
      height: 0,
    };
    setContainer(res);
  }, [stream]);

  useEffect(() => {
    if (selectCamera) {
      onStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCamera]);

  useEffect(() => {
    return () => {
      stream?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [stream]);

  const changeHandler = (
    event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode,
  ) => {
    onStop();
    const cameraId = String(event.target.value);
    const cameraFacingMode: any = cameraList.find((cam) => cam.id === cameraId);
    setFacingMode(cameraFacingMode.facingMode);
    setSelectCamera(cameraId);
  };

  function onCanPlay() {
    const current = videoRef.current as any;
    setIsLandscapeMode(window.matchMedia('(orientation:landscape)').matches && window.innerHeight <= 480);
    if (container.width === 0) {
      setContainer({
        width: current.videoWidth,
        height: current.videoHeight,
      });
    }
    setIsVideoPlaying(true);
    current.play();
  }

  const onCaptureHandler = useCallback(() => {
    const canvasCurrent = canvasRef.current as any;
    const context = canvasCurrent.getContext('2d');

    context.drawImage(videoRef.current, 0, 0, container.width, container.height);
    canvasCurrent.toBlob((blob: any) => onCapture(blob), 'image/jpeg', 1);
    onStop();
  }, [container, onCapture, onStop]);

  const resolveVideoMaxWidth = () => {
    if (isLandscapeMode) {
      return `calc(${window.innerHeight - 80}px)`;
    } else {
      return '90%';
    }
  };

  const videoHidden = () => (!isVideoPlaying ? { display: 'hidden' } : {});

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        {/* TODO: migration to mui 5 issue */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Select value={selectCamera} onChange={changeHandler} style={{ marginBottom: 10 }}>
          {cameraList.map((i, index) => (
            <MenuItem key={index} value={i.id}>
              {i.text}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          marginBottom: '20px',
        }}
      >
        <video
          ref={videoRef as any}
          style={{ maxWidth: resolveVideoMaxWidth(), marginBottom: '10px', ...videoHidden }}
          onCanPlay={onCanPlay}
          autoPlay
          playsInline
        />
        <canvas
          ref={canvasRef as any}
          style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: 0, height: 0 }}
          width={container.width}
          height={container.height}
        />
        {isVideoPlaying && (
          <Button
            onClick={onCaptureHandler}
            style={{
              minWidth: '50px',
              background: 'red',
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              position: 'absolute',
              bottom: '-15px',
            }}
          >
            <CameraAlt style={{ color: '#fff' }} />
          </Button>
        )}
      </div>
    </>
  );
};
