import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@adeccoux/tag-ds';

interface ModalProps {
  isOpen: boolean;
  title: string;
  content: string;
  onConfirm?: () => any;
  onClose?: () => any;
  confirmLabel?: string;
  cancelLabel?: string;
}

export const ModalPrompt: React.FC<ModalProps> = ({
  isOpen,
  title,
  content,
  onConfirm,
  onClose,
  confirmLabel,
  cancelLabel,
}) => {
  return (
    <div className="tag-ds">
      <Modal onClose={onClose} open={isOpen} renderAsPortal={true}>
        <ModalHeader>
          <h4>{title}</h4>
        </ModalHeader>
        <ModalBody>{content} </ModalBody>
        <ModalFooter>
          <button className="button-primary" onClick={onConfirm}>
            {confirmLabel}
          </button>
          <button className="button-secondary" onClick={onClose}>
            {cancelLabel}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
