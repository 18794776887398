import { CountryModel } from '@models/country.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CountriesStoreState {
  countries: CountryModel[];
}
const initialState: CountriesStoreState = {
  countries: [],
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<CountryModel[]>) => {
      state.countries = action.payload.sort((a, b) => a.name.localeCompare(b.name));
      return state;
    },
  },
});
