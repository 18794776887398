import React, { useContext } from 'react';
import { ViewOnlyContext } from '@context/view-only-provider';
import ConditionSign from '@features/selfService/components/ConditionSign/ConditionSign';
import { Box } from '@mui/material';

import { DocumentPropertyType } from './DocumentList';

import styles from '../../ConfigurationSetup.module.scss';

interface Props {
  document: any;
  isActive: boolean;
  getTranslation: (key: string) => string;
  setSelectedDocumentDetails: ({ type, controls }: { type: DocumentPropertyType; controls: any }) => void;
  handleDocumentDelete: (documentName: string) => void;
  expandedDocumentation: any | null;
  setExpandedDocumentation: React.Dispatch<React.SetStateAction<any>>;
}

const DocumentationItem: React.FC<Props> = ({
  document,
  isActive,
  getTranslation,
  setSelectedDocumentDetails,
  handleDocumentDelete,
  expandedDocumentation,
  setExpandedDocumentation,
}) => {
  const documentHavingCondition = document.condition?.length > 0;
  const { isViewOnly } = useContext(ViewOnlyContext);

  return (
    <div
      key={document.name}
      className={`${styles.controlListItem} ${styles.controlListItem__document}`}
      style={{ border: isActive ? '1px solid #009ACC' : '' }}
      onClick={() => setSelectedDocumentDetails({ type: 'document', controls: document })}
    >
      <Box display="flex" alignItems="center">
        {!isViewOnly && (
          <div className="material-icons" style={{ fontSize: 15 }}>
            drag_indicator
          </div>
        )}
        <p className={`subtitle ${styles.controlListItem__label}`} style={{ wordBreak: 'break-all' }}>
          {getTranslation(document.label)}
        </p>
      </Box>
      <Box display="flex">
        {!isViewOnly && (
          <div
            className={styles.controlListItem__button}
            onClick={(e) => {
              e.stopPropagation();
              handleDocumentDelete(document.name);
            }}
          >
            <span className="material-icons-outlined">delete</span>
          </div>
        )}

        <div
          className="material-icons"
          style={{
            fontSize: 15,
            cursor: 'pointer',
            transform: expandedDocumentation ? 'rotate(180deg)' : '',
            marginLeft: 8,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setExpandedDocumentation((state: any) => (state ? null : document));
          }}
        >
          keyboard_arrow_up
        </div>
      </Box>
      {documentHavingCondition && <ConditionSign />}
    </div>
  );
};

export default DocumentationItem;
