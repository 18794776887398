import axios from 'axios';
import { History, Location } from 'history';
import { injectable } from 'inversify';
import getPkce from 'oauth-pkce';
import { Dispatch } from 'redux';
import { IB2CSettings } from '@models/featureConfiguration.model';
import { authAppSlice } from '@store/slices/authApp.slice';

import { AamBackendApi } from '../libs/aamBackendApi';
import { AppConfig } from './appConfig';
import { Utils } from './utils';

export enum AzureB2CRedirectType {
  signIn = 'signIn',
  signUp = 'signUp',
  resetPassword = 'resetPassword',
  refresh = 'refresh',
}

export interface B2CTokenResponse {
  id_token: string;
  id_token_expires_in: number;
  not_before: number;
  profile_info: string;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
  token_type: string;
}
export interface B2CTokenResponseParsed {
  idToken: string;
  idTokenExpiresIn: number;
  notBefore: number;
  profileInfo: string;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  scope: string;
  tokenType: string;
}

export class InvalidCodeVerifierError extends Error {}

declare const cordova: any;

@injectable()
export class AuthHelper {
  // InAppBrowser instance.
  private iab: any = null;

  constructor(private readonly appConfig: AppConfig, private readonly aamBackendApi: AamBackendApi) {}

  public isInAppBrowserEnabled(): boolean {
    return typeof cordova !== 'undefined' && cordova?.InAppBrowser;
  }

  public async finishLoginB2C(
    tenantId: number,
    userId: string,
    tokenResponse: B2CTokenResponseParsed,
    dispatch: Dispatch<any>,
  ) {
    dispatch(
      authAppSlice.actions.authB2C({
        data: tokenResponse,
        userId: userId,
      }),
    );

    const user = await this.aamBackendApi.fetchUser(tenantId, userId);
    let userProfile = null;
    if (user) {
      userProfile = await this.aamBackendApi.fetchUserProfile(tenantId, userId);
    }
    dispatch(
      authAppSlice.actions.userData({
        user: user as any,
        userProfile: userProfile as any,
      }),
    );
  }

  public addCodeVerifierInSessionStorage(codeVerifier: string) {
    localStorage.setItem('codeVerifier', codeVerifier);
  }

  public removeCodeVerifierFromSessionStorage() {
    localStorage.removeItem('codeVerifier');
  }

  public getCodeVerifierFromSessionStorage(): string | null {
    return localStorage.getItem('codeVerifier');
  }

  public async b2cGetTokenFromCode(
    tokenUri: string,
    parameters: {
      grantType: string;
      clientId: string;
      redirectUri: string;
      code: string;
      codeVerifier: string;
    },
  ): Promise<B2CTokenResponse> {
    const encodedParameters = [
      `grant_type=${encodeURIComponent(parameters.grantType)}`,
      `client_id=${encodeURIComponent(parameters.clientId)}`,
      `redirect_uri=${encodeURIComponent(parameters.redirectUri)}`,
      `code=${encodeURIComponent(parameters.code)}`,
      `code_verifier=${encodeURIComponent(parameters.codeVerifier)}`,
    ].join('&');

    const httpClient = axios.create();
    const response = await httpClient.post<any>(tokenUri, encodedParameters, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });
    return response.data;
  }

  public async exchangeAuthCode(
    code: string,
    settings: IB2CSettings,
    redirectUrl: string,
  ): Promise<B2CTokenResponseParsed> {
    const codeVerifier = this.getCodeVerifierFromSessionStorage();
    if (!codeVerifier) {
      Utils.consoleError('Failed to apply token, empty code_verifier.');
      throw new InvalidCodeVerifierError('Failed to apply token, empty code_verifier.');
    }
    this.removeCodeVerifierFromSessionStorage();

    let b2cTokenResponse: B2CTokenResponse;

    try {
      b2cTokenResponse = await this.b2cGetTokenFromCode(settings.tokenUri, {
        grantType: 'authorization_code',
        clientId: settings.clientId,
        redirectUri: redirectUrl,
        code,
        codeVerifier: codeVerifier,
      });
    } catch (err) {
      const errorMessage = err.response?.data?.error_description;
      if (errorMessage?.includes('code_verifier')) {
        throw new InvalidCodeVerifierError(errorMessage);
      }
      throw err;
    }

    return {
      idToken: b2cTokenResponse.id_token,
      idTokenExpiresIn: b2cTokenResponse.id_token_expires_in,
      notBefore: b2cTokenResponse.not_before,
      profileInfo: b2cTokenResponse.profile_info,
      refreshToken: b2cTokenResponse.refresh_token,
      refreshTokenExpiresIn: b2cTokenResponse.refresh_token_expires_in,
      scope: b2cTokenResponse.scope,
      tokenType: b2cTokenResponse.token_type,
    };
  }

  public get redirectUrl() {
    return `${window.location.protocol}//${window.location.host}/app/aadlogin/token`;
  }

  public async getAuthorizeUrlSignIn(
    settings: IB2CSettings,
    location: Location,
    state?: { email?: string; brands?: string[]; isEmailInputDisabled?: boolean; userExistsOnCurrentTenant?: boolean },
    additionUrlParams?: any,
  ): Promise<string> {
    return await this.getAuthorizeUrl(
      settings,
      {
        action: AzureB2CRedirectType.signIn,
        ...(state ?? {}),
      },
      state?.email,
      additionUrlParams,
    );
  }

  public async getAuthorizeUrlResetPassword(
    settings: IB2CSettings,
    location: Location,
    email?: string,
    state?: any,
    additionUrlParams?: any,
  ): Promise<string> {
    return await this.getAuthorizeUrl(
      settings,
      {
        action: AzureB2CRedirectType.resetPassword,
        ...(state ?? {}),
      },
      email,
      additionUrlParams,
    );
  }

  public async getAuthorizeUrlSignup(
    settings: IB2CSettings,
    location: Location,
    email?: string,
    state?: any,
    additionUrlParams?: any,
  ): Promise<string> {
    return await this.getAuthorizeUrl(
      settings,
      {
        action: AzureB2CRedirectType.signUp,
        ...(state ?? {}),
      },
      email,
      additionUrlParams,
    );
  }

  private async getAuthorizeUrl(
    settings: IB2CSettings,
    state: { action: AzureB2CRedirectType; [key: string]: any },
    email?: string,
    additionUrlParams?: any,
  ): Promise<string> {
    const { codeVerifier, codeChallenge } = (await new Promise((resolve) => {
      getPkce(43, (error, { verifier, challenge }) => {
        if (error) {
          throw error;
        }
        resolve({ codeVerifier: verifier, codeChallenge: challenge });
      });
    })) as any;

    this.addCodeVerifierInSessionStorage(codeVerifier);

    const encryptedState = Utils.encodeBase64(JSON.stringify(state));
    const additionUrlParamsStr = additionUrlParams
      ? Object.keys(additionUrlParams)
          .filter((k) => !!additionUrlParams[k])
          .map((x) => `${x}=${additionUrlParams[x]}`)
          .join('&')
      : '';

    return [
      `${settings.authorizeUri}`,
      `client_id=${settings.clientId}`,
      'response_type=code',
      `code_challenge=${codeChallenge}`,
      'code_challenge_method=S256',
      `redirect_uri=${encodeURIComponent(this.redirectUrl)}`,
      'scope=openid',
      'response_mode=fragment',
      `prompt=login`,
      `state=${encodeURIComponent(encryptedState)}`,
      `${additionUrlParamsStr}`,
    ]
      .filter((x) => !!x)
      .join('&');
  }

  private openInAppBrowser(url: string, target: string) {
    if (this.isInAppBrowserEnabled()) {
      if (!this.iab) {
        this.iab = cordova.InAppBrowser.open(
          url,
          target,
          'toolbar=no,location=no,fullscreen=yes,zoom=no,presentationstyle=fullscreen',
        );
      } else {
        this.iab.executeScript({ code: `window.location.href = '${url}';` });
        this.iab.show();
      }
      return this.iab;
    }
    return null;
  }

  private async closeInAppBrowser() {
    return new Promise((resolve) => {
      this.iab.hide();
      resolve(void 0);
    });
  }

  public b2cNavigation(b2cSettings: IB2CSettings, authUrl: string, history: History, useExternalBrowser: boolean) {
    if (!this.isInAppBrowserEnabled()) {
      // Just switch window location as we are in web mode
      window.location.assign(authUrl);
      return false;
    }
    // We are in Cordova mode after this line
    if (useExternalBrowser) {
      Utils.consoleLog(`Opening ${authUrl} in system browser`);
      this.openInAppBrowser(authUrl, '_system');
      return;
    }

    // We are not using external browser. open in inAppBrowser
    const redirectUrl = this.redirectUrl;
    Utils.consoleLog(`Opening ${authUrl} in InAppBrowser`);
    const iab = this.openInAppBrowser(authUrl, '_blank');
    if (!iab.setupDone) {
      iab.setupDone = true;
      iab.openedAt = new Date().valueOf();
      iab.addEventListener('loadstart', async (event: any) => {
        if (!event.url.startsWith(redirectUrl)) {
          Utils.consoleLog('InAppBrowser opened ' + event.url);
          return;
        }
        // we have loaded return url, parse it and forward to return url within app
        const retUrl = new URL(event.url);
        // this.aamBackendApi.updateUser(tenantId,userId,{useAcceptedConditions:retUrl.hash} )
        await this.closeInAppBrowser();
        history.push({ pathname: retUrl.pathname, hash: retUrl.hash.substr(1) });
      });
    }
  }
}

export const getRegistrationStepInfo = (config: any): string[] => {
  const regStep = config.flow?.find((itm: any) => itm.name.toLowerCase().includes('regist'));
  const { clientOrganizationId } = config;
  if (!regStep) {
    Utils.consoleLog('registrationStepNotDefined', JSON.stringify({ clientOrganizationId }));
    throw new Error('Registration step not defined.');
  }
  const regPage = regStep.pages?.find((itm: any) => itm.name.toLowerCase().includes('consent')) || regStep.pages[0];
  if (!regPage) {
    Utils.consoleLog('registrationPageNotDefined', JSON.stringify({ clientOrganizationId }));
  }
  return [regStep.name, regPage?.name];
};
