import React, { useState } from 'react';
import { Help, HelpOutline, Info, InfoOutlined } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';

import { useT } from '../../hooks/useT';
import { HtmlCustom } from './HtmlCustom';
import { OOSelect } from './OOSelect';
import { OOSelectCheckbox } from './SelectCheckbox/OOSelectCheckbox';

export type OOSelectWithIconProps = {
  isEditableCandidate?: boolean;
  isEditableRecruiter?: boolean;
  validationKey: string;
  label: string;
  description: string;
  menuItems?: JSX.Element[];
  mandatory: boolean;
  loading: boolean;
  defaultValue?: any;
  iconType?: 'INFO' | 'HELP' | undefined;
  onChangeCallback?: (event: any) => void;
  hasHtmlDescription?: boolean;
  rootProps?: any;
  multiple?: boolean;
  checkBoxItems?: boolean;
};

export const OOSelectWithIcon: React.FC<OOSelectWithIconProps> = (props) => {
  const { t } = useT('entry');
  const theme = useTheme();
  const [isIconOutlined, setIsIconOutlined] = useState(false);

  const prefix = props.label.split('.')[0].toLowerCase();
  const description = t(`${prefix}:${props.description}`);

  const renderIcon = () => {
    switch (props?.iconType) {
      case 'HELP':
        return isIconOutlined ? <Help color="primary" /> : <HelpOutline color="primary" />;
      case 'INFO':
        return isIconOutlined ? <Info color="primary" /> : <InfoOutlined color="primary" />;
      default:
        return <></>;
    }
  };

  return (
    <Box>
      <Box>
        {props.checkBoxItems ? (
          <OOSelectCheckbox
            validationKey={props.validationKey}
            label={props.label}
            mandatory={props.mandatory}
            defaultValue={props.defaultValue}
            isEditableCandidate={props.isEditableCandidate}
            isEditableRecruiter={props.isEditableRecruiter}
            description={props.iconType ? '' : props.description}
            menuItems={props.menuItems}
            loading={props.loading}
            onChangeCallback={props.onChangeCallback}
            multiple={props.multiple}
            rootProps={props.rootProps}
            checkBoxItems={props.checkBoxItems}
          />
        ) : (
          <OOSelect
            validationKey={props.validationKey}
            label={props.label}
            mandatory={props.mandatory}
            defaultValue={props.defaultValue}
            isEditableCandidate={props.isEditableCandidate}
            isEditableRecruiter={props.isEditableRecruiter}
            description={props.iconType ? '' : props.description}
            menuItems={props.menuItems}
            loading={props.loading}
            rootProps={props.rootProps}
            onChangeCallback={props.onChangeCallback}
            multiple={props.multiple}
          />
        )}
        {description && (
          <Box pl={1} height="16px" onClick={() => setIsIconOutlined(!isIconOutlined)}>
            {renderIcon()}
          </Box>
        )}
      </Box>
      {description && isIconOutlined && !props?.hasHtmlDescription && (
        <Box bgcolor={theme.palette.primary.main} px={2} py={1} my={1}>
          <Typography variant="body1" style={{ color: theme.palette.primary.contrastText }}>
            {description}
          </Typography>
        </Box>
      )}
      {description && isIconOutlined && !!props?.hasHtmlDescription && (
        <HtmlCustom controlModel={{ ...props.rootProps, staticText: props.description }} />
      )}
    </Box>
  );
};
