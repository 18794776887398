import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';

export const getPaymentInfoDocuments = (steps: OOStepModel[]): OODocumentModel[] => {
  const step = steps.find((x) => x.name === 'DOCUMENT_UPLOAD2');
  const documentations = step?.documentations || [];
  return documentations
    .filter((x) => x.isMandatory)
    .map((i) => i.documents)
    .flat();
};
export const useRecruiterValidatesPaymentInfo = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const documents = getPaymentInfoDocuments(steps).filter((i) => i.name !== 'PHYSICAL_CONTRACT');
  const isReview = documents.some(
    ({ userDocument }) =>
      userDocument === undefined || userDocument?.additionalData?.status === OnboardingDocumentStatus.REVIEW,
  );
  const active = currentStep === 'DOCUMENT_VALIDATION2' && isReview;
  const isStepCompleted = flowWrapper.isStepCompleted('DOCUMENT_VALIDATION2');
  const completed = isStepCompleted || !isReview;
  const disabled = active || completed ? false : !isReview;
  return {
    active,
    disabled,
    completed,
    onClick: (): void => {
      const step = steps.find((x) => x.name === 'DOCUMENT_UPLOAD2');
      setPageStepContext({ step, page: undefined });
    },
  };
};
