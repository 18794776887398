import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DatePicker, Option, Select } from '@adeccoux/tag-ds';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { useAllConfigurationsForTenant } from '@hooks/apiHooks';
import { BranchModel } from '@models/branch.model';
import { ClientOrganizationModel } from '@models/clientOrganization.model';
import { Box, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { CampaignModel } from '../../../oneOnboarding/models/CampaignModel';
import { AdminReactiveSelect } from '../../components/AdminReactiveSelect/AdminReactiveSelect';

import styles from '../CampaignDetails/CampaignDetails.module.scss';

const EditCampaign = () => {
  const params = useParams<{
    campaignUrl: string;
  }>();

  const { tenantId } = useSelector((state: RootState) => state.tenant);

  const [disabledEndDate, setDisabledEndDate] = useState(false);
  const [journeysData, setJourneysData] = useState<any[]>([]);
  const [branchsData, setBranchsData] = useState<BranchModel[]>([]);
  const [campaignData, setCampaignData] = useState<CampaignModel>({
    tenantId: tenantId,
    url: params.campaignUrl,
    name: '',
    enabled: true,
    clientOrganizationId: '',
    selectedConfigurationId: '',
    applicationId: '',
    startDate: new Date(),
  });

  const { t } = useTranslation(['recruiter']);

  const history = useHistory();

  const aamBackendApi = useInjection(AamBackendApi);

  const { data, isLoading } = useQuery({
    queryKey: ['fetchCampaign', params.campaignUrl],
    queryFn: () => aamBackendApi.fetchCampaign(params.campaignUrl),
    onSuccess(data: any) {
      setCampaignData(data);
      const duration = moment.duration(moment(data?.endDate).diff(data?.startDate));
      const durationYears = duration.years();
      if (durationYears === 10) {
        setDisabledEndDate(true);
      }
      return data;
    },
    retry: false,
    refetchOnMount: true,
  });

  const campaignUrlFull = useMemo(() => {
    return `${window.location.origin}/oo/campaign?campaignUrl=${data?.url}`;
  }, [data]);

  const {
    data: allConfigurationsForTenant,
    isInitialLoading: configurationsAreLoading,
  } = useAllConfigurationsForTenant(tenantId);

  const { data: allBranchs } = useQuery({
    queryKey: ['fetchBranchsByTenantAsyncImport'],
    queryFn: () => aamBackendApi.fetchBranches(),
    refetchOnMount: true,
  });

  const updateCampaign = async () => {
    try {
      const response = await aamBackendApi.updateCampaign(tenantId, campaignData);
      history.push(`/oo/recruiter/import-users/campaigns/${response.url}`);
    } catch (err) {
      window.alert(err);
    }
  };

  const onBack = () => {
    history.push(`/oo/recruiter/import-users/campaigns/${data.url}`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignData((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleClientOrganizationChange = (value: string) => {
    setCampaignData({ ...campaignData, clientOrganizationId: value, selectedConfigurationId: '' });
  };

  const handleJourneyChange = (value: string) => {
    setCampaignData({
      ...campaignData,
      selectedConfigurationId: value,
    });
  };

  const handleBranchChange = (value: string) => {
    setCampaignData({
      ...campaignData,
      branchIds: [value],
    });
  };

  const makeCampaignIndefinitely = () => {
    if (!disabledEndDate) {
      setDisabledEndDate(true);

      const startDateCopy = new Date(campaignData.startDate);

      const endDate = new Date(startDateCopy.setFullYear(startDateCopy.getFullYear() + 10));

      setCampaignData({ ...campaignData, endDate });
    } else {
      setDisabledEndDate(false);
    }
  };

  const downloadPNG = () => {
    const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrcode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    const journeys: any[] = [];
    if (allConfigurationsForTenant) {
      allConfigurationsForTenant
        .filter((x: any) => x.id === campaignData.clientOrganizationId)
        .forEach((element: any) => {
          if (element.selectedConfigurations && element.selectedConfigurations.length > 0) {
            element.selectedConfigurations.forEach((item: any) => {
              journeys.push({
                id: item.id,
                name: item.candidateName,
                value: item.id,
              });
            });
          }
        });
    }

    setJourneysData(journeys);
  }, [campaignData.clientOrganizationId, allConfigurationsForTenant]);

  useEffect(() => {
    let uniqueBranchs: any[] = [];
    if (allBranchs) {
      uniqueBranchs = [...new Map(allBranchs?.map((item) => [item['sid'], item])).values()];
    }
    setBranchsData(uniqueBranchs);
  }, [allBranchs]);

  if (isLoading || configurationsAreLoading) {
    return <Loader />;
  }

  return (
    <div className="campaign">
      <PageWrapper
        featureName="campaign-details"
        pageName="campaign-details"
        headerConfig={{ pageName: data?.name || '', languagePicker: true, onBack }}
        sideMenu={true}
      >
        <div className={`${styles.featureCampaignDetails} tag-ds`}>
          <div className={`${styles.featureCampaignDetailsSummary} tag-ds`}>
            <Grid container className="tag-ds">
              <Grid item minWidth={440}>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.campaignName').toUpperCase()}</label>
                    <input
                      placeholder={t('GENERAL.IMPORT.enterCampaignName')}
                      type="text"
                      name="name"
                      onChange={handleInputChange}
                      defaultValue={campaignData.name}
                    />
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.selectClient').toUpperCase()}</label>
                    <div style={{ marginTop: '8px' }}>
                      <Select
                        className="tag-ds"
                        placeholder={t('GENERAL.IMPORT.selectAnOption')}
                        type="text"
                        value={campaignData.clientOrganizationId}
                        name="clientOrganizationId"
                        onChange={(e) => handleClientOrganizationChange(e)}
                      >
                        {allConfigurationsForTenant?.map((item: ClientOrganizationModel) => (
                          <Option label={item.name ? item.name : ''} value={item.id} key={item.id} />
                        ))}
                      </Select>
                    </div>
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.selectJourney').toUpperCase()}</label>
                    <div>
                      <AdminReactiveSelect
                        value={campaignData.selectedConfigurationId}
                        label=""
                        name="selectedConfigurationId"
                        placeholder={t('GENERAL.IMPORT.selectAnOption')}
                        options={journeysData}
                        onChange={(e) => handleJourneyChange(e.target.value as string)}
                      />
                    </div>
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.application').toUpperCase()}</label>
                    <input
                      className=""
                      placeholder={t('GENERAL.IMPORT.enterApplication')}
                      type="text"
                      name="applicationId"
                      value={campaignData.applicationId}
                      disabled={true}
                    />
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.headerBranch').toUpperCase()}</label>
                    <div>
                      <AdminReactiveSelect
                        value={campaignData.branchIds ? campaignData.branchIds[0] : ''}
                        label=""
                        name="selectedBranchId"
                        placeholder={t('GENERAL.IMPORT.selectAnOption')}
                        options={branchsData.map((x) => ({
                          name: x.name,
                          value: x.id,
                        }))}
                        onChange={(e) => handleBranchChange(e.target.value as string)}
                      />
                    </div>
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.startDate').toUpperCase()}</label>
                    <DatePicker
                      value={moment(data.startDate).toDate().getTime()}
                      defaultValue={moment(data.startDate).toDate().getTime()}
                      name="startDate"
                      dateFormat="MM/dd/yyyy"
                      onChange={(date) => {
                        setCampaignData({ ...campaignData, startDate: new Date(date as number) });
                      }}
                    />
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds checkbox-container">
                    <input
                      id="activeIndefinitely"
                      type="checkbox"
                      checked={disabledEndDate}
                      onChange={makeCampaignIndefinitely}
                    />
                    <label>{t('GENERAL.IMPORT.makeThisCampaignActive')}</label>
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }}>
                  <div className="tag-ds input-wrapper   ">
                    <label className="caption">{t('GENERAL.IMPORT.endDate').toUpperCase()}</label>
                    <DatePicker
                      name="endDate"
                      value={moment(data.endDate).toDate().getTime()}
                      defaultValue={moment(data.endDate).toDate().getTime()}
                      dateFormat="MM/dd/yyyy"
                      disabled={disabledEndDate}
                      onChange={(date) => {
                        setCampaignData({ ...campaignData, endDate: new Date(date as number) });
                      }}
                    />
                  </div>
                </Box>
                <Box style={{ marginTop: '24px' }} display="flex" justifyContent="space-between">
                  <button className="button-secondary" style={{ minWidth: 110, width: 210 }} onClick={() => onBack()}>
                    {t('GENERAL.IMPORT.discardChanges').toUpperCase()}
                  </button>
                  <button
                    className="button-primary"
                    style={{ minWidth: 110, width: 210 }}
                    onClick={() => updateCampaign()}
                  >
                    {t('GENERAL.IMPORT.saveChanges').toUpperCase()}
                  </button>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className={`${styles.featureCampaignDetailsQr} tag-ds`}>
            <span className={`${styles.featureCampaignDetailsQrLabel}`}>{t('GENERAL.IMPORT.qrCode')}</span>
            <div className={`${styles.featureCampaignDetailsQrImageContainer}`}>
              <QRCode id="qrcode" value={campaignUrlFull} renderAs="canvas" />
            </div>
            <div className={`${styles.featureCampaignDetailsQrCopyButtonContainer} tag-ds`}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className={`${styles.featureCampaignDetailsQrCopyButton} tag-ds`}
                onClick={() => navigator.clipboard.writeText(campaignUrlFull)}
              >
                {t('GENERAL.IMPORT.copyUrl')}
                <span className="material-icons right" style={{ fontSize: '12px' }}>
                  link
                </span>
              </a>
            </div>
            <div className={`${styles.featureCampaignDetailsQrDownloadButtonContainer} tag-ds`}>
              <button
                className={`${styles.featureCampaignDetailsQrDownloadButton} button-secondary_small`}
                onClick={downloadPNG}
              >
                {t('GENERAL.IMPORT.downloadPng')}
                <span className="material-icons right">download</span>
              </button>
            </div>
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};

export default EditCampaign;
