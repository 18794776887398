import React, { PropsWithChildren, Ref } from 'react';
import ReactDOM from 'react-dom';

import styles from './RichTextEditor.module.scss';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}
type OrNull<T> = T | null;

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>,
  ) => <span {...props} ref={ref as any} />,
);

Button.displayName = 'Button';

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    }: PropsWithChildren<
      {
        value: any;
      } & BaseProps
    >,
    ref: Ref<OrNull<null>>,
  ) => {
    const textLines = value.document.nodes
      .map((node: any) => node.text)
      .toArray()
      .join('\n');
    return (
      <div ref={ref as any} {...props}>
        <div>Slate's value as text</div>
        <div>{textLines}</div>
      </div>
    );
  },
);

EditorValue.displayName = 'EditorValue';

export const Icon = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLSpanElement>>) => (
    <span {...props} ref={ref as any} />
  ),
);

Icon.displayName = 'Icon';

export const Instruction = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <div {...props} ref={ref as any} />
  ),
);

Instruction.displayName = 'Instruction';

export const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <div {...props} data-test-id="menu" ref={ref as any} className={styles.editorOptions__container} />
  ),
);

Menu.displayName = 'Menu';

export const Portal: React.FC<any> = ({ children }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<OrNull<HTMLDivElement>>) => (
    <Menu {...props} ref={ref as any} />
  ),
);

Toolbar.displayName = 'Toolbar';
