import { useEffect, useState } from 'react';
import { OOConfiguration } from '@features/oneOnboarding/interfaces';
import {
  DeletionReasonFilterModel,
  StatusFilterModel,
  StepFilterModel,
} from '@features/oneOnboardingAdmin/pages/AdminDashboard/AdminDashboard';

export const useAggregatedStepFilters = (
  tenantConfigurations: OOConfiguration[] | undefined,
  filterClientOrganizationId: string,
  filterJourneyId: string,
) => {
  const [stepFiltersItems, setStepFiltersItems] = useState<Array<StepFilterModel>>([]);

  useEffect(() => {
    const getAggregatedStepFilters = () => {
      const allStepFilters = tenantConfigurations?.flatMap((journey) => journey.stepFilters ?? []);
      return [...new Map(allStepFilters?.map((filter) => [filter.name, filter])).values()];
    };

    let configuration: OOConfiguration | undefined;
    if (!!filterClientOrganizationId && !!filterJourneyId) {
      configuration = tenantConfigurations?.find(
        (itm: any) =>
          itm.clientOrganisationId === filterClientOrganizationId && itm.configurationId === filterJourneyId,
      );
    }

    const stepFilters = configuration ? configuration?.stepFilters ?? [] : getAggregatedStepFilters();

    const stepFiltersTemp: StepFilterModel[] = [
      ...new Map<string, StepFilterModel>(
        stepFilters.map((item) => [
          item.label,
          {
            name: item.name,
            step: item.step,
            label: item.label,
            hasRejectedDocuments: item.hasRejectedDocuments,
            hasMandatoryDocumentsVerified: item.hasMandatoryDocumentsVerified,
            inactivity: item.inactivity,
            userStatus: item.userStatus,
          } as StepFilterModel,
        ]),
      ).values(),
    ];
    setStepFiltersItems(() => stepFiltersTemp);
  }, [tenantConfigurations, filterClientOrganizationId, filterJourneyId]);

  return stepFiltersItems;
};

export const useAggregatedStatusFilters = (
  tenantConfigurations: OOConfiguration[] | undefined,
  filterClientOrganizationId: string,
  filterJourneyId: string,
) => {
  const [statusFiltersItems, setStatusFiltersItems] = useState<Array<StatusFilterModel>>([]);

  useEffect(() => {
    const getAggregatedStatusFilters = () => {
      let allStatusFilters = tenantConfigurations?.flatMap((journey) => journey.statusFilters ?? []);

      if (allStatusFilters) {
        allStatusFilters = [
          ...allStatusFilters,
          {
            label: 'STATUS_FILTERS.ELIGIBLE.label',
            status: 'eligible',
          },
        ];
      }

      return [...new Map(allStatusFilters?.map((filter) => [filter.status, filter])).values()];
    };

    let configuration: OOConfiguration | undefined;
    if (!!filterClientOrganizationId && !!filterJourneyId) {
      configuration = tenantConfigurations?.find(
        (itm: any) =>
          itm.clientOrganisationId === filterClientOrganizationId && itm.configurationId === filterJourneyId,
      );
    }

    const statusFilters = configuration ? configuration?.statusFilters ?? [] : getAggregatedStatusFilters();

    const statusFiltersTemp: StatusFilterModel[] = [
      ...new Map<string, StatusFilterModel>(
        statusFilters.map((item) => [
          item.label,
          {
            status: item.status,
            label: item.label,
          } as StatusFilterModel,
        ]),
      ).values(),
    ].sort((a, b) => a.label.localeCompare(b.label));
    setStatusFiltersItems(() => statusFiltersTemp);
  }, [tenantConfigurations, filterClientOrganizationId, filterJourneyId]);

  return statusFiltersItems;
};

export const useAggregatedDeletionReasonFilter = (
  tenantConfigurations: OOConfiguration[] | undefined,
  filterClientOrganizationId: string,
  filterJourneyId: string,
) => {
  const [deletionReasons, setDeletionReasons] = useState<Array<DeletionReasonFilterModel>>([]);

  useEffect(() => {
    const getAggregatedStatusFilters = () => {
      let allStatusFilters = tenantConfigurations?.flatMap((journey) => journey.deletionReasonFilters ?? []);

      if (allStatusFilters) {
        allStatusFilters = [
          { label: 'DELETION_REASON.ALL.label', status: 'All' },
          { label: 'DELETION_REASON.INACTIVITY.label', status: 'Inactivity' },
          { label: 'DELETION_REASON.DELETION.label', status: 'Deletion' },
          { label: 'DELETION_REASON.COMPLETION.label', status: 'Completion' },
          ...allStatusFilters,
        ];
      }

      return [...new Map(allStatusFilters?.map((filter) => [filter.status, filter])).values()];
    };

    let configuration: OOConfiguration | undefined;
    if (!!filterClientOrganizationId && !!filterJourneyId) {
      configuration = tenantConfigurations?.find(
        (itm: any) =>
          itm.clientOrganisationId === filterClientOrganizationId && itm.configurationId === filterJourneyId,
      );
    }

    const statusFilters = configuration ? configuration?.deletionReasonFilters ?? [] : getAggregatedStatusFilters();

    const statusFiltersTemp: DeletionReasonFilterModel[] = [
      ...new Map<string, DeletionReasonFilterModel>(
        statusFilters.map((item) => [
          item.label,
          {
            status: item.status,
            label: item.label,
          } as DeletionReasonFilterModel,
        ]),
      ).values(),
    ].sort((a, b) => a.label.localeCompare(b.label));
    setDeletionReasons(() => statusFiltersTemp);
  }, [tenantConfigurations, filterClientOrganizationId, filterJourneyId]);

  return deletionReasons;
};
