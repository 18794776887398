import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type AdminInputProps = TextFieldProps & {
  containerStyle?: React.CSSProperties;
  icon?: JSX.Element;
  min?: any;
  iconside?: boolean; //is it on the right (true) or the left side (end-start-Adornment )
  boxProps?: BoxProps;
  timeFormat?: React.ElementType<InputBaseComponentProps>;
};

export const AdminInput: React.FC<AdminInputProps> = ({
  containerStyle,
  icon,
  min,
  iconside = false,
  boxProps,
  timeFormat,
  ...textFieldProps
}) => {
  const clientTextFieldProps = { ...textFieldProps };
  const IconJsx = icon;
  const adornmentPosition = iconside ? 'startAdornment' : 'endAdornment';

  if (boxProps) {
    return (
      <Box {...boxProps}>
        <TextField
          {...clientTextFieldProps}
          InputProps={{
            [adornmentPosition]: IconJsx ? IconJsx : null,
            inputComponent: timeFormat,
          }}
          inputProps={{
            min: min,
            'aria-label': 'client-input',
          }}
        />
      </Box>
    );
  }

  return (
    <div style={containerStyle}>
      <TextField
        {...clientTextFieldProps}
        InputProps={{
          [adornmentPosition]: IconJsx ? IconJsx : null,
          inputComponent: timeFormat,
        }}
        inputProps={{
          min: min,
          'aria-label': 'client-input',
        }}
      />
    </div>
  );
};
