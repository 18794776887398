import { Version } from '@features/oneOnboardingAdmin/components/VersionPicker/VersionPicker';

export enum FeatureConfigurationName {
  b2c = 'b2c',
  adeccoAd = 'adeccoAd',
  appSettings = 'appSettings',
  documents = 'documents',
}

export interface IB2CSettings {
  issuer: string;
  jwksUri: string;
  authorizeUri: string;
  tokenUri: string;
  clientId: string;
  flowUrl: string;
}
export type IAdeccoAdSettings = IB2CSettings;

export type FeatureConfigurationData<T = keyof typeof FeatureConfigurationName> = T extends FeatureConfigurationName.b2c
  ? {
      signIn: IB2CSettings;
      signUp: IB2CSettings;
      resetPassword: IB2CSettings;
    }
  : T extends FeatureConfigurationName.appSettings
  ? {
      useExternalBrowser: boolean;
      useOneSpan: boolean;
      useDocuSign: boolean;
      isOneOnboardingEnabled: boolean;
      appointmentStartOffset: number;
      skipDisyValidations: boolean;
      excludedCountries: string[];
      excludedCountriesForNationality: string[];
      allowedBulkActions?: { name: string; approvalSteps?: string[] }[];
      hideReimportButton?: boolean;
      searchOnboardingEndpointVersion: Version;
      documentFamilyPickerVisible: boolean;
    }
  : T extends FeatureConfigurationName.adeccoAd
  ? { signIn: IAdeccoAdSettings }
  : any;

export interface FeatureConfigurationModel<T = keyof typeof FeatureConfigurationName> {
  tenantId: number;
  channel: string;
  name: T;
  data: FeatureConfigurationData<T>;
}
