import React from 'react';
import { FieldProps } from 'formik';

interface Props {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  isError: any;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  fieldProps?: FieldProps;
  disabled: boolean;
}

const MinimumCharacters: React.FC<Props> = ({ value, onChange, name, isError, onBlur, fieldProps, disabled }) => {
  return (
    <>
      <label className="caption">Minimum characters</label>
      <input
        type="number"
        value={value}
        onChange={onChange}
        name={name}
        className={isError ? 'error' : ''}
        onBlur={onBlur}
        disabled={disabled}
      />
      {isError && <p className="input-helper-text">{fieldProps?.meta.error}</p>}
    </>
  );
};

export default MinimumCharacters;
