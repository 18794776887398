import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HeaderLanguages, Option } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../../../libs/aamBackendApi';
import { translationHelper } from '../../../../../helpers/translationHelper';
import {
  BooleanCondition,
  DateCondition,
  NumberCondition,
  OperatorCondition,
  SelectCondition,
  TextCondition,
} from './ConditionPropertyCatalog';

import controlsStyle from '../Controls.module.scss';
import styles from './ConditionProperty.module.scss';

interface Props {
  option: { operator: string; value: any; notValue: any; control: any };
  onChange: (control: any) => void;
  onDelete: () => void;
  handleNewCondition: (control: any) => void;
  indexValue: number;
  operatorValue: string;
  disabled: boolean;
}

const ConditionPropertyItem: React.FC<Props> = ({
  option,
  onChange,
  onDelete,
  handleNewCondition,
  indexValue,
  operatorValue,
  disabled,
}) => {
  const [values, setValues] = useState<{ operator: string; value: any; notValue: any }>({
    operator: '',
    value: '',
    notValue: '',
  });
  const [selectedPage, setSelectedPage] = useState<string>('');
  const [selectedControl, setSelectedControl] = useState<{ name: string; controlHeaderName: string }>({
    name: '',
    controlHeaderName: '',
  });

  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const aamBackendApi = useInjection(AamBackendApi);
  const { t, i18n } = useTranslation(['stepper', 'entry', 'approval', 'recruiter']);

  const getOptions = (fieldName: string) =>
    selectedConfiguration?.controlHeaders.find((x: any) => x.name === fieldName);

  const feature = {
    name: 'ENTRY',
    step: 'CANDIDATE_DETAILS',
    value: 'CANDIDATE_DETAILS',
    label: 'ENTRY.CANDIDATE_DETAILS.label',
  };

  const pagesForStep = selectedConfiguration?.features
    .find((f) => f.name === feature.name)
    .steps.find((s: { name: string }) => s.name === feature.step).pages;

  useEffect(() => {
    const findPage = () => {
      let page: any = {};
      let control: any = {};

      pagesForStep.forEach((p: { name: string; controls: any[] }) => {
        p.controls.forEach((c: { name: string }) => {
          if (c.name === option.control.name) {
            control = c;
            page = p;
          }
        });
      });

      setSelectedPage(page?.name);
      setSelectedControl({ name: control.name, controlHeaderName: control.controlHeaderName });
    };

    findPage();

    setValues({
      operator: option.operator,
      value: option?.value?.toString() || '',
      notValue: option?.notValue?.toString() || '',
    });
  }, [option, pagesForStep]);

  const handleCondition = (control: string, e: any) => {
    let value = '';

    if (typeof e === 'string') {
      value = e;
    } else {
      value = e.target.value;
    }

    setValues((state) => ({ ...state, [control]: value }));

    const newValues: any = { ...values, [control]: value };
    const destOptions = { ...option };
    const merged = { ...destOptions, ...newValues };

    const isError = newValues.value === '' && newValues.notValue === '';

    if (isError) {
      onChange(merged);
      return;
    }

    Object.keys(merged).forEach((x) => {
      if (merged[x] === '' || merged[x] === undefined) {
        delete merged[x];
      }
      if (merged[x] === 'true') {
        merged[x] = true;
      }
      if (merged[x] === 'false') {
        merged[x] = false;
      }
    });

    onChange(merged);
  };

  const { data: controlItems } = useQuery({
    queryKey: [selectedControl.name],
    queryFn: () => aamBackendApi.getControlItems(selectedControl.name),
    enabled: !!selectedControl.name,
  });

  const renderFields = (
    type: 'value' | 'notValue',
    handleCondition: (type: 'value' | 'notValue', value: any) => void,
    disabled: boolean,
  ) => {
    const controlType = option.control.type;
    const isDisabled = type === 'value' ? values.notValue.length > 0 : values.value.length > 0;

    const isError = values.value === '' && values.notValue === '';

    if (controlType === 'boolean') {
      return (
        <BooleanCondition
          value={values[type]}
          isDisabled={isDisabled || disabled}
          isError={isError}
          handleChange={(val) => handleCondition(type, val)}
        />
      );
    }

    // ABANDONED DEVELOPMENT
    if (controlType === 'date') {
      return <DateCondition />;
    }

    if (controlType === 'selector') {
      const options =
        getOptions(selectedControl.controlHeaderName || selectedControl.name)?.items || controlItems || [];

      return (
        <SelectCondition
          handleChange={(e) => handleCondition(type, e)}
          isDisabled={isDisabled || disabled}
          value={values[type]}
          isError={isError}
          options={options}
        />
      );
    }

    if (controlType === 'string') {
      return (
        <TextCondition
          handleChange={(e) => handleCondition(type, e)}
          isDisabled={isDisabled || disabled}
          value={values[type]}
          isError={isError}
        />
      );
    }

    if (controlType === 'number') {
      return (
        <NumberCondition
          handleChange={(e) => handleCondition(type, e)}
          isDisabled={isDisabled || disabled}
          value={values[type]}
          isError={isError}
        />
      );
    }
  };

  if (!selectedFeature?.stepName) return <div>Error loading feature</div>;

  const getTranslation = (key: string) => translationHelper(key, i18n, t);

  const allControls = pagesForStep
    ?.find((p: { name: string }) => p.name === selectedPage)
    ?.controls.filter(
      (x: any) => x.component !== 'Datepicker' && x.component !== 'Autocomplete' && x.component !== 'StaticText',
    );

  return (
    <>
      {indexValue !== 0 && (
        <OperatorCondition
          value={operatorValue}
          handleChange={(e) => handleCondition('operator', e)}
          isError={false}
          isDisabled={indexValue !== 1}
        />
      )}
      <div className={styles.item}>
        <div className={styles.condition__header}>
          <span className={clsx('subtitle', styles['condition__header-title'])}>Condition {indexValue + 1}</span>
          <div
            className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
            onClick={() => {
              if (disabled) return;

              onDelete();
            }}
          >
            <span className="material-icons-outlined" style={{ fontSize: 16 }}>
              delete
            </span>
          </div>
        </div>
        <span className="caption">Condition based on</span>
        <HeaderLanguages
          className={clsx('tag-ds', styles.item__input)}
          name="select"
          onChange={() => true}
          placeholder="Select an option"
          value={feature.value}
          disabled={disabled}
        >
          <Option label={t(feature?.label as string)} value={feature.value} />
        </HeaderLanguages>
        <span className="material-icons" style={{ paddingLeft: 24, fontSize: 16, margin: '4px 0' }}>
          arrow_downward
        </span>
        <HeaderLanguages
          className={clsx('tag-ds', styles.item__input)}
          name="select"
          onChange={(val) => setSelectedPage(val)}
          placeholder="Select an option"
          value={selectedPage}
          disabled={disabled}
        >
          {pagesForStep.map((p: { name: string; label: string }, i: number) => {
            return <Option key={i} label={t(p.label)} value={p.name} />;
          })}
        </HeaderLanguages>
        <span className="material-icons" style={{ paddingLeft: 24, fontSize: 16, margin: '4px 0' }}>
          arrow_downward
        </span>
        {selectedPage && (
          <HeaderLanguages
            className={clsx('tag-ds', styles.item__input)}
            name="select"
            onChange={(val) => {
              setSelectedControl(val);
              const controlDetails = allControls?.find((c: { name: string }) => c.name === val);

              handleNewCondition(controlDetails);
            }}
            placeholder="Select an option"
            value={selectedControl.name}
            disabled={disabled}
          >
            {allControls.map((p: { name: string; label: string }, i: number) => {
              return <Option key={i} label={getTranslation(p.label)} value={p.name} />;
            })}
          </HeaderLanguages>
        )}
        {selectedControl.name && (
          <div>
            <div style={{ marginTop: 12, display: 'flex', marginBottom: 32 }}>
              <span className="medium" style={{ marginRight: 8 }}>
                Type of attribute
              </span>
              <div className="tag-ds status-tag ">{option.control.component || option.control.type}</div>
            </div>
            <div style={{ marginBottom: 16 }}>
              <span className="caption" style={{ marginBottom: 16, display: 'inline-block' }}>
                Condition value
              </span>
              {renderFields('value', handleCondition, disabled)}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConditionPropertyItem;
