import isEqual from 'lodash/isEqual';
import { OOControls, OOControlValues, OODocumentations, OOPages, OOSteps } from '@features/oneOnboarding/interfaces';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOUserFlowModel } from '@features/oneOnboarding/models/UserFlowModel';
import { deserializeFilters } from '@features/oneOnboardingAdmin/pages/AdminDashboard/adminHelpers';
import { ClientOrganizationModel } from '@models/clientOrganization.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiCallStatus } from '../apiCallStatus';

export interface AdminDashboardFilters {
  clientOrganizationId: string;
  applicationId: string;
  journeyId: string;
  locations: string[];
  regions: string[];
  jobTypes: string[];
  onboardingStatuses: string[];
  steps: Record<string, any>[];
  startDate: string | null;
  availableFrom: string | null;
  affiliation: string;
  unread: boolean;
  failedMails: boolean;
  withIntegrationStatus: string;
  blobExportStatus: Record<string, unknown> | null;
  deletedReason: string[];
  isEUCitizen: boolean;
  isStudent: boolean;
  searchQuery: string;
  branches: string[];
  sites: string[];
  campaigns: string;
  importByMe: boolean;
}

export const adminDashboardFilters: AdminDashboardFilters = {
  clientOrganizationId: '',
  applicationId: '',
  journeyId: '',
  locations: [],
  regions: [],
  jobTypes: [],
  onboardingStatuses: [],
  steps: [],
  startDate: null,
  availableFrom: null,
  affiliation: '',
  unread: false,
  failedMails: false,
  withIntegrationStatus: '',
  blobExportStatus: null,
  deletedReason: [],
  isEUCitizen: false,
  isStudent: false,
  searchQuery: '',
  branches: [],
  sites: [],
  campaigns: '',
  importByMe: false,
};

const filters = deserializeFilters(window.location.search);
export interface SelectedLanguage {
  isDefault: boolean;
  code: string;
  name: string;
}

interface AdditionalData {
  dashboardStepperComponent: string;
  startPageUrl: string;
  logoUrl: string;
  formStepper: string;
  stepper: string;
  logo: string;
  startPageLogo: string;
  startPageBackground: string;
  progressLineColor: string;
  hideDocumentStatuses: boolean;
  signInButton: Record<string, unknown>;
  smallTitle: Record<string, undefined>;
  showStatusSubLabel: boolean;
  showBackwardArrowInHeader?: boolean;
  isFontBoldForMultipleValuesRadio?: boolean;
  showNotificationOnApprove?: boolean;
  stepperHeader?: { text: string; color: string };
}

interface OneOnboardingStoreState {
  step: OOSteps;
  form: OOPages;
  currentStep: OOSteps;
  currentForm: OOPages | null;
  flow: OOStepModel[];
  logo?: string;
  salutations: null | Array<{ id: string; label: string }>;
  values: OOControlValues;
  clientOrganization: ClientOrganizationModel | null;
  clientOrganizationId: ClientOrganizationModel['id'] | null;
  importClientOrganizationId: string | null;
  fetchOneOnboardingStatus: ApiCallStatus;
  stepData: OOStepModel | undefined;
  selectedConfigurationId: string | null;
  applicationId: string | null;
  languages: SelectedLanguage[];
  mails: any[];
  themeAdditionalData: AdditionalData;
  adminDashboardFilters: AdminDashboardFilters;
  hasMultipleOnboarding: boolean;
  userId: string | null;
  rejectReasons: string[];
  appLoaded: { [target: string]: boolean };
  unsavedChangesExist?: boolean;
  stepsOverviewHidden?: boolean;
}

const initialState: any = {
  step: '',
  form: null,
  currentStep: '',
  currentForm: null,
  values: {},
  salutations: null,
  clientOrganization: null,
  flow: [],
  stepData: undefined,
  clientOrganizationId: null,
  importClientOrganizationId: null,
  selectedConfigurationId: null,
  applicationId: null,
  languages: [],
  mails: [],
  themeAdditionalData: {},
  hasMultipleOnboarding: false,
  adminDashboardFilters: Object.keys(filters).length ? filters : adminDashboardFilters,
  appLoaded: {},
};

type setValuesPayloadType<K extends OOControls = OOControls> = {
  key: K;
  value: OOControlValues[K];
};

export const oneOnboardingSlice = createSlice({
  name: 'tenant',
  initialState: initialState as OneOnboardingStoreState,
  reducers: {
    setHasMultipleOnboarding: (state, action: PayloadAction<any>) => {
      state.hasMultipleOnboarding = Array.isArray(action.payload) && action.payload.length > 1;
      return state;
    },
    setFlowData: (state, action: PayloadAction<OOUserFlowModel | OOUserFlowModel[]>) => {
      const payload = Array.isArray(action.payload) ? action.payload[action.payload.length - 1] : action.payload;

      if (!payload) {
        return state;
      }

      state.currentStep = payload.currentStep as OOSteps;
      state.currentForm = payload.currentPage as OOPages;
      state.clientOrganization = payload.selectedConfiguration?.clientOrganization;
      state.flow = payload.selectedConfiguration?.flow;
      state.logo = payload.selectedConfiguration?.logo;
      state.selectedConfigurationId = payload.configurationId;
      state.stepsOverviewHidden = payload.selectedConfiguration?.stepsOverviewHidden;

      return state;
    },
    setCurrentForm: (state, action: PayloadAction<{ step: OOSteps; form: OOPages | OODocumentations | null }>) => {
      const { step, form } = action.payload;
      state.step = step;
      state.form = form as OOPages;
      state.stepData = state.flow?.find((s) => s.name === step);
    },
    setCurrentStep: (state, action: PayloadAction<{ step: OOSteps }>) => {
      state.currentStep = action.payload.step;
      return state;
    },
    setValues: (state, action: PayloadAction<setValuesPayloadType>) => {
      (state.values as any)[action.payload.key] = action.payload.value;
      return state;
    },
    setClientOrganizationId: (state, action: PayloadAction<ClientOrganizationModel['id'] | null>) => {
      state.clientOrganizationId = action.payload;
      localStorage.setItem('clientOrganizationId', action.payload || '');
      return state;
    },
    setConfigurationId: (state, action: PayloadAction<string | null>) => {
      state.selectedConfigurationId = action.payload;
      localStorage.setItem('selectedConfigurationId', action.payload || '');
      return state;
    },
    setApplicationId: (state, action: PayloadAction<string | null>) => {
      state.applicationId = action.payload;
      localStorage.setItem('applicationId', action.payload || '');
      return state;
    },
    setLanguages: (state, action: PayloadAction<Array<SelectedLanguage>>) => {
      state.languages = action.payload;
      return state;
    },
    setMails: (state, action: PayloadAction<any[]>) => {
      state.mails = action.payload;
      return state;
    },
    setThemeAdditionalData: (state, action: PayloadAction<any>) => {
      state.themeAdditionalData = action.payload.additionalData || {};
      return state;
    },
    setAdminDashboardFilters: (state, action: PayloadAction<AdminDashboardFilters>) => {
      state.adminDashboardFilters = action.payload || null;
      if (!isEqual(adminDashboardFilters, action.payload)) {
        localStorage.setItem('adminDashboardFilters', JSON.stringify(state.adminDashboardFilters));
      }
      return state;
    },
    setClientOrganizationIdImport: (state, action: PayloadAction<ClientOrganizationModel['id'] | null>) => {
      state.importClientOrganizationId = action.payload;
      localStorage.setItem('importClientOrganizationId', action.payload || '');
      return state;
    },
    setUserId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
      localStorage.setItem('userId', action.payload || '');
      return state;
    },
    resetAdminDashboardFilters: (state) => {
      state.adminDashboardFilters = { ...adminDashboardFilters };
      localStorage.setItem('adminDashboardFilters', JSON.stringify(adminDashboardFilters));
      return state;
    },
    restoreAdminDashboardFilters: (state) => {
      state.adminDashboardFilters = JSON.parse(localStorage.getItem('adminDashboardFilters') || '{}');
      return state;
    },
    removeFilter: (state, action: PayloadAction<string>) => {
      const filters = {
        ...state.adminDashboardFilters,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [action.payload]: adminDashboardFilters[action.payload],
      };

      state.adminDashboardFilters = filters;
      localStorage.setItem('adminDashboardFilters', JSON.stringify(state.adminDashboardFilters));
      return state;
    },
    setRejectReasons: (state, action: PayloadAction<string[]>) => {
      state.rejectReasons = action.payload;
      return state;
    },
    setUnsavedChangesExist: (state, action: PayloadAction<boolean>) => {
      state.unsavedChangesExist = action.payload;
      return state;
    },
    setAppLoaded: (state, action: PayloadAction<string>) => {
      if (!state.appLoaded[action.payload]) {
        state.appLoaded[action.payload] = true;
      }

      return state;
    },
  },
});

const {
  setClientOrganizationId,
  setConfigurationId,
  setApplicationId,
  setClientOrganizationIdImport,
  setUserId,
  setUnsavedChangesExist,
  resetAdminDashboardFilters,
  setAdminDashboardFilters,
  restoreAdminDashboardFilters,
  setAppLoaded,
} = oneOnboardingSlice.actions;
export {
  setClientOrganizationId,
  setConfigurationId,
  setApplicationId,
  setClientOrganizationIdImport,
  setUserId,
  setUnsavedChangesExist,
  resetAdminDashboardFilters,
  setAdminDashboardFilters,
  restoreAdminDashboardFilters,
  setAppLoaded,
};
