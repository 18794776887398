import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { getBlobData } from '@helpers/fileUpload.helper';
import { PictureAsPdf } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import { Box, Link, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';

interface Props {
  selectedDoc: any;
  selectedDocName?: string;
  isPdfDocument: boolean;
  isFromLibrary?: boolean;
  isDocumentUploaded: boolean;
  uploadedDocumentInfo: any;
}

enum DocumentFormat {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}
interface DocumentForPreview {
  blob: string;
  type: DocumentFormat;
  format: string;
}

export const DocumentPreview: React.FC<Props> = ({
  selectedDoc,
  selectedDocName,
  isPdfDocument,
  isFromLibrary,
  isDocumentUploaded,
  uploadedDocumentInfo,
}) => {
  const [document, setDocument] = useState<DocumentForPreview | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const { t } = useTranslation(['hiring']);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const aamBackendApi = useInjection(AamBackendApi);
  const prefix = selectedDocName?.split('.')[0].toLowerCase();
  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const dispatch = useDispatch();

  useEffect(() => {
    const getDocumentFromBlob = async (isPdf: boolean) => {
      try {
        if (!selectedConfiguration?.clientOrganization?.id || !selectedDocName) return;

        setIsLoading(true);

        const res = await aamBackendApi.assetGetPredefined(
          tenantId,
          'documents-for-signature',
          `${selectedDocName}.${isPdf ? 'pdf' : 'docx'}`,
          selectedConfiguration?.clientOrganization?.id,
          selectedConfiguration?.configurationId,
        );

        const { data, headers } = res;

        const contentType = headers['content-type'];
        const { imageUrl } = getBlobData(data, contentType);

        setDocument({
          blob: imageUrl,
          type: isPdf ? DocumentFormat.PDF : DocumentFormat.DOCX,
          format: isPdf ? 'pdf' : 'docx',
        });

        setIsLoading(false);
      } catch (err) {
        dispatch(
          snackbarSlice.actions.showError({
            message: `Failed to get document ${selectedDocName} from blob storage!`,
          }),
        );

        setIsLoading(false);
      }
    };

    if (!selectedDocName) return;

    if (isFromLibrary && isDocumentUploaded) {
      getDocumentFromBlob(false);
      setIsDone(true);

      return;
    }

    // Adding and view from library
    if (isDocumentUploaded && uploadedDocumentInfo) {
      getDocumentFromBlob(false);
      setIsDone(true);

      return;
    }
    // View old and existing one
    if (!isFromLibrary && isDocumentUploaded && !isDone) {
      getDocumentFromBlob(isPdfDocument);
      return;
    }
  }, [
    aamBackendApi,
    dispatch,
    isDocumentUploaded,
    isPdfDocument,
    uploadedDocumentInfo,
    isFromLibrary,
    selectedConfiguration,
    selectedDocName,
    tenantId,
    selectedDoc,
    isDone,
  ]);

  return (
    <Box style={{ height: '100%', position: 'relative' }}>
      {isLoading && <Loader style={{ position: 'absolute', left: '50%', top: '50%' }} size={10} />}
      {document && (
        <Link href={document.blob} target="_blank">
          <Box display="flex">
            {document.type === DocumentFormat.PDF ? <PictureAsPdf color="primary" /> : <ArticleIcon color="primary" />}
            <Typography variant="body1" style={{ marginLeft: 16 }}>
              {t(`${prefix}:${selectedDocName}`)}.{document.format}
            </Typography>
          </Box>
        </Link>
      )}
    </Box>
  );
};
