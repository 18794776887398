import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { useInjection } from '@context/inversify-context-provider';
import { ViewOnlyContext } from '@context/view-only-provider';
import { findDifference } from '@features/selfService/helpers/commonFunctions';
import { getDisyDocumentName, storeDisyDocumentName } from '@features/selfService/helpers/controlHelper';
import { PathHelper } from '@features/selfService/helpers/pathHelper';
import {
  getInitialTranslationValues,
  handleTranslationSubmit,
} from '@features/selfService/helpers/renderControlHelper';
import { RootState } from '@store/rootReducer';
import { addNewDocumentForSignature, updateConfigurationEntity } from '@store/slices/selfService.slice';
import { snackbarSlice } from '@store/slices/snackbar';

import VersionPicker from '../other/VersionPicker/VersionPicker';
import Controls from '../renderControls/Controls';
import {
  DocumentForSignatureFromLibraryTemplate,
  DocumentForSignatureTemplate,
  EditDocumentForSignatureTemplate,
  ExternalDocumentForSignatureTemplate,
  Template,
} from './templates';

import styles from '../../ConfigurationSetup.module.scss';
import docForSignStyles from './DocumentToSign.module.scss';

interface Props {
  document: any;
  handleClose: (callback?: () => void) => void;
  externalDocument: boolean;
  libraryItem: null | { name: string; id: string };
  handleDifferentVersion: (control: any) => void;
}

const DocumentToSignDetails: React.FC<Props> = ({
  document,
  handleClose,
  externalDocument,
  libraryItem,
  handleDifferentVersion,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [template, setTemplate] = useState<Template | null>(null);

  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const formikRef = useRef<any>();
  const aamBackendApi = useInjection(AamBackendApi);
  const { t } = useTranslation(['hiring']);
  const dispatch = useDispatch();
  const { isViewOnly } = useContext(ViewOnlyContext);

  const tenantId = localStorage.getItem('tenantId');

  const availableLanguages = selectedConfiguration?.languages;

  useEffect(() => {
    const getTemplate = () => {
      if (libraryItem) {
        return DocumentForSignatureFromLibraryTemplate;
      }

      if (externalDocument) {
        return ExternalDocumentForSignatureTemplate;
      }

      if (isEdit) {
        return EditDocumentForSignatureTemplate;
      }

      return DocumentForSignatureTemplate;
    };

    setTemplate(getTemplate());
  }, [externalDocument, libraryItem, isEdit]);

  useEffect(() => {
    if (!selectedConfiguration || !availableLanguages || !template) return;

    setIsEdit(!!document.name);

    const withTranslations = (initialValues: any) =>
      getInitialTranslationValues(initialValues, document, selectedConfiguration, t);

    const disyName = getDisyDocumentName(
      document.name,
      selectedConfiguration?.additionalConfigurations?.selfServiceMetaData?.disyDocuments,
    );

    const withInitialValues = (initialValues: any) => {
      return { ...template.initialValues, ...initialValues, disyDocumentName: disyName };
    };

    setInitialValues(withTranslations(withInitialValues(template.converterForUi(document))));
  }, [document, selectedConfiguration, availableLanguages, t, template]);

  if (!document) {
    return <div>The document does not exist or has been deleted</div>;
  }

  const onSubmit = (values: any) => {
    let mergeVisibleAndHidden = { ...template?.hidden, ...values };

    mergeVisibleAndHidden = template?.convertForConfiguration(mergeVisibleAndHidden);

    storeDisyDocumentName(values.name, values.disyDocumentName, dispatch);
    handleTranslationSubmit(initialValues, values, dispatch, document);

    template?.hideForConfiguration.forEach((x: string) => {
      delete mergeVisibleAndHidden[x];
    });

    const pathHelper = new PathHelper(selectedConfiguration)
      .signingFeature()
      .signingStep()
      .documentsForSignature(document.name);

    if (pathHelper) {
      dispatch(
        updateConfigurationEntity({
          value: mergeVisibleAndHidden,
          pathSegments: pathHelper.getPath(),
          modification: {
            type: 'change',
            typeOfChange: findDifference(values, initialValues),
            property: mergeVisibleAndHidden,
          },
        }),
      );
    } else {
      dispatch(addNewDocumentForSignature(mergeVisibleAndHidden));
    }
  };

  const onDocumentUpload = (e: any, cb: () => void, name: string) => {
    // handleAssetUpload(e, dir, clientOrganizationId, configurationId);

    const handleAssetUpload = async (
      e: any,
      clientOrganizationId?: string,
      configurationId?: string,
    ): Promise<void> => {
      const { files } = e.target;
      if (!files?.length || !tenantId) {
        return;
      }

      const path = `oo/${clientOrganizationId}/${configurationId}/documents-for-signature/`;

      try {
        setIsLoading(true);
        await aamBackendApi.assetUploadWithName(parseInt(tenantId), files, path, name);

        setIsLoading(false);

        cb();
        dispatch(
          snackbarSlice.actions.showSuccess({
            message: 'Document is successfully updloaded!',
          }),
        );
      } catch (err) {
        setIsLoading(false);
        dispatch(
          snackbarSlice.actions.showError({
            message: 'Document failed to upload!',
          }),
        );
      }
    };

    handleAssetUpload(e, selectedConfiguration?.clientOrganization?.id, selectedConfiguration?.configurationId);
  };

  return (
    <div className="tag-ds">
      <div className={styles.controlDetails__heading}>
        <div className={styles.controlDetails__name}>
          <div
            className="material-icons"
            onClick={() => {
              if (formikRef.current.dirty) {
                const modifiedForm = formikRef.current.values;
                handleClose(() => onSubmit(modifiedForm));
              } else {
                handleClose();
              }
            }}
            style={{ cursor: 'pointer', marginRight: 20 }}
          >
            arrow_back
          </div>
          <span className="large">Document for signature</span>
        </div>
      </div>

      {libraryItem && <VersionPicker libraryItem={libraryItem} selectVersionHandler={handleDifferentVersion} />}

      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        validateOnMount
        validateOnChange
        onSubmit={(modifiedStep: any) => {
          onSubmit(modifiedStep);
          handleClose();
        }}
        initialValues={initialValues}
        validationSchema={template?.validation}
      >
        {(props: FormikProps<any>) => {
          return (
            <form onSubmit={props.handleSubmit}>
              {template?.visible.map((x: string) => {
                return (
                  <Field name={x} key={x}>
                    {(fieldProps: FieldProps) => (
                      <Controls
                        fieldProps={fieldProps}
                        fieldConfiguration={template.fieldConfiguration?.[x]}
                        isViewOnly={isViewOnly}
                        isFromLibrary={!!libraryItem}
                      />
                    )}
                  </Field>
                );
              })}
              {isLoading && <div>loading</div>}
              {(!isEdit || libraryItem) && !externalDocument && (
                <div
                  className={clsx(
                    docForSignStyles.uploadButton,
                    props.values?.name?.length === 0 && docForSignStyles.uploadButtonDisabled,
                  )}
                >
                  <label className={docForSignStyles.uploadButtonLabel}>UPLOAD DOCUMENT</label>
                  <input
                    className={docForSignStyles.uploadButtonInput}
                    type="file"
                    accept=".docx"
                    onChange={(e) =>
                      onDocumentUpload(
                        e,
                        () => {
                          props.setFieldValue('uploadedDocument', true);
                          props.setFieldValue('documentExtensionType', 'docx');

                          if (e.target.files) {
                            props.setFieldValue('uploadedDocumentInfo', e.target.files[0]);
                          }
                        },
                        props.values.name,
                      )
                    }
                    style={{ marginTop: 0 }}
                    disabled={props.values?.name?.length === 0}
                  />
                </div>
              )}
              {!isViewOnly && <button type="submit">Save</button>}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DocumentToSignDetails;
