import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { DatePicker } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { CampaignModel } from '@features/oneOnboarding/models/CampaignModel';
import { BranchModel } from '@models/branch.model';
import { ClientOrganizationModel } from '@models/clientOrganization.model';
import { Box, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../../../libs/aamBackendApi';
import { AdminReactiveSelect } from '../../../components/AdminReactiveSelect/AdminReactiveSelect';

export interface NewCampaignProps {
  back: () => void;
  clientOrganizations: ClientOrganizationModel[];
  branchs: BranchModel[];
}

const NewCampaign: React.FC<NewCampaignProps> = ({ back, clientOrganizations, branchs }) => {
  const applicationIdMaxLength = 256;
  const { t } = useTranslation(['recruiter']);

  const { tenantId } = useSelector((state: RootState) => state.tenant);

  const history = useHistory();

  const aamBackendApi = useInjection(AamBackendApi);

  const [disabledEndDate, setDisabledEndDate] = useState(false);

  const [journeysData, setJourneysData] = useState<any[]>([]);

  const [branchsData, setBranchsData] = useState<BranchModel[]>([]);

  const campaignId = uuid();

  const [campaignData, setCampaignData] = useState<CampaignModel>({
    tenantId: tenantId,
    url: campaignId,
    name: '',
    enabled: true,
    clientOrganizationId: '',
    selectedConfigurationId: '',
    applicationId: `campaign_${campaignId}`.substring(0, applicationIdMaxLength),
    startDate: new Date(),
    branchIds: [],
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCampaignData((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const makeCampaignIndefinitely = () => {
    if (!disabledEndDate) {
      setDisabledEndDate(true);

      const startDateCopy = new Date(campaignData.startDate);

      const endDate = new Date(startDateCopy.setFullYear(startDateCopy.getFullYear() + 10));

      setCampaignData({ ...campaignData, endDate });
    } else {
      setDisabledEndDate(false);
    }
  };

  const handleClientOrganizationChange = (value: string) => {
    setCampaignData({ ...campaignData, clientOrganizationId: value, selectedConfigurationId: '' });
  };

  const handleJourneyChange = (value: string) => {
    setCampaignData({
      ...campaignData,
      selectedConfigurationId: value,
    });
  };

  const handleBranchChange = (value: string) => {
    setCampaignData({
      ...campaignData,
      branchIds: [value],
    });
  };

  const saveCampaign = async () => {
    const response = await aamBackendApi.createCampaign(campaignData);
    history.push(`/oo/recruiter/import-users/campaigns/${response.url}`);
  };

  const validateCampaign = () =>
    campaignData.applicationId === '' ||
    campaignData.selectedConfigurationId === '' ||
    campaignData.branchIds?.length === 0 ||
    campaignData.name === '' ||
    campaignData.url === '' ||
    campaignData.clientOrganizationId === '';

  useEffect(() => {
    const journeys: any[] = [];
    clientOrganizations
      .filter((x) => x.id === campaignData.clientOrganizationId)
      .forEach((element) => {
        if (element.selectedConfigurations && element.selectedConfigurations.length > 0) {
          element.selectedConfigurations.forEach((item) => {
            journeys.push({
              id: item.id,
              name: item.candidateName,
              value: item.id,
            });
          });
        }
      });
    setJourneysData(journeys);
  }, [campaignData.clientOrganizationId, clientOrganizations]);

  useEffect(() => {
    let uniqueBranchs: any[] = [];
    if (branchs) {
      uniqueBranchs = [...new Map(branchs?.map((item) => [item['sid'], item])).values()];
    }
    setBranchsData(uniqueBranchs);
  }, [branchs]);

  return (
    <div className="tag-ds App newCampaign">
      <Grid container className="newCampaign">
        <Grid item xs={8}>
          <Grid container className="tag-ds">
            <Grid item xs={6}>
              <Box style={{ borderTop: '24px' }} display="flex" alignItems="center">
                <span
                  className="material-icons"
                  onClick={() => back()}
                  style={{ cursor: 'pointer', marginRight: '16px' }}
                >
                  arrow_back
                </span>
                <p className="tag-ds  subtitle">{t('GENERAL.IMPORT.createNewCampaign')}</p>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.campaignUrl').toUpperCase()}</label>
                  <input
                    className=""
                    placeholder={t('GENERAL.IMPORT.enterCampaignURL')}
                    type="text"
                    name="url"
                    value={campaignData.url}
                    disabled={true}
                  />
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.campaignName').toUpperCase()}</label>
                  <input
                    className=""
                    placeholder={t('GENERAL.IMPORT.enterCampaignName')}
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                  />
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.selectClient').toUpperCase()}</label>
                  <div>
                    <AdminReactiveSelect
                      value={campaignData.clientOrganizationId}
                      label=""
                      name="clientOrganizationId"
                      placeholder={t('GENERAL.IMPORT.selectAnOption')}
                      options={clientOrganizations
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((x) => ({
                          name: x.name,
                          value: x.id,
                        }))}
                      onChange={(e) => handleClientOrganizationChange(e.target.value as string)}
                    />
                  </div>
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.selectJourney').toUpperCase()}</label>
                  <div>
                    <AdminReactiveSelect
                      value={campaignData.selectedConfigurationId}
                      label=""
                      name="selectedConfigurationId"
                      placeholder={t('GENERAL.IMPORT.selectAnOption')}
                      disabled={campaignData.clientOrganizationId === '' || journeysData.length === 0}
                      options={journeysData}
                      onChange={(e) => handleJourneyChange(e.target.value as string)}
                    />
                  </div>
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.application').toUpperCase()}</label>
                  <input
                    className=""
                    placeholder={t('GENERAL.IMPORT.enterApplication')}
                    type="text"
                    name="applicationId"
                    value={campaignData.applicationId}
                    disabled={true}
                  />
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.campaignBranch').toUpperCase()}</label>
                  <div>
                    <AdminReactiveSelect
                      value={campaignData.branchIds ? campaignData.branchIds[0] : ''}
                      label=""
                      name="selectedBranchId"
                      placeholder={t('GENERAL.IMPORT.selectAnOption')}
                      options={branchsData.map((x) => ({
                        name: `${x.sid} - ${x.name}`,
                        value: x.id,
                      }))}
                      onChange={(e) => handleBranchChange(e.target.value as string)}
                    />
                  </div>
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.startDate').toUpperCase()}</label>
                  <DatePicker
                    name="startDate"
                    dateFormat="MM/dd/yyyy"
                    onChange={(date) => {
                      setCampaignData({ ...campaignData, startDate: new Date(date as number) });
                    }}
                  />
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds checkbox-container">
                  <input id="activeIndefinitely" type="checkbox" onChange={makeCampaignIndefinitely} />
                  <label htmlFor="activeIndefinitely">{t('GENERAL.IMPORT.makeThisCampaignActive')}</label>
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <div className="tag-ds input-wrapper   ">
                  <label className="caption">{t('GENERAL.IMPORT.endDate').toUpperCase()}</label>
                  <DatePicker
                    name="endDate"
                    dateFormat="MM/dd/yyyy"
                    disabled={disabledEndDate}
                    selected={campaignData.endDate ? moment(campaignData.endDate).format('MM/dd/yyyy') : undefined}
                    onChange={(date) => {
                      setCampaignData({ ...campaignData, endDate: new Date(date as number) });
                    }}
                  />
                </div>
              </Box>
              <Box style={{ marginTop: '24px' }}>
                <button className="button-primary" disabled={validateCampaign()} onClick={() => saveCampaign()}>
                  {disabledEndDate
                    ? t('GENERAL.IMPORT.activateCampaign').toUpperCase()
                    : t('GENERAL.IMPORT.startCampaign').toUpperCase()}
                </button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ backgroundColor: '#F8F8F9' }}>
          <Box style={{ marginLeft: '30px', marginTop: '30px' }}>
            <Box>
              <h4 className="tag-ds ">{t('GENERAL.IMPORT.tips')}</h4>
            </Box>
            <Box>
              <p className="tag-ds  subtitle">{t('GENERAL.IMPORT.duration')}</p>
            </Box>
            <Box display="flex" alignItems="center">
              <span className="material-icons" style={{ cursor: 'pointer', marginRight: '16px' }}>
                arrow_circle_right
              </span>
              <p
                className="tag-ds"
                style={{ padding: '10px', backgroundColor: '#FFFFFF', marginRight: '16px', borderRadius: '8px' }}
              >
                {t('GENERAL.IMPORT.makeThisCampaignActiveIndefinitely')}
              </p>
            </Box>
            <Box style={{ marginTop: '16px', marginRight: '16px' }}>
              <p className="tag-ds">{t('GENERAL.IMPORT.bySelectingTheMakeThis')}</p>
            </Box>
            <Box style={{ marginTop: '16px', marginRight: '16px' }}>
              <p className="tag-ds">{t('GENERAL.IMPORT.thisMeansCampaigns')}</p>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewCampaign;
