import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { Option, Select } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { SelfServiceLibraryItemResponse } from '@models/selfService.model';
import { snackbarSlice } from '@store/slices/snackbar';

import styles from '../../../ConfigurationSetup.module.scss';

interface Props {
  libraryItem: { name: string; id: string };
  selectVersionHandler: (control: any) => void;
}

const VersionPicker: React.FC<Props> = ({ libraryItem, selectVersionHandler }) => {
  const [selectedVersion, setSelectedVersion] = useState('');
  const [allVersions, setAllVersions] = useState<SelfServiceLibraryItemResponse | null>(null);

  const aamBackendApi = useInjection(AamBackendApi);
  const dispatch = useDispatch();

  const seen = new Set();
  const counts: any = {};

  useEffect(() => {
    const getVersions = async () => {
      try {
        const res = await aamBackendApi.getControlByName(libraryItem?.id, libraryItem.name);

        const defaultSelected = res.selfServiceLibraryItems[0];
        setAllVersions(res);
        setSelectedVersion(defaultSelected.hash);
      } catch (err) {
        dispatch(
          snackbarSlice.actions.showError({
            message: 'Failed to load item version!',
          }),
        );
      }
    };

    if (libraryItem) {
      getVersions();
    }
  }, [libraryItem, aamBackendApi, dispatch]);

  const handleDifferentVersion = (hash: string) => {
    const findControl = allVersions?.selfServiceLibraryItems.find((item) => {
      return item.hash === hash;
    });

    if (findControl) {
      selectVersionHandler(findControl?.data);
    }
  };

  const generateLabel = (version: any) => {
    const baseLabel = `${version.name} - ${version.clientName}`;
    let label = baseLabel;

    if (counts[baseLabel] === undefined) {
      counts[baseLabel] = 0;
    }

    counts[baseLabel] += 1;

    if (counts[baseLabel] > 1) {
      label = `${baseLabel} (${counts[baseLabel] - 1})`;
    }

    seen.add(baseLabel);

    return label;
  };

  return (
    <div className={styles.versionSelect}>
      <Select
        name="select"
        label="Select version"
        onChange={(clientName) => {
          handleDifferentVersion(clientName);
          setSelectedVersion(clientName);
        }}
        placeholder="Select an option"
        value={selectedVersion}
        disabled={
          allVersions?.selfServiceLibraryItems.length === 0 || allVersions?.selfServiceLibraryItems.length === 1
        }
      >
        {allVersions?.selfServiceLibraryItems.map((version, index: number) => {
          const label = generateLabel(version);

          return <Option key={index} label={label} value={version.hash} />;
        })}
      </Select>
    </div>
  );
};

export default VersionPicker;
