import React, { FC, Fragment } from 'react';
import { OOPages, OOSteps } from '@features/oneOnboarding/interfaces';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { CustomStep } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/CustomStep';
import { RecruiterSidebarItem } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { StepItem } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/StepItem';
import { Onboarding } from '@models/onboarding.model';

interface SidebarItemsProps {
  items: RecruiterSidebarItem[];
  steps: OOStepModel[];
  flowWrapper: OOFlowWrapper;
  onboarding?: Onboarding;
  currentStep: OOSteps;
  currentPage: OOPages;
}
export const SidebarItems: FC<SidebarItemsProps> = (props) => {
  const { items, steps, flowWrapper, onboarding, currentStep, currentPage } = props;
  return (
    <>
      {items.map((item, index) => {
        const commonProps = { index, steps, flowWrapper, onboarding, currentStep, currentPage };
        switch (item.type) {
          case 'step':
            return <StepItem key={index} {...commonProps} item={item} />;
          case 'custom':
            return <CustomStep key={index} {...commonProps} item={item} />;
          default:
            return <Fragment key={index} />;
        }
      })}
    </>
  );
};
