import React from 'react';
import { OOConfiguration } from '@features/oneOnboarding/interfaces';
import { Collapse } from '@mui/material';

import { CheckedJourneysAndClients } from '../UserPermissionDetailsPage';
import { ListType } from './NestedListSelector';

import styles from '../UserPermissions.module.scss';

export type ClientsAndJourneys = {
  clientOrganizationId: string;
  clientOrganizationDetails: { [key: string]: any };
  configs: OOConfiguration[];
};

interface ClientsAndJourneysListProps {
  options: ClientsAndJourneys[];
  checkedJourneys: CheckedJourneysAndClients[];
  handleChange: (clientId: string, journeyId: string | null) => void;
  isDisabled: boolean;
  handleCollapse: (
    type: ListType,
    itemId: string,
  ) => {
    set: any;
    get: any;
  };
}

const ClientsAndJourneysList: React.FC<ClientsAndJourneysListProps> = ({
  options,
  checkedJourneys,
  handleChange,
  isDisabled,
  handleCollapse,
}) => {
  return (
    <div className={styles.nestedList}>
      {options.map((x) => {
        const isClientChecked = checkedJourneys.find((z) => z.clientId === x.clientOrganizationId);
        const getAllJourneysForClient = options.find((y) => y.clientOrganizationId === x.clientOrganizationId)?.configs;
        const collapseConfig = handleCollapse(ListType.CLIENTS_AND_JOURNEYS, x.clientOrganizationId);
        const isCollapsed = collapseConfig.get.includes(x.clientOrganizationId);

        const isIntermediate =
          getAllJourneysForClient?.length !==
          checkedJourneys.filter((z) => z.clientId === x.clientOrganizationId)?.length;

        return (
          <div key={x.clientOrganizationId} className={styles.nestedListParent}>
            <div className="tag-ds checkbox-container" style={{ margin: 0 }}>
              <span
                className="material-icons"
                onClick={collapseConfig.set}
                style={{ transform: isCollapsed ? 'rotate(180deg)' : '' }}
              >
                arrow_drop_down
              </span>
              <input
                id={x.clientOrganizationId}
                type="checkbox"
                checked={!!isClientChecked}
                onChange={() => handleChange(x.clientOrganizationId, null)}
                className={isIntermediate ? 'intermediate' : ''}
                disabled={isDisabled}
              />
              <label htmlFor={x.clientOrganizationId} className={styles.checkboxLabel}>
                {x.clientOrganizationDetails.name}
              </label>
            </div>
            <Collapse in={!isCollapsed}>
              {x.configs.map((z: any) => {
                const isJourneyChecked = checkedJourneys.find(
                  (y) => y.journeyId === z.id && y.clientId === x.clientOrganizationId,
                );

                return (
                  <div
                    className="tag-ds checkbox-container"
                    key={z.id + x.clientOrganizationId}
                    id={styles.childContainer}
                  >
                    <input
                      id={z.id + x.clientOrganizationId}
                      type="checkbox"
                      checked={!!isJourneyChecked}
                      onChange={() => {
                        handleChange(x.clientOrganizationId, z.id);
                      }}
                      disabled={isDisabled}
                    />
                    <label htmlFor={z.id + x.clientOrganizationId} className={styles.checkboxLabel}>
                      {z.candidateName}
                    </label>
                  </div>
                );
              })}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default ClientsAndJourneysList;
