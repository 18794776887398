import React, { HTMLAttributes } from 'react';

export const ClosePageIcon = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.2929 18.8181L1.58579 18.111L0.878687 18.8181L1.58579 19.5252L2.2929 18.8181ZM9.34837 11.7626L10.0555 12.4698L10.7626 11.7626L10.0555 11.0555L9.34837 11.7626ZM3.70711 20.2323L3.00001 20.9394L3.70711 21.6465L4.41422 20.9394L3.70711 20.2323ZM10.7626 13.1769L11.4697 12.4698L10.7626 11.7627L10.0555 12.4698L10.7626 13.1769ZM18.1809 20.5952L17.4738 21.3023L18.1809 22.0094L18.888 21.3023L18.1809 20.5952ZM19.5951 19.181L20.3022 19.8881L21.0093 19.181L20.3022 18.4739L19.5951 19.181ZM12.1768 11.7626L11.4697 11.0555L10.7626 11.7626L11.4697 12.4698L12.1768 11.7626ZM20.2323 3.70718L20.9394 4.41429L21.6465 3.70718L20.9394 3.00008L20.2323 3.70718ZM18.818 2.29297L19.5252 1.58586L18.818 0.878756L18.1109 1.58586L18.818 2.29297ZM10.7626 10.3484L10.0555 11.0555L10.7626 11.7626L11.4697 11.0555L10.7626 10.3484ZM3.06998 2.65583L3.77708 1.94872L3.06998 1.24162L2.36287 1.94872L3.06998 2.65583ZM1.65576 4.07004L0.948655 3.36294L0.241548 4.07004L0.948655 4.77715L1.65576 4.07004ZM3.00001 19.5252L10.0555 12.4698L8.64126 11.0555L1.58579 18.111L3.00001 19.5252ZM4.41422 19.5252L3.00001 18.111L1.58579 19.5252L3.00001 20.9394L4.41422 19.5252ZM10.0555 12.4698L3.00001 19.5252L4.41422 20.9394L11.4697 13.884L10.0555 12.4698ZM18.888 19.8881L11.4697 12.4698L10.0555 13.884L17.4738 21.3023L18.888 19.8881ZM18.888 18.4739L17.4738 19.8881L18.888 21.3023L20.3022 19.8881L18.888 18.4739ZM11.4697 12.4698L18.888 19.8881L20.3022 18.4739L12.8839 11.0555L11.4697 12.4698ZM19.5252 3.00008L11.4697 11.0555L12.8839 12.4698L20.9394 4.41429L19.5252 3.00008ZM18.1109 3.00008L19.5252 4.41429L20.9394 3.00008L19.5252 1.58586L18.1109 3.00008ZM11.4697 11.0555L19.5252 3.00008L18.1109 1.58586L10.0555 9.64133L11.4697 11.0555ZM2.36287 3.36294L10.0555 11.0555L11.4697 9.64133L3.77708 1.94872L2.36287 3.36294ZM2.36287 4.77715L3.77708 3.36294L2.36287 1.94872L0.948655 3.36294L2.36287 4.77715ZM10.0555 11.0555L2.36287 3.36294L0.948655 4.77715L8.64126 12.4698L10.0555 11.0555Z"
          fill="#DA291C"
        />
      </svg>
    </div>
  );
};
