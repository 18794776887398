import React from 'react';

import styles from './StepSeparator.module.css';

export const StepSeparator = () => (
  <div className={styles.stepSeparator}>
    <div className="separator" />
    <hr />
  </div>
);
