import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useRecruiterVerifiesDocuments = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const active = currentStep === 'DOCUMENT_VALIDATION' || currentStep === 'DOCUMENT_UPLOAD';
  const completed = active ? false : flowWrapper.isStepCompleted('DOCUMENT_VALIDATION');
  const disabled = !(active || completed);

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      const step = steps.find((x) => x.name === 'DOCUMENT_UPLOAD');
      setPageStepContext({ step, page: undefined });
    },
  };
};
