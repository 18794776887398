import React from 'react';
import { Box, Dialog, DialogContent } from '@mui/material';

interface IntegrationErrorModalProps {
  integrationState: any | null;
  isActive: boolean;
  closeModal: () => void;
}

const IntegrationErrorsModal: React.FC<IntegrationErrorModalProps> = ({ integrationState, isActive, closeModal }) => {
  const findAllByErrorType = (object: Record<string, any>, errorType: string) => {
    switch (errorType) {
      case 'generalException':
        return findAllByKey(object, 'exMessage')?.length > 0
          ? findAllByKey(object, 'exMessage')
          : findAllByKey(object, 'message');
      case 'validationError':
        return findAllByKey(object, 'title');
      default:
        return findAllByKey(object, 'message');
    }
  };
  const findAllByKey: any = (object: Record<string, any>, keyToFind: string) => {
    return Object.entries(object).reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(value)
          : typeof value === 'object'
          ? acc.concat(findAllByKey(value, keyToFind))
          : acc,
      [],
    );
  };
  return (
    <Dialog open={isActive} onClose={closeModal}>
      <Box>
        <DialogContent>
          {Object.keys(integrationState || {})
            .filter((key) => key.includes('Error'))
            .map((key, index) => {
              let integrationErrorsStateProp = integrationState?.[key];
              let integrationErrorsMessages: string[] = [];
              try {
                integrationErrorsStateProp = JSON.parse(integrationErrorsStateProp);
                const integrationErrorType = integrationErrorsStateProp?.type;
                integrationErrorsMessages = findAllByErrorType(integrationErrorsStateProp, integrationErrorType);
              } catch (err) {
                // nothing
              }
              return (
                <Box display="flex" flexWrap="wrap" key={index} alignItems="center">
                  <p className="tag-ds  medium" style={{ margin: 0 }}>
                    "{key}":
                  </p>
                  <div>
                    {integrationErrorsMessages.map((error, index) => (
                      <div className="tag-ds  medium" style={{ margin: 0 }} key={index}>
                        {error}
                      </div>
                    ))}
                  </div>
                </Box>
              );
            })}
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default IntegrationErrorsModal;
