import { createTheme, responsiveFontSizes } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import createTypography from '@mui/material/styles/createTypography';
import { deepmerge } from '@mui/utils';

import { defaultTheme } from './defaultTheme';

const { palette, typography, components } = defaultTheme;

export const createOnboardingTheme = (data: Partial<ThemeOptions>) => {
  const themePalette = createPalette({ ...palette, ...(data.palette || {}) });
  const themeTypography = createTypography(themePalette, { ...typography, ...(data.typography || {}) });
  const themeComponents = { ...components, ...(data.components || {}) };
  const options = {
    spacing: [0, 8, 16, 18, 20, 26, 36, 42, 48, 66, 86, 112],
    palette: themePalette,
    typography: themeTypography,
    components: themeComponents,
  };
  const ootheme = createTheme(deepmerge(defaultTheme, options));
  return responsiveFontSizes(ootheme);
};
