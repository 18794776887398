import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import { convertOffsetToValue } from '@features/selfService/helpers/controlHelper';

export const translationValidation = yup
  .object()
  .test('is-not-empty', 'Translation values are required', (obj: any) => {
    if (!obj) return false;

    const keys = Object.keys(obj);

    if (keys.length === 0) return false;

    for (const key of keys) {
      if (!obj[key]) {
        return false; // Return false if any key does not have a value
      }
    }

    return true;
  })
  .required();

const nameValidation = yup
  .string()
  .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
  .required('Name is required!');

export const conditionValidation = (fieldName: string) =>
  yup.mixed().test('isRequired', 'Field is required', (value: any): any => {
    if (value === null) {
      return true; // Null is allowed
    }

    const arraySchema = yup.array().of(
      yup
        .object()
        .shape({
          value: yup.string(),
          notValue: yup.string(),
          operator: yup.string(),
        })
        .test('value-notValue', 'Either "value" or "notValue" is required', function (obj: any) {
          const res = obj.value !== undefined || obj.notValue !== undefined;

          if (!res) {
            throw this.createError({
              path: fieldName,
              message: `Either "value" or "notValue" is required`,
            });
          }

          return res;
        }),
    );

    return arraySchema.validateSync(value);
  });

interface Template {
  // Properties that are rendered in UI, defined by business which fields should be editable
  visible: string[];
  // Used for fields like description of controls or such
  static?: { type: string; value: string }[];
  initialValues: { [key: string]: any };
  // Properties that are not editable by business
  hidden: { [key: string]: any };
  validation: yup.ObjectSchema | null;
  // Properties that should not exists in configuration, but they exists in UI for some other purpose
  hideForConfiguration?: string[];
  // Used for converting configuration object to UI object
  converterForUi?: (configurationObject: { [key: string]: any }) => { [key: string]: any };
  convertForConfiguration?: (configurationObject: { [key: string]: any }) => { [key: string]: any };
  isReadOnly?: boolean;
}

export const blankTemplate = {
  name: '',
  component: '',
};

export const DatepickerTemplate: Template = {
  visible: ['name', 'label', 'labelTranslations', 'isMandatory', 'typeOfDate', 'renderCondition', 'mandatoryCondition'],
  initialValues: {
    component: 'Datepicker',
    name: '',
    label: '',
    isMandatory: false,
    typeOfDate: 'anyDate',
    maxOffset: null,
    minOffset: null,
    renderCondition: null,
    mandatoryCondition: null,
    labelTranslations: {},
  },
  hidden: {
    isVisibleCandidate: true,
    isVisibleRecruiter: true,
    isEditableCandidate: true,
    isEditableRecruiter: true,
    sequence: 0,
    version: 1,
    type: 'date',
    category: 'standard',
    sourceClass: 'UserProfile',
  },
  hideForConfiguration: ['typeOfDate', 'labelTranslations'],
  convertForConfiguration: (configuration) => {
    return {
      ...configuration,
      sourceField: 'customData.' + configuration.name,
    };
  },
  converterForUi(configurationObject) {
    return {
      ...configurationObject,
      typeOfDate: convertOffsetToValue(configurationObject?.minOffset, configurationObject?.maxOffset),
    };
  },
  validation: yup.object().shape({
    component: yup.string().required('Component is required!'),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
    labelTranslations: translationValidation,
  }),
};

export const HeadingTemplate: Template = {
  visible: ['name', 'staticText', 'staticTextTranslations', 'renderCondition', 'mandatoryCondition'],
  initialValues: {
    component: 'Heading',
    name: '',
    staticText: '',
    renderCondition: null,
    mandatoryCondition: null,
    staticTextTranslations: {},
  },
  hidden: {
    category: 'static',
    isMandatory: false,
    isEditableCandidate: false,
    isVisibleRecruiter: true,
    isEditableRecruiter: false,
    isVisibleCandidate: true,
    type: 'string',
    sequence: 0,
    version: 1,
  },
  validation: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    staticText: yup.string().required('Label is required!'),
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
    staticTextTranslations: translationValidation,
  }),
};

export const HtmlTemplate: Template = {
  visible: ['name', 'richTextEditor', 'renderCondition', 'mandatoryCondition'],
  initialValues: {
    component: 'Html',
    componentName: 'Body Text',
    richTextEditor: '',
    name: '',
    staticText: '',
    renderCondition: null,
    mandatoryCondition: null,
    staticTextTranslations: {},
  },
  hideForConfiguration: ['richTextEditor', 'staticTextTranslations'],
  hidden: {
    category: 'static',
    isMandatory: false,
    isEditableCandidate: false,
    isVisibleRecruiter: false,
    isEditableRecruiter: false,
    isVisibleCandidate: true,
    type: 'string',
    sequence: 0,
    version: 1,
  },
  converterForUi: (configurationObject: { [key: string]: any }) => {
    return {
      ...configurationObject,
      richTextEditor: configurationObject.staticText,
    };
  },
  validation: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    staticText: yup.string().required('Content is required!'),
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
    staticTextTranslations: translationValidation,
  }),
};

export const TextfieldTemplate: Template = {
  visible: [
    'textFieldSelector',
    'name',
    'label',
    'labelTranslations',
    'isMandatory',
    'minMax',
    'minMaxValidationError',
    'minMaxValidationErrorTranslations',
    'regexValidation',
    'renderCondition',
    'mandatoryCondition',
  ],
  initialValues: {
    component: 'Textfield',
    name: '',
    label: '',
    isMandatory: false,
    min: 1,
    max: 80,
    minMaxValidationError: '',
    regexValidation: '',
    regExpValidationError: '',
    renderCondition: null,
    mandatoryCondition: null,
    labelTranslations: {},
    minMaxValidationErrorTranslations: {},
    textFieldSelector: 'Textfield',
  },
  hidden: {
    category: 'standard',
    isVisibleCandidate: true,
    isEditableCandidate: true,
    isVisibleRecruiter: true,
    isEditableRecruiter: false,
    type: 'string',
    sequence: 0,
    version: 1,
    sourceClass: 'UserProfile',
  },

  hideForConfiguration: ['labelTranslations', 'minMaxValidationErrorTranslations', 'textFieldSelector'],
  convertForConfiguration: (configuration) => {
    return {
      ...configuration,
      sourceField: 'customData.' + configuration.name,
    };
  },
  validation: yup.object().shape({
    component: yup.string().required('Component is required!'),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    min: yup.number().required('Min is required!'),
    max: yup.number().required('Max is required!'),
    minMaxValidationError: yup.string().required('Min Max Validation Error is required!'),
    regexValidation: yup.string(),
    regExpValidationError: yup.string(),
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
    minMaxValidationErrorTranslations: translationValidation,
  }),
};

export const MultilineTextfieldTemplate: Template = {
  visible: [
    'textFieldSelector',
    'name',
    'label',
    'labelTranslations',
    'isMandatory',
    'minMax',
    'minMaxValidationError',
    'minMaxValidationErrorTranslations',
    'regexValidation',
    'renderCondition',
    'mandatoryCondition',
  ],
  initialValues: {
    component: 'MultilineTextfield',
    name: '',
    label: '',
    isMandatory: false,
    min: 1,
    max: 200,
    minMaxValidationError: '',
    regexValidation: '',
    regExpValidationError: '',
    renderCondition: null,
    mandatoryCondition: null,
    labelTranslations: {},
    minMaxValidationErrorTranslations: {},
    textFieldSelector: 'MultilineTextfield',
  },
  hidden: {
    category: 'standard',
    isVisibleCandidate: true,
    isEditableCandidate: false,
    isVisibleRecruiter: true,
    isEditableRecruiter: false,
    type: 'string',
    sequence: 0,
    version: 1,
    sourceClass: 'UserProfile',
  },
  hideForConfiguration: ['labelTranslations', 'minMaxValidationErrorTranslations', 'textFieldSelector'],
  convertForConfiguration: (configuration) => {
    return {
      ...configuration,
      sourceField: 'customData.' + configuration.name,
    };
  },
  validation: yup.object().shape({
    component: yup.string().required('Component is required!'),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    min: yup.number().required('Min is required!'),
    max: yup.number().required('Max is required!'),
    minMaxValidationError: yup.string().required('Min Max Validation Error is required!'),
    regexValidation: yup.string(),
    regExpValidationError: yup.string(),
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
    minMaxValidationErrorTranslations: translationValidation,
  }),
};

export const SingleSelectTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'isMandatory',
    'singleSelectComponentType',
    'selectOptions',
    'renderCondition',
    'mandatoryCondition',
  ],
  initialValues: {
    component: 'SingleSelect',
    name: '',
    label: '',
    isMandatory: false,
    singleSelectComponentType: 'Select',
    selectOptions: [],
    defaultValue: '',
    renderCondition: null,
    mandatoryCondition: null,
    labelTranslations: {},
    selectOptionsTranslations: {},
  },
  hidden: {
    category: 'standard',
    isVisibleCandidate: true,
    isEditableCandidate: true,
    isVisibleRecruiter: true,
    isEditableRecruiter: true,
    min: 1,
    max: 40,
    type: 'selector',
    version: 1,
    sourceClass: 'UserProfile',
  },
  hideForConfiguration: ['singleSelectComponentType', 'labelTranslations', 'selectOptionsTranslations'],
  convertForConfiguration: (configurationObject: { [key: string]: any }) => {
    if (configurationObject.controlHeaderTable && configurationObject.selectOptions.length > 0) {
      delete configurationObject['controlHeaderTable'];
    }

    return {
      ...configurationObject,
      component: configurationObject.singleSelectComponentType,
      controlHeaderName: configurationObject.name + '__' + uuid(),
      sourceField: 'customData.' + configurationObject.name,
      description: configurationObject.label,
    };
  },
  converterForUi: (configurationObject: { [key: string]: any }) => {
    return {
      ...configurationObject,
      singleSelectComponentType: configurationObject.component,
      component: 'SingleSelect',
    };
  },
  validation: yup.object().shape({
    component: yup.string().required('Component is required!'),
    name: nameValidation,
    label: yup.string().required('Label is required!'),
    labelTranslations: translationValidation,
    singleSelectComponentType: yup.string().required('Component Type is required!'),
    selectOptions: yup.array().required('Select Options are required!'),
    selectOptionsTranslations: translationValidation,
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
  }),
};

export const MultipleSelectTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'description',
    'descriptionTranslations',
    'isMandatory',
    'multipleSelectComponentType',
    'selectOptions',
    'renderCondition',
    'mandatoryCondition',
  ],
  initialValues: {
    component: 'MultipleSelect',
    name: '',
    label: '',
    description: '',
    isMandatory: false,
    multipleSelectComponentType: 'MultipleSelect',
    selectOptions: [],
    defaultValue: '',
    labelTranslations: {},
    selectOptionsTranslations: {},
    renderCondition: null,
    mandatoryCondition: null,
  },
  hidden: {
    category: 'standard',
    isVisibleCandidate: true,
    isEditableCandidate: true,
    isVisibleRecruiter: true,
    isEditableRecruiter: true,
    min: 1,
    max: 40,
    type: 'selector',
    version: 1,
    sourceClass: 'UserProfile',
  },
  hideForConfiguration: ['multipleSelectComponentType', 'labelTranslations', 'selectOptionsTranslations'],
  convertForConfiguration: (configurationObject: { [key: string]: any }) => {
    if (configurationObject.controlHeaderTable && configurationObject.selectOptions.length > 0) {
      delete configurationObject['controlHeaderTable'];
    }

    return {
      ...configurationObject,
      component: configurationObject.multipleSelectComponentType,
      controlHeaderName: configurationObject.name + '__' + uuid(),
      sourceField: 'customData.' + configurationObject.name,
      defaultValue: JSON.stringify(configurationObject.defaultValue),
      // Maybe this is not needed,test this
      translationSelectItems: configurationObject.label.split('.')[0].toLowerCase(),
    };
  },
  converterForUi: (configurationObject: { [key: string]: any }) => {
    const res: { [key: string]: any } = {
      ...configurationObject,
      multipleSelectComponentType: configurationObject.component,
      component: 'MultipleSelect',
    };

    if (configurationObject?.defaultValue) {
      res.defaultValue = JSON.parse(configurationObject.defaultValue);
    }

    return res;
  },
  validation: yup.object().shape({
    component: yup.string().required('Component is required!'),
    name: nameValidation,
    label: yup.string().required('Label is required!'),
    labelTranslations: translationValidation,
    multipleSelectComponentType: yup.string().required('Component Type is required!'),
    selectOptions: yup.array().required('Select Options are required!'),
    selectOptionsTranslations: translationValidation,
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
  }),
};

export const BooleanTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'isMandatory',
    'booleanComponentType',
    'renderCondition',
    'mandatoryCondition',
  ],
  static: [
    {
      type: 'info',
      value: 'Boolean means a result that can only have one of two possible values: true or false.',
    },
  ],
  initialValues: {
    component: 'Boolean',
    name: '',
    label: '',
    isMandatory: false,
    booleanComponentType: 'Checkbox',
    renderCondition: null,
    mandatoryCondition: null,
    labelTranslations: {},
  },
  hidden: {
    category: 'standard',
    isVisibleCandidate: true,
    isEditableCandidate: true,
    isVisibleRecruiter: true,
    isEditableRecruiter: true,
    type: 'boolean',
    version: 1,
    sourceClass: 'UserProfile',
  },
  hideForConfiguration: ['booleanComponentType', 'labelTranslations'],
  convertForConfiguration: (configurationObject: { [key: string]: any }) => {
    if (configurationObject.controlHeaderTable && configurationObject.selectOptions.length > 0) {
      delete configurationObject['controlHeaderTable'];
    }

    return {
      ...configurationObject,
      component: configurationObject.booleanComponentType,
      controlHeaderName: configurationObject?.customControlHeaders?.name || '',
      sourceField: 'customData.' + configurationObject.name,
    };
  },
  converterForUi: (configurationObject: { [key: string]: any }) => {
    return {
      ...configurationObject,
      booleanComponentType: configurationObject.component,
      component: 'Boolean',
    };
  },
  validation: yup.object().shape({
    component: yup.string().required('Component is required!'),
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    renderCondition: conditionValidation('renderCondition'),
    mandatoryCondition: conditionValidation('mandatoryCondition'),
  }),
};

export const StaticTextTemplate: Template = {
  visible: ['name', 'staticText', 'staticTextTranslations', 'renderCondition', 'mandatoryCondition'],
  isReadOnly: true,
  initialValues: {
    component: 'StaticText',
    name: '',
    label: '',
    renderCondition: null,
    mandatoryCondition: null,
  },

  hidden: {
    category: 'static',
    isVisibleCandidate: true,
    isEditableCandidate: true,
    isVisibleRecruiter: true,
    isEditableRecruiter: true,
    type: 'string',
    version: 1,
  },
  validation: yup.object(),
};
