import { AdminDashboardFilters, adminDashboardFilters } from '@store/slices/oneOnboarding.slice';

export const serializeFilters = (filters: any) => {
  const SKIP_FILTER_KEYS = ['unread', 'failedMails', 'isStudent', 'isEUCitizen', 'importByMe'];
  const serializeArray = (arr: any[]): string => arr.map((v) => `[${v}]`).join(',');
  const serializedEntries = Object.entries(filters)
    .filter(([key, value]) => !SKIP_FILTER_KEYS.includes(key) && value !== null && value !== undefined)
    .map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0) {
        return `${key}=${serializeArray(value)}`;
      }
      return `${key}=${value}`;
    });

  return serializedEntries.join('&');
};

export const deserializeFilters = (queryString: string): AdminDashboardFilters => {
  const urlParams = new URLSearchParams(queryString);
  const ARRAY_VALUE_PATTERN = /\[.{0,250}\]/;
  const deserializeArrayValue = (value: string): any[] => value.split(',').map((v) => v.slice(1, -1));

  const deserialized = Array.from(urlParams.entries()).reduce((acc, [key, value]) => {
    if (!Object.prototype.hasOwnProperty.call(adminDashboardFilters, key)) {
      return acc;
    }

    switch (value) {
      case 'true':
        return { ...acc, [key]: true };
      case 'false':
        return { ...acc, [key]: false };
      case '[]':
        return { ...acc, [key]: [] };
      default:
        if (ARRAY_VALUE_PATTERN.test(value)) {
          return { ...acc, [key]: deserializeArrayValue(value) };
        }
        return { ...acc, [key]: value };
    }
  }, {} as Partial<AdminDashboardFilters>);

  return { ...adminDashboardFilters, ...deserialized };
};

export const deserializeDateFromUrl = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString);
  const ARRAY_VALUE_PATTERN = /\[.{0,250}\]/;
  const deserializeArrayValue = (value: string): any[] => value.split(',').map((v) => v.slice(1, -1));

  const deserialized = Array.from(urlParams.entries()).reduce((acc, [key, value]) => {
    switch (value) {
      case '[]':
        return { ...acc, [key]: [] };
      default:
        if (ARRAY_VALUE_PATTERN.test(value)) {
          return { ...acc, [key]: deserializeArrayValue(value) };
        }
        return { ...acc, [key]: value };
    }
  }, {} as any);

  return deserialized;
};
