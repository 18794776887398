import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { AssetType } from './AdminImport';
import { AdminSimpleButton } from './AdminSimpleButton';

import styles from './AdminImport.module.scss';

interface AdminAssetModalProps {
  asset: AssetType;
  setAsset: (asset: AssetType | undefined) => void;
  handleAssetRemove: (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => void;
}

export const AdminAssetModal: FC<AdminAssetModalProps> = ({ asset, setAsset, handleAssetRemove, ...rest }) => {
  const { t } = useTranslation(['recruiter']);

  return (
    <Dialog
      id="assetmodal"
      className="candidate-profile-delete-modal"
      open={!!asset}
      onClose={() => setAsset(undefined)}
    >
      <DialogTitle>
        <Typography variant="h5" component="span">
          {t('GENERAL.CONFIGURATION.AssetDetails')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box
            className={styles.flexHolder}
            style={{
              borderBottom: '1px dotted #555',
            }}
          >
            <Typography variant="h6">{t('GENERAL.CONFIGURATION.AssetFileName')}</Typography>
            <Typography variant="h6">{asset?.name}</Typography>
          </Box>
          <Box
            className={styles.flexHolder}
            style={{
              borderBottom: '1px dotted #555',
            }}
          >
            <Typography variant="h6">{t('GENERAL.CONFIGURATION.AssetMimeType')}</Typography>
            <Typography variant="h6">{asset?.contentType}</Typography>
          </Box>
          <Box
            className={styles.flexHolder}
            style={{
              borderBottom: '1px dotted #555',
            }}
          >
            <Typography variant="h6">{t('GENERAL.CONFIGURATION.AssetFileSize')}</Typography>
            <Typography variant="h6">{asset?.size ? (asset.size / 1024).toFixed(2) : '--'} kB</Typography>
          </Box>
          {asset?.dimensions && (
            <Box className={styles.flexHolder}>
              <Typography variant="h6">{t('GENERAL.CONFIGURATION.AssetDimensions')}</Typography>
              <Typography variant="h6">{asset?.dimensions}</Typography>
            </Box>
          )}
        </Box>
        <Box id="assetpanel" style={{ textAlign: 'right' }}>
          <div className={styles.assetPanel}>
            {asset?.isImage ? (
              <img src={asset?.src} alt={asset?.path} id="assetpreview" width="100%" className={styles.assetImage} />
            ) : (
              <object
                data={asset?.src}
                type="application/pdf"
                width="100%"
                aria-label={asset?.path}
                style={{ display: 'block' }}
              />
            )}
          </div>
          <Typography
            component="a"
            href={asset?.src}
            target="_blank"
            download={asset?.name}
            style={{
              fontSize: '12px',
            }}
            className={styles.assetDownloadLink}
          >
            <span>{t('GENERAL.CONFIGURATION.DownloadAsset')}</span>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        <AdminSimpleButton
          label={t('GENERAL.CONFIGURATION.AssetModalRemove')}
          onClick={(e: any) => {
            if (asset?.path && window.confirm(t('GENERAL.CONFIGURATION.AssetRemovalConfirmation'))) {
              handleAssetRemove(e, asset.dir, asset.name, asset.clientOrganizationId, asset.configurationId);
              setAsset(undefined);
            }
          }}
        />
        <AdminSimpleButton label={t('GENERAL.CONFIGURATION.AssetModalOk')} onClick={() => setAsset(undefined)} />
      </DialogActions>
    </Dialog>
  );
};
