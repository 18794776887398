import { useParams } from 'react-router-dom';
import { decodeApplicationId } from '@helpers/encode-decode.helper';

type DecodedParams = {
  clientOrganizationId: string;
  userId: string;
  applicationId: string;
  configurationId: string;
};

export const useDecodedParams = (): DecodedParams => {
  const params = useParams<{
    clientOrganizationId: string;
    userId: string;
    applicationId: string;
    configurationId: string;
  }>();
  const { clientOrganizationId = '', userId = '', applicationId = '', configurationId = '' } = decodeApplicationId(
    params,
  );

  return { clientOrganizationId, userId, applicationId, configurationId };
};
