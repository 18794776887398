import Axios, { AxiosInstance, CancelToken } from 'axios';
import { injectable } from 'inversify';
import { OOConfiguration, OODashboardData, OOSelectedElement } from '@features/oneOnboarding/interfaces';
import { CampaignModel } from '@features/oneOnboarding/models/CampaignModel';
import { Import, ImportLine, ImportResponse } from '@features/oneOnboarding/models/Import';
import { OOSelectedTheme } from '@features/oneOnboarding/models/ThemeModel';
import { UserActionHistory, UserFlowHistory, UserFlowModel } from '@features/oneOnboarding/models/UserFlow';
import { OOUserFlowModel } from '@features/oneOnboarding/models/UserFlowModel';
import { SelectedConfiguration } from '@features/selfService/models/configurationModels';
import { AppConfig } from '@helpers/appConfig';
import { doubleEncodeURIComponent } from '@helpers/encode-decode.helper';
import {
  getClientOrganizationId,
  getClientOrganizationIdImport,
  getSelectedConfigurationId,
} from '@helpers/tenant.helper';
import { Utils } from '@helpers/utils';
import { BranchModel } from '@models/branch.model';
import { CivilStatusModel } from '@models/civilStatus.model';
import { ClientOrganizationModel } from '@models/clientOrganization.model';
import { Configuration } from '@models/configurationList.model';
import { DocumentForSignatureModel } from '@models/documentsForSignature.model';
import { DocusignSignedDocumentDto } from '@models/docuSign.model';
import { DraftConfiguration } from '@models/draftConfiguration.model';
import { FeatureConfigurationModel } from '@models/featureConfiguration.model';
import { ImportControl } from '@models/importControl.model';
import { IntegrationStateFaq } from '@models/integrationStateFaq.model';
import { LocationModel } from '@models/location.model';
import { Onboarding } from '@models/onboarding.model';
import { OnboardingUserDetails } from '@models/onboarding-steps';
import { OneSpanDocumentUrlResponse, OneSpanSignedDocumentDto } from '@models/oneSpan.model';
import { PurchaseOrderModel } from '@models/purchaseOrder.model';
import { RegionModel } from '@models/region.model';
import { RequisitionModel } from '@models/requisition.model';
import { QuickFilter, SearchResult } from '@models/searchResult.model';
import {
  SelfServiceItemType,
  SelfServiceLibraryItemResponse,
  SelfServiceSearchResult,
} from '@models/selfService.model';
import { SiteModel } from '@models/site.model';
import {
  EventType,
  TargetName,
  TelemetryActor,
  TelemetryList,
  TelemetrySearchParameters,
  TelemetryStat,
} from '@models/telemetry.model';
import { TenantModel } from '@models/tenant.model';
import { TranslationResponseItem } from '@models/translations.model';
import { CANDIDATE_DELETED_REASON_DELETION, CandidateEmailStatus, CheckUser, UserModel } from '@models/user.model';
import { UserDocumentModel } from '@models/userDocument.model';
import { UserInvitationModel } from '@models/userInvitation.model';
import { UserProfileModel } from '@models/userProfile.model';
import { ControlItems } from '@store/apiQuery';
import { authAdminSlice } from '@store/slices/authAdmin.slice';
import { authAppSlice } from '@store/slices/authApp.slice';
import { dashboardSlice } from '@store/slices/dashboard.slice';
import { oneOnboardingSlice, SelectedLanguage } from '@store/slices/oneOnboarding.slice';

import { store } from '../store';
import { ApiBase, ApiBaseStoreUpdate } from './apiBase';
import { LoggerLevelEnum } from './logger';

interface RequestOptions {
  storeUpdate?: ApiBaseStoreUpdate;
  cancelToken?: CancelToken;
  returnFullResponse?: boolean;
}
@injectable()
export class AamBackendApi extends ApiBase {
  constructor(private _appConfig: AppConfig, private _utils: Utils) {
    super({
      baseURL: _appConfig.backendUrl,
      headers: {
        'Content-Type': 'application/json',
        'X-Client-TraceId': `${_appConfig.clientTraceId}`,
        'X-Request-TraceId': `${_utils.generateRequestTrace()}`,
        'X-Device-Platform': 'web',
        'X-Api-Version': '1.0',
        ...(_appConfig.apimSubscriptionKey
          ? {
              'Ocp-Apim-Subscription-Key': _appConfig.apimSubscriptionKey,
            }
          : {}),
      },
    });

    this.setHooks({
      beforeRequest: [this.addTenantAlias, this.addAuthorizationToken, this.addRequestId],
      tokenExpiredError: [this.handleTokenRefresh],
    });
  }

  protected handleTokenRefresh = async (): Promise<boolean> => {
    const isAppSection = window.location.pathname.startsWith('/app/');
    // This means we had a token but it expired
    const auth = isAppSection ? store.getState().authApp : store.getState().authAdmin;
    if (!auth.refreshToken) {
      return this.onTokenRefreshFailure();
    }
    const redirectUrl = (() => {
      if (isAppSection) {
        return this._appConfig.frontendUrl + '/app/aadlogin/token';
      }
      return this._appConfig.frontendUrl + '/admin/aadlogin/token';
    })();
    try {
      if (auth.authMethod === 'b2c') {
        const b2c = store.getState().featureConfiguration.b2c.signIn;
        const url = `${b2c.tokenUri}&grant_type=refresh_token&scope=openid%20offline_access&client_id=${
          b2c.clientId
        }&redirect_uri=${encodeURIComponent(redirectUrl)}&refresh_token=${auth.refreshToken}`;
        const res = await Axios.create().post(url);
        store.dispatch(authAppSlice.actions.refreshB2C({ data: res.data }));
      } else {
        const adeccoAd = store.getState().featureConfiguration.adeccoAd.signIn;
        const params = new URLSearchParams();
        params.append('client_id', adeccoAd.clientId);
        params.append('grant_type', 'refresh_token');
        params.append('scope', 'openid offline_access');
        params.append('redirect_uri', redirectUrl);
        params.append('refresh_token', auth.refreshToken);

        const res = await Axios.create().post(adeccoAd.tokenUri, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        store.dispatch(authAdminSlice.actions.refreshAdeccoAD({ data: res.data }));
      }
      return true;
    } catch (err) {
      return this.onTokenRefreshFailure();
    }
  };

  private onTokenRefreshFailure() {
    const route = window.location.pathname;
    const isCandidateSection = route.startsWith('/app/') || (route.startsWith('/oo/') && !route.includes('/recruiter'));

    if (isCandidateSection) {
      store.dispatch(authAppSlice.actions.clear());
      const isPreregisterUrl =
        window.location.href.includes('app/login') && window.location.href.includes('candidateId');
      // reload the page to start from scratch without the previous user session
      if (isPreregisterUrl) {
        window.location.reload();
      } else {
        window.location.href = '/app/login?sessionExpired=true';
      }
      return false;
    }
    store.dispatch(authAdminSlice.actions.clear());
    window.location.href = '/oo/recruiter/login?sessionExpired=true';
    return false;
  }

  protected addTenantAlias = async (axiosInstance: AxiosInstance): Promise<void> => {
    axiosInstance.defaults.headers['X-TenantAlias'] = store.getState().tenant.tenantAlias;
  };

  protected addRequestId = async (axiosInstance: AxiosInstance): Promise<void> => {
    axiosInstance.defaults.headers['X-Request-Id'] = this._utils.uuid();
  };

  protected addAuthorizationToken = async (axiosInstance: AxiosInstance): Promise<void> => {
    const isAppSection =
      window.location.pathname.startsWith('/app/') ||
      (window.location.pathname.startsWith('/oo/') && !window.location.pathname.includes('recruiter'));
    const auth = isAppSection ? store.getState().authApp : store.getState().authAdmin;
    if (auth.idToken) {
      axiosInstance.defaults.headers.authorization = `Bearer ${auth.idToken}`;
    }
  };

  public async create<T>(path: string, object: T, options: RequestOptions = {}): Promise<T> {
    return this.request<T>({
      method: 'POST',
      url: path,
      data: object,
      ...options,
    });
  }

  public async upsert<T>(path: string, object: T, options: RequestOptions = {}): Promise<T> {
    return this.request<T>({
      method: 'PUT',
      url: path,
      data: object,
      ...options,
    });
  }

  public async update<T>(path: string, object: T, options: RequestOptions = {}): Promise<T> {
    return this.request<T>({
      method: 'PATCH',
      url: path,
      data: object,
      ...options,
    });
  }

  public async delete<T>(path: string, ids: any, options: RequestOptions = {}): Promise<T> {
    return this.request<T>({
      method: 'DELETE',
      url: path,
      ...options,
      hooks: {
        dataTransform: [
          (data: any) => ({
            ...data,
            ...ids,
          }),
        ],
      },
    });
  }

  public async fetchSingle<T>(path: string, options: RequestOptions = {}): Promise<T> {
    return this.request<T>({
      method: 'get',
      url: path,
      ...options,
    });
  }

  public async fetchCollection<T>(path: string, options: RequestOptions = {}): Promise<T[]> {
    return this.request<T[]>({
      method: 'get',
      url: path,
      ...options,
    });
  }

  public async fetchFeatureConfigurations(
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<FeatureConfigurationModel[] | undefined> {
    return this.request<FeatureConfigurationModel[]>({
      method: 'get',
      url: `/api/featureConfiguration`,
      storeUpdate,
    });
  }

  public async checkUser(
    tenantId: number,
    cid: any,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<any> {
    return this.fetchSingle(
      `/api/tenant/${tenantId}/user/${cid}/check?clientOrganizationId=${clientOrganizationId}&configurationId=${configurationId}`,
    );
  }

  public async fetchUserProfile(
    tenantId: number,
    userId: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<UserProfileModel | undefined> {
    return this.fetchSingle(`/api/tenant/${tenantId}/userProfile/${userId}`, { storeUpdate });
  }

  public async fetchAvailableTenantsForRecruiter(userId: string): Promise<number[]> {
    return this.request({
      method: 'GET',
      url: `/api/user/${userId}/get-all-tenants`,
    });
  }

  public async fetchOnboardingFlowUsersv2(tenantId: number, data: any): Promise<SearchResult> {
    return this.create(`/api/onboarding/${tenantId}/search-onboardings-v2`, data);
  }

  public async fetchOnboardingFlowUsersv3(data: any): Promise<SearchResult> {
    return this.create(`/api/onboarding-summary/search-onboardings-v3`, data);
  }

  public async fetchDocumentForSign(tenantId: number, documentKey: string): Promise<any> {
    return this.request({
      method: 'GET',
      url: `/api/tenant/${tenantId}/document/static/${documentKey}`,
      responseType: 'blob',
    });
  }

  public async getOnboardingTheme(
    clientOrganizationId: string,
    configId: string,
  ): Promise<OOSelectedTheme | undefined> {
    return this.fetchSingle<OOSelectedTheme>(
      `api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/selected-theme/current`,
      {
        storeUpdate: {
          dispatch: store.dispatch,
          dataUpdateAction: oneOnboardingSlice.actions.setThemeAdditionalData,
        },
      },
    );
  }

  public async onboardingCompleteStep(
    tenantId: number,
    userId: string,
    onboardingFlowId: string,
    key: string,
    data: any,
    updateData?: any,
  ): Promise<OnboardingUserDetails> {
    const requestData: any = {
      onboardingFlowId,
      key,
    };
    if (data) {
      requestData['data'] = data;
    }

    if (updateData) {
      requestData['updateData'] = updateData;
    }

    return this.request({
      method: 'post',
      url: `/api/onboarding/complete-step`,
      data: requestData,
    });
  }

  public async onboardingCompleteStepForUser(
    tenantId: number,
    userId: string,
    onboardingFlowId: string,
    key: string,
    data: any,
    updateData?: any,
  ): Promise<OnboardingUserDetails> {
    const requestData: any = {
      onboardingFlowId,
      userId,
      key,
    };
    if (data) {
      requestData['data'] = data;
    }

    if (updateData) {
      requestData['updateData'] = updateData;
    }

    return this.request({
      method: 'post',
      url: `/api/onboarding/complete-step-for-user`,
      data: requestData,
    });
  }

  public async onboardingUpdateStep(
    tenantId: number,
    userId: string | null,
    onboardingFlowId: string,
    key: string,
    updateData: any,
  ): Promise<OnboardingUserDetails> {
    const requestData = {
      onboardingFlowId,
      key,
      updateData,
    };

    return this.request({
      method: 'post',
      url: `/api/onboarding/update-step`,
      data: requestData,
    });
  }

  public async onboardingUpdateStepForUser(
    tenantId: number,
    userId: string | null,
    onboardingFlowId: string,
    key: string,
    updateData: any,
  ): Promise<OnboardingUserDetails> {
    const requestData = {
      onboardingFlowId,
      userId,
      key,
      updateData,
    };

    return this.request({
      method: 'post',
      url: `/api/onboarding/update-step-for-user`,
      data: requestData,
    });
  }

  public exportCandidates = async (
    filters: any,
    uploadToBlobStorage: boolean,
    exportConfigurationName?: string,
  ): Promise<any> =>
    this.request({
      method: 'post',
      url: `/api/export-candidates`,
      data: { userSearchDto: filters, uploadToBlobStorage, exportConfigurationName },
      responseType: 'blob',
      returnFullResponse: true,
    }).then((resp: any) => {
      const fileName = resp.headers['content-disposition']?.split('filename=')[1] || 'candidatesList.xlsx';
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

  public exportApprovedPDF = async (
    clientOrganizationId: string,
    configurationId: string,
    userId: string,
    applicationId: string,
  ): Promise<any> =>
    this.request({
      method: 'post',
      url: `/api/export-candidates/export-approved-pdf`,
      data: { clientOrganizationId, configurationId, userId, applicationId },
      responseType: 'blob',
      returnFullResponse: true,
    });

  public exportAllDocumentsPDF = async (userId: string): Promise<any> =>
    this.request({
      method: 'get',
      url: `/api/export-candidates/export-all-docs-pdf/${userId}`,
      responseType: 'blob',
      returnFullResponse: true,
    });
  public async downloadHiringList(filters: any): Promise<any> {
    return this.request({
      method: 'post',
      url: `/api/tenant/${store.getState().tenant.tenantId}/user/get-hired-candidates`,
      data: filters,
      responseType: 'blob',
      returnFullResponse: true,
    }).then((resp: any) => {
      const fileName = resp.headers['content-disposition']?.split('filename=')[1] || 'hiring-list.xlsx';
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  public exportCsv = async (tenantId: number, entityName: string): Promise<any> =>
    this.request({
      method: 'post',
      url: `/api/export-csv`,
      data: { entityName },
      responseType: 'blob',
      returnFullResponse: true,
    });

  public async downloadDocument(tenantId: number, userId: string, documentId: any, documentKey?: any): Promise<any> {
    if (!documentId && documentKey) {
      return this.fetchDocumentForSign(tenantId, documentKey);
    } else {
      const { data } = await this.downloadDocumentWithFullResponse(tenantId, userId, documentId);
      return data;
    }
  }

  public async downloadDocumentWithFullResponse(tenantId: number, userId: string, documentId: string): Promise<any> {
    return this.request({
      responseType: 'arraybuffer',
      method: 'get',
      url: `/api/tenant/${tenantId}/document/${userId}/download/${documentId}`,
      returnFullResponse: true,
    });
  }

  public async downloadExample(
    tenantId: number,
    clientOrganizationId: string,
    configuratonId: string,
    exampleName: string,
  ): Promise<any> {
    return this.request({
      responseType: 'arraybuffer',
      method: 'get',
      url: `/api/tenant/${tenantId}/document/${clientOrganizationId}/${configuratonId}/download/${exampleName}`,
      returnFullResponse: true,
    });
  }

  public async downloadLogo(tenantId: number, logoName: string): Promise<any> {
    return this.request({
      responseType: 'arraybuffer',
      method: 'get',
      url: `/api/tenant/${tenantId}/document/logo/download/${logoName}`,
      returnFullResponse: true,
    });
  }

  public async updateDocument(tenantId: number, documentId: any, data: any): Promise<any> {
    return this.request({
      method: 'patch',
      url: `/api/tenant/${tenantId}/document/${documentId}`,
      data,
    });
  }

  public async rejectDocument(
    clientOrganizationId: any,
    userId: any,
    documentId: any,
    data: any,
    applicationId: string,
    configId: string,
  ): Promise<any> {
    return this.request({
      method: 'post',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/reject-document/${documentId}`,
      data,
    });
  }

  public async approveDocument(
    clientOrganizationId: any,
    userId: any,
    documentId: any,
    data: any,
    applicationId: string,
    configId: string,
  ): Promise<any> {
    return this.request({
      method: 'post',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/approve-document/${documentId}`,
      data,
    });
  }

  public async fetchRegions(): Promise<any[]> {
    return this.fetchCollection('/api/region');
  }

  public async fetchImports(coi?: string, ci?: string): Promise<Import[]> {
    const clientOrganizationId = coi ?? store.getState().oneOnboarding.importClientOrganizationId;
    const configurationId = ci ?? store.getState().oneOnboarding.selectedConfigurationId;
    return this.fetchCollection<Import>(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import/with-relations`,
    );
  }

  public async fetchImportsWithNoRelation(
    coi?: string,
    ci?: string,
    tableParameters?: {
      page: number;
      limit: number;
      startDate?: number | null;
      importStatus?: string;
      order: {
        direction: string;
        field: string;
      };
    },
  ): Promise<ImportResponse> {
    const clientOrganizationId = coi ?? store.getState().oneOnboarding.importClientOrganizationId;
    const configurationId = ci ?? store.getState().oneOnboarding.selectedConfigurationId;

    let baseUrl = `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import?page=${tableParameters?.page}&limit=${tableParameters?.limit}&order=${tableParameters?.order.direction}&orderBy=${tableParameters?.order.field}`;

    if (tableParameters?.startDate) {
      baseUrl += `&startDate=${tableParameters.startDate}`;
    }

    if (tableParameters?.importStatus) {
      baseUrl += `&importStatus=${tableParameters.importStatus}`;
    }

    return this.request({
      method: 'GET',
      url: baseUrl,
    });
  }

  public async fetchImportLine(lineId: string, coi?: string, ci?: string): Promise<ImportLine[]> {
    const clientOrganizationId = coi ?? store.getState().oneOnboarding.importClientOrganizationId;
    const configurationId = ci ?? store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'get',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import/import-line/${lineId}`,
    });
  }

  public async fetchImport(lineId: string, coi?: string, ci?: string): Promise<Import> {
    const clientOrganizationId = coi ?? store.getState().oneOnboarding.importClientOrganizationId;
    const configurationId = ci ?? store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'get',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import/import-data/${lineId}`,
    });
  }

  public async triggerImport(importLineId: string): Promise<ImportLine> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;

    return this.request<ImportLine>({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import/one-onboarding-batch-retrigger/${importLineId}`,
    });
  }

  public async deleteMultipleCandidatesSoft(
    tenantId: any,
    userIds: any,
    selectAll?: boolean,
    dashboardFilters?: { [key: string]: string },
    sendDeletionMail?: boolean,
  ) {
    return this.create(`/api/tenant/${tenantId}/user/delete/multiple`, {
      userIds,
      selectAll,
      dashboardFilters,
      sendDeletionMail,
    });
  }

  public async ooImportCandidates(
    formData: FormData,
  ): Promise<{ users: UserModel[]; failedUsers: Array<any>; error: string }> {
    const clientOrganizationIdImport = getClientOrganizationIdImport();
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationIdImport}/selected-configuration/${configurationId}/import/one-onboarding`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async ooImportCandidatesAsync(
    formData: FormData,
  ): Promise<{ users: UserModel[]; failedUsers: Array<any>; error: string; result: Import }> {
    const clientOrganizationIdImport = getClientOrganizationIdImport();
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationIdImport}/selected-configuration/${configurationId}/import/one-onboarding-batch`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  public async ooImportPdf(formData: FormData): Promise<{ result: boolean; error: string }> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import/pdf`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsv(tenantId: number, type: string, formData: FormData): Promise<BranchModel[]> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/${type}`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvLocations(formData: FormData): Promise<LocationModel[]> {
    return this.request({
      method: 'POST',
      url: `/api/csv-import/locations`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvGeneral(tenantId: number, formData: FormData): Promise<{ result: Array<any>; error: string }> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/general`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvSelfService(
    tenantId: number,
    configuration: {
      file: any;
      fromClientOrganizationId: string;
      fromConfigurationId: string;
      toClientOrganizationId: string;
      toConfigurationId: string;
      isUpdate?: boolean;
    },
  ): Promise<{ result: Array<any>; error: string }> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/self-service`,
      data: configuration,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  public async importCsvTenant(tenantId: number, formData: FormData): Promise<TenantModel[]> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/tenant`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvSite(
    tenantId: number,
    formData: FormData,
  ): Promise<{
    imported: SiteModel[];
    failed: any[];
  }> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/sites`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvBranch(
    tenantId: number,
    formData: FormData,
  ): Promise<{
    imported: BranchModel[];
    failed: any[];
  }> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/branches`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvClientOrganization(tenantId: number, formData: FormData): Promise<PurchaseOrderModel[]> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/client-organization`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvRegions(formData: FormData): Promise<RegionModel[]> {
    return this.request({
      method: 'POST',
      url: `/api/csv-import/regions`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  public async importCsvCivilStatuses(tenantId: number, formData: FormData): Promise<CivilStatusModel[]> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/csv-import/civilStatuses`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  public async updateOnboarding(userId: string, payload: any): Promise<any> {
    return this.update(`/api/onboarding/entity/${userId}`, payload);
  }

  public async updateOneOnboarding(
    clientOrganizationId: string,
    configurationId: string,
    userId: string,
    applicationId: string,
    payload: any,
  ): Promise<any> {
    return this.update(
      `/api/onboarding/${clientOrganizationId}/${configurationId}/${userId}/${doubleEncodeURIComponent(applicationId)}`,
      payload,
    );
  }

  public async updateOnboardingMultiple(
    clientOrganizationId: string,
    configurationId: string,
    applicationId: string,
    userId: string,
    payload: any,
  ): Promise<any> {
    return this.update(
      `/api/onboarding/entity/${clientOrganizationId}/${configurationId}/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
      payload,
    );
  }

  public async getOnboarding(
    clientOrganizationId: string,
    configurationId: string,
    applicationId: string,
    userId: string,
  ): Promise<Onboarding> {
    return this.fetchSingle(
      `/api/onboarding/get-one-with-relations/${clientOrganizationId}/${configurationId}/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
    );
  }

  public async getOnboardingsWithRelations(userId: string): Promise<Onboarding[]> {
    return this.fetchCollection<Onboarding>(`/api/onboarding/get-all-with-relations/${userId}`);
  }

  public async getOnboardingsWithRelationsMultiple(
    clientOrganizationId: string,
    configurationId: string,
    applicationId: string,
    userId: string,
  ): Promise<Onboarding> {
    return this.fetchSingle<Onboarding>(
      `/api/onboarding/get-one-with-relations/${clientOrganizationId}/${configurationId}/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
      {
        storeUpdate: {
          dispatch: store.dispatch,
          dataUpdateAction: dashboardSlice.actions.selectOnboarding,
        },
      },
    );
  }

  public async setCandidateJourney(
    tenantId: number,
    clientOrganizationId: string,
    configurationId: string,
    applicationId: string,
    userId: string,
    journeyId: string,
  ): Promise<Onboarding> {
    return this.request<Onboarding>({
      method: 'POST',
      url: `/api/onboarding/${tenantId}/${clientOrganizationId}/${configurationId}/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/set-candidate-journey`,
      data: { journeyId },
    });
  }

  public async resetCandidateJourney(
    clientOrganizationId: string,
    configurationId: string,
    userId: string,
    applicationId: string,
  ): Promise<any> {
    return this.request({
      method: 'delete',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/user-flow/${userId}/${applicationId}/reset-journey`,
    });
  }

  public async getAllApplicationsForUserAndClientOrg(userId: string): Promise<Onboarding[]> {
    return this.fetchCollection<Onboarding>(`/api/onboarding/get-all-with-clientorg/${userId}`, {
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setHasMultipleOnboarding,
      },
    });
  }

  public async checkOnboardingDocuments(tenantId: number, userId: string): Promise<any> {
    return this.create(`/api/tenant/${tenantId}/document/${userId}/check-documents`, {});
  }

  public async createUserDocuments(tenantId: number, userId: string, data?: any): Promise<any> {
    return this.create(`/api/tenant/${tenantId}/document/${userId}/check-documents`, data);
  }

  public async getDocumentByUrlId(
    tenantId: number,
    userId: string,
    documentType: string,
  ): Promise<UserDocumentModel[]> {
    return this.fetchCollection(`/api/tenants/${tenantId}/document/${userId}/document/documentType/${documentType}`);
  }

  public async createUserProfile(tenantId: number, data: any): Promise<UserProfileModel> {
    return this.create(`/api/tenant/${tenantId}/userProfile/create`, data);
  }

  public async getIntegrationCommandInfo(commandId: string): Promise<any[]> {
    return this.fetchCollection(`/api/service-bus-messages/${commandId}`);
  }
  public async retriggerIntegration(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    applicationId: string,
  ): Promise<any[]> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/commands/retrigger`,
    });
  }

  public async updateUserProfile(tenantId: number, userId: string | null, data: any): Promise<UserProfileModel> {
    return this.update(`/api/tenant/${tenantId}/userProfile/${userId}`, data);
  }

  public async updateUser(tenantId: number, userId: string, data: any): Promise<any> {
    return this.update(`/api/tenant/${tenantId}/user/${userId}`, data);
  }

  public async ooRejectUser(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    applicationId: string,
    reason: string,
  ): Promise<UserProfileModel> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/reject-user`,
      data: {
        reason,
      },
    });
  }

  public async ooRejectUserBulk(
    clientOrganizationId: string,
    configId: string,
    users: any[],
    selectAll: boolean,
    userFilters: { [key: string]: any },
    reason: string,
  ): Promise<UserProfileModel> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/reject-user-bulk`,
      data: {
        users,
        selectAll,
        userFilters,
        reason,
      },
    });
  }

  public async ooUnRejectUser(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    applicationId: string,
  ): Promise<UserProfileModel> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/unreject-user`,
    });
  }

  public async deleteUser(tenantId: number, userId: string, sendDeletionMail: boolean): Promise<UserModel> {
    return this.create<any>(`/api/tenant/${tenantId}/user/${userId}`, { sendDeletionMail });
  }

  public async markForDeletion(tenantId: number, userId: string): Promise<any> {
    return this.updateUser(tenantId, userId, {
      toBeDeletedReason: CANDIDATE_DELETED_REASON_DELETION,
    });
  }

  public async deleteSelf(tenantId: number): Promise<UserModel> {
    return this.request({
      method: 'delete',
      url: `/api/tenant/${tenantId}/user/self`,
    });
  }

  public async fetchUser(
    tenantId: number,
    id: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<UserModel | undefined> {
    const user = await this.fetchSingle<UserModel | undefined>(`/api/tenant/${tenantId}/user/${id}`, { storeUpdate });
    const isOneOnboardingAdmin = window.location.pathname.includes('/oo/recruiter');
    if (user?.lockedAt && !isOneOnboardingAdmin) {
      store.dispatch(authAppSlice.actions.clear());
      window.location.href = '/app/login';
    }
    return user;
  }

  public async fetchUserByAdId(
    tenantId: number,
    adeccoAdId: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<UserModel | undefined> {
    return this.fetchSingle(`/api/tenant/${tenantId}/user/get-one-by-adecco-ad-id/${adeccoAdId}`, { storeUpdate });
  }

  public async fetchUserWithUserProfile(tenantId: number, id: string): Promise<UserModel | undefined> {
    return this.fetchSingle(`/api/tenant/${tenantId}/user/${id}/userProfile`);
  }

  public async fetchUserByAdIdWithUserProfile(
    tenantId: number,
    adeccoAdId: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<UserModel | undefined> {
    return this.fetchSingle(`/api/tenant/${tenantId}/user/get-one-by-adecco-ad-id-with-user-profile/${adeccoAdId}`, {
      storeUpdate,
    });
  }

  public async b2cTokenReceived(data: {
    token: string;
    consent?: any;
    clientOrganizationId?: string;
    email?: string;
    campaignId?: string | null;
  }): Promise<{ status: string; userId: string }> {
    return this.request<{ status: string; userId: string }>({
      method: 'post',
      url: '/api/auth/b2cTokenReceived',
      data,
    });
  }

  public async adeccoAdTokenReceived(idToken: string, consentData?: any): Promise<{ userId: string }> {
    return this.request<{ userId: string }>({
      method: 'post',
      url: '/api/auth/adeccoAdTokenReceived',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      data: consentData ?? {},
    });
  }

  public async invalidateToken(): Promise<void> {
    return this.request({
      method: 'POST',
      url: `/api/token/invalidate`,
    });
  }

  public async getUserProfile(tenantId: string | number, userId: string | null | undefined): Promise<UserProfileModel> {
    return this.request({
      method: 'GET',
      url: `/api/tenant/${tenantId}/userProfile/${userId}`,
    });
  }

  public async getCandidateEmailStatus(tenantId: string | number, email: string): Promise<CandidateEmailStatus> {
    return this.request({
      method: 'GET',
      url: `/api/tenant/${tenantId}/user/candidateEmailStatus/${email}/`,
    });
  }

  public async fetchUserInvitations(tenantId: number): Promise<UserInvitationModel[]> {
    return this.fetchCollection(`/api/tenant/${tenantId}/userInvitations`);
  }
  public async createUserInvitation(
    tenantId: number,
    data: Pick<UserInvitationModel, 'email' | 'role'>,
  ): Promise<UserInvitationModel> {
    return this.create(`/api/tenant/${tenantId}/userInvitations`, data) as Promise<UserInvitationModel>;
  }
  public async deleteUserInvitation(tenantId: number, data: UserInvitationModel): Promise<UserInvitationModel> {
    return this.delete(`/api/tenant/${tenantId}/userInvitations/${data.id}`, ['id']);
  }

  public async updateUserPermission(tenantId: number, userInvitationId: string, data: any): Promise<any> {
    return this.update(`/api/tenant/${tenantId}/user/updateUserPermission/${userInvitationId}`, data);
  }
  public async fetchUserPermissions(tenantId: number): Promise<UserModel[]> {
    return this.fetchCollection(`/api/tenant/${tenantId}/user/get-all-admin-with-permission`);
  }

  public async getUserWithPermissions(
    tenantId: string | number,
    userId: string | null | undefined,
  ): Promise<UserModel> {
    return this.request({
      method: 'GET',
      url: `/api/tenant/${tenantId}/user/with-permission/${userId}`,
    });
  }

  public async deleteUserWithPermission(tenantId: number, userId: string): Promise<UserModel> {
    return this.delete(`/api/tenant/${tenantId}/user/user-permission/${userId}`, ['id']);
  }

  public async deactivateUserWithPermission(tenantId: number, userId: string): Promise<UserModel> {
    return this.delete(`/api/tenant/${tenantId}/user/user-permission-deactivate/${userId}`, ['id']);
  }

  public async saveSignedDocument(userId: string, data: DocusignSignedDocumentDto): Promise<any[]> {
    return this.request<any[]>({
      method: 'post',
      url: `/api/docusign/${userId}/document`,
      data,
    });
  }

  public async saveOneSpanSignedDocument(userId: string, data: OneSpanSignedDocumentDto): Promise<any[]> {
    let { clientOrganizationId, selectedConfigurationId } = store.getState().oneOnboarding;
    clientOrganizationId = getClientOrganizationId() ?? clientOrganizationId;
    selectedConfigurationId = getSelectedConfigurationId() ?? selectedConfigurationId;
    return this.request<any[]>({
      method: 'post',
      url: `/api/client-organization/${clientOrganizationId}/onespan/${userId}/document`,
      data: { ...data, configurationId: selectedConfigurationId },
    });
  }

  public async getOneSpanOOSignAllDocumentUrl(
    clientOrganizationId: string,
    data: any,
  ): Promise<OneSpanDocumentUrlResponse> {
    return this.request<OneSpanDocumentUrlResponse>({
      method: 'post',
      url: `/api/client-organization/${clientOrganizationId}/onespan/oo/sign-all`,
      data,
    });
  }

  public async getSites(): Promise<Array<SiteModel>> {
    return this.fetchCollection<SiteModel>(`/api/site`);
  }

  public async getLocationsBySelectedRegions(regionIds: string[]): Promise<Array<SiteModel>> {
    return this.create<any>('/api/branch/get-filter-locations', { regionIds });
  }

  public async getAllTenants(): Promise<any[]> {
    return this.fetchCollection(`/api/country/tenants/get-all`);
  }
  public async getAdminMetrics(period: 'week' | 'month' | 'year', tenantId?: number): Promise<any[]> {
    return this.request({
      method: 'post',
      url: `/api/onboarding/metrics/${period}/${tenantId || ''}`,
    });
  }

  public async ooGetCountries(): Promise<ControlItems> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.create(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-control-item/get-all-for-table`,
      {
        tableName: 'Country',
        labelColumn: 'name',
        valueColumn: 'id',
        filters: [{ orderBy: 'ASC' }],
      } as any,
    );
  }

  public async getRecruiterElements(
    clientOrganizationId: string,
    configurationId: string,
  ): Promise<OOSelectedElement[]> {
    return this.fetchCollection(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-element/get-recruiter-elements`,
    );
  }

  public async ooGetCountryById(id: string): Promise<ControlItems> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.create(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-control-item/get-all-for-table`,
      {
        tableName: 'Country',
        labelColumn: 'name',
        valueColumn: 'id',
        filters: [{ key: 'id', value: id }],
      } as any,
    );
  }

  public async ooGetJobShiftTypes(): Promise<ControlItems> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    const tenantId = store.getState().tenant.tenantId;
    return this.create(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-control-item/get-all-for-table`,
      {
        tableName: 'JobShiftType',
        labelColumn: 'name',
        valueColumn: 'id',
        filters: [
          { key: 'tenantId', value: tenantId },
          // { key: 'isValid', value: true },
        ],
      } as any,
    );
  }

  public async ooGetUserFlow(
    clientOrganizationId: string,
    configurationId: string,
    userId: string,
    applicationId: string,
  ): Promise<UserFlowModel> {
    return this.fetchSingle(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
    );
  }

  public async ooGetDocumentsForSign(
    clientOrganizationId: string,
    configurationId: string,
  ): Promise<DocumentForSignatureModel[]> {
    return this.fetchCollection(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/document-for-signature/get-all-for-selected-configuration`,
    );
  }

  public async downloadOODocumentForSignature(name: string, coid?: string, cid?: string): Promise<any> {
    const clientOrganizationId = coid ?? store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = cid ?? store.getState().oneOnboarding.selectedConfigurationId;

    return this.request({
      responseType: 'blob',
      method: 'get',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/document-for-signature/${name}/download`,
    });
  }

  public async fetchClientOrganizationForTenantIncludingTenantUnrelated(tenantId: number) {
    return this.fetchCollection<ClientOrganizationModel>(
      `/api/client-organization?forTenant=${tenantId}&includeRecordsWithoutTenant=true`,
    );
  }
  public async fetchFileProps(clientOrganizationId: string, includeFiles?: boolean) {
    return await this.fetchCollection(
      `/api/client-organization/get-file-props-for-client-by-tenant/${clientOrganizationId}/${includeFiles}`,
    );
  }
  public async fetchClientOrganizationForTenantByTenant(includeFiles: boolean = true) {
    const result: any = await this.fetchCollection<TenantModel>(
      `/api/client-organization/get-clients-for-tenant-by-tenant`,
    );
    return result;
  }
  public async fetchClientOrganizations() {
    return this.fetchCollection<ClientOrganizationModel>(`/api/client-organization`);
  }

  public async deleteClientOrganization(id: string) {
    return this.request<ClientOrganizationModel>({
      method: 'DELETE',
      url: `/api/client-organization/${id}`,
    });
  }

  public fetchSelectedConfiguration = async (clientOrganizationId: string, configurationId: string) => {
    return this.fetchSingle<SelectedConfiguration>(
      `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}`,
    );
  };

  public fetchUserFlowWithCurrentStepInfoAndUpdateRedux = async (
    userId: string,
    applicationId?: string,
    clientOrganizationId?: string,
    selectedConfigurationId?: string,
  ): Promise<OOUserFlowModel> => {
    const orgId =
      clientOrganizationId ?? store.getState().oneOnboarding.clientOrganizationId ?? getClientOrganizationId();
    const confId =
      selectedConfigurationId ?? store.getState().oneOnboarding.selectedConfigurationId ?? getSelectedConfigurationId();

    const appId = applicationId || localStorage.getItem('applicationId') || '';
    return this.fetchSingle<OOUserFlowModel>(
      `/api/client-organization/${orgId}/selected-configuration/${confId}/user-flow/${userId}/${doubleEncodeURIComponent(
        appId,
      )}`,
      {
        storeUpdate: {
          dispatch: store.dispatch,
          dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
        },
      },
    );
  };

  public async getControlItems(
    controlHeaderName: string,
    data?: any,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<ControlItems> {
    const orgId = clientOrganizationId
      ? clientOrganizationId
      : store.getState().dashboard.selectedOnboarding?.clientOrganizationId ??
        store.getState().oneOnboarding.clientOrganizationId ??
        getClientOrganizationId();

    const confId = configurationId
      ? configurationId
      : store.getState().dashboard.selectedOnboarding?.configurationId ??
        store.getState().oneOnboarding.selectedConfigurationId ??
        getSelectedConfigurationId();

    if (!confId || !orgId) return [];

    return this.request({
      method: 'POST',
      url: `/api/client-organization/${orgId}/selected-configuration/${confId}/selected-control-item/get-all-for-control-name/${controlHeaderName}`,
      data,
    });
  }
  public async getControlsItems(clientOrganizationId: string, configurationId: string, names: string[]): Promise<any> {
    if (!clientOrganizationId || !configurationId || !names || names.length === 0) {
      return {};
    }
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-control-item/get-all-for-control-names`,
      data: { names },
    });
  }
  public async getDocumentControlItems(
    controlHeaderName: string,
    documentId: string,
    data?: any,
  ): Promise<ControlItems> {
    const selectedOnboarding = store.getState().dashboard.selectedOnboarding;
    const oneOnboarding = store.getState().oneOnboarding;
    const clientOrganizationId = selectedOnboarding?.clientOrganizationId ?? oneOnboarding?.clientOrganizationId;
    const configurationId = selectedOnboarding?.configurationId ?? oneOnboarding?.selectedConfigurationId;
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-document-control/get-all-for-control-name/${documentId}/${controlHeaderName}`,
      data,
    });
  }
  public async fetchSelectedLanguages(
    clientOrganizationId: string,
    selectedConfigurationId: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<Array<SelectedLanguage>> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${selectedConfigurationId}/selected-language/get-all-for-selected-configuration`,
      storeUpdate,
    });
  }
  public fetchClientOrganizationSelectedLanguages(
    clientOrganizationId: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<Array<SelectedLanguage>> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/--/selected-language/get-all-for-client-organization`,
      storeUpdate,
    });
  }
  public async fetchSelectedLanguagesForTenant(storeUpdate?: ApiBaseStoreUpdate): Promise<Array<SelectedLanguage>> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/--/selected-configuration/--/selected-language/get-all-for-tenant`,
      storeUpdate,
    });
  }
  public async fetchSelectedMail(
    clientOrganizationId: string,
    selectedConfigurationId: string,
    storeUpdate?: ApiBaseStoreUpdate,
  ): Promise<Array<any>> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${selectedConfigurationId}/selected-mail/get-all-for-recruiter`,
      storeUpdate,
    });
  }
  public async sendReminderMail(
    clientOrganizationId: string,
    selectedConfigurationId: string,
    data: any,
  ): Promise<void> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${selectedConfigurationId}/selected-mail/send-cron-mails-manually`,
      data,
    });
  }
  public async sendManualMail(
    clientOrganizationId: string,
    selectedConfigurationId: string,
    userId: string,
    applicationId: string,
    data: any,
  ): Promise<boolean> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${selectedConfigurationId}/selected-mail/send-manual-mail/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
      data,
    });
  }

  private buildFormData(data: any, files?: any): FormData {
    const formData = new FormData();
    if (files) {
      Object.keys(files).forEach((key) => {
        formData.append(key, files[key]);
      });
    }
    Object.keys(data).forEach((key) => {
      let val = data[key];
      if (typeof val === 'object') {
        val = JSON.stringify(val);
      }
      formData.append(key, val);
    });

    return formData;
  }

  public async updateUserFlow(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    data: any,
    files?: any,
    appId?: any,
  ): Promise<UserFlowModel> {
    const defaultAppId = store.getState().dashboard.selectedOnboarding?.applicationId;
    const applicationId = appId || localStorage.getItem('applicationId') || defaultAppId;
    return this.request({
      method: 'PATCH',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
      data: this.buildFormData(data, files),
      headers: { 'Content-Type': 'application/json' },
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
      },
    });
  }

  public async updatePlainUserFlow(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    applicationId: string,
    data: any,
  ): Promise<any> {
    return this.request({
      method: 'PATCH',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/update-plain`,
      data,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  public async updateStepData(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    applicationId: string,
    data: any,
    files?: any,
  ): Promise<any> {
    return this.request({
      method: 'PATCH',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/step-data`,
      data: this.buildFormData(data, files),
      headers: { 'Content-Type': 'application/json' },
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
      },
    });
  }

  public async removeDocument(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    applicationId: string,
    data: any,
  ): Promise<any> {
    return this.request({
      method: 'PATCH',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        applicationId,
      )}/remove-document`,
      headers: { 'Content-Type': 'application/json' },
      data,
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
      },
    });
  }

  public async ooAdminCompleteStepForUser(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    data: any,
    applicationId: string,
  ): Promise<any> {
    const appId = applicationId || localStorage.getItem('applicationId') || '';
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        appId,
      )}/complete-step-for-user`,
      data,
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
      },
    });
  }

  public async ooAdminCompleteStepForUserBulk(clientOrganizationId: string, configId: string, data: any): Promise<any> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/complete-step-for-user-bulk`,
      data,
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
      },
    });
  }

  public async ooCompleteDocumentForSignatureStep(
    clientOrganizationId: string,
    configId: string,
    userId: string,
    data: any,
    applicationId?: string,
  ): Promise<any> {
    const appId = applicationId || localStorage.getItem('applicationId') || '';
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/${doubleEncodeURIComponent(
        appId,
      )}/complete-document-for-signature-step`,
      data,
      storeUpdate: {
        dispatch: store.dispatch,
        dataUpdateAction: oneOnboardingSlice.actions.setFlowData,
      },
    });
  }

  public async getControlItemConditionResult(data: any): Promise<any> {
    const userId = store.getState().authApp.userId;
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/check-item-condition`,
      data,
    });
  }

  public async completeRegistrationStep(): Promise<any> {
    const userId = store.getState().authApp.userId;
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/${userId}/steps/complete-registration-step`,
    });
  }

  public async getSelectedFeature(): Promise<any> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-feature/get-all-for-selected-configuration`,
    });
  }

  public async getSelectedFeatureMultipleOnboardings(clientOrganizationId: any, configurationId: any): Promise<any> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-feature/get-all-for-selected-configuration`,
    });
  }

  public getReferenceData(): Promise<Array<any>> {
    const clientOrganizationId = store.getState().oneOnboarding.clientOrganizationId;
    const configurationId = store.getState().oneOnboarding.selectedConfigurationId;

    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/reference-data/get-all-for-selected-configuration`,
    });
  }

  public async getOtherConfiguration(
    clientOrganizationId: string,
    selectedConfigurationId: string,
  ): Promise<Array<any>> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${selectedConfigurationId}/other-configuration/get-all-for-selected-configuration`,
    });
  }

  public async fetchUserMailHistory(
    userId: string,
    clientOrganizationId: string,
    configurationId: string,
  ): Promise<any[]> {
    return this.request({
      method: 'GET',
      url: `api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/mail-history/${userId}`,
    });
  }

  public async resend(
    clientOrganizationId: string,
    configurationId: string,
    mailTemplateId: string,
    mailId: string,
    id: string,
    applicationId: string,
  ): Promise<any> {
    return this.request({
      method: 'POST',
      url: `api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-mail/resend/${mailTemplateId}/${mailId}/${id}/${doubleEncodeURIComponent(
        applicationId,
      )}`,
    });
  }

  public async getJourneysByTenant(tenantId: number): Promise<OOConfiguration[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${tenantId}/selected-configuration/get-by-tenant`,
    });
  }

  public async getRequisitionsByTenant(tenantId: number): Promise<RequisitionModel[]> {
    return this.request({
      method: 'GET',
      url: `/api/requisition/`,
    });
  }

  public async getAllForDashboard(tenantId: number): Promise<OODashboardData> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${tenantId}/selected-configuration/get-all-for-dashboard`,
    });
  }

  public async assetGetPredefined(
    tenantId: number,
    target: string,
    name: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<any> {
    if (!tenantId || !target || !name) {
      throw new Error('Tenant id, target and name are mandatory');
    }
    if (target !== 'logo' && (!clientOrganizationId || !configurationId)) {
      throw new Error(`Mandatory params for target "${target}" not provided`);
    }
    let suffix = '';
    if (clientOrganizationId) {
      suffix = `/${clientOrganizationId}/${configurationId}`;
    }
    return this.request({
      responseType: 'arraybuffer',
      method: 'GET',
      url: `api/tenant/${tenantId}/asset-manager/predefined/${target}/${name}${suffix}`,
      returnFullResponse: true,
    });
  }

  public async assetUpload(tenantId: number, files: any[], path: string): Promise<any[]> {
    if (!path) {
      throw new Error('Path is mandatory');
    }
    if (path.length < 1) {
      throw new Error('Invalid path provided');
    }
    if (path.slice(-1) !== '/') {
      throw new Error('Path must be directory');
    }

    const formData = new FormData();
    formData.append('path', path);
    [...files].forEach((file) => {
      formData.append('files[]', file);
    });
    return this.request({
      method: 'POST',
      url: `api/tenant/${tenantId}/asset-manager/`,
      data: formData,
    });
  }

  public async assetUploadWithName(tenantId: number, file: any, path: string, name: string): Promise<any[]> {
    if (!path) {
      throw new Error('Path is mandatory');
    }

    if (path.length < 1) {
      throw new Error('Invalid path provided');
    }

    if (path.slice(-1) !== '/') {
      throw new Error('Path must be directory');
    }

    const formData = new FormData();

    formData.append('path', path);
    formData.append('name', name);

    [...file].forEach((file) => {
      formData.append('file', file);
    });

    return this.request({
      method: 'POST',
      url: `api/tenant/${tenantId}/asset-manager/upload-single`,
      data: formData,
    });
  }

  public async assetDownloadPredefined(
    tenantId: number,
    target: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<any> {
    if (!tenantId || !target) {
      throw new Error('Tenant id and target are mandatory');
    }
    if (target !== 'logo' && (!clientOrganizationId || !configurationId)) {
      throw new Error(`Mandatory params for target "${target}" not provided`);
    }
    let suffix = '';
    if (clientOrganizationId) {
      suffix = `/${clientOrganizationId}/${configurationId}`;
    }
    return this.request({
      responseType: 'arraybuffer',
      method: 'GET',
      url: `api/tenant/${tenantId}/asset-manager/predefined-all/${target}${suffix}`,
      returnFullResponse: true,
    });
  }

  public async assetDelete(tenantId: number, path: string): Promise<any[]> {
    if (!path) {
      throw new Error('Path is mandatory');
    }
    if (path.length < 1) {
      throw new Error('Invalid path provided');
    }
    if (path.slice(-1) === '/') {
      //throw new Error('Path must be file');
    }
    return this.request({
      method: 'DELETE',
      url: `api/tenant/${tenantId}/asset-manager/${encodeURIComponent(path)}`,
    });
  }

  public async assetDeletePredefined(
    tenantId: number,
    target: string,
    name: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<any[]> {
    if (!tenantId || !target || !name) {
      throw new Error('Tenant id, target and name are mandatory');
    }
    if (target !== 'logo' && (!clientOrganizationId || !configurationId)) {
      throw new Error(`Mandatory params for target "${target}" not provided`);
    }
    let suffix = '';
    if (clientOrganizationId) {
      suffix = `/${clientOrganizationId}/${configurationId}`;
    }
    return this.request({
      method: 'DELETE',
      url: `api/tenant/${tenantId}/asset-manager/predefined/${target}/${name}${suffix}`,
    });
  }

  // delete "selected" tables items for the config
  public async configChildrenDelete(
    tenantId: number,
    clientOrganizationId: string,
    configurationId: string,
  ): Promise<any[]> {
    return this.request({
      method: 'DELETE',
      url: `api/client-organization/${clientOrganizationId}/selected-configuration/delete-children/${configurationId}`,
    });
  }

  // delete the config
  public async configDelete(tenantId: number, clientOrganizationId: string, configurationId: string): Promise<any[]> {
    return this.request({
      method: 'DELETE',
      url: `api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}`,
    });
  }

  // delete the config
  public async configExport(tenantId: number, clientOrganizationId: string, configurationId: string): Promise<any> {
    return this.request({
      method: 'GET',
      url: `api/client-organization/${clientOrganizationId}/selected-configuration/export-configuration/${configurationId}`,
    });
  }

  public async getImportControl(clientOrganizationId: string, configurationId: string): Promise<ImportControl[]> {
    return this.request({
      method: 'GET',
      url: `api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/selected-import-control/get-all-for-selected-configuration`,
    });
  }

  public async logRemote(message: string, level: LoggerLevelEnum) {
    return this.request({
      method: 'POST',
      url: `api/log-remote/`,
      data: { message, level },
    });
  }

  public async checkCandidate(
    clientOrganizationId: string,
    configId: string,
    users: CheckUser[],
    selectAll: boolean,
    userFilters: { [key: string]: any },
    searchOnboardingEndpointVersion: number,
  ): Promise<{
    rejectEnabled: boolean;
    approveEnabled: boolean;
    hiringEnabled: boolean;
    hasHiringFeature: boolean;
    resetEnabled: boolean;
  }> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configId}/user-flow/check`,
      data: {
        users,
        selectAll,
        filters: userFilters,
        version: searchOnboardingEndpointVersion,
      },
    });
  }
  public async fetchBranches(): Promise<BranchModel[]> {
    return this.request({
      method: 'GET',
      url: `api/branch`,
    });
  }

  public async deleteBranch(brnachId: string): Promise<any> {
    return this.request({
      method: 'DELETE',
      url: `api/branch/${brnachId}`,
    });
  }

  public async invalidateBranch(brnachId: string): Promise<any> {
    return this.request({
      method: 'PATCH',
      url: `api/branch/${brnachId}`,
      data: { isValid: false },
    });
  }

  public async getIntegrationStateFaq(tenantId: number): Promise<IntegrationStateFaq[]> {
    return this.request({
      method: 'GET',
      url: `/api/tenant/${tenantId}/integration-state-faq`,
    });
  }

  public async fetchTranslation(tenantId: number, language: string, fileName: string): Promise<{ [key: string]: any }> {
    return this.request({
      method: 'GET',
      url: `/api/i18n/${tenantId}/${language}/${fileName}`,
    });
  }

  public async exportTranslations(tenantId: number, translationsCount: string): Promise<any> {
    return this.request({
      method: 'GET',
      url: `api/i18n/export-translation/${tenantId}?translationsCount=${translationsCount}`,
    });
  }

  public async importTranslations(formData: FormData, tenantId: number): Promise<any> {
    return this.request({
      method: 'POST',
      url: `api/i18n/import-translations/${tenantId}`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  public fetchCampaign = async (url: string): Promise<CampaignModel> => {
    return this.fetchSingle<any>(`/api/campaign/${url}`);
  };

  public async createCampaign(data: CampaignModel): Promise<any> {
    return this.request<any>({
      method: 'post',
      url: `/api/campaign/`,
      data,
    });
  }

  public async fetchCampaigns() {
    return this.fetchCollection<CampaignModel>(`/api/campaign/find-all`);
  }

  public async campaignImport(
    clientOrganizationId: string,
    configurationId: string,
    userId: string,
    campaignUrl: string,
  ): Promise<Partial<{ status: string; error: string }>> {
    return this.request<{ status: string; error: string }>({
      method: 'post',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/import/campaign-import/${userId}/${campaignUrl}`,
    });
  }

  public async invalidate(campaignUrl: string): Promise<Partial<{ status: string; error: string }>> {
    return this.request<{ status: string; error: string }>({
      method: 'post',
      url: `/api/campaign/invalidate/${campaignUrl}`,
    });
  }

  public async updateCampaign(tenantId: number, data: any): Promise<any> {
    return this.request({
      method: 'patch',
      url: `/api/campaign/${data.url}`,
      data,
    });
  }

  public async validateDocument(tenantId: number, documentId: string): Promise<any> {
    return this.request({
      method: 'POST',
      url: `/api/tenant/${tenantId}/document/${documentId}/validate`,
    });
  }

  public async fetchUploadLimitSize(
    clientOrganizationId: string | null,
    selectedConfigurationId: string | null,
  ): Promise<string> {
    const orgId =
      clientOrganizationId ?? store.getState().oneOnboarding.clientOrganizationId ?? getClientOrganizationId();
    const configId =
      selectedConfigurationId ?? store.getState().oneOnboarding.selectedConfigurationId ?? getSelectedConfigurationId();
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${orgId}/selected-configuration/${configId}/upload-size-limit`,
    });
  }

  public async fetchAllConfigurations(clientOrganizationId: string): Promise<Configuration[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration`,
    });
  }

  public async fetchAllConfigurationsForAllTenants(clientOrganizationId: string): Promise<Configuration[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/all`,
    });
  }

  public async getFrCitiesByZip(zipCode: string): Promise<any> {
    return this.request({
      method: 'GET',
      url: `/api/place/fr-cities-by-zip/${zipCode}`,
    });
  }

  public async getMagicLinksUrl(tenantId: number, email: string): Promise<string | undefined> {
    return this.request({
      method: 'GET',
      url: `/api/tenant/${tenantId}/user/get-magic-links-url/${email}`,
    });
  }

  public async getTokenHint(
    tenantId: number,
    email: string,
    clientOrganizationId?: string,
  ): Promise<string | undefined> {
    return this.request({
      method: 'GET',
      url:
        `/api/tenant/${tenantId}/user/get-token-hint/${email}` + (clientOrganizationId && '/' + clientOrganizationId),
    });
  }

  public async getRejectReasons(): Promise<string[]> {
    return this.request({
      method: 'GET',
      url: `/api/onboarding/get-reject-reasons`,
    });
  }

  public async getQuickFilters(tenantId: number, data: any): Promise<QuickFilter> {
    return this.request({
      method: 'POST',
      url: `/api/onboarding/${tenantId}/get-quick-filters`,
      data,
    });
  }

  public async getTranslationsByLanguage(language: string): Promise<TranslationResponseItem[]> {
    return this.request({
      method: 'GET',
      url: `/api/translations/${language}`,
    });
  }

  public async getTranslations(): Promise<TranslationResponseItem[]> {
    return this.request({
      method: 'GET',
      url: `/api/translations`,
    });
  }

  public async fetchAllDraftConfigurations(clientOrganizationId: string): Promise<DraftConfiguration[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/draft-configuration`,
    });
  }

  public async fetchAllDraftConfigurationsByTenantId(
    clientOrganizationId: string,
    tenantId: number,
  ): Promise<DraftConfiguration[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/draft-configuration/${tenantId}`,
    });
  }

  public async createDraftConfiguration(
    clientOrganizationId: string,
    data: Record<string, any>,
  ): Promise<DraftConfiguration> {
    return this.request({
      method: 'POST',
      url: `/api/client-organization/${clientOrganizationId}/draft-configuration`,
      data,
      headers: {
        Accept: 'application/json',
      },
    });
  }
  public async updateDraftConfiguration(
    clientOrganizationId: string,
    id: string,
    data: Record<string, any>,
  ): Promise<DraftConfiguration> {
    return this.request({
      method: 'PATCH',
      url: `/api/client-organization/${clientOrganizationId}/draft-configuration/${id}`,
      data,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  public async deleteDraftConfiguration(id: string): Promise<DraftConfiguration> {
    return this.request({
      method: 'DELETE',
      url: `/api/client-organization/x/draft-configuration/${id}`,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  public async hasPdfExport(clientOrganizationId: string, configurationId: string): Promise<boolean | undefined> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/has-pdf-export`,
    });
  }

  public async getUserFlowHistory(clientOrganizationId: string, userId: string): Promise<UserFlowHistory[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/user-flow-history/get-all-for-user-with-relations/${userId}`,
    });
  }

  public async getUserActionHistory(
    tenantId: number,
    userId: string,
    applicationId: string,
    clientOrganizationId: string,
    configurationId: string,
  ): Promise<UserActionHistory[]> {
    return this.request({
      method: 'GET',
      url: `/api/client-organization/${clientOrganizationId}/selected-configuration/${configurationId}/user-action-history/${userId}/${applicationId}/get-all-for-user-with-relations`,
    });
  }

  public async createTelemetryAuthenticated(
    tenantId: number,
    eventType: EventType,
    clientOrganizationId: string,
    configurationId: string,
    applicationId: string,
    targetName?: TargetName,
    candidateId?: string,
  ): Promise<any> {
    return this.request({
      method: 'POST',
      url: `/api/telemetryEvents/create-authenticated`,
      data: {
        tenantId,
        eventType,
        clientOrganizationId,
        configurationId,
        applicationId,
        targetName,
        candidateId,
      },
    });
  }

  public async createTelemetryUnauthenticated(
    tenantId: number,
    eventType: EventType,
    clientOrganizationId: string,
    configurationId: string,
    applicationId: string,
    actor: TelemetryActor,
    email?: string,
    targetName?: TargetName,
    candidateId?: string | null,
  ): Promise<any> {
    return this.request({
      method: 'POST',
      url: `/api/telemetryEvents/create-unauthenticated`,
      data: {
        tenantId,
        eventType,
        clientOrganizationId,
        configurationId,
        applicationId,
        email,
        targetName,
        actor,
        candidateId,
      },
    });
  }

  public async getTelemetryEvents(tenantId: number, data: TelemetrySearchParameters): Promise<TelemetryList> {
    return this.request({
      method: 'GET',
      url: `/api/telemetryEvents/get-telemetry-events?page=${data.page}&limit=${data.limit}&candidateId=${data.candidateId}`,
    });
  }

  public async getTelemetryStat(
    dateFrom: string,
    dateTo: string,
    events: string[],
    clientOrganizationId?: string,
  ): Promise<TelemetryStat[]> {
    return this.request({
      method: 'POST',
      url: `/api/telemetryEvents/get-telemetry-stat`,
      data: {
        dateFrom,
        dateTo,
        events,
        clientOrganizationId,
      },
    });
  }

  public async searchControlByName(
    featureName: string,
    stepName: string,
    type: SelfServiceItemType,
    searchQuery: string,
  ): Promise<SelfServiceSearchResult[]> {
    return this.request({
      method: 'GET',
      url: `/api/self-service-library-item/search?featureName=${featureName}&stepName=${stepName}&type=${type}&searchString=${searchQuery}`,
    });
  }

  public async getControlByName(id: string, controlName: string): Promise<SelfServiceLibraryItemResponse> {
    return this.request({
      method: 'GET',
      url: `/api/selfServiceLibrary/get-library-items-by-name/${id}/${controlName}`,
    });
  }
}
