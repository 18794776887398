import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useFlowWrapper } from '@features/oneOnboarding/hooks/useFlowWrapper';
import { editableCondition } from '@features/oneOnboarding/hooks/useRenderCondition';
import { Box, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material';

import { useT } from '../../hooks/useT';
import { OOSelectorCheckboxModelInterface } from '../../interfaces';
import { FormControlLoader } from '../FormControlLoader';

interface RadioButtonComponentProps {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  disable?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonComponent: FC<RadioButtonComponentProps> = ({ label, value, name, checked, disable, onChange }) => {
  const id = `${name}_${value}`;

  return (
    <div className="tag-ds radio-container">
      <input id={id} type="radio" value={value} name={name} checked={checked} onChange={onChange} disabled={!disable} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export const BooleanRadio: React.VoidFunctionComponent<OOSelectorCheckboxModelInterface> = (props) => {
  const isRecruiter = window.location.pathname.includes('oo/recruiter');
  const { t, translationsLoading } = useT('entry', 'candidate_recruiter', 'approval', 'hiring');
  const formik = useFormikContext<Record<string, any>>();
  let prefix = props.label.split('.')[0].toLowerCase();
  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === 'true') {
      formik.setFieldValue(props.name, true);
    } else {
      formik.setFieldValue(props.name, false);
    }
  };
  const validationKey = props.name;
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);
  const { error } = fieldMeta;

  const data = [
    { value: true, label: t('candidate_recruiter:GENERAL.GENERIC.yes') },
    { value: false, label: t('candidate_recruiter:GENERAL.GENERIC.no') },
  ];

  const flowWrapper = useFlowWrapper();
  const isEditable =
    (isRecruiter ? props.isEditableRecruiter : props.isEditableCandidate) &&
    (editableCondition(props, flowWrapper, formik.values) ?? true);

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  return (
    <FormControl variant="outlined">
      {t(`${prefix}:${props.label}`) && (
        <Box pb={1}>
          <FormLabel>{`${t(`${prefix}:${props.label}`)} ${props.isMandatory ? '*' : ''}`}</FormLabel>
        </Box>
      )}
      <RadioGroup name={validationKey} value={fieldProps.value || ''}>
        {data?.map((option, index) => (
          <RadioButtonComponent
            onChange={handleChange}
            checked={fieldProps.value === option.value}
            name={validationKey}
            value={option.value ? 'true' : 'false'}
            label={t(option.label)}
            key={index}
            disable={isEditable}
          />
        ))}
      </RadioGroup>
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormControl>
  );
};
