import React, { useState } from 'react';
import clsx from 'clsx';
import { Editor } from 'slate';

import controlsStyle from '../../Controls.module.scss';
import style from '../RichTextEditor.module.scss';

interface Props {
  editor: any;
  icon: string;
  options: { label: string; tag: string; style: string; optionIcon?: string }[];
  disabled: boolean;
}

const TextStyle: React.FC<Props> = ({ editor, icon, options, disabled }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleLink = () => {
    setIsActive((state) => !state);
  };

  const isMarkActive = (editor: any) => {
    const marks: any = Editor.marks(editor);
    const tags = options.map((x) => x.tag);

    if (!marks) return { active: false, activeTag: '' };

    let result = { active: false, activeTag: '' };

    Object.keys(marks).forEach((x) => {
      result = { active: tags.includes(x), activeTag: Object.keys(marks)[0] };
    });

    return result;
  };

  const toggleMark = (editor: any, format: string) => {
    const isActive = isMarkActive(editor).active;
    const activeTag = isMarkActive(editor).activeTag;

    if (isActive) {
      Editor.removeMark(editor, activeTag);
      if (activeTag !== format) {
        Editor.addMark(editor, format, true);
      }
      setIsActive(false);
    } else {
      Editor.addMark(editor, format, true);
      setIsActive(false);
    }
  };

  return (
    <span
      className={`${style.editorOptions__item} ${
        isActive || isMarkActive(editor).active ? style.editorOptions__itemActive : ''
      } ${disabled ? controlsStyle['control--disabled'] : ''}`}
      onClick={toggleLink}
      style={{ position: 'relative' }}
    >
      <span className="material-icons">{icon}</span>
      {isActive && (
        <div className={style.textStyle__dropdown}>
          {options.map((option, i) => {
            return (
              <span
                key={i}
                className={clsx(style.textStyle__item, option.style)}
                onMouseDown={(e) => {
                  toggleMark(editor, option.tag);
                }}
                style={{ backgroundColor: option.tag === isMarkActive(editor).activeTag ? '#CCF2FF' : 'inherit' }}
              >
                {option.optionIcon && (
                  <span className={clsx('material-icons', style['textStyle__item-icon'])}>{option.optionIcon}</span>
                )}{' '}
                {option.label}
              </span>
            );
          })}
        </div>
      )}
    </span>
  );
};

export default TextStyle;
