import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import { Component } from '@features/oneOnboarding/interfaces';
import { findDifference } from '@features/selfService/helpers/commonFunctions';
import { RootState } from '@store/rootReducer';
import { getPath, handleNewPageControl } from '@store/slices/selfService.slice';
import { snackbarSlice } from '@store/slices/snackbar';

import { getInitialTranslationValues, handleTranslationSubmit } from '../../../../../helpers/renderControlHelper';
import VersionPicker from '../../other/VersionPicker/VersionPicker';
import Controls from '../../renderControls/Controls';
import NewControlSelector from './NewControlSelector';
import StaticComponent from './StaticComponent';
import {
  BooleanTemplate,
  DatepickerTemplate,
  HeadingTemplate,
  HtmlTemplate,
  MultilineTextfieldTemplate,
  MultipleSelectTemplate,
  SingleSelectTemplate,
  StaticTextTemplate,
  TextfieldTemplate,
} from './templates';

import styles from '../../../ConfigurationSetup.module.scss';

interface Props {
  control: any;
  handleClose: (callback?: () => void) => void;
  libraryItem: null | { name: string; id: string };
  handleDifferentVersion: (control: any) => void;
}

const PageControls: React.FC<Props> = ({ control, handleClose, libraryItem, handleDifferentVersion }) => {
  const [initialValues, setInitialValues] = useState<any>({
    component: '',
  });
  const [isEdit, setIsEdit] = useState(false);

  const formikRef = useRef<any>();
  const { t } = useTranslation(['ENTRY', 'control-items']);
  const dispatch = useDispatch();
  const { selectedConfiguration, selectedFeature } = useSelector((store: RootState) => store.selfService);
  const { isViewOnly } = useContext(ViewOnlyContext);

  useEffect(() => {
    if (control.name.length > 0) {
      setIsEdit(true);
    }
  }, [control]);

  const lookup: any = useMemo(() => {
    return {
      [Component.Datepicker]: DatepickerTemplate,
      [Component.Heading]: HeadingTemplate,
      [Component.Html]: HtmlTemplate,
      [Component.Textfield]: TextfieldTemplate,
      [Component.MultilineTextfield]: MultilineTextfieldTemplate,
      SingleSelect: SingleSelectTemplate,
      [Component.Select]: SingleSelectTemplate,
      RadioGroup: SingleSelectTemplate,
      [Component.Autocomplete]: SingleSelectTemplate,
      MultipleSelect: MultipleSelectTemplate,
      [Component.CheckboxMultiSelect]: MultipleSelectTemplate,
      Boolean: BooleanTemplate,
      [Component.Radio]: BooleanTemplate,
      [Component.Checkbox]: BooleanTemplate,
      [Component.Consent]: BooleanTemplate,
      [Component.Switch]: BooleanTemplate,
      [Component.StaticText]: StaticTextTemplate,
    };
  }, []);

  useEffect(() => {
    const requiredConverting = control.name && lookup[control.component]?.converterForUi;
    const controlHeaders = selectedConfiguration?.controlHeaders.find((x) => x.name === control.controlHeaderName);

    if (!selectedConfiguration) return;

    const withTranslations = (initialValues: any) =>
      getInitialTranslationValues(initialValues, control, selectedConfiguration, t);

    const withControlHeaders = (initialValues: any) => {
      if (!controlHeaders) return initialValues;

      return { ...initialValues, selectOptions: controlHeaders.items };
    };

    const withInitialValues = (initialValues: any) => {
      return { ...lookup[control.component].initialValues, ...initialValues };
    };

    if (requiredConverting) {
      setInitialValues(
        withTranslations(withInitialValues(withControlHeaders(lookup[control.component].converterForUi(control)))),
      );
      return;
    }

    if (control.name) {
      setInitialValues(withInitialValues(withTranslations(withControlHeaders(control))));
      return;
    }
  }, [
    control,
    lookup,
    selectedConfiguration?.controlHeaders,
    t,
    selectedConfiguration?.languages.supported,
    selectedConfiguration,
  ]);

  // User for creating new controls
  const enabledControls = Object.keys(lookup).filter(
    (x) =>
      ![
        'Radio',
        'Select',
        'Autocomplete',
        'RadioGroup',
        'Checkbox',
        'Consent',
        'Switch',
        'MultilineTextfield',
        'StaticText',
        'MultiSelectWithCheckbox',
        'CheckboxMultiSelect',
      ].includes(x),
  );

  const isNameDuplicate = (name: string) => {
    if (!selectedConfiguration) return;

    const getAllControlsForCurrentPage = getPath('page', selectedConfiguration, {
      featureName: selectedFeature?.featureName || '',
      stepName: selectedFeature?.stepName || '',
      pageName: selectedFeature?.pageName || '',
    });
    const controlIndex = getAllControlsForCurrentPage.controls.findIndex((x: any) => x.name === name);

    return controlIndex !== -1;
  };

  const onSubmit = (values: any) => {
    if (isNameDuplicate(values.name) && !isEdit) {
      dispatch(
        snackbarSlice.actions.showError({
          message: `Control with name ${values.name} already exists`,
        }),
      );

      throw new Error(`Control with name ${values.name} already exists`);
    }

    let mergeVisibleAndHidden = { ...lookup[values.component].hidden, ...values };

    if (lookup[values.component].convertForConfiguration) {
      mergeVisibleAndHidden = lookup[values.component].convertForConfiguration(mergeVisibleAndHidden);
    }

    handleTranslationSubmit(initialValues, values, dispatch, control);

    if (lookup[values.component].hideForConfiguration) {
      lookup[values.component].hideForConfiguration.forEach((x: string) => {
        delete mergeVisibleAndHidden[x];
      });
    }

    mergeVisibleAndHidden['change'] = findDifference(values, initialValues);

    dispatch(handleNewPageControl(mergeVisibleAndHidden));
  };

  const isNewControl = initialValues.component === '';
  const isReadOnlyControl = lookup[initialValues.component]?.isReadOnly;

  const resetInitialValues = (component: string) => {
    setInitialValues((state: any) => ({ ...state, ...lookup[component].initialValues }));
  };

  return (
    <div className="tag-ds">
      <div className={styles.controlDetails__heading}>
        <div className={styles.controlDetails__name}>
          <div
            className="material-icons"
            onClick={() => {
              if (formikRef.current.dirty) {
                const modifiedForm = formikRef.current.values;
                handleClose(() => onSubmit(modifiedForm));
              } else {
                handleClose();
              }
            }}
            style={{ cursor: 'pointer', marginRight: 20 }}
          >
            arrow_back
          </div>
          {isNewControl ? (
            <span className="large">Select Field Type</span>
          ) : (
            <span className="large">{initialValues?.componentName || initialValues?.component} properties</span>
          )}
        </div>
      </div>

      {libraryItem && <VersionPicker libraryItem={libraryItem} selectVersionHandler={handleDifferentVersion} />}

      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        validateOnMount
        validateOnChange
        onSubmit={(values) => {
          onSubmit(values);
          handleClose();
        }}
        initialValues={initialValues}
        validationSchema={lookup[initialValues.component]?.validation ?? null}
      >
        {(props: FormikProps<any>) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Field name="component">
                {(fieldProps: FieldProps) => {
                  return (
                    isNewControl && (
                      <NewControlSelector
                        enabledControls={enabledControls}
                        onSelect={(selectedComponent) => {
                          props.setFieldValue('component', selectedComponent);

                          setInitialValues((state: any) => ({ ...state, ...lookup[selectedComponent].initialValues }));
                        }}
                      />
                    )
                  );
                }}
              </Field>
              {props.values.component &&
                lookup[props.values.component]?.static?.map((s: { type: string; value: string }, index: number) => {
                  return <StaticComponent key={index} item={s} />;
                })}
              {props.values.component &&
                lookup[props.values.component].visible.map((x: string) => {
                  return (
                    <Field name={x} key={x}>
                      {(fieldProps: any) => {
                        return (
                          <Controls
                            fieldProps={fieldProps}
                            resetInitialValues={resetInitialValues}
                            isViewOnly={isViewOnly}
                          />
                        );
                      }}
                    </Field>
                  );
                })}

              {(!isNewControl || !isViewOnly) && (
                <button type="submit" disabled={isReadOnlyControl || isViewOnly}>
                  Save
                </button>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PageControls;
