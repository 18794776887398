import { AamBackendApi } from 'src/libs/aamBackendApi';
import { useInjection } from '@context/inversify-context-provider';
import { Utils } from '@helpers/utils';
import {
  AuthenticatedTelemetryHookPayload,
  EventType,
  TargetName,
  TelemetryActor,
  UnauthenticatedTelemetryHookPayload,
} from '@models/telemetry.model';

let lastRecord: { eventType: EventType; targetName: TargetName | null; time: number } | null = null;

const useTelemetry = () => {
  const isDuplicateCall = (eventType: EventType, targetName?: TargetName) => {
    const currentTime = Date.now();

    if (lastRecord) {
      const isWithinTimespan = currentTime - lastRecord.time <= 10000;

      if (isWithinTimespan && eventType === lastRecord.eventType && targetName === lastRecord.targetName) {
        return true;
      }
    }

    lastRecord = {
      eventType: eventType,
      targetName: targetName || null,
      time: currentTime,
    };

    return false;
  };

  const aamBackendApi = useInjection(AamBackendApi);

  const createTelemetryUnauthenticated = async (payload: UnauthenticatedTelemetryHookPayload) => {
    const {
      eventType,
      targetName,
      applicationId,
      clientOrganizationId,
      configurationId,
      email,
      tenantId,
      actor,
      candidateId,
    } = payload;

    const isDuplicate = isDuplicateCall(eventType, targetName);
    const validParameters = applicationId && clientOrganizationId && configurationId && (candidateId || email);

    if (isDuplicate || !validParameters) return;

    await aamBackendApi.createTelemetryUnauthenticated(
      tenantId,
      eventType,
      clientOrganizationId,
      configurationId,
      applicationId,
      actor,
      email,
      targetName,
      candidateId,
    );
  };

  const createTelemetryAuthenticated = async (payload: AuthenticatedTelemetryHookPayload) => {
    const {
      eventType,
      targetName,
      applicationId,
      clientOrganizationId,
      configurationId,
      tenantId,
      candidateId,
      actor,
    } = payload;
    const isDuplicate = isDuplicateCall(eventType, targetName);

    if (isDuplicate) {
      Utils.consoleLog(`Duplicate call: ${eventType} - ${targetName}`);
      return;
    }

    if (actor === TelemetryActor.CANDIDATE) {
      const validParameters = applicationId && clientOrganizationId && configurationId;

      if (!validParameters) {
        Utils.consoleError('Missing parameter for sending telemetry event');
        return;
      }

      await aamBackendApi.createTelemetryAuthenticated(
        tenantId,
        eventType,
        clientOrganizationId,
        configurationId,
        applicationId,
        targetName,
      );
    }

    if (actor === TelemetryActor.RECRUITER) {
      const validParameters = applicationId && clientOrganizationId && configurationId && candidateId;

      if (!validParameters) {
        Utils.consoleError('Missing parameter for sending telemetry event');
        return;
      }

      await aamBackendApi.createTelemetryAuthenticated(
        tenantId,
        eventType,
        clientOrganizationId,
        configurationId,
        applicationId,
        targetName,
        candidateId,
      );
    }
  };

  return { createTelemetryUnauthenticated, createTelemetryAuthenticated };
};

export default useTelemetry;
