import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components/shared/Loader/Loader';
import { ClientOrganizationModel } from '@models/clientOrganization.model';

interface ClientOrganizationModelExtended extends ClientOrganizationModel {
  displayConfigurationName: string;
  clientOrganizationId: string;
  recruiterName: string;
}

interface ConfigurationListProps {
  configurationList: ClientOrganizationModelExtended[];
  selectedConfiguration?: string | null;
  handleConfigurationChange: (value: string) => void;
  isLoading: boolean;
}

export const ConfigurationList: React.FC<ConfigurationListProps> = ({
  configurationList,
  selectedConfiguration,
  handleConfigurationChange,
  isLoading,
}) => {
  const { t } = useTranslation(['candidate_recruiter']);

  const getValueFromi18n = (transPath: string, key: string) => {
    const i18nstring = `${transPath}.${key}`;
    return i18n.exists(i18nstring) ? t(i18nstring) : key;
  };

  const getOptionText = (displayName: string, recruiterName: string): string =>
    [getValueFromi18n('recruiter:CLIENT_NAME', displayName), getValueFromi18n('recruiter:CLIENT_NAME', recruiterName)]
      .filter((x) => !!x)
      .join(' - ');

  return (
    <>
      <fieldset className="tag-ds checkbox-group-container ">
        <p className="tag-ds  subtitle">{t('GENERAL.GENERIC.selectConfiguration')}</p>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="checkbox-group">
            {configurationList.map((x: any) => {
              const optionText = getOptionText(x.displayConfigurationName, x.recruiterName);
              const value = JSON.stringify({ clientOrganizationId: x.clientOrganizationId, configurationId: x.id });

              return (
                <div
                  className="checkbox-container feature-admin-import__configuration-item"
                  onClick={() => handleConfigurationChange(value)}
                  key={x.id + x.clientOrganizationId}
                >
                  <input
                    id={value}
                    type="radio"
                    checked={selectedConfiguration === value}
                    onChange={() => handleConfigurationChange(value)}
                  />
                  <label htmlFor="checkbox3">{optionText}</label>
                </div>
              );
            })}
          </div>
        )}
      </fieldset>
    </>
  );
};
