import React, { FC, useState } from 'react';
import { useStore } from 'react-redux';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { getBlobData } from '@helpers/fileUpload.helper';
import { Info, InfoOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { useT } from '../../hooks/useT';
import { OODocumentPartModel } from '../../models/DocumentPartModel';

const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
interface ExampleDocumentProps {
  state: any;
  previewImageUrl: any;
  setPreviewImageUrl: (previewImageUrl: any) => void;
  part: OODocumentPartModel;
}
export const ExampleDocument: FC<ExampleDocumentProps> = ({ part, setPreviewImageUrl, previewImageUrl, state }) => {
  const { t } = useT('documents', 'entry', 'candidate_recruiter');
  const aamBackendApi = useInjection(AamBackendApi);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pdfURL, setPdfURL] = useState<HTMLAnchorElement>();
  const [revokedUrl, setRevokedUrl] = useState<string>('');
  const store = useStore<RootState>();
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const downloadExampleImage = async (partName: string, exampleName: string) => {
    if (!exampleName) {
      return;
    }
    setIsLoading(true);
    try {
      const { oneOnboarding, tenant } = store.getState();
      const { data, headers } = await aamBackendApi.downloadExample(
        tenant.tenantId,
        oneOnboarding.clientOrganizationId!,
        oneOnboarding.selectedConfigurationId!,
        exampleName ?? '',
      );

      const blobData = getBlobData(data, headers['content-type'], exampleName);
      const imagesUrls = { ...previewImageUrl };
      imagesUrls[partName] = blobData?.imageUrl;
      setPdfURL(blobData?.link);
      setRevokedUrl(blobData?.imageUrl);
      imagesUrls.isImage = blobData?.isImage;
      setPreviewImageUrl(imagesUrls);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Grid item xs={12}>
      {part?.sampleUrl ? (
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              setOpenPreview(!openPreview);
              if (!!part?.sampleUrl && !openPreview) {
                downloadExampleImage(part.name, part?.sampleUrl);
              }
            }}
            style={{ cursor: 'pointer' }}
          >
            <Box mx={1} mr="10px">
              {openPreview ? <Info color="primary" /> : <InfoOutlined color="primary" />}
            </Box>
            <Typography>{t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.documentExample')}</Typography>
          </Box>
        </Grid>
      ) : (
        <></>
      )}
      {/* Preview for sample image */}
      {openPreview && part?.sampleUrl && previewImageUrl[part.name] && previewImageUrl.isImage ? (
        !isLoading ? (
          <Box p={2}>
            <img src={previewImageUrl[part.name]} alt="example preview 121" height="200px" />
          </Box>
        ) : (
          <Loader />
        )
      ) : openPreview && !previewImageUrl.isImage ? (
        <div>
          {!isMobileBrowser() ? (
            <iframe title="rmpl" width="100%" height="350" src={previewImageUrl[part.name]} />
          ) : (
            <Box pt={1}>
              <Button
                variant="contained"
                className="button-primary"
                onClick={() => {
                  pdfURL?.click();
                  URL.revokeObjectURL(revokedUrl);
                }}
              >
                {t('documents:DOCUMENTS.DOCUMENT_UPLOAD.STUDENT_EMPLOYMENT.student-employ.front.download')}
              </Button>
            </Box>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Preview for uploaded image */}
      {previewImageUrl[part.name] && state[part.name]?.data?.toString() === previewImageUrl[part.name] && (
        <Box
          p={2}
          onClick={() => {
            previewImageUrl[part.name] = '';
            setPreviewImageUrl({ ...previewImageUrl });
          }}
        >
          <img src={previewImageUrl[part.name]} alt="example preview" style={{ cursor: 'pointer', height: '200px' }} />
        </Box>
      )}
    </Grid>
  );
};
