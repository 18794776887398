import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { translationHelper } from '@features/selfService/helpers/translationHelper';

import style from './SingleSelectOptions.module.scss';

interface Props {
  fromBackend: boolean;
  values: any[];
}

const PreviewList: React.FC<Props> = ({ fromBackend, values }) => {
  const [viewAllActive, setViewAllActive] = useState(false);

  const { t, i18n } = useTranslation(['entry', 'control-items']);

  const getTranslation = (key: string) => translationHelper(key, i18n, t);

  const valueSliced = viewAllActive ? values : values.slice(0, 5);

  return (
    <div className={style.selectedOptionsList__container}>
      {(valueSliced ?? []).map((x, i) => {
        if (fromBackend) {
          return (
            <div key={i} className={style.selectedOptionsList}>
              <span>{getTranslation(x.label)}</span>
            </div>
          );
        }

        return (
          <div key={i} className={style.selectedOptionsList}>
            <span>{getTranslation(x.value)}</span>
          </div>
        );
      })}
      {values.length > 5 && (
        <span className={clsx('big', style.fullListBtn)} onClick={() => setViewAllActive((state) => !state)}>
          FULL LIST
          <span className="material-icons" style={{ fontSize: 24 }}>
            keyboard_arrow_down
          </span>
        </span>
      )}
    </div>
  );
};

export default PreviewList;
