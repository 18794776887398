import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector, useStore } from 'react-redux';
import { Footer } from '@components/app/Footer/Footer';
import { Header } from '@components/app/Header/Header';
import { ModalPrompt } from '@components/shared/Modal/Modal';
import { Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { setUnsavedChangesExist } from '@store/slices/oneOnboarding.slice';

import { OONavigationContext } from '../../context/NavigationContext';
import { CustomLogo } from '../../controls/CustomLogo';
import StepsOverview from '../../controls/StepsOverview';
import { useT } from '../../hooks/useT';

import styles from './CandidatePageLayout.module.scss';

interface CandidatePageLayoutProps {
  children?: React.ReactNode;
  hideOverview?: boolean;
  overrideBack?: () => void;
  enableUnsaveChangesDialog?: boolean;
}

export const CandidatePageLayout: FC<CandidatePageLayoutProps> = ({
  children,
  hideOverview,
  overrideBack,
  enableUnsaveChangesDialog = true,
}) => {
  const { t } = useT('candidate_recruiter');
  const navigationContext = useContext(OONavigationContext);
  const logo = useSelector((state: RootState) => state.oneOnboarding.logo);
  const store = useStore<RootState>();
  const { unsavedChangesExist } = useSelector((state: RootState) => state.oneOnboarding);
  const [unsavedChangesPopup, setUnsavedChangesPopup] = useState(false);
  const [onModalConfirmHandler, setOnModalConfirmHandler] = useState<any>();
  const [onModalCloseHandler, setOnModalCloseHandler] = useState<any>();

  const beforeUnload = useCallback(
    (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      return unsavedChangesExist ? (ev.returnValue = '') : undefined;
    },
    [unsavedChangesExist],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnload);
    return () => window.removeEventListener('beforeunload', beforeUnload);
  }, [beforeUnload]);

  const onBackPress = async () => {
    const confirmed = await showUnsavedChangesConfirmationModal();
    if (!confirmed) {
      return;
    }

    if (overrideBack) {
      overrideBack();
    } else {
      navigationContext?.back?.();
    }
  };

  const showUnsavedChangesConfirmationModal = useCallback(async (): Promise<boolean> => {
    if (!unsavedChangesExist || !enableUnsaveChangesDialog) {
      return true;
    }

    setUnsavedChangesPopup(true);
    return new Promise((resolve) => {
      setOnModalConfirmHandler(() => {
        return () => {
          setUnsavedChangesPopup(false);
          store.dispatch(setUnsavedChangesExist(false));
          resolve(true);
        };
      });

      setOnModalCloseHandler(() => {
        return () => {
          setUnsavedChangesPopup(false);
          resolve(false);
        };
      });
    });
  }, [unsavedChangesExist, store, enableUnsaveChangesDialog]);

  return (
    <div
      className={clsx('form-driver', styles['candidatePageLayout'], {
        [styles.hideOverview]: hideOverview === true,
      })}
    >
      <Header
        onBackPress={onBackPress}
        isAuthenticated={true}
        showUnsavedChangesConfirmationModal={showUnsavedChangesConfirmationModal}
        {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }}
      />
      <Grid
        container
        className={clsx('form-driver__container', styles['candidatePageLayoutContainer'], {
          [styles.hideOverview]: hideOverview === true,
        })}
      >
        {!hideOverview && (
          <Grid item className="form-driver__step-overview">
            <StepsOverview showUnsavedChangesConfirmationModal={showUnsavedChangesConfirmationModal} />
          </Grid>
        )}

        <Grid
          item
          className={clsx('form-driver__form-content', styles['candidatePageLayoutContent'], {
            [styles.hideOverview]: hideOverview === true,
          })}
        >
          <React.Fragment>{children}</React.Fragment>
        </Grid>
      </Grid>

      {unsavedChangesPopup && (
        <ModalPrompt
          isOpen={unsavedChangesPopup}
          onClose={onModalCloseHandler}
          onConfirm={onModalConfirmHandler}
          title={t('candidate_recruiter:GENERAL.GENERIC.unsavedChangesWarningTitle')}
          content={t('candidate_recruiter:GENERAL.GENERIC.unsavedChangesWarningContent')}
          cancelLabel={t('candidate_recruiter:GENERAL.GENERIC.cancel')}
          confirmLabel={t('candidate_recruiter:GENERAL.GENERIC.yes')}
        />
      )}
      <Footer />
    </div>
  );
};
