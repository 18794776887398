import React from 'react';

import styles from './ConfigurationActions.module.scss';

interface Props {
  sectionName: 'Added' | 'Removed' | 'Other changes';
  options?: { change: any; changeList: string[] }[];
}

const ActivitySection: React.FC<Props> = ({ sectionName, options }) => {
  const isChangeListVisible = sectionName === 'Other changes';

  return (
    <div className={styles.activityHistory__item}>
      <span className="small-title">{sectionName}:</span>
      {options?.map((x, i) => {
        return (
          <div key={i}>
            {x.change.name ?? 'Unknown name'}
            {isChangeListVisible &&
              x.changeList.map((y, i) => {
                if (i === 0) {
                  return `: ${y}`;
                }

                if (i === x.changeList.length) {
                  return `${y}`;
                }

                return `, ${y}`;
              })}
          </div>
        );
      })}
    </div>
  );
};

export default ActivitySection;
