import React from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFetchSelectedConfiguration, useGetRequisitionsByTenant } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { OnboardingStatus } from '@models/onboarding.model';
import { RootState } from '@store/rootReducer';

import styles from './CandidateInfo.module.scss';

export interface CandidateJourney {
  id: string;
  applicationId: string;
  requisitionId: string;
  siteCode: string;
}

interface Props {
  userData: {
    email: string | null;
    phoneNumber: string;
    journey: CandidateJourney;
    address: string;
    client: string;
    clientDisplayName: string;
    onboardingApplicationId?: string;
    candidateJourneys: CandidateJourney[];
    selectedCandidateJourney: {
      siteCode?: string;
      applicationId?: string;
      requisitionId?: string;
    } | null;
    onboardingStatus?: OnboardingStatus;
    selectedCandidateJourneyId: string | null;
  };
  onCandidateJourneySelect: (candidateJourney: string) => void;
}

const InformationList: React.FC<Props> = ({ userData, onCandidateJourneySelect }) => {
  const { clientOrganizationId, configurationId } = useDecodedParams();

  const { t } = useTranslation(['recruiter', 'control-items', 'integration-state-faq']);
  const getTranslation = (translationPath: string, key: string) => {
    const i18nString = `${translationPath}.${key}`;
    return i18next.exists(i18nString) ? t(i18nString) : key;
  };
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { data: requisitions } = useGetRequisitionsByTenant(tenantId);
  const { data: selectedConfiguration } = useFetchSelectedConfiguration(clientOrganizationId, configurationId);

  const {
    email,
    phoneNumber,
    address,
    clientDisplayName,
    journey,
    onboardingApplicationId,
    candidateJourneys,
    selectedCandidateJourney,
    onboardingStatus,
    selectedCandidateJourneyId,
  } = userData;

  const hireEvaluation = (journey: CandidateJourney, selectedCandidateJourneyId: string) => {
    const hiredStatus = [OnboardingStatus.hired, OnboardingStatus.readyForHire, OnboardingStatus.matched];
    const isHired = onboardingStatus ? hiredStatus.includes(onboardingStatus) : false;
    let hiredTag = '';
    let disabledJourney = false;
    if (isHired) {
      if (journey.id === selectedCandidateJourneyId) {
        hiredTag = `-- ${t(`recruiter:HIRING.HIRE.hired`)}`;
      } else {
        disabledJourney = true;
      }
    }

    return { hiredTag, disabledJourney };
  };

  return (
    <ul className={clsx('list-icon_small', styles.infoList)}>
      <li className={styles.infoList__item}>
        <div className={styles.infoList__itemDescription}>
          <span className={clsx('material-icons', styles.infoList__icon)}>alternate_email</span>
          <span className="small">{t(`recruiter:GENERAL.IMPORT.headerEmail`)}</span>
        </div>
        <div className={styles.infoList__itemContent}>{email}</div>
      </li>
      <li className={styles.infoList__item}>
        <div className={styles.infoList__itemDescription}>
          <span className={clsx('material-icons', styles.infoList__icon)}>phone</span>
          <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.phone`)}</span>
        </div>
        <div className={styles.infoList__itemContent}>{phoneNumber}</div>
      </li>
      <li className={styles.infoList__item}>
        <div className={styles.infoList__itemDescription}>
          <span className={clsx('material-icons', styles.infoList__icon)}>link</span>
          <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.address`)}</span>
        </div>
        <div className={styles.infoList__itemContent}>{address}</div>
      </li>
      <li className={styles.infoList__item}>
        <div className={styles.infoList__itemDescription}>
          <span className={clsx('material-icons', styles.infoList__icon)}>business</span>
          <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.client`)}</span>
        </div>
        <div className={styles.infoList__itemContent}>{getTranslation(`recruiter:CLIENT_NAME`, clientDisplayName)}</div>
      </li>
      <li className={styles.infoList__item}>
        <div className={styles.infoList__itemDescription}>
          <span className={clsx('material-icons', styles.infoList__icon)}>assignment</span>
          <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.application`)}</span>
        </div>
        <div className={styles.infoList__itemContent}>
          {selectedCandidateJourney?.applicationId ?? onboardingApplicationId}
        </div>
      </li>
      <li className={styles.infoList__item}>
        <div className={styles.infoList__itemDescription}>
          <span className={clsx('material-icons', styles.infoList__icon)}>route</span>
          <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.journey`)}</span>
        </div>
        <div className={styles.infoList__itemContent}>{getTranslation(`recruiter:CLIENT_NAME`, `${journey}`)}</div>
      </li>
      {candidateJourneys?.length > 1 && selectedCandidateJourney && (
        <>
          <li className={styles.infoList__item}>
            <div className={styles.infoList__itemDescription}>
              <span className={clsx('material-icons', styles.infoList__icon)}>description</span>
              <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.requisition`)}</span>
            </div>
            <div className={styles.infoList__itemContent}>{selectedCandidateJourney?.requisitionId}</div>
          </li>
          <li className={styles.infoList__item}>
            <div className={styles.infoList__itemDescription}>
              <span className={clsx('material-icons', styles.infoList__icon)}>view_column</span>
              <span className="small">{t(`recruiter:GENERAL.CANDIDATE_DETAILS.site`)}</span>
            </div>
            <div className={styles.infoList__itemContent}>{selectedCandidateJourney?.siteCode}</div>
          </li>
        </>
      )}
      {selectedCandidateJourneyId && candidateJourneys?.length > 1 && (
        <li className={styles.infoList__item}>
          <span style={{ width: '100%' }}>
            <div className="tag-ds input-wrapper   ">
              <select onChange={(element) => onCandidateJourneySelect(element.target.value)}>
                {candidateJourneys.map((journey, index) => {
                  const { hiredTag, disabledJourney } = hireEvaluation(journey, selectedCandidateJourneyId);
                  const jobType = requisitions?.find((r) => r.requisitionId === journey.requisitionId)?.jobType;

                  return (
                    <option
                      key={index}
                      value={journey.id}
                      selected={journey.id === selectedCandidateJourneyId}
                      disabled={disabledJourney}
                    >{`${journey.applicationId}, ${journey.requisitionId}, ${journey.siteCode}${
                      selectedConfiguration?.showJobType && jobType ? `, ${jobType?.name} ${hiredTag}` : ''
                    }`}</option>
                  );
                })}
              </select>
            </div>
          </span>
        </li>
      )}
    </ul>
  );
};

export default InformationList;
