import { getUnixTime } from 'date-fns';
import { injectable } from 'inversify';

import { ApiBase } from './apiBase';
import { GoogleApiTimezoneModel } from './googleMapsApi.interfaces';

@injectable()
export class GoogleMapsApi extends ApiBase {
  constructor() {
    super({
      baseURL: 'https://maps.googleapis.com/maps',
    });
  }

  public async getTimezone(lat: string, lng: string): Promise<GoogleApiTimezoneModel> {
    const timestamp = getUnixTime(new Date());

    return this.request({
      method: 'get',
      url: `/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
    });
  }
}
