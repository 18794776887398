import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import LabelWithTooltip from '@features/selfService/components/LabelWithTooltip/LabelWithTooltip';

import controlsStyles from '../Controls.module.scss';
import styles from './DocumentParts.module.scss';
interface Props {
  value: any;
  setFieldValue: any;
  setFieldTouched: any;
  disabled: boolean;
}

const DocumentParts: React.FC<Props> = ({ value, setFieldValue, setFieldTouched, disabled }) => {
  const [activePartIndex, setActivePartIndex] = useState<number>(-1);
  const [allParts, setAllParts] = useState<any[]>([]);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (value && value.length > 0 && !touched) {
      setAllParts(value);
      setActivePartIndex(0);
      setTouched(true);
    }
  }, [value, touched]);

  useEffect(() => {
    setFieldValue('parts', allParts);
  }, [allParts, setFieldValue]);

  const handleNewPart = () => {
    if (disabled) return;

    setFieldTouched('parts', true);
    let newParts = allParts.map((p) => ({ ...p }));
    const maxSequence = newParts.length > 0 ? newParts.sort((a, b) => a.sequence - b.sequence)[0].sequence : 10;
    const count = newParts.length;
    const newPart = {
      name: '',
      isMandatory: false,
      sequence: maxSequence + 10,
      description: '',
    };
    newParts = [...newParts, newPart];

    setAllParts(newParts);
    setActivePartIndex(count);
  };

  const handleChange = (key: string, value: any) => {
    if (disabled) return;

    setFieldTouched('parts', true);
    const newParts = allParts.map((p) => ({ ...p }));
    newParts[activePartIndex][key] = value;
    setAllParts(newParts);
  };

  const handleDelete = () => {
    if (disabled) return;

    setFieldTouched('parts', true);
    const newParts = allParts.map((p) => ({ ...p }));
    newParts.splice(activePartIndex, 1);
    setAllParts(newParts);
    setActivePartIndex((prev) => (newParts.length > 0 ? prev - 1 : -1));
  };

  const isHavingPages = allParts?.length > 0;

  return (
    <div>
      <div className={styles.container}>
        <div style={{ marginBottom: 16 }}>
          <LabelWithTooltip
            tooltip="Set how many pages of this document the candidate should upload"
            labelProps={{ classname: 'subtitle' }}
          >
            Pages
          </LabelWithTooltip>
        </div>
        {!isHavingPages && (
          <div
            onClick={handleNewPart}
            className={clsx(disabled ? controlsStyles['control--disabled'] : '', styles.addButton)}
          >
            <span className="material-icons" style={{ fontSize: 16 }}>
              add
            </span>
          </div>
        )}
      </div>
      {isHavingPages && (
        <div className={styles.partContainer}>
          <div style={{ display: 'flex', gap: 8 }}>
            {allParts?.map((part: any, i: number) => {
              return (
                <div
                  className={clsx('tag-ds chip', activePartIndex === i && 'active')}
                  key={part.name + i}
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => setActivePartIndex(i)}
                >
                  {part.name}
                  <span
                    className="material-icons-outlined right"
                    style={{ fontSize: 16, marginLeft: 8 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete();
                    }}
                  >
                    delete
                  </span>
                </div>
              );
            })}
          </div>
          <div
            className={clsx(disabled ? controlsStyles['control--disabled'] : '', styles.addButton)}
            onClick={handleNewPart}
          >
            <span className="material-icons" style={{ fontSize: 16 }}>
              add
            </span>
          </div>
        </div>
      )}
      {activePartIndex !== -1 && (
        <div>
          <div style={{ marginBottom: 12, width: 320 }}>
            <label>Page name</label>
            <input
              type="text"
              value={allParts[activePartIndex].name}
              onChange={(e) => handleChange('name', e.target.value)}
              disabled={disabled}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex' }}>
              <input
                type="checkbox"
                checked={allParts[activePartIndex].isMandatory}
                onChange={(e) => handleChange('isMandatory', e.target.checked)}
                disabled={disabled}
              />
              <label>Mandatory</label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentParts;
