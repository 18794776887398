import React, { FC, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@components/shared/Dialog/Dialog';
import { useInjection } from '@context/inversify-context-provider';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { Utils } from '@helpers/utils';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { OODocumentationModel } from '../models/DocumentationModel';
import { OODocumentModel } from '../models/DocumentModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';
import { ImagePreview } from './ImagePreview';

interface DocumentData {
  [key: string]: {
    file?: File;
    data?: string | ArrayBuffer | null;
  };
}
interface ReadOnlyDocumentInfoProps {
  document: OODocumentModel;
  state?: DocumentData;
  documentationSelected?: (doc: OODocumentationModel) => void;
  setDocument: (document?: OODocumentModel) => void;
  closeDrawer?: () => void;
  isRecruiter?: boolean;
  nextButtonClicked?: boolean;
}

export const ReadOnlyDocumentInfo: FC<ReadOnlyDocumentInfoProps> = ({
  document,
  state,
  documentationSelected,
  setDocument,
  isRecruiter,
  nextButtonClicked,
  closeDrawer,
}) => {
  const { t } = useTranslation(['documents']);
  const history = useHistory();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const formatDate = (date?: string) => date && format(parseISO(date), 'dd/MM/yyyy');

  const store = useStore<RootState>();
  const aamBackendApi = useInjection(AamBackendApi);
  const userId = useSelector((rootState: RootState) => rootState.authApp.userId)!;

  const validateDocument = async () => {
    const tid = store.getState().tenant.tenantId;
    const documentId = document.userDocument.id;
    try {
      await aamBackendApi.validateDocument(tid, documentId);
      if (isRecruiter && closeDrawer) {
        closeDrawer();
        return;
      }

      const flow = await aamBackendApi.fetchUserFlowWithCurrentStepInfoAndUpdateRedux(userId);
      OOFlowWrapper.create(flow.selectedConfiguration.flow);
      history.push('/oo/flow/document-upload', { overview: false });
    } catch (err) {
      Utils.consoleError(`Failed to validate document: ${documentId}`);
    }
  };

  const onClickNewVersionUpload = async () => {
    const { oneOnboarding, authApp } = store.getState();
    const data = { currentStep: oneOnboarding.step, currentPage: document.name };
    await aamBackendApi.removeDocument(
      oneOnboarding.clientOrganizationId!,
      oneOnboarding.selectedConfigurationId!,
      authApp.userId!,
      oneOnboarding.applicationId!,
      data,
    );
    const flow = await aamBackendApi.fetchUserFlowWithCurrentStepInfoAndUpdateRedux(userId);
    const updatedFlow = OOFlowWrapper.create(flow.selectedConfiguration.flow);
    const flowSteps = updatedFlow?.steps.find((step) => step.name === oneOnboarding.step);
    const docModel = flowSteps?.documentations.find((doc) => doc.name === document.userDocument.type);
    if (documentationSelected && docModel) {
      setDocument();
      documentationSelected(docModel);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {!isRecruiter && (
          <Box mb={4} className="document-read-only-info-message">
            <span>{t('infoMessage')}</span>
          </Box>
        )}
        {!isRecruiter && nextButtonClicked && (
          <Box mb={4} className="document-read-only-error-message">
            <span>{t('errorMessage')}</span>
          </Box>
        )}
        {state && (
          <Box mb={4} className="document-read-only-preview">
            <h2>{t(`documents:${document.label}`)}</h2>
            {document.parts.map(
              (part, index) => state[part.name]?.data && <ImagePreview key={index} state={state} part={part} />,
            )}
          </Box>
        )}
        <Box mb={4} className="document-read-only-info">
          <Box>
            <Typography>
              {t('actions.createdDate')}
              {formatDate(document.userDocument.metadata?.createdDate)}
            </Typography>
            <Typography>
              {t('actions.endDate')}
              {formatDate(document.userDocument.metadata?.validityEndDate)}
            </Typography>
            <Typography>
              {t('actions.startDate')} {formatDate(document.userDocument.metadata?.validityStartDate)}
            </Typography>
            <Typography>
              {t('actions.name')}
              {document.userDocument?.name}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={12} xs={12}>
        {!isRecruiter && (
          <Link style={{ textDecoration: 'none' }}>
            <div className="document-read-only-upload">
              <span className="material-icons right">file_upload</span>
              <span onClick={() => setShowConfirmDialog(true)}>{t('actions.uploadNewVersion')}</span>
            </div>
          </Link>
        )}
        {document.userDocument?.additionalData?.status === OnboardingDocumentStatus.VALIDATING && (
          <Box pt={1}>
            <Button variant="contained" color="primary" onClick={validateDocument}>
              {t('actions.validate')}
            </Button>
          </Box>
        )}
      </Grid>
      <Dialog
        open={showConfirmDialog}
        onConfirm={() => onClickNewVersionUpload()}
        onClose={() => setShowConfirmDialog(false)}
      >
        <p>
          {t('documents:DOCUMENTS.DOCUMENT_UPLOAD.EDIT_READONLY_CONFIRM_MODAL.content-line-01')}
          <br />
          {t('documents:DOCUMENTS.DOCUMENT_UPLOAD.EDIT_READONLY_CONFIRM_MODAL.content-line-02')}
        </p>
      </Dialog>
    </Grid>
  );
};
