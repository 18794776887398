import React from 'react';
import { v4 as uuid } from 'uuid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, FormGroup } from '@mui/material';

interface AdminCheckboxGroupProps {
  label: string;
  options: { name: string; value: any }[];
  onChange: (value: any) => void;
  defaultChecked?: any;
  checked: boolean;
}

const AdminCheckboxGroup: React.FC<AdminCheckboxGroupProps> = ({
  label,
  options,
  onChange,
  defaultChecked = undefined,
  checked,
}) => {
  return (
    <FormGroup row className="oo-filter__checkbox-group">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p className="tag-ds  subtitle" style={{ margin: 0 }}>
            {label}
          </p>
        </AccordionSummary>
        <AccordionDetails>
          {options.map((x, i) => {
            const id = uuid();
            return (
              <div key={`acbg-${i}`} className="tag-ds checkbox-container">
                <input
                  checked={checked === x.value}
                  type="checkbox"
                  id={id}
                  onChange={(e) => {
                    if (e.target.checked === false) {
                      onChange(null);
                      return;
                    }
                    onChange(x.value);
                  }}
                />
                <label htmlFor={id}>{x.name}</label>
              </div>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </FormGroup>
  );
};

export default AdminCheckboxGroup;
