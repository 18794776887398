import React from 'react';
import clsx from 'clsx';

import { AnchorType, SignatureAnchorWithUuid } from './SignatureAnchors';

import controlsStyle from '../Controls.module.scss';
import styles from './SignatureAnchors.module.scss';

interface Props {
  anchor: SignatureAnchorWithUuid;
  indexValue: number;
  handleDelete: () => void;
  setEditAnchor: () => void;
  anchorType: AnchorType;
  disabled: boolean;
}

const SignatureItem: React.FC<Props> = ({ anchor, indexValue, handleDelete, setEditAnchor, anchorType, disabled }) => {
  const generateObj = (anchor: any) => {
    if (!anchor) return <></>;

    if (anchorType === AnchorType.TEXT) {
      const generateComponent = Object.keys(anchor.extractAnchor).map((x) => {
        return <div key={x}>{`${x}: ${anchor.extractAnchor[x]}`}</div>;
      });

      return generateComponent;
    }

    if (anchorType === AnchorType.POSITION) {
      const generateComponent = Object.keys(anchor)
        .filter((x) => x !== 'uuid' && x !== 'extract' && x !== 'role')
        .map((x) => {
          return <div key={x}>{`${x}: ${anchor[x]}`}</div>;
        });

      return generateComponent;
    }
  };

  return (
    <div className={styles.item}>
      <div className={styles.condition__header}>
        <span className={clsx('subtitle', styles['condition__header-title'])}>Signature {indexValue + 1}</span>
        <div className={styles['heading__icon-container']}>
          <div
            className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
            style={{ marginRight: 8 }}
            onClick={setEditAnchor}
          >
            <span className="material-icons-outlined" style={{ fontSize: 16 }}>
              edit
            </span>
          </div>
          <div
            className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
            onClick={handleDelete}
          >
            <span className="material-icons-outlined" style={{ fontSize: 16 }}>
              delete
            </span>
          </div>
        </div>
      </div>
      <div>Role: {anchor.role}</div>
      <div>{generateObj(anchor)}</div>
    </div>
  );
};

export default SignatureItem;
