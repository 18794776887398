import React, { FC } from 'react';
import { Box, Button } from '@mui/material';

import { useT } from '../../hooks/useT';
import { OODocumentPartModel } from '../../models/DocumentPartModel';

interface ImagePreviewProps {
  state: any;
  part: OODocumentPartModel;
}
const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
export const ImagePreview: FC<ImagePreviewProps> = ({ part, state }) => {
  const { t } = useT('candidate_recruiter');
  return (
    <>
      {state[part.name]?.file?.type.startsWith('image') ? (
        <>
          <Box>
            <img
              src={state[part.name].data!.toString()}
              alt="preview"
              style={{ marginRight: '8px', cursor: 'pointer', width: '100%' }}
            />
          </Box>
        </>
      ) : (
        <div>
          {!isMobileBrowser() ? (
            <iframe title="rmpl" width="100%" height="350" src={state[part.name].data!.toString()} />
          ) : (
            <Box pt={1}>
              <Button
                variant="contained"
                className="button-primary"
                onClick={() => {
                  state[part.name].link?.click();
                  URL.revokeObjectURL(state[part.name].data);
                }}
              >
                {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.download')}
              </Button>
            </Box>
          )}
        </div>
      )}
    </>
  );
};
