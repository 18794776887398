import { createTheme } from '@mui/material';

import { publicUrl } from '../build';

import scssVars from './theme2/_variables.scss';

const houschkaHeadMediumFontFace = {
  fontFamily: 'HouschkaHeadMedium',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('HouschkaHead'),
    local('HouschkaHead-Medium'),
    url('${publicUrl}fonts/HouschkaHead-Medium.otf') format('opentype')
  `,
};

const defaultTheme = createTheme();
export const theme2 = createTheme({
  spacing: [0, 8, 16, 18, 20, 26, 36, 42, 48, 66, 86, 112],
  typography: {
    allVariants: {
      color: scssVars.adeccoPrimaryBlack,
    },
    fontFamily: 'Nunito, Merriweather, sans-serif',
    h1: {
      fontSize: 64,
      fontWeight: 600,
      fontFamily: 'HouschkaHead, sans-serif',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 2.8,
    },
    h2: {
      fontSize: 40,
      fontFamily: 'HouschkaHead, sans-serif',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.4,
      letterSpacing: 2,
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
      lineHeight: 1.33,
      letterSpacing: 1.5,
      fontFamily: 'HouschkaHead, sans-serif',
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    h4: {
      fontSize: 18,
      fontFamily: 'HouschkaHead, sans-serif',
      fontWeight: 900,
      lineHeight: 1.22,
      letterSpacing: 1,
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    h5: {
      fontSize: 14,
      fontWeight: 900,
      fontFamily: 'HouschkaHead, sans-serif',
      lineHeight: 1.29,
      letterSpacing: 0.7,
      color: scssVars.adeccoTwilight,
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    h6: {
      fontSize: 10,
      fontWeight: 'bold',
      fontFamily: 'Nunito, sans-serif',
      lineHeight: 1.8,
      letterSpacing: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    subtitle1: {
      fontSize: 21,
      lineHeight: 1.35,
      letterSpacing: 0.5,
      fontWeight: 700,
      fontFamily: 'Nunito, sans-serif',
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
    subtitle2: {
      fontSize: 16,
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 0.25,
    },
    body1: {
      fontSize: 16,
      fontFamily: 'Nunito, sans-serif',
      lineHeight: 1.33,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      letterSpacing: 0.3,
    },
    body2: {
      fontSize: 16,
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.38,
      letterSpacing: 'normal',
    },
    caption: {
      fontFamily: 'Nunito, sans-serif',
      fontSize: 10,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 0.2,
    },
  },
  palette: {
    common: {
      black: scssVars.adeccoPrimaryBlack,
      white: scssVars.white,
    },
    background: {
      paper: '#fff',
      default: 'rgba(255, 255, 255, 1)',
    },
    primary: {
      light: '#da291c',
      main: 'rgba(218, 41, 28, 1)',
      dark: 'rgba(218, 41, 28, 1)',

      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: 'rgba(255, 255, 255, 1)',
      contrastText: 'rgba(218, 40, 28, 1)',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: scssVars.adeccoPrimaryBlack,
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [houschkaHeadMediumFontFace],
      },
    },
    // Style sheet name ⚛️

    // Buttons
    MuiButton: {
      root: {
        minWidth: 144,
        minHeight: 48,
        borderRadius: 10,
        '&.floating-button': {
          borderRadius: 27.5,
        },
      },
      label: {
        fontFamily: 'Nunito, sans-serif',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.13,
        letterSpacing: 0.5,
        textTransform: 'capitalize',
        fontSize: 18,
        fontWeight: 700,
      },
      sizeLarge: {
        minWidth: 216,
        minHeight: 64,
        borderRadius: 8,
        '& span': {
          fontSize: 20,
          fontWeight: 'bold',
        },
      },
      sizeSmall: {
        minWidth: 96,
        minHeight: 32,
        borderRadius: 4,
        '& span': {
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 1.29,
          letterSpacing: 0.44,
        },
      },
      contained: {
        borderRadius: 10,
        backgroundColor: scssVars.adeccoRed,
        color: scssVars.white,
        '&:hover': {
          backgroundColor: scssVars.adeccoRed,
          opacity: 0.9,
        },
        '&$disabled': {
          color: scssVars.white,
          backgroundColor: scssVars.adeccodGrayLight,
        },
      },
      containedPrimary: {
        backgroundColor: scssVars.adeccoRed,
        color: scssVars.white,
        '&:hover': {
          backgroundColor: scssVars.adeccoDarkRed,
          opacity: 0.9,
        },
      },
      containedSecondary: {
        backgroundColor: scssVars.adeccoPink,
        color: scssVars.adeccoTwilight,
        '&:hover': {
          backgroundColor: scssVars.adeccoPink,
          opacity: 0.9,
        },
      },
      outlinedPrimary: {
        backgroundColor: 'transparent',
        color: scssVars.adeccoPrimaryBlack,
        border: `solid 1px ${scssVars.adeccoPrimaryBlack}`,
        '&:hover': {
          border: `solid 1px ${scssVars.adeccoPrimaryBlack}`,
          backgroundColor: scssVars.adeccodGrayLight,
          opacity: 0.9,
        },
        '&$disabled': {
          color: scssVars.adeccoSecondaryGray,
          backgroundColor: scssVars.white,
        },
      },
      outlinedSecondary: {
        backgroundColor: 'transparent',
        color: scssVars.white,
        border: `solid 2px ${scssVars.white}`,
        '&:hover': {
          border: `solid 2px ${scssVars.white}`,
          opacity: 0.9,
        },
      },
      textPrimary: {
        color: scssVars.adeccoPrimaryBlack,
        '&:hover': {
          textDecoration: 'underline',
          textDecorationColor: scssVars.primaryBrandRed,
          textDecorationThickness: 2,
          textUnderlineOffset: '5px',
          backgroundColor: 'transparent',
        },
        '& span': {
          fontFamily: 'Nunito, sans-serif',
          fontSize: 16,
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.6,
          letterSpacing: 1,
        },
      },
    },

    MuiIconButton: {
      root: {
        padding: 14,
        '&:hover': {
          backgroundColor: scssVars.adeccoSecondaryGray,
        },
        '&.button-icon-rect': {
          borderRadius: 5,
          border: `1px solid ${scssVars.adeccoSecondaryGrayLight1}`,
        },
      },
    },
    MuiFab: {
      primary: {
        '&:hover': {
          backgroundColor: scssVars.adeccoDarkRed,
        },
      },
    },

    MuiSlider: {
      rail: {
        height: 8,
        opacity: 1,
        backgroundColor: scssVars.dust,
        borderRadius: 4.5,
      },
      track: {
        backgroundColor: scssVars.adeccoRoyal,
        height: 8,
        borderRadius: 4.5,
      },
      markActive: {
        opacity: 0,
      },
      mark: {
        opacity: 0,
      },
      thumb: {
        width: 24,
        height: 24,
        marginTop: -7,
        backgroundColor: scssVars.white,
        boxShadow: '0 2px 4px 0 rgba(150, 140, 131, 0.4)',
        border: '1px solid rgba(150, 140, 131, 0.2)',
        '&:hover': {
          border: '1px solid rgba(150, 140, 131, 0.2)',
          boxShadow: '0 2px 4px 0 rgba(150, 140, 131, 0.4)',
        },
      },
      active: {
        boxShadow: '0 2px 4px 0 rgba(150, 140, 131, 0.4) !important',
      },
    },

    MuiAppBar: {
      colorPrimary: {
        color: '#000',
      },
    },
    MuiFormControl: {
      root: {
        backgroundColor: scssVars.white,

        width: '100%',
      },
    },

    MuiInputBase: {
      root: {
        fontFamily: 'Nunito, Times New Roman',
        fontWeight: 300,
      },
      input: {
        backgroundColor: scssVars.white,
        fontFamily: 'Nunito, Times New Roman',
        fontWeight: 300,

        '&::placeholder': {
          color: scssVars.adeccoGray,
          fontFamily: 'Nunito, Times New Roman',
          fontWeight: 300,
          opacity: 1,
        },
        '&::-webkit-input-placeholder': {
          color: scssVars.adeccoGray,
          fontFamily: 'Nunito, Times New Roman',
          fontWeight: 300,
          opacity: 1,
        },
        ':-ms-input-placeholder': {
          color: scssVars.adeccoGray,
          fontFamily: 'Nunito, Times New Roman',
          fontWeight: 300,
          opacity: 1,
        },
        '&$disabled': {
          opacity: 0.8,
        },
      },
    },
    // Inputs
    MuiInput: {
      root: {},
    },
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        borderWidth: 2,
        borderRadius: 8,
        '& $notchedOutline': {
          borderColor: scssVars.adeccoIce,
          borderWidth: 2,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: scssVars.adeccoIce,
        },
        '&$focused $notchedOutline': {
          borderColor: scssVars.adeccoRoyal,
          borderWidth: 2,
        },
      },
      input: {
        padding: 14.5,
        fontSize: 16,
        lineHeight: 1.2,
      },
    },
    // Select
    MuiSelect: {
      selectMenu: {
        width: '100%',
      },
      icon: {
        fill: 'rgba(218, 41, 28, 1)',
      },
      select: {
        '&:focus': {
          backgroundColor: scssVars.white,
        },
      },
    },
    // Labels
    MuiFormLabel: {
      root: {
        fontSize: 14,
        color: scssVars.adeccoGray,
        lineHeight: '23px',
        fontFamily: 'Nunito, Times New Roman',
        fontWeight: 300,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 12px) scale(1)',
        color: scssVars.adeccoGray,
        fontFamily: 'Nunito, Times New Roman',
        fontWeight: 300,
        fontSize: 16,
        '&.MuiInputLabel-shrink.input-no-label': {
          color: 'transparent !important',
          transform: 'none',
        },
      },
    },

    MuiList: {
      root: {
        '& .MuiListItem-root.Mui-selected': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: 64,
        color: scssVars.adeccoTwilight,
        '&:hover': {
          color: scssVars.adeccoRoyal,
        },
      },
    },
    MuiListItemText: {
      root: {},
      primary: {
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: scssVars.adeccoIce,
        color: scssVars.adeccoTwilight,
        colorPrimary: {
          backgroundColor: scssVars.adeccoIce,
          color: scssVars.adeccoTwilight,
          '&:focus': {
            backgroundColor: `${scssVars.adeccoRoyal} !important`,
            color: scssVars.white,
          },
        },
      },
      colorPrimary: {
        backgroundColor: `${scssVars.adeccoRoyal} !important`,
        color: scssVars.white,
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 700,
        textTransform: 'none',
      },
      root: {
        padding: 0,
        [defaultTheme.breakpoints.up('sm')]: {
          minWidth: 'auto',
        },
        marginRight: 50,
        '&:last-of-type': {
          marginRight: 0,
        },
        '&$selected': {
          color: scssVars.adeccoRed,
          borderBottom: `6px solid ${scssVars.adeccoRed}`,
        },
      },
    },
    MuiBottomNavigationAction: {
      label: {
        fontSize: 14,
      },
    },
    MuiSwitch: {
      root: {
        width: 62,
        height: 32,
        padding: 0,
      },
      thumb: {
        width: 30,
        height: 30,
        border: '1px solid black',
      },
      track: {
        borderRadius: 32 / 2,
        border: '1px solid transparent',
        backgroundColor: scssVars.black,
        opacity: 1,
      },
    },

    MuiTypography: {
      colorPrimary: {
        color: scssVars.adeccoPurple,
      },
      colorSecondary: {
        color: scssVars.black,
      },
      colorTextSecondary: {
        color: scssVars.white,
      },
    },
    MuiLink: {
      underlineHover: {},
    },
    MuiDialogActions: {
      root: {
        padding: '24px',
      },
    },
    MuiDialog: {
      paper: {
        padding: '38px 94px',
        borderRadius: 16,
        boxShadow:
          '0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14)',
      },
    },
    MuiDialogTitle: {
      root: {
        '& .MuiTypography-root': {
          color: scssVars.adeccoTwilight,
        },
      },
    },
    MuiPopover: {
      root: {
        '&.drawer-select .MuiMenu-paper': {
          width: 336,
          top: '68px !important',
          left: '7px !important',
          borderRadius: 12,
        },

        '& .MuiPickersToolbar-toolbar': {
          backgroundColor: scssVars.adeccoTwilight,
        },

        '& .MuiPickersDay-dayDisabled.MuiPickersDay-daySelected': {
          color: scssVars.white,
        },

        '& .MuiPickersDay-dayDisabled': {
          backgroundColor: 'rgb(244, 243, 242)',
        },

        '& .MuiPickersDay-daySelected': {
          backgroundColor: scssVars.adeccoRoyal,
          '&:hover': {
            backgroundColor: scssVars.adeccoRoyal,
            opacity: 0.9,
          },
        },
      },
    },
  },

  props: {
    // Name of the component ⚛️
    MuiButton: {
      disableElevation: true,
    },
    MuiSvgIcon: {
      cursor: 'pointer',
    },

    MuiTextField: {
      fullWidth: true,
      variant: 'outlined',
    },
    MuiFormControl: {
      variant: 'outlined',
    },
  },
});
