import React from 'react';
import { apiQuery } from '@store/apiQuery';

import { useT } from '../../hooks/useT';
import { OOMultiSelectModelInterface } from '../../interfaces';
import { OOMultiSelect } from './OOMultiSelect';

export const OOMultiSelectComponent: React.VoidFunctionComponent<OOMultiSelectModelInterface> = (props) => {
  const { t, translationsLoading } = useT('entry');
  const { data, isFetching } = apiQuery.useGetMultiselectValuesQuery({
    name: props.controlHeaderName ? props.controlHeaderName : props.name.toLowerCase(),
  });

  return (
    <OOMultiSelect
      validationKey={props.name}
      label={t(props.label)}
      description={t(props.description)}
      data={data}
      mandatory={props.isMandatory}
      loading={translationsLoading || isFetching}
      defaultValue={props.defaultValue}
      onChangeCallback={props.onChangeCallback}
      itemModificationFunctions={props.itemModificationFunctions}
      disable={props.isEditableCandidate}
    />
  );
};
