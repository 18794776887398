import React from 'react';
import { Paginator } from '@components/shared/Paginator/Paginator';
import { Box } from '@mui/material';

interface AdminPaginationProps {
  setRowsPerPage: (rows: number) => void;
  setPage: (page: number) => void;
  rowsPerPage: number;
  currentPage: number;
  total: number;
  isRowsPerPageVisible?: boolean;
}

const AdminPagination: React.FC<AdminPaginationProps> = ({
  rowsPerPage,
  setRowsPerPage,
  total,
  currentPage,
  setPage,
  isRowsPerPageVisible,
}) => {
  const offsetChangeProp = isRowsPerPageVisible ? { onOffsetChange: (offset: number) => setRowsPerPage(offset) } : {};
  return (
    <Box className="admin-pagination" display="flex" justifyContent="flex-end">
      <Box display="flex" alignItems="center">
        <Paginator
          total={total}
          defaultPage={currentPage + 1}
          perPage={rowsPerPage}
          onChange={(x) => {
            setPage(x - 1);
          }}
          offsets={[5, 10, 20, 25]}
          {...offsetChangeProp}
        />
      </Box>
    </Box>
  );
};

export default AdminPagination;
