import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthLayout from '@components/shared/AuthLayout/AuthLayout';
import { useInjection } from '@context/inversify-context-provider';
import { AdeccoAdAuthHelper } from '@helpers/adeccoAdAuthHelper';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';

import styles from './AdminLoginPage.module.scss';

const AdminLoginPage = () => {
  const adeccoAd = useSelector((state: RootState) => state.featureConfiguration.adeccoAd);
  const appSettings = useSelector((state: RootState) => state.featureConfiguration.appSettings);
  const adeccoAdAuthHelper = useInjection(AdeccoAdAuthHelper);
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.authAdmin.userId);
  const { t } = useTranslation(['candidate_recruiter']);
  const sessionExpiredAdmin = useSelector((state: RootState) => state.authAdmin.sessionExpired);

  useEffect(() => {
    if (sessionExpiredAdmin || new URLSearchParams(window.location.search).get('sessionExpired')) {
      dispatch(
        snackbarSlice.actions.showError({
          message: t('candidate_recruiter:GENERAL.GENERIC.sessionExpired'),
        }),
      );
    } else {
      dispatch(snackbarSlice.actions.getFlash('unauthorized'));
    }
  }, [dispatch, sessionExpiredAdmin, t]);

  if (userId) {
    history.push('/oo/recruiter');
    return <></>;
  }

  const goToAdeccoAD = async () => {
    const signInUrl = await adeccoAdAuthHelper.getAuthorizeUrlSignIn(adeccoAd.signIn, history.location);
    adeccoAdAuthHelper.navigate(signInUrl, history, appSettings.useExternalBrowser);
  };

  return (
    <AuthLayout>
      <h2 className={styles.heading}>Log in with Adecco AD</h2>
      <button className={clsx('button_large', styles.button)} onClick={goToAdeccoAD}>
        LOG IN
      </button>
    </AuthLayout>
  );
};

export default AdminLoginPage;
