import React from 'react';
import DOMPurify from 'dompurify';

import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';

type HtmlCustomProps = {
  controlModel?: OOControlModelInterface;
  rawHtml?: string;
};

export const HtmlCustom: React.FC<HtmlCustomProps> = ({ controlModel, rawHtml }: HtmlCustomProps) => {
  const ALLOWED_TAGS = [
    'h1',
    'h2',
    'h6',
    'p',
    'span',
    'ol',
    'a',
    'li',
    'strong',
    'em',
    'div',
    'style',
    'ul',
    'class',
    'br',
    'del',
    'ins',
    'strike',
    'italic',
    'u',
    'b',
  ];
  const ALLOWED_ATTR = ['style', 'href', 'class', 'target', 'rel'];

  const { t, i18n } = useT('entry', 'approval', 'documents');

  const getPrefix = (label: string) => {
    let prefix = label?.split('.')?.[0].toLowerCase() || '';
    const prefixAlias: any = {
      documents2: 'documents',
      documents3: 'documents',
    };
    if (prefixAlias[prefix]) {
      prefix = prefixAlias[prefix];
    }
    return prefix;
  };

  const prefix = getPrefix(controlModel?.staticText ?? '');

  const localizedHtml = rawHtml
    ? rawHtml
    : prefix && i18n.exists(controlModel?.staticText ?? '', { ns: prefix })
    ? t(`${prefix}:${controlModel?.staticText}` ?? '')
    : i18n.exists(controlModel?.staticText ?? '')
    ? t(controlModel?.staticText ?? '')
    : controlModel?.staticText;

  const safeHTML = localizedHtml
    ? DOMPurify.sanitize(localizedHtml, {
        ALLOWED_TAGS,
        ALLOWED_ATTR,
      })
    : '';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        color: '#1C304B',
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: safeHTML }} />
    </div>
  );
};
