import React, { useContext } from 'react';
import { ViewOnlyContext } from '@context/view-only-provider';
import ConditionSign from '@features/selfService/components/ConditionSign/ConditionSign';
import { Box } from '@mui/material';

import { DocumentPropertyType } from './DocumentList';

import styles from '../../ConfigurationSetup.module.scss';

interface Props {
  documents: any;
  getTranslation: (key: string) => string;
  setSelectedDocumentDetails: ({ type, controls }: { type: DocumentPropertyType; controls: any }) => void;
  document: any;
  index: number;
}

const DocumentListItem: React.FC<Props> = ({
  documents,
  getTranslation,
  setSelectedDocumentDetails,
  document,
  index,
}) => {
  const documentHavingCondition = documents.condition?.length > 0;
  const { isViewOnly } = useContext(ViewOnlyContext);

  return (
    <div
      key={documents.name}
      className={`${styles.controlListItem} ${styles.controlListItem__document}`}
      style={{ padding: '8px 16px' }}
      onClick={() => setSelectedDocumentDetails({ type: 'documents', controls: document.documents[index] })}
    >
      <Box display="flex" alignItems="center">
        {!isViewOnly && (
          <div className={styles.controlListItem__button}>
            <div className="material-icons" style={{ fontSize: 15, marginRight: 8 }}>
              drag_indicator
            </div>
          </div>
        )}
        <span className="medium">{getTranslation(documents.label)}</span>
      </Box>
      {documentHavingCondition && <ConditionSign />}
    </div>
  );
};

export default DocumentListItem;
