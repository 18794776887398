import React from 'react';
import { useFormikContext } from 'formik';
import { useDecodedParams } from '@hooks/useDecodedParams';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';
import { apiQuery } from '@store/apiQuery';

import { checkboxErrorLabel } from '../../helpers';
import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';
import { FormControlLoader } from '../FormControlLoader';
import { HtmlCustom } from './HtmlCustom';

export const OOCheckboxListHTML: React.FC<OOControlModelInterface> = (props) => {
  const { isMandatory, description, name, defaultValue, controlHeaderName } = props;
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(name);
  const fieldMeta = formik.getFieldMeta(name);
  const { t, translationsLoading } = useT('entry', 'control-items');

  const { clientOrganizationId, configurationId } = useDecodedParams();

  const { data } = apiQuery.useGetDropdownsQuery({
    name: controlHeaderName ? controlHeaderName : props.name.toLowerCase(),
    clientOrganizationId,
    configurationId,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!fieldProps.value) {
      formik.setFieldValue(name, [event.target.name]);
    } else {
      if (Array.isArray(fieldProps.value)) {
        let items: Array<string> = [...fieldProps.value];
        if (event.target.checked) {
          items.push(event.target.name);
        } else {
          items = items.filter((i) => i !== event.target.name);
        }
        formik.setFieldValue(name, items);
      }
    }
  };

  const renderError = (error?: string, validationKey?: string) => {
    if (error && validationKey) {
      return (
        <FormHelperText error={true}>
          {t(`entry:validations.${validationKey}`) !== `validations.${validationKey}`
            ? t(`entry:validations.${validationKey}`)
            : error}
        </FormHelperText>
      );
    }
  };

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  return (
    <FormControl variant="outlined">
      {description && (
        <FormLabel>
          <b>{t(`entry:${description}`)}</b>
          {isMandatory ? '*' : ''}
        </FormLabel>
      )}
      <FormGroup>
        {data?.map((i, index) => (
          <FormControlLabel
            style={{ alignItems: 'center' }}
            key={index}
            control={
              <Checkbox
                checked={
                  fieldProps.value === undefined ? defaultValue === i.value : fieldProps.value?.includes(i.value)
                }
                name={i.value}
                onChange={handleChange}
              />
            }
            label={
              <Typography className="accordion tag-ds">
                <HtmlCustom rawHtml={t(`control-items:${i.label}`)} />
              </Typography>
            }
          />
        ))}
      </FormGroup>
      {renderError(checkboxErrorLabel(fieldMeta), name)}
    </FormControl>
  );
};
