import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface Props {
  children: React.ReactNode;
  Fallback: React.ComponentType<any>;
}

const ErrorBoundaryWrapper: React.FC<Props> = ({ children, Fallback }) => {
  const [someKey, setSomeKey] = useState(null);
  const [errorInfo, setErrorInfo] = useState<string | null>(null);

  const logError = (_: Error, info: { componentStack: string }) => {
    setErrorInfo(info.componentStack);
  };

  return (
    <ErrorBoundary
      FallbackComponent={(props) => <Fallback {...props} errorInfo={errorInfo} />}
      onReset={() => setSomeKey(null)}
      resetKeys={[someKey]}
      onError={logError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
