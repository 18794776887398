import { useFormikContext } from 'formik';
import { ControlItems } from '@store/apiQuery';

export const useConditionOnControlItems = (controlItems?: ControlItems) => {
  const evaluateCondition = (condition: Record<string, any>[], formValues: Record<string, any>) => {
    return condition.reduce((previousValue, currentValue: Record<string, any>, index) => {
      const { control, value, notValue, operator } = currentValue;
      const controlFromCondition = control.name;

      const formikValue = value === false ? !!formValues[controlFromCondition] : formValues[controlFromCondition];
      const conditionResult = !(
        (typeof value !== 'undefined' && formikValue !== value) ||
        (typeof notValue !== 'undefined' && formikValue === notValue)
      );

      if (index === 0) {
        return conditionResult;
      }

      const safeOperator = operator || 'AND';

      if (safeOperator === 'OR') {
        return previousValue || conditionResult;
      } else if (safeOperator === 'AND') {
        return previousValue && conditionResult;
      } else {
        throw new Error(`Unsupported operator: ${safeOperator}`);
      }
    }, false);
  };

  const formik = useFormikContext<Record<string, any>>();
  const conditionalData = [];
  if (controlItems) {
    for (let i = 0; i < controlItems.length; i++) {
      if (controlItems[i].condition) {
        if (evaluateCondition(controlItems[i].condition!, formik.values)) {
          conditionalData.push(controlItems[i]);
        }
      } else {
        conditionalData.push(controlItems[i]);
      }
    }
  }
  return conditionalData;
};
