import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelfServiceItemType } from '@models/selfService.model';
import { Box } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { findDocumentInConfiguration, handleDocumentTarget, selfServiceSlice } from '@store/slices/selfService.slice';

import DraggableComponent from '../DraggableComponent';
import AddItemFromLibrary from '../other/AddItemFromLibrary/AddItemFromLibrary';
import DocumentationItem from './DocumentationItem';
import { DocumentPropertyType } from './DocumentList';
import DocumentListItem from './DocumentListItem';

interface Props {
  document: any;
  setSelectedDocumentDetails: ({ type, controls }: { type: DocumentPropertyType; controls: any }) => void;
  isActive: boolean;
  addDocumentFromLibrary: (document: any, name: string, id: string) => void;
}

const DocumentationListItem: React.FC<Props> = ({
  document,
  setSelectedDocumentDetails,
  isActive,
  addDocumentFromLibrary,
}) => {
  const [expandedDocumentation, setExpandedDocumentation] = useState<any | null>(null);

  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation(['documents']);

  const getTranslation = (key: string) => {
    if (i18n.exists(`documents:${key}`)) {
      return t(`documents:${key}`);
    }

    return key;
  };

  const handleDocumentDelete = (documentName: string) => {
    const currentDocument = findDocumentInConfiguration(
      {
        featureName: selectedFeature?.featureName,
        stepName: selectedFeature?.stepName,
      },
      selectedConfiguration,
    );

    if (!currentDocument || !selectedFeature) return;

    const newDocuments = currentDocument.documentations
      .filter((x: any) => x.name !== documentName)
      .map((y: any, i: number) => ({ ...y, sequence: i * 10 }));

    dispatch(
      selfServiceSlice.actions.handleDocumentToUploadFeature({
        featureName: selectedFeature?.featureName,
        stepName: selectedFeature?.stepName,
        documentations: newDocuments,
        modification: {
          type: 'delete',
          property: currentDocument.documentations.find((x: any) => x.name === documentName),
        },
      }),
    );
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <DraggableComponent control={document} componentType="documentControl">
        <DocumentationItem
          document={document}
          isActive={isActive}
          getTranslation={getTranslation}
          setSelectedDocumentDetails={setSelectedDocumentDetails}
          handleDocumentDelete={handleDocumentDelete}
          expandedDocumentation={expandedDocumentation}
          setExpandedDocumentation={setExpandedDocumentation}
        />
      </DraggableComponent>
      <Box pl={4} style={{ borderLeft: '2px solid #EBEBEE' }}>
        {expandedDocumentation && document.documents && (
          <>
            {document.documents
              .slice()
              ?.sort((a: any, b: any) => a.sequence - b.sequence)
              .map((documents: any, i: number) => {
                return (
                  <div key={documents.name} style={{ marginTop: 12 }}>
                    <DraggableComponent control={documents} componentType="documentsControl">
                      <DocumentListItem
                        documents={documents}
                        getTranslation={getTranslation}
                        setSelectedDocumentDetails={setSelectedDocumentDetails}
                        document={document}
                        index={i}
                      />
                    </DraggableComponent>
                  </div>
                );
              })}
            <AddItemFromLibrary
              addItemFromLibrary={(item, name, id) => {
                addDocumentFromLibrary(item, name, id);
                dispatch(handleDocumentTarget(document.name));
              }}
              type={SelfServiceItemType.DOCUMENT}
            />
          </>
        )}
      </Box>
    </div>
  );
};

export default DocumentationListItem;
