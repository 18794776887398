import React, { FC } from 'react';
import { Box } from '@mui/material';

import { OODocumentPartModel } from '../models/DocumentPartModel';

interface ImagePreviewProps {
  state: any;
  part: OODocumentPartModel;
}

export const ImagePreview: FC<ImagePreviewProps> = ({ part, state }) => (
  <>
    {state[part.name]?.file?.type.startsWith('image') ? (
      <>
        <Box>
          <img
            src={state[part.name].data!.toString()}
            alt="preview"
            style={{ marginRight: '8px', cursor: 'pointer', width: '100%' }}
          />
        </Box>
      </>
    ) : (
      <iframe title="rmpl" width="100%" height="350" src={state[part.name].data!.toString()} />
    )}
  </>
);
