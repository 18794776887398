import React from 'react';
import clsx from 'clsx';

import styles from './NewConfigurationAction.module.scss';

interface Props {
  actions: { text: string; icon?: string; onClick: () => void; disabled: boolean }[];
}

const NewConfigurationActions: React.FC<Props> = ({ actions }) => {
  return (
    <div className={clsx('tag-ds', styles.container)}>
      {actions.map((action, i) => {
        return (
          <button key={i} onClick={action.onClick} disabled={action.disabled} className="tag-ds">
            {action.text}
            {action.icon && <span className="material-icons left">{action.icon}</span>}
          </button>
        );
      })}
    </div>
  );
};

export default NewConfigurationActions;
