import React from 'react';
import { useSelector } from 'react-redux';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOControlModelInterface } from '@features/oneOnboarding/interfaces';
import { Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';

type OOImageComponentProps = {
  controlModel: OOControlModelInterface;
};

export const OOImageComponentMOCK: React.FC<OOImageComponentProps> = ({ controlModel }: OOImageComponentProps) => {
  const { t } = useT('entry');

  const { logo } = useSelector((state: RootState) => state.authApp)!;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        minHeight: 225,
      }}
    >
      {controlModel?.imageSrc && (
        <div
          style={{
            width: `calc(30% + 32px)`,
            marginBottom: '32px',
          }}
        >
          <img
            style={{
              borderRadius: '50%',
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
            }}
            src={logo}
            alt="preview"
          />
        </div>
      )}
      <Typography
        align="center"
        style={{
          fontSize: 22,
          lineHeight: '32px',
          fontWeight: 400,
        }}
      >
        {t(controlModel.label)}
      </Typography>
      <Typography
        align="center"
        variant="body1"
        style={{
          lineHeight: '24px',
          fontWeight: 400,
        }}
      >
        {t(controlModel.description)}
      </Typography>
    </div>
  );
};
