import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useInjection } from '@context/inversify-context-provider';
import { useFlowWrapper } from '@features/oneOnboarding/hooks/useFlowWrapper';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OOStepElementModel } from '@features/oneOnboarding/models/StepElementModel';
import { setMomentLocale } from '@helpers/date-locales.helper';
import { getBlobData } from '@helpers/fileUpload.helper';
import { getTenantDefaultLanguage, getTenantLanguageList } from '@helpers/tenant.helper';
import { Menu } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { authAppSlice } from '@store/slices/authApp.slice';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { ArrowBackwardIcon } from '../../shared/customIcons/app/ArrowBackwardIcon';
import { HeaderMenu } from './HeaderMenu';

export interface HeaderProps {
  title?: string | JSX.Element;
  onBackPress?: () => void;
  style?: CSSProperties;
  isAuthenticated?: boolean;
  isOnPreRegisterPage?: boolean;
  hideExpandBtn?: boolean;
  showUnsavedChangesConfirmationModal?: () => Promise<boolean>;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  onBackPress,
  style,
  isAuthenticated = true,
  isOnPreRegisterPage = false,
  hideExpandBtn,
  showUnsavedChangesConfirmationModal,
}) => {
  const { t } = useTranslation('candidate');
  const aamBackendApi = useInjection(AamBackendApi);
  const { tenantId } = useSelector((state: RootState) => state.tenant)!;
  const { logo } = useSelector((state: RootState) => state.authApp)!;
  const history = useHistory();
  const [expanded, setExpanded] = useState<boolean>(false);
  const tenant = useSelector((state: RootState) => state.tenant);
  const { step, themeAdditionalData, hasMultipleOnboarding, flow } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const flowWrapper = useFlowWrapper();

  const stepName: any = useMemo(
    () =>
      step ? flow?.find((s) => s.elements.length > 0)?.name || OOStepsEnum.registration : OOStepsEnum.registration,
    [step, flow],
  );
  useEffect(() => {
    const downloadImage = async () => {
      try {
        const { data, headers } = await aamBackendApi.downloadLogo(tenantId, themeAdditionalData.logo || 'default.png');
        const logo = getBlobData(data, headers['content-type']).imageUrl;
        store.dispatch(authAppSlice.actions.updateLogo(logo));
      } finally {
        // setIsLoading(false);
      }
    };

    if (!logo) {
      downloadImage();
    }
  }, [aamBackendApi, tenantId, themeAdditionalData, logo]);

  const elements: OOStepElementModel[] = useMemo(() => {
    if (!flowWrapper || !tenant.useOneOnboarding) {
      return [];
    }
    const currentStepKey: string = stepName;
    return flowWrapper.steps.find((step) => step.name === currentStepKey)?.elements ?? [];
  }, [flowWrapper, tenant.useOneOnboarding, stepName]);
  useEffect(() => {
    if (i18n?.services?.languageUtils) {
      const langFromStorage = localStorage.getItem('i18nextLng') || getTenantDefaultLanguage(tenant.tenantId);
      const lang = (getTenantLanguageList(tenant.tenantId) as string[]).includes(langFromStorage)
        ? langFromStorage
        : getTenantDefaultLanguage(tenant.tenantId);
      i18n.changeLanguage(lang);
      setMomentLocale(tenant.tenantId, lang);
    }
  }, [tenant.tenantId]);

  const renderBackArrow = useMemo(() => {
    if (!history.location.pathname.includes('/oo/flow/dashboard') && themeAdditionalData.showBackwardArrowInHeader) {
      return (
        <Box className="header__arrow-icon" onClick={onBackPress}>
          <span className="material-icons">arrow_back</span>
          <p className="tag-ds subtitle" style={{ margin: 0 }}>
            {t('GENERAL.GENERIC.back')}
          </p>
        </Box>
      );
    }
    return (
      <Box className="header__arrow-icon" onClick={onBackPress}>
        <ArrowBackwardIcon />
        {logo && <img src={logo} alt="Dialog" height="24px" />}
      </Box>
    );
  }, [themeAdditionalData, onBackPress, history, logo, t]);

  return (
    <>
      <div className="header" style={style}>
        <header className="header__container">
          <Grid
            item
            xs={12}
            className="header__subcontainer"
            container
            alignItems="center"
            sx={{ display: { xs: 'flex', md: 'none' } }}
          >
            <Accordion style={{ flex: 1 }} expanded={expanded}>
              <AccordionSummary
                style={{ marginTop: 4, marginBottom: 4 }}
                expandIcon={
                  hideExpandBtn ? (
                    <></>
                  ) : (
                    <Box
                      mr={1}
                      display="flex"
                      alignItems="center"
                      position="relative"
                      onClick={() => setExpanded(!expanded)}
                    >
                      <Menu />
                    </Box>
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box display="flex" alignItems="center" flexGrow={1}>
                  <Box className="header__iconcontainer" display="flex" alignItems="center">
                    {onBackPress && renderBackArrow}
                  </Box>
                  <Box>
                    {title && (
                      <Typography variant="h4" align="center">
                        {title}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <HeaderMenu
                  elements={elements}
                  hasMultipleOnboarding={hasMultipleOnboarding}
                  isOnPreRegisterPage={isOnPreRegisterPage}
                  isAuthenticated={isAuthenticated}
                  showUnsavedChangesConfirmationModal={showUnsavedChangesConfirmationModal}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            item
            xs={12}
            className="header__subcontainer"
            container
            alignItems="center"
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            <Box display="flex" alignItems="center" flexGrow={1}>
              <Box>
                <Box className="header__iconcontainer" display="flex" alignItems="center">
                  {onBackPress && renderBackArrow}
                </Box>
                <Box>
                  {title && (
                    <Typography variant="h4" align="center">
                      {title}
                    </Typography>
                  )}
                </Box>
              </Box>
              <HeaderMenu
                elements={elements}
                hasMultipleOnboarding={hasMultipleOnboarding}
                isOnPreRegisterPage={isOnPreRegisterPage}
                isAuthenticated={isAuthenticated}
                showUnsavedChangesConfirmationModal={showUnsavedChangesConfirmationModal}
              />
            </Box>
          </Grid>
          <div className="tag-ds ">
            <div className="separator" />
          </div>
        </header>
      </div>
    </>
  );
};
