import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useGeneralInfo = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { page } = flowWrapper.getCurrentStepAndPage();
  const active = page === 'GENERAL_INFO';
  const completed = flowWrapper.isPageCompleted('CANDIDATE_DETAILS', 'GENERAL_INFO');
  const disabled = !(active || completed);

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      const step = steps.find((x) => x.name === 'GENERAL_INFO');
      setPageStepContext({ step, page: undefined });
    },
  };
};
