import { OOSelectedElement } from '@features/oneOnboarding/interfaces';
import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { deserializeDateFromUrl } from '@features/oneOnboardingAdmin/pages/AdminDashboard/adminHelpers';
import { IKey } from '@models/dialog';
import { Onboarding } from '@models/onboarding.model';
import { OnboardingFlowUsersModel, OnboardingUserDetails } from '@models/onboarding-steps';
import { QuickFilterModel } from '@models/quick-filter.model';
import { UserProfileModel } from '@models/userProfile.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DashboardDatePicker {
  appointmentStartDate: Date | string;
  appointmentEndDate: Date | string;
}
interface DashboardSlice extends DashboardDatePicker {
  selectedUser: OnboardingFlowUsersModel | undefined;
  selectedOnboarding: Onboarding | undefined;
  approvedApplicationId: string;
  userProfile: UserProfileModel | undefined;
  onboardingUserDetails: OnboardingUserDetails | undefined;
  quickFilterItems: QuickFilterModel[];
  skippedCandidates: OnboardingFlowUsersModel[];
  userData: IKey;
  recruiterElements: OOSelectedElement[];
  candidateSelectedPage: OOPageModel | undefined;
  selectedDocument: OODocumentModel | undefined;
}

const dateFromUrl = deserializeDateFromUrl(window.location.search);

const initialState: DashboardSlice = {
  selectedUser: undefined,
  selectedOnboarding: undefined,
  approvedApplicationId: '',
  userProfile: undefined,
  onboardingUserDetails: undefined,
  quickFilterItems: [],
  skippedCandidates: [],
  userData: {},
  recruiterElements: [],
  candidateSelectedPage: undefined,
  selectedDocument: undefined,
  appointmentStartDate: dateFromUrl?.onboardingStartDate || '',
  appointmentEndDate: dateFromUrl?.onboardingEndDate || '',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectUser: (state, action: PayloadAction<OnboardingFlowUsersModel | undefined>) => {
      state.selectedUser = action.payload;
      return state;
    },

    selectOnboarding: (state, action: PayloadAction<Onboarding | undefined>) => {
      state.selectedOnboarding = action.payload;
      return state;
    },

    setUserProfile: (state, action: PayloadAction<UserProfileModel | undefined>) => {
      state.userProfile = action.payload;
      return state;
    },

    setApprovedApplicationId: (state, action: PayloadAction<any>) => {
      state.approvedApplicationId = action.payload.approvedApplicationId;
      return state;
    },

    setAppointmentStartDate: (state, action: PayloadAction<any>) => {
      state.appointmentStartDate = action.payload;
      return state;
    },

    setAppointmentEndDate: (state, action: PayloadAction<any>) => {
      state.appointmentEndDate = action.payload;
      return state;
    },

    setOnboardingData: (state, action: PayloadAction<OnboardingUserDetails>) => {
      state.onboardingUserDetails = action.payload;
      state.userData = action.payload.userSteps
        .map(({ data }: { data: any }) => data)
        .reduce((acc: any, curr: any) => {
          if (curr) {
            Object.assign(acc, curr);
          }

          return acc;
        }, {});
      return state;
    },

    setSkippedCandidates: (state, action: PayloadAction<OnboardingFlowUsersModel[]>) => {
      state.skippedCandidates = action.payload;
      return state;
    },

    setRecruiterElements: (state, action: PayloadAction<OOSelectedElement[]>) => {
      state.recruiterElements = action.payload;
      return state;
    },
    setCandidateSelectedPage: (state, action: PayloadAction<OOPageModel>) => {
      state.candidateSelectedPage = action.payload;
      return state;
    },
    setSelectedDocument: (state, action: PayloadAction<OODocumentModel>) => {
      state.selectedDocument = action.payload;
      return state;
    },
  },
});
const { actions } = dashboardSlice;

const { setCandidateSelectedPage, selectOnboarding, setSelectedDocument } = actions;

export { setCandidateSelectedPage, selectOnboarding, setSelectedDocument };
