import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@components/app/Header/Header';
import { Container, Grid } from '@mui/material';

export interface AccessUnauthorizedProps {
  isAuthenticated?: boolean;
}
export const AccessUnauthorized: React.FC<AccessUnauthorizedProps> = ({ isAuthenticated = false }) => {
  const { t } = useTranslation('candidate');

  return (
    <Container maxWidth="md">
      <Header isAuthenticated={isAuthenticated} hideExpandBtn={true} />
      <Grid container spacing={3} direction="column">
        <Grid item>
          <p>{t('candidate:GENERAL.PAGES.accessUnathorized')}</p>
        </Grid>
      </Grid>
    </Container>
  );
};
