import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import jwt from 'jsonwebtoken';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useInjection } from '@context/inversify-context-provider';
import { AuthHelper, AzureB2CRedirectType, InvalidCodeVerifierError } from '@helpers/authHelper';
import {
  getApplicationId,
  getClientOrganizationId,
  getSelectedConfigurationId,
  getTenantCountryCode,
  getTenantDefaultLanguage,
} from '@helpers/tenant.helper';
import { Utils } from '@helpers/utils';
import { Box, CircularProgress, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { toastSlice } from '@store/slices/toast.slice';

import { AamBackendApi } from '../../libs/aamBackendApi';
import { Logger } from '../../libs/logger';
import { store } from '../../store';

const compareInputs = (inputKeys: any, oldInputs: any, newInputs: any) => {
  inputKeys.forEach((key: any) => {
    const oldInput = oldInputs[key];
    const newInput = newInputs[key];
    if (oldInput !== newInput) {
      Utils.consoleLog('change detected', key, 'old:', oldInput, 'new:', newInput);
    }
  });
};
const useDependenciesDebugger = (inputs: any) => {
  const oldInputsRef = useRef(inputs);
  const inputValuesArray = Object.values(inputs);
  const inputKeysArray = Object.keys(inputs);
  useMemo(() => {
    const oldInputs = oldInputsRef.current;

    compareInputs(inputKeysArray, oldInputs, inputs);

    oldInputsRef.current = inputs;
  }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
};

export const AppB2CHandler: React.FC = () => {
  const { t } = useTranslation(['candidate']);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { i18n } = useTranslation();
  const utils = useInjection(Utils);
  const authHelper = useInjection(AuthHelper);
  const aamBackendApi = useInjection(AamBackendApi);
  const logger = useInjection(Logger);
  const { tenantId, useOneOnboarding } = useSelector((state: RootState) => state.tenant);
  const b2cSettings = useSelector((state: RootState) => state.featureConfiguration.b2c);
  const useExternalBrowser = useSelector(
    (state: RootState) => state.featureConfiguration.appSettings.useExternalBrowser,
  );

  const [deletedAccount, setDeletedAccount] = useState(false);
  const [isAccountLocked, setIsAccountLocked] = useState(false);

  const hashes = useMemo(() => utils.extractHashAndSearch(location.hash, location.search), [
    utils,
    location.hash,
    location.search,
  ]);
  const hashState = useMemo(() => hashes.state && JSON.parse(Utils.decodeBase64(hashes.state)), [hashes.state]);
  useDependenciesDebugger({
    aamBackendApi,
    authHelper,
    b2cSettings,
    dispatch,
    hashState,
    hashes,
    history,
    location,
    tenantId,
    useExternalBrowser,
  });

  useEffect(() => {
    const asyncCall = async () => {
      try {
        if (hashes?.error) {
          // Forgot password -> https://docs.microsoft.com/en-us/azure/active-directory-b2c/add-password-reset-policy?pivots=b2c-user-flow
          // Error handling -> https://docs.microsoft.com/en-us/azure/active-directory-b2c/authorization-code-flow
          const getCurrentLng = () =>
            i18n.language || window.localStorage.i18nextLng || getTenantDefaultLanguage(tenantId);
          const isResetPassword = hashes.error_description.startsWith('AADB2C90118');
          if (isResetPassword) {
            const loginHint = hashes.error_description.split('login_hint: ')[1];
            const url = await authHelper.getAuthorizeUrlResetPassword(
              b2cSettings.resetPassword,
              location,
              hashState?.email || loginHint,
              {
                from: AzureB2CRedirectType.signIn,
                email: hashState?.email || loginHint,
                isEmailInputDisabled: hashState?.isEmailInputDisabled,
              },
            );
            authHelper.b2cNavigation(
              b2cSettings.resetPassword,
              url + '&ui_locales=' + getCurrentLng() + '&country=' + getTenantCountryCode(tenantId),
              history,
              useExternalBrowser,
            );
            return;
          }

          // https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
          const isUserCancellation = hashes.error_description.startsWith('AADB2C90091');
          if (isUserCancellation) {
            history.push('/');
            return;
          }

          dispatch(
            toastSlice.actions.showError({ title: hashes.error, message: hashes.error_description.split('\n')[0] }),
          );
          // TODO: Add localisation here https://dev.azure.com/WW-ApplicationManager2020/APP20/_workitems/edit/4547
          const msg = hashes.error + hashes.error_description?.split('\n')[0];
          Utils.consoleError(msg);
          history.push('/');
          logger.error(msg);
          return;
        }

        if (hashes?.code) {
          Utils.consoleLog('getCodeVerifierFromSessionStorage', authHelper.getCodeVerifierFromSessionStorage());
          if (authHelper.getCodeVerifierFromSessionStorage() === '') {
            Utils.consoleError('No code verifier in session storage');
            return;
          }

          const settingsToUse = (() => {
            switch (hashState?.action) {
              case AzureB2CRedirectType.signUp:
                return b2cSettings.signUp;
              case AzureB2CRedirectType.signIn:
                return b2cSettings.signIn;
              case AzureB2CRedirectType.refresh:
                return b2cSettings.signIn;
              case AzureB2CRedirectType.resetPassword:
                return b2cSettings.resetPassword;
            }
          })();

          const tokenResponse = await authHelper.exchangeAuthCode(
            hashes.code,
            settingsToUse as any,
            authHelper.redirectUrl,
          );
          Utils.consoleLog('Got tokenResponse', tokenResponse);
          const hashStateParsed = JSON.parse(Utils.decodeBase64(hashes.state));
          let userId;
          const userAcceptedTerms: boolean = hashStateParsed.userAcceptedTerms
            ? hashStateParsed.userAcceptedTerms
            : false;

          if (useOneOnboarding) {
            if (hashStateParsed.action === 'signIn') {
              const campaignUrl = localStorage.getItem('campaignUrl');

              const response = await aamBackendApi.b2cTokenReceived({
                token: tokenResponse.idToken,
                clientOrganizationId: getClientOrganizationId()!,
                email: hashStateParsed?.email,
                campaignId: campaignUrl,
              });
              store.dispatch(oneOnboardingSlice.actions.setClientOrganizationId(getClientOrganizationId()));
              userId = response.userId;

              if (campaignUrl) {
                localStorage.removeItem('campaignUrl');
                logger.log(`Campaign import started: ${campaignUrl}`);
                await aamBackendApi.campaignImport(
                  getClientOrganizationId()!,
                  getSelectedConfigurationId()!,
                  userId,
                  campaignUrl,
                );
                logger.log(`Campaign import finished: ${campaignUrl}`);
              }

              logger.log('User signed in');
            } else {
              const response = await aamBackendApi.b2cTokenReceived({
                token: tokenResponse.idToken,
                clientOrganizationId: getClientOrganizationId()!,
                consent: hashStateParsed,
              });
              store.dispatch(oneOnboardingSlice.actions.setClientOrganizationId(getClientOrganizationId()));
              userId = response.userId;
              logger.log('User registered successfully');
            }
          } else {
            const response = await aamBackendApi.b2cTokenReceived({
              token: tokenResponse.idToken,
              consent: userAcceptedTerms,
            });
            userId = response.userId;
          }

          const idTokenPayload: any = jwt.decode(tokenResponse.idToken);
          await authHelper.finishLoginB2C(tenantId, userId, tokenResponse, dispatch);

          if (useOneOnboarding) {
            dispatch(
              oneOnboardingSlice.actions.setCurrentForm({
                step: 'CANDIDATE_DETAILS',
                form: 'PERSONAL_INFO',
              }),
            );
            const applicationId = getApplicationId();
            history.push('/oo/flow', { redirect: !!applicationId });
          } else {
            if (idTokenPayload && userAcceptedTerms) {
              aamBackendApi.updateUser(tenantId, userId, { userAcceptedTerms });
            }
          }
          return;
        }
        if (hashes?.id_token) {
          const idTokenPayload: any = jwt.decode(hashes.id_token);
          const response = await aamBackendApi.b2cTokenReceived({
            token: hashes.id_token,
            consent: true,
          });

          await authHelper.finishLoginB2C(
            tenantId,
            response.userId,
            {
              idToken: hashes.id_token,
              idTokenExpiresIn: idTokenPayload.exp,
              notBefore: idTokenPayload.nbf,
              scope: 'openid',
              profileInfo: '--',
              refreshToken: '',
              refreshTokenExpiresIn: 1,
              tokenType: 'Bearer',
            },
            dispatch,
          );

          dispatch(
            oneOnboardingSlice.actions.setCurrentForm({
              step: 'CANDIDATE_DETAILS',
              form: 'PERSONAL_INFO',
            }),
          );
          const applicationId = getApplicationId();
          history.push('/oo/flow', { redirect: !!applicationId });
        }
      } catch (err) {
        const isLocked = err?.response?.data?.error?.data?.message?.includes('User locked');

        setIsAccountLocked(isLocked);

        if (err instanceof InvalidCodeVerifierError) {
          window.location.href = '/app/login?sessionExpired=true';
          return;
        }
        setDeletedAccount(true);
        Utils.consoleError('Failed to apply token' + JSON.stringify(err));
        logger.error('Failed to apply token');
        // await authHelper.logout(dispatch);
      }
    };
    asyncCall();
  }, [
    useOneOnboarding,
    aamBackendApi,
    authHelper,
    b2cSettings,
    dispatch,
    hashState,
    hashes,
    history,
    i18n.language,
    location,
    tenantId,
    useExternalBrowser,
    logger,
  ]);

  const getLockedAccountMessage = useMemo(() => {
    if (i18n.exists('candidate:GENERAL.CANDIDATE_DELETION.lockedAccountMessage')) {
      return t('candidate:GENERAL.CANDIDATE_DELETION.lockedAccountMessage');
    }

    return t('candidate:GENERAL.CANDIDATE_DELETION.deletedAccountMessage');
  }, [i18n, t]);

  return deletedAccount ? (
    <Box mt={6}>
      <Typography variant="h3" component="p" align="center">
        {isAccountLocked ? getLockedAccountMessage : t('candidate:GENERAL.CANDIDATE_DELETION.deletedAccountMessage')}
      </Typography>
    </Box>
  ) : (
    <CircularProgress />
  );
};
