import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

import { OOStepModel } from '../models/StepModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';
import { OOForm } from './Form';

interface OOStepProps {
  step: OOStepModel;
  flowWrapper: OOFlowWrapper;
}

export const OOStep: React.VoidFunctionComponent<OOStepProps> = ({ step, flowWrapper }: OOStepProps) => {
  const currentForm = useSelector((state: RootState) => state.oneOnboarding.form);

  if (currentForm === null) {
    return <>Unable to show form</>;
  }
  const form = step.getForm(currentForm);
  if (!form) {
    return <>Unable to show form</>;
  }

  return <OOForm form={form} flowWrapper={flowWrapper} />;
};
