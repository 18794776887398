import React from 'react';
import { Trans } from 'react-i18next';
import { FormControl, Typography, useTheme } from '@mui/material';

import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';
import { FormControlLoader } from '../FormControlLoader';

type DefaultConsentWithoutCheckmarkFieldProps = {
  controlModel: OOControlModelInterface;
};

export const DefaultConsentWithoutCheckmark: React.FC<DefaultConsentWithoutCheckmarkFieldProps> = ({
  controlModel,
}: DefaultConsentWithoutCheckmarkFieldProps) => {
  const theme = useTheme();
  const { t, translationsLoading } = useT('entry');

  if (translationsLoading) {
    return <FormControlLoader />;
  }
  return (
    <FormControl>
      <div>
        <Typography variant="body1" className="static-text consent-wo-mark">
          <Trans
            i18nKey={t(controlModel.description)}
            components={{
              anchor: (
                <a
                  href={controlModel.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.palette.info.dark }}
                >
                  {' '}
                </a>
              ),
            }}
          />
          {controlModel.link ? (
            <span className="specialColor">
              <a
                href={controlModel.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.palette.info.dark }}
              >
                {t(controlModel.label)}
              </a>
            </span>
          ) : (
            <span>{t(controlModel.description)}</span>
          )}
        </Typography>
      </div>
    </FormControl>
  );
};
