import { Dispatch } from '@reduxjs/toolkit';
import { handleDisyDocumentName } from '@store/slices/selfService.slice';

import { SelectedConfiguration } from '../models/configurationModels';

const allControlsInConfiguration = (selectedConfiguration: SelectedConfiguration) => {
  return selectedConfiguration.features.reduce((accumulator: any, feature: any) => {
    feature.steps.forEach((step: any) => {
      if (step.pages) {
        step.pages.forEach((page: any) => {
          if (page.controls) {
            const controlNames = page.controls.map((control: any) => control.name);
            accumulator.push(...controlNames);
          }
        });
      }
    });
    return accumulator;
  }, []);
};

const allDocumentsInConfiguration = (selectedConfiguration: SelectedConfiguration) => {
  return selectedConfiguration.features.reduce((accumulator: any, feature: any) => {
    feature.steps.forEach((step: any) => {
      if (step.documentations) {
        step.documentations.forEach((page: any) => {
          accumulator.push(page.name);
        });
      }
    });
    return accumulator;
  }, []);
};

export const getAvailableControls = (selectedConfiguration: SelectedConfiguration, allControlsForTenant: any) => {
  const showOnlyUnique = allControlsForTenant.filter(
    ({ name }: { name: string }) => !allControlsInConfiguration(selectedConfiguration).some((y: any) => y === name),
  );

  return showOnlyUnique;
};

export const getAvailableDocuments = (selectedConfiguration: SelectedConfiguration, allDocumentsForTenant: any) => {
  const showOnlyUnique = allDocumentsForTenant?.filter(
    ({ name }: { name: string }) =>
      !allDocumentsInConfiguration(selectedConfiguration).some((y: any) => {
        return y === name;
      }),
  );

  return showOnlyUnique;
};

export const findAvailableControls = (selectedConfiguration: SelectedConfiguration) => {
  const entryIndex = selectedConfiguration.features.findIndex((x) => x.name === 'ENTRY');
  const candidateDetailsIndex = selectedConfiguration.features[entryIndex].steps.findIndex(
    (x: { name: string }) => x.name === 'CANDIDATE_DETAILS',
  );

  return selectedConfiguration.features[entryIndex].steps[candidateDetailsIndex].pages.flatMap(
    (x: { controls: any }) => {
      return x.controls;
    },
  );
};

export const getAllConditionElements = (feature: any) => {
  const occurrences: any[] = [];

  function search(obj: any, keys: string[]) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (keys.includes(prop)) {
          const isNotEmpty = obj[prop].length > 0;
          if (isNotEmpty) {
            obj[prop].forEach((x: any) => {
              occurrences.push(x.control);
            });
          }
        } else if (typeof obj[prop] === 'object') {
          search(obj[prop], keys);
        }
      }
    }
  }

  search(feature, ['mandatoryCondition', 'renderCondition', 'condition']);

  return occurrences;
};

export const storeDisyDocumentName = (documentName: string, disyDocumentName: string, dispatch: Dispatch) => {
  dispatch(handleDisyDocumentName({ documentName, disyDocumentName }));
};

export const getDisyDocumentName = (documentName: string, disyDocumentObject: { [key: string]: string }) => {
  return disyDocumentObject?.[documentName];
};

enum DatepickerValues {
  future = 1,
  past = -1,
  olderThan18 = -6575,
}

export const convertOffsetToValue = (minOffset: number, maxOffset: number) => {
  if (minOffset === DatepickerValues.future) {
    return 'future';
  }

  if (maxOffset === DatepickerValues.past) {
    return 'past';
  }

  if (minOffset === DatepickerValues.olderThan18) {
    return 'olderThan18';
  }

  return 'anyDate';
};
