import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
interface UploadCandidateProps {
  candidatesFileHandler: (e: any) => void;
  disabled: boolean;
}

const UploadCandidate: React.FC<UploadCandidateProps> = ({ candidatesFileHandler, disabled }) => {
  const inputRef = useRef<any>(null);
  const disabledProp = disabled ? { disabled: true } : {};
  const { t } = useTranslation(['recruiter']);

  return (
    <div className="tag-ds">
      <button
        style={{ width: '100%' }}
        onClick={() => {
          inputRef && inputRef.current && inputRef.current.click();
        }}
        {...disabledProp}
      >
        {t(`recruiter:GENERAL.IMPORT.uploadCandidates`)}
        <input
          ref={inputRef}
          type="file"
          hidden
          id="uploadAmazonSFImportButton"
          accept=".xlsx"
          onChange={candidatesFileHandler}
          onClick={() => (inputRef.current.value = null)}
        />
      </button>
    </div>
  );
};

export default UploadCandidate;
