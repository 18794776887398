import React, { FC, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useInjection } from '@context/inversify-context-provider';
import { useAllConfigurationsForTenant } from '@hooks/apiHooks';
import { Tenant, useTenant } from '@hooks/useComponentMapping';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import AdminCsvExportButton from '../../components/AdminCsvExportButton/AdminCsvExportButton';
import AdminCsvImportButton from '../../components/AdminCsvImportButton/AdminCsvImportButton';
import { AdminAssetDirectory } from './AdminAssetDirectory';
import { configFileExtension } from './adminImportHelpers';
import { AdminLinkButton } from './AdminLinkButton';
import { AdminSimpleButton } from './AdminSimpleButton';
import { AdminUploadButton } from './AdminUploadButton';

import styles from './AdminImport.module.scss';

interface ImportGeneralType {
  successMessage: string;
  errorMessage: string;
  formData: FormData;
  handler: (tenantId: Tenant, formData: FormData) => void;
  itemsRefetch?: () => void;
}
interface AdminClientOrganizationProps {
  item: any;
  otherTenantId?: number;
  showAssets?: boolean;
  importBranch: (tenantId: Tenant, formData: FormData) => Promise<void>;
  importGeneral: (parameters: ImportGeneralType) => Promise<void>;
  handleConfigRemoval: (configTenantId: number, clientOrganizationId: string, id: string) => void;
  handleConfigExport: (configTenantId: number, clientOrganizationId: string, id: string) => void;
  handleAssetRemove: (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => Promise<void>;
  importSites: (tenantId: Tenant, formData: FormData) => Promise<void>;
  assetBoxes: string[];
  toggleAssetBox: (strId: string) => void;
  handleAssetPreview: (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => void;
  handleAssetUpload: (
    e: any,
    targetDir: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ) => Promise<void>;
  handleAssetDownload: (e: any, dir: string, clientOrganizationId?: string, configurationId?: string) => void;
  handleAddConfig: (
    e: any,
    clientOrg: { isNewClient: boolean; id?: string; name?: string; displayName?: string; description?: string },
    isUpdate: boolean,
    configId?: string,
  ) => void;
}

export const AdminClientOrganization: FC<AdminClientOrganizationProps> = ({
  item,
  otherTenantId,
  showAssets,
  importBranch,
  importGeneral,
  handleConfigRemoval,
  handleConfigExport,
  handleAssetRemove,
  importSites,
  assetBoxes,
  toggleAssetBox,
  handleAssetPreview,
  handleAssetUpload,
  handleAssetDownload,
  handleAddConfig,
}) => {
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useTenant();
  const { refetch: clientOrganizationsRefetch } = useAllConfigurationsForTenant(tenantId);
  const assetDirsToManage = ['documents-for-signature', 'examples', 'documents-for-export'];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setClipboard] = useState('');

  const { t } = useTranslation(['recruiter']);
  const { data } = useQuery({
    queryKey: ['fetchClientOrganizationForTenantByTenant'],
    queryFn: () => aamBackendApi.fetchClientOrganizationForTenantByTenant(),
  });
  const onCopy = (msg: string, result: boolean) => {
    if (result) {
      setClipboard(msg);
    }
  };
  const copyMsg = t('Click to copy to clipboard');
  const getTenantLabel = (tId: number): string => {
    const t = data?.find((itm: any) => itm.id === tId);
    if (!t) {
      return '--';
    }
    return `${t.id} - ${t.name}`;
  };
  const importHandlers = new Map<
    | 'Branch'
    | 'Client'
    | 'CoVendor'
    | 'JobShiftType'
    | 'JobType'
    | 'Location'
    | 'Region'
    | 'Requisition'
    | 'Site'
    | 'CivilStatus'
    | 'PurchaseOrder'
    | 'ClientOrganization',
    (tenantId: Tenant, formData: FormData) => Promise<void>
  >([
    ['Branch', importBranch],
    [
      'Client',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: 'Clients successfully imported!',
          errorMessage: 'Clients import error',
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'clients', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'CoVendor',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'covendors', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'JobShiftType',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'jobshifttypes', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'JobType',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'jobtypes', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'Location',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsvLocations(formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'Region',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsvRegions(formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'Requisition',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'requisitions', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    ['Site', importSites],
    [
      'CivilStatus',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'civilStatuses', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'PurchaseOrder',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
          errorMessage: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          formData,
          handler: (tenantId, formData) => aamBackendApi.importCsv(tenantId, 'purchaseorders', formData),
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
    [
      'ClientOrganization',
      async (tenantId, formData) =>
        importGeneral({
          successMessage: 'Client Organizations successfully imported!',
          errorMessage: 'Client Organizations import error',
          formData,
          handler: aamBackendApi.importCsvClientOrganization,
          itemsRefetch: clientOrganizationsRefetch,
        }),
    ],
  ]);
  return (
    <div key={item.id} className={styles.clientOrganization + (otherTenantId ? ' ' + styles.otherTenant : '')}>
      <div className={styles.flexHolder}>
        <span className={styles.clientOrganizationLabel}>
          {item.name}
          {otherTenantId ? (
            <span style={{ color: '#f33' }}>
              {' '}
              [{t('Tenant')}: {getTenantLabel(otherTenantId)}]
            </span>
          ) : (
            ''
          )}
        </span>{' '}
        <CopyToClipboard text={item.id} onCopy={onCopy}>
          <span title={copyMsg} className={styles.clientOrganizationId}>
            {item.id}
          </span>
        </CopyToClipboard>
      </div>
      <br />
      <div className={styles.clientOrganizationConfigs}>
        {item.selectedConfigurations && item.selectedConfigurations?.length > 0 ? (
          <>
            <div className={styles.clientOrganizationConfigList}>
              {item.selectedConfigurations?.map((cfg: any, i: number) => {
                return (
                  <div key={'cfg-' + item.id + cfg.id + i}>
                    {cfg.tenantId === tenantId ? (
                      <>
                        <div className={styles.clientOrganizationConfigItem}>
                          <span>{i + 1}.</span>
                          <span>
                            <CopyToClipboard text={cfg.id} onCopy={onCopy}>
                              <span title={copyMsg} className={styles.clientOrganizationConfig}>
                                {cfg.recruiterName ? t(`${cfg.recruiterName}`) : cfg.id}
                              </span>
                            </CopyToClipboard>
                          </span>
                          <AdminSimpleButton
                            label={t('GENERAL.CONFIGURATION.RemoveConfiguration')}
                            onClick={() => {
                              handleConfigRemoval(cfg.tenantId, cfg.clientOrganizationId, cfg.id);
                            }}
                          />
                          <span
                            style={{
                              padding: '0 8px',
                            }}
                          >
                            <AdminUploadButton
                              label={t('GENERAL.CONFIGURATION.UpdateConfiguration')}
                              extension={configFileExtension}
                              onChange={(e: any) =>
                                handleAddConfig(e, { isNewClient: false, id: item.id }, true, cfg.id)
                              }
                            />
                          </span>
                          <AdminLinkButton
                            onClick={() => {
                              handleConfigExport(cfg.tenantId, cfg.clientOrganizationId, cfg.id);
                            }}
                          >
                            {t('GENERAL.CONFIGURATION.ExportConfiguration')}
                          </AdminLinkButton>
                        </div>
                        {cfg.referenceData && cfg.referenceData.length > 0 && (
                          <>
                            <div className={styles.flexHolder + ' ' + styles.impExpHolder}>
                              <span>{t('GENERAL.CONFIGURATION.ConfigurationReferenceDataImport')}</span>
                              <AdminCsvImportButton
                                options={cfg.referenceData}
                                configurationId={cfg.id}
                                clientOrganizationId={cfg.clientOrganizationId}
                                style={{
                                  padding: '2px 8px',
                                }}
                                handlers={importHandlers}
                              />
                            </div>
                            <div className={styles.flexHolder + ' ' + styles.impExpHolder}>
                              <span>{t('GENERAL.CONFIGURATION.ConfigurationReferenceDataExport')}</span>
                              <AdminCsvExportButton
                                options={cfg.referenceData}
                                style={{
                                  padding: '2px 8px',
                                }}
                              />
                            </div>
                          </>
                        )}
                        {showAssets &&
                          assetDirsToManage.map((dir, ii) => (
                            <AdminAssetDirectory
                              dir={dir}
                              strId={item.id + cfg.id + dir}
                              clientOrganizationId={item.id}
                              configurationId={cfg.id}
                              key={'asset-dir-' + item.id + cfg.id + i + ii}
                              assetBoxes={assetBoxes}
                              toggleAssetBox={toggleAssetBox}
                              handleAssetPreview={handleAssetPreview}
                              handleAssetUpload={handleAssetUpload}
                              handleAssetDownload={handleAssetDownload}
                              handleAssetRemove={handleAssetRemove}
                            />
                          ))}
                      </>
                    ) : (
                      <>
                        <CopyToClipboard text={cfg.id} onCopy={onCopy}>
                          <span title={copyMsg} className={styles.clientOrganizationConfig}>
                            {cfg.recruiterName ? t(cfg.recruiterName) : cfg.id}
                          </span>
                        </CopyToClipboard>
                        <span style={{ color: '#f33' }}>
                          [{t('Tenant')}: {getTenantLabel(cfg.tenantId)}]
                        </span>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <span className={styles.noConfigs}>{t('GENERAL.CONFIGURATION.NoSelectedConfigs')}</span>
        )}
      </div>
    </div>
  );
};
