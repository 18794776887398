import React, { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';

import { CandidatePageLayout } from '../components/CandidatePageLayout/CandidatePageLayout';

export const OOCandidateOnHold: VoidFunctionComponent = () => {
  const { t } = useTranslation('documents');

  return (
    <CandidatePageLayout hideOverview={true} enableUnsaveChangesDialog={false}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        minHeight="80vh"
        sx={{ margin: '16px auto 0 auto' }}
      >
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography variant="h3">{t('actions.recruiterActionNeeded')}</Typography>
          </Grid>
          <Grid item>
            <Typography>{t('actions.processStep')}</Typography>
          </Grid>
        </Grid>
      </Box>
    </CandidatePageLayout>
  );
};
