import React, { FC, ReactNode, useContext } from 'react';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { useInjection } from '@context/inversify-context-provider';
import { DocumentCommand, OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { OODocumentationModel } from '@features/oneOnboarding/models/DocumentationModel';
import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';

import { DownloadDocument } from './DowloadDocument';
import { UploadDocument } from './UploadDocument';
import { ViewDocument } from './ViewDocument';

export interface DocumentStatusProps {
  documentModel: OODocumentModel;
  documentation: OODocumentationModel;
}
export const DocumentActions: FC<DocumentStatusProps> = ({ documentModel, documentation }) => {
  const { setDocument, setDocumentationModel, setCommand } = useContext(OODocumentContext);
  const aamBackendApi = useInjection(AamBackendApi);
  const handleDocument = (documentCommand: DocumentCommand) => {
    setDocument(documentModel);
    setDocumentationModel(documentation);
    setCommand(documentCommand);
  };

  const dowloadDocument = async () => {
    documentModel.parts.forEach(async (part: any) => {
      if (part.userDocumentPart.userDocumentId) {
        try {
          const doc = await aamBackendApi.downloadDocumentWithFullResponse(
            part.userDocumentPart.tenantId,
            part.userDocumentPart.userId,
            `${part.userDocumentPart.userDocumentId}/${part.name}`,
          );
          if (doc.data) {
            const url = window.URL.createObjectURL(new Blob([doc.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', part.userDocumentPart.metadata.filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } catch (err) {
          alert(`Unable to dowload ${part.userDocumentPart.userDocumentId}/${part.name} error: ${err.message}`);
        }
      }
    });
  };
  const actions: ReactNode[] = [];
  if (
    !documentModel.userDocument ||
    documentModel.userDocument.additionalData?.status === OnboardingDocumentStatus.REJECTED
  ) {
    actions.push(
      <UploadDocument
        handleDocument={() => handleDocument(DocumentCommand.UPLOAD)}
        disabled={documentModel.isDisable}
      />,
    );
  } else {
    actions.push(<DownloadDocument handleDocument={async () => await dowloadDocument()} />);
  }

  if (documentModel.userDocument) {
    actions.push(<ViewDocument handleDocument={() => handleDocument(DocumentCommand.VIEW)} />);
  }

  return (
    <div className="feature-candidate-profile__documents-item-actions">
      {actions.map((action, index) => (
        <div key={index} style={{ marginLeft: 16 }}>
          {action}
        </div>
      ))}
    </div>
  );
};
