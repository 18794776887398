import React from 'react';
import { Grid } from '@mui/material';

import { OOControlModel } from '../../models/ControlModel';
import { OOFormControl } from '../FormControl';

interface ContainerWithSubcontrolsProps {
  controlModel: OOControlModel;
  filterSubControls?: (c: OOControlModel) => boolean;
}

const ContainerWithSubcontrols: React.FC<ContainerWithSubcontrolsProps> = ({ controlModel, filterSubControls }) => {
  const { subControls, name } = controlModel;

  if (!filterSubControls || !subControls) {
    throw Error('Missing properties!');
  }

  return (
    <div className="sub-controls-wrapper">
      <>
        {(filterSubControls
          ? (subControls as Array<OOControlModel>).filter((c: any) => filterSubControls(c))
          : subControls
        ).map((subControl: any) => (
          <Grid key={name} item xs={12} style={{ minWidth: '100%' }}>
            <OOFormControl key={subControl.name} control={subControl} />
          </Grid>
        ))}
      </>
    </div>
  );
};

export default ContainerWithSubcontrols;
