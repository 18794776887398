import { OOUserDocumentPartModel } from './UserDocumentPartModel';
interface ConstructorParams {
  name: string;
  header: string;
  label: string;
  description: string;
  sampleUrl: string;
  isMandatory: boolean;
  isDownloadSampleEnabled: boolean;
  userDocumentPart: OOUserDocumentPartModel;
}

export class OODocumentPartModel {
  public name: string;
  public header: string;
  public label: string;
  public description: string;
  public sampleUrl: string;
  public isMandatory: boolean;
  public isDownloadSampleEnabled: boolean;
  public userDocumentPart: OOUserDocumentPartModel;

  constructor({
    name,
    header,
    label,
    description,
    sampleUrl,
    isMandatory,
    isDownloadSampleEnabled,
    userDocumentPart,
  }: ConstructorParams) {
    this.name = name;
    this.header = header;
    this.label = label;
    this.description = description;
    this.sampleUrl = sampleUrl;
    this.isMandatory = isMandatory;
    this.isDownloadSampleEnabled = isDownloadSampleEnabled;
    this.userDocumentPart = userDocumentPart ?? {};
  }

  public static fromJson(json: any) {
    const { name } = json;
    const { header } = json;
    const { label } = json;
    const { description } = json;
    const { sampleUrl } = json;
    const { isMandatory } = json;
    const { isDownloadSampleEnabled } = json;
    const userDocumentPart = json.userDocumentPart;
    return new OODocumentPartModel({
      name,
      header,
      label,
      description,
      sampleUrl,
      isMandatory,
      isDownloadSampleEnabled,
      userDocumentPart: userDocumentPart,
    });
  }
}
