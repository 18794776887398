import React, { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { CheckboxProps, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { checkboxControlError, checkboxErrorLabel } from '../../../helpers';
import { useT } from '../../../hooks/useT';
import { FormControlLoader } from '../../FormControlLoader';

import styles from './Checkbox.module.scss';

export type OOCheckboxProps = {
  validationKey: string;
  label: string | ReactNode;
  description: string | string[];
  mandatory: boolean;
  checkboxProps: CheckboxProps;
  defaultValue: string | undefined;
  descriptionStyle?: string | undefined;
  onChangeCallback?: (e: any) => void;
};

export const OOCheckbox: React.FC<OOCheckboxProps> = ({
  validationKey,
  checkboxProps,
  description,
  label,
  mandatory,
  defaultValue,
  descriptionStyle,
  onChangeCallback,
}) => {
  const { t, translationsLoading } = useT('entry', 'documents');
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);
  let prefix = label?.toString().split('.')[0].toLowerCase();

  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };

  if (prefix && prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  if (translationsLoading) {
    return <FormControlLoader />;
  }
  const renderDescriptionOrError = (error?: string) => {
    if (description) {
      if (descriptionStyle) {
        return (
          <span className={descriptionStyle}>
            {Array.isArray(description) ? (
              <>
                {description?.map((desc, index) => (
                  <FormHelperText key={index} style={{ marginTop: 16 }}>
                    {t(`${prefix}:${desc}`)}
                  </FormHelperText>
                ))}
              </>
            ) : (
              <FormHelperText>{t(`${prefix}:${description}`)}</FormHelperText>
            )}
          </span>
        );
      }
      return Array.isArray(description) ? (
        <span style={{ marginTop: 16 }}>
          {description?.map((desc, index) => (
            <FormHelperText key={index}>{t(`${prefix}:${desc}`)}</FormHelperText>
          ))}
        </span>
      ) : (
        <FormHelperText style={{ marginTop: 16 }}>{t(`${prefix}:${description}`)}</FormHelperText>
      );
    }
    if (error) {
      return <FormHelperText style={{ marginTop: 16 }}>{t(`validations.${error}`)}</FormHelperText>;
    }
  };

  return (
    <FormControl {...checkboxControlError(fieldMeta)}>
      <FormControlLabel
        className={`checkbox-container ${styles.checkboxLabel}`}
        label={
          <div className={`tag-ds checkbox-container ${styles.checkboxText}`}>
            <label htmlFor={validationKey}>
              {typeof label === 'string' ? t(`${prefix}:${label}`) + (mandatory ? ' *' : '') : label}
            </label>
          </div>
        }
        control={
          <>
            <div className={`tag-ds checkbox-container ${styles.checkboxText}`}>
              <input
                type="checkbox"
                id={validationKey}
                onChange={(e) => {
                  formik.setFieldValue(validationKey, e.target.checked);
                  onChangeCallback?.(e);
                }}
                checked={fieldProps.value === undefined ? defaultValue === 'true' : fieldProps.value}
                disabled={checkboxProps.disabled}
              />
            </div>
          </>
        }
      />

      {renderDescriptionOrError(checkboxErrorLabel(fieldMeta))}
    </FormControl>
  );
};
