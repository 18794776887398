import React from 'react';

import scssVars from '../../../../styles/theme1/_variables.scss';

export interface ArrowForwardIconProps {
  color?: 'primary' | 'secondary';
}

export const ArrowForwardIcon: React.FC<ArrowForwardIconProps> = ({ color = 'primary' }) => {
  const primary = scssVars.adeccoRed;
  const secondary = scssVars.white;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="pfe7e0twla"
          d="M20.965 10.301l4.733 4.849.007.007c.088.092.16.2.21.32l.016.04c.012.03.022.061.03.093l.007.025c.01.035.017.072.022.109 0 .008.002.015.003.023.005.043.008.088.008.133 0 .045-.003.089-.008.133l.008-.133c0 .052-.004.104-.011.155-.005.038-.013.074-.022.11l-.007.025c-.008.031-.018.061-.029.09-.005.014-.01.028-.017.042-.032.077-.073.15-.122.216l-.02.026c-.02.025-.04.048-.06.07l-.015.016-4.632 4.745c-.202.207-.467.31-.732.31-.265 0-.53-.103-.732-.31-.404-.414-.404-1.086 0-1.5l2.864-2.935H6.036c-.572 0-1.035-.474-1.035-1.06 0-.586.463-1.06 1.035-1.06l16.43-.001-2.965-3.038c-.404-.414-.404-1.086 0-1.5.405-.414 1.06-.414 1.464 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g transform="translate(-172 -764) translate(0 716) translate(148 24) translate(8 8) translate(16 16)">
                  <use fill={color === 'primary' ? primary : secondary} xlinkHref="#pfe7e0twla" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
