import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import { SelfServiceItemType } from '@models/selfService.model';
import { RootState } from '@store/rootReducer';
import { findDocumentInConfiguration } from '@store/slices/selfService.slice';

import AddItemFromLibrary from '../other/AddItemFromLibrary/AddItemFromLibrary';
import DocumentationListItem from './DocumentationListItem';

import styles from '../../ConfigurationSetup.module.scss';

export type DocumentPropertyType = 'document' | 'documents' | 'parts' | 'condition';

interface Props {
  setDocumentControlDetails: Dispatch<SetStateAction<{ type: DocumentPropertyType; controls: any } | null>>;
  selectedDocumentControl: any;
  setLibraryItem: ({ name, id }: { name: string; id: string }) => void;
}

const DocumentList: React.FC<Props> = ({ setDocumentControlDetails, selectedDocumentControl, setLibraryItem }) => {
  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const currentDocument = findDocumentInConfiguration(
    { featureName: selectedFeature?.featureName, stepName: selectedFeature?.stepName },
    selectedConfiguration,
  );

  return (
    <div className="tag-ds">
      <h4 className={`subtitle ${styles.pageName}`}>
        Upload
        <span className="material-icons" style={{ marginLeft: 8 }}>
          help_outline
        </span>
      </h4>
      {currentDocument?.documentations
        .slice()
        .sort((a: any, b: any) => a.sequence - b.sequence)
        .map((x: any) => {
          return (
            <DocumentationListItem
              key={x.name}
              document={x}
              setSelectedDocumentDetails={setDocumentControlDetails}
              isActive={selectedDocumentControl?.controls.name === x.name}
              addDocumentFromLibrary={(documentation, name, id) => {
                setDocumentControlDetails({ controls: documentation, type: 'documents' });
                setLibraryItem({ name, id });
              }}
            />
          );
        })}
      {!isViewOnly && (
        <AddItemFromLibrary
          addItemFromLibrary={(item, name, id) => {
            setDocumentControlDetails({ controls: item, type: 'document' });
            setLibraryItem({ name, id });
          }}
          type={SelfServiceItemType.DOCUMENTATION}
        />
      )}
    </div>
  );
};

export default DocumentList;
