import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ViewOnlyProvider } from '@context/view-only-provider';

import Configurations from './pages/configurations/Configurations';
import ConfigurationSetupPage from './pages/configurationSetup/ConfigurationSetupPage';
import ConfigurationBase from './pages/newConfiguration/ConfigurationBase';
import ConfigurationInfo from './pages/newConfiguration/ConfigurationInfo';

const SelfServiceRouter = () => {
  return (
    <Switch>
      <Route
        path="/oo/recruiter/self-service/configuration-setup"
        component={(props: any) => (
          <ViewOnlyProvider>
            <ConfigurationSetupPage {...props} />
          </ViewOnlyProvider>
        )}
      />
      <Route
        path="/oo/recruiter/self-service/new-configuration/configuration-name/:draftId?"
        component={ConfigurationInfo}
      />
      <Route path="/oo/recruiter/self-service/new-configuration/configuration-base" component={ConfigurationBase} />
      <Route path="/oo/recruiter/self-service/" component={Configurations} />
    </Switch>
  );
};

export default SelfServiceRouter;
