import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Info, InfoOutlined } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import { store } from '@store/index';

import { editableCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { OOSwitch } from './OOSwitch';

export const OOSwitchWithInfo: React.VoidFunctionComponent<OOControlModelInterface> = (props) => {
  const { t } = useT('entry');
  const flowData = store.getState().oneOnboarding.flow;
  const theme = useTheme();
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const formik = useFormikContext<Record<string, any>>();

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isEditable = editableCondition(props, flowWrapper, formik.values);

  const prefix = props.label.split('.')[0].toLowerCase();

  const description = t(`${prefix}:${props.description}`);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <OOSwitch
          validationKey={props.name}
          label={props.label}
          description=""
          mandatory={props.isMandatory}
          checkboxProps={{
            disabled:
              !(flowWrapper?.isControlEditable(props.isEditableCandidate, props.isEditableRecruiter) ?? true) ||
              !isEditable,
          }}
          defaultValue={props.defaultValue}
          value={props.value}
        />
        {description && (
          <Box pl={1} onClick={() => setIsInfoVisible(!isInfoVisible)}>
            {isInfoVisible ? <Info color="primary" /> : <InfoOutlined color="primary" />}
          </Box>
        )}
      </Box>
      {description && isInfoVisible && (
        <Box bgcolor={theme.palette.primary.main} px={2} py={1} my={1}>
          <Typography variant="body2" style={{ color: theme.palette.primary.contrastText }}>
            {description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
