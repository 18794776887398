import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import { getBase64EncodedStringFromFile } from '@helpers/fileUpload.helper';
import { Utils } from '@helpers/utils';
import { Tenant, useTenant } from '@hooks/useComponentMapping';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { snackbarSlice } from '@store/slices/snackbar';

import { AamBackendApi } from '../../../../libs/aamBackendApi';

type AdminCsvImportButtonProps = {
  options: string[];
  configurationId: string;
  clientOrganizationId: string;
  onSuccess?: any;
  className?: string;
  style?: any;
  handlers?: Map<
    | 'Branch'
    | 'Client'
    | 'CoVendor'
    | 'JobShiftType'
    | 'JobType'
    | 'Location'
    | 'Region'
    | 'Requisition'
    | 'Site'
    | 'CivilStatus'
    | 'PurchaseOrder'
    | 'ClientOrganization',
    (tenantId: Tenant, formData: FormData) => Promise<void>
  >;
};

const AdminCsvImportButton = (props: AdminCsvImportButtonProps) => {
  const { options, clientOrganizationId, configurationId, onSuccess, className, style, handlers } = props;
  const { t } = useTranslation(['recruiter']);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState(options[0] || 'ClientOrganization');

  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useTenant();

  const jsonImports: string[] = ['OOSelectedConfiguration'];

  const fileExtension = jsonImports.includes(selectedOption) ? 'json' : 'csv';

  const importInternalHandler = async (selectedOption: string, tenantId: Tenant, formData: FormData) => {
    try {
      let response: { result: any; error: string } = {
        result: {},
        error: '',
      };
      switch (selectedOption) {
        case 'Branch':
          response.result = await aamBackendApi.importCsv(tenantId, 'branches', formData);
          break;
        case 'Client':
          response.result = await aamBackendApi.importCsv(tenantId, 'clients', formData);
          break;
        case 'CoVendor':
          response.result = await aamBackendApi.importCsv(tenantId, 'covendors', formData);
          break;
        case 'JobShiftType':
          response.result = await aamBackendApi.importCsv(tenantId, 'jobshifttypes', formData);
          break;
        case 'JobType':
          response.result = await aamBackendApi.importCsv(tenantId, 'jobtypes', formData);
          break;
        case 'Location':
          response.result = await aamBackendApi.importCsvLocations(formData);
          break;
        case 'Region':
          response.result = await aamBackendApi.importCsvRegions(formData);
          break;
        case 'Requisition':
          response.result = await aamBackendApi.importCsv(tenantId, 'requisitions', formData);
          break;
        case 'Site':
          response.result = await aamBackendApi.importCsv(tenantId, 'sites', formData);
          break;
        case 'CivilStatus':
          response.result = await aamBackendApi.importCsv(tenantId, 'civilStatuses', formData);
          break;
        case 'PurchaseOrder':
          response.result = await aamBackendApi.importCsv(tenantId, 'purchaseorders', formData);
          break;
        case 'ClientOrganization':
          response.result = await aamBackendApi.importCsv(tenantId, 'client-organization', formData);
          break;
        default:
          response = await aamBackendApi.importCsvGeneral(tenantId, formData);
          break;
      }
      const { result, error } = response;
      if (error) {
        dispatch(
          snackbarSlice.actions.showError({
            message: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
          }),
        );
      } else {
        if (result?.length || (result?.imported?.length > 0 && result?.failed.length === 0)) {
          dispatch(
            snackbarSlice.actions.showSuccess({
              message: t('recruiter:GENERAL.CONFIGURATION.onImportSuccessful'),
            }),
          );
          if (onSuccess) {
            onSuccess(result);
          }
        } else if (result?.imported?.length > 0) {
          dispatch(
            snackbarSlice.actions.showWarning({
              message: `${t('recruiter:GENERAL.CONFIGURATION.onImportPartial')}
              \n${t('recruiter:GENERAL.CONFIGURATION.imported')} ${result.imported.length}, ${t(
                'recruiter:GENERAL.CONFIGURATION.failed',
              )} ${result.failed.length}`,
            }),
          );
        } else {
          Utils.consoleLog('No rows imported');
          dispatch(
            snackbarSlice.actions.showError({
              message: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
            }),
          );
        }
      }
    } catch (err) {
      Utils.consoleLog(err);
      dispatch(
        snackbarSlice.actions.showError({
          message: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
        }),
      );
    }
  };
  const handleClick = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList?.length) {
      return;
    }

    const file = fileList[0];
    const { name } = file;
    if (name.slice(name.lastIndexOf('.') + 1).toLowerCase() !== fileExtension) {
      Utils.consoleLog(t('Wrong file extension'));
      return;
    }

    const formData = new FormData();
    formData.append('clientOrganizationId', clientOrganizationId);
    formData.append('configurationId', configurationId);
    try {
      switch (selectedOption) {
        case 'Branch':
        case 'Client':
        case 'CoVendor':
        case 'JobShiftType':
        case 'JobType':
        case 'Location':
        case 'Region':
        case 'Requisition':
        case 'Site':
        case 'CivilStatus':
        case 'PurchaseOrder':
        case 'ClientOrganization':
          formData.append('file', file, name);
          if (handlers?.has(selectedOption) === true) {
            const handler = handlers.get(selectedOption);
            handler
              ? await handler(tenantId, formData)
              : await importInternalHandler(selectedOption, tenantId, formData);
          } else {
            await importInternalHandler(selectedOption, tenantId, formData);
          }
          break;
        default:
          formData.append('entityName', selectedOption);
          formData.append('file', await getBase64EncodedStringFromFile(file));
          await importInternalHandler(selectedOption, tenantId, formData);
          break;
      }
    } catch (err) {
      Utils.consoleLog(err);
      dispatch(
        snackbarSlice.actions.showError({
          message: t('recruiter:GENERAL.CONFIGURATION.onImportError'),
        }),
      );
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, val: string) => {
    setSelectedOption(val);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box>
      <ButtonGroup variant="outlined" size="small" component="span" ref={anchorRef} aria-label="split button">
        <Button className={className || ''} style={style || {}}>
          <label>
            {t(`recruiter:GENERAL.CONFIGURATION.upload${selectedOption || options[0]}CsvList`)}
            <input type="file" hidden onChange={handleClick} id="inputId" accept={`.${fileExtension}`} />
          </label>
        </Button>
        <Button
          className={className || ''}
          style={style || {}}
          size="small"
          component="span"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select csv import"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon color="primary" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === selectedOption}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {t(`recruiter:GENERAL.CONFIGURATION.upload${option}CsvList`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
export default AdminCsvImportButton;
