import * as React from 'react';
import { Utils } from '@helpers/utils';

import { OONavigationPages } from '../interfaces';

export interface OONavigationContextInterface {
  to: (page: OONavigationPages) => void;
  toNextIndex: () => void;
  toPrevIndex: () => void;
  forward: () => void;
  back?: () => void;
  currentPage: string;
  toDocumentTypeSelection: () => void;
  toDocumentTypeUpload: () => void;
}

export const OONavigationContext = React.createContext<OONavigationContextInterface>({
  to: () => {
    Utils.consoleError('Default to called');
  },
  toNextIndex: () => {
    Utils.consoleError('Default to called');
  },
  toPrevIndex: () => {
    Utils.consoleError('Default to called');
  },
  forward: () => {
    Utils.consoleError('Default forward called');
  },
  back: () => {
    Utils.consoleError('Default back called');
  },
  toDocumentTypeSelection: () => {
    Utils.consoleError('Default to called');
  },
  toDocumentTypeUpload: () => {
    Utils.consoleError('Default to called');
  },
  currentPage: '',
});
