import * as yup from 'yup';

import { translationValidation } from '../pageFeature/renderControls/templates';

export interface Template {
  // Properties that are rendered in UI, defined by business which fields should be editable
  visible: string[];
  initialValues: { [key: string]: any };
  //   // Properties that are not editable by business
  hidden?: { [key: string]: any };
  validation: yup.ObjectSchema | null;
  //   // Properties that should not exists in configuration, but they exists in UI for some other purpose
  hideForConfiguration: string[];
  //   // Used for converting configuration object to UI object
  converterForUi: (configurationObject: { [key: string]: any }) => { [key: string]: any };
  convertForConfiguration: (configurationObject: { [key: string]: any }) => { [key: string]: any };
  fieldConfiguration?: {
    [fieldName: string]: {
      disabled: boolean;
    };
  };
}

export const BlankDocumentForSignTemplate = {
  name: '',
  label: '',
  labelTranslations: {},
  description: '',
  descriptionTranslations: {},

  candidateSignatureRequired: false,
};

export const DocumentForSignatureTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'description',
    'descriptionTranslations',
    'candidateSignatureRequired',
    'uploadedDocument',
  ],
  initialValues: {
    name: '',
    label: '',
    labelTranslations: {},
    description: '',
    descriptionTranslations: {},
    candidateSignatureRequired: false,
    uploadedDocument: false,
    uploadedDocumentInfo: null,
  },

  converterForUi: (configurationObject) => {
    const getSignatureRequired = () => {
      if (configurationObject.candidateSignatureRequired) {
        return configurationObject.candidateSignatureRequired;
      } else {
        return configurationObject.isCandidateSignatureRequired ? true : false;
      }
    };

    return {
      ...configurationObject,
      candidateSignatureRequired: getSignatureRequired(),
    };
  },
  convertForConfiguration: (configurationObject) => {
    const res = {
      ...configurationObject,
      isCandidateSignatureRequired: configurationObject.candidateSignatureRequired,
      isRecruiterSignatureRequired: false,
    };

    return res;
  },
  hideForConfiguration: [
    'descriptionTranslations',
    'labelTranslations',
    'candidateSignatureRequired',
    'uploadedDocumentInfo',
    'uploadedDocument',
  ],
  validation: yup.object().shape({
    name: yup.string().required('Name is required!'),
    label: yup.string().required('Label is required!'),
    labelTranslations: translationValidation,
    description: yup.string(),
    descriptionTranslations: translationValidation,
    uploadedDocument: yup.bool().oneOf([true], 'File must be uploaded'),
  }),
};

export const EditDocumentForSignatureTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'description',
    'descriptionTranslations',
    'candidateSignatureRequired',
    'uploadedDocument',
  ],
  initialValues: {
    name: '',
    label: '',
    labelTranslations: {},
    description: '',
    descriptionTranslations: {},
    candidateSignatureRequired: false,
    uploadedDocument: true,
  },
  fieldConfiguration: {
    name: {
      disabled: true,
    },
    candidateSignatureRequired: {
      disabled: true,
    },
  },
  hidden: {},
  converterForUi: (configurationObject) => {
    const getSignatureRequired = () => {
      if (configurationObject.candidateSignatureRequired) {
        return configurationObject.candidateSignatureRequired;
      } else {
        return configurationObject.isCandidateSignatureRequired ? true : false;
      }
    };

    return {
      ...configurationObject,
      candidateSignatureRequired: getSignatureRequired(),
    };
  },
  convertForConfiguration: (configurationObject) => {
    return {
      ...configurationObject,
      isCandidateSignatureRequired: configurationObject.candidateSignatureRequired,
      isRecruiterSignatureRequired: false,
    };
  },
  hideForConfiguration: [
    'descriptionTranslations',
    'labelTranslations',
    'candidateSignatureRequired',
    'uploadedDocument',
  ],
  validation: yup.object().shape({
    name: yup.string().required('Name is required!'),
    label: yup.string().required('Label is required!'),
    labelTranslations: translationValidation,
    description: yup.string(),
    descriptionTranslations: translationValidation,

    uploadedDocument: yup.bool().oneOf([true], 'File must be uploaded'),
  }),
};

export const ExternalDocumentForSignatureTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'description',
    'descriptionTranslations',
    'isCandidateSignatureRequired',
    'isRecruiterSignatureRequired',
    'disyDocumentName',
  ],
  initialValues: {
    name: '',
    label: '',
    labelTranslations: {},
    description: '',
    descriptionTranslations: {},
    needPersonalization: true,
    isCandidateSignatureRequired: true,
    isRecruiterSignatureRequired: true,
    disyDocumentName: '',
  },
  converterForUi: (configuration) => configuration,
  convertForConfiguration: (configuration) => {
    return configuration;
  },
  hideForConfiguration: ['descriptionTranslations', 'labelTranslations', 'uuid', 'disyDocumentName'],
  validation: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9_]*$/, 'Only underscores are allowed.')
      .required('Name is required!'),
    label: yup.string().required('Label is required!'),
    labelTranslations: translationValidation,
    description: yup.string(),
    descriptionTranslations: translationValidation,
    disyDocumentName: yup.string().required('Document name is required!'),
  }),
};

export const DocumentForSignatureFromLibraryTemplate: Template = {
  visible: [
    'name',
    'label',
    'labelTranslations',
    'description',
    'descriptionTranslations',
    'candidateSignatureRequired',
    'uploadedDocument',
  ],
  initialValues: {
    name: '',
    label: '',
    labelTranslations: {},
    description: '',
    descriptionTranslations: {},
    candidateSignatureRequired: false,
    uploadedDocument: false,
  },
  fieldConfiguration: {
    name: {
      disabled: true,
    },
    candidateSignatureRequired: {
      disabled: true,
    },
  },
  hidden: {},
  converterForUi: (configurationObject) => {
    const getSignatureRequired = () => {
      if (configurationObject.candidateSignatureRequired) {
        return configurationObject.candidateSignatureRequired;
      } else {
        return configurationObject.isCandidateSignatureRequired ? true : false;
      }
    };

    return {
      ...configurationObject,
      candidateSignatureRequired: getSignatureRequired(),
    };
  },
  convertForConfiguration: (configurationObject) => {
    // Mechanism for recognizing file type
    // If we dont have signature anchors, that means its docx
    if (configurationObject?.signatureAnchors) {
      delete configurationObject.signatureAnchors;
    }

    return {
      ...configurationObject,
      isCandidateSignatureRequired: configurationObject.candidateSignatureRequired,
      isRecruiterSignatureRequired: false,
    };
  },
  hideForConfiguration: [
    'descriptionTranslations',
    'labelTranslations',
    'candidateSignatureRequired',
    'uploadedDocument',
  ],
  validation: yup.object().shape({
    name: yup.string().required('Name is required!'),
    label: yup.string().required('Label is required!'),
    labelTranslations: translationValidation,
    description: yup.string(),
    descriptionTranslations: translationValidation,
    uploadedDocument: yup.bool().oneOf([true], 'File must be uploaded'),
  }),
};
