import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

import './AdminReactiveSelect.scss';

interface AdminReactiveSelectProps extends SelectProps {
  options: { name: string; value: any }[];
  label: string;
  placeholder?: string;
}

export const AdminReactiveSelect = (props: AdminReactiveSelectProps) => {
  const { options, label, placeholder, className, ...rest } = props;
  const renderValueProp = placeholder
    ? {
        renderValue: (selected: any) => {
          const existing = options.find((o) => o.value === selected);
          return existing?.name || <span>{placeholder}</span>;
        },
        displayEmpty: true,
      }
    : {};
  const id = label.trim().toLowerCase().replace(/[\W]+/g, '');
  return (
    <FormControl fullWidth className={'admin-reactive-select' + (className ? ' ' + className : '')}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select {...rest} IconComponent={ExpandMore} labelId={`${id}-label`} id={id} label={label} {...renderValueProp}>
        {options.map((x) => (
          <MenuItem value={x.value} key={`${id}-${x.value}`}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
