import React, { FC } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';
import { OOUserDocumentModel } from '@features/oneOnboarding/models/UserDocumentModel';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { Typography } from '@mui/material';

export interface DocumentStatusProps {
  document: OODocumentModel;
  hasValidationStep: boolean;
}
export const DocumentStatus: FC<DocumentStatusProps> = ({ document, hasValidationStep }) => {
  const { t } = useTranslation(['documents', 'candidate_recruiter']);

  const renderStatus = (userDocument: OOUserDocumentModel) => {
    if (userDocument.additionalData?.status === OnboardingDocumentStatus.VERIFIED) {
      return (
        <>
          <div className="status-tag_success " style={{ marginBottom: 8 }}>
            {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.verified')}
          </div>
          <Typography variant="subtitle2">
            {format(new Date(document.userDocument.updatedAt), 'MMM dd yyyy hh:mma')}
          </Typography>
        </>
      );
    }

    if (document.userDocument.additionalData?.status === OnboardingDocumentStatus.REJECTED) {
      return (
        <>
          <div className="status-tag error " style={{ marginBottom: 8 }}>
            {t('documents:statuses.status_rejected')}
          </div>
          <Typography variant="subtitle2">
            {document.userDocument.updatedAt
              ? format(new Date(document.userDocument.updatedAt), 'MMM dd yyyy hh:mma')
              : ''}
          </Typography>
        </>
      );
    }

    if (
      document.userDocument.additionalData?.status !== OnboardingDocumentStatus.VERIFIED &&
      document.userDocument.additionalData?.status !== OnboardingDocumentStatus.REJECTED
    ) {
      return (
        <>
          {hasValidationStep ? (
            <div className="status-tag_info" style={{ marginBottom: 8, backgroundColor: '#CCF2FF' }}>
              {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.uploaded')}
            </div>
          ) : (
            <div className="status-tag_success " style={{ marginBottom: 8 }}>
              {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.verified')}
            </div>
          )}
          <Typography variant="subtitle2">
            {format(new Date(document.userDocument.updatedAt), 'MMM dd yyyy hh:mma')}
          </Typography>
        </>
      );
    }

    return (
      <>
        <div className="status-tag_info " style={{ marginBottom: 8, backgroundColor: '#CCF2FF' }}>
          {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.uploaded')}
        </div>
        <span className="documents-and-data-upload__status-missing">
          {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.missingDocument')}
        </span>
      </>
    );
  };

  return (
    <div className="feature-candidate-profile__documents-item-status">
      <div className="tag-ds" style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
        {document.userDocument ? (
          <>
            <div>{document.userDocument.isReadOnly ? 'Read only' : ''}</div>
            {renderStatus(document.userDocument)}
          </>
        ) : (
          <div className="status-tag_alert ">{t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.missingDocument')}</div>
        )}
      </div>
    </div>
  );
};
