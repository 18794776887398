import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuickFilterModel } from '@models/quick-filter.model';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';

interface QuickFilterItemProps {
  item: QuickFilterModel;
  onClick: () => void;
  onClose: () => void;
  selected: boolean;
}

export const QuickFilterItem: React.FC<QuickFilterItemProps> = ({ item, onClick, onClose, selected }) => {
  const { t } = useTranslation(['recruiter', 'control-items']);

  const generateBoxClassName = `feature-dashboard__quick-filter-grid-item ${
    selected && 'feature-dashboard__quick-filter-grid-item--selected'
  }`;

  const generateNumberClassName = (number: number) => {
    const base = `tag-ds  h2 feature-dashboard__quick-filter-grid-item__number`;
    const zeroOrNot = number === 0 ? '__zero' : '__non-zero';
    return base + zeroOrNot;
  };

  return (
    <Box onClick={() => !selected && onClick()} className={generateBoxClassName}>
      {selected && (
        <div className="feature-dashboard__quick-filter-grid-item__close-icon">
          <CloseIcon onClick={() => onClose()} />
        </div>
      )}
      <p className={generateNumberClassName(item.number)}>{item.number}</p>
      <Typography className="feature-dashboard__quick-filter-grid-item__title">{item.title}</Typography>
      <Typography className="feature-dashboard__quick-filter-grid-item__description">
        {t(`control-items:GENERAL.${item.description}`)}
      </Typography>
    </Box>
  );
};
