import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Header } from '@components/app/Header/Header';
import { ContactDetails } from '@features/app/ContactUs/ContactDetails';
import { useContactDetails } from '@features/app/ContactUs/useContactDetails';
import { Box, Container, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { CustomLogo } from '../../oneOnboarding/controls/CustomLogo';

interface ContactUsProps {
  onClose: () => void;
}

export const ContactUs: FC<ContactUsProps> = () => {
  const { t } = useTranslation('candidate');
  const history = useHistory();
  const { logo } = useSelector((state: RootState) => state.oneOnboarding);
  const { contactUs, help } = useContactDetails();

  return (
    <>
      <Header
        isAuthenticated
        onBackPress={() => history.push('Dashboard')}
        {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }}
      />
      <Container maxWidth="xl">
        <Grid container mt={6} className="tag-ds">
          <Grid item xl={4} style={{ paddingLeft: '20px' }}>
            <Box mb={5}>
              <h2 className="tag-ds ">{t('candidate:GENERAL.CONTACT_US.contactUs')}</h2>
            </Box>
            <ContactDetails contactUs={contactUs} help={help} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
