import React from 'react';
import { useInjection } from '@context/inversify-context-provider';

import { PrivateRoute } from '../../App';
import { Logger } from '../../libs/logger';
import { OOCandidateOnHold } from './pages/CandidateOnHold';
import { OOFlowWrapper } from './wrappers/FlowWrapper';

export const OneOnboardingRoute = (props: any) => {
  const logger = useInjection(Logger);
  const isStepAvailable = OOFlowWrapper.isStepAvailable(props.step);
  if (!isStepAvailable) {
    logger.log(`Requested step "${props.step}" is not yet available, redirecting to "on hold" page`);
  }
  return <PrivateRoute {...props} component={!isStepAvailable ? OOCandidateOnHold : props.component} />;
};
