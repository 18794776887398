import noop from 'lodash/noop';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import useRecruiterCreatesHiringDocuments from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterCreatesHiringDocuments';

import { useRecruiterEntersHiringData } from './useRecruiterEntersHiringData';
import { useRecruiterSignsHiringDocuments } from './useRecruiterSignsHiringDocuments';
import { useRecruiterVerifiesDocuments } from './useRecruiterVerifiesDocuments';

export const useCandidateUploadsDocuments = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const recruiterVerifiesDocumentsState = useRecruiterVerifiesDocuments(params);
  const recruiterEntersHiringDataState = useRecruiterEntersHiringData(params);
  const recruiterCreatesHiringDocumentsState = useRecruiterCreatesHiringDocuments(params);
  const recruiterSignsHiringDocumentsState = useRecruiterSignsHiringDocuments(params);
  const allStates = [
    recruiterVerifiesDocumentsState,
    recruiterEntersHiringDataState,
    recruiterCreatesHiringDocumentsState,
    recruiterSignsHiringDocumentsState,
  ];
  const active =
    recruiterVerifiesDocumentsState.active ||
    recruiterEntersHiringDataState.active ||
    recruiterCreatesHiringDocumentsState.active ||
    recruiterSignsHiringDocumentsState.active;

  const completed =
    recruiterVerifiesDocumentsState.completed &&
    recruiterEntersHiringDataState.completed &&
    recruiterCreatesHiringDocumentsState.completed &&
    recruiterSignsHiringDocumentsState.completed;
  const disabled = allStates.every((x) => x.disabled);

  return {
    active,
    disabled,
    completed,
    onClick: () => noop(),
  };
};
