import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { store } from '@store/index';

import OOSeparator from '../../../../components/shared/Separator/Separator';
import { editableCondition } from '../../hooks/useRenderCondition';
import { OOSelectorCheckboxModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { OOCheckbox } from './Checkbox/OOCheckbox';

export const OODefaultCheckbox: React.VoidFunctionComponent<OOSelectorCheckboxModelInterface> = (props) => {
  const flowData = store.getState().oneOnboarding.flow;
  const formik = useFormikContext<Record<string, any>>();

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isEditable = editableCondition(props, flowWrapper, formik.values) ?? true;

  return (
    <div>
      <OOCheckbox
        validationKey={props.name}
        label={props.label}
        description={props.description}
        mandatory={props.isMandatory}
        defaultValue={props.defaultValue}
        onChangeCallback={props.onChangeCallback}
        checkboxProps={{
          disabled:
            !(flowWrapper?.isControlEditable(props.isEditableCandidate, props.isEditableRecruiter) ?? true) ||
            !isEditable,
        }}
      />
      <OOSeparator />
    </div>
  );
};
