import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  Method,
  ResponseType,
} from 'axios';
import { injectable } from 'inversify';
import merge from 'lodash/merge';
import { AnyAction, Dispatch } from 'redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { ApiCallStatus } from '@store/apiCallStatus';
import { snackbarSlice } from '@store/slices/snackbar';
import { toastSlice } from '@store/slices/toast.slice';
import { StoreKeeper } from '@store/storeKeeper';

import { localStorageKey as adminLocalStorageKey } from '../store/slices/authAdmin.slice';
import { localStorageKey as appLocalStorageKey } from '../store/slices/authApp.slice';

export type ApiBaseHookModifyAxiosConfigHook = (axiosRequestConfig: AxiosRequestConfig) => Promise<void>;

export type ApiBaseHookBeforeRequestHook = (axiosInstance: AxiosInstance) => Promise<void>;

export type ApiBaseHookResponseSuccessHook = (
  axiosInstance: AxiosInstance,
  axiosResponse: AxiosResponse,
) => Promise<void>;

export type ApiBaseHookResponseErrorHook = (
  axiosInstance: AxiosInstance,
  axiosResponse: AxiosResponse,
  axiosError: AxiosError,
) => Promise<void>;

export type ApiBaseHookTokenExpireErrorHook = () => Promise<boolean>;
export type ApiBaseHookDataTransformHook = (data: any) => Promise<any>;

export interface ApiBaseHooks {
  modifyAxiosConfig?: ApiBaseHookModifyAxiosConfigHook[];
  beforeRequest?: ApiBaseHookBeforeRequestHook[];
  responseError?: ApiBaseHookResponseErrorHook[];
  responseSuccess?: ApiBaseHookResponseSuccessHook[];
  tokenExpiredError?: ApiBaseHookTokenExpireErrorHook[];
  dataTransform?: ApiBaseHookDataTransformHook[];
}

export interface ApiBaseOptions {
  baseURL?: string;
  headers?: { [key: string]: string };
  hooks?: ApiBaseHooks;
}

export interface ApiBaseStoreUpdate {
  dispatch: Dispatch<AnyAction>;
  dataUpdateAction: ActionCreatorWithPayload<any>;
  setStatusAction?: ActionCreatorWithPayload<{ name: string; value: ApiCallStatus }>;
  setStatusName?: string;
}

export interface ApiBaseRequestOptions {
  method: Method;
  url: string;
  data?: any;
  headers?: any;
  config?: AxiosRequestConfig;
  hooks?: ApiBaseHooks;
  storeUpdate?: ApiBaseStoreUpdate;
  responseType?: ResponseType;
  returnFullResponse?: boolean;
  cancelToken?: CancelToken;
  onErrorToast?: (
    axiosInstance: AxiosInstance,
    axiosResponse: AxiosResponse,
    axiosError: AxiosError,
  ) => Promise<boolean | { message: string; title: string }>;
}

@injectable()
export class ApiBase {
  private _options: ApiBaseOptions;

  constructor(options?: ApiBaseOptions) {
    this._options = options ?? {};
  }

  protected setHooks(hooks: ApiBaseHooks) {
    this._options.hooks = hooks;
  }

  protected request = async <T>(options: ApiBaseRequestOptions): Promise<T> => {
    if (options.storeUpdate) {
      if (options.storeUpdate.setStatusAction) {
        if (options.storeUpdate.setStatusAction) {
          await options.storeUpdate.dispatch(
            options.storeUpdate.setStatusAction({
              name: options.storeUpdate.setStatusName || '',
              value: ApiCallStatus.pending,
            }),
          );
        }
      }
    }

    const headers = {
      ...this._options.headers,
      ...options.headers,
    };

    const axiosRequestConfig: AxiosRequestConfig = {
      baseURL: this._options.baseURL ?? undefined,
      url: options.url,
      method: options.method,
      headers,
      data: options.data,
      responseType: options.responseType,
    };

    if (options.config) {
      merge(axiosRequestConfig, options.config);
    }

    const modifyAxiosConfigHooks = [
      ...(this._options.hooks?.modifyAxiosConfig ?? []),
      ...(options.hooks?.modifyAxiosConfig ?? []),
    ];

    for (const hook of modifyAxiosConfigHooks) {
      await hook(axiosRequestConfig);
    }

    const http = Axios.create(axiosRequestConfig);

    const beforeRequestHooks = [...(this._options.hooks?.beforeRequest ?? []), ...(options.hooks?.beforeRequest ?? [])];

    for (const hook of beforeRequestHooks) {
      await hook(http);
    }

    let response: AxiosResponse | undefined;

    try {
      response = await http.request(axiosRequestConfig);
    } catch (err) {
      if (err.response?.data?.error?.name === 'AuthUnauthorizedError') {
        if (window.location.href.includes('/recruiter') || window.location.href.includes('/admin/aadlogin/token')) {
          StoreKeeper.store.dispatch(
            snackbarSlice.actions.setFlash({
              id: 'unauthorized',
              message: 'unauthorized',
            }),
          );
          window.localStorage.removeItem(adminLocalStorageKey);
          window.location.href = '/oo/recruiter/unauthorized';
        } else {
          window.localStorage.removeItem(appLocalStorageKey);
          window.location.href = '/';
        }
      }
      if (
        err.response?.data?.error?.name === 'AuthPayloadVerificationError' &&
        err.response?.data?.error?.innerError?.name === 'JwtTokenExpiredError'
      ) {
        const tokenExpiredErrorHook = [
          ...(this._options.hooks?.tokenExpiredError ?? []),
          ...(options.hooks?.tokenExpiredError ?? []),
        ];
        let returnRepeatedRequest = false;
        for (const hook of tokenExpiredErrorHook) {
          const successfullyRefreshed = await hook();
          if (successfullyRefreshed) {
            returnRepeatedRequest = true;
            break; // Break out of the loop as soon as the condition is met
          }
        }
        if (returnRepeatedRequest) {
          return this.request(options);
        }
      }

      const responseErrorHooks = [
        ...(this._options.hooks?.responseError ?? []),
        ...(options.hooks?.responseError ?? []),
      ];

      for (const hook of responseErrorHooks) {
        await hook(http, response as AxiosResponse, err as AxiosError);
      }

      if (!options.onErrorToast) {
        StoreKeeper.store.dispatch(
          toastSlice.actions.showError({
            message: 'Please, try again or check your internet connection',
            title: 'Network error',
          }),
        );
      } else {
        const result = await options.onErrorToast(http, response as AxiosResponse, err as AxiosError);
        if (typeof result === 'boolean') {
          if (result) {
            StoreKeeper.store.dispatch(
              toastSlice.actions.showError({
                message: 'Please, try again or check your internet connection',
                title: 'Network error',
              }),
            );
          }
        } else {
          StoreKeeper.store.dispatch(
            toastSlice.actions.showError({
              message: result.message,
              title: result.title,
            }),
          );
        }
      }

      throw err;
    }

    const responseSuccessHooks = [
      ...(this._options.hooks?.responseSuccess ?? []),
      ...(options.hooks?.responseSuccess ?? []),
    ];

    for (const hook of responseSuccessHooks) {
      await hook(http, response as AxiosResponse);
    }

    if (options.storeUpdate) {
      if (options.storeUpdate.setStatusAction) {
        await options.storeUpdate.dispatch(
          options.storeUpdate.setStatusAction({
            name: options.storeUpdate.setStatusName || '',
            value: ApiCallStatus.success,
          }),
        );
      }
    }

    let data = response?.data;

    const dataTransformHooks = [...(this._options.hooks?.dataTransform ?? []), ...(options.hooks?.dataTransform ?? [])];

    for (const hook of dataTransformHooks) {
      data = await hook(data);
    }

    if (options.storeUpdate) {
      await options.storeUpdate.dispatch(options.storeUpdate.dataUpdateAction(data));
    }

    return options.returnFullResponse ? { ...response, data } : data;
  };
}
