import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
const AutoHideDuration = 5000;

const CustomizedSnackbars = () => {
  const { open, type, message } = useSelector((state: RootState) => state.snackbar)!;
  const dispatch = useDispatch();
  const handleClose = (event: any) =>
    dispatch(
      snackbarSlice.actions.setSnackbar({
        open: false,
        type: type,
        message: message,
      }),
    );

  const [timeLeft, setTimeLeft] = useState<number>(AutoHideDuration / 1000);

  useEffect(() => {
    if (timeLeft > 0 && open) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setTimeout(() => setTimeLeft(AutoHideDuration / 1000), 100);
      return;
    }
  }, [timeLeft, open]);

  const alert = (
    <Alert className={type} elevation={6} variant="filled" onClose={(e) => handleClose(e)} severity={type}>
      {message} ({timeLeft.toString()}s)
    </Alert>
  );

  return (
    <Snackbar
      className="snackbar"
      disableWindowBlurListener={true}
      open={open}
      autoHideDuration={AutoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {alert}
    </Snackbar>
  );
};

export default CustomizedSnackbars;
