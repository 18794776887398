import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AdminButton } from '@components/admin/AdminButton/AdminButton';
import { AsyncImportLinesTable } from '@components/admin/dashboard/AsyncImportLinesTable';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { getClientOrganizationId, getSelectedConfigurationId } from '@helpers/tenant.helper';
import { UserRole } from '@models/user.model';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { setClientOrganizationId, setConfigurationId } from '@store/slices/oneOnboarding.slice';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { ImportLine } from '../../oneOnboarding/models/Import';

export const OOAsyncImportLine: React.VoidFunctionComponent = () => {
  const { t } = useTranslation(['recruiter']);
  const aamBackendApi = useInjection(AamBackendApi);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { appSettings } = useSelector((state: RootState) => state.featureConfiguration);
  const { userRole } = useSelector((state: RootState) => state.authAdmin);
  const history = useHistory();
  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>('startDate');
  const { importLineId } = useParams<{ importLineId: string }>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [importType, setImportType] = useState('');
  const [importLines, setImportLines] = useState<ImportLine[]>([]);
  const [triggeringItem, setTriggeringItem] = useState<ImportLine>();
  const [refetchInterval, setRefetchInterval] = useState(2000);
  const [importDataLength, setImportDataLength] = useState(0);
  const dispatch = useDispatch();

  const { data, isFetching, isRefetching, refetch } = useQuery({
    queryKey: ['fetchImportLines', importLineId],
    queryFn: () =>
      aamBackendApi.fetchImportLine(importLineId).then((items: Array<any>) => {
        if (!items.length) {
          return items;
        }

        const pageItems = items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || [];
        if (!pageItems.find((i) => i.status === 'IN_PROGRESS')) {
          setRefetchInterval(0);
        } else {
          setRefetchInterval(2000);
        }
        return items;
      }),
    enabled: !!importLineId,
    refetchOnMount: true,
    refetchInterval,
  });

  useEffect(() => {
    refetch();
  }, [refetch, rowsPerPage, page]);

  useEffect(() => {
    const clientOrganizationId = getClientOrganizationId();
    const configurationId = getSelectedConfigurationId();

    const fetchImportFromDB = async () => {
      const res = await aamBackendApi.fetchImport(importLineId);
      if (!clientOrganizationId || !configurationId) {
        dispatch(setClientOrganizationId(res.clientOrganizationId));
        dispatch(setConfigurationId(res.configurationId));
      }
      setImportType(res.type);
    };

    fetchImportFromDB();
  }, [dispatch, aamBackendApi, importLineId]);

  useEffect(() => {
    const isLoading = isFetching || isRefetching;
    if (data?.length) {
      setImportDataLength(data?.length);
    }
    setImportLines(!data?.length || isLoading ? [] : data.splice(page * rowsPerPage - rowsPerPage, rowsPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rowsPerPage, page]);

  const goBack = useCallback(() => {
    history.push('/oo/recruiter/import-users');
  }, [history]);

  const onTriggerImport = async (item: ImportLine) => {
    setTriggeringItem(item);
    await aamBackendApi.triggerImport(item.id);
    await refetch();
    setTriggeringItem(undefined);
  };

  return (
    <div className="admin">
      <PageWrapper
        featureName="async-import"
        pageName="async-import"
        headerConfig={{ pageName: t('recruiter:GENERAL.IMPORT.importDetails') }}
      >
        <Grid container>
          <Grid item xs={12} className="feature-candidates-profile-side-page__header">
            <Box display="flex" width="100%" justifyContent="flex-end">
              <AdminButton text={t('candidate_recruiter:GENERAL.GENERIC.back')} size="small" onClick={goBack} />
            </Box>
          </Grid>
        </Grid>
        <Container maxWidth="xl">
          <Typography variant="h1">{t('recruiter:GENERAL.IMPORT.importItems')}</Typography>
          {isFetching || isRefetching ? (
            <Box style={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <div>
                  <span>{importDataLength}</span> <span>{t('GENERAL.IMPORT.results')}</span>
                </div>
              </Box>
              <AsyncImportLinesTable
                triggeringItem={triggeringItem}
                onTriggerImport={onTriggerImport}
                items={importLines}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                page={page}
                setPage={setPage}
                total={data?.length || 0}
                rowsPerPageOptions={[5, 10, 25]}
                tenantId={tenantId}
                reimportDisabled={importType === 'Event'}
                hideReimportButton={appSettings?.hideReimportButton ? userRole === UserRole.adeccoStaff : false}
              />
            </>
          )}
        </Container>
      </PageWrapper>
    </div>
  );
};
