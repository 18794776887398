import React from 'react';
import HeaderLogo from './headerLogo';
import HeaderItem from './headerItem';

import Dropdown, {DropdownButton, DropdownMenu} from '../dropdown/dropdown';
import {useDevice} from '../../hooks/useDevice/useDevice';
import Select, {Option} from '../select/select';
import HeaderContact from './headerContact';
import Tree from '../treeView/treeView';

export {default as HeaderLogo} from './headerLogo';
export {default as HeaderItem} from './headerItem';

export const HeaderLanguages = Select;
export const HeaderLanguage = Option;

export interface IHeaderProps {
  /**
   * Add class name in header
   */
  className?: string;
  /**
   * Handler search icon in header
   */
  onSearch?: () => void;
  [others: string]: any;
}

const Header: React.FC<IHeaderProps> = (props) => {
  const {className, children, onSearch, ...rest} = props;
  const {isMobile} = useDevice();

  const renderHeader = () => {
    let _logo = null,
      _contact = null,
      _languageSelector = null,
      _search = null;
    const _items: any[] = [];

    React.Children.forEach(children, (_child: any) => {
      if (_child.type === HeaderLogo) _logo = _child;
      else if (_child.type === HeaderLanguages)
        _languageSelector = React.cloneElement(_child, {
          ..._child.props,
          className: `header-languages ${_child.props.className ?? ''}`,
        });
      else if (_child.type === HeaderContact) _contact = _child;
      else if (_child.type === HeaderItem) {
        _items.push(_child);
      }
    });

    if (typeof onSearch === 'function') {
      _search = (
        <button
          type="button"
          className="header-search"
          onClick={onSearch}
          data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-search-btn` : undefined}
        >
          <span className="material-icons">search</span>
        </button>
      );
    }

    if (isMobile) {
      return (
        <>
          {_logo}
          <div className="header-content">
            {_contact}
            {_search}
            <Dropdown
              keepShown
              className="header-items-dropdown"
              data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-items-dropdown` : undefined}
            >
              <DropdownButton
                data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-items-dropdown-btn` : undefined}
              >
                <span className="material-icons">menu</span>
              </DropdownButton>
              <DropdownMenu>
                <div>
                  <Tree className="header-items">{_items}</Tree>
                  {_languageSelector}
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </>
      );
    } else if (_contact) {
      return (
        <>
          <div className="header-top">
            {_contact}
            {_languageSelector}
          </div>
          <div className="header-bottom">
            {_logo}
            <div className="header-items">{_items}</div>
            {_search}
          </div>
        </>
      );
    }
    return (
      <>
        {_logo}
        <div className="header-items">{_items}</div>
        {_languageSelector}
        {_search}
      </>
    );
  };

  // const renderHeaderContent = () => {
  //   let _logo = null;
  //   const _items: any[] = [];
  //   let _search: any = null,
  //     _contact: any = null;
  //   React.Children.forEach(children, (_child: any) => {
  //     if (_child.type === HeaderLogo) {
  //       _logo = _child;
  //     } else if (_child.type === HeaderItem) {
  //       if (isMobile) {
  //         const _childClone = React.cloneElement(_child, {
  //           ..._child.props,
  //           'data-mobile': true,
  //           key: _child.props.id || `header-item-${_items.length}`,
  //           onClick: () => {
  //             if (typeof _child.props?.onClick === 'function') _child.props.onClick();
  //           },
  //         });
  //         _items.push(_childClone);
  //       } else _items.push(_child);
  //     }
  //   });
  //   // if (languages) {
  //   //   _language = languages.find((_lang: IHeaderLanguage) => _lang.selected);
  //   //   _languageSelector = (
  //   //     <Select
  //   //       key="header-languages-dropdown"
  //   //       className="header-languages"
  //   //       data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-languages-dropdown` : undefined}
  //   //     >
  //   //       {languages.map((_lang: IHeaderLanguage, _idxLang: number) => (
  //   //         <Option
  //   //           id={_lang.id || `language-${_idxLang}`}
  //   //           key={_lang.id || `language-${_idxLang}`}
  //   //           data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-language-${_idxLang}` : undefined}
  //   //         >
  //   //           {_lang.label}
  //   //         </Option>
  //   //       ))}
  //   //     </Select>
  //   //   );
  //   // }
  //   if (typeof onSearch === 'function') {
  //     _search = (
  //       <button
  //         type="button"
  //         className="header-search"
  //         onClick={onSearch}
  //         data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-search-btn` : undefined}
  //       >
  //         <span className="material-icons">search</span>
  //       </button>
  //     );
  //   }

  //   if (isMobile) {
  //     return (
  //       <>
  //         {_logo}
  //         <div className="header-content">
  //           {_contact}
  //           {_search}
  //           <Dropdown
  //             keepShown
  //             className="header-items-dropdown"
  //             data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-items-dropdown` : undefined}
  //           >
  //             <DropdownButton
  //               data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-items-dropdown-btn` : undefined}
  //             >
  //               <span className="material-icons">menu</span>
  //             </DropdownButton>
  //             <DropdownMenu>
  //               <div className="header-items">{_items}</div>
  //               {_languageSelector}
  //             </DropdownMenu>
  //           </Dropdown>
  //         </div>
  //       </>
  //     );
  //   } else if (_contact) {
  //     return (
  //       <>
  //         <div className="header-top">
  //           {_contact}
  //           {_languageSelector}
  //         </div>
  //         <div className="header-bottom">
  //           {_logo}
  //           <div className="tab-list">{_items}</div>
  //           {_search}
  //         </div>
  //       </>
  //     );
  //   }
  //   return (
  //     <>
  //       {_logo}
  //       <div className="tab-list">{_items}</div>
  //       {_languageSelector}
  //       {_search}
  //     </>
  //   );
  // };

  return (
    <div className={`header ${className || ''}`} {...rest}>
      {renderHeader()}
    </div>
  );
};

export default Header;
