import React, { Fragment, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { DownIcon } from '@components/shared/customIcons/DownIcon';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { ControlItems } from '@store/apiQuery';

import OOSeparator from '../../../../../components/shared/Separator/Separator';
import { checkboxControlError, checkboxErrorLabel } from '../../../helpers';
import { useT } from '../../../hooks/useT';
import { FormControlLoader } from '../../FormControlLoader';
import RadioButtonComponent from './RadioButton';

import styles from './OORadioGroup.module.scss';

type RadioGroupProps = {
  validationKey: string;
  description: string;
  data?: ControlItems;
  mandatory: boolean;
  loading: boolean;
  parameters?: Array<Record<string, any>>;
  link?: string;
  defaultValue?: string;
  disabled?: boolean;
};

export const OORadioGroup: React.FC<RadioGroupProps> = ({
  mandatory,
  data,
  description,
  loading,
  validationKey,
  parameters,
  defaultValue,
  disabled,
  link,
}) => {
  const theme = useTheme();
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);

  const { t, i18n, translationsLoading } = useT('entry', 'control-items');

  const errorLabel = useMemo(() => checkboxErrorLabel(fieldMeta), [fieldMeta]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue(validationKey, event.currentTarget.value);

  if (translationsLoading || loading) {
    return <FormControlLoader />;
  }
  if (defaultValue && fieldProps.value === undefined) {
    formik.setFieldValue(validationKey, defaultValue);
  }

  const translateWithParameter = (name: string, parameters: any) => {
    let transParameters = {};
    for (const param of parameters || []) {
      transParameters = { ...transParameters, [param.name]: param.value };
    }
    const staticParameters = {
      keySeparator: '>',
      nsSeparator: '|',
    };
    transParameters = { ...transParameters, ...staticParameters };
    const translate = t(name, transParameters);
    return JSON.parse(JSON.stringify(translate));
  };
  const translate = (label: string) => {
    const prefix = description ? description.split('.').slice(0, -1).join('.') : '';
    const keys = [
      `entry:${prefix}.${label}`,
      `control-items:${prefix}.${label}`,
      `control-items:${label}`,
      `entry:${label}`,
    ];
    const languageKey = keys.find((key) => i18n.exists(key)) || '';

    let transParameters = {};
    for (const param of parameters || []) {
      transParameters = { ...transParameters, [param.name]: param.value };
    }
    const translation = t(languageKey, transParameters);
    return typeof translation === 'string'
      ? { label: translation, title: '', description: '', labelList: [], toolTip: '' }
      : translation;
  };
  return (
    <FormControl {...checkboxControlError(fieldMeta)} variant="outlined">
      {t(description) && (
        <Box pb={1} sx={{ paddingBottom: '0' }}>
          <FormLabel style={{ fontWeight: theme.components?.MuiFormLabel?.defaultProps?.style?.fontWeight }}>{`${t(
            translateWithParameter(description, parameters),
          )} ${mandatory ? '*' : ''}`}</FormLabel>
        </Box>
      )}
      <RadioGroup name={validationKey} value={fieldProps.value || ''}>
        {data?.map((i, index) => {
          const isLast = index === data.length - 1;
          if (i.description) {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  className="feature-candidates-profile__space-between"
                  expandIcon={<DownIcon color={theme.palette.primary.main} />}
                >
                  <RadioButtonComponent
                    onChange={handleChange}
                    checked={fieldProps.value === i.value}
                    value={i.value}
                    key={index}
                    name={validationKey}
                    disabled={disabled}
                    label={translateWithParameter(i.label, parameters)}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="subtitle2">{t(i.description)}</Typography>
                  {`${i.description}.link` !== t(`${i.description}.link`) ? (
                    <Link rel="noreferrer" target="_blank" href={link}>
                      {t(`${i.description}.link`)}
                    </Link>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            const { title, description, label, labelList, toolTip } = {
              ...translate(i.label),
            };
            return (
              <Fragment key={index}>
                {title && (
                  <Typography className={styles.title} variant="h4">
                    {title} {mandatory ? '*' : ''}
                  </Typography>
                )}
                {description && <div className={styles.description}>{description}</div>}
                <RadioButtonComponent
                  onChange={handleChange}
                  checked={fieldProps.value === i.value}
                  name={validationKey}
                  value={i.value}
                  label={label || i.label}
                  labelList={labelList}
                  disabled={disabled}
                  toolTipContent={toolTip}
                />
                {!isLast && <OOSeparator />}
              </Fragment>
            );
          }
        })}
      </RadioGroup>
      {errorLabel && <FormHelperText>{errorLabel}</FormHelperText>}
    </FormControl>
  );
};
