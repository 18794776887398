import React from 'react';
import { useSelector } from 'react-redux';
import { TElement } from '@adeccoux/tag-ds';
import { onceEncodeURIComponent } from '@helpers/encode-decode.helper';
import { OnboardingFlowUsersModel } from '@models/onboarding-steps';
import { RootState } from '@store/rootReducer';

type NameCellProps = {
  userModel: OnboardingFlowUsersModel;
  addLastName: boolean;
};

const NameCell: React.FC<NameCellProps> = ({ userModel, addLastName }) => {
  let href = `/oo/recruiter/client-organization/${userModel.clientOrganizationId}/user-flow/${
    userModel.userId
  }/${onceEncodeURIComponent(userModel.applicationId)}/${userModel.configurationId}`;

  const { adminDashboardFilters } = useSelector((store: RootState) => store.oneOnboarding);

  if (adminDashboardFilters?.steps?.length > 0) {
    href += `?filter=${adminDashboardFilters?.steps}`;
  }

  const getUserName = (user: OnboardingFlowUsersModel, fullName = false) => {
    const email = user.userProfile.user.email;

    if (!user.userProfile.firstName) {
      return email;
    }

    return fullName ? `${user.userProfile?.firstName} ${user.userProfile?.lastName}` : user.userProfile?.firstName;
  };

  return (
    <TElement>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{ fontWeight: 'normal', color: '#1c304b', textTransform: 'initial' }}
      >
        {getUserName(userModel, addLastName)}
      </a>
    </TElement>
  );
};

export default NameCell;
