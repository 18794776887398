import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ModalBody, ModalFooter, ModalHeader } from '@adeccoux/tag-ds';
import { Box, Modal } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { Item } from './SingleSelectOptions';

import styles from './SingleSelectOptions.module.scss';

interface Props {
  itemForEdit: Item;
  handleItemChange: (newItem: Item) => void;
  translationValue: { [key: string]: string };
  handleTranslationChange: (translations: any) => void;
  closeModal: () => void;
}

const EditOptionModal: React.FC<Props> = ({
  itemForEdit,
  handleItemChange,
  translationValue,
  handleTranslationChange,
  closeModal,
}) => {
  const [internalChanges, setInternalChanges] = useState<Item>(itemForEdit);
  const [internalTranslations, setInternalTranslations] = useState<{ [key: string]: string }>(translationValue);

  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  const availableLanguages = selectedConfiguration?.languages;

  useEffect(() => {
    const fromDb: any = {
      name: itemForEdit.value,
      label: itemForEdit.label,
    };

    setInternalChanges(fromDb);
  }, [itemForEdit]);

  const handleSave = () => {
    handleItemChange(internalChanges);
    handleTranslationChange(internalTranslations);
    closeModal();
  };

  return (
    <Modal open={!!itemForEdit}>
      <Box className={clsx('tag-ds', styles.modal)}>
        <ModalHeader style={{ marginBottom: 24 }}>
          <h4>Edit technical name</h4>
        </ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: 20 }}>
            <label className="caption" htmlFor="name">
              Name
            </label>
            <input
              value={internalChanges?.name}
              id="name"
              type="text"
              onChange={(e) => setInternalChanges((state) => ({ ...state, name: e.target.value }))}
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <label className="caption" htmlFor="label">
              Label
            </label>
            <input value={internalChanges?.label} id="label" type="text" disabled />
          </div>
          <div style={{ marginBottom: 40 }}>
            {availableLanguages?.supported.map((language: any, i) => {
              return (
                <div key={i} style={{ marginBottom: 20 }}>
                  <label className="caption">Label translation for {language}</label>
                  <input
                    type="text"
                    value={internalTranslations?.[language] ?? ''}
                    onChange={(e) => {
                      const payload: any = { ...internalTranslations, [language]: e.target.value };
                      const supported = availableLanguages?.supported || [];

                      for (const lang of supported) {
                        if (!(lang in payload)) {
                          payload[lang] = undefined;
                        }
                      }

                      setInternalTranslations(payload);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="button-secondary" onClick={closeModal}>
            Cancel
          </button>
          <button className="button-primary" onClick={handleSave}>
            Save changes
          </button>
        </ModalFooter>
      </Box>
    </Modal>
  );
};

export default EditOptionModal;
