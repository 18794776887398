import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { useUserCheck } from '@hooks/apiHooks';
import { OnboardingStatus } from '@models/onboarding.model';

export const useRecruiterCreatesHiringDocuments = ({
  onboarding,
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { onboardingChecked } = useUserCheck(onboarding);
  const isStepCompleted = flowWrapper.isStepCompleted('HIRE');
  const { step } = flowWrapper.getCurrentStepAndPage();
  const active = step === 'AWAIT_CONTRACT' && onboardingChecked?.hiringEnabled === false;
  const completed =
    active || onboardingChecked?.hiringEnabled === true
      ? false
      : isStepCompleted
      ? isStepCompleted
      : !!onboardingChecked?.hiringEnabled || onboarding?.status === OnboardingStatus.readyForHire;

  const disabled = active || completed ? false : !onboardingChecked?.hiringEnabled;
  return {
    active,
    disabled,
    completed,
    onClick: (): void => {
      const step = steps.find((x) => x.name === 'HIRE');
      const page = step?.pages?.find((x) => x.name === 'CONTRACT_INFO');
      setPageStepContext({ step, page });
    },
  };
};
export default useRecruiterCreatesHiringDocuments;
