import React, { useEffect, useMemo, useState } from 'react';
import format from 'date-fns/format';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { locales, textErrors } from '../../helpers';
import { useT } from '../../hooks/useT';
import { FormControlLoader } from '../FormControlLoader';

const getLocalizedUtils = (headerTextFormat = 'EEE, d MMMM') =>
  class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date: any) {
      return format(date, headerTextFormat, { locale: this.locale });
    }
  };
export interface DatePickerProps<TInputDate, TDate>
  extends Omit<
      DesktopDatePickerProps<TInputDate, TDate>,
      'components' | 'componentsProps' | 'onChange' | 'renderInput' | 'value'
    >,
    Omit<
      MobileDatePickerProps<TInputDate, TDate>,
      'components' | 'componentsProps' | 'onChange' | 'renderInput' | 'value'
    > {
  headerTextFormat?: string;
  validationKey: string;
  label: string;
  minOffset?: number;
  maxOffset?: number;
  isMandatory: boolean;
  name: string;
  onChangeCallback?: (event: any) => void;
  defaultValue?: string;
}
type OODatePickerProps = DatePickerProps<Date, Date>;

export const OODatePicker: React.FC<OODatePickerProps> = ({
  headerTextFormat,
  validationKey,
  label,
  isMandatory,
  minOffset,
  maxOffset,
  onChangeCallback,
  defaultValue,
  name,
  ...props
}) => {
  const { t, translationsLoading, i18n } = useT('entry', 'documents');
  const LocalizedUtils = getLocalizedUtils(headerTextFormat);
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);
  const value = fieldProps.value;
  const prefix = label?.split('.')[0].toLowerCase();
  const aamBackendApi = useInjection(AamBackendApi);
  const [dateFormat, dateFormatSet] = useState<string>('dd/MM/yyyy');
  const { selectedConfigurationId, clientOrganizationId, importClientOrganizationId } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const [open, setOpen] = useState(false);
  const selectedLanguage = localStorage.getItem('i18nextLng');
  const clientOrgId = clientOrganizationId || importClientOrganizationId;
  useEffect(() => {
    if (clientOrgId && selectedConfigurationId) {
      const fetchDisplayDateFormat = async () => {
        const configData = await aamBackendApi.getOtherConfiguration(clientOrgId, selectedConfigurationId);
        const defaultLanguage = configData?.find((d) => d.name === 'languages')?.value;

        const fallbackVerification = configData?.find((d) => d.name === 'displayDateFormatFallback')?.value;

        const format = fallbackVerification
          ? configData?.find((d) =>
              selectedLanguage === defaultLanguage
                ? d.name === 'displayDateFormat'
                : d.name === 'displayDateFormatFallback',
            )?.value
          : configData?.find((d) => d.name === 'displayDateFormat')?.value;
        if (format) {
          dateFormatSet(format);
        }
      };
      fetchDisplayDateFormat();
    }
  }, [clientOrgId, selectedConfigurationId, aamBackendApi, selectedLanguage]);

  const maxDate = useMemo(() => {
    if (maxOffset || maxOffset === 0) {
      const date = new Date();
      date.setDate(date.getDate() + maxOffset);
      return date;
    } else {
      return undefined;
    }
  }, [maxOffset]);

  const minDate = useMemo(() => {
    if (minOffset || minOffset === 0) {
      const date = new Date();
      date.setDate(date.getDate() + minOffset);
      return date;
    } else {
      return undefined;
    }
  }, [minOffset]);

  if (defaultValue && fieldProps.value === undefined) {
    formik.setFieldValue(validationKey, defaultValue);
  }

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  const isWrongFormat = (value: any) => value === '' || value === undefined || value === null;

  const datepickerValue = isWrongFormat(value) ? null : moment(value, 'YYYY-MM-DD').utc().toDate();

  return (
    <LocalizationProvider utils={LocalizedUtils} dateAdapter={AdapterDateFns} adapterLocale={locales[i18n.language]}>
      <DatePicker
        {...fieldProps}
        {...textErrors(fieldMeta)}
        {...props}
        defaultCalendarMonth={maxDate}
        open={open}
        DialogProps={{
          sx: {
            '.MuiCalendarPicker-root': { width: '100%' },
            '.MuiCalendarOrClockPicker-root div:nth-child(2)': {
              width: '100%',
              '.MuiPickersArrowSwitcher-root': { width: '40%' },
            },
          },
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={datepickerValue}
        label={t(`${prefix}:${label}`)}
        // TODO: migration to mui 5 issue
        // okLabel={t('entry:form.okLabel')}
        inputFormat={dateFormat}
        maxDate={maxDate}
        minDate={minDate}
        OpenPickerButtonProps={{
          style: {
            marginRight: 0,
          },
        }}
        renderInput={(props) => (
          <TextField
            required={isMandatory}
            {...props}
            name={name}
            inputProps={{
              ...props.inputProps,
              label: t(`${prefix}:${label}`),
              placeholder: t(`${prefix}:${label}`),
            }}
            {...textErrors(fieldMeta)}
            onClick={() => setOpen(true)}
            onInvalid={(e) => {
              e.preventDefault();
            }}
            placeholder={t(`${prefix}:${label}`)}
            label={t(`${prefix}:${label}`)}
            style={{ marginBottom: 7 }}
          />
        )}
        onChange={(value: any) => {
          const valueWithFormat = moment(value).format('YYYY-MM-DD');

          formik.setFieldValue(validationKey, valueWithFormat);
          onChangeCallback && onChangeCallback(valueWithFormat);
        }}
      />
    </LocalizationProvider>
  );
};
