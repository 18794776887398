import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Searchbox, TabItem, Tabs } from '@adeccoux/tag-ds';
import OOTableContainer from '@components/admin/Table/OOTableContainer';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { AcccessStatus, UserModel } from '@models/user.model';
import { Box, Dialog } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import UserPermissionsTable from './components/UserPermissionsTable';

import styles from './UserPermissions.module.scss';
export const UserPermissionsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deactivationUserModal, setDeactivationUserModal] = useState<boolean>(false);
  const [userForDeactivation, setUserForDeactivation] = useState<UserModel>();
  const { t } = useTranslation(['recruiter']);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>('userInvitations.email');
  const aamBackendApi = useInjection(AamBackendApi);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const [userStatusFilter, setUSerStatusFilter] = useState<string>('');
  const [userSearch, setUserSearch] = useState<string>('');

  const { refetch: refetchUserPermissions, data: userPermissions } = useQuery({
    queryKey: ['userInvitationPermission', tenantId],
    queryFn: () => aamBackendApi.fetchUserPermissions(tenantId),
    refetchOnMount: true,
  });

  const deactivateUser = async (user: UserModel) => {
    try {
      await aamBackendApi.deactivateUserWithPermission(tenantId, user.id as string);
    } finally {
      setDeactivationUserModal(false);
      dispatch(
        snackbarSlice.actions.showSuccess({
          message: 'User deactivated',
        }),
      );
      await refetchUserPermissions();
    }
  };

  return (
    <div className="adminPermission">
      <PageWrapper
        featureName="admin-permission"
        pageName="admin-permission"
        sideMenu
        headerConfig={{ pageName: t('GENERAL.PERMISSIONS.accessManagement'), languagePicker: true }}
      >
        <div className="tag-ds">
          <OOTableContainer>
            <Box display="flex" mb={4} justifyContent="space-between">
              <Box>
                <Tabs onChangeTab={(e) => setUSerStatusFilter(e)}>
                  <TabItem id="" title={t(`GENERAL.PERMISSIONS.allUsers`)} />
                  <TabItem id="active" title={t(`GENERAL.PERMISSIONS.active`)} />
                  <TabItem id="pending" title={t(`GENERAL.PERMISSIONS.pending`)} />
                  <TabItem id="deactivated" title={t(`GENERAL.PERMISSIONS.deactivated`)} />
                </Tabs>
              </Box>
              <Box>
                <Searchbox
                  className="tag-ds input-container"
                  onChange={(e) => setUserSearch(e.target.value)}
                  placeholder={t('GENERAL.PERMISSIONS.search')}
                  value={userSearch}
                  style={{ paddingTop: '8px', paddingBottom: '8px', maxHeight: '40px' }}
                />
              </Box>
              <Box className={styles.addButtonContainer}>
                <button
                  onClick={(e: any) => {
                    history.push(`/oo/recruiter/permissions/${0}`);
                  }}
                  className={`${styles.addButton} tag-ds button-primary`}
                >
                  {t(`GENERAL.PERMISSIONS.addUser`)} <span className="material-icons">add</span>
                </button>
              </Box>
            </Box>
            <UserPermissionsTable
              invitesList={
                userStatusFilter
                  ? userStatusFilter === 'pending'
                    ? userPermissions?.filter((x) => !x.lastLoginAt && x.accessStatus !== AcccessStatus.deactivated)
                    : userStatusFilter === 'active'
                    ? userPermissions?.filter((x) => x.accessStatus === userStatusFilter && x.lastLoginAt)
                    : userPermissions?.filter((x) => x.accessStatus === userStatusFilter)
                  : userPermissions
              }
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              allConfigurations={[]}
              pagination={{ setRowsPerPage, setPage, rowsPerPage, page }}
              deleteUser={(user: UserModel) => {
                setUserForDeactivation(user);
                return setDeactivationUserModal(true);
              }}
              userSearch={userSearch}
            />
          </OOTableContainer>
        </div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={deactivationUserModal}
          onClose={() => setDeactivationUserModal(false)}
          style={{ overflowY: 'visible' }}
          PaperProps={{
            style: {
              borderRadius: 8,
            },
          }}
        >
          <div className="tag-ds modal docs ">
            <button className="modal-close-button" onClick={() => setDeactivationUserModal(false)}>
              <span className="material-icons">close</span>
            </button>
            <h4 className="modal-header">{t(`GENERAL.PERMISSIONS.deactivateUser`)}</h4>
            <div className="modal-body">
              {t(`GENERAL.PERMISSIONS.deactivationModalMessage`, { email: userForDeactivation?.email as string })}
            </div>
            <div className="modal-footer">
              <button className="button-secondary" onClick={() => deactivateUser(userForDeactivation as UserModel)}>
                {t(`GENERAL.PERMISSIONS.deactivateUser`).toUpperCase()}
              </button>
              <button className="button-primary" onClick={() => setDeactivationUserModal(false)}>
                {t(`GENERAL.PERMISSIONS.keepAccess`)}
              </button>
            </div>
          </div>
        </Dialog>
      </PageWrapper>
    </div>
  );
};
