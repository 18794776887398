import React from 'react';
import clsx from 'clsx';
import { ContentSwitcher, ContentSwitcherItem } from '@adeccoux/tag-ds';

import styles from './TextFieldSelector.module.scss';

interface Props {
  value: string;
  onChange: (val: string) => void;
  disabled: boolean;
}

const TextFieldSelector: React.FC<Props> = ({ value, onChange, disabled }) => {
  return (
    <ContentSwitcher
      className={clsx(styles.container)}
      defaultActiveItem={value}
      onChangeItem={(val) => {
        if (disabled) return;

        onChange(val);
      }}
    >
      <ContentSwitcherItem id="Textfield" title="Single line" style={{ marginBottom: 0 }} disabled={disabled} />
      <ContentSwitcherItem id="MultilineTextfield" title="Multilines" style={{ marginBottom: 0 }} disabled={disabled} />
    </ContentSwitcher>
  );
};

export default TextFieldSelector;
