import React from 'react';
import clsx from 'clsx';

import styles from './Table.module.scss';

interface Props {
  children: React.ReactElement[] | React.ReactElement;
}

const OOTableContainer: React.FC<Props> = ({ children }) => {
  return <div className={clsx(styles.table__container, 'visible-scroll')}>{children}</div>;
};

export default OOTableContainer;
