import { i18n, TFunction } from 'i18next';

import { SelectedConfiguration } from '../models/configurationModels';

export const checkForTranslationInConfig = (key: string, language: string, configuration?: SelectedConfiguration) => {
  return configuration?.__translation
    ?.find((translation) => translation.key === key)
    ?.translations.find((y) => y.language === language);
};

export const translationHelper = (
  key: string,
  i18nInstance: i18n,
  t: TFunction,
  selectedConfiguration?: SelectedConfiguration,
) => {
  if (!key) return 'No translation key provided!';

  // From config is being used when translation is not yet in translation json file, but it is still holded in configuration
  const fromConfig = checkForTranslationInConfig(key, i18nInstance.language, selectedConfiguration);

  if (fromConfig) return fromConfig.value;

  let result = [];

  if (i18nInstance.exists(`stepper:${key}`)) {
    result.push(t(`stepper:${key}`));
  }
  if (i18nInstance.exists(`entry:${key}`)) {
    result.push(t(`entry:${key}`));
  }
  if (i18nInstance.exists(`approval:${key}`)) {
    result.push(t(`approval:${key}`));
  }
  if (i18nInstance.exists(`hiring:${key}`)) {
    result.push(t(`hiring:${key}`));
  }
  if (i18nInstance.exists(`control-items:${key}`)) {
    result.push(t(`control-items:${key}`));
  }
  if (i18nInstance.exists(`documents:${key}`)) {
    result.push(t(`documents:${key}`));
  }

  if (i18nInstance.exists(`address-country:${key}`)) {
    result.push(t(`address-country:${key}`));
  }

  if (i18nInstance.exists(`address-country-code-fr:${key}`)) {
    result.push(t(`address-country-code-fr:${key}`));
  }

  result = result.filter((x) => !!x);

  function differentiateArray(arr: any[]) {
    // Check if all elements are strings
    const areAllStrings = arr.every((item) => typeof item === 'string');

    // Check if all elements are objects
    const areAllObjects = arr.every((item) => typeof item === 'object' && item !== null && !Array.isArray(item));

    if (areAllStrings && !areAllObjects) {
      return arr[0];
    } else if (areAllObjects && !areAllStrings) {
      const firstProperty = Object.keys(arr[0])[0];
      return arr[0][firstProperty];
    } else {
      return '';
    }
  }

  return result.length > 0 ? differentiateArray(result) : key;
};

export const composeTranslationPath = (featureName: string, stepName: string, pageName?: string) => {
  let result = '';

  result += featureName + '.';
  result += stepName + '.';

  if (pageName) {
    result += pageName + '.';
  }

  return result;
};

export const convertShortToFullLanguageName = (shortName: string, locale: string = 'en') => {
  const languageDisplay = new Intl.DisplayNames([locale], { type: 'language' });
  const fullName = languageDisplay.of(shortName);
  return fullName || shortName;
};
