import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';

import { RoundVerticalStepper } from '../components/RoundVerticalStepper';
import { useFlowWrapper } from '../hooks/useFlowWrapper';
import { useT } from '../hooks/useT';
import { OOSteps, OOStepsEnum } from '../interfaces';
import { StepRole } from '../models/StepModel';
import { OOStepModelWithCompletionInfo } from '../pages/Dashboard';
import { FormControlLoader } from './FormControlLoader';

interface StepsOverviewProps {
  showUnsavedChangesConfirmationModal?: () => Promise<boolean>;
}

const StepsOverview: FC<StepsOverviewProps> = ({ showUnsavedChangesConfirmationModal }) => {
  const flowWrapper = useFlowWrapper();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t, translationsLoading } = useT('stepper');
  const { currentStep, themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);

  const stepperHeader = themeAdditionalData?.stepperHeader;
  const notAccessibleSteps: OOSteps[] = [OOStepsEnum.registration, OOStepsEnum.import];

  const steps: OOStepModelWithCompletionInfo[] = useMemo(() => {
    if (!flowWrapper) return [];
    const currentIndex = flowWrapper.steps.findIndex((step) => step.name === currentStep);
    return flowWrapper.steps
      .map((step, index) => {
        const isCompleted = index < currentIndex;
        const isCurrent = index === currentIndex;
        return { step: step, isCompleted, isCurrent, isNotDisabled: true };
      })
      .filter((s) => s.step.role.includes('candidate'));
  }, [currentStep, flowWrapper]);

  const onClick = useCallback(
    async (s: OOStepModelWithCompletionInfo) => {
      if (showUnsavedChangesConfirmationModal && !(await showUnsavedChangesConfirmationModal())) {
        return;
      }
      const { step } = s;
      if (step.role === StepRole.system || step.role === StepRole.recruiter) {
        return;
      }
      let route: string;

      if (step.pages.filter((page) => !page?.isLastPage).length) {
        route = `${step.name.toLowerCase().replaceAll('_', '-')}-${step.pages[0].name
          .toLowerCase()
          .replaceAll('_', '-')}`;
        dispatch(oneOnboardingSlice.actions.setCurrentForm({ step: step.name, form: step.pages[0].name }));
      } else {
        route = step.name.toLowerCase().replaceAll('_', '-');
        dispatch(oneOnboardingSlice.actions.setCurrentForm({ step: step.name, form: null }));
      }
      history.push(`/oo/flow/${route}${location.search ? location.search : ''}`, { overview: false });
    },
    [history, location.search, dispatch, showUnsavedChangesConfirmationModal],
  );

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  return (
    <div>
      {stepperHeader && (
        <h3 className="tag-ds" style={{ color: stepperHeader.color, marginTop: 16, marginBottom: 20 }}>
          {t(`${stepperHeader.text}`)}
        </h3>
      )}
      <RoundVerticalStepper steps={steps} notAccessibleSteps={notAccessibleSteps} onClick={onClick} />
    </div>
  );
};

export default StepsOverview;
