import axios from 'axios';
import { History, Location } from 'history';
import { injectable } from 'inversify';
import getPkce from 'oauth-pkce';
import { Dispatch } from 'redux';
import { IAdeccoAdSettings } from '@models/featureConfiguration.model';
import { UserModel } from '@models/user.model';
import { UserProfileModel } from '@models/userProfile.model';
import { authAdminSlice } from '@store/slices/authAdmin.slice';

import { AamBackendApi } from '../libs/aamBackendApi';
import { AppConfig } from './appConfig';
import { AzureB2CRedirectType } from './authHelper';
import { Utils } from './utils';

export interface AdeccoAdTokenResponse {
  token_type: string;
  scope: string;
  expires_in: string;
  ext_expires_in: string;
  expires_on: string;
  not_before: string;
  resource: string;
  access_token: string;
  refresh_token: string;
  id_token: string;
}
export interface AdeccoAdTokenResponseParsed {
  tokenType: string;
  scope: string;
  expiresOn: number;
  notBefore: number;
  resource: string;
  accessToken: string;
  refreshToken: string;
  idToken: string;
}

declare const cordova: any;

@injectable()
export class AdeccoAdAuthHelper {
  // InAppBrowser instance.
  private iab: any = null;

  constructor(private readonly appConfig: AppConfig, private readonly aamBackendApi: AamBackendApi) {}
  public async finishLogin(
    tenantId: number,
    adeccoAdId: string,
    tokenResponse: AdeccoAdTokenResponseParsed,
    dispatch: Dispatch<any>,
  ) {
    dispatch(
      authAdminSlice.actions.authAdeccoAD({
        data: tokenResponse,
        adeccoAdId: adeccoAdId,
      }),
    );
    const user = (await this.aamBackendApi.fetchUserByAdId(tenantId, adeccoAdId)) as UserModel;
    if (!user || !user.id) {
      throw Error('No user');
    }
    const userId = user.id;
    const userProfile = (await this.aamBackendApi.fetchUserProfile(tenantId, userId)) as UserProfileModel;

    dispatch(
      authAdminSlice.actions.userData({
        user,
        userProfile,
      }),
    );
  }

  public addCodeVerifierInSessionStorage(codeVerifier: string) {
    sessionStorage.setItem('adeccoAd-codeVerifier', codeVerifier);
  }

  public removeCodeVerifierFromSessionStorage() {
    sessionStorage.removeItem('adeccoAd-codeVerifier');
  }

  public getCodeVerifierFromSessionStorage(): string | null {
    return sessionStorage.getItem('adeccoAd-codeVerifier');
  }

  private async getTokenFromCode(
    tokenUri: string,
    parameters: {
      grantType: string;
      clientId: string;
      redirectUri: string;
      code: string;
      codeVerifier: string;
    },
  ): Promise<AdeccoAdTokenResponse> {
    const encodedParameters = [
      `grant_type=${encodeURIComponent(parameters.grantType)}`,
      `client_id=${encodeURIComponent(parameters.clientId)}`,
      `scope=${encodeURIComponent('openid profile offline_access')}`,
      `redirect_uri=${encodeURIComponent(parameters.redirectUri)}`,
      `code=${encodeURIComponent(parameters.code)}`,
      `code_verifier=${encodeURIComponent(parameters.codeVerifier)}`,
    ].join('&');

    const httpClient = axios.create();
    const response = await httpClient.post<any>(tokenUri, encodedParameters, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    });
    return response.data;
  }

  public async exchangeAuthCode(
    code: string,
    settings: IAdeccoAdSettings,
    redirectUrl: string,
  ): Promise<AdeccoAdTokenResponseParsed> {
    const codeVerifier = this.getCodeVerifierFromSessionStorage();
    if (!codeVerifier) {
      Utils.consoleError('Failed to apply token, empty code_verifier.');
      throw new Error('Failed to apply token, empty code_verifier.');
    }
    this.removeCodeVerifierFromSessionStorage();

    const adeccoAdTokenResponse = await this.getTokenFromCode(settings.tokenUri, {
      grantType: 'authorization_code',
      clientId: settings.clientId,
      redirectUri: redirectUrl,
      code,
      codeVerifier: codeVerifier,
    });
    Utils.consoleLog(adeccoAdTokenResponse);
    return {
      tokenType: adeccoAdTokenResponse.token_type,
      scope: adeccoAdTokenResponse.scope,
      notBefore: parseInt(adeccoAdTokenResponse.not_before),
      expiresOn: parseInt(adeccoAdTokenResponse.expires_on),
      resource: adeccoAdTokenResponse.resource,
      accessToken: adeccoAdTokenResponse.access_token,
      refreshToken: adeccoAdTokenResponse.refresh_token,
      idToken: adeccoAdTokenResponse.id_token,
    };
  }

  public async getAuthorizeUrlSignIn(
    settings: IAdeccoAdSettings,
    location: Location,
    state?: { email?: string; brands?: string[]; isEmailInputDisabled?: boolean; userExistsOnCurrentTenant?: boolean },
  ): Promise<string> {
    return await this.getAuthorizeUrl(settings, {
      action: AzureB2CRedirectType.signIn,
      ...(state ?? {}),
    });
  }

  public get redirectUrl() {
    return `${window.location.protocol}//${window.location.host}/admin/aadlogin/token`;
  }

  private async getAuthorizeUrl(
    settings: IAdeccoAdSettings,
    state: { action: AzureB2CRedirectType; [key: string]: any },
  ): Promise<string> {
    const { codeVerifier, codeChallenge } = (await new Promise((resolve) => {
      getPkce(43, (error, { verifier, challenge }) => {
        if (error) {
          throw error;
        }
        resolve({ codeVerifier: verifier, codeChallenge: challenge });
      });
    })) as any;

    this.addCodeVerifierInSessionStorage(codeVerifier);

    const encryptedState = Utils.encodeBase64(JSON.stringify(state));
    const url =
      `${settings.flowUrl}` +
      `&code_challenge=${codeChallenge}` +
      '&code_challenge_method=S256' +
      `&state=${encodeURIComponent(encryptedState)}`;
    if (window.location.hostname === 'was-eur-ww-dev2-amazondigitalfe.azurewebsites.net') {
      return url.replace(
        'was-eur-ww-dev-amazondigitalfe.azurewebsites.net',
        'was-eur-ww-dev2-amazondigitalfe.azurewebsites.net',
      );
    }
    if (window.location.hostname === 'was-eur-ww-test2-amazondigitalfe.azurewebsites.net') {
      return url.replace(
        'was-eur-ww-test-amazondigitalfe.azurewebsites.net',
        'was-eur-ww-test2-amazondigitalfe.azurewebsites.net',
      );
    }
    return url;
  }

  public isInAppBrowserEnabled(): boolean {
    return typeof cordova !== 'undefined' && cordova?.InAppBrowser;
  }

  private openInAppBrowser(url: string, target: string) {
    if (this.isInAppBrowserEnabled()) {
      if (!this.iab) {
        this.iab = cordova.InAppBrowser.open(
          url,
          target,
          'toolbar=no,location=no,fullscreen=yes,zoom=no,presentationstyle=fullscreen',
        );
      } else {
        this.iab.executeScript({ code: `window.location.href = '${url}';` });
        this.iab.show();
      }
      return this.iab;
    }
    return null;
  }

  private async closeInAppBrowser() {
    return new Promise((resolve) => {
      this.iab.hide();
      resolve(void 0);
    });
  }

  public navigate(authUrl: string, history: History, useExternalBrowser: boolean) {
    if (!this.isInAppBrowserEnabled()) {
      // Just switch window location as we are in web mode
      window.location.href = authUrl;
      return;
    }

    // We are in Cordova mode after this line
    if (useExternalBrowser) {
      Utils.consoleLog(`Opening ${authUrl} in system browser`);
      this.openInAppBrowser(authUrl, '_system');
      return;
    }

    // We are not using external browser. open in inAppBrowser
    const redirectUrl = this.redirectUrl;
    Utils.consoleLog(`Opening ${authUrl} in InAppBrowser`);
    const iab = this.openInAppBrowser(authUrl, '_blank');
    if (!iab.setupDone) {
      iab.setupDone = true;
      iab.openedAt = new Date().valueOf();
      iab.addEventListener('loadstart', async (event: any) => {
        if (!event.url.startsWith(redirectUrl)) {
          Utils.consoleLog('InAppBrowser opened ' + event.url);
          return;
        }
        // we have loaded return url, parse it and forward to return url within app
        const retUrl = new URL(event.url);
        await this.closeInAppBrowser();
        history.push({ pathname: retUrl.pathname, hash: retUrl.hash.substr(1) });
      });
    }
  }
}
