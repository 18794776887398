import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserActionHistory } from '@features/oneOnboarding/models/UserFlow';
import ActionHistoryItem from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/ActionHistoryItem/ActionHistoryItem';
import { CircularProgress, Typography } from '@mui/material';

import styles from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateInfo/ActionHistoryItem/ActionHistoryItem.module.css';

interface ActionHistoryItemListProps {
  data: UserActionHistory[] | undefined;
  isLoading: boolean;
}

const formatDate = (dateString: string | Date) =>
  new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

const ActionHistoryItemList: FC<ActionHistoryItemListProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();

  const groupedByDate = useMemo(() => {
    return (
      data?.reduce((acc, item) => {
        const date = formatDate(item.createdAt!);
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {} as Record<string, UserActionHistory[]>) || {}
    );
  }, [data]);

  const sortedDates = useMemo(() => {
    return Object.keys(groupedByDate)
      .map((date) => new Date(date))
      .sort((a, b) => b.getTime() - a.getTime());
  }, [groupedByDate]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ display: 'grid', gap: '1rem' }}>
      {sortedDates.length === 0 ? (
        <div>{t('recruiter:GENERAL.CANDIDATE_DETAILS.actionHistoryNotFound')}</div>
      ) : (
        <>
          {sortedDates.map((date) => {
            const key = formatDate(date);
            const items = groupedByDate[key];
            return (
              <div key={key}>
                <Typography variant="h6" className={styles.date}>
                  {formatDate(date)}
                </Typography>
                {items.map((item, index) => {
                  let name = ' ';
                  if (item.actor) {
                    if (item.actor.userProfile?.firstName || item.actor.userProfile?.lastName) {
                      name = `${item.actor.userProfile.firstName} ${item.actor.userProfile.lastName}`;
                    } else {
                      name = item.actor.userProfile?.legalName || item.actor.email || '';
                    }
                  }
                  return (
                    <ActionHistoryItem
                      key={index}
                      title={item.actionType}
                      name={name}
                      document={item.document}
                      actionType={item.actionType}
                    />
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ActionHistoryItemList;
