import React, { Dispatch, SetStateAction } from 'react';
import { format } from 'date-fns';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Table, TBody, TElement, THead, TRow } from '@adeccoux/tag-ds';
import { ClientOrganizationModel } from '@models/clientOrganization.model';
import { OnboardingFlowUsersModel } from '@models/onboarding-steps';
import { Grid, Typography } from '@mui/material';

import AdminPagination from '../AdminPagination';
import NameCell from './NameCell';
import { SortingButton } from './SortingButton';

import styles from './AdminDashboardTable.module.css';
interface AdminDashboardTableProps {
  data: OnboardingFlowUsersModel[];
  allOrganizations?: ClientOrganizationModel[];
  allJournies: any[];

  dateFormat: string;
  pagination: {
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    total: number;
    rowsPerPage: number;
    setRowsPerPage: Dispatch<SetStateAction<number>>;
  };
  selectedUsers: any[];
  handleSelectingUsers: (users: any[], selectionType: AdminTableSelectionType) => void;
  enabledTableButtons: { approveEnabled: boolean; rejectEnabled: boolean };
  selection: {
    selectionType: AdminTableSelectionType;
    setSelectionType: (type: AdminTableSelectionType) => void;
  };
  isApproveButtonVisible: boolean;
  isDeleteButtonVisible: boolean;
  isRejectButtonVisible: boolean;

  handleTableActions: (actionType: AdminTableActionType) => void;
  order: string;
  orderBy: string;
  setOrder: (order: string) => void;
  setOrderBy: (orderBy: string) => void;
}

export type AdminTableSelectionType =
  | SelectionType.pageEntries
  | SelectionType.singleEntry
  | SelectionType.totalEntries
  | '';

export enum AdminTableActionType {
  reject = 'reject',
  delete = 'delete',
  approve = 'approve',
}

export enum SelectionType {
  pageEntries = 'pageEntries',
  singleEntry = 'singleEntry',
  totalEntries = 'totalEntries',
}

export enum DashboardColumnHeader {
  headerName = 'headerName',
  headerLastName = 'headerLastName',
  headerClient = 'headerClient',
  headerJourney = 'headerJourney',
  headerBranch = 'headerBranch',
  headerApplication = 'headerApplication',
  headerOnboardingStarted = 'headerOnboardingStarted',
  headerCurrentStatus = 'headerCurrentStatus',
  headerCurrentStepOO = 'headerCurrentStepOO',
  headerLastActivity = 'headerLastActivity',
}

export interface ColumnDefinitionInterfaceItem {
  field: string;
  headerName: DashboardColumnHeader;
  sorting: boolean;
  element: JSX.Element | null;
}

export type ColumnDefinition = {
  [key in keyof typeof DashboardColumnHeader]: ColumnDefinitionInterfaceItem | null;
};

const AdminDashboardTable: React.FC<AdminDashboardTableProps> = ({
  data,
  allOrganizations,
  allJournies,
  dateFormat,
  pagination,
  selectedUsers,
  handleSelectingUsers,
  enabledTableButtons,
  selection,
  isApproveButtonVisible,
  isRejectButtonVisible,
  isDeleteButtonVisible,
  handleTableActions,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const { t } = useTranslation(['recruiter', 'stepper']);

  const { selectionType, setSelectionType } = selection;
  const useSeparateFirstAndLastName = allJournies?.some((j) => j.additionalConfigurations?.useSeparateFirstAndLastName);
  const showOnboardingStatus = allJournies?.some((j) => j.additionalConfigurations?.showOnboardingStatus);
  const hideApplicationColumn = allJournies?.every((j) => !!j.additionalConfigurations?.hideApplicationColumn);
  const getTranslation = (translationPath: string, key: string) => {
    const i18nString = `${translationPath}.${key}`;
    return i18next.exists(i18nString) ? t(i18nString) : key;
  };
  const buildColumns = (
    userFlow?: any,
    onboardingFlowUsersModel?: OnboardingFlowUsersModel,
  ): ColumnDefinitionInterfaceItem[] => {
    const hasRequisitionId = !!onboardingFlowUsersModel?.requisitionId;
    const columns: ColumnDefinition = {
      headerName: {
        field: `"UserProfile"."firstName"`,
        headerName: DashboardColumnHeader.headerName,
        sorting: true,
        element: onboardingFlowUsersModel ? (
          <NameCell userModel={onboardingFlowUsersModel} addLastName={!useSeparateFirstAndLastName} />
        ) : null,
      },
      headerLastName: useSeparateFirstAndLastName
        ? {
            field: `"UserProfile"."lastName"`,
            headerName: DashboardColumnHeader.headerLastName,
            sorting: true,
            element: onboardingFlowUsersModel ? (
              <TElement>{onboardingFlowUsersModel.userProfile?.lastName}</TElement>
            ) : null,
          }
        : null,
      headerClient: {
        field: ``,
        headerName: DashboardColumnHeader.headerClient,
        sorting: true,
        element: onboardingFlowUsersModel ? (
          <TElement>
            {getTranslation(`recruiter:CLIENT_NAME`, getClientName(onboardingFlowUsersModel.clientOrganizationId))}
          </TElement>
        ) : null,
      },
      headerJourney: {
        field: `"Onboarding"."applicationId"`,
        headerName: DashboardColumnHeader.headerJourney,
        sorting: true,
        element: onboardingFlowUsersModel ? (
          <TElement>
            {getTranslation(
              `recruiter:CLIENT_NAME`,
              getJourneyName(onboardingFlowUsersModel.configurationId, onboardingFlowUsersModel.clientOrganizationId),
            )}
          </TElement>
        ) : null,
      },
      headerBranch: {
        field: `"Branch"."name"`,
        headerName: DashboardColumnHeader.headerBranch,
        sorting: true,
        element: onboardingFlowUsersModel ? <TElement>{onboardingFlowUsersModel.branch?.sid}</TElement> : null,
      },
      headerApplication: hideApplicationColumn
        ? null
        : {
            field: `"Onboarding"."applicationId"`,
            headerName: DashboardColumnHeader.headerApplication,
            sorting: true,
            element: onboardingFlowUsersModel ? (
              <TElement>
                {hasRequisitionId
                  ? `${onboardingFlowUsersModel.applicationId} - ${onboardingFlowUsersModel.requisitionId}`
                  : onboardingFlowUsersModel.applicationId}
              </TElement>
            ) : null,
          },
      headerOnboardingStarted: {
        field: `"Onboarding"."createdAt"`,
        headerName: DashboardColumnHeader.headerOnboardingStarted,
        sorting: true,
        element: onboardingFlowUsersModel ? (
          <TElement>
            <Grid container>
              <Grid item xs={12}>
                <span>
                  {onboardingFlowUsersModel?.createdAt && formatTableDate(onboardingFlowUsersModel.createdAt)}
                </span>
              </Grid>
              <Grid item xs={12}>
                <Typography className="feature-dashboard__table-small-text">
                  {onboardingFlowUsersModel.createdAt && formatTableTime(onboardingFlowUsersModel.createdAt)}
                </Typography>
              </Grid>
            </Grid>
          </TElement>
        ) : null,
      },
      headerCurrentStatus: showOnboardingStatus
        ? {
            field: `"Onboarding"."status"`,
            headerName: DashboardColumnHeader.headerCurrentStatus,
            sorting: true,
            element: onboardingFlowUsersModel ? (
              <TElement>
                {t(`control-items:GENERAL.STATUS_FILTERS.${onboardingFlowUsersModel.status?.toUpperCase()}.label`)}
              </TElement>
            ) : null,
          }
        : null,
      headerCurrentStepOO: {
        field: `"UserFlow"."currentStep"`,
        headerName: DashboardColumnHeader.headerCurrentStepOO,
        sorting: true,
        element: <TElement>{t(`stepper:${userFlow?.label}`)}</TElement>,
      },
      headerLastActivity: {
        field: `"Onboarding"."updatedAt"`,
        headerName: DashboardColumnHeader.headerLastActivity,
        sorting: true,
        element: onboardingFlowUsersModel ? (
          <TElement>
            <Grid container>
              <Grid item xs={12}>
                <span>{formatTableDate(onboardingFlowUsersModel.updatedAt)}</span>
              </Grid>
              <Grid item xs={12}>
                <Typography className="feature-dashboard__table-small-text">
                  {formatTableTime(onboardingFlowUsersModel.updatedAt)}
                </Typography>
              </Grid>
            </Grid>
          </TElement>
        ) : null,
      },
    };

    return Object.values(columns).filter((item) => !!item) as ColumnDefinitionInterfaceItem[];
  };

  const getClientName = (id: string) => {
    const client = allOrganizations?.find((c) => c.id === id);
    return client?.displayName ?? '';
  };

  const getJourneyName = (id: string, clientOrganizationId: string) => {
    const journey = allJournies?.find((j: any) => j.id === id && j.clientOrganizationId === clientOrganizationId);

    return journey?.recruiterName ? journey?.recruiterName : '';
  };

  const defaultDateFormat = 'yyyy-MM-dd';
  const formatTableTime = (date: Date) => format(new Date(date), 'HH:mm:ss');
  const formatTableDate = (date: Date) => format(new Date(date), dateFormat || defaultDateFormat);

  const generateSelectionText = (type: string) => {
    const res: any = {
      pageEntries: t('GENERAL.GENERIC.pageEntries', { pageEntries: data.length }),
      singleEntry: t('GENERAL.GENERIC.singleEntry', { selectedUsers: selectedUsers.length }),
      totalEntries: t('GENERAL.GENERIC.totalEntries', { totalEntries: pagination.total }),
    };

    return res[type];
  };

  return (
    <div className="tag-ds App">
      {selectedUsers.length > 0 && (
        <div className="data-table-wrapper data-table-action-bar">
          <div className="data-table-action-bar">
            <span className="data-table-items-selected">
              {generateSelectionText(selectionType)}
              {selectionType === 'pageEntries' && (
                <button
                  className="admin-table-buttons small"
                  style={{ display: 'inline-block' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectionType(SelectionType.totalEntries);
                    handleSelectingUsers(data, SelectionType.totalEntries);
                  }}
                >
                  {`${t('GENERAL.GENERIC.selectAll')} ${pagination.total} ${t('GENERAL.GENERIC.entries')}`}
                </button>
              )}
              {selectionType === 'totalEntries' && (
                <button
                  className="admin-table-buttons small"
                  style={{ display: 'inline-block' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectionType('');
                    handleSelectingUsers([], SelectionType.pageEntries);
                  }}
                >
                  {t('GENERAL.GENERIC.clearSelection')}
                </button>
              )}
            </span>

            <div className="data-table-action-items" style={{ gap: 0 }}>
              {isRejectButtonVisible && (
                <button
                  className="admin-table-buttons small"
                  disabled={!enabledTableButtons.rejectEnabled}
                  onClick={() => {
                    setSelectionType('');

                    handleTableActions(AdminTableActionType.reject);
                  }}
                >
                  {t('CANDIDATES_OVERVIEW.reject')} <span className="material-icons left">do_not_disturb_on</span>
                </button>
              )}
              {isApproveButtonVisible && (
                <button
                  className="admin-table-buttons small"
                  disabled={!enabledTableButtons.approveEnabled}
                  onClick={() => {
                    setSelectionType('');
                    handleTableActions(AdminTableActionType.approve);
                  }}
                >
                  {t('CANDIDATES_OVERVIEW.approve')} <span className="material-icons left">check_circle</span>
                </button>
              )}
              {isDeleteButtonVisible && (
                <button
                  className="admin-table-buttons small"
                  onClick={() => {
                    handleTableActions(AdminTableActionType.delete);
                    setSelectionType('');
                  }}
                >
                  {t('CANDIDATES_OVERVIEW.delete').toUpperCase()} <span className="material-icons left">delete</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <Table className={styles.adminDashboardTable}>
        <THead>
          <TRow>
            <TElement>
              <div className="checkbox-container">
                <input
                  id="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectionType(SelectionType.pageEntries);
                    } else {
                      setSelectionType('');
                    }
                    handleSelectingUsers(data, SelectionType.pageEntries);
                  }}
                  type="checkbox"
                  checked={selectionType === SelectionType.pageEntries || selectionType === SelectionType.totalEntries}
                />
              </div>
            </TElement>
            {buildColumns().map(({ sorting, field, headerName }, index) => {
              return (
                <TElement key={field + index} style={{ position: 'relative' }}>
                  {sorting && (
                    <SortingButton
                      onClick={() => {
                        setOrder(orderBy === field && order === 'asc' ? 'desc' : 'asc');
                        setOrderBy(field);
                      }}
                      direction={orderBy === field ? order : 'asc'}
                      active={orderBy === field}
                    />
                  )}
                  {t(`recruiter:GENERAL.IMPORT.${headerName}`)}
                </TElement>
              );
            })}
          </TRow>
        </THead>
        <TBody>
          {data.length > 0 &&
            data.map((x, i) => {
              const userFlow: any = x?.userProfile?.user?.userFlows.find(
                (f: any) =>
                  f.applicationId === x.applicationId &&
                  f.configurationId === x.configurationId &&
                  f.clientOrganizationId === x.clientOrganizationId,
              );
              const isUserSelected = selectedUsers.find(
                (y) =>
                  y.applicationId === x.applicationId &&
                  y.clientOrganizationId === x.clientOrganizationId &&
                  y.userId === x.userId,
              );
              const isChecked = !!isUserSelected || selectionType === 'totalEntries';

              return (
                <TRow key={i}>
                  <TElement>
                    <div className="checkbox-container">
                      <input
                        id="checkbox"
                        onChange={() => {
                          setSelectionType(SelectionType.singleEntry);
                          handleSelectingUsers([x], SelectionType.singleEntry);
                        }}
                        type="checkbox"
                        checked={isChecked}
                      />
                    </div>
                  </TElement>
                  {buildColumns(userFlow, x).map(({ element }, index) => (
                    <React.Fragment key={index}>{element}</React.Fragment>
                  ))}
                </TRow>
              );
            })}
        </TBody>
      </Table>
      {data.length > 0 && (
        <AdminPagination
          rowsPerPage={pagination.rowsPerPage}
          setRowsPerPage={pagination.setRowsPerPage}
          total={pagination.total}
          currentPage={pagination.page}
          setPage={pagination.setPage}
          isRowsPerPageVisible={true}
        />
      )}
    </div>
  );
};

export default AdminDashboardTable;
