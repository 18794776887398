export enum SelfServiceItemType {
  CONTROL = 'CONTROL',
  DOCUMENTATION = 'DOCUMENTATION',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_FOR_SIGNATURE = 'DOCUMENT_FOR_SIGNATURE',
}

export interface SelfServiceSearchResult {
  name: string;
  selfServiceLibraryId: string;
}

interface SelfServiceLibraryItem {
  selfServiceLibraryId: string;
  name: string;
  clientName: string;
  data: { [key: string]: any };
  type: SelfServiceItemType;
  hash: string;
  createdAt: string;
  updatedAt: string;
}

export interface SelfServiceLibraryItemResponse {
  id: string;
  tenantId: number;
  featureName: string;
  stepName: string;
  selfServiceLibraryItems: SelfServiceLibraryItem[];
  createdAt: string;
  updatedAt: string;
}
