export interface ChosenFileValidation {
  sizeError: boolean;
  typeError: boolean;
  emptyFileError: boolean;
  valid: boolean;
}

interface ChosenFileValidationOptions {
  maxSize?: number;
  allowOnlyImages?: boolean;
}

const DEFAULT_MAX_SIZE = 10 * 1024 * 1024; // 10 MB
const IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
const DOCUMENT_TYPES = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
const ALL_TYPES = IMAGE_TYPES.concat(['application/pdf']);

export function validateChosenFile(
  tenantId: number,
  file: File,
  options?: ChosenFileValidationOptions,
): ChosenFileValidation {
  const sizeError = file.size > (options?.maxSize || DEFAULT_MAX_SIZE);
  const emptyFileError = file.size === 0;

  if ([107, 174].includes(tenantId) && file.name.endsWith('.jfif')) {
    return { sizeError, emptyFileError, typeError: true, valid: false };
  }

  const allowedTypes = options?.allowOnlyImages ? IMAGE_TYPES : ALL_TYPES;
  const typeError = !allowedTypes.includes(file.type);

  return { sizeError, emptyFileError, typeError, valid: !sizeError && !typeError && !emptyFileError };
}

export function getBlobData(content: any, contentType: string, exampleName?: string) {
  if (!ALL_TYPES.includes(contentType) && !DOCUMENT_TYPES.includes(contentType)) {
    throw new Error(`Invalid document content type ${contentType}`);
  }

  const isImage = IMAGE_TYPES.includes(contentType);
  const blob = new Blob([content], { type: contentType });
  const imageUrl = URL.createObjectURL(blob);
  const size = blob.size;
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = `${exampleName}`;
  return { imageUrl, isImage, size, link };
}

export async function getBase64EncodedStringFromFile(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.addEventListener('error', (err) => reject(err));

    reader.readAsDataURL(file);
  });
}
