import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxedButton } from '@components/shared/BoxedButton/BoxedButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';

interface ManualActionDialogProps {
  open: boolean;
  users: Array<any>;
  onSelect: (item?: any) => void;
}
export const ManualActionDialog: React.FC<ManualActionDialogProps> = ({ onSelect, open, users }) => {
  const { t } = useTranslation(['recruiter-deprecated']);
  return (
    <Dialog maxWidth="lg" open={open} className="manual-action-dialog">
      <MuiDialogTitle className="manual-action-dialog-title">
        <Box mt={1} display="flex" alignItems="center">
          <CancelIcon className="manual-action-dialog__icon" />
          &nbsp;
          {t('MANUAL_ACTION.manualActionNeeded')}
        </Box>
        <IconButton onClick={() => onSelect()} size="large">
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>

      <MuiDialogContent>
        <Typography gutterBottom>
          {t('MANUAL_ACTION.theUploadDidNotTriggerTheIdDeletionFor')} <strong>{users.length}</strong>&nbsp;
          {t('MANUAL_ACTION.specificCandidates')}
        </Typography>

        <Grid className="manual-action-dialog-grid">
          {users.map((item, index) => (
            <Grid key={index} className="manual-action-dialog-grid-item">
              <Grid item className="manual-action-dialog-grid-item__name">
                <Typography variant="subtitle2">{t('candidateName')}</Typography>
                <Typography className="manual-action-dialog-grid-item__name-value">
                  {item?.userProfile?.firstName} {item?.userProfile?.lastName}
                </Typography>
              </Grid>
              <Grid item className="manual-action-dialog-grid-item__id">
                <Typography variant="subtitle2">{t('candidateId')}</Typography>
                <Typography className="manual-action-dialog-grid-item__id-value">
                  {item.userProfile?.onboardings?.[0]?.localCandidateId}
                </Typography>
              </Grid>
              <Grid item className="manual-action-dialog-grid-item__action">
                {item.deleted && <CheckCircleIcon />}

                {!item.deleted && (
                  <BoxedButton
                    size="small"
                    color="primary"
                    text={t('viewProfile')}
                    component="span"
                    onClick={() => onSelect(item)}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </MuiDialogContent>
    </Dialog>
  );
};
