import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';
import { useDebounce } from '@components/shared/customHooks/useDebounce';
import { useInjection } from '@context/inversify-context-provider';
import { AutocompleteRenderInputParams, FormControl, FormHelperText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { textErrors } from '../../helpers';
import { useFilterCondition } from '../../hooks/useFilterCondition';
import { useT } from '../../hooks/useT';
import { OOSelectorDropdownModelInterface } from '../../interfaces';
import { FormControlLoader } from '../FormControlLoader';

export const ExtendableAutoComplete: React.VoidFunctionComponent<OOSelectorDropdownModelInterface> = (props) => {
  const {
    name,
    label,
    isMandatory,
    controlHeaderName,
    filterCondition,
    documentId,
    defaultValue,
    preventPopupWithoutInput,
  } = props;
  const queryName = controlHeaderName || name;
  const [open, setOpen] = useState(false);
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(name);
  const fieldMeta = formik.getFieldMeta(name);
  const initialValue: any = fieldMeta.initialValue;
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 1000);
  const { t, translationsLoading } = useT('entry', 'documents', 'control-items', 'candidate_recruiter');
  const [value, setValue] = useState<any>({ value: '', label: '' });
  const [initialAdded, setInitialAdded] = useState<any>(null);
  let prefix = label.split('.')[0].toLowerCase();

  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }

  const backendApi = useInjection(AamBackendApi);
  const { filterData, isDisabled } = useFilterCondition(filterCondition);
  const enabled = useMemo(() => {
    return open;
  }, [open]);

  const { isFetching, data } = useQuery({
    queryKey: [name, debouncedValue, documentId, queryName, filterData, initialValue],
    queryFn: () =>
      documentId
        ? backendApi.getDocumentControlItems(queryName, documentId, { value: debouncedValue, ...filterData })
        : backendApi.getControlItems(queryName, { value: debouncedValue, ...filterData, initialValue }),
    enabled,
    staleTime: 20 * 1000,
  });

  useEffect(() => {
    const fetchItems = async () => {
      if (initialValue || defaultValue) {
        const controlItems = documentId
          ? await backendApi.getDocumentControlItems(queryName, documentId, {
              value: inputValue,
              ...filterData,
            })
          : await backendApi.getControlItems(queryName, { value: inputValue, ...filterData, initialValue });

        let newValue;
        if (initialValue) {
          newValue = controlItems.find((i) => i.value.toString() === initialValue?.toString());
          if (!newValue) {
            newValue = {
              value: initialValue,
              label: initialValue,
              id: uuid(),
              description: 'USER_ADDED',
            };
            setInitialAdded(newValue);
          }
        }
        if (!newValue && defaultValue) {
          newValue = controlItems.find((i) => i.value.toString() === defaultValue);
        }
        setInputValue(newValue?.label ?? '');
        formik.setFieldValue(name, newValue?.value ?? '');
        setValue(newValue ?? undefined);
      }
    };
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  const renderDescription = () => {
    if (props.description) {
      return <FormHelperText>{t(`${prefix}:${props.description}`)}</FormHelperText>;
    }
  };

  return (
    <FormControl>
      <Autocomplete
        {...fieldProps}
        isOptionEqualToValue={(option, selected) => option?.value === selected?.value}
        disabled={isDisabled}
        loading={isFetching}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={data || []}
        filterOptions={(options, params) => {
          const filtered = (preventPopupWithoutInput === true
            ? inputValue
              ? [...options, initialAdded]
              : []
            : [...options, initialAdded]
          ).filter((x) => x);
          const isExisting = filtered.some((option) => option.label === inputValue);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              value: inputValue,
              label: `${t('candidate_recruiter:GENERAL.GENERIC.add')} "${inputValue}"`,
              id: uuid(),
              description: 'USER_ADDED',
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }

          if (option.label === `${t('candidate_recruiter:GENERAL.GENERIC.add')} "${inputValue}"`) {
            return `${t('candidate_recruiter:GENERAL.GENERIC.add')} "${inputValue}"`;
          }

          return t(`control-items:${option.label}`) || '';
        }}
        loadingText={t('entry:loadingText')}
        noOptionsText={t('entry:noOptionsText')}
        closeText={t('candidate_recruiter:GENERAL.GENERIC.close')}
        clearText={t('candidate_recruiter:GENERAL.GENERIC.clear')}
        openText={t('candidate_recruiter:GENERAL.GENERIC.open')}
        onChange={(event: any, newValue: any | null) => {
          setInputValue((newValue?.description === 'USER_ADDED' ? newValue?.value : newValue?.label) || '');
          formik.setFieldValue(name, newValue?.value ?? '');
          setValue(
            newValue
              ? {
                  ...newValue,
                  id: data?.find((i) => i.value === newValue.value)?.id ?? (newValue.id || ''),
                  label: newValue?.description === 'USER_ADDED' ? newValue?.value : newValue?.label,
                }
              : null,
          );
        }}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            {...textErrors(fieldMeta)}
            label={`${t(`${prefix}:${label}`)} ${isMandatory ? '*' : ''}`}
          />
        )}
      />
      {renderDescription()}
    </FormControl>
  );
};
