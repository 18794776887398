import { FormikContextType } from 'formik';
import moment from 'moment';
import { store } from '@store/index';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';
import { OOStepModel } from '../models/StepModel';

export class StartDateBeforeAvailabilityDate implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;
  private flowData: OOStepModel[];
  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = formControls?.filter((fc) => fc.name.includes('START_DATE_WARNING')) ?? [];
    this.allControls = formControls ?? [];
    this.formik = formik;
    this.flowData = store.getState().oneOnboarding.flow;
  }

  onChange = (startDate: Date) => {
    this.control.value = startDate;
    const endDate = this.allControls?.find(
      (control) =>
        control.name === 'END_DATE' ||
        control.name.includes('ADDRESS_TO') ||
        control.name.includes('EMPLOYMENT_HISTORY_TO'),
    );

    if (endDate) {
      const today = moment(moment().format('YYYY-MM-DD'));
      const selectedDay = moment(moment(startDate).format('YYYY-MM-DD'));

      const diffDays = today.diff(selectedDay, 'days');
      endDate.minOffset = selectedDay.isAfter(today) ? Math.abs(diffDays) : -Math.abs(diffDays);
    }

    const availableFrom = this.flowData
      ?.find((step) => step.name === 'CANDIDATE_DETAILS')
      ?.pages.find((i) => i.name === 'GENERAL_INFO')
      ?.controls.find((c) => c.name === 'AVAILABLE_FROM');

    const firstRelevantControl = this.relevantControls && this.relevantControls[0];

    if (firstRelevantControl) {
      if (availableFrom && startDate) {
        const availableFromDateObj = moment(availableFrom.value).format('YYYY-MM-DD');
        firstRelevantControl.isVisibleRecruiter = moment(availableFromDateObj).isAfter(startDate);
      } else {
        firstRelevantControl.isVisibleRecruiter = false;
      }
    }
  };
}
