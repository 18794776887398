import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from '@mui/material';
interface DeleteCandidateDialogProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  deleting: boolean;
  skipDeletionMail: boolean;
  onChangeSkipDeletionMail: (value: boolean) => void;
}
export const DeleteCandidateDialog: React.FC<DeleteCandidateDialogProps> = ({
  open,
  onClose,
  onDelete,
  deleting,
  skipDeletionMail,
  onChangeSkipDeletionMail,
}) => {
  const { t } = useTranslation(['recruiter']);
  return (
    <>
      <Dialog className="multi-candidate-delete-modal" open={open} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle>
          <Typography variant="h3" component="span">
            {t('recruiter:GENERAL.CANDIDATE_DELETION.deleteCandidateUserMessage')}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} className="closer">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  className="checkBox"
                  onChange={() => onChangeSkipDeletionMail(!skipDeletionMail)}
                  checked={skipDeletionMail}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'start',
                    justifyContent: 'left',
                  }}
                />
              }
              label={t('recruiter:GENERAL.CANDIDATE_DELETION.skipDeletionMail')}
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions className="tag-ds">
          <button className="button-secondary" onClick={onClose}>
            {t(`GENERAL.CANDIDATE_DELETION.dontDelete`).toUpperCase()}
          </button>
          <button className="button-primary" onClick={onDelete}>
            {deleting && <CircularProgress color="primary" size={14} />}
            {!deleting && t('GENERAL.CANDIDATE_DELETION.deleteCandidate').toUpperCase()}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};
