import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Help, HelpOutlineOutlined, Info, InfoOutlined } from '@mui/icons-material';
import { Box, FormControl, TextField, Typography, useTheme } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { store } from '@store/index';

import { textErrors } from '../../helpers';
import { editableCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { TextFieldControlModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { FormControlLoader } from '../FormControlLoader';

type OOTextFieldProps = {
  controlModel: TextFieldControlModelInterface;
  textFieldProps?: Omit<TextFieldProps, 'value'>;
  onChangeCallback?: (event: any) => void;
  iconType: 'Info' | 'Help';
};

export const OOTextFieldWithButton: React.FC<OOTextFieldProps> = ({
  controlModel,
  textFieldProps,
  onChangeCallback,
  iconType,
}: OOTextFieldProps) => {
  const { t, translationsLoading } = useT('entry', 'approval', 'documents');
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(controlModel.name);
  const fieldMeta = formik.getFieldMeta(controlModel.name);
  const flowData = store.getState().oneOnboarding.flow;
  const configurationClasses = controlModel.customStyles ?? [];
  const prefix = controlModel.label.split('.')[0].toLowerCase();
  const theme = useTheme();

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isDisabled = !(
    flowWrapper?.isControlEditable(controlModel.isEditableCandidate, controlModel.isEditableRecruiter) ?? true
  );

  const isEditable = editableCondition(controlModel, flowWrapper, formik.values) ?? true;

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  if (controlModel?.defaultValue !== undefined && fieldProps.value === undefined) {
    formik.setFieldValue(controlModel.name, controlModel.defaultValue);
  }
  const onChange = (event: any) => {
    formik.handleChange(event);
    if (onChangeCallback) {
      onChangeCallback(event);
    }
  };
  const description = t(`${prefix}:${controlModel.description}`);

  return (
    <Box className={`textFieldWithButton ${configurationClasses.join(' ')}`}>
      <Box display="flex" alignItems="center">
        <FormControl>
          <TextField
            {...fieldProps}
            {...textErrors(fieldMeta)}
            {...textFieldProps}
            InputLabelProps={{ required: controlModel.isMandatory }}
            // variant="outlined"
            fullWidth={true}
            placeholder={t(`${prefix}:${controlModel.label}`)}
            label={t(`${prefix}:${controlModel.label}`)}
            disabled={isDisabled || !isEditable}
            onChange={onChange}
            defaultValue={controlModel.defaultValue}
          />
        </FormControl>
        {description && (
          <Box
            pl={1}
            onClick={() => setIsInfoVisible(!isInfoVisible)}
            // TODO: migration to mui 5 issue
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            mt={theme.props?.MuiTextField?.variant === 'standard' ? 3 : 0}
          >
            {iconType === 'Info' && (isInfoVisible ? <Info color="primary" /> : <InfoOutlined color="primary" />)}
            {iconType === 'Help' &&
              (isInfoVisible ? <Help color="primary" /> : <HelpOutlineOutlined color="primary" />)}
          </Box>
        )}
      </Box>
      {description && isInfoVisible && (
        <Box
          bgcolor={theme.palette.primary.main}
          px={2}
          py={1}
          my={1}
          className="textFieldWithButton__infoDescription__box"
        >
          <Typography
            variant="body2"
            style={{ color: theme.palette.primary.contrastText }}
            className="textFieldWithButton__infoDescription__text"
          >
            {description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
