import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import ErrorBoundaryWrapper from '@components/shared/ErrorBoundaryWrapper/ErrorBoundaryWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { ViewOnlyContext } from '@context/view-only-provider';
import SSErrorBoundaryFallback from '@features/selfService/components/SSErrorBoundaryFallback/SSErrorBoundaryFallback';
import { useGetUrlParameter } from '@hooks/useGetUrlParameter';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { selfServiceSlice } from '@store/slices/selfService.slice';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import ConfigurationActions from './components/configurationActions/ConfigurationActions';
import PagePreview from './components/pageFeature/PagePreview';
import RenderList from './components/RenderList';
import StepsSelector from './components/StepsSelector';

import styles from './ConfigurationSetup.module.scss';

const ConfigurationSetupPage = () => {
  const history = useHistory();
  const configurationId = useGetUrlParameter('configurationId') || '';
  const clientOrganizationId = useGetUrlParameter('clientOrganizationId') || '';
  const saved = useGetUrlParameter('saved');
  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const aamBackendApi = useInjection(AamBackendApi);
  const newConfigurationDetails = useHistory().location.state?.configurationInfo;
  const dispatch = useDispatch();
  const { setIsViewOnly, isViewOnly } = useContext(ViewOnlyContext);
  const viewOnlyFromParameters = useGetUrlParameter('viewOnly');

  useEffect(() => {
    store.dispatch(oneOnboardingSlice.actions.setClientOrganizationId(clientOrganizationId || ''));
    store.dispatch(oneOnboardingSlice.actions.setConfigurationId(configurationId || ''));
  }, [clientOrganizationId, configurationId]);

  useEffect(() => {
    const isViewOnly = viewOnlyFromParameters === 'true';

    setIsViewOnly(isViewOnly);
  }, [setIsViewOnly, viewOnlyFromParameters]);

  useQuery({
    queryKey: ['exportConfiguration', clientOrganizationId, configurationId],
    queryFn: () => aamBackendApi.configExport(0, clientOrganizationId, configurationId),
    refetchOnMount: true,
    onSuccess: (data) => {
      // merge data from configurationDetails and from endpoint
      const payload = { ...data, ...newConfigurationDetails };

      dispatch(
        selfServiceSlice.actions.initializeConfiguration({ configuration: payload, configurationChangeStack: [] }),
      );
    },
    enabled: !saved || !selectedConfiguration,
  });

  const loadSaved = saved === 'true';

  if (!configurationId && !clientOrganizationId && !loadSaved) {
    history.push('/oo/recruiter/self-service/configuration-list');
  }

  if (loadSaved && !selectedConfiguration) {
    history.push('/oo/recruiter/self-service/configuration-list');
  }

  return (
    <PageWrapper
      featureName="dashboard"
      pageName="dashboard"
      sideMenu
      headerConfig={{
        pageName: 'Client Form Setup',
        onBack: () => history.push('/oo/recruiter/self-service'),
      }}
    >
      <div className={styles.container}>
        <div className={styles.stepsSelector}>
          <ErrorBoundaryWrapper Fallback={SSErrorBoundaryFallback}>
            <StepsSelector />
          </ErrorBoundaryWrapper>
        </div>
        <div className={styles.pagePreview}>
          <ErrorBoundaryWrapper Fallback={SSErrorBoundaryFallback}>
            <PagePreview />
          </ErrorBoundaryWrapper>
        </div>
        <div className={styles.controlList}>
          <ErrorBoundaryWrapper Fallback={SSErrorBoundaryFallback}>
            <RenderList />
          </ErrorBoundaryWrapper>
        </div>
        {!isViewOnly && (
          <div className={styles.configurationActions}>
            <ErrorBoundaryWrapper Fallback={SSErrorBoundaryFallback}>
              <ConfigurationActions />
            </ErrorBoundaryWrapper>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ConfigurationSetupPage;
