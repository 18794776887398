import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import jwt from 'jsonwebtoken';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { AdeccoAdAuthHelper } from '@helpers/adeccoAdAuthHelper';
import { getRedirectionUrl, getSelectedConfigurationId, setRedirectionUrl } from '@helpers/tenant.helper';
import { Utils } from '@helpers/utils';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { toastSlice } from '@store/slices/toast.slice';

import { AamBackendApi } from '../../libs/aamBackendApi';

export const AdminAdeccoAdHandler: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const utils = useInjection(Utils);
  const adeccoAdAuthHelper = useInjection(AdeccoAdAuthHelper);
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useSelector((state: RootState) => state.tenant.tenantId);
  const tenant = useSelector((state: RootState) => state.tenant);
  const adeccoAd = useSelector((state: RootState) => state.featureConfiguration.adeccoAd);
  const hashes = useMemo(() => {
    return utils.extractHashAndSearch(location.hash, location.search);
  }, [utils, location.hash, location.search]);
  useEffect(() => {
    const asyncCall = async () => {
      try {
        if (hashes?.error) {
          dispatch(toastSlice.actions.showError({ title: hashes.error, message: hashes.error_description }));
          Utils.consoleError(hashes.error + hashes.error_description?.split('\n')[0]);
          history.push(tenant.useOneOnboarding ? '/oo/recruiter' : '/admin');
          return;
        }

        if (hashes?.code) {
          if (adeccoAdAuthHelper.getCodeVerifierFromSessionStorage() === '') {
            Utils.consoleError('No code verifier in session storage');
            return;
          }

          const tokenResponse = await adeccoAdAuthHelper.exchangeAuthCode(
            hashes.code,
            adeccoAd.signIn,
            adeccoAdAuthHelper.redirectUrl,
          );
          // TODO: Verify JWT

          try {
            await aamBackendApi.adeccoAdTokenReceived(tokenResponse.accessToken);

            const accessTokenPayload: any = jwt.decode(tokenResponse.accessToken);
            await adeccoAdAuthHelper.finishLogin(tenantId, accessTokenPayload.oid, tokenResponse, dispatch);

            const configurationId = getSelectedConfigurationId();
            dispatch(oneOnboardingSlice.actions.setConfigurationId(configurationId));

            if (tenant.useOneOnboarding) {
              const redirectionUrl = getRedirectionUrl();
              if (redirectionUrl) {
                setRedirectionUrl('');
                history.push(redirectionUrl);
              } else {
                history.push('/oo/recruiter');
              }
            } else {
              history.push('/admin');
            }
            return;
          } catch (err) {
            const error = err as AxiosError;
            if (
              error?.response?.data?.error?.name === 'AuthUnauthorizedError' ||
              error?.response?.data?.error?.name === 'AuthUserDoesNotHaveRequiredScopesError'
            ) {
              history.push(tenant.useOneOnboarding ? '/oo/recruiter/unauthorized' : '/admin/unauthorized');
              return;
            }
            throw err;
          }
        }
      } catch (err) {
        Utils.consoleError('Failed to apply token' + JSON.stringify(err));
        // await authHelper.logout(dispatch);
      }
    };
    asyncCall();
  }, [
    aamBackendApi,
    adeccoAd,
    dispatch,
    hashes,
    history,
    location,
    tenantId,
    adeccoAdAuthHelper,
    tenant.useOneOnboarding,
  ]);

  return <Loader />;
};
