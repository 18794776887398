import * as React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { BoxProps } from '@mui/material/Box';

type StickyBoxProps = BoxProps & {
  style?: React.CSSProperties;
};

const BottomBox: React.FC<StickyBoxProps> = ({ style, children, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const marginBottom = isMobile ? 'max(40px, env(safe-area-inset-bottom))' : style?.marginBottom;
  return (
    <Box style={{ ...style, marginBottom }} {...rest}>
      {children}
    </Box>
  );
};

export default BottomBox;
