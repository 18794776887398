import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { v4 as uuid } from 'uuid';
import { useInjection } from '@context/inversify-context-provider';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { composeTranslationPath } from '../../../../../helpers/translationHelper';
import EditList from './EditList';
import EditListBe from './EditListBe';
import PreviewList from './PreviewList';

import style from './SingleSelectOptions.module.scss';

export interface Item {
  label: string;
  description: string;
  value: string;
  sequence: number;
  defaultOption: boolean;
  uuid: string;
  name: string;
}

interface Props {
  value: any[];
  setFieldValue: any;
  nameValue: string;
  selectOptionsTranslations: { [key: string]: any };
  fieldProps: any;
  disabled: boolean;
  template: string;
  defaultValue: string | string[];
}

const SingleSelectOptions: React.FC<Props> = ({
  value,
  setFieldValue,
  nameValue,
  selectOptionsTranslations,
  fieldProps,
  disabled,
  template,
  defaultValue,
}) => {
  const [items, setItems] = useState<Item[] | null>(null);
  const [fullscreenActive, setFullscreenActive] = useState(false);
  const [isControlFromBackend, setIsControlFromBackend] = useState(false);

  const { selectedFeature } = useSelector((store: RootState) => store.selfService);
  const aamBackendApi = useInjection(AamBackendApi);

  const { data: controlItems, isLoading } = useQuery({
    queryKey: [fieldProps.form.initialValues?.controlHeaderTable, fieldProps.form.initialValues?.controlHeaderName],
    queryFn: () => {
      if (fieldProps.form.initialValues?.controlHeaderTable) {
        return aamBackendApi.getControlItems(fieldProps.form.initialValues?.controlHeaderTable);
      }
      if (fieldProps.form.initialValues?.controlHeaderName) {
        return aamBackendApi.getControlItems(fieldProps.form.initialValues?.controlHeaderName);
      }
    },
  });

  useEffect(() => {
    const withUuid: any = (values: any[]) =>
      values?.map((x) => ({ ...x, uuid: uuid(), label: x.label, name: x.value }));

    if (isLoading) return;

    if (fieldProps.form.initialValues?.controlHeaderName) {
      const withUuidFromBe: any = (values: any[]) => values?.map((x) => ({ ...x, uuid: uuid() }));

      if (fieldProps.form.initialValues?.controlHeaderTable) {
        setItems(withUuidFromBe(value));
        setIsControlFromBackend(true);
      } else {
        if (controlItems && controlItems.length > 0) {
          setIsControlFromBackend(true);
          setItems(withUuidFromBe(controlItems));
        } else {
          // Since controlHeaderTable is being deleted, controlHeaderValueColumn is here to check is original data from BE
          if (fieldProps.form.initialValues?.controlHeaderValueColumn) {
            setIsControlFromBackend(true);
          } else {
            setIsControlFromBackend(false);
          }
          setItems(withUuidFromBe(value));
        }
      }

      return;
    }

    if (fieldProps.form.initialValues?.controlHeaderTable && !items) {
      const withUuidFromBe: any = (values: any[]) => values?.map((x) => ({ ...x, uuid: uuid() }));

      if (value.length > 0) {
        setItems(withUuidFromBe(value));
      } else {
        setItems(withUuidFromBe(controlItems));
      }

      setIsControlFromBackend(true);

      return;
    }

    if (value?.length !== 0) {
      setItems(withUuid(value));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameValue, isLoading]);

  useEffect(() => {
    // Tracks name of change and update labels accordinaly
    if (!selectedFeature?.featureName) return;

    const getPath = composeTranslationPath(
      selectedFeature?.featureName,
      selectedFeature?.stepName,
      selectedFeature?.pageName,
    );

    const withNewName = items?.map((x) => {
      return {
        ...x,
        label: getPath + nameValue + '.' + x.value.toLowerCase() + '.label',
      };
    });

    if (withNewName) {
      // check this one
      // setItemForEdit(null);
      handleFormikChange(withNewName);
      setItems(withNewName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameValue]);

  const handleFormikChange = (data: Item[]) => {
    const structureForDb = data.map((x) => ({
      label: x.label,
      value: x.value,
      sequence: x.sequence,
      description: x.description,
    }));

    const defaultValue = () => {
      const value = data.find((x) => x.defaultOption === true)?.value;
      if (template === 'MultipleSelect') {
        return [value];
      }

      return value;
    };

    setFieldValue('selectOptions', structureForDb);
    setFieldValue('defaultValue', defaultValue());
  };

  const deleteAll = () => {
    if (disabled) return;

    setFieldValue('selectOptions', []);
    // check this one also
    // setCsvOptions('');
    setItems(null);
  };

  if (isLoading) return <div>loading</div>;

  if (value === undefined) return <p>Cannot reach data</p>;

  if (fullscreenActive) {
    if (isControlFromBackend) {
      return (
        <EditListBe
          onClose={() => setFullscreenActive(false)}
          items={items}
          setItems={setItems}
          handleFormikChange={handleFormikChange}
          setFieldValue={setFieldValue}
          selectOptionsTranslations={selectOptionsTranslations}
        />
      );
    }
    return (
      <EditList
        onClose={() => setFullscreenActive(false)}
        nameValue={nameValue}
        items={items}
        setItems={setItems}
        handleFormikChange={handleFormikChange}
        setFieldValue={setFieldValue}
        selectOptionsTranslations={selectOptionsTranslations}
        defaultValue={defaultValue}
        isMultiple={template === 'MultipleSelect'}
      />
    );
  }

  if (items?.length === 0 || !items) {
    return (
      <div className={clsx(style.initialBox, style.initialBox__title)}>
        <label className="subtitle">Item list</label>
        <div
          className={clsx(style.initialBox__icon, (!nameValue || disabled) && style['initialBox__icon--disabled'])}
          onClick={() => {
            if (nameValue && !disabled) {
              setFullscreenActive(true);
            }
          }}
        >
          <span className={clsx('material-icons', disabled ? 'disabled' : '')}>add</span>
        </div>
      </div>
    );
  }

  return (
    <div className={style.initialBox}>
      <div className={style.initialBox__title}>
        <span className="subtitle">Item list</span>
        <div>
          <div
            className={clsx(style.initialBox__icon, (!nameValue || disabled) && style['initialBox__icon--disabled'])}
            onClick={() => {
              if (nameValue && !disabled) {
                setFullscreenActive(true);
              }
            }}
            style={{ marginRight: 8 }}
          >
            <span className={clsx('material-icons-outlined', disabled ? 'disabled' : '')}>edit</span>
          </div>
          <div
            className={clsx(style.initialBox__icon, (!nameValue || disabled) && style['initialBox__icon--disabled'])}
            onClick={deleteAll}
          >
            <span className={clsx('material-icons-outlined', disabled ? 'disabled' : '')}>delete</span>
          </div>
        </div>
      </div>
      {items && <PreviewList fromBackend={isControlFromBackend} values={items} />}
    </div>
  );
};

export default SingleSelectOptions;
