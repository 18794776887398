import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, DrawerBody, Tree, TreeViewElement } from '@adeccoux/tag-ds';
import { ViewOnlyContext } from '@context/view-only-provider';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { RootState } from '@store/rootReducer';
import { selfServiceSlice } from '@store/slices/selfService.slice';

import { translationHelper } from '../../../helpers/translationHelper';
import PublishConfigurationDialog from './other/PublishConfigurationDialog/PublishConfigurationDialog';

import styles from '../ConfigurationSetup.module.scss';

const stepIcon: { [key: string]: string } = {
  'ENTRY.IMPORT.label': 'upload_file',
  'ENTRY.REGISTRATION.label': 'how_to_reg',
  'ENTRY.CANDIDATE_DETAILS.label': 'manage_accounts',
  'DOCUMENTS.DOCUMENT_UPLOAD.label': 'upload_file',
  'DOCUMENTS.DOCUMENT_VALIDATION.label': 'upload_file',
  'APPROVAL.SIGNATURE.label': 'text_format',
  'APPROVAL.CANDIDATE_CONFIRMS_DOCUMENTS.label': 'assignment',
  'APPROVAL.FEEDBACK.label': '',
  'APPROVAL.APPROVE.label': '',
};

const EXCLUDED_STEPS = ['REGISTRATION', 'HIRE_SIGNATURE', 'FEEDBACK_STEP', 'SIGNATURE', 'HIRE', 'FEEDBACK', 'MATCH'];

const StepsSelector = () => {
  const [uploadConfigurationModal, setUploadConfigurationModal] = useState({
    isVisible: false,
    details: {
      feature: '',
      step: '',
    },
  });

  const { selectedConfiguration, selectedFeature, isPublished } = useSelector((state: RootState) => state.selfService);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['stepper', 'entry', 'approval', 'recruiter']);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const getTranslation = (key: string) => translationHelper(key, i18n, t);

  const renderedFeatures = useMemo(
    () =>
      selectedConfiguration?.features.map(({ name, steps }: any) => ({
        featureName: name,
        steps: steps.map((y: any) => y),
      })) || [],
    [selectedConfiguration?.features],
  );

  useEffect(() => {
    if (!selectedConfiguration) return;

    const selectFirstAvailable = () => {
      let firstFeature = '';
      let firstStep = '';
      let firstPage = '';
      let selectedPage: any = null;

      renderedFeatures?.forEach((feature: any) => {
        if (firstFeature === '') {
          firstFeature = feature.featureName;
        }
        feature.steps.forEach((x: any) => {
          if (!isStepDisabled(x) && stepHasPages(x)) {
            if (firstStep === '') {
              firstStep = x.name;
            }
            x.pages.forEach((page: any) => {
              if (firstPage === '') {
                firstPage = page.name;
                selectedPage = page;
              }
            });
          }
        });
      });

      dispatch(
        selfServiceSlice.actions.handlePageModify({
          ...selectedPage,
          featureName: firstFeature,
          stepName: firstStep,
          pageName: firstPage,
        }),
      );
    };

    if (!selectedFeature?.featureName) {
      selectFirstAvailable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, renderedFeatures, selectedConfiguration, selectedFeature]);

  const onFirstLevelClick = (feature: any, step: any) => {
    if (isDocumentStep(step)) {
      dispatch(
        selfServiceSlice.actions.handleDocumentToUploadFeature({
          documentations: step.documentations,
          featureName: feature.featureName,
          stepName: step.name,
        }),
      );

      return;
    }

    if (isSignatureStep(step)) {
      if (isPublished || isViewOnly) {
        openDocumentToSignPage(feature, step);
      } else {
        setUploadConfigurationModal({ isVisible: true, details: { feature, step } });
      }

      return;
    }
  };

  const onSecondLevelClick = (feature: any, step: any, page: any) => {
    dispatch(
      selfServiceSlice.actions.handlePageModify({
        ...page,
        featureName: feature.featureName,
        stepName: step.name,
        pageName: page.name,
      }),
    );
  };

  const openDocumentToSignPage = (feature: any, step: any) => {
    dispatch(
      selfServiceSlice.actions.handleDocumentToSign({
        featureName: feature.featureName,
        stepName: step.name,
        document: step,
      }),
    );
  };

  const handleHideConfigurationUploadModal = () => {
    setUploadConfigurationModal({
      isVisible: false,
      details: {
        feature: '',
        step: '',
      },
    });
  };

  const stepHasPages = (step: any) => step.pages?.length > 0;
  const isDocumentStep = (step: any) => step.documentations?.length > 0;
  const isSignatureStep = (step: any) => step.documentsForSignature?.length > 0;
  const stepsToExclude = (step: any) => EXCLUDED_STEPS.includes(step.name);

  const isStepDisabled = (step: any) => {
    return (!stepHasPages(step) && !isDocumentStep(step) && !isSignatureStep(step)) || stepsToExclude(step);
  };

  return (
    <Drawer className="tag-ds sidepanel" open style={{ margin: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 className="subtitle">{t('recruiter:GENERAL.SELF_SERVICE.selectStep', 'Configure steps')}</h2>
        <SelfServiceTooltip
          hoverContent="Create and add your desired field steps for your candidate(s) to fill out."
          position="right"
        />
      </div>
      <DrawerBody>
        <Tree className={styles.stepsSelector__container}>
          {renderedFeatures?.map((feature: any) => {
            return feature.steps.map((step: any) => (
              <TreeViewElement
                key={step.name}
                arrow="right"
                name={step.label ? t(step.label) : t(step.recruiterLabel)}
                disabled={isStepDisabled(step)}
                onClick={() => onFirstLevelClick(feature, step)}
                icon={stepIcon[step.label]}
                className={selectedFeature?.stepName === step.name ? styles.stepsSelector__pageActive : ''}
              >
                {stepHasPages(step) && (
                  <div>
                    {step.pages.map((page: any) => (
                      <TreeViewElement
                        key={page.name}
                        name={getTranslation(page.label)}
                        onClick={() => onSecondLevelClick(feature, step, page)}
                        className={selectedFeature?.pageName === page.name ? styles.stepsSelector__pageActive : ''}
                      />
                    ))}
                  </div>
                )}
              </TreeViewElement>
            ));
          })}
        </Tree>
      </DrawerBody>
      <PublishConfigurationDialog
        isVisible={uploadConfigurationModal.isVisible}
        onSave={() => {
          openDocumentToSignPage(uploadConfigurationModal.details?.feature, uploadConfigurationModal.details?.step);
          handleHideConfigurationUploadModal();
        }}
        onCancel={handleHideConfigurationUploadModal}
      />
    </Drawer>
  );
};

export default StepsSelector;
