import { useEffect, useState } from 'react';
import { i18n } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';

import { AamBackendApi } from '../libs/aamBackendApi';

const useBackendTranslations = (i18n: i18n, useFromSelfService?: boolean) => {
  const [, setInternalCounter] = useState(0);

  const aamBackendApi = useInjection(AamBackendApi);
  const dispatch = useDispatch();
  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  useEffect(() => {
    function removePrefix(obj: any): any {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map((item) => removePrefix(item));
      }

      const newObj: any = {};
      for (const key in obj) {
        const colonIndex = key.indexOf(':');
        const newKey = colonIndex !== -1 ? key.substring(colonIndex + 1) : key;
        newObj[newKey] = removePrefix(obj[key]);
      }

      return newObj;
    }

    const getTranslationsFromBe = async () => {
      try {
        const res = await aamBackendApi.getTranslations();

        const perLanguage: { [key: string]: any[] } = {};

        res.forEach((translation) => {
          const parsed = translation.translationKey.split('.');

          const result: any = {};
          let current = result;

          for (let i = 0; i < parsed.length; i++) {
            const segment = parsed[i];

            if (i === parsed.length - 1) {
              current[segment] = translation.translationValue;
            } else {
              current[segment] = {};
              current = current[segment];
            }
          }

          if (!perLanguage[translation.language]) {
            perLanguage[translation.language] = [];
          }

          perLanguage[translation.language].push(result);

          return result;
        });

        Object.keys(perLanguage).forEach((lng) => {
          perLanguage[lng].forEach((y: any) => {
            let fileName = Object.keys(y)[0].toLowerCase();

            if (fileName.includes(':')) {
              fileName = fileName.split(':')[0];
              y = removePrefix(y);
            }

            i18n.addResourceBundle(lng, fileName, { ...y }, true);
          });
        });

        setInternalCounter((state) => state + 1);
      } catch (err) {
        const isMissingAuth = err?.response?.data?.error?.name === 'NoAuthHeaderError';

        if (isMissingAuth) return;

        dispatch(
          snackbarSlice.actions.showError({
            message: 'Failed to get translations!',
          }),
        );
      }
    };

    const getTranslationsFromSelfService = () => {
      const trans = selectedConfiguration?.__translation?.map((translation) => {
        if (Array.isArray(translation.key)) return {};

        const parsed = translation.key.split('.');
        const findValue = translation.translations.find((x) => x.language === i18n.language);

        const result: any = {};
        let current = result;

        for (let i = 0; i < parsed.length; i++) {
          const segment = parsed[i];

          if (i === parsed.length - 1) {
            current[segment] = findValue?.value;
          } else {
            current[segment] = {};
            current = current[segment];
          }
        }

        return result;
      });

      trans?.forEach((x: any) => {
        if (Object.keys(x).length === 0) return;

        const fileName = Object.keys(x)[0].toLowerCase();

        i18n.addResourceBundle(i18n.language, fileName, { ...x }, true, true);
      });

      setInternalCounter((state) => state + 1);
    };

    if (useFromSelfService) {
      getTranslationsFromSelfService();
    } else {
      getTranslationsFromBe();
    }

    // eslint-disable-next-line
  }, [selectedConfiguration?.__translation]);
};

export default useBackendTranslations;
