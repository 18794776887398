import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import ErrorIcon from '@mui/icons-material/Error';
import { Step, StepConnector, StepLabel, Stepper, Typography } from '@mui/material';

import { OODocumentationModel } from '../models/DocumentationModel';
import { OODocumentModel } from '../models/DocumentModel';
import { OOStepModelWithCompletionInfo } from '../pages/Dashboard';
interface DashboardComponentProps {
  steps: OOStepModelWithCompletionInfo[];
  notAccessibleSteps: string[];
  onClick: (data: any) => void;
}

export const hasRejectedMandatoryDocument = (step: any) => {
  if (step.documentations.length > 0) {
    const rejectedDocumentation = step.documentations.filter((documentation: OODocumentationModel) => {
      return documentation.documents.some((document: OODocumentModel) => {
        const isMandatoryRejected =
          (document.isMandatory || (documentation.isMandatory && !documentation.groupDocuments)) &&
          document?.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED;
        return isMandatoryRejected;
      });
    });

    return rejectedDocumentation.length > 0;
  } else return false;
};

export const RoundVerticalStepper: React.FC<DashboardComponentProps> = ({ steps, notAccessibleSteps, onClick }) => {
  const { t } = useTranslation(['stepper', 'entry']);
  const activeStep = steps.reduce((acc, val, index) => {
    if (val.isCompleted) {
      return acc + 1;
    }

    if (val.step.skipStep && (steps[index - 1]?.isCompleted || steps[index - 1]?.step?.skipStep)) {
      return acc + 1;
    }
    if (val.step.pages?.length > 0 && (steps[index - 1]?.isCompleted || steps[index - 1]?.step?.skipStep)) {
      if (val.step.pages.every((p) => p.completeOnEnter)) {
        return acc + 1;
      }
    }
    return acc;
  }, 0);

  const CustomStepIcon = () => (
    <div style={{ color: 'red', backgroundColor: 'white' }}>
      <ErrorIcon />
    </div>
  );

  return (
    <Stepper activeStep={activeStep} orientation="vertical" connector={<StepConnector />}>
      {steps
        .filter((x) => x.step.isVisibleCandidate)
        .map((s) => {
          const enabled = !notAccessibleSteps.includes(s.step.name) && (s.isCurrent || s.isCompleted);
          const hasRejected = hasRejectedMandatoryDocument(s.step);

          return (
            <Step key={s.step.label}>
              <StepLabel
                error={hasRejected}
                StepIconComponent={hasRejected ? CustomStepIcon : undefined}
                style={{ cursor: enabled ? 'pointer' : '' }}
                onClick={(event) => {
                  event.preventDefault();
                  if (enabled) {
                    onClick(s);
                  }
                }}
              >
                <Typography variant="body1" color={s.isCompleted || s.isCurrent ? 'primary' : 'textSecondary'}>
                  {t(s.step.label)}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t(s.step.durationLabel, { duration: s.step.duration })}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
    </Stepper>
  );
};
