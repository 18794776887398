import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getStepperComponent } from '@helpers/getStepperComponent';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { Utils } from '@helpers/utils';
import { Box, Button, Grid, MenuItem } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { OOSelect } from '../controls/fields/OOSelect';
import { useT } from '../hooks/useT';
import { OODocumentationModel } from '../models/DocumentationModel';
import { OODocumentModel } from '../models/DocumentModel';

interface OOUploadDocumentTypeSelectionProps {
  setDocument: (doc?: OODocumentModel) => void;
  documentationModel?: OODocumentationModel;
  setDocumentationModel: (doc?: OODocumentationModel) => void;
}

export const OOUploadDocumentTypeSelection: React.FC<OOUploadDocumentTypeSelectionProps> = ({
  documentationModel,
  setDocument,
  setDocumentationModel,
}) => {
  const { t, translationsLoading } = useT('ooControls', 'documentsUpload', 'documents', 'candidate_recruiter');
  const documentation = useMemo(() => documentationModel, [documentationModel])!;
  const { themeAdditionalData } = useSelector((state: RootState) => state.oneOnboarding);

  if (!documentation) {
    setDocumentationModel(undefined);
    return <></>;
  }

  const initialValues = {
    documentType: '',
  };
  const validationSchema = Yup.object({
    documentType: Yup.string()
      .required()
      .oneOf(documentation.documents.map((d) => d.name)),
  });

  const hasMandatoryDocumentUploaded = documentation.documents.some((d) => d.isMandatory && d.userDocument);

  const renderMenuItems = () => {
    const hasRejectedDocuments = documentation.documents.some(
      (d) => d.userDocument?.additionalData?.status === OnboardingDocumentStatus.REJECTED,
    );
    return documentation.documents
      .filter((d) => {
        if (hasMandatoryDocumentUploaded && !hasRejectedDocuments) {
          return (d.isMandatory && d?.userDocument) || !d.isMandatory;
        } else {
          return d;
        }
      })
      .map((d) => (
        <MenuItem key={d.name} value={d.name}>
          {t(`documents:${d.label}`)} {d.isMandatory ? '*' : ''}
        </MenuItem>
      ));
  };

  const navigateToDocumentUpload = (documentType?: string) => {
    if (!documentType) {
      Utils.consoleError('No document type selected');
      return;
    }
    if (documentation) {
      setDocumentationModel(documentation);
      setDocument(documentation.documents.find((d) => d.name === documentType)!);
    }
  };

  return (
    <Box m={{ md: 2 }}>
      {getStepperComponent(themeAdditionalData.stepper)}
      <Formik
        validateOnMount={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          navigateToDocumentUpload(values.documentType);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} style={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ ...themeAdditionalData.smallTitle }}>
                <h2>{t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.uploadDocument')}</h2>
              </Grid>
              <Grid item xs={12}>
                {documentation?.description &&
                documentation?.description !== t(`documents:${documentation.description}`) ? (
                  <h3>{t(`documents:${documentation.description}`)}</h3>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12}>
                <OOSelect
                  validationKey="documentType"
                  description=""
                  label={t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.selectDocumentToUpload')}
                  loading={translationsLoading}
                  mandatory={true}
                  menuItems={renderMenuItems()}
                />
                {/*{JSON.stringify(props)}*/}
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" disabled={!props.isValid} type="submit">
                  {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.toUpload')}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
};
