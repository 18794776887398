import React from 'react';
import { FieldProps } from 'formik';

interface Props {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  isError: any;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  fieldProps?: FieldProps;
}

const MinMaxValidationError: React.FC<Props> = ({ value, onChange, name, isError, onBlur, fieldProps }) => {
  return (
    <>
      <label className="caption">Min-Max Validation Error Text</label>
      <input
        type="text"
        value={value}
        onChange={onChange}
        name={name}
        className={isError ? 'error' : ''}
        onBlur={onBlur}
        disabled
      />
      {isError && (
        <p className="input-helper-text" style={{ margin: 0 }}>
          {fieldProps?.meta.error}
        </p>
      )}
    </>
  );
};

export default MinMaxValidationError;
