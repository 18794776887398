import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Table, TBody, TElement, THead, TRow } from '@adeccoux/tag-ds';
import { getComparator, stableSort } from '@components/admin/dashboard/kpi/helpers';
import { OOConfiguration } from '@features/oneOnboarding/interfaces';
import { AcccessStatus, UserModel } from '@models/user.model';

import { SortingButton } from '../../../components/AdminDashboardTable/SortingButton';
import AdminPagination from '../../../components/AdminPagination';

interface UserPermissionsTableProps {
  invitesList: UserModel[] | undefined;
  order: string;
  orderBy: string;
  setOrder: (value: string) => void;
  setOrderBy: (value: string) => void;
  allConfigurations: OOConfiguration[];
  pagination: {
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    rowsPerPage: number;
    page: number;
  };
  deleteUser: (value: UserModel) => void;
  userSearch: string;
}

interface InvitesList {
  items: UserModel[];
  total: number;
}
const ActionIcons: React.FC<{ item: UserModel; deleteUser: (item: UserModel) => void }> = ({ item, deleteUser }) => {
  const history = useHistory();

  return (
    <div style={{ display: 'flex', gap: 16 }}>
      <span
        style={{ cursor: 'pointer' }}
        className="material-icons"
        onClick={() => history.push(`/oo/recruiter/permissions/${item.id}`)}
      >
        edit
      </span>
      <span style={{ cursor: 'pointer' }} className="material-icons" onClick={() => deleteUser(item)}>
        delete
      </span>
    </div>
  );
};
const UserPermissionsTable: React.FC<UserPermissionsTableProps> = ({
  invitesList,
  order,
  orderBy,
  pagination,
  setOrder,
  setOrderBy,
  deleteUser,
  userSearch,
}) => {
  const { t } = useTranslation(['recruiter']);

  const columns = [
    { field: 'email', headerName: t('GENERAL.IMPORT.headerEmail'), sorting: false },
    { field: 'role', headerName: t('GENERAL.PERMISSIONS.headerRole'), sorting: false },
    { field: 'accessStatus', headerName: t('GENERAL.PERMISSIONS.headerAccessStatus'), sorting: false },
    { field: 'branches', headerName: t('GENERAL.IMPORT.headerBranch'), sorting: false },
    { field: 'regions', headerName: t('GENERAL.CANDIDATES_OVERVIEW.filterRegion'), sorting: false },
    { field: 'clients', headerName: t('GENERAL.IMPORT.headerClient'), sorting: false },
    { field: 'journeys', headerName: t('GENERAL.IMPORT.headerJourney'), sorting: false },
    { field: 'actions', headerName: '', sorting: false },
  ];

  const prepareList = (): InvitesList => {
    const result: InvitesList = { items: [], total: 0 };
    if (!invitesList) {
      return result;
    }
    let items = [...invitesList];

    items = userSearch ? items.filter((x) => x.email?.match(new RegExp(userSearch, 'i'))) : items;
    result.total = items.length || 0;

    result.items = items.slice(
      pagination.page * pagination.rowsPerPage,
      pagination.rowsPerPage * (pagination.page + 1),
    );

    return result;
  };

  const getTag = (item: UserModel) => {
    const status = item.lastLoginAt
      ? item?.accessStatus
      : item?.accessStatus !== AcccessStatus.deactivated
      ? 'pending'
      : item.accessStatus;
    if (status === 'pending') {
      return <div className="tag-ds status-tag_info ">{t(`GENERAL.PERMISSIONS.pending`).toUpperCase()}</div>;
    } else if (status === 'active') {
      return <div className="tag-ds status-tag_success ">{t(`GENERAL.PERMISSIONS.active`).toUpperCase()}</div>;
    } else if (status === 'deactivated') {
      return <div className="tag-ds status-tag error ">{t(`GENERAL.PERMISSIONS.deactivated`).toUpperCase()}</div>;
    }
  };

  const renderList = prepareList();

  return (
    <div className="tag-ds App userPermission">
      <Table>
        <THead>
          <TRow>
            {columns.map(({ field, headerName, sorting }) => (
              <TElement key={field} style={{ postion: 'relative' }} className="feature-admin-permission__table-field">
                {sorting && (
                  <SortingButton
                    onClick={() => {
                      setOrder(orderBy === field && order === 'asc' ? 'desc' : 'asc');
                      setOrderBy(field);
                    }}
                    direction={orderBy === field ? order : 'asc'}
                    active={orderBy === field}
                  />
                )}
                {headerName ?? ''}
              </TElement>
            ))}
          </TRow>
        </THead>
        <TBody>
          {renderList?.total > 0 &&
            stableSort(renderList.items, getComparator(order, orderBy)).map((item: UserModel, index) => (
              <TRow key={index}>
                <TElement>{item.email}</TElement>
                <TElement>{t(`GENERAL.PERMISSIONS.${item.role}`)}</TElement>
                <TElement>{getTag(item)}</TElement>
                <TElement>{item.userPermission?.branchIds?.length || 0}</TElement>
                <TElement>{item.userPermission?.regionIds?.length || 0}</TElement>
                <TElement>{item.userPermission?.clientOrganizationIds?.length || 0}</TElement>
                <TElement>{item.userPermission?.selectedConfigurationIds?.length || 0}</TElement>
                <TElement>
                  <ActionIcons item={item} deleteUser={deleteUser} />
                </TElement>
              </TRow>
            ))}
        </TBody>
      </Table>
      {renderList.total > 0 && (
        <AdminPagination
          {...pagination}
          total={renderList.total}
          currentPage={pagination.page}
          isRowsPerPageVisible={false}
        />
      )}
    </div>
  );
};

export default UserPermissionsTable;
