import React from 'react';

export const ArrowBackwardIcon: React.FC = () => {
  return (
    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5658 0.434215C12.1447 1.01317 12.1447 1.95184 11.5658 2.53079L3.71907 10.3775L11.5658 18.2242C12.1447 18.8032 12.1447 19.7419 11.5658 20.3208C10.9868 20.8998 10.0482 20.8998 9.46921 20.3208L0.574214 11.4258C-0.00473833 10.8469 -0.00473829 9.90818 0.574215 9.32923L9.46921 0.434215C10.0482 -0.144738 10.9868 -0.144738 11.5658 0.434215Z"
        fill="#273446"
      />
    </svg>
  );
};
