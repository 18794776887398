import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Header } from '@components/app/Header/Header';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { useTenant } from '@hooks/useComponentMapping';
import { GetApp, PictureAsPdf } from '@mui/icons-material';
import { Box, Button, Link, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../../libs/aamBackendApi';

interface SignDocumentOverviewProps {
  selectedDoc: any;
  goBack: () => void;
  selectedDocName?: string;
}

export const SignDocumentPreview: React.FC<SignDocumentOverviewProps> = ({ selectedDoc, goBack, selectedDocName }) => {
  const { t } = useTranslation(['approval', 'hiring', 'documents', 'candidate_recruiter']);
  const [documentPdf, setDocumentPdf] = useState('');
  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useTenant();
  const { userId } = useSelector((state: RootState) => state.authApp);
  const [isLoading, setIsLoading] = useState(true);
  const prefix = selectedDocName?.split('.')[0].toLowerCase();
  const isDocx = selectedDoc?.documentExtensionType === 'docx';

  const downloadDocument = useCallback(
    async (item: any) => {
      try {
        setIsLoading(true);
        const userDocuments = [...(item.userDocuments || [])].sort(
          (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
        let documentPdf;
        if (userId && userDocuments && userDocuments.length > 0) {
          documentPdf = await aamBackendApi.downloadDocument(
            tenantId,
            userId,
            userDocuments[userDocuments.length - 1].id,
          );
        } else {
          documentPdf = await aamBackendApi.downloadOODocumentForSignature(item.name);
        }
        const docxType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const pdf = URL.createObjectURL(
          new Blob([documentPdf], {
            type: isDocx ? docxType : 'application/pdf',
          }),
        );
        setDocumentPdf(pdf);
      } finally {
        setIsLoading(false);
      }
    },
    [aamBackendApi, userId, tenantId, isDocx],
  );

  useEffect(() => {
    if (selectedDoc) {
      downloadDocument(selectedDoc);
    }
  }, [selectedDoc, downloadDocument]);

  return (
    <Box style={{ height: '100%' }}>
      <Header
        onBackPress={() => goBack()}
        title={selectedDocName ? t(`${prefix}:${selectedDocName}`) : t(`${prefix}:${selectedDoc.name}`)}
      />
      <Box m={2} style={{ height: 'calc(100% - 154px)' }}>
        <Box my={3}>
          <Typography variant="body1">{t(`${prefix}:${selectedDocName}`)}</Typography>
        </Box>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              style={{
                height: 'calc(100% - 29px)',
              }}
            >
              <Box style={{ height: '100%' }} mb={1}>
                {documentPdf && !isDocx && (
                  <object data={documentPdf} type="application/pdf" width="100%" style={{ height: '100%' }}>
                    <Link href={documentPdf} target="_blank">
                      <Box className="document-form__image-preview">
                        <Box display="flex" alignItems="center">
                          <Box mr={1} display="flex" alignItems="center" className="document-form__icon">
                            <PictureAsPdf color="primary" />
                          </Box>
                          <Typography variant="body1">{t(`${prefix}:${selectedDocName}`)}</Typography>
                        </Box>
                      </Box>
                    </Link>
                  </object>
                )}
              </Box>
              <Box>
                <Link href={documentPdf} target="_blank" download>
                  <Button variant="outlined" color="primary">
                    <Box component="span" mr={1}>
                      {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.download')}
                    </Box>
                    <GetApp fontSize="small" />
                  </Button>
                </Link>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
