import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import OOTableContainer from '@components/admin/Table/OOTableContainer';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { useGetUserFlow, useGetUserMailHistory, useGetUserProfile } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { useAllSteps } from '../../../oneOnboarding/hooks/useSteps';
import { OOStepModel } from '../../../oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '../../../oneOnboarding/wrappers/FlowWrapper';
import CustomMail from './components/CustomMail';
import MailDetails from './components/MailDetails';
import MailList from './components/MailList';
import { MailNotifications, MailNotificationsType } from './components/MailNotifications';
import MailTable from './components/MailTable';
import SendEmailButton from './components/SendEmailButton';

const CandidateMailOverview = () => {
  const { t } = useTranslation(['recruiter']);
  const [dateFormat, setDateFormat] = useState('');
  const [selectedMailType, setSelectedMailType] = useState<{
    mailTemplateId: null | string;
    id: null | string;
  }>({
    mailTemplateId: null,
    id: null,
  });
  const [activeTab, setActiveTab] = useState('Automatic');
  const [detailsModal, setDetailsModal] = useState(false);
  const [mailDetails, setMailDetails] = useState<any>('');
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const aamBackendApi = useInjection(AamBackendApi);
  const [showCustomEmail, setShowCustomEmail] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { mails } = useSelector((state: RootState) => state.oneOnboarding);
  const [notificationType, setNotificationType] = useState<MailNotificationsType>(MailNotificationsType.NONE);
  const [error, setError] = useState('');

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const { data: userFlow } = useGetUserFlow(clientOrganizationId, configurationId, userId, applicationId);
  const { data: userProfile } = useGetUserProfile(tenantId, userId);
  const { data: mailHistory, refetch } = useGetUserMailHistory(userId, clientOrganizationId, configurationId);
  const flowWrapper = useMemo(
    () => (userFlow ? OOFlowWrapper.create(userFlow?.selectedConfiguration?.flow) : undefined),
    [userFlow],
  );
  const allSteps: OOStepModel[] = useAllSteps(flowWrapper, userFlow?.currentStep);

  useEffect(() => {
    if (clientOrganizationId && configurationId) {
      aamBackendApi.fetchSelectedMail(clientOrganizationId, configurationId).then((data) => {
        dispatch(oneOnboardingSlice.actions.setMails(data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clientOrganizationId && configurationId) {
      const fetchDisplayDateFormat = async () => {
        const format = (await aamBackendApi.getOtherConfiguration(clientOrganizationId, configurationId))?.find(
          (d) => d.name === 'displayDateFormat',
        )?.value;

        format ? setDateFormat(format) : setDateFormat('dd/MM/yyyy');
      };
      fetchDisplayDateFormat();
    }
  }, [clientOrganizationId, configurationId, aamBackendApi]);

  const resend = async (mailTemplateId: string, mailId: string, id: string) => {
    await aamBackendApi.resend(clientOrganizationId, configurationId, mailTemplateId, mailId, id, applicationId);
    refetch();
  };

  const handleDetails = (id: string, visibility: 'show' | 'hide') => {
    if (visibility === 'show') {
      if (mailHistory) {
        const details = mailHistory.find((m: any) => m.id === id);
        setMailDetails(details);
        setDetailsModal(true);
      }
    } else {
      setDetailsModal(false);
      setTimeout(() => {
        setMailDetails('');
      }, 300);
    }
  };
  const checkHireSignatureStep = () => {
    const hireStep = allSteps.find((step) => step.name === 'HIRE_SIGNATURE');
    return hireStep?.status === 'IN_PROGRESS' || hireStep?.status === 'COMPLETED';
  };
  const handleSelectedMail = async () => {
    const mailTemplateId = selectedMailType.mailTemplateId;

    const dataMailId = selectedMailType.id;
    if (mailTemplateId === '--') return;

    if (!dataMailId) return;

    const mail = mails.find((m) => m.mailTemplateId === mailTemplateId && m.id === dataMailId);
    if (clientOrganizationId && configurationId && mail && userId && mailTemplateId) {
      if (
        window.confirm(
          t('recruiter:GENERAL.MAILS.confirmSendEmail', {
            mailTemplateId: t(
              `recruiter:GENERAL.MAILS.mailTemplateIds.${_.startCase(mailTemplateId).replace(/\s/g, '')}`,
            ),
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
          }),
        )
      ) {
        if (selectedMailType.mailTemplateId === 'Candidate hired' && !checkHireSignatureStep()) {
          setNotificationType(MailNotificationsType.ERROR);
          setError(t('recruiter:GENERAL.MAILS.NotHired'));
        } else {
          sendSelectedMail(mailTemplateId, dataMailId);
        }
      }
    }
  };

  const sendSelectedMail = async (mailTemaplateId: string, mailId: string, mailSubject?: string, mailBody?: string) => {
    if (mailTemaplateId === '--' || mailId === '--') return;
    const mail = mails.find((m) => m.mailTemplateId === mailTemaplateId && m.id === mailId);
    if (clientOrganizationId && configurationId && mail && userId) {
      try {
        const res = await aamBackendApi.sendManualMail(clientOrganizationId, configurationId, userId, applicationId, {
          mailTemplateId: mail.mailTemplateId,
          id: mail.id,
          mailSubject,
          mailBody,
        });

        let message: string;

        if (res) {
          message = t('recruiter:GENERAL.MAILS.emailSentSuccess', {
            mailTemplateId: t(
              `recruiter:GENERAL.MAILS.mailTemplateIds.${_.startCase(mail.mailTemplateId).replace(/\s/g, '')}`,
            ),
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
          });
        } else {
          message = t('recruiter:GENERAL.MAILS.emailSentError', {
            mailTemplateId: t(
              `recruiter:GENERAL.MAILS.mailTemplateIds.${_.startCase(mail.mailTemplateId).replace(/\s/g, '')}`,
            ),
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
          });
        }
        refetch();

        alert(message);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  };

  const sendCustomMail = async (subject: string, body: string) => {
    const customMail = mails.find((x) => x.mailTemplateId === 'Custom');

    if (clientOrganizationId && configurationId && customMail && userId) {
      try {
        const res = await aamBackendApi.sendManualMail(clientOrganizationId, configurationId, userId, applicationId, {
          mailTemplateId: customMail.mailTemplateId,
          id: customMail.id,
          mailSubject: subject,
          mailBody: body,
        });

        let message: string;

        if (res) {
          message = t('recruiter:GENERAL.MAILS.customEmailSentSuccess', {
            mailTemplateId: customMail.mailTemplateId,
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
          });
        } else {
          message = t('recruiter:GENERAL.MAILS.customEemailSentError', {
            mailTemplateId: customMail.mailTemplateId,
            firstName: userProfile?.firstName,
            lastName: userProfile?.lastName,
          });
        }
        refetch();

        alert(message);
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  };

  const renderScreen: any = {
    Automatic: (
      <MailTable
        dateFormat={dateFormat}
        mailHistory={mailHistory || []}
        openDetails={(id) => handleDetails(id, 'show')}
        resend={resend}
      />
    ),
    Manual: showCustomEmail ? (
      <CustomMail sendCustomMail={sendCustomMail} />
    ) : (
      <MailTable
        dateFormat={dateFormat}
        mailHistory={mailHistory || []}
        openDetails={(id) => handleDetails(id, 'show')}
        resend={resend}
      />
    ),
  };
  return (
    <div className="admin">
      {error && <MailNotifications type={notificationType} onClose={() => setError('')} error={error} />}
      <PageWrapper
        sideMenu
        featureName="mail-overview"
        headerConfig={{
          pageName: t('recruiter:GENERAL.MAILS.MailOverview'),
          onBack: () => window.history.back(),
          languagePicker: true,
        }}
        pageName="mail-overview"
      >
        <Grid container className="feature-mail-overview__container">
          <Grid item xs={3} className="feature-mail-overview__left-side">
            <MailList
              selectedMailType={selectedMailType}
              setSelectedMailType={setSelectedMailType}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setShowCustomEmail={setShowCustomEmail}
            />
            <SendEmailButton handleSendEmail={handleSelectedMail} />
          </Grid>
          <Grid item flex={1} style={{ overflow: 'scroll' }} className="tag-ds">
            <OOTableContainer>{renderScreen[activeTab]}</OOTableContainer>
          </Grid>
        </Grid>
      </PageWrapper>
      <MailDetails isActive={detailsModal} mailDetails={mailDetails} closeModal={() => handleDetails('', 'hide')} />
    </div>
  );
};

export default CandidateMailOverview;
