import React, { FC } from 'react';
import { useStore } from 'react-redux';
import { useFlowWrapper } from '@features/oneOnboarding/hooks/useFlowWrapper';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OODocumentationModel } from '@features/oneOnboarding/models/DocumentationModel';
import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';
import { RootState } from '@store/rootReducer';

import { DocumentItem } from '../DocumentItem/DocumentItem';

interface DocumentListProps {
  items: OODocumentationModel[];
  hasValidationStep: boolean;
}
const DocumentList: FC<DocumentListProps> = ({ items, hasValidationStep }) => {
  const { t } = useT('recruiter');
  const store = useStore<RootState>();
  const fw = useFlowWrapper();

  const mandatoryFilter = (documentation: OODocumentationModel) => (document: OODocumentModel) => {
    const hasNotUploadedOneMandatory = documentation.documents
      .filter((doc) => doc.isMandatory)
      .every((doc) => doc.isMandatory && !doc?.userDocument);

    return hasNotUploadedOneMandatory
      ? document.isMandatory || !document.isMandatory
      : (document.isMandatory && document?.userDocument) || !document.isMandatory;
  };

  const disableDocumentForDE = (document: OODocumentModel) => {
    if (!fw) {
      return document;
    }

    const currentStep = store.getState().oneOnboarding.currentStep;
    const currentIndex = fw?.steps.findIndex((s) => s.name === currentStep);
    const docUpload2Index = fw?.steps.findIndex((s) => s.name === OOStepsEnum.documentUploadTwo);
    const prefix = document.label.split('.')[1];

    if (prefix === OOStepsEnum.documentUpload && docUpload2Index !== -1 && currentIndex >= docUpload2Index) {
      document.isDisable = true;
    }
    return document;
  };

  return (
    <div>
      {items.map((documentation: OODocumentationModel, index: number) => (
        <div key={index} className="feature-candidate-profile__documents-item-group">
          <span className="tag-ds  small-title" style={{ marginBottom: 8, display: 'inline-block' }}>
            {t(`documents:${documentation.label}`)} {documentation.isMandatory ? '*' : ''}
          </span>
          {documentation.documents
            .filter(mandatoryFilter(documentation))
            .map((document: OODocumentModel) => disableDocumentForDE(document))
            .map((document: OODocumentModel, index: number) => (
              <DocumentItem
                document={document}
                documentation={documentation}
                hasValidationStep={hasValidationStep}
                key={index}
              />
            ))}
        </div>
      ))}
    </div>
  );
};

export default DocumentList;
