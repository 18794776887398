import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import { RootState } from '@store/rootReducer';
import {
  findDocumentInConfiguration,
  findPageInConfiguration,
  selfServiceSlice,
} from '@store/slices/selfService.slice';

interface DraggableComponentProps {
  children: React.ReactNode;
  control: any;
  componentType?: 'pageControl' | 'documentControl' | 'documentsControl';
  fallback?: (value: any) => void;
  onDrop?: (e: any, targetField: string) => void;
}

export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  const newArr = [...arr];
  while (old_index < 0) {
    old_index += newArr.length;
  }
  while (new_index < 0) {
    new_index += newArr.length;
  }
  if (new_index >= newArr.length) {
    let k = new_index - newArr.length + 1;
    while (k--) {
      newArr.push(undefined);
    }
  }

  newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);

  return newArr;
};

export const DraggableComponent: React.FC<DraggableComponentProps> = ({
  children,
  control,
  componentType,
  fallback,
  onDrop,
}) => {
  const [targetField, setTargetField] = useState<string>('');

  const dispatch = useDispatch();
  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const currentPage = findPageInConfiguration(
    {
      featureName: selectedFeature?.featureName,
      stepName: selectedFeature?.stepName,
      pageName: selectedFeature?.pageName,
    },
    selectedConfiguration,
  );

  const currentDocumentToUpload = findDocumentInConfiguration(
    {
      featureName: selectedFeature?.featureName,
      stepName: selectedFeature?.stepName,
    },
    selectedConfiguration,
  );

  const onDragOver = (e: any) => {
    const fieldId = e.currentTarget.getAttribute('data-name');

    if (fieldId && fieldId !== targetField) {
      setTargetField(fieldId);
    }
    e.preventDefault();
  };

  const onDragStart = (e: any, name: string) => {
    e.dataTransfer.setData('name', name);
  };

  const onDropInternal = (e: any) => {
    if (targetField === null) return;

    if (onDrop) {
      onDrop(e, targetField);
      return;
    }

    const id = e.dataTransfer.getData('name');

    const handlePageControl = () => {
      const currentPageArray = currentPage?.controls;
      const dragIndex = currentPageArray?.findIndex((x: any) => x.name === id) || 0;
      const targetIndex = currentPageArray?.findIndex((x: any) => x.name === targetField) || 0;

      const newArr = arrayMove(currentPageArray || [], dragIndex, targetIndex);

      const withNewSequence = newArr.map((x, i) => {
        const newSequence = i * 10;
        return { ...x, sequence: newSequence };
      });

      dispatch(
        selfServiceSlice.actions.handlePageModify({
          featureName: selectedFeature?.featureName || '',
          stepName: selectedFeature?.stepName || '',
          pageName: selectedFeature?.pageName || '',
          controls: withNewSequence,
          modification: {
            type: 'change',
            typeOfChange: 'sequence',
            property: currentPageArray?.find((x: any) => x.name === id),
          },
        }),
      );
    };

    const handleDocumentControl = () => {
      if (!selectedFeature) return;

      const currentDocumentArray = currentDocumentToUpload?.documentations;
      const dragIndex = currentDocumentArray?.findIndex((x: { name: string }) => x.name === id) || 0;
      const targetIndex = currentDocumentArray?.findIndex((x: { name: string }) => x.name === targetField) || 0;
      const newArr = arrayMove(currentDocumentArray || [], dragIndex, targetIndex);
      const withNewSequence = newArr.map((x, i) => {
        const newSequence = i * 10;
        return { ...x, sequence: newSequence };
      });

      dispatch(
        selfServiceSlice.actions.handleDocumentToUploadFeature({
          featureName: selectedFeature?.featureName,
          stepName: selectedFeature?.stepName,
          documentations: withNewSequence,
          modification: {
            type: 'change',
            typeOfChange: 'sequence',
            property: currentDocumentArray?.find((x: { name: string }) => x.name === id),
          },
        }),
      );
    };

    const handleDocumentsControl = () => {
      if (!selectedFeature) return;

      const currentDocumentsArray = currentDocumentToUpload?.documentations;
      const finding: any = currentDocumentsArray?.find((item: any) => {
        return item.documents.some((x: any) => x.name === id);
      });
      if (!finding) return;
      const dragIndex = finding.documents?.findIndex((x: any) => x.name === id) || 0;
      const targetIndex = finding.documents?.findIndex((x: any) => x.name === targetField) || 0;
      const newArr = arrayMove(finding.documents || [], dragIndex, targetIndex);
      const withNewSequence = newArr.map((x, i) => {
        const newSequence = i * 10;
        return { ...x, sequence: newSequence };
      });
      const result = currentDocumentsArray?.map((x: any) => {
        if (x.name === finding.name) {
          return { ...x, documents: withNewSequence };
        }
        return x;
      });
      fallback?.(withNewSequence);

      dispatch(
        selfServiceSlice.actions.handleDocumentToUploadFeature({
          featureName: selectedFeature?.featureName,
          stepName: selectedFeature?.stepName,
          documentations: result,
          modification: {
            type: 'change',
            typeOfChange: 'sequence',
            property: currentDocumentsArray?.find((item: any) => {
              return item.documents.some((x: any) => x.name === id);
            }),
          },
        }),
      );
    };

    if (componentType === 'pageControl') {
      handlePageControl();
    }

    if (componentType === 'documentControl') {
      handleDocumentControl();
    }

    if (componentType === 'documentsControl') {
      handleDocumentsControl();
    }
  };

  return (
    <div
      draggable={isViewOnly ? false : true}
      onDrop={(ev: React.DragEvent<HTMLDivElement>) => onDropInternal(ev)}
      onDragOver={(ev: React.DragEvent<HTMLDivElement>) => onDragOver(ev)}
      onDragStart={(e) => onDragStart(e, control.name)}
      data-name={control.name}
    >
      {children}
    </div>
  );
};

export default DraggableComponent;
