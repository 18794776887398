import React, { FC } from 'react';

import styles from './AdminImport.module.scss';
interface AdminSimpleButtonProps {
  onClick: any;
  label: string;
  className?: string;
  disabled?: boolean;
  outlined?: boolean;
  style?: any;
}

export const AdminSimpleButton: FC<AdminSimpleButtonProps> = ({
  onClick,
  label,
  className,
  disabled,
  outlined,
  ...rest
}) => (
  <div
    className={
      styles.simpleButton +
      (disabled ? ' ' + styles.simpleButtonDisabled : '') +
      (outlined ? ' ' + styles.simpleButtonOutlined : '') +
      (className ? ' ' + className : '')
    }
    onClick={!disabled ? onClick : null}
    {...rest}
  >
    <label className={styles.simpleButtonLabel}>{label}</label>
  </div>
);
