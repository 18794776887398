import React, { useState } from 'react';
import { format } from 'date-fns';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Table, TBody, TElement, THead, Toggle, TRow } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { CampaignModel } from '@features/oneOnboarding/models/CampaignModel';
import { mutateActivateCampaign } from '@hooks/apiHooks';
import { Menu, MenuItem } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../libs/aamBackendApi';
import { SortingButton } from '../../../components/AdminDashboardTable/SortingButton';
import AdminPagination from '../../../components/AdminPagination';
import ActivateDeactivateCampaignModal from './ActivateDeactvateCampaignModal';
interface CampaignsList {
  items: CampaignModel[];
  total: number;
}

enum MenuItemAction {
  DOWNLOAD = 'DOWNLOAD',
  COPY = 'COPY',
}

const CampaignsTable: React.FC = () => {
  const history = useHistory();

  const [anchor, setAnchor] = useState(null);

  const [selected, setSelected] = useState('');

  const aamBackendApi = useInjection(AamBackendApi);

  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<string>('desc');
  const [orderBy, setOrderBy] = useState<string>('startDate');

  const [campaignItems, setCampaignItems] = useState<CampaignModel[]>([]);

  const { tenantId } = useSelector((state: RootState) => state.tenant);

  const { t } = useTranslation(['recruiter']);

  const formatTableDate = (date: Date) => format(new Date(date), 'dd-MM-yyyy');

  const [isActiveteCampaignModalActive, setIsActiveteCampaignModalActive] = useState<boolean>(false);

  const [campaignChange, setCampaignChange] = useState<CampaignModel>();

  const [isDeactivateCampaignModalActive, setIsDeactivateCampaignModalActive] = useState<boolean>(false);

  const columns = [
    { field: 'campaignName', headerName: t('GENERAL.IMPORT.campaignName'), sorting: false },
    { field: 'campaignStatus', headerName: t('GENERAL.IMPORT.campaignStatus'), sorting: false },
    { field: 'startDate', headerName: t('GENERAL.IMPORT.startDate'), sorting: false },
    { field: 'endDate', headerName: t('GENERAL.IMPORT.endDate'), sorting: false },
    { field: 'numberOfCandidates', headerName: t('GENERAL.IMPORT.numberOfCandidates'), sorting: false },
    { field: 'edit', headerName: '', sorting: false },
    { field: 'share', headerName: '', sorting: false },
  ];

  const { refetch: refetchCampaigns } = useQuery({
    queryKey: ['campaigns'],
    queryFn: () => aamBackendApi.fetchCampaigns(),
    refetchOnMount: true,
    onSuccess: (res) => {
      setCampaignItems(res);
    },
  });

  const prepareList = (): CampaignsList => {
    const result: CampaignsList = { items: [], total: 0 };
    if (!campaignItems) {
      return result;
    }
    result.total = campaignItems.length || 0;
    result.items = campaignItems.slice(page * rowsPerPage, rowsPerPage * (page + 1));

    return result;
  };

  const renderList = prepareList();

  const { mutate: activateCampaign, isLoading: activatingCampaign } = mutateActivateCampaign(
    tenantId,
    campaignChange,
    () => {
      setIsActiveteCampaignModalActive(false);
      setIsDeactivateCampaignModalActive(false);
      refetchCampaigns();
    },
  );

  const updateCampaign = (campaign: CampaignModel) => {
    campaign.startDate = new Date();
    campaign.enabled = !campaign.enabled;
    if (campaign.enabled) {
      if (!campaign.endDate) {
        campaign.endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
      }
      if (new Date() < campaign.startDate) {
        campaign.startDate = new Date();
      }
      setCampaignChange(campaign);
      setIsActiveteCampaignModalActive(true);
    } else {
      setCampaignChange(campaign);
      setIsDeactivateCampaignModalActive(true);
    }
  };

  const cancelButtonModal = () => {
    if (campaignChange) {
      campaignChange.enabled = !campaignChange.enabled;
    }
  };

  const openMenu = (event: any, item: string) => {
    setSelected(item);
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const campaignLinkBase = `${window.location.origin}/oo/campaign?campaignUrl=`;
  const onMenuItemClick = (item: string, toDo: MenuItemAction) => {
    setAnchor(null);

    const campaignLink = `${campaignLinkBase}${item}`;
    if (toDo === MenuItemAction.COPY) {
      navigator.clipboard.writeText(campaignLink);
    }
    if (toDo === MenuItemAction.DOWNLOAD) {
      const canvas = document.getElementById('qrcode') as HTMLCanvasElement;
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'qrcode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <div className="tag-ds App campaign">
      <Table>
        <THead>
          <TRow>
            {columns.map(({ field, headerName, sorting }) => {
              return (
                <TElement key={field} style={{ postion: 'relative' }}>
                  {sorting && (
                    <SortingButton
                      onClick={() => {
                        setOrder(orderBy === field && order === 'asc' ? 'desc' : 'asc');
                        setOrderBy(field);
                      }}
                      direction={orderBy === field ? order : 'desc'}
                      active={orderBy === field}
                    />
                  )}
                  {headerName ?? ''}
                </TElement>
              );
            })}
          </TRow>
        </THead>
        <TBody>
          {renderList?.total > 0 &&
            renderList.items.map((item: CampaignModel, index) => {
              return (
                <TRow key={index}>
                  <TElement>{item.name}</TElement>
                  <TElement>
                    <div className="tag-ds " style={{ display: 'flex', alignItems: 'center' }}>
                      <Toggle
                        id={item.name}
                        onChange={(checked: boolean) => {
                          updateCampaign(item);
                        }}
                        checked={item.enabled}
                      />
                      <p className="medium" style={{ color: '#5C6573', marginLeft: 8 }}>
                        {item.enabled ? t('GENERAL.IMPORT.on') : t('GENERAL.IMPORT.off')}
                      </p>
                    </div>
                  </TElement>
                  <TElement>{formatTableDate(item.startDate)}</TElement>
                  <TElement>{item.endDate ? formatTableDate(item.endDate) : ''}</TElement>
                  <TElement>{item.onboardings ? item.onboardings.length : 0}</TElement>
                  <TElement>
                    <button
                      onClick={(e: any) => {
                        history.push(`/oo/recruiter/import-users/campaigns/${item.url}`);
                      }}
                      className="button-secondary_small"
                    >
                      <span className="material-icons">notes</span>
                    </button>
                  </TElement>
                  <TElement>
                    <div className="tag-ds ">
                      <button
                        className="button-secondary_small"
                        id={item.url}
                        onClick={(event) => {
                          openMenu(event, item.url);
                        }}
                      >
                        <span className="material-icons">share</span>
                      </button>
                      <Menu
                        open={Boolean(anchor)}
                        anchorEl={anchor}
                        onClose={closeMenu}
                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                      >
                        <MenuItem
                          selected={item.url === selected}
                          onClick={(event) => {
                            event.stopPropagation();
                            onMenuItemClick(selected, MenuItemAction.DOWNLOAD);
                          }}
                        >
                          <span className="material-icons">download</span>
                          {t('GENERAL.IMPORT.downloadPng')}
                        </MenuItem>
                        <MenuItem
                          selected={item.url === selected}
                          onClick={(event) => {
                            event.stopPropagation();
                            onMenuItemClick(selected, MenuItemAction.COPY);
                          }}
                        >
                          <span className="material-icons">link</span>
                          {t('GENERAL.IMPORT.copyUrl')}
                        </MenuItem>
                      </Menu>
                    </div>
                  </TElement>
                </TRow>
              );
            })}
        </TBody>
      </Table>
      {renderList.total > 0 && (
        <AdminPagination
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          total={renderList.total}
          currentPage={page}
          isRowsPerPageVisible={true}
        />
      )}
      <ActivateDeactivateCampaignModal
        activating={activatingCampaign}
        activateCampaign={() => activateCampaign()}
        buttonText={t('GENERAL.IMPORT.reactivateCampaign')}
        bodyText={t(`GENERAL.IMPORT.reactivateCampaignMessage`)}
        headerText={t('recruiter:GENERAL.IMPORT.reactivateCampaign')}
        cancelButtonText={t(`GENERAL.IMPORT.dontChange`)}
        cancelButtonModal={() => cancelButtonModal()}
        isActiveModal={isActiveteCampaignModalActive}
        setIsActiveModal={(value) => setIsActiveteCampaignModalActive(value)}
      />
      <ActivateDeactivateCampaignModal
        activating={activatingCampaign}
        activateCampaign={() => activateCampaign()}
        buttonText={t('GENERAL.IMPORT.deactivateCampaign')}
        bodyText={t(`GENERAL.IMPORT.deactivateCampaignMessage`)}
        headerText={t('recruiter:GENERAL.IMPORT.changeCampaignStatus')}
        cancelButtonText={t(`GENERAL.IMPORT.dontChange`)}
        cancelButtonModal={() => cancelButtonModal()}
        isActiveModal={isDeactivateCampaignModalActive}
        setIsActiveModal={(value) => setIsDeactivateCampaignModalActive(value)}
      />
      <div style={{ visibility: 'hidden' }}>
        <QRCode id="qrcode" value={`${campaignLinkBase}${selected}`} renderAs="canvas" />
      </div>
    </div>
  );
};

export default CampaignsTable;
