import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DatePicker, Loader, Option, Select } from '@adeccoux/tag-ds';
import { DatePickerMode } from '@adeccoux/tag-ds/datepicker';
import { useAllConfigurationsForTenant, useTelemetryStat } from '@hooks/apiHooks';
import { descriptionMap } from '@models/telemetry.model';
import { RootState } from '@store/rootReducer';

const TelemetryChart = () => {
  const currentDate = new Date();
  const dateSeventDays = new Date(currentDate.setDate(currentDate.getDate() - 7)).getTime();
  const startDefault = new Date().getTime();
  const defaultSelectedEvent = descriptionMap;

  const [selectedDate, setSelectedDate] = useState<{ startDate: number; endDate: number }>({
    startDate: dateSeventDays,
    endDate: startDefault,
  });
  const [selectedClient, setSelectedClient] = useState<string>('');

  const { tenantId } = useSelector((state: RootState) => state.tenant);

  const { data, isLoading } = useTelemetryStat(
    defaultSelectedEvent,
    moment(selectedDate.startDate).format('YYYY-MM-DD HH:mm:ss.SSS Z'),
    moment(selectedDate.endDate).format('YYYY-MM-DD HH:mm:ss.SSS Z'),
    selectedClient || '',
  );

  const { data: clientOrganizations } = useAllConfigurationsForTenant(tenantId);

  const dataWithDescription = useMemo(() => {
    return data?.map((x: any) => {
      const findDescription = descriptionMap.find((y) => y.eventType === x.eventType && y.targetName === x.targetName);

      return { ...x, description: findDescription?.description };
    });
  }, [data]);

  const handleDateSelect = (date: number[]) => {
    const startDate = (date as number[])[0];
    const endDate = (date as number[])[1];

    if (startDate && endDate) {
      setSelectedDate({ startDate, endDate });
    }
  };

  return (
    <div>
      {isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20vh' }}>
          <Loader spinner title="Loading data" />
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="tag-ds " style={{ display: 'flex' }}>
            <DatePicker
              mode={DatePickerMode.RANGE}
              name="datepicker"
              onChange={(e) => handleDateSelect(e as number[])}
              defaultValue={[selectedDate.startDate, selectedDate.endDate]}
              requiredEnd
            />
            <div style={{ marginLeft: 16 }}>
              <Select
                name="select"
                onChange={setSelectedClient}
                placeholder="Select an client"
                required
                value={selectedClient}
              >
                {clientOrganizations?.map((x, i) => (
                  <Option key={i} label={x.name} value={x.id} />
                ))}
              </Select>
            </div>
          </div>
          <div style={{ height: '60vh' }}>
            {dataWithDescription?.length !== 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={1000} height={600} data={dataWithDescription} layout="vertical" margin={{ top: 20 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="description" type="category" width={300} />
                  <Tooltip />
                  <Legend verticalAlign="top" />
                  <Bar
                    name="Candidates amount per event"
                    dataKey="count"
                    fill="#da291c"
                    activeBar={<Rectangle fill="#da291c" stroke="#952821" />}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
            {dataWithDescription?.length === 0 && <div>No data for selected criteria</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default TelemetryChart;
