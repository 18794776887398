import React from 'react';
import { convertOffsetToValue } from '@features/selfService/helpers/controlHelper';

import BooleanComponentType from '../booleanComponentType/ BooleanComponentType';
import MaximumCharacters from '../maximumCharacters/MaximumCharacters';
import MinimumCharacters from '../minimumCharacters/MinimumCharacters';
import MinMaxValidationError from '../minMaxValidationError/MinMaxValidationError';
import RegexValidation from '../regexValidation/RegexValidation';
import TypeOfDate from '../typeOfDate/TypeOfDate';

import styles from './DocumentsControls.module.scss';

export const renderFieldsForDatepicker = (value: any, component: string, handleChange: any, disabled: boolean) => {
  if (component !== 'Datepicker') return null;

  return (
    <TypeOfDate
      customHandler={(data: { minOffset: any; maxOffset: any }) => {
        handleChange(data);
      }}
      value={convertOffsetToValue(value.minOffset, value.maxOffset)}
      name="typeOfDate"
      disabled={disabled}
    />
  );
};

export const renderFieldsForTextfield = (
  value: {
    min: number;
    max: number;
    minMaxValidationError: string;
    regexValidation: any;
    name: string;
    label: string;
  },
  component: string,
  handleTranslation: any,
  handleChange: any,
  translations: any[],
  selectedConfiguration: any,
  getPath: string,
  handleError: (fieldName: string) => { isError: boolean; errorMessage: string },
  disabled: boolean,
) => {
  if (component !== 'Textfield') return null;

  const availableLanguages = selectedConfiguration?.languages;

  return (
    <>
      <div className={styles.item__input}>
        <MinimumCharacters
          value={value.min}
          onChange={(e) => handleChange('min', e.target.value)}
          isError={false}
          onBlur={(e) => handleChange('min', e.target.value)}
          name="min"
          disabled={disabled}
        />
        {handleError('min').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {handleError('min').errorMessage}
          </p>
        )}
      </div>
      <div className={styles.item__input}>
        <MaximumCharacters
          value={value.max}
          onChange={(e) => handleChange('max', e.target.value)}
          isError={false}
          onBlur={(e) => handleChange('max', e.target.value)}
          name="max"
          disabled={disabled}
        />
        {handleError('max').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {handleError('max').errorMessage}
          </p>
        )}
      </div>
      <div className={styles.item__input}>
        <MinMaxValidationError
          value={value.minMaxValidationError}
          onChange={(e) => handleChange('minMaxValidationError', e.target.value)}
          isError={false}
          onBlur={(e) => handleChange('minMaxValidationError', e.target.value)}
          name="minMaxValidationError"
        />
        {handleError('name').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {handleError('name').errorMessage}
          </p>
        )}
      </div>
      <div className={styles.item__input}>
        {availableLanguages?.supported.map((language: string, i: number) => {
          const findTranslations = translations.find((x) => x.name === value.name);

          return (
            <div key={i} style={{ marginBottom: 16 }}>
              <label className="caption">Validation translation for {language}</label>
              <input
                type="text"
                value={findTranslations?.minMaxValidationError?.[language] ?? ''}
                onChange={(e) => {
                  const payload: any = { ...findTranslations.minMaxValidationError, [language]: e.target.value };

                  for (const lang of availableLanguages.supported) {
                    if (!(lang in payload)) {
                      payload[lang] = undefined;
                    }
                  }

                  handleTranslation(value.name, 'minMaxValidationError', payload);
                  if (!findTranslations?.minMaxValidationErrorKey) {
                    handleTranslation(
                      value.name,
                      'minMaxValidationErrorKey',
                      null,
                      `${getPath}${value.name.toLowerCase()}.minMaxValidationError`,
                    );
                  }

                  if (language === availableLanguages.default) {
                    handleChange(
                      'minMaxValidationError',
                      `${getPath}${value.name.toLowerCase()}.minMaxValidationError`,
                    );
                  }
                }}
                disabled={disabled}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.item__input}>
        <RegexValidation
          value={value.regexValidation}
          customHandler={(value: { regexValidation: string; regExpValidationError: string }) => {
            handleChange('regexValidation', value);
          }}
          setFieldValue={(name: string, value: any) => {
            return true;
          }}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export const renderFieldsForBoolean = (value: any, componentType: string, handleChange: any, disabled: boolean) => {
  if (componentType !== 'boolean') return null;

  return (
    <BooleanComponentType
      name="booleanComponentType"
      customHandler={(value: { component: string; customControlHeaders?: any }) => {
        handleChange(value);
      }}
      value={value}
      disabled={disabled}
    />
  );
};
