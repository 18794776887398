import React from 'react';
import { useTranslation } from 'react-i18next';
import { getDocumentForSignatureStatusIcon } from '@components/app/DocumentSteps/components/dictDocumentStatuses';
import { SignDocumentsStatus } from '@helpers/OnboardingSteps';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, BoxProps, useTheme } from '@mui/material';

type SignDocumentButtonProps = {
  title: string;
  status: SignDocumentsStatus | string;
  statusClassName?: string; // if this is optional
  dictionaries: string[];
  disabled: boolean;
  isMandatory: boolean;
} & BoxProps;

export const SignDocumentButton = ({
  dictionaries,
  title,
  status,
  statusClassName,
  disabled,
  isMandatory,
  ...rest
}: SignDocumentButtonProps) => {
  const { t } = useTranslation(dictionaries);
  const theme = useTheme();

  return (
    <Box
      className="document-button__main_container"
      style={{
        cursor: disabled ? 'default' : 'pointer',
        borderColor: `${theme.palette.text.disabled}`,
      }}
      {...rest}
    >
      <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
        <div className="document-button__right_container1">
          {getDocumentForSignatureStatusIcon(status)}
          <span className="tag-ds  base">
            {title} {!isMandatory && `(${t('documents:optional')})`}
          </span>
        </div>
        <div className="document-button__right_container2">
          <KeyboardArrowRight fontSize="small" style={{ color: '#5C6573' }} />
        </div>
      </Box>
      <Box px={5}>
        <span className="tag-ds  small">{t(`documents:statuses.status_${status.toLowerCase()}`)}</span>
      </Box>
    </Box>
  );
};
