import { getOneTrustApplicationId } from '@helpers/tenant.helper';

export const renderOneTrustIfNeeded = () => {
  const tenantId = localStorage.getItem('tenantId') || '';
  if (Number(tenantId) === 109) {
    // temporary solution
    const oneTrustApplicationId = getOneTrustApplicationId(parseInt(tenantId));
    const testPostfix = process.env.REACT_APP_ENV === 'p-env' ? '' : '-test';
    if (oneTrustApplicationId) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      const typ = document.createAttribute('data-domain-script');
      typ.value = `${oneTrustApplicationId}${testPostfix}`;
      script.attributes.setNamedItem(typ);
      document.body.appendChild(script);

      const scriptBody = document.createElement('script');
      scriptBody.type = 'text/javascript';
      scriptBody.text = 'function OptanonWrapper() { }';
      document.body.appendChild(scriptBody);
    }
  }
};
