import React from 'react';

export interface DownIconProps {
  color?: string;
  width?: number;
  wrapperStyle?: React.CSSProperties;
}

export const DownIcon: React.FC<DownIconProps> = ({ color = '#DA291C', width, wrapperStyle }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.62 14c-.32 0-.628-.129-.855-.357l-5.41-5.446c-.473-.476-.473-1.247 0-1.722.472-.475 1.237-.475 1.71 0l4.555 4.586 4.674-4.704c.473-.476 1.238-.476 1.71 0 .473.475.473 1.246 0 1.721l-5.528 5.565c-.227.228-.535.357-.856.357"
      />
    </svg>
  );
};
