import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useT } from '@features/oneOnboarding/hooks/useT';
import useBackendTranslations from '@hooks/useBackendTranslations';
import { Drawer } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { findDocumentInConfiguration } from '@store/slices/selfService.slice';

import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog';
import DocumentList, { DocumentPropertyType } from './documentFeature/DocumentList';
import DocumentControls from './documentFeature/renderControls/DocumentControls';
import DocumentToSignDetails from './documentsToSign/Document';
import DocumentToSignList from './documentsToSign/DocumentToSignList';
import PageControlList from './pageFeature/ControlList/PageControlList';
import PageControls from './pageFeature/renderControls/PageControls';

import styles from '../ConfigurationSetup.module.scss';

const RenderList = () => {
  const [controlDetails, setControlDetails] = useState<any>(null);
  const [documentControlDetails, setDocumentControlDetails] = useState<{
    type: DocumentPropertyType;
    controls: any;
  } | null>(null);
  const [pagePreviewActive, setPagePreviewActive] = useState(false);
  const [documentToSignDetails, setDocumentToSignDetails] = useState<any | undefined>(null);
  const [documentToSignExternalDetails, setDocumentToSignExternalDetails] = useState<any | undefined>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [submitFunction, setSubmitFunction] = useState<(() => void) | null>(null);
  const [libraryItem, setLibraryItem] = useState<{ name: string; id: string } | null>(null);

  const { selectedFeature, selectedConfiguration } = useSelector((state: RootState) => state.selfService);

  const { i18n } = useT('entry', 'documents', 'approval', 'hiring', 'candidate_recruiter');
  useBackendTranslations(i18n, false);

  useEffect(() => {
    if (selectedFeature) {
      setDocumentControlDetails(null);
      setControlDetails(null);
      setLibraryItem(null);
    }

    if (pagePreviewActive) {
      setDocumentControlDetails(null);
      setControlDetails(null);
      setLibraryItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderList = useMemo(() => {
    switch (selectedFeature?.featureName) {
      case 'ENTRY':
        return (
          <PageControlList
            setControlDetails={(ctrl) => {
              setIsDrawerVisible(true);
              setControlDetails(ctrl);
            }}
            selectedControlDetails={controlDetails}
            setPagePreviewActive={setPagePreviewActive}
            setLibraryItem={setLibraryItem}
          />
        );
      case 'DOCUMENTS':
      case 'DOCUMENTS2': {
        const currentDocument = findDocumentInConfiguration(
          { featureName: selectedFeature?.featureName, stepName: selectedFeature?.stepName },
          selectedConfiguration,
        );

        const isDocumentationPage = currentDocument?.documentations?.length > 0;

        return isDocumentationPage ? (
          <DocumentList
            setDocumentControlDetails={(ctrl) => {
              setIsDrawerVisible(true);
              setDocumentControlDetails(ctrl);
            }}
            selectedDocumentControl={documentControlDetails}
            setLibraryItem={setLibraryItem}
          />
        ) : (
          <PageControlList
            setControlDetails={(ctrl) => {
              setIsDrawerVisible(true);
              setControlDetails(ctrl);
            }}
            selectedControlDetails={controlDetails}
            setPagePreviewActive={setPagePreviewActive}
            setLibraryItem={setLibraryItem}
          />
        );
      }
      case 'APPROVAL':
        return (
          <DocumentToSignList
            setDocumentToSignDetails={(doc) => {
              setIsDrawerVisible(true);
              setDocumentToSignDetails(doc);
            }}
            documentToSignDetails={documentToSignDetails}
            setLibraryItem={setLibraryItem}
          />
        );
      case 'HIRING':
        return (
          <DocumentToSignList
            setDocumentToSignDetails={(doc) => {
              setIsDrawerVisible(true);
              setDocumentToSignExternalDetails(doc);
            }}
            documentToSignDetails={documentToSignExternalDetails}
            setLibraryItem={setLibraryItem}
          />
        );
      default:
        return <div />;
    }
  }, [
    controlDetails,
    documentControlDetails,
    documentToSignDetails,
    selectedFeature,
    selectedConfiguration,
    documentToSignExternalDetails,
  ]);

  const handleClose = (callback: () => void) => {
    setIsDrawerVisible(false);
    callback();
  };

  const submit = (submitFunction: (() => void) | undefined, elseAction: (value: any) => void) => {
    if (submitFunction) {
      setSubmitFunction(() => submitFunction);
      setIsDialogVisible(true);
    } else {
      handleClose(() => elseAction(null));
      setLibraryItem(null);
    }
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <div className={styles.renderList}>
          <div>{renderList}</div>
        </div>
        <Drawer anchor="right" open={isDrawerVisible}>
          <div className={styles.controlDetails}>
            {controlDetails && (
              <PageControls
                control={controlDetails}
                handleClose={(submitFunction) => submit(submitFunction, setControlDetails)}
                libraryItem={libraryItem}
                handleDifferentVersion={(control) => setControlDetails(control)}
              />
            )}
            {documentControlDetails && (
              <DocumentControls
                document={documentControlDetails}
                handleClose={(submitFunction) => submit(submitFunction, setDocumentControlDetails)}
                libraryItem={libraryItem}
                handleDifferentVersion={(documentation) => {
                  setDocumentControlDetails({ controls: documentation, type: documentControlDetails.type });
                }}
              />
            )}
            {documentToSignDetails && (
              <DocumentToSignDetails
                document={documentToSignDetails}
                handleClose={(submitFunction) => submit(submitFunction, setDocumentToSignDetails)}
                externalDocument={false}
                libraryItem={libraryItem}
                handleDifferentVersion={(documentation) => {
                  setDocumentToSignDetails(documentation);
                }}
              />
            )}
            {documentToSignExternalDetails && (
              <DocumentToSignDetails
                document={documentToSignExternalDetails}
                handleClose={(submitFunction) => submit(submitFunction, setDocumentToSignExternalDetails)}
                externalDocument={true}
                libraryItem={libraryItem}
                handleDifferentVersion={(documentation) => {
                  setDocumentToSignExternalDetails(documentation);
                }}
              />
            )}
          </div>
        </Drawer>
      </div>
      <ConfirmationDialog
        isVisible={isDialogVisible}
        onSave={() => {
          handleClose(() => {
            if (submitFunction) {
              submitFunction();
            }
            setIsDialogVisible(false);
            setSubmitFunction(null);
            setLibraryItem(null);
          });
        }}
        onCancel={() => {
          setSubmitFunction(null);
          setIsDialogVisible(false);
        }}
        onDiscard={() => {
          handleClose(() => {
            setDocumentToSignDetails(null);
            setDocumentControlDetails(null);
            setControlDetails(null);
            setSubmitFunction(null);
            setIsDialogVisible(false);
            setLibraryItem(null);
          });
        }}
      />
    </>
  );
};

export default RenderList;
