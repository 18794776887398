import { useSelector } from 'react-redux';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { useInjection } from '@context/inversify-context-provider';
import { useFlowWrapper } from '@features/oneOnboarding/hooks/useFlowWrapper';
import { BranchModel } from '@models/branch.model';
import { Onboarding } from '@models/onboarding.model';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

export interface Contacts {
  tenant: number;
  phoneNumber?: string;
  email?: string;
  url: { href: string; label: string };
  address?: string;
  name?: string;
}

export const useContactDetails = () => {
  const aamBackendApi = useInjection(AamBackendApi);
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { clientOrganizationId, selectedConfigurationId, applicationId } = useSelector(
    (state: RootState) => state.oneOnboarding,
  );
  const { userId } = useSelector((state: RootState) => state.authApp);
  const flowWrapper = useFlowWrapper();
  const registrationStep = flowWrapper?.getStep('REGISTRATION');
  const help = registrationStep?.elements.find((element) => element.name === 'HELP')?.additionalData?.link;

  const { data: currentOnboarding } = useQuery({
    queryKey: ['getOnboardingForUser', clientOrganizationId, selectedConfigurationId, applicationId, userId],
    queryFn: () =>
      clientOrganizationId && selectedConfigurationId && applicationId && userId
        ? aamBackendApi.getOnboarding(clientOrganizationId, selectedConfigurationId, applicationId, userId)
        : Promise.resolve<Onboarding | null>(null),
    refetchOnMount: true,
  });

  const candidateDetails = flowWrapper?.getStep('CANDIDATE_DETAILS');
  const hasHiringStep = flowWrapper?.steps?.find((x) => x.name === 'HIRE');
  const additionalDataContactUS = candidateDetails?.elements?.find((element) => element.name === 'CONTACT_US')
    ?.additionalData;
  const defaultContactUs: Contacts[] = [
    {
      tenant: 107,
      email: 'adecco.v04@adecco.fr',
      url: { href: 'https://www.adecco.fr', label: 'adecco.fr' },
    },
    {
      tenant: 184,
      email: 'AdeccoAmazon-Jobs@Adecco.co.uk',
      url: { href: 'https://www.adecco-jobs.com/', label: 'https://www.adecco-jobs.com/' },
    },
    {
      tenant: 109,
      url: { href: 'https://www.adecco.de', label: 'adecco.de' },
    },
    {
      tenant: 174,
      url: { href: 'https://www.adecco.es', label: 'adecco.es' },
    },
  ];
  const defaultContact = defaultContactUs.find((c) => c.tenant === tenantId);

  const getStreetAndNumber = (branch?: BranchModel) => {
    if (!branch?.street || !branch?.streetNumber) {
      return '';
    }

    return [branch?.street, branch?.streetNumber].join(' ');
  };

  const contactUs =
    hasHiringStep && currentOnboarding?.branch
      ? {
          tenant: tenantId,
          phoneNumber: currentOnboarding?.branch?.phone,
          email: currentOnboarding?.branch?.email,
          url: { href: currentOnboarding?.branch?.web, label: currentOnboarding?.branch?.web },
          address: [getStreetAndNumber(currentOnboarding?.branch), currentOnboarding?.branch?.city]
            .filter((a) => a)
            .join(', '),
          name: currentOnboarding?.branch?.name,
        }
      : additionalDataContactUS
      ? {
          tenant: tenantId,
          phoneNumber: additionalDataContactUS.phoneNumber,
          email: additionalDataContactUS.email,
          url: { href: additionalDataContactUS?.url?.href, label: additionalDataContactUS?.url?.label },
        }
      : currentOnboarding?.branch && defaultContact
      ? {
          tenant: tenantId,
          phoneNumber: currentOnboarding.branch.phone,
          email: currentOnboarding.branch.email,
          url: { href: currentOnboarding.branch.web, label: currentOnboarding.branch.web },
        }
      : defaultContact;

  return { help, contactUs };
};
