import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationBody, NotificationHeader } from '@adeccoux/tag-ds';

import styles from '../CandidateMailOverview.module.scss';

export enum MailNotificationsType {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  NONE = 'NONE',
}
export interface MailNotificationsProps {
  type: MailNotificationsType;
  onClose: () => void;
  error?: string;
}
export const MailNotifications: FC<MailNotificationsProps> = ({ type, onClose, error }) => {
  const { t } = useTranslation(['recruiter']);

  const renderEmailNotification = useMemo(() => {
    const emailNotification: { [key: string]: JSX.Element } = {
      [MailNotificationsType.ERROR]: (
        <Notification
          icon={<span className="material-icons">error_outline</span>}
          error
          className={styles.notification}
          onClose={onClose}
          show={true}
        >
          <NotificationHeader>{t('recruiter:GENERAL.MAILS.sendEmail')}</NotificationHeader>
          <NotificationBody>{error}</NotificationBody>
        </Notification>
      ),
      [MailNotificationsType.SUCCESS]: (
        <Notification
          icon={<span className="material-icons">error_outline</span>}
          success
          className={styles.notification}
          onClose={onClose}
          show={true}
        >
          <NotificationHeader>{t('recruiter:GENERAL.MAILS.sendEmail')}</NotificationHeader>
          <NotificationBody>{t('recruiter:GENERAL.MAILS.Succeeded')}</NotificationBody>
        </Notification>
      ),
    };

    if (!emailNotification[type]) {
      return <></>;
    }

    return emailNotification[type];
  }, [error, onClose, t, type]);

  return <div className="tag-ds">{renderEmailNotification}</div>;
};
