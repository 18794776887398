import React from 'react';
import { Tooltip } from '@mui/material';

interface Props {
  style?: React.CSSProperties;
}

const ConditionSign: React.FC<Props> = ({ style }) => {
  return (
    <Tooltip
      title="This field contains a condition."
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          style: {
            background: '#1C304B',
            fontSize: 12,
            padding: '8px 16px',
            fontWeight: 400,
            borderRadius: 8,
            margin: '0px 4px 8px 4px',
          },
        },
        arrow: {
          style: {
            color: '#1C304B',
          },
        },
      }}
    >
      <div className="material-icons-outlined" style={{ fontSize: 16, position: 'absolute', right: -24, ...style }}>
        tips_and_updates
      </div>
    </Tooltip>
  );
};

export default ConditionSign;
