import React from 'react';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import controlsStyle from '../../Controls.module.scss';
import style from '../RichTextEditor.module.scss';

interface Props {
  format: string;
  icon: string;
  disabled: boolean;
}

const isMarkActive = (editor: any, format: string) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor: any, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const MarkButton: React.FC<Props> = ({ format, icon, disabled }) => {
  const editor = useSlate();
  return (
    <span
      onMouseDown={(event: any) => {
        if (disabled) return;

        event.preventDefault();
        toggleMark(editor, format);
      }}
      className={`${style.editorOptions__item} ${isMarkActive(editor, format) ? style.editorOptions__itemActive : ''} ${
        disabled ? controlsStyle['control--disabled'] : ''
      }`}
    >
      <span className="material-icons">{icon}</span>
    </span>
  );
};

export default MarkButton;
