import { useTranslation } from 'react-i18next';

export const useT = (...translations: Array<string>) => {
  const { t, i18n, ready } = useTranslation(translations, {
    useSuspense: false,
  });

  return {
    t,
    i18n,
    translationsLoading: !ready,
  };
};
