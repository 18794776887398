import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { SelfServiceItemType } from '@models/selfService.model';
import { RootState } from '@store/rootReducer';
import { findPageInConfiguration } from '@store/slices/selfService.slice';

import { translationHelper } from '../../../../../helpers/translationHelper';
import AddItemFromLibrary from '../../other/AddItemFromLibrary/AddItemFromLibrary';
import { blankTemplate } from '../renderControls/templates';
import RegularControl from './RegularControl';

import styles from '../../../ConfigurationSetup.module.scss';

interface Props {
  setControlDetails: (control: any) => void;
  selectedControlDetails: any;
  setPagePreviewActive: (value: boolean) => void;
  setLibraryItem: ({ name, id }: { name: string; id: string }) => void;
}

const PageControlList: React.FC<Props> = ({
  setControlDetails,
  selectedControlDetails,
  setPagePreviewActive,
  setLibraryItem,
}) => {
  const { t, i18n } = useTranslation(['documents', 'entry', 'hiring', 'approval']);
  const { selectedFeature, selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const getTranslation = (key?: string) => {
    if (!key) return '';
    return translationHelper(key, i18n, t);
  };

  if (!selectedConfiguration) return <div>Missing configuration</div>;

  const currentPage = findPageInConfiguration(
    {
      featureName: selectedFeature?.featureName,
      stepName: selectedFeature?.stepName,
      pageName: selectedFeature?.pageName,
    },
    selectedConfiguration,
  );

  return (
    <div className="tag-ds">
      <h4 className={`subtitle ${styles.pageName}`}>
        {getTranslation(selectedFeature?.pageName)}
        <SelfServiceTooltip
          hoverContent="Select any control to edit it, add from the library of preset controls, or create a new control from scratch"
          position="right"
        />
      </h4>
      {currentPage.controls
        .slice()
        ?.sort((a: any, b: any) => a.sequence - b.sequence)
        .map((x: any) => {
          return (
            <RegularControl
              key={x.name}
              control={x}
              setControlDetails={setControlDetails}
              isSelected={selectedControlDetails?.name === x?.name}
            />
          );
        })}
      {selectedFeature && !isViewOnly && (
        <AddItemFromLibrary
          createNewControl={() => {
            setPagePreviewActive(false);
            setControlDetails(blankTemplate);
          }}
          addItemFromLibrary={(control, name, id) => {
            setControlDetails(control);
            setLibraryItem({ name, id });
          }}
          type={SelfServiceItemType.CONTROL}
        />
      )}
    </div>
  );
};

export default PageControlList;
