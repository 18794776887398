import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OOStepsEnum, StepStatusEnum } from '@features/oneOnboarding/interfaces';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { mutateCompleteStep } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

interface RequestConfirmationProps {
  step: OOStepModel;
}

export const RequestConfirmation: FC<RequestConfirmationProps> = ({ step }) => {
  const { t } = useTranslation(['recruiter']);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const queryClient = useQueryClient();
  const { mutate: completeStepForUser } = mutateCompleteStep(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    OOStepsEnum.requestConfirmation,
    queryClient,
  );
  const requestConfirmationAvailable = step.status === StepStatusEnum.completed;

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <div className="tag-ds ">
          <button className="" disabled={requestConfirmationAvailable} onClick={() => completeStepForUser()}>
            {t('recruiter:DEPRECATED.CANDIDATE_DETAILS.REQUEST_CONFIRMATION')}
          </button>
        </div>
      </Box>
    </div>
  );
};
