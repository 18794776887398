import React from 'react';
import { useTranslation } from 'react-i18next';
import { OOUserDocumentModel } from '@features/oneOnboarding/models/UserDocumentModel';

import styles from './ActionHistoryItem.module.css';

interface ActionHistoryItemProps {
  title: string;
  name: string;
  document?: OOUserDocumentModel;
  actionType?: string;
}

const ActionHistoryItem: React.FC<ActionHistoryItemProps> = ({ title, document, actionType, name = '' }) => {
  const { t } = useTranslation(['recruiter', 'general', 'documents']);
  const initials = name
    .split(' ')
    .filter((n) => n !== '')
    .map((n) => n[0].toUpperCase())
    .join('');

  const statusText =
    actionType !== 'HIRE'
      ? t(`recruiter:GENERAL.CANDIDATE_DETAILS.actionHistory${actionType}`, {
          name: t([
            `documents:DOCUMENTS.DOCUMENT_UPLOAD.${document?.type}.${document?.name
              .toLowerCase()
              .replaceAll('_', '-')}.label`,
            `documents:DOCUMENTS2.DOCUMENT_UPLOAD2.${document?.name.toLowerCase().replaceAll('_', '-')}.label`,
            `documents:DOCUMENTS.DOCUMENT_UPLOAD.${document?.type}.label`,
            `documents:DOCUMENTS2.DOCUMENT_UPLOAD2.${document?.type}.label`,
          ]),
        })
      : t(`recruiter:GENERAL.CANDIDATE_DETAILS.actionHistory${actionType}`);

  return (
    <div style={{ marginBottom: '0.5rem' }}>
      <div className={styles.timelineItem}>
        <div className={styles.avatar}>{initials}</div>
        <div className={styles.details}>
          <div className={styles.title}>{statusText}</div>
          <div className={styles.name}>{name}</div>
        </div>
      </div>
    </div>
  );
};

export default ActionHistoryItem;
