import { useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';

export const useFlowWrapper = (id?: string) => {
  const store = useStore();
  const flowData = store.getState().oneOnboarding.flow;
  const storedId = useSelector((state: RootState) => state.authApp.userId)!;
  const aamBackendApi = useInjection(AamBackendApi);
  const userId = id || storedId;

  useQuery({
    queryKey: ['getFlowAndUpdateRedux', userId],
    queryFn: () => aamBackendApi.fetchUserFlowWithCurrentStepInfoAndUpdateRedux(userId),
    refetchOnReconnect: false,
    retry: false,
    enabled: !!userId,
  });

  return useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);
};
