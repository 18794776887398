import { injectable } from 'inversify';

import { Utils } from './utils';

@injectable()
export class AppConfig {
  public env: string;
  public envPrefix: string;
  public clientTraceId: string;
  public frontendUrl: string;
  public backendUrl: string;
  public apimSubscriptionKey?: string;

  constructor(private utils: Utils) {
    this.env = process.env.REACT_APP_ENV!;
    this.envPrefix = process.env.REACT_APP_ENV_PREFIX!;
    this.clientTraceId = this.utils.generateClientTrace();
    this.frontendUrl = process.env.REACT_APP_FRONTEND_URL!;
    this.backendUrl = process.env.REACT_APP_BACKEND_URL!;
    this.apimSubscriptionKey = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY!;
  }
}
