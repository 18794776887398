import React from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import './Dialog.scss';

interface DialogProps extends MuiDialogProps {
  title?: string;
  content?: string;
  onConfirm?: () => any;
  confirmLabel?: string;
  cancelLabel?: string;
}

export const Dialog: React.FC<DialogProps> = ({
  title,
  content,
  children,
  onConfirm,
  onClose,
  confirmLabel,
  cancelLabel,
  ...rest
}) => {
  const { t } = useTranslation(['candidate_recruiter']);
  return (
    <MuiDialog {...rest} onClose={onClose}>
      {onClose && (
        <IconButton aria-label="close" onClick={(e) => onClose(e, 'backdropClick')} className="closer">
          <CloseIcon />
        </IconButton>
      )}
      {title && (
        <DialogTitle>
          <Typography variant="h6" component="span">
            {title}&nbsp;
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <Box>{content || children}</Box>
      </DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button variant="contained" color="primary" onClick={onConfirm}>
            {confirmLabel || t('candidate_recruiter:GENERAL.GENERIC.yes')}
          </Button>
        )}
        {onClose && (
          <Button variant="outlined" autoFocus onClick={(e) => onClose(e, 'backdropClick')}>
            {cancelLabel || t('candidate_recruiter:GENERAL.GENERIC.cancel')}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
};
