import React from 'react';
import OOTableContainer from '@components/admin/Table/OOTableContainer';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { Loader } from '@components/shared/Loader/Loader';
import { useAllConfigurations, useAllDraftConfigurationsByTenantId } from '@hooks/apiHooks';

import ConfigurationTable from './components/ConfigurationTable';

const Configurations = () => {
  const tenantId = localStorage.getItem('tenantId');

  const { data: allConfigurations, isLoading } = useAllConfigurations('x');
  const { data: allDraftConfigurations, isLoading: isLoadingDraftConfigurations } = useAllDraftConfigurationsByTenantId(
    'x',
    Number(tenantId),
  );
  return (
    <PageWrapper
      featureName="dashboard"
      pageName="dashboard"
      sideMenu
      headerConfig={{
        pageName: 'Form builder',
        languagePicker: true,
      }}
    >
      {(isLoading || isLoadingDraftConfigurations) && <Loader />}
      {allConfigurations && allDraftConfigurations && (
        <OOTableContainer>
          <ConfigurationTable configurations={allConfigurations} drafts={allDraftConfigurations} />
        </OOTableContainer>
      )}
    </PageWrapper>
  );
};

export default Configurations;
