import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DataTable,
  DataTableTopToolbar,
  Searchbox,
  TabItem,
  Table,
  Tabs,
  TBody,
  TElement,
  THead,
  TRow,
} from '@adeccoux/tag-ds';
import { remapValuesForConfigurationLevel } from '@features/selfService/helpers/commonFunctions';
import { mutateDleteDraftConfiguration } from '@hooks/apiHooks';
import { Configuration, ConfigurationSource } from '@models/configurationList.model';
import { DraftConfiguration } from '@models/draftConfiguration.model';
import { handlePublishedStatus } from '@store/slices/selfService.slice';
import { useQueryClient } from '@tanstack/react-query';

interface ConfigurationTableProps {
  configurations: Configuration[];
  drafts: DraftConfiguration[];
}

const columns = ['Client Name', 'Journeys', 'Status', ''];

const ConfigurationTable: React.FC<ConfigurationTableProps> = ({ configurations, drafts }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState<string>('drafts');

  const history = useHistory();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const { mutate } = mutateDleteDraftConfiguration(queryClient);

  const publishedConfigurations: Configuration[] = useMemo(() => {
    if (searchTerm === '') return configurations;

    return configurations.filter((configuration) =>
      configuration.recruiterName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [configurations, searchTerm]);

  const draftConfigurations: DraftConfiguration[] = useMemo(() => {
    if (searchTerm === '') return drafts;

    return drafts.filter((configuration) =>
      configuration.data.configuration.recruiterName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [drafts, searchTerm]);

  const onEditDraft = (configuration: DraftConfiguration) => {
    history.push(
      `/oo/recruiter/self-service/new-configuration/configuration-name?draftId=${configuration.id}&configurationName=${configuration.data.configuration.recruiterName}`,
    );
  };

  const handleNewConfiguration = () => history.push('/oo/recruiter/self-service/new-configuration/configuration-base');

  const handleViewConfiguration = (clientOrganizationId: string, configurationId: any, name: string) => {
    history.push(
      `/oo/recruiter/self-service/configuration-setup?clientOrganizationId=${clientOrganizationId}&configurationId=${configurationId}&viewOnly=true`,
      {
        configurationInfo: remapValuesForConfigurationLevel({
          configurationId,
          name,
          id: clientOrganizationId,
        }),
      },
    );
  };

  const handleEditPublishedConfiguration = (clientOrganizationId: string, configurationId: any, name: string) => {
    dispatch(handlePublishedStatus(true));
    history.push(
      `/oo/recruiter/self-service/configuration-setup?clientOrganizationId=${clientOrganizationId}&configurationId=${configurationId}`,
      {
        configurationInfo: remapValuesForConfigurationLevel({
          configurationId,
          name,
          id: clientOrganizationId,
        }),
      },
    );
  };

  return (
    <DataTable className="tag-ds ">
      <DataTableTopToolbar className="tag-ds" key="tableTopToolbar">
        <Tabs onChangeTab={setActiveTab} defaultActiveTab="drafts">
          <TabItem id="drafts" title="Drafts" />
          <TabItem id="published" title="Published" />
        </Tabs>
        <Searchbox onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search" small value={searchTerm} />
        <button className="button-primary" style={{ marginRight: 8 }} onClick={handleNewConfiguration}>
          Add new client
          <span className="material-icons right">add</span>
        </button>
      </DataTableTopToolbar>
      {activeTab === 'published' && (
        <Table>
          <THead>
            <TRow>
              {columns.map((column, index) => (
                <TElement key={index}>{column}</TElement>
              ))}
            </TRow>
          </THead>
          <TBody>
            {publishedConfigurations
              .sort((a, b) => (a.recruiterName > b.recruiterName ? 1 : -1))
              .map((configuration) => {
                const viewDisabled = configuration.source === ConfigurationSource.SELF_SERVICE;
                const editDisabled = configuration.source !== ConfigurationSource.SELF_SERVICE;

                return (
                  <TRow key={configuration.id + configuration.clientOrganizationId}>
                    <TElement>{configuration.recruiterName}</TElement>
                    <TElement />
                    <TElement>
                      <div className="status-tag_success " style={{ marginBottom: 8 }}>
                        Published
                      </div>
                    </TElement>
                    <TElement>
                      <button
                        className="table-menu-info"
                        disabled={editDisabled}
                        onClick={() =>
                          handleEditPublishedConfiguration(
                            configuration.clientOrganizationId,
                            configuration.id,
                            configuration.recruiterName,
                          )
                        }
                      >
                        <span
                          className="material-icons-outlined"
                          style={{ color: editDisabled ? '#ced7e4' : '#da291c', fontSize: 20 }}
                        >
                          edit
                        </span>
                      </button>
                      <button
                        className="table-menu-info"
                        style={{ marginRight: 8 }}
                        disabled={viewDisabled}
                        onClick={() =>
                          handleViewConfiguration(
                            configuration.clientOrganizationId,
                            configuration.id,
                            configuration.recruiterName,
                          )
                        }
                      >
                        <span
                          className="material-icons-outlined"
                          style={{ color: viewDisabled ? '#ced7e4' : '#da291c', fontSize: 20 }}
                        >
                          visibility
                        </span>
                      </button>
                    </TElement>
                  </TRow>
                );
              })}
          </TBody>
        </Table>
      )}
      {activeTab === 'drafts' && (
        <Table>
          <THead>
            <TRow>
              {columns.map((column, index) => (
                <TElement key={index}>{column}</TElement>
              ))}
            </TRow>
          </THead>
          <TBody>
            {draftConfigurations
              .sort((a, b) => (a.data?.configuration?.recruiterName > b.data?.configuration?.recruiterName ? 1 : -1))
              .map((configuration) => (
                <TRow key={configuration.id + configuration.clientOrganizationId}>
                  <TElement>{configuration.data?.configuration?.recruiterName}</TElement>
                  <TElement />
                  <TElement style={{ width: 104 }}>
                    <div className="status-tag_info " style={{ marginBottom: 8, marginRight: 8 }}>
                      Draft
                    </div>
                  </TElement>
                  <TElement>
                    <div style={{ display: 'flex' }} className="tag-ds">
                      <button
                        className="table-menu-info"
                        style={{ marginRight: 8 }}
                        onClick={() => onEditDraft(configuration)}
                      >
                        <span className="material-icons-outlined" style={{ color: '#107B9E', fontSize: 20 }}>
                          edit
                        </span>
                      </button>
                      <button className="table-menu-info" onClick={() => mutate({ draftId: configuration.id })}>
                        <span className="material-icons-outlined" style={{ color: '#107B9E', fontSize: 20 }}>
                          delete
                        </span>
                      </button>
                    </div>
                  </TElement>
                </TRow>
              ))}
          </TBody>
        </Table>
      )}
    </DataTable>
  );
};

export default ConfigurationTable;
