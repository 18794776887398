import { OOUserDocumentModel } from '@features/oneOnboarding/models/UserDocumentModel';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useCandidateSignDocuments = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { step: currentStep, page: currentPage } = flowWrapper.getCurrentStepAndPage();
  const step = steps.find((x) => x.name === 'CANDIDATE_SIGN_DOCUMENTS');
  const recruiterDocumentsForSignature =
    step?.documentsForSignature.filter((x) => x.isRecruiterSignatureRequired) || [];
  const candidateDocumentsForSignature =
    step?.documentsForSignature.filter((x) => x.isCandidateSignatureRequired) || [];

  const recruiterDocuments: OOUserDocumentModel[] = recruiterDocumentsForSignature.flatMap((i) =>
    i.userDocuments
      ? [
          i.userDocuments.reduce((latest, current) =>
            new Date(latest.createdAt!).getTime() > new Date(current.createdAt!).getTime() ? latest : current,
          ),
        ]
      : [],
  );

  const candidateDocuments: OOUserDocumentModel[] = candidateDocumentsForSignature.flatMap((i) =>
    i.userDocuments
      ? [
          i.userDocuments.reduce((latest, current) =>
            new Date(latest.createdAt!).getTime() > new Date(current.createdAt!).getTime() ? latest : current,
          ),
        ]
      : [],
  );

  const isAllRecruiterSigned =
    recruiterDocuments.length > 0 && !recruiterDocuments.some((x) => x.additionalData?.signedByRecruiter !== true);
  const isAllCandidateSigned =
    candidateDocuments.length > 0 && !candidateDocuments.some((x) => x.additionalData?.signed !== true);
  const isStepCompleted = flowWrapper.isStepCompleted('CANDIDATE_SIGN_DOCUMENTS');
  const completed = isStepCompleted || (isAllRecruiterSigned && isAllCandidateSigned);
  const active = currentStep === 'CANDIDATE_SIGN_DOCUMENTS' && isAllRecruiterSigned;
  const disabled =
    active || completed
      ? false
      : !isAllRecruiterSigned || currentPage === 'HIRE_SIGNATURE_CREATE' || currentStep !== 'CANDIDATE_SIGN_DOCUMENTS';

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      const step = steps.find((x) => x.name === 'CANDIDATE_SIGN_DOCUMENTS');
      setPageStepContext({ step, page: undefined });
    },
  };
};
