import React, { FC } from 'react';
import { Tree } from '@adeccoux/tag-ds';
import { OOPages, OOSteps, StepStatusEnum } from '@features/oneOnboarding/interfaces';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { getStepStatus } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CandidateProfileSidebar/CandidateProfileSidebar';
import { RecruiterSidebarItem } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { SidebarItems } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/SidebarItems';
import { useGetOnboardingsWithRelationsMultiple } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { Onboarding } from '@models/onboarding.model';

import styles from './CustomCandidateProfileSidebar.module.css';

interface CustomCandidateProfileSidebarProps {
  items: RecruiterSidebarItem[];
  flowWrapper: OOFlowWrapper;
  steps: OOStepModel[];
  currentStep: OOSteps;
  currentPage: OOPages;
}
export interface ItemProps {
  index: number;
  steps: OOStepModel[];
  flowWrapper: OOFlowWrapper;
  onboarding?: Onboarding;
  currentStep: OOSteps;
  currentPage: OOPages;
}

export const getStatus = (
  item: RecruiterSidebarItem,
  step: OOStepModel,
  flowWrapper: OOFlowWrapper,
  currentStep: string,
): StepStatusEnum => {
  if (item.type === 'custom') {
    const completed = item.state?.completed;
    if (completed) {
      return StepStatusEnum.completed;
    } else {
      return StepStatusEnum.notStarted;
    }
  } else {
    return getStepStatus(step, flowWrapper, currentStep);
  }
};

const CustomCandidateProfileSidebar: FC<CustomCandidateProfileSidebarProps> = (props) => {
  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const { items } = props;
  const { data: onboarding } = useGetOnboardingsWithRelationsMultiple(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
  );

  return (
    <div className="tag-ds">
      <div className={styles.sidebar}>
        {items ? (
          <Tree className={styles.treeContainerWrapper}>
            <SidebarItems {...props} onboarding={onboarding} />
          </Tree>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default CustomCandidateProfileSidebar;
