import { AppConfig } from '@helpers/appConfig';
import { Utils } from '@helpers/utils';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AamBackendApi } from '../libs/aamBackendApi';

const utils = new Utils();
const appConfig = new AppConfig(utils);
export type ControlItem = {
  value: string;
  label: string;
  id: string;
  description?: string;
  condition?: Array<Record<string, any>>;
  disabled?: boolean;
  sequence: number;
  controlHeader: {
    name: string;
  };
};
export type ControlItems = ControlItem[];

const apiQuery = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['getDropdowns', 'getMultiselectValues'],
  endpoints: (build) => ({
    getDropdowns: build.query<
      ControlItems,
      { name: string; body?: any; clientOrganizationId?: string; configurationId?: string }
    >({
      queryFn: async ({ name, body, clientOrganizationId, configurationId }) => {
        const data = await new AamBackendApi(appConfig, utils).getControlItems(
          name,
          body,
          clientOrganizationId,
          configurationId,
        );
        return { data };
      },
      providesTags: (result, error, id) => ['getDropdowns'],
    }),
    getMultiselectValues: build.query<ControlItems, { name: string }>({
      queryFn: async ({ name }) => {
        let data = await new AamBackendApi(appConfig, utils).getControlItems(name);
        const controlItemsReturn: ControlItems = [];
        for (const item of data) {
          if (item.condition) {
            const conditionResult = await new AamBackendApi(appConfig, utils).getControlItemConditionResult(
              item.condition,
            );
            if (conditionResult) {
              controlItemsReturn.push(item);
            }
          } else {
            controlItemsReturn.push(item);
          }
        }
        data = controlItemsReturn;
        return { data };
      },
      providesTags: (result, error, id) => ['getMultiselectValues'],
    }),
  }),
});

export { apiQuery };
