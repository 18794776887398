import React from 'react';
import clsx from 'clsx';

import styles from './AuthLayout.module.scss';

interface Props {
  children: React.ReactChild[] | React.ReactChild;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.content, 'tag-ds')}>{children}</div>
      <div className={styles.branding}>
        <div className={styles.logo}>
          <img src="/images/branding/adecco-logo-white.svg" alt="Adecco logo" />
        </div>
        <div className={styles.drawing} />
      </div>
    </div>
  );
};

export default AuthLayout;
