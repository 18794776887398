import { injectable } from 'inversify';

import { AamBackendApi } from './aamBackendApi';

export enum LoggerLevelEnum {
  info = 'info',
  warning = 'warning',
  error = 'error',
}

@injectable()
export class Logger {
  constructor(private readonly aamBackendApi: AamBackendApi) {}
  public log = async (message: string, level: LoggerLevelEnum = LoggerLevelEnum.info): Promise<void> => {
    await this.aamBackendApi.logRemote(message, level);
  };
  public info = async (message: string): Promise<void> => {
    this.log(message);
  };
  public warning = async (message: string): Promise<void> => {
    this.log(message, LoggerLevelEnum.warning);
  };
  public error = async (message: string): Promise<void> => {
    this.log(message, LoggerLevelEnum.error);
  };
}
