import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import DocumentToSignControl from './DocumentToSignControl';

import styles from './DocumentToSignControls.module.scss';

interface Props {
  value: any;
  setFieldValue: any;
  setFieldTouched: any;
  fieldProps: any;
  disabled: boolean;
}

const DocumentToSignControls: React.FC<Props> = ({ value, setFieldValue, setFieldTouched, fieldProps, disabled }) => {
  const [allControls, setAllControls] = useState<any[]>([]);
  const [touched, setTouched] = useState(false);
  const [isExtended, setIsExtended] = useState(false);

  useEffect(() => {
    if (value && value.length > 0 && !touched) {
      const withUuid = value?.map((x: any) => ({ ...x, uuid: uuid() }));
      setAllControls(withUuid);
      setTouched(true);
    }
  }, [value, touched]);

  useEffect(() => {
    setFieldValue('documentToSignControls', allControls);
  }, [allControls, setFieldValue]);

  useEffect(() => {
    if (value) {
      setIsExtended(true);
    }
  }, [value]);

  const handleNewPart = () => {
    if (disabled) return;

    setFieldTouched('documentToSignControls', true);

    let newParts = allControls.map((p) => ({ ...p }));

    const maxSequence = newParts.length > 0 ? newParts.sort((a, b) => a.sequence - b.sequence)[0].sequence : 10;

    const newPart = {
      name: '',
      category: '',
      isMandatory: false,
      type: '',
      component: '',
      sourceClass: '',
      sourceField: '',
      sequence: maxSequence + 10,
      uuid: uuid(),
    };

    newParts = [...newParts, newPart];

    setAllControls(newParts);
  };

  const handleChange = (index: number, key: string, value: any) => {
    if (disabled) return;

    setFieldTouched('documentToSignControls', true);
    const newParts = allControls.map((p) => ({ ...p }));
    newParts[index][key] = value;
    setAllControls(newParts);
  };

  const handleDelete = (index: number) => {
    if (disabled) return;

    setFieldTouched('documentToSignControls', true);
    const newParts = allControls.map((p) => ({ ...p }));
    newParts.splice(index, 1);
    setAllControls(newParts);
  };

  const errorList = fieldProps?.meta?.error;
  const isAddButtonVisible = allControls?.length === 0;

  return (
    <div>
      <div className={styles.heading}>
        <span className="subtitle" style={{ display: 'inline-block', marginBottom: 16 }}>
          Controls
        </span>
        {isAddButtonVisible ? (
          <div className={styles.heading__icon} onClick={handleNewPart}>
            <span className="material-icons">add</span>
          </div>
        ) : (
          <div>
            <span
              className="material-icons"
              onClick={() => setIsExtended((state) => !state)}
              style={{ cursor: 'pointer', transform: isExtended ? 'rotate(180deg)' : 'none' }}
            >
              keyboard_arrow_up
            </span>
          </div>
        )}
      </div>
      <div style={{ display: isExtended ? 'block' : 'none' }}>
        {allControls.map((x, i) => {
          return (
            <DocumentToSignControl
              key={x.uuid}
              item={x}
              index={i}
              handleDelete={() => handleDelete(i)}
              handleChange={(fieldName: string, value: any) => handleChange(i, fieldName, value)}
              error={errorList?.[i]}
            />
          );
        })}
        {!isAddButtonVisible && (
          <button type="button" onClick={handleNewPart}>
            Add new document control
          </button>
        )}
      </div>
    </div>
  );
};

export default DocumentToSignControls;
