import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { authAdminSlice } from '@store/slices/authAdmin.slice';
import { authAppSlice } from '@store/slices/authApp.slice';

interface IdleTimerContainerProps {
  //number of minutes for idle time
  timeout: number;
}

const IdleTimerContainer = ({ timeout }: IdleTimerContainerProps) => {
  const idleTimer = useRef<IdleTimer | null>(null);
  const dispatch = useDispatch();
  const onIdle = () => {
    const isLogin =
      window.location.pathname.startsWith('/app/login') || window.location.pathname.startsWith('/admin/login');
    if (isLogin) {
      return;
    }
    const isAppSection = window.location.pathname.startsWith('/app/');
    if (isAppSection) {
      dispatch(authAppSlice.actions.clear(true));
    } else {
      dispatch(authAdminSlice.actions.clear(true));
    }
  };

  return (
    <>
      <IdleTimer
        ref={(ref: IdleTimer) => {
          idleTimer.current = ref;
        }}
        onIdle={onIdle}
        debounce={250}
        // minutes to seconds to milliseconds
        timeout={timeout * 60 * 1000}
      />
    </>
  );
};

export default IdleTimerContainer;
