import React from 'react';
import { Grid } from '@mui/material';

import { OOControlModel } from '../../models/ControlModel';
import { OOFormControl } from '../FormControl';
import { OOAccordion } from './Accordion/OOAccordion';

interface AccordionWithSubcontrolsProps {
  controlModel: OOControlModel;
  filterSubControls?: (c: OOControlModel) => boolean;
}

const AccordionWithSubcontrols: React.FC<AccordionWithSubcontrolsProps> = ({ controlModel, filterSubControls }) => {
  const { subControls, name } = controlModel;

  if (!subControls || !subControls.length || !filterSubControls) {
    throw Error('Missing properties');
  }

  return (
    <>
      <OOAccordion controlModel={controlModel}>
        <>
          {(filterSubControls
            ? (subControls as Array<OOControlModel>).filter((c: any) => filterSubControls(c))
            : (subControls as Array<OOControlModel>)
          ).map((subControl: OOControlModel) => (
            <Grid key={name} item xs={12} style={{ minWidth: '100%' }}>
              <OOFormControl key={subControl.name} control={subControl} />
            </Grid>
          ))}
        </>
      </OOAccordion>
    </>
  );
};

export default AccordionWithSubcontrols;
