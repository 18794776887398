import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Box, FormControl, FormHelperText, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { checkboxControlError, checkboxErrorLabel } from '../../../helpers';
import { useT } from '../../../hooks/useT';
import { FormControlLoader } from '../../FormControlLoader';

import styles from './RankingSurvey.module.scss';

export type OORankingSurveyProps = {
  validationKey: string;
  label: string;
  description: string;
  staticText?: string;
  isMandatory: boolean;
  min?: number | undefined;
  max?: number | undefined;
  parameters?: Array<Record<string, any>>;
  defaultValue?: string | undefined;
};

export const OORankingSurvey: React.FC<OORankingSurveyProps> = ({
  validationKey,
  label,
  description,
  staticText,
  isMandatory,
  min,
  max,
  parameters,
  defaultValue,
}: OORankingSurveyProps) => {
  const { t, translationsLoading } = useT('entry', 'approval', 'documents');
  const formik = useFormikContext<Record<string, any>>();
  const fieldProps = formik.getFieldProps(validationKey);
  const fieldMeta = formik.getFieldMeta(validationKey);
  useEffect(() => {
    if ([null, undefined].includes(fieldProps.value) && defaultValue) {
      formik.setFieldValue(validationKey, parseInt(defaultValue, 10));
    }
  });

  const getPrefix = (label: string) => {
    let prefix = label?.split('.')?.[0].toLowerCase() || '';
    const prefixAlias: any = {
      documents2: 'documents',
      documents3: 'documents',
    };
    if (prefixAlias[prefix]) {
      prefix = prefixAlias[prefix];
    }
    return prefix;
  };

  const prefix = getPrefix(label);

  if (translationsLoading) {
    return <FormControlLoader />;
  }

  const translateWithParameter = (name: string, parameters: any) => {
    let transParameters = {};
    for (const param of parameters || []) {
      transParameters = { ...transParameters, [param.name]: param.value };
    }
    const translate = t(`${prefix}:${name}`, transParameters);
    return JSON.parse(JSON.stringify(translate));
  };

  const start = min || min === 0 ? (min as number) : 1;
  const end = max ? max : 10;
  const ranks = [...Array(end - start + 1).keys()].map(
    (i: number): number => Number.parseInt(i.toString()) + Number.parseInt(start.toString()),
  );

  const renderDescriptionOrError = (error?: string) => {
    if (error) {
      return <FormHelperText>{t(`${prefix}:${error}`)}</FormHelperText>;
    }

    if (description) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '5px',
          }}
        >
          <FormHelperText sx={{ alignSelf: 'flex-start' }}>{`${start} - ${t(
            `${prefix}:${description}`,
          )}`}</FormHelperText>
          <FormHelperText sx={{ alignSelf: 'flex-end' }}>{`${t(
            staticText ? `${prefix}:${staticText}` : '',
          )} - ${end}`}</FormHelperText>
        </Box>
      );
    }
  };

  return (
    <FormControl {...checkboxControlError(fieldMeta)}>
      {t(`${prefix}:${label}`) && (
        <Box pb={1}>
          <FormLabel>{`${t(translateWithParameter(label, parameters))} ${isMandatory ? '*' : ''}`}</FormLabel>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ToggleButtonGroup
          className={styles.RankingSurveyButtonGroup}
          aria-label="ranking survey button group"
          onChange={(event: any, newRank: number) => formik.setFieldValue(validationKey, newRank)}
          value={fieldProps.value}
          exclusive
        >
          {ranks.map((i) => (
            <ToggleButton
              className={styles.RankingSurveyButton}
              value={i}
              aria-label="centered"
              sx={{
                width: `${100 / ranks.length}%`,
              }}
              key={`rankBtn${i}`}
            >
              {`${i}`}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {renderDescriptionOrError(checkboxErrorLabel(fieldMeta))}
    </FormControl>
  );
};
