import { TenantAppConfig, TenantModel } from '@models/tenant.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiCallStatus } from '../apiCallStatus';

interface TenantStoreState {
  tenantId: number;
  tenantAlias: string | null;
  useOneOnboarding: boolean;
  appConfig: TenantAppConfig;
  [key: string]: any;
  fetchAppConfigStatus: ApiCallStatus;
}

const initialState: any = {
  tenantId: 101,
  tenantAlias: null,
  appConfig: null,
  useOneOnboarding: false,
  fetchAppConfigStatus: ApiCallStatus.none,
};

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState: initialState as TenantStoreState,
  reducers: {
    setStatus: (state, action: PayloadAction<{ name: string; value: ApiCallStatus }>) => {
      state[action.payload.name] = action.payload.value;
      return state;
    },
    setAppConfig: (state, action: PayloadAction<TenantModel>) => {
      state.appConfig = action.payload.appConfig;
      return state;
    },
    setTenant: (state, action: PayloadAction<{ tenantId: number; tenantAlias: string }>) => {
      state.tenantId = action.payload.tenantId;
      state.tenantAlias = action.payload.tenantAlias;
      state.useOneOnboarding = true;
      return state;
    },
  },
});
