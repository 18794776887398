import { combineReducers } from '@reduxjs/toolkit';

import { apiQuery } from './apiQuery';
import { authAdminSlice } from './slices/authAdmin.slice';
import { authAppSlice } from './slices/authApp.slice';
import { clientSlice } from './slices/client.slice';
import { countriesSlice } from './slices/country.slice';
import { dashboardSlice } from './slices/dashboard.slice';
import { featureConfigurationSlice } from './slices/featureConfiguration.slice';
import { oneOnboardingSlice } from './slices/oneOnboarding.slice';
import { selfServiceSlice } from './slices/selfService.slice';
import { snackbarSlice } from './slices/snackbar';
import { tenantSlice } from './slices/tenant.slice';

export const rootReducer = combineReducers({
  authApp: authAppSlice.reducer,
  authAdmin: authAdminSlice.reducer,
  tenant: tenantSlice.reducer,
  client: clientSlice.reducer,
  countries: countriesSlice.reducer,
  featureConfiguration: featureConfigurationSlice.reducer,
  dashboard: dashboardSlice.reducer,
  snackbar: snackbarSlice.reducer,
  oneOnboarding: oneOnboardingSlice.reducer,
  [apiQuery.reducerPath]: apiQuery.reducer,
  selfService: selfServiceSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
