import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';

export interface AdminDropdownProps extends SelectProps {
  defaultValue?: string | Number;
  items: any[];
  name?: string;
  onChange: (e: object) => void;
  value?: string | Number;
  containerStyle?: object;
  placeholder?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  inputStyle?: object;
  disabled?: boolean;
  uniqueId?: string;
  disableNone?: boolean;
}

export const AdminDropdown: React.FC<AdminDropdownProps> = ({
  value,
  items,
  onChange,
  name,
  containerStyle,
  placeholder,
  required = false,
  error,
  helperText,
  inputStyle,
  disabled,
  uniqueId,
  disableNone = false,
  ...rest
}) => {
  return (
    <>
      <FormControl style={containerStyle} required={required} error={error} disabled={disabled}>
        <InputLabel id={`demo-simple-select-outlined-label-${uniqueId}`}>{placeholder}</InputLabel>
        <Select
          MenuProps={{ disablePortal: true }}
          style={inputStyle}
          labelId={`demo-simple-select-filled-label-${uniqueId}`}
          id={`demo-simple-select-filled${uniqueId}`}
          value={value}
          name={name ?? name}
          label={placeholder}
          onChange={onChange}
          {...rest}
        >
          {!disableNone && <MenuItem value="">none</MenuItem>}
          {items &&
            items.map((x) => {
              return (
                <MenuItem key={x.value} value={x.value}>
                  {x.text}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};
