import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import { findDifference } from '@features/selfService/helpers/commonFunctions';
import { RootState } from '@store/rootReducer';
import { getPath, handleDocumentTarget, handleDocumentToUploadPropertyModify } from '@store/slices/selfService.slice';
import { snackbarSlice } from '@store/slices/snackbar';

import {
  getInitialTranslationValues,
  handleSecondLevelTranslationSubmit,
  handleTranslationSubmit,
  initializeTranslationValue,
} from '../../../../../helpers/renderControlHelper';
import VersionPicker from '../../other/VersionPicker/VersionPicker';
import Controls from '../../renderControls/Controls';
import { DocumentPropertyType } from '../DocumentList';
import { DocumentsTemplate, DocumentTemplate } from './templates';

import styles from '../../../ConfigurationSetup.module.scss';

interface Props {
  document: {
    type: DocumentPropertyType;
    controls: { [key: string]: any };
  };
  handleClose: (callback?: () => void) => void;
  libraryItem: null | { name: string; id: string };
  handleDifferentVersion: (control: any) => void;
}

const DocumentControls: React.FC<Props> = ({ document, handleClose, libraryItem, handleDifferentVersion }) => {
  const [initialValues, setInitialValues] = useState({
    component: '',
  });
  const [translations, setTranslations] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);

  const formikRef = useRef<any>();
  const { selectedConfiguration, selectedFeature } = useSelector((store: RootState) => store.selfService);
  const availableLanguages = selectedConfiguration?.languages;
  const { t } = useTranslation(['documents']);
  const dispatch = useDispatch();
  const { isViewOnly } = useContext(ViewOnlyContext);

  const lookup: any = useMemo(() => {
    return {
      document: DocumentTemplate,
      documents: DocumentsTemplate,
      parts: {},
    };
  }, []);

  useEffect(() => {
    if (document.controls.name.length > 0) {
      setIsEdit(true);
    }
  }, [document.controls]);

  useEffect(() => {
    const requiredConverting = document.type && lookup[document.type]?.converterForUi;

    if (!selectedConfiguration || !availableLanguages) return;

    const withTranslations = (initialValues: any) =>
      getInitialTranslationValues(initialValues, document.controls, selectedConfiguration, t);

    const withInitialValues = (initialValues: any) => {
      return { ...lookup[document.type].initialValues, ...initialValues };
    };

    setTranslations(initializeTranslationValue(lookup, document, selectedConfiguration, availableLanguages, t));

    if (requiredConverting) {
      setInitialValues(withTranslations(withInitialValues(lookup[document.type].converterForUi(document.controls))));
      return;
    }

    setInitialValues(withInitialValues(withTranslations(document.controls)));
  }, [document, lookup, selectedConfiguration, t, availableLanguages?.supported, availableLanguages]);

  const isNameDuplicate = (name: string) => {
    if (!selectedConfiguration) return;

    const getDocumentations = getPath('documentations', selectedConfiguration, {
      featureName: selectedFeature?.featureName || '',
      stepName: selectedFeature?.stepName,
    });
    const documentIndex = getDocumentations.findIndex((x: any) => x.name === name);

    return documentIndex !== -1;
  };

  const onSubmit = (values: any) => {
    if (isNameDuplicate(values.name) && !isEdit) {
      dispatch(
        snackbarSlice.actions.showError({
          message: `Document with name ${values.name} already exists`,
        }),
      );

      throw new Error(`Document with name ${values.name} already exists`);
    }

    let mergeVisibleAndHidden = { ...lookup[document.type]?.hidden, ...values };

    if (lookup[document.type]?.convertForConfiguration) {
      mergeVisibleAndHidden = lookup[document.type].convertForConfiguration(mergeVisibleAndHidden);
    }

    // Handle top level controls
    handleTranslationSubmit(initialValues, values, dispatch, document.controls);

    // Handle second level controls - document controls
    handleSecondLevelTranslationSubmit(document, availableLanguages, translations, t, dispatch);

    if (lookup[document.type]?.hideForConfiguration) {
      lookup[document.type].hideForConfiguration.forEach((x: string) => {
        delete mergeVisibleAndHidden[x];
      });
    }

    mergeVisibleAndHidden['change'] = findDifference(values, initialValues);

    dispatch(
      handleDocumentToUploadPropertyModify({
        propertyType: document.type,
        control: mergeVisibleAndHidden,
        originalControlName: document.controls.name,
      }),
    );
  };

  return (
    <div className="tag-ds">
      <div className={styles.controlDetails__heading}>
        <div className={styles.controlDetails__name}>
          <div
            className="material-icons"
            onClick={() => {
              if (formikRef.current.dirty) {
                const modifiedForm = formikRef.current.values;
                handleClose(() => onSubmit(modifiedForm));
              } else {
                handleClose();
              }

              dispatch(handleDocumentTarget(null));
            }}
            style={{ cursor: 'pointer', marginRight: 20 }}
          >
            arrow_back
          </div>
          <span className="large">{document.type === 'document' ? 'Document family' : 'Document properties'}</span>
        </div>
      </div>

      {libraryItem && <VersionPicker libraryItem={libraryItem} selectVersionHandler={handleDifferentVersion} />}

      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        validateOnMount
        validateOnChange
        onSubmit={(values) => {
          onSubmit(values);
          dispatch(handleDocumentTarget(null));
          handleClose();
        }}
        initialValues={initialValues}
        validationSchema={lookup[document.type]?.validation ?? null}
      >
        {(props: FormikProps<any>) => {
          return (
            <form onSubmit={props.handleSubmit}>
              {lookup[document.type].visible.map((x: string) => (
                <Field name={x} key={x}>
                  {(fieldProps: FieldProps) => (
                    <Controls
                      fieldProps={fieldProps}
                      documentControlTranslations={translations}
                      setDocumentControlTranslations={setTranslations}
                      isViewOnly={isViewOnly}
                    />
                  )}
                </Field>
              ))}
              {!isViewOnly && <button type="submit">Save</button>}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DocumentControls;
