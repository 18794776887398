import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '@context/inversify-context-provider';
import { ExpandMore } from '@mui/icons-material';
import { Collapse, MenuItem, Select, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { AdminSimpleButton } from './AdminSimpleButton';

import styles from './AdminImport.module.scss';
interface AdminDeleteBranchProps {
  assetBoxes: string[];
  strId: string;
  toggleAssetBox: (strId: string) => void;
  handleDeleteBranch: (branchId: string) => void;
}
export const AdminDeleteBranch: FC<AdminDeleteBranchProps> = ({
  assetBoxes,
  strId,
  toggleAssetBox,
  handleDeleteBranch,
}) => {
  const { t } = useTranslation(['recruiter']);
  const [deleteBranchId, setDeleteBranchId] = useState<string>('');
  const assetBoxOpen = assetBoxes.includes(strId);
  const aamBackendApi = useInjection(AamBackendApi);
  const { data: branches } = useQuery({
    queryKey: ['fetchBranches'],
    queryFn: () => aamBackendApi.fetchBranches(),
  });
  return (
    <div className={`${styles.assetHolder} ${styles.tenantAssetDir}`}>
      <div className={`${styles.flexHolder} ${styles.assetHeader}`} onClick={() => toggleAssetBox(strId)}>
        <Typography variant="h4">{t('GENERAL.CONFIGURATION.RemoveBranchLabel')}</Typography>
        <ExpandMore style={!assetBoxOpen ? { transform: 'rotate(180deg)' } : {}} />
      </div>
      <Collapse in={assetBoxOpen} timeout="auto" unmountOnExit>
        <div className={styles.assetBox} style={{ display: 'grid', gap: '0.5rem' }}>
          <div>
            <span className={styles.textInput}>
              <Select
                style={{ width: 300, marginBottom: '8px' }}
                value={deleteBranchId}
                onChange={(e) => setDeleteBranchId(e.target.value)}
              >
                {branches?.map((b) => (
                  <MenuItem key={b.id} value={b.id}>
                    {b.name} / {b.sid}
                  </MenuItem>
                ))}
              </Select>
              <AdminSimpleButton
                label={t('GENERAL.CONFIGURATION.RemoveBranchAction')}
                disabled={!deleteBranchId}
                onClick={async () => {
                  if (deleteBranchId) {
                    await handleDeleteBranch(deleteBranchId);
                    setDeleteBranchId('');
                  }
                }}
              />
            </span>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
