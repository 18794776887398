import { useEffect, useMemo, useState } from 'react';
import { NotificationDetails, NotificationType } from '@components/admin/Notification/Notification';
import { OODashboardData } from '@features/oneOnboarding/interfaces';
import { ClientsAndJourneys } from '@features/oneOnboardingAdmin/pages/UserPermissions/components/ClientsAndJourneysList';
import { ListType } from '@features/oneOnboardingAdmin/pages/UserPermissions/components/NestedListSelector';
import { RegionsAndBranches } from '@features/oneOnboardingAdmin/pages/UserPermissions/components/RegionsAndBranchesList';
import {
  CheckedJourneysAndClients,
  CheckedRegionsAndBranches,
} from '@features/oneOnboardingAdmin/pages/UserPermissions/UserPermissionDetailsPage';
import { useFetchBranches, useGetUserWithPermissions, useRegions } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { AcccessStatus, UserModel, UserRole } from '@models/user.model';
import { UserPermissionModel } from '@models/userPermission.model';

export const useFillPageWithData = (
  tenantId: number,
  setCheckedRegionsAndBranches: (value: CheckedRegionsAndBranches) => void,
  setMasterCheck: (value: (prevState: { [key in ListType]: boolean }) => { [key in ListType]: boolean }) => void,
  setCheckedClientsAndJourneys: (value: CheckedJourneysAndClients[]) => void,
) => {
  const [userPermissionData, setUserPermissionData] = useState<UserPermissionModel>({
    userId: '',
    tenantId,
    role: UserRole.adeccoStaff,
  });
  const { userId } = useDecodedParams();

  const handleSelectedConfigurationIdsClientOrganizationIds = (data: UserModel) => {
    const nullSelectedConfigurationIdsClientOrganizationIds =
      data.userPermission?.selectedConfigurationIds === null && data.userPermission?.clientOrganizationIds === null;

    if (nullSelectedConfigurationIdsClientOrganizationIds) {
      setMasterCheck((state) => ({ ...state, [ListType.CLIENTS_AND_JOURNEYS]: true }));
    } else {
      const initialCheckedClientsAndJourneys = data.userPermission?.selectedConfigurationIds
        ?.map((x) => {
          return {
            clientId: x[0],
            journeyId: x[1],
          };
        })
        .reduce((acc: CheckedJourneysAndClients[], curr: CheckedJourneysAndClients) => {
          if (acc.findIndex((x) => x.clientId === curr.clientId && x.journeyId === curr.journeyId) < 0) {
            acc.push(curr);
          }

          return acc;
        }, []);

      if (initialCheckedClientsAndJourneys) {
        setCheckedClientsAndJourneys(initialCheckedClientsAndJourneys);
      }
    }
  };

  const handleBranchIdsRegionsIds = (data: UserModel) => {
    const nullBranchIdsRegionsIds = data.userPermission?.branchIds === null && data.userPermission?.regionIds === null;

    if (nullBranchIdsRegionsIds) {
      setMasterCheck((state) => ({ ...state, [ListType.REGIONS_AND_BRANCHES]: true }));
    } else {
      setCheckedRegionsAndBranches({
        branchIds: data.userPermission?.branchIds ?? [],
        regionIds: data.userPermission?.regionIds ?? [],
      });
    }
  };

  const fillPageWithData = (data: UserModel) => {
    const userWithPermissions: UserPermissionModel = {
      tenantId: tenantId,
      userId: data.id as string,
      email: data.email as string,
      branchIds: data.userPermission?.branchIds,
      regionIds: data.userPermission?.regionIds,
      selectedConfigurationIds: data.userPermission?.selectedConfigurationIds,
      clientOrganizationIds: data.userPermission?.clientOrganizationIds,
      role: data.role as UserRole,
      accessStatus: data.accessStatus as AcccessStatus,
    };

    handleSelectedConfigurationIdsClientOrganizationIds(data);
    handleBranchIdsRegionsIds(data);

    setUserPermissionData(userWithPermissions);
  };

  useGetUserWithPermissions(tenantId, userId, fillPageWithData);

  return { userPermissionData, setUserPermissionData };
};
export const useGenerateClientAndJourneyList = (data: OODashboardData | undefined): ClientsAndJourneys[] =>
  useMemo(() => {
    if (!data) return [];

    const result = data.configurations.reduce((acc: any[], curr: any) => {
      const cidIndex = acc.findIndex((item) => item.clientOrganizationId === curr.clientOrganizationId);

      if (cidIndex >= 0) {
        acc[cidIndex].configs.push(curr);
      } else {
        acc.push({
          clientOrganizationId: curr.clientOrganizationId,
          clientOrganizationDetails: curr.clientOrganization,
          configs: [curr],
        });
      }

      return acc;
    }, []);

    return result;
  }, [data]);
export const useRegionsAndBranches = (
  tenantId: number,
  triggerNotification: (type: NotificationType, details: NotificationDetails) => void,
  t: (key: string) => string,
) => {
  const { data: regionsData, isLoading: isRegionsLoading, isError: isRegionsError } = useRegions();
  const { data: branchesData, isError: isBranchesError } = useFetchBranches();

  useEffect(() => {
    if (isRegionsError || isBranchesError) {
      triggerNotification('error', { header: t('CANDIDATES_OVERVIEW.somethingWentWrong') });
    }
  }, [isRegionsError, isBranchesError, t, triggerNotification]);

  const regionsAndBranches: RegionsAndBranches[] = useMemo(() => {
    const prepareRegions: any[] =
      regionsData?.map((x) => ({
        regionId: x.id,
        regionDetails: x,
        branches: [],
      })) || [];

    (branchesData || [])
      .filter((y: any) => y.tenantId === tenantId)
      .forEach((x: any) => {
        const index = prepareRegions.findIndex((z) => z.regionId === x.regionId);

        if (index >= 0) {
          prepareRegions[index].branches.push(x);
        }
      });

    return prepareRegions.filter((x) => !!x.branches.length);
  }, [regionsData, branchesData, tenantId]);

  return { regionsAndBranches, isRegionsLoading };
};
