import React from 'react';

interface Props {
  value: string;
  setFieldValue: (value: string) => void;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const SingleSelectType: React.FC<Props> = ({ value, setFieldValue, onChange, disabled }) => {
  const options = [
    {
      value: 'RadioGroup',
      label: 'Radio buttons',
    },
    {
      value: 'Select',
      label: 'Drop down with a single select',
    },
    {
      value: 'Autocomplete',
      label: 'Drop down with auto-complete',
    },
  ];

  return (
    <div style={{ marginTop: 12 }}>
      <label className="caption">Single-select type</label>
      <div className="tag-ds">
        {options.map((option, index) => {
          return (
            <div key={index} className="radio-container">
              <input
                id={option.value}
                type="radio"
                onChange={(e: any) => {
                  onChange(e);
                  setFieldValue(e.target.value);
                }}
                name="singleSelectComponentType"
                checked={option.value === value}
                value={option.value}
                disabled={disabled}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleSelectType;
