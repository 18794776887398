import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { ConfigurationDetails, SelectedConfiguration } from '@features/selfService/models/configurationModels';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DocumentPropertyType = 'document' | 'documents' | 'parts' | 'condition';

const initialState: SelfServiceStore = {
  selectedConfiguration: null,
  selectedFeature: null,
  targetField: '',
  flowWrapper: null,
  configurationChangeStack: [],
  isPublished: false,
  documentTarget: null,
};

interface SelfServiceStore {
  selectedConfiguration: SelectedConfiguration | null;
  selectedFeature: {
    featureName: string;
    stepName: string;
    pageName?: string;
    label?: string;
    data?: unknown;
  } | null;
  flowWrapper: any;
  configurationChangeStack: {
    typeOfChange: 'add' | 'change' | 'delete';
    change: any;
    featureType: string;
    changeList: string[];
  }[];
  // Used for adding document to documentation.
  // Without this property, we would not know to which documentation document should be added.
  documentTarget: null | string;
  //   dnd
  targetField: string;
  isPublished: boolean;
}

export const handleFlowWrapper = (sc: any, selectedPage: { stepName: string; pageName: string }) => {
  if (!sc) return;

  const getAllSteps = sc?.features.flatMap((x: any) => {
    return x.steps.map((y: any) => y);
  });

  const wrap = OOFlowWrapper.create(getAllSteps);

  const findForm = wrap?.steps
    .find((x: any) => x.name === selectedPage?.stepName)
    ?.pages.find((y: any) => y.name === selectedPage?.pageName);

  return {
    form: findForm,
    flowWrapper: wrap,
  };
};

export const findPageInConfiguration = (
  pageDetails: { featureName?: string; stepName?: string; pageName?: string },
  configuration: SelectedConfiguration | null,
) => {
  if (!configuration) throw new Error();

  return configuration?.features
    .find((x: any) => x.name === pageDetails.featureName)
    ?.steps.find((x: any) => x.name === pageDetails.stepName)
    ?.pages.find((x: any) => x.name === pageDetails.pageName);
};

export const findDocumentInConfiguration = (
  documentDetails: { featureName?: string; stepName?: string },
  configuration: SelectedConfiguration | null,
) => {
  if (!configuration) throw new Error();

  return configuration?.features
    .find((x: any) => x.name === documentDetails.featureName)
    ?.steps.find((x: any) => x.name === documentDetails.stepName);
};

export const getPath = (
  target: 'feature' | 'steps' | 'page' | 'documentations' | 'documents',
  selectedConfiguration: SelectedConfiguration,
  data: { featureName: string; stepName?: string; pageName?: string; documentName?: string },
): any => {
  const featureIndex = selectedConfiguration.features.findIndex((feature: any) => feature.name === data?.featureName);
  const stepsIndex = selectedConfiguration.features[featureIndex].steps.findIndex(
    (step: any) => step.name === data?.stepName,
  );
  const pageIndex = selectedConfiguration.features[featureIndex].steps[stepsIndex].pages.findIndex(
    (page: any) => page.name === data?.pageName,
  );
  const documentIndex = selectedConfiguration.features[featureIndex].steps[stepsIndex].documentations.findIndex(
    (x: any) => {
      return x.documents?.some((y: any) => {
        return y.name === data.documentName;
      });
    },
  );

  if (target === 'feature') {
    return selectedConfiguration.features[featureIndex];
  }

  if (target === 'steps') {
    return selectedConfiguration.features[featureIndex].steps[stepsIndex];
  }

  if (target === 'page') {
    return selectedConfiguration.features[featureIndex].steps[stepsIndex].pages[pageIndex];
  }

  if (target === 'documentations') {
    return selectedConfiguration.features[featureIndex].steps[stepsIndex].documentations;
  }

  if (target === 'documents') {
    return selectedConfiguration.features[featureIndex].steps[stepsIndex].documentations[documentIndex];
  }

  return selectedConfiguration;
};

const modifyNestedObject = (parent: any, pathSegments: string[], value: any): any => {
  let currentObject = parent;

  for (const segment of pathSegments) {
    currentObject = currentObject[segment as keyof Object];
  }

  for (const key of Object.keys(currentObject)) {
    if (Array.isArray(currentObject[key]) && value[key]) {
      const currentLength = currentObject[key].length;
      currentObject[key].splice(0, currentLength);
      currentObject[key].push([...value[key]]);
    }
    currentObject[key] = value[key];
  }

  return parent;
};

export const selfServiceSlice = createSlice({
  name: 'selfService',
  initialState: initialState as SelfServiceStore,
  reducers: {
    initializeConfiguration: (
      state,
      action: PayloadAction<{ configuration: SelectedConfiguration; configurationChangeStack?: any[] }>,
    ) => {
      state.selectedConfiguration = action.payload.configuration;
      state.configurationChangeStack = [];
      state.selectedFeature = null;

      if (action.payload.configurationChangeStack && action.payload?.configurationChangeStack.length > 0) {
        state.configurationChangeStack = action.payload.configurationChangeStack;
      }

      return state;
    },
    // Regular page
    // Used for select, drag reorder and delete
    handlePageModify: (
      state,
      action: PayloadAction<{
        featureName: string;
        stepName: string;
        pageName: string;
        controls: any;
        modification?: {
          type: 'delete' | 'change';
          typeOfChange: string;
          property: any;
        };
      }>,
    ) => {
      if (!state.selectedConfiguration) throw new Error('Missing configuration!');

      state.selectedFeature = action.payload;

      getPath('page', state.selectedConfiguration, {
        featureName: action.payload?.featureName,
        stepName: action.payload?.stepName,
        pageName: action.payload.pageName,
        // Check this thing, looks suspicios
      }).controls = action.payload.controls;

      if (action.payload.modification) {
        const { modification, pageName } = action.payload;

        state.configurationChangeStack.push({
          typeOfChange: modification.type,
          change: modification.property,
          featureType: pageName,
          changeList: [modification.typeOfChange],
        });
      }

      return state;
    },
    handleNewPageControl: (
      state,
      action: PayloadAction<{
        path: string;
        selectOptions: any;
        customControlHeaders?: { name: string; options: any[] };
        name: string;
        sequence?: number;
        controlHeaderName?: string;
        change?: string[];
      }>,
    ) => {
      if (!state.selectedConfiguration) throw new Error('Missing configuration!');
      // Difference between controlHeaders and customControlHeaders is that custom one can have different name than control name
      const controlHeaders = action.payload['selectOptions'];
      const customControlHeaders = action.payload['customControlHeaders'];

      const findControlHeader = (controlHeaderName?: string) => {
        if (!controlHeaderName) return null;

        return state.selectedConfiguration?.controlHeaders.find((x: any) => x.name === controlHeaderName);
      };

      const findControlHeaderByUuid = (controlHeaderName?: string) => {
        if (!controlHeaderName) return null;

        const getRoot = (name: string) => name.split('__')[0];

        return state.selectedConfiguration?.controlHeaders.findIndex(
          (x: any) => getRoot(x.name) === getRoot(controlHeaderName),
        );
      };

      if (customControlHeaders) {
        const alreadyExists = findControlHeader(customControlHeaders.name);

        if (!alreadyExists) {
          state.selectedConfiguration.controlHeaders = [
            ...state.selectedConfiguration.controlHeaders,
            { name: customControlHeaders.name, items: customControlHeaders.options },
          ];
        }

        delete action.payload['customControlHeaders'];
      }

      if (controlHeaders) {
        const alreadyExists = findControlHeader(action.payload?.controlHeaderName);
        const sameUuidExists = findControlHeaderByUuid(action.payload?.controlHeaderName);

        if (sameUuidExists !== -1) {
          // Doing recognition by id, and if exists we delete it
          const fOut = state.selectedConfiguration.controlHeaders.filter((_, i) => i !== sameUuidExists);

          state.selectedConfiguration.controlHeaders = fOut;
        }

        if (alreadyExists) {
          alreadyExists.items = controlHeaders;
        } else {
          state.selectedConfiguration.controlHeaders = [
            ...state.selectedConfiguration.controlHeaders,
            { name: action.payload.controlHeaderName, items: controlHeaders },
          ];
        }

        delete action.payload['selectOptions'];
      }

      const getPage = getPath('page', state.selectedConfiguration, {
        featureName: state.selectedFeature?.featureName || '',
        stepName: state.selectedFeature?.stepName || '',
        pageName: state.selectedFeature?.pageName || '',
      });

      const controlIndex = getPage.controls.findIndex((x: any) => x.name === action.payload.name);
      const controlExists = controlIndex !== -1;

      if (controlExists) {
        getPage.controls[controlIndex] = action.payload;

        state.configurationChangeStack.push({
          typeOfChange: 'change',
          change: action.payload,
          featureType: state.selectedFeature?.pageName || '',
          changeList: action.payload.change || [],
        });

        return;
      }

      action.payload.sequence = getPage.controls[getPage.controls.length - 1]?.sequence + 10 || 10;

      getPage.controls.push(action.payload);

      state.configurationChangeStack.push({
        typeOfChange: 'add',
        change: action.payload,
        featureType: state.selectedFeature?.pageName || '',
        changeList: [],
      });

      return state;
    },
    // Document to upload
    handleDocumentToUploadFeature: (
      state,
      action: PayloadAction<{
        featureName: string;
        stepName: string;
        documentations: any;
        modification?: { type: 'delete' | 'change'; typeOfChange?: string; property: any };
      }>,
    ) => {
      if (!state.selectedConfiguration) throw new Error('Missing configuration!');

      state.selectedFeature = action.payload;

      if (action.payload.modification) {
        state.configurationChangeStack.push({
          typeOfChange: action.payload.modification.type,
          change: action.payload.modification.property,
          featureType: 'UPLOAD_DOCUMENTS',
          changeList: action.payload.modification?.typeOfChange ? [action.payload.modification?.typeOfChange] : [],
        });
      }

      getPath('steps', state.selectedConfiguration, {
        featureName: action.payload.featureName,
        stepName: action.payload.stepName,
      }).documentations = action.payload.documentations;

      return state;
    },
    handleDocumentToUploadPropertyModify: (
      state,
      action: PayloadAction<{ propertyType: DocumentPropertyType; control: any; originalControlName: string }>,
    ) => {
      state.configurationChangeStack.push({
        typeOfChange: 'change',
        change: action.payload.control,
        featureType: 'UPLOAD_DOCUMENTS',
        changeList: action.payload.control?.change,
      });

      const handleDocumentation = () => {
        if (!state.selectedConfiguration) throw new Error('Missing configuration!');

        const getDocumentations = getPath('documentations', state.selectedConfiguration, {
          featureName: state.selectedFeature?.featureName || '',
          stepName: state.selectedFeature?.stepName,
        });

        const documentIndex = getDocumentations.findIndex((x: any) => x.name === action.payload.originalControlName);

        if (documentIndex === -1) {
          state.configurationChangeStack.push({
            typeOfChange: 'add',
            change: action.payload,
            // This one should be dynamic?
            featureType: 'UPLOAD_DOCUMENTS',
            changeList: [],
          });

          action.payload.control.sequence = getDocumentations[getDocumentations.length - 1]?.sequence + 10 || 10;

          getDocumentations.push(action.payload.control);

          return;
        }

        getDocumentations[documentIndex] = action.payload.control;
      };

      const handleDocument = () => {
        if (!state.selectedConfiguration) throw new Error('Missing configuration!');

        const isDocumentFromLibrary = state.documentTarget;

        const getDocuments = getPath('documents', state.selectedConfiguration, {
          featureName: state.selectedFeature?.featureName || '',
          stepName: state.selectedFeature?.stepName,
          documentName: action.payload.originalControlName,
        });

        const documentIndex = getDocuments.documents.findIndex((x: any) => {
          return x.name === action.payload.originalControlName;
        });

        const customControlHeaders = action.payload.control.controls
          ?.filter((x: any) => x.customControlHeaders)
          .map((x: any) => x.customControlHeaders);

        if (customControlHeaders?.length > 0) {
          customControlHeaders.forEach((controlHeader: any) => {
            const alreadyExists = state.selectedConfiguration?.controlHeaders.find(
              (x: any) => x.name === controlHeader.name,
            );

            if (!alreadyExists) {
              state.selectedConfiguration?.controlHeaders.push({
                name: controlHeader.name,
                items: controlHeader.options,
              });
            }
          });
        }

        action.payload.control.controls?.forEach((x: any) => {
          if (x.customControlHeaders) {
            delete x.customControlHeaders;
          }
        });

        if (isDocumentFromLibrary) {
          const getDocumentations = getPath('documentations', state.selectedConfiguration, {
            featureName: state.selectedFeature?.featureName || '',
            stepName: state.selectedFeature?.stepName,
          });

          state.configurationChangeStack.push({
            typeOfChange: 'add',
            change: action.payload.control,
            featureType: 'UPLOAD_DOCUMENTS',
            changeList: [],
          });

          const findDocument = getDocumentations.find((x: any) => x.name === isDocumentFromLibrary);

          if (findDocument) {
            findDocument.documents.push(action.payload.control);
          }

          return;
        }

        getDocuments.documents[documentIndex] = action.payload.control;
      };

      if (action.payload.propertyType === 'document') {
        handleDocumentation();
      }

      if (action.payload.propertyType === 'documents') {
        handleDocument();
      }

      return state;
    },

    handleNewDocument: (state, action: PayloadAction<any>) => {
      if (!state.selectedFeature || !state.selectedConfiguration) throw new Error('No selected document');

      state.configurationChangeStack.push({
        typeOfChange: 'add',
        change: action.payload,
        // This one should be dynamic?
        featureType: 'UPLOAD_DOCUMENTS',
        changeList: [],
      });

      const getStep = getPath('steps', state.selectedConfiguration, {
        featureName: state.selectedFeature?.featureName,
        stepName: state.selectedFeature?.stepName,
      });

      action.payload.sequence = getStep.documentations[getStep.documentations.length - 1]?.sequence + 10 || 10;

      getStep.documentations.push(action.payload);

      return state;
    },

    handleAddDocumentToDocumentation: (state, action: PayloadAction<{ document: any; targetName: string }>) => {
      if (!state.selectedFeature || !state.selectedConfiguration) throw new Error('No selected document!');

      state.configurationChangeStack.push({
        typeOfChange: 'add',
        change: action.payload.document,
        featureType: 'UPLOAD_DOCUMENTS',
        changeList: [],
      });

      const getDocuments = getPath('documents', state.selectedConfiguration, {
        featureName: state.selectedFeature?.featureName,
        stepName: state.selectedFeature?.stepName,
        documentName: action.payload.targetName,
      });

      if (!getDocuments) throw new Error('Failed to find a document');

      getDocuments.documents.push(action.payload.document);

      return state;
    },
    // Global
    handleClientOrganizationDetails: (state, action: PayloadAction<ConfigurationDetails>) => {
      if (!state.selectedConfiguration) throw new Error('Missing configuration!');

      state.selectedConfiguration.clientOrganization = {
        ...state.selectedConfiguration.clientOrganization,
        name: action.payload.clientOrganization.name,
        id: action.payload.clientOrganization.id,
        displayName: action.payload.clientOrganization.displayName,
      };

      if (action.payload.candidateName) {
        state.selectedConfiguration.candidateName = action.payload.candidateName;
      }

      if (action.payload.recruiterName) {
        state.selectedConfiguration.recruiterName = action.payload.recruiterName;
      }

      if (action.payload.configurationId) {
        state.selectedConfiguration.configurationId = action.payload.configurationId;
      }

      return state;
    },
    handleTopLevelProperties: (state, action: PayloadAction<{ propertyName: string; propertyValue: any }>) => {
      (state.selectedConfiguration as any)[action.payload.propertyName] = action.payload.propertyValue;

      return state;
    },
    // Document for signature
    handleDocumentToSign: (state, action: PayloadAction<{ featureName: string; stepName: string; document: any }>) => {
      state.selectedFeature = action.payload;

      return state;
    },
    // Delete/add/reorder
    updateConfigurationEntity: (
      state,
      action: PayloadAction<{
        value: any;
        pathSegments: string[];
        modification: {
          type: 'delete' | 'change' | 'add';
          typeOfChange: string[];
          property: any;
        };
      }>,
    ) => {
      modifyNestedObject(state.selectedConfiguration, action.payload.pathSegments, action.payload.value);

      state.configurationChangeStack.push({
        typeOfChange: action.payload.modification.type,
        change: action.payload.modification.property,
        featureType: 'DOCUMENT_TO_SIGN',
        changeList: action.payload.modification.typeOfChange,
      });

      return state;
    },
    addNewDocumentForSignature: (state, action: PayloadAction<any>) => {
      if (!state.selectedFeature || !state.selectedConfiguration) throw new Error('No selected document');

      state.configurationChangeStack.push({
        typeOfChange: 'add',
        change: action.payload,
        featureType: 'DOCUMENT_TO_SIGN',
        changeList: [],
      });

      const getStep = getPath('steps', state.selectedConfiguration, {
        featureName: state.selectedFeature?.featureName,
        stepName: state.selectedFeature?.stepName,
      });

      action.payload.sequence =
        getStep.documentsForSignature[getStep.documentsForSignature.length - 1]?.sequence + 10 || 10;

      getStep.documentsForSignature.push(action.payload);

      return state;
    },
    // Translations
    addTranslationToConfiguration: (state, action: PayloadAction<{ key: string; translations: any }>) => {
      if (!state.selectedConfiguration) return state;

      const modifyForApi = {
        key: action.payload.key,
        translations: Object.keys(action.payload.translations).map((x: string) => {
          return {
            language: x,
            value: action.payload.translations[x],
          };
        }),
      };

      if (!state.selectedConfiguration?.__translation) {
        state.selectedConfiguration.__translation = [];
      }

      const indexOfExistingTranslation = state.selectedConfiguration.__translation?.findIndex(
        (x) => x.key === action.payload.key,
      );

      if (indexOfExistingTranslation >= 0) {
        state.selectedConfiguration.__translation[indexOfExistingTranslation].translations = modifyForApi.translations;
      } else {
        state.selectedConfiguration.__translation?.push(modifyForApi);
      }

      return state;
    },
    // Other
    handlePublishedStatus: (state, action: PayloadAction<boolean>) => {
      state.isPublished = action.payload;

      return state;
    },
    handleDocumentTarget: (state, action: PayloadAction<string | null>) => {
      state.documentTarget = action.payload;

      return state;
    },
    handleDisyDocumentName: (state, action: PayloadAction<{ documentName: string; disyDocumentName: string }>) => {
      if (!state.selectedConfiguration?.additionalConfigurations) return state;

      if (!state.selectedConfiguration.additionalConfigurations.selfServiceMetaData) {
        state.selectedConfiguration.additionalConfigurations.selfServiceMetaData = {};
      }

      state.selectedConfiguration.additionalConfigurations.selfServiceMetaData['disyDocuments'] = {
        ...state.selectedConfiguration.additionalConfigurations.selfServiceMetaData['disyDocuments'],
        [action.payload.documentName]: action.payload.disyDocumentName,
      };

      return state;
    },
  },
});

export const {
  addTranslationToConfiguration,
  handleNewPageControl,
  handlePublishedStatus,
  addNewDocumentForSignature,
  updateConfigurationEntity,
  handleDocumentToUploadPropertyModify,
  handleDocumentTarget,
  handleDisyDocumentName,
} = selfServiceSlice.actions;
