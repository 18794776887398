import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';

import classes from './Paginator.module.scss';

interface PaginatorProps {
  total: number;
  defaultPage?: number;
  perPage?: number;
  offsets?: number[];
  visiblePages?: number;
  showPrevNext?: boolean;
  showFirstLast?: boolean;
  onChange: (p: number) => any;
  onOffsetChange?: (p: number) => any;
}

export const Paginator: React.FC<PaginatorProps> = ({
  total,
  defaultPage,
  perPage,
  onChange,
  onOffsetChange,
  offsets = [8, 16, 32, 64, 128],
  visiblePages = 5,
  showPrevNext = false,
  showFirstLast = false,
}) => {
  const { t } = useTranslation(['candidate_recruiter']);
  const [currentPage, setCurrentPage] = useState(defaultPage || 1);
  const [pages, setPages] = useState<number[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [offset, setOffset] = useState(offsets[0]);

  const visiblePagesLn = visiblePages < 3 ? 3 : visiblePages;

  const handleOffset = !!onOffsetChange;

  useEffect(() => {
    if (!total || !perPage || offset === perPage) {
      return;
    }
    setOffset(perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, perPage]);

  const getVisiblePages = () => {
    let pageArr: number[] = [];
    for (let i = 0; i < Math.ceil(total / offset); i++) {
      pageArr.push(i + 1);
    }
    setTotalPages(pageArr.length);
    if (pageArr.length > visiblePagesLn) {
      const currIndex = pageArr.findIndex((p) => p === currentPage);
      let firstIndex = Math.ceil(currIndex - visiblePagesLn / 2);
      let lastIndex = Math.ceil(currIndex + visiblePagesLn / 2);
      if (firstIndex < 0) {
        firstIndex = 0;
        lastIndex = visiblePagesLn;
      }
      if (lastIndex > pageArr.length) {
        lastIndex = pageArr.length;
        firstIndex = lastIndex - visiblePagesLn;
      }
      pageArr = pageArr.slice(firstIndex, lastIndex);
    }
    return [...pageArr];
  };

  useEffect(() => {
    const pageArr = getVisiblePages();
    setPages([...pageArr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, visiblePagesLn, currentPage, total]);

  const navigate = (p: number) => {
    setCurrentPage(p);
    onChange(p);
  };

  const changeOffset = (newOffset: number) => {
    setOffset(newOffset);
    onOffsetChange?.(newOffset);
    if (currentPage !== 1) {
      navigate(1);
    }
  };

  return (
    <div className={classes.paginator}>
      {total > offset && (
        <div className={classes['paginator-pages']}>
          {showFirstLast && (
            <span
              className={
                classes['paginator-page'] + (currentPage === 1 ? ' ' + classes['paginator-page-disabled'] : '')
              }
              onClick={() => (currentPage === 1 ? null : navigate(1))}
            >
              ..
            </span>
          )}
          {showPrevNext && (
            <span
              className={
                classes['paginator-page'] + (currentPage === 1 ? ' ' + classes['paginator-page-disabled'] : '')
              }
              onClick={() => (currentPage === 1 ? null : navigate(currentPage - 1))}
            >
              .
            </span>
          )}
          {pages.map((page, i) => (
            <span
              className={
                classes['paginator-page'] + (page === currentPage ? ' ' + classes['paginator-page-selected'] : '')
              }
              key={`paginator-${i}`}
              onClick={() => (page === currentPage ? null : navigate(page))}
            >
              {page}
            </span>
          ))}
          {showPrevNext && (
            <span
              className={
                classes['paginator-page'] + (currentPage === totalPages ? ' ' + classes['paginator-page-disabled'] : '')
              }
              onClick={() => (currentPage === totalPages ? null : navigate(currentPage + 1))}
            >
              .
            </span>
          )}
          {showFirstLast && (
            <span
              className={
                classes['paginator-page'] + (currentPage === totalPages ? ' ' + classes['paginator-page-disabled'] : '')
              }
              onClick={() => (currentPage === totalPages ? null : navigate(totalPages))}
            >
              ..
            </span>
          )}
        </div>
      )}
      {handleOffset && (
        <Select
          classes={{ select: classes['paginator-offsets'], icon: classes['paginator-offsets-icon'] }}
          placeholder={t('candidate_recruiter:PAGINATION.ROWS_PER_PAGE')}
          name="paginatoroffset"
          value={`${offset}`}
          onChange={(e) => {
            changeOffset(parseInt(e.target.value, 10));
          }}
        >
          {offsets.map((x) => {
            return (
              <MenuItem key={`paginator-offset-${x}`} value={`${x}`}>
                {`${x}`}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </div>
  );
};
