import React from 'react';

interface Props {
  attributes: any;
  children: any;
  leaf: LeafModel;
}

interface LeafModel {
  bold: boolean;
  strikethrough: boolean;
  code: boolean;
  italic: boolean;
  underline: boolean;
  h1: string;
  h2: string;
  h3: string;
  p: string;
  [key: string]: boolean | string;
}

const Leaf: React.FC<Props> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.h1) {
    children = <h1>{children}</h1>;
  }

  if (leaf.h2) {
    children = <h2>{children}</h2>;
  }

  if (leaf.h3) {
    children = <h3>{children}</h3>;
  }

  if (leaf.p) {
    children = <p>{children}</p>;
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
