import React from 'react';
import { useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';

import { useFlowWrapper } from '../../hooks/useFlowWrapper';
import { mandatoryCondition, renderCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { OOControlModelInterface } from '../../interfaces';
import { OOControlModel } from '../../models/ControlModel';
import { OOFormControl } from '../FormControl';
import { OOAccordion } from './Accordion/OOAccordion';

export type AccordionWithAddButtonProps = {
  controlModel: OOControlModelInterface;
  isOpen?: boolean;
};

export const AccordionWithAddButton: React.FC<AccordionWithAddButtonProps> = ({ controlModel, isOpen }) => {
  const { t } = useT('entry', 'approval', 'documents');
  const flowWrapper = useFlowWrapper();
  const formik = useFormikContext<Record<string, any>>();
  const createNewControls = (controls: any) =>
    controls.map((item: any) => {
      const ooControl = new OOControlModel({
        ...item,
        name: OOControlModel.generateNameForSubControl(
          controlModel.name,
          controlModel.subControls?.length ?? 0,
          item.originalName,
        ),
        value: flowWrapper?.getDefaultValue(item.type),
      });

      ooControl.parentFormIndex = controlModel.subControls?.length ?? 0;
      ooControl.parentFormName = item.parentFormName;
      return ooControl;
    });

  const handleDelete = (index: number) => {
    if (Array.isArray(controlModel.subControls)) {
      const newSubControls = [...controlModel.subControls];
      newSubControls.splice(index, 1);

      if (newSubControls.length === 0) {
        const newItem = JSON.parse(JSON.stringify(controlModel.subControls[0] || []));
        const ooControlModels = createNewControls(newItem);
        newSubControls.push(ooControlModels);
      }

      controlModel.subControls = newSubControls as Array<Array<OOControlModel>>;

      const prefix = `ARRAY_ELEMENT:${controlModel.name}:`;
      const newValues: Record<string, any> = {};

      Object.keys(formik.values).forEach((key) => {
        if (key.startsWith(prefix)) {
          const parts = key.split(':');
          const currentIndex = parseInt(parts[2], 10);
          if (currentIndex === index) {
            delete newValues[key];
          } else {
            newValues[key] = formik.values[key];
          }
        } else {
          newValues[key] = formik.values[key];
        }
      });

      const SKIP_FORWARD = true;
      const payload = { ...newValues, SKIP_FORWARD };
      formik.setValues(payload);
      formik.handleSubmit();
    }
  };

  const handleSave = () => {
    const SKIP_FORWARD = true;
    const newValues = { ...formik.values, SKIP_FORWARD };
    formik.setValues(newValues);
    formik.handleSubmit();
  };

  const handleAdd = () => {
    if (Array.isArray(controlModel.subControls)) {
      const newItem = JSON.parse(JSON.stringify(controlModel.subControls[0] || []));
      const ooControlModels = createNewControls(newItem);
      controlModel.subControls.push(ooControlModels);

      const newValues: Record<string, any> = {};
      ooControlModels.forEach((item: any) => {
        newValues[item.name as string] = item.value;
      });

      if (flowWrapper) {
        formik.setValues({
          ...formik.values,
          ...newValues,
        });
      }
    }
  };

  const showAddButton =
    controlModel.maxSubControls && controlModel.subControls
      ? controlModel.subControls.length < controlModel.maxSubControls
      : true;

  return (
    <div className="sub-controls-wrapper" style={{ marginBottom: 20 }}>
      {(controlModel.subControls as Array<Array<OOControlModel>>).map((arrayControls, index) => (
        <OOAccordion controlModel={controlModel} key={controlModel.name + index} defaultShow={isOpen ?? index === 0}>
          <div key={index} className="sub-controls" style={{ marginBottom: 16 }}>
            {arrayControls
              .filter((c) => renderCondition(c, flowWrapper, formik.values))
              .map((control: OOControlModel) => {
                control.isMandatory = mandatoryCondition(control, flowWrapper, formik.values) ?? control.isMandatory;
                return control;
              })
              .map((subControl: OOControlModel) => (
                <Grid key={subControl.name} item xs={12} style={{ minWidth: '100%', marginTop: '12px' }}>
                  <OOFormControl key={subControl.name} control={subControl} otherFormControls={arrayControls} />
                </Grid>
              ))}
          </div>
          <div style={{ display: 'flex', gap: 16 }}>
            <Button variant="outlined" onClick={() => handleDelete(index)}>
              {t('candidate_recruiter:GENERAL.GENERIC.delete')}
            </Button>
            <Button variant="outlined" onClick={handleSave}>
              {t('candidate_recruiter:GENERAL.GENERIC.save')}
            </Button>
          </div>
        </OOAccordion>
      ))}
      {showAddButton && (
        <Grid key={controlModel.name} item xs={12} style={{ minWidth: '100%' }}>
          <Button endIcon={<AddIcon />} variant="text" style={{ color: '#4a89a7', padding: 16 }} onClick={handleAdd}>
            {t('candidate_recruiter:GENERAL.GENERIC.add')}
          </Button>
        </Grid>
      )}
    </div>
  );
};
