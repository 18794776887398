import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'uuid';
import { useInjection } from '@context/inversify-context-provider';
import { Utils } from '@helpers/utils';
import { useGetOnboardingsWithRelationsMultiple, useGetUser } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { IntegrationStateFaq } from '@models/integrationStateFaq.model';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { snackbarSlice } from '@store/slices/snackbar';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../libs/aamBackendApi';

interface IntegrationStateModalProps {
  integrationState: any | null;
  isActive: boolean;
  closeModal: () => void;
}

const excludeIntegrationKeysArray = [
  'GDSPCreateCandidateCommand',
  'GDSPUpdateCandidateCommand',
  'cronJobIntegrationErrorStatus',
];

const IntegrationStateModal: React.FC<IntegrationStateModalProps> = ({ integrationState, isActive, closeModal }) => {
  const [commandInfo, setCommandInfo] = useState<any>(null);
  const [retriggeringInProgress, setRetriggeringInProgress] = useState<boolean>(false);
  const [integrationStateFaq, setIntegrationStateFaq] = useState<IntegrationStateFaq[] | null>(null);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { t } = useTranslation(['recruiter', 'integration-state-faq']);
  const aamBackendApi = useInjection(AamBackendApi);
  const dispatch = useDispatch();

  const { data: user } = useGetUser(tenantId, userId, { refetchOnMount: false });
  const { data: onboarding } = useGetOnboardingsWithRelationsMultiple(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
  );

  const { refetch: getIntegrationStateFaq } = useQuery({
    queryKey: ['getIntegrationStateFaq', tenantId],
    queryFn: () => aamBackendApi.getIntegrationStateFaq(tenantId),
    refetchOnMount: true,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      const integrationState = JSON.stringify(onboarding?.integrationState);
      const explanations: IntegrationStateFaq[] = [];

      data?.forEach((x) => {
        const replaceSpecialSymbols = (word: string) => {
          if (!word) return '';

          return word.replace(/\[|\]|\\|\//g, '');
        };

        const phraseWithoutChars = replaceSpecialSymbols(x.phrase);
        if (new RegExp(`\\b${phraseWithoutChars}\\b`).test(replaceSpecialSymbols(integrationState))) {
          explanations.push(x);
        }
      });

      setIntegrationStateFaq(explanations);
    },
  });

  useEffect(() => {
    if (onboarding) {
      getIntegrationStateFaq();
    }
  }, [onboarding, getIntegrationStateFaq]);

  const integrationError = () => {
    const userIntegrationState = onboarding?.integrationState ?? {};
    return Object.values(userIntegrationState).some((val) => val === 'error');
  };

  const retriggerIntegration = async () => {
    if (!user || !onboarding || !onboarding?.applicationId || !user.id) return;
    try {
      setRetriggeringInProgress(true);
      await aamBackendApi.retriggerIntegration(
        onboarding.clientOrganizationId,
        onboarding.configurationId,
        user.id,
        onboarding.applicationId,
      );
      dispatch(
        snackbarSlice.actions.showSuccess({
          message: t('recruiter:GENERAL.CANDIDATE_DETAILS.retriggerIntegrationSuccess'),
        }),
      );
    } catch (err) {
      setRetriggeringInProgress(false);
      Utils.consoleLog(err.message);
      dispatch(
        snackbarSlice.actions.showError({
          message: t('recruiter:GENERAL.CANDIDATE_DETAILS.retriggerIntegrationError'),
        }),
      );
    }
  };

  const getIntegrationCommandInfo = async (commandId: string) => {
    const integrationCommandInfo = await aamBackendApi.getIntegrationCommandInfo(commandId);
    if (integrationCommandInfo) {
      setCommandInfo(integrationCommandInfo);
    }
  };

  return (
    <Dialog open={isActive} onClose={closeModal}>
      <Box>
        <DialogTitle>
          <p className="tag-ds  h4">{t('recruiter:GENERAL.CANDIDATE_DETAILS.integrationState')}</p>
        </DialogTitle>
        <DialogContent>
          {Object.keys(integrationState || {}).map((key, index) => {
            let integrationStateProp = integrationState?.[key];
            try {
              integrationStateProp = JSON.parse(integrationStateProp);
            } catch (err) {
              // nothing
            }
            const isClickable = validate(integrationStateProp?.toString() || '');
            const isObject = typeof integrationStateProp === 'object' && integrationStateProp !== null;
            return (
              <Box display="flex" flexWrap="wrap" key={index} alignItems="center">
                <p className="tag-ds  medium" style={{ margin: 0 }}>
                  "{key}":
                </p>
                <pre
                  className={isClickable ? 'integration-state-prop--active' : ''}
                  onClick={isClickable ? () => getIntegrationCommandInfo(integrationStateProp) : () => true}
                  style={{ margin: 0 }}
                >
                  {isObject ? (
                    <>
                      <button onClick={() => setCommandInfo([integrationStateProp])}>
                        {t('recruiter:GENERAL.CANDIDATE_DETAILS.Details')}
                      </button>
                      <br />
                    </>
                  ) : (
                    <p className="tag-ds  medium" style={{ margin: 0 }}>
                      {integrationStateProp?.toString()}
                    </p>
                  )}
                </pre>
                {isClickable && (
                  <button onClick={() => getIntegrationCommandInfo(integrationStateProp)}>
                    {t('recruiter:GENERAL.CANDIDATE_DETAILS.Details')}
                  </button>
                )}
              </Box>
            );
          })}
          <Box display="flex" flexWrap="wrap" key={Object.keys(integrationState || {}).length + 1} alignItems="center">
            {integrationError() && (
              <Box>
                {integrationStateFaq?.map(({ explanation }, i) => (
                  <Typography key={i} className="feature-candidates-profile__integration-state-faq">
                    <pre style={{ whiteSpace: 'pre-wrap' }}>* {t(`integration-state-faq:${explanation}`)}</pre>
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <div className="tag-ds ">
            <button
              disabled={
                retriggeringInProgress ||
                !Object.keys(integrationState || {}).some(
                  (key) => integrationState[key] === 'error' && !excludeIntegrationKeysArray.includes(key),
                )
              }
              onClick={retriggerIntegration}
            >
              {t('recruiter:GENERAL.CANDIDATE_DETAILS.retriggerIntegration')}
            </button>
          </div>
        </DialogActions>
        {commandInfo && (
          <Dialog open={!!commandInfo} onClose={() => setCommandInfo(null)}>
            <DialogTitle>
              <Box>
                <Typography variant="h5">{commandInfo?.id}</Typography>
                <Box onClick={() => setCommandInfo(null)}>
                  <CloseIcon />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              {commandInfo &&
                commandInfo.map((data: any, index: number) => (
                  <Box my={1} key={index}>
                    <Typography variant="h5">{data.topic || ''}</Typography>
                    <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(data, null, 2)}</pre>
                  </Box>
                ))}
            </DialogContent>
          </Dialog>
        )}
      </Box>
    </Dialog>
  );
};

export default IntegrationStateModal;
