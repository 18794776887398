import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { RootState } from '@store/rootReducer';

import {
  adminDashboardFilters as InitialFilterValues,
  oneOnboardingSlice,
} from '../../../../store/slices/oneOnboarding.slice';
import { fieldToTranslationKey } from './DashboardFiltersConfigs';
import { FilterAction } from './DashboardFilterSelect';

interface DashboardFiltersChipOverviewProps {
  dispatch: React.Dispatch<FilterAction>;
  resetPageNumber: () => void;
}
const DashboardFiltersChipOverview: FC<DashboardFiltersChipOverviewProps> = ({ dispatch, resetPageNumber }) => {
  const { adminDashboardFilters } = useSelector((state: RootState) => state.oneOnboarding);
  const { t, i18n } = useTranslation(['recruiter', 'control-items']);
  const storeDispatch = useDispatch();

  const removeFilter = (filterName: string) => {
    const payload = { ...adminDashboardFilters };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    payload[filterName] = (InitialFilterValues as any)[filterName];
    storeDispatch(oneOnboardingSlice.actions.removeFilter(filterName));
    dispatch({ type: 'REMOVE_FILTER', payload: filterName });
  };

  const isFilterActive = Object.keys(adminDashboardFilters).filter((x: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Array.isArray(adminDashboardFilters[x]) && adminDashboardFilters[x].length > 0) {
      return x;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (adminDashboardFilters[x] && !Array.isArray(adminDashboardFilters[x])) {
      return x;
    }
    return false;
  });

  return (
    <Box mt={5}>
      <div className="tag-ds " style={{ display: 'flex' }}>
        {isFilterActive.map((x) => {
          const filterCount = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (Array.isArray(adminDashboardFilters[x])) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return `+ ${adminDashboardFilters[x].length}`;
            }
          };

          return (
            <button key={x} className="chip active" style={{ marginRight: 16 }} onClick={() => removeFilter(x)}>
              {i18n.exists(`GENERAL.CANDIDATE_DETAILS.${fieldToTranslationKey[x]}`, { ns: 'recruiter' })
                ? t(`recruiter:GENERAL.CANDIDATE_DETAILS.${fieldToTranslationKey[x]}`)
                : i18n.exists(`GENERAL.CANDIDATES_OVERVIEW.${fieldToTranslationKey[x]}`, { ns: 'recruiter' })
                ? t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.${fieldToTranslationKey[x]}`)
                : t(`recruiter:GENERAL.CANDIDATE_DELETION.${fieldToTranslationKey[x]}`)}{' '}
              {filterCount()}
              <span className="material-icons right">close</span>
            </button>
          );
        })}
        {isFilterActive.length > 0 && (
          <button
            className="button_small"
            onClick={() => {
              resetPageNumber();
              storeDispatch(oneOnboardingSlice.actions.resetAdminDashboardFilters());
              dispatch({ type: 'SYNC_FILTERS', payload: InitialFilterValues });
            }}
          >
            {t('recruiter:GENERAL.CANDIDATES_OVERVIEW.clearAllFilters')}
            <span className="material-icons right">delete</span>
          </button>
        )}
      </div>
    </Box>
  );
};

export default DashboardFiltersChipOverview;
