import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '@context/inversify-context-provider';
import { useAllConfigurationsForTenant } from '@hooks/apiHooks';
import { useTenant } from '@hooks/useComponentMapping';
import { ClientOrganizationModel } from '@models/clientOrganization.model';
import { ExpandMore } from '@mui/icons-material';
import { Collapse, FormHelperText, MenuItem, Select, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { AdminSimpleButton } from './AdminSimpleButton';

import styles from './AdminImport.module.scss';
interface AdminDeleteExistingClientProps {
  strId: string;
  className: string;
  assetBoxes: string[];
  toggleAssetBox: (strId: string) => void;
  clientOrganizations: ClientOrganizationModel[];
}

export const AdminDeleteExistingClient: FC<AdminDeleteExistingClientProps> = ({
  strId,
  className,
  assetBoxes,
  clientOrganizations,
  toggleAssetBox,
}) => {
  const tenantId = useTenant();
  const aamBackendApi = useInjection(AamBackendApi);
  const { t } = useTranslation(['recruiter']);
  const { refetch: clientOrganizationsRefetch } = useAllConfigurationsForTenant(tenantId);
  const { refetch: exportTranslationsRefetch } = useQuery({
    queryKey: ['exportTranslations', tenantId],
    queryFn: () => aamBackendApi.exportTranslations(tenantId as number, 'yes'),
  });
  const assetBoxOpen = assetBoxes.includes(strId);
  const [clientId, setClientId] = useState(
    clientOrganizations && clientOrganizations.length > 0 ? clientOrganizations[0].id : '',
  );

  const isConfigurationExists = useMemo(() => {
    const configuration = clientOrganizations?.find((c: ClientOrganizationModel) => c.id === clientId);
    return !!(configuration && configuration.selectedConfigurations && configuration.selectedConfigurations.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  return (
    <div className={`${styles.assetHolder}${className ? ` ${className}` : ''}`}>
      <div className={`${styles.flexHolder} ${styles.assetHeader}`} onClick={() => toggleAssetBox(strId)}>
        <Typography variant="h4">{t('GENERAL.CONFIGURATION.DeleteExistingClient')}</Typography>
        <ExpandMore style={!assetBoxOpen ? { transform: 'rotate(180deg)' } : {}} />
      </div>
      <Collapse in={assetBoxOpen} timeout="auto" unmountOnExit>
        <div className={styles.assetBox} style={{ display: 'grid', gap: '0.5rem' }}>
          <div>
            <span className={styles.textInput}>
              <Select
                placeholder="Existing client"
                value={clientId}
                style={{ width: 300 }}
                onChange={(e) => setClientId(e.target.value as string)}
              >
                {clientOrganizations?.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
              </Select>
            </span>
          </div>
          <div>
            <AdminSimpleButton
              label={t('GENERAL.CONFIGURATION.DeleteClient')}
              disabled={isConfigurationExists}
              onClick={async () => {
                await aamBackendApi.deleteClientOrganization(clientId);
                clientOrganizationsRefetch();
                exportTranslationsRefetch();
              }}
            />
          </div>
          {isConfigurationExists && (
            <FormHelperText error={true}>{t(`GENERAL.CONFIGURATION.ConfigurationExisted`)}</FormHelperText>
          )}
        </div>
      </Collapse>
    </div>
  );
};
