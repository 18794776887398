import { FormikContextType } from 'formik';

import { HourCalcType, ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class WorkingHoursCalculator implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;
  multiplikator = 4.333428571;
  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls =
      formControls?.filter((fc) =>
        ['CALCULATE_BY', 'HOURS_PER_DAY', 'HOURS_PER_WEEK', 'HOURS_PER_MONTH', 'DAYS_PER_WEEK'].includes(fc.name),
      ) ?? [];
    this.allControls = formControls ?? [];
    this.formik = formik;
  }

  private formatDecimals(num: number, decimalPoints: number) {
    return parseFloat(num.toFixed(decimalPoints));
  }

  getNumericValue = (control: OOControlModelInterface): number => {
    const value = control.value;
    const numValue = !Number.isNaN(value) ? Number(value) : 0;
    if (control.min && control.min > numValue) {
      return control.min;
    }

    if (control.max && control.max < numValue) {
      return control.max;
    }

    return numValue;
  };

  onChange = (event: any) => {
    this.control.value = event.target.value;

    const calcByControl = this.relevantControls.find((dc) => ['CALCULATE_BY'].includes(dc.name));
    const hpd = this.relevantControls.find((dc) => ['HOURS_PER_DAY'].includes(dc.name));
    const hpw = this.relevantControls.find((dc) => ['HOURS_PER_WEEK'].includes(dc.name));
    const hpm = this.relevantControls.find((dc) => ['HOURS_PER_MONTH'].includes(dc.name));
    const dpw = this.relevantControls.find((dc) => ['DAYS_PER_WEEK'].includes(dc.name));

    if (!calcByControl || !hpd || !hpw || !hpm || !dpw) {
      return;
    }

    dpw.isEditableRecruiter = true;

    if (event.target.name === 'CALCULATE_BY') {
      hpd.isEditableRecruiter = event.target.value === HourCalcType.hoursPerDay;
      hpw.isEditableRecruiter = event.target.value === HourCalcType.hoursPerWeek;
      hpm.isEditableRecruiter = event.target.value === HourCalcType.hoursPerMonth;
    }

    if (calcByControl.value === HourCalcType.hoursPerDay) {
      if (dpw.value && hpd.value) {
        hpw.value = this.formatDecimals(this.getNumericValue(dpw) * this.getNumericValue(hpd), 2);
        hpm.value = this.formatDecimals(this.getNumericValue(dpw) * this.getNumericValue(hpd) * this.multiplikator, 2);
      }
    } else if (calcByControl.value === HourCalcType.hoursPerWeek) {
      if (dpw.value && hpw.value) {
        hpd.value =
          this.getNumericValue(dpw) !== 0
            ? this.formatDecimals(this.getNumericValue(hpw) / this.getNumericValue(dpw), 2)
            : 0;
        hpm.value = this.formatDecimals(this.getNumericValue(hpw) * this.multiplikator, 2);
      }
    } else if (calcByControl.value === HourCalcType.hoursPerMonth) {
      if (hpm.value && dpw.value) {
        hpd.value =
          hpw.value !== 0
            ? this.formatDecimals(this.getNumericValue(hpm) / this.multiplikator / this.getNumericValue(dpw), 2)
            : 0;
        hpw.value = this.formatDecimals(this.getNumericValue(hpm) / this.multiplikator, 2);
      }
    }

    this.formik.validateForm();
  };
}
