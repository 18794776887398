import React from 'react';
import { QuickFilterModel } from '@models/quick-filter.model';
import { Grid } from '@mui/material';

import { QuickFilterItem } from './QuickFilterItem';

interface Props {
  // to be deleted
  items: QuickFilterModel[];
  quickFilter: any;
  onSelectQuickFilter: (QuickFilterModel: any) => void;
  onCloseQuickFilter: () => void;
  resetPageNumber: () => void;
}

const QuickFilter: React.FC<Props> = ({
  items,
  quickFilter,
  onSelectQuickFilter,
  onCloseQuickFilter,
  resetPageNumber,
}) => {
  return (
    <Grid item container className="feature-dashboard__quick-filter-grid">
      {items.map((quickFilterItem, index) => (
        <QuickFilterItem
          selected={quickFilter?.key === quickFilterItem?.key}
          onClick={() => {
            onSelectQuickFilter(quickFilterItem);
            resetPageNumber();
          }}
          onClose={() => onCloseQuickFilter()}
          item={quickFilterItem}
          key={index}
        />
      ))}
    </Grid>
  );
};

export default QuickFilter;
