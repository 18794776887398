import { useState } from 'react';

export const useSorting = () => {
  const [order, setOrder] = useState<string>('asc');
  const [orderBy, setOrderBy] = useState<string>(`"UserProfile"."firstName"`);

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
  };
};
