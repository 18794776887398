import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertShortToFullLanguageName } from '@features/selfService/helpers/translationHelper';
import { RootState } from '@store/rootReducer';

interface Props {
  fieldName: string;
  description: string;
  setFieldValue: any;
  fieldPropsMeta: any;
  value: any;
  callback?: () => void;
  disabled?: boolean;
}

const TranslationInput: React.FC<Props> = ({
  fieldName,
  description,
  setFieldValue,
  fieldPropsMeta,
  value,
  callback,
  disabled,
}) => {
  const [supportedLanguages, setSupportedLanguages] = useState<any[] | null>(null);

  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  useEffect(() => {
    const availableLanguages = selectedConfiguration?.languages.supported;

    if (availableLanguages) {
      setSupportedLanguages(availableLanguages);
    }
  }, [selectedConfiguration?.languages.supported]);

  const isError = fieldPropsMeta.error && fieldPropsMeta.touched;

  return (
    <div>
      {supportedLanguages?.map((language, i) => {
        return (
          <div key={i} style={{ paddingTop: i !== 0 ? 24 : 0, width: 320 }}>
            <label className="caption">
              {description} {convertShortToFullLanguageName(language)}
            </label>
            <input
              disabled={disabled}
              type="text"
              value={value?.[language] ?? ''}
              onChange={(e) => {
                const payload = { ...value, [language]: e.target.value };

                for (const lang of supportedLanguages) {
                  if (!(lang in payload)) {
                    payload[lang] = undefined;
                  }
                }

                setFieldValue(fieldName, payload);

                callback?.();
              }}
              className={isError ? 'error' : ''}
            />
            {isError && (
              <p className="input-helper-text" style={{ margin: 0 }}>
                {fieldPropsMeta.error}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TranslationInput;
