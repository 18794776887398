import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '@context/inversify-context-provider';
import { DocumentCommand, OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { OOSteps, OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OODocumentationModel } from '@features/oneOnboarding/models/DocumentationModel';
import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { UserFlowModel } from '@features/oneOnboarding/models/UserFlow';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { useGetUserFlow } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../../libs/aamBackendApi';
import AdminHeader from '../../../../components/AdminHeader/AdminHeader';
import PreviewDocument from './PreviewDocument';
import UploadDocument from './UploadDocument';

import styles from './DocumentPages.module.scss';

const getStep = (flow: OOFlowWrapper, stepName: OOSteps | OOStepsEnum | undefined) =>
  flow.steps.filter((step) => step.name === stepName);

const isCurrentStepCompleted = (flow: OOFlowWrapper, step: OOStepModel[], userFlowStep: OOStepModel[]) => {
  const csIndex = flow.steps.indexOf(step[0]);
  const userFlowStepIndex = flow.steps.indexOf(userFlowStep[0]);
  return userFlowStepIndex > csIndex;
};

const getValidationStep = (flow: OOFlowWrapper, step: OOStepModel[]) => {
  const csIndex = flow.steps.indexOf(step[0]);
  return flow.steps.slice(csIndex).find((remainingStep) => remainingStep.name.toLowerCase().includes('validation'));
};
const isVisible = (doc: OODocumentationModel): boolean => OOFlowWrapper.isRecruiter() || doc?.uploadableByCandidate;

const filterMissingDocumentationForUpload = (documentation: OODocumentationModel[]) =>
  documentation
    .filter((x) => isVisible(x))
    .filter((documentation) => {
      const isMandatory = documentation.isMandatory;
      if (isMandatory && documentation.documents.length === 1) {
        return !documentation.documents[0].userDocument;
      } else {
        return documentation.documents.every(
          (document) => isMandatory && !document.userDocument && document.isMandatory,
        );
      }
    });

const checkOptionalDocumentations = (step: OOStepModel) => {
  if (step.minOptionalDocumentations) {
    const numberOfUploadedOptionalDocumentations = step.documentations
      .filter((x) => isVisible(x))
      .filter((documentation: OODocumentationModel) => {
        const isMandatory = documentation.isMandatory;
        return documentation.documents.some((document: OODocumentModel) => !isMandatory && document.userDocument);
      }).length;
    if (numberOfUploadedOptionalDocumentations >= step.minOptionalDocumentations) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const RenderDocumentPage: FC = () => {
  const [hasValidationStep, setHasValidationStep] = useState<boolean>(true);
  const [isCompleteStepActive, setIsCompleteStepActive] = useState<boolean>(false);
  const [conditionsForCompleteStep, setConditionsForCompleteStep] = useState<any>({
    missingDocumentationForUpload: [],
    allUploadedDocsVerified: false,
    missingOptionalDocumentations: true,
  });
  const [documentations, setDocumentations] = useState<OODocumentationModel[] | null>(null);
  const [prevSelectedDocumentNames, setPrevSelectedDocumentNames] = useState<string[]>([]);

  const { t } = useTranslation(['documents']);
  const {
    document,
    setDocument,
    setDocumentationModel,
    command,
    setCommand,
    step,
    setCompleteStepAvailable,
  } = useContext(OODocumentContext);
  const aamBackendApi = useInjection(AamBackendApi);
  const queryClient = useQueryClient();
  useEffect(() => {
    setDocument(undefined);
    setCommand(undefined);
  }, [setCommand, setDocument]);

  useEffect(() => {
    if (command) {
      window.document.body.style.overflow = 'hidden';
    }

    return () => {
      window.document.body.style.overflow = 'visible';
    };
  }, [command]);

  const stepName: OOSteps | undefined = step?.name;

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();

  const { refetch: refetchUserOOFlowDocumentUpload } = useGetUserFlow(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    true,
    handleUserFlowUpdate,
  );

  function handleUserFlowUpdate(userFlow: UserFlowModel) {
    const flow = OOFlowWrapper.create(userFlow?.selectedConfiguration?.flow);
    const currentStep = userFlow.currentStep;
    const step = getStep(flow, stepName);
    const userFlowStep = getStep(flow, currentStep);

    const isStepAlreadyCompleted = isCurrentStepCompleted(flow, step, userFlowStep);
    const validationStep = getValidationStep(flow, step);

    updateStateWithValidationStep(validationStep, currentStep);
    let documentations = step[0]?.documentations || [];
    const documentsUpload2Step = flow.getDocumentStep(OOStepsEnum.documentUploadTwo)!;
    if (currentStep === OOStepsEnum.documentValidation && documentsUpload2Step?.uploadEarly) {
      documentations = documentations.concat(
        documentsUpload2Step.documentations
          .filter((item) => item.documents.some((d) => d.userDocument))
          .map((item) => ({
            ...item,
            uploadEarly: true,
            isMandatory: false,
          })),
      );
    }
    setPrevSelectedDocumentNames([]);
    const missingDocumentationForUpload = filterMissingDocumentationForUpload(documentations);

    const missingOptionalDocumentations = checkOptionalDocumentations(step[0]);

    setConditionsForCompleteStep((state: any) => ({
      ...state,
      missingDocumentationForUpload,
      missingOptionalDocumentations,
    }));
    setDocumentations(documentations);

    if (hasValidationStep) {
      const isValidationStepAlreadyCompleted = validationStep
        ? isCurrentStepCompleted(flow, [validationStep], userFlowStep)
        : true;
      handleValidationStepActions(
        documentations,
        missingDocumentationForUpload,
        isStepAlreadyCompleted,
        isValidationStepAlreadyCompleted,
      );
    }
  }

  function updateStateWithValidationStep(validationStep: OOStepModel | undefined, currentStep: OOSteps | OOStepsEnum) {
    setIsCompleteStepActive(currentStep === validationStep?.name);
    setHasValidationStep(!!validationStep);
  }

  async function handleValidationStepActions(
    documentation: OODocumentationModel[],
    missingDocumentation: OODocumentationModel[],
    isStepCompleted: boolean,
    isValidationStepAlreadyCompleted: boolean,
  ) {
    const visibleDocs = documentation.filter((x) => isVisible(x));
    const mandatoryVisibleDocs = visibleDocs.filter((d) => d.isMandatory);

    const allUploadedDocsVerified = mandatoryVisibleDocs
      .flatMap((d) => d.documents)
      .filter((d) => d.userDocument)
      .map((d) => d.userDocument?.additionalData?.status)
      .every((s) => s === OnboardingDocumentStatus.VERIFIED);

    setConditionsForCompleteStep((state: any) => ({ ...state, allUploadedDocsVerified }));

    if (missingDocumentation.length === 0 && !isStepCompleted && allUploadedDocsVerified) {
      await completeStepActions();
    } else if (isStepCompleted) {
      setIsCompleteStepActive(!isValidationStepAlreadyCompleted);
    }
  }

  async function completeStepActions() {
    await aamBackendApi.ooAdminCompleteStepForUser(
      clientOrganizationId,
      configurationId,
      userId,
      { stepName },
      applicationId,
    );

    queryClient.refetchQueries(['getUserOOFlow']);
    queryClient.refetchQueries(['getUserFlow']);
    queryClient.refetchQueries(['getUserOOFlowDocumentUpload']);
    queryClient.invalidateQueries(['getOnboardingChecked', clientOrganizationId, configurationId, userId]);
  }

  useEffect((): any => {
    (async () => {
      await refetchUserOOFlowDocumentUpload();
    })();
  }, [stepName, refetchUserOOFlowDocumentUpload]);

  useEffect(() => {
    const allDocsUploaded = conditionsForCompleteStep.missingDocumentationForUpload.length === 0;
    const areAllUploadedDocsVerified = conditionsForCompleteStep.allUploadedDocsVerified;
    setCompleteStepAvailable(
      isCompleteStepActive &&
        allDocsUploaded &&
        areAllUploadedDocsVerified &&
        conditionsForCompleteStep.missingOptionalDocumentations,
    );
  }, [
    conditionsForCompleteStep.missingDocumentationForUpload,
    conditionsForCompleteStep.allUploadedDocsVerified,
    isCompleteStepActive,
    setCompleteStepAvailable,
    conditionsForCompleteStep.missingOptionalDocumentations,
  ]);

  const changeToNextStep = () => {
    const allUploadedDocuments: OODocumentModel[] =
      documentations?.flatMap((d) => d.documents).filter((doc) => doc.userDocument) || [];

    const newSelectedDocument = allUploadedDocuments.find((doc) => {
      const isReviewOrUndefinedStatus =
        !doc.userDocument?.additionalData?.status ||
        doc.userDocument?.additionalData?.status === OnboardingDocumentStatus.REVIEW;
      const isNotCurrentDocument = doc.name !== document?.name;
      const isNotPreviouslySelected = !prevSelectedDocumentNames.includes(doc.name);

      return isReviewOrUndefinedStatus && isNotCurrentDocument && isNotPreviouslySelected;
    });

    const newSelectedDocumentation = documentations?.find((item) =>
      item.documents?.find((d) => d.name === newSelectedDocument?.name),
    );

    setPrevSelectedDocumentNames([...prevSelectedDocumentNames, document?.name ?? '']);
    setDocument(newSelectedDocument ?? undefined);
    setDocumentationModel(newSelectedDocumentation ?? undefined);
  };

  const renderScreen = {
    [DocumentCommand.VIEW]: (
      <PreviewDocument changeToNextDocument={changeToNextStep} hasValidationStep={hasValidationStep} />
    ),
    [DocumentCommand.UPLOAD]: <UploadDocument hasValidationStep={hasValidationStep} />,
  };

  return (
    <div>
      {command && (
        <div className={styles.renderDocumentContainer}>
          <AdminHeader
            onBack={() => {
              setDocument(undefined);
              setCommand(undefined);
            }}
            pageName={t(`documents:${document?.label}`)}
            customStyle={{ paddingLeft: 32 }}
          />
          {renderScreen[command]}
        </div>
      )}
    </div>
  );
};
