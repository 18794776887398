import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import {
  useFillPageWithData,
  useGenerateClientAndJourneyList,
  useRegionsAndBranches,
} from '@features/oneOnboardingAdmin/pages/UserPermissions/userPermissionsHooks';
import { useAllForDashboard } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { CircularProgress, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import Notification, { useNotification } from '../../../../components/admin/Notification/Notification';
import { AamBackendApi } from '../../../../libs/aamBackendApi';
import NestedListSelector, { ListType } from './components/NestedListSelector';
import UserConfigurationPanel from './components/UserConfigurationPanel';

import styles from './UserPermissions.module.scss';

export type CheckedJourneysAndClients = {
  clientId: string;
  journeyId: string;
};

export type CheckedRegionsAndBranches = {
  regionIds: string[];
  branchIds: string[];
};

const UserPermissionDetails: React.VoidFunctionComponent = () => {
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { userId } = useDecodedParams();

  const [checkedClientsAndJourneys, setCheckedClientsAndJourneys] = useState<CheckedJourneysAndClients[]>([]);
  const [checkedRegionsAndBranches, setCheckedRegionsAndBranches] = useState<CheckedRegionsAndBranches>({
    regionIds: [],
    branchIds: [],
  });
  const [masterCheck, setMasterCheck] = useState<{ [key in ListType]: boolean }>({
    [ListType.CLIENTS_AND_JOURNEYS]: false,
    [ListType.REGIONS_AND_BRANCHES]: false,
  });
  const [collapsedClients, setCollapsedClients] = useState<string[]>([]);
  const [collapsedRegions, setCollapsedRegions] = useState<string[]>([]);

  const aamBackendApi = useInjection(AamBackendApi);
  const { isActive, notificationType, notificationDetails, triggerNotification, closeNotification } = useNotification();
  const { t } = useTranslation(['recruiter']);

  const { data, isInitialLoading: allForDashboardLoading } = useAllForDashboard(tenantId);
  const clientAndJourneys = useGenerateClientAndJourneyList(data);

  const { regionsAndBranches, isRegionsLoading } = useRegionsAndBranches(tenantId, triggerNotification, t);

  const { userPermissionData, setUserPermissionData } = useFillPageWithData(
    tenantId,
    setCheckedRegionsAndBranches,
    setMasterCheck,
    setCheckedClientsAndJourneys,
  );

  const handleJourneyAndClientCheck = (clientId: string, journeyId: string | null) => {
    const checkClient = checkedClientsAndJourneys.findIndex((x) => x.clientId === clientId);
    const checkClientAndJourney = checkedClientsAndJourneys.findIndex(
      (x) => x.clientId === clientId && x.journeyId === journeyId,
    );

    let spreadCheckedJourneys = [...checkedClientsAndJourneys];

    // Click on parent
    if (journeyId === null) {
      if (checkClient >= 0) {
        spreadCheckedJourneys = spreadCheckedJourneys.filter((x) => x.clientId !== clientId);
      } else {
        const findConfigByClientId = clientAndJourneys?.find((x) => {
          return x.clientOrganizationId === clientId;
        });

        findConfigByClientId?.configs.forEach((x) => {
          spreadCheckedJourneys.push({ clientId: clientId, journeyId: x.id });
        });
      }
    } else {
      // Click on children nodes
      if (checkClientAndJourney >= 0) {
        spreadCheckedJourneys.splice(checkClientAndJourney, 1);
      } else {
        spreadCheckedJourneys.push({ clientId: clientId, journeyId: journeyId as any });
      }
    }

    setCheckedClientsAndJourneys(spreadCheckedJourneys);
  };

  const handleRegionAndBranchCheck = (regionId: string, branchId: string | null) => {
    const regionAlreadySelected = checkedRegionsAndBranches.regionIds.findIndex((x) => x === regionId) >= 0;
    const branchAlreadySelected = checkedRegionsAndBranches.branchIds.findIndex((x) => x === branchId) >= 0;
    const regionDetails = regionsAndBranches?.find((x) => x.regionId === regionId);

    const spreadCheckedRegionsAndBranches = { ...checkedRegionsAndBranches };

    const parentSelected = branchId === null;

    const selectRegion = () => {
      spreadCheckedRegionsAndBranches.regionIds.push(regionId);
    };

    const selectBranch = () => {
      if (!branchId) return;

      spreadCheckedRegionsAndBranches.branchIds.push(branchId);
    };

    const deselectRegion = () => {
      spreadCheckedRegionsAndBranches.regionIds = spreadCheckedRegionsAndBranches.regionIds.filter(
        (x) => x !== regionId,
      );
    };

    const deselectBranch = () => {
      spreadCheckedRegionsAndBranches.branchIds = spreadCheckedRegionsAndBranches.branchIds.filter(
        (x) => x !== branchId,
      );
    };

    const selectBranchesFromRegion = () => {
      regionDetails?.branches.forEach((x) => {
        spreadCheckedRegionsAndBranches.branchIds.push(x.id);
      });
    };

    const deselectBranchesFromRegion = () => {
      regionDetails?.branches.forEach((branch) => {
        const branchIndex = spreadCheckedRegionsAndBranches.branchIds.indexOf(branch.id as any);

        spreadCheckedRegionsAndBranches.branchIds.splice(branchIndex, 1);
      });
    };

    if (parentSelected) {
      if (regionAlreadySelected) {
        deselectRegion();
        deselectBranchesFromRegion();
      } else {
        selectRegion();
        selectBranchesFromRegion();
      }
    } else {
      if (branchAlreadySelected) {
        deselectBranch();

        const branchOfSameRegion = regionDetails?.branches.reduce((acc, curr) => {
          if (spreadCheckedRegionsAndBranches.branchIds.includes(curr.id)) {
            acc = true;
          }

          return acc;
        }, false);

        if (!branchOfSameRegion) {
          deselectRegion();
        }
      } else {
        selectBranch();
        selectRegion();
      }
    }

    setCheckedRegionsAndBranches(spreadCheckedRegionsAndBranches);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserPermissionData((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleSelectAll = (type: ListType, isChecked: boolean) => {
    if (type === ListType.CLIENTS_AND_JOURNEYS) {
      const result = clientAndJourneys?.flatMap((x) => {
        const journeys = x.configs.map((y) => ({
          clientId: x.clientOrganizationId,
          journeyId: y.id,
        }));

        return journeys;
      });

      if (isChecked) {
        setCheckedClientsAndJourneys(result as any);
      } else {
        setCheckedClientsAndJourneys([]);
      }
    }

    if (type === ListType.REGIONS_AND_BRANCHES) {
      const regions: string[] = [];
      const branches: string[] = [];
      regionsAndBranches?.forEach((x) => {
        regions.push(x.regionId);
        x.branches.forEach((y) => {
          branches.push(y.id);
        });
      });

      if (isChecked) {
        setCheckedRegionsAndBranches({ regionIds: regions, branchIds: branches });
      } else {
        setCheckedRegionsAndBranches({ regionIds: [], branchIds: [] });
      }
    }
  };

  const handleSave = async () => {
    try {
      const allData = {
        ...userPermissionData,
        clientOrganizationIds: checkedClientsAndJourneys.map((x) => x.clientId),
        selectedConfigurationIds: checkedClientsAndJourneys.map((x) => {
          return [x.clientId, x.journeyId];
        }),
        regionIds: checkedRegionsAndBranches.regionIds.map((x) => x),
        branchIds: checkedRegionsAndBranches.branchIds.map((x) => x),
      };

      await aamBackendApi.updateUserPermission(tenantId, userId, allData);

      triggerNotification('success', { header: t('GENERAL.PERMISSIONS.saveMessage') });
    } catch (err) {
      triggerNotification('error', { header: t('GENERAL.PERMISSIONS.somethingWentWrong') });
    }
  };

  const handleMasterCheck = (type: ListType) => {
    setMasterCheck((state) => ({ ...state, [type]: !state[type] }));

    if (type === ListType.CLIENTS_AND_JOURNEYS) {
      setCheckedClientsAndJourneys([]);
    }
    if (type === ListType.REGIONS_AND_BRANCHES) {
      setCheckedRegionsAndBranches({ regionIds: [], branchIds: [] });
    }
  };

  const handleCollapse = (type: ListType, itemId: string) => {
    switch (type) {
      case ListType.CLIENTS_AND_JOURNEYS:
        return {
          set: () => {
            const idExist = collapsedClients.indexOf(itemId);
            if (idExist >= 0) {
              const copy = [...collapsedClients];
              copy.splice(idExist, 1);
              setCollapsedClients(copy);
            } else {
              setCollapsedClients((state) => [...state, itemId]);
            }
          },
          setAllCollapsed: function () {
            if (collapsedClients.length === clientAndJourneys?.length) {
              setCollapsedClients([]);
            } else {
              const mapToIds = clientAndJourneys?.map((x) => x.clientOrganizationId) || [];
              setCollapsedClients(mapToIds);
            }
          },
          get: collapsedClients,
          isEverythingCollapsed: collapsedClients.length === clientAndJourneys?.length,
        };
      case ListType.REGIONS_AND_BRANCHES:
        return {
          set: () => {
            const idExist = collapsedRegions.indexOf(itemId);
            if (idExist >= 0) {
              const copy = [...collapsedRegions];
              copy.splice(idExist, 1);
              setCollapsedRegions(copy);
            } else {
              setCollapsedRegions((state) => [...state, itemId]);
            }
          },
          setAllCollapsed: function () {
            if (collapsedRegions.length === regionsAndBranches?.length) {
              setCollapsedRegions([]);
            } else {
              const mapToIds = regionsAndBranches?.map((x) => x.regionId) || [];
              setCollapsedRegions(mapToIds);
            }
          },
          get: collapsedRegions,
          isEverythingCollapsed: collapsedRegions.length === regionsAndBranches?.length,
        };
      default:
        return {
          set: null,
          setAllCollapsed: null,
          get: [],
          isEverythingCollapsed: false,
        };
    }
  };

  const isDataLoading = allForDashboardLoading || isRegionsLoading;

  return (
    <div className={`${styles.userPermissionDetailsPageContainer} admin`}>
      <PageWrapper
        featureName="admin-permission-details"
        pageName="admin-permission-details"
        sideMenu
        headerConfig={{ pageName: t('GENERAL.PERMISSIONS.userPermission'), languagePicker: true }}
      >
        <Grid container className={`${styles.adminPermissionDetails__container} tag-ds`}>
          <Grid item xs={3} className={styles.adminPermissionDetails__leftSide}>
            <UserConfigurationPanel
              userId={userId}
              data={{
                email: userPermissionData.email,
                role: userPermissionData.role,
                accessStatus: userPermissionData.accessStatus,
              }}
              handleInputChange={handleInputChange}
              handleSave={handleSave}
            />
          </Grid>
          <Grid item xs={9} className={styles.adminPermissionDetails__rightSide}>
            {isDataLoading ? (
              <CircularProgress className={styles.adminPermissionDetails__loader} />
            ) : (
              <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={6}>
                  {clientAndJourneys && (
                    <NestedListSelector
                      options={clientAndJourneys}
                      type={ListType.CLIENTS_AND_JOURNEYS}
                      checkedOptions={checkedClientsAndJourneys}
                      handleChange={handleJourneyAndClientCheck}
                      handleSelectAll={handleSelectAll}
                      masterCheck={{
                        set: () => handleMasterCheck(ListType.CLIENTS_AND_JOURNEYS),
                        get: masterCheck.CLIENTS_AND_JOURNEYS,
                      }}
                      handleCollapse={handleCollapse}
                    />
                  )}
                </Grid>
                <Grid item xs={6} style={{ height: '100%' }}>
                  {regionsAndBranches && (
                    <NestedListSelector
                      options={regionsAndBranches}
                      type={ListType.REGIONS_AND_BRANCHES}
                      checkedOptions={checkedRegionsAndBranches}
                      handleChange={handleRegionAndBranchCheck}
                      handleSelectAll={handleSelectAll}
                      masterCheck={{
                        set: () => handleMasterCheck(ListType.REGIONS_AND_BRANCHES),
                        get: masterCheck.REGIONS_AND_BRANCHES,
                      }}
                      handleCollapse={handleCollapse}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </PageWrapper>
      <Notification
        type={notificationType}
        details={notificationDetails}
        isActive={isActive}
        hide={closeNotification}
      />
    </div>
  );
};

export default UserPermissionDetails;
