import { StepStatusEnum } from '@features/oneOnboarding/interfaces';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import {
  RecruiterSidebarItem,
  StepRecruiterSidebarItem,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { getStepAndPage } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/StepItem';

interface UseStepItemStateParams {
  item: StepRecruiterSidebarItem;
  flowWrapper: OOFlowWrapper;
}

const getStepStatus = (item: RecruiterSidebarItem, flowWrapper: OOFlowWrapper): StepStatusEnum => {
  const { step: currentStep, page: currentPage } = flowWrapper.getCurrentStepAndPage();

  if (!currentStep) {
    return StepStatusEnum.notStarted;
  }
  const { step, page } = getStepAndPage(item, flowWrapper.steps);

  if (!step) {
    return StepStatusEnum.notStarted;
  }

  if (!step) {
    return StepStatusEnum.notStarted;
  }

  if (currentStep !== step.name) {
    return flowWrapper.isStepCompleted(step.name) ? StepStatusEnum.completed : StepStatusEnum.notStarted;
  }
  if (!page) {
    if (currentStep === step.name) {
      return StepStatusEnum.inProgress;
    } else {
      return flowWrapper.isStepCompleted(step.name) ? StepStatusEnum.completed : StepStatusEnum.notStarted;
    }
  }

  if (currentPage !== page.name) {
    return flowWrapper.isPageCompleted(step.name, page.name) ? StepStatusEnum.completed : StepStatusEnum.notStarted;
  }

  const isPageCompleted = page
    ? flowWrapper.isPageCompleted(step.name, page.name)
    : flowWrapper.isStepCompleted(step.name);
  return isPageCompleted ? StepStatusEnum.completed : StepStatusEnum.inProgress;
};

export const useStepItemState = ({ item, flowWrapper }: UseStepItemStateParams) => {
  const { children } = item;
  let stepStatus = getStepStatus(item, flowWrapper);
  let isActive = stepStatus === StepStatusEnum.inProgress;
  let disabled: boolean;

  if (item.type === 'step' && children) {
    const filtered = children.filter((child) => child.type === 'step');
    const childrenStatuses = filtered.map((child) => getStepStatus(child, flowWrapper));
    const hasInProgress = childrenStatuses.includes(StepStatusEnum.inProgress);
    const allCompleted = childrenStatuses.every((status) => status === StepStatusEnum.completed);
    isActive = isActive || hasInProgress;
    if (allCompleted) {
      stepStatus = StepStatusEnum.completed;
    }
    disabled =
      stepStatus === StepStatusEnum.notStarted &&
      (!children || children.every((child) => getStepStatus(child, flowWrapper) === StepStatusEnum.notStarted));
  } else {
    disabled = stepStatus === StepStatusEnum.notStarted;
  }

  return { stepStatus, isActive, disabled };
};
