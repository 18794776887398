import React from 'react';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Table, TBody, TElement, THead, TRow } from '@adeccoux/tag-ds';
import { locales } from '@features/oneOnboarding/helpers';
import { Import, ImportResponse } from '@features/oneOnboarding/models/Import';

import { SortingButton } from '../../../components/AdminDashboardTable/SortingButton';
import AdminPagination from '../../../components/AdminPagination';

const columns = [
  { field: 'startDate', headerName: 'headerStartDate', sorting: true },
  { field: 'endDate', headerName: 'headerEndDate', sorting: true },
  { field: 'status', headerName: 'headerStatus', sorting: false },
  { field: 'expectedLines', headerName: 'headerExpectedLines', sorting: false },
  { field: 'actualLines', headerName: 'headerActualLines', sorting: false },
  { field: 'errors', headerName: 'headerErrors', sorting: false },
  { field: 'importerId', headerName: 'importer', sorting: false },
  { field: 'goToImports', headerName: 'goToImports', sorting: false },
];
interface ImportTableProps {
  importList: ImportResponse | undefined;
  order: string;
  orderBy: string;
  setOrder: (value: string) => void;
  setOrderBy: (value: string) => void;
  pagination: {
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    rowsPerPage: number;
    page: number;
  };
}

const ImportTable: React.FC<ImportTableProps> = ({ importList, order, orderBy, pagination, setOrder, setOrderBy }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(['recruiter']);

  const selectedLanguage = localStorage.getItem('i18nextLng') || 'enUS';

  const locale = locales[selectedLanguage];

  const formatTableDate = (date?: any) => (date ? format(parseISO(date), 'MMM dd yyyy', { locale }) : '');

  return (
    <div className="tag-ds App">
      <Table>
        <THead>
          <TRow>
            {columns.map(({ field, headerName, sorting }) => (
              <TElement key={field} style={{ postion: 'relative' }} className="feature-admin-import__table-field">
                {sorting && (
                  <SortingButton
                    onClick={() => {
                      setOrder(orderBy === field && order === 'asc' ? 'desc' : 'asc');
                      setOrderBy(field);
                    }}
                    direction={orderBy === field ? order : 'asc'}
                    active={orderBy === field}
                  />
                )}
                {i18n.exists(`GENERAL.IMPORT.${headerName}`, { ns: 'recruiter' })
                  ? t(`GENERAL.IMPORT.${headerName}`)
                  : t(`GENERAL.CANDIDATES_OVERVIEW.${headerName}`)}
              </TElement>
            ))}
          </TRow>
        </THead>
        <TBody>
          {importList?.results &&
            importList.results.length > 0 &&
            importList.results.map((item: Import, index: number) => (
              <TRow key={index}>
                <TElement>{formatTableDate(item.startDate)}</TElement>
                <TElement>{formatTableDate(item.endDate)}</TElement>
                <TElement>{item.status ? t(`GENERAL.IMPORT.${item.status}`) : ''}</TElement>
                <TElement>{item.expectedLines}</TElement>
                <TElement>{item.actualLines}</TElement>
                <TElement>{item.failedLines}</TElement>
                <TElement>
                  {item.importer
                    ? !item.importer?.firstName || !item.importer?.lastName
                      ? item.importer?.email
                      : item.importer?.firstName + ' ' + item.importer?.lastName
                    : ''}
                </TElement>
                <TElement onClick={() => history.push(`/oo/recruiter/import-users/${item.id}`)}>
                  <button className="button-secondary_small">
                    <span className="material-icons">notes</span>
                  </button>
                </TElement>
              </TRow>
            ))}
        </TBody>
      </Table>
      {importList?.results && importList?.results?.length > 0 && (
        <AdminPagination
          {...pagination}
          total={importList.total}
          currentPage={pagination.page}
          isRowsPerPageVisible={true}
          setPage={pagination.setPage}
        />
      )}
    </div>
  );
};

export default ImportTable;
