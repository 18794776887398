import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog } from '@mui/material';
interface RetriggerIntegrationDialogProps {
  open: boolean;
  onClose: () => void;
  onRetrigger: () => void;
}
export const RetriggerIntegrationDialog: React.FC<RetriggerIntegrationDialogProps> = ({
  open,
  onClose,
  onRetrigger,
}) => {
  const { t } = useTranslation(['recruiter']);
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      style={{ overflowY: 'visible' }}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: '667px',
          height: '260px',
        },
      }}
    >
      <div className="tag-ds modal docs">
        <button className="modal-close-button" onClick={onClose}>
          <span className="material-icons">close</span>
        </button>

        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <h4 className="modal-header">{t(`recruiter:HIRING.HIRE.error-during-integration`)}</h4>
        </Box>
        <div style={{ paddingTop: '12px' }}>
          <div className="modal-footer">
            <button className="button-secondary" onClick={onRetrigger}>
              {t(`recruiter:HIRING.HIRE.retrigger-integration`)}
            </button>
            <button className="button-primary" onClick={onClose}>
              {t(`recruiter:HIRING.HIRE.quit`)}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
