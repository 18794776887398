import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option, Select } from '@adeccoux/tag-ds';

import { OptionType } from './DashboardFilters';
import { actionTypes, multipleSelectors, stateKeys, translationKeys } from './DashboardFiltersConfigs';
interface FilterSelectProps {
  options: Array<any>;
  optionKey: string;
  selectedValues: any;
  valueKey?: string;
  translationOptionKey?: string;
  label?: string;
  dispatch: (action: FilterAction) => void;
  uniqueKey?: string;
  disabled?: boolean;
}
export interface FilterAction {
  type: string;
  payload?: any;
  options?: any[];
}

export const DashboardFilterSelect: FC<FilterSelectProps> = ({
  options,
  optionKey,
  selectedValues,
  valueKey = 'id',
  translationOptionKey = 'control-items:',
  label,
  dispatch,
  uniqueKey,
  disabled,
}) => {
  const { t } = useTranslation(['recruiter', 'candidate_recruiter', 'control-items']);
  const localNameCheck = (option: OptionType) => {
    if (option.localName) {
      const selectedLanguage = localStorage.getItem('i18nextLng');
      if (selectedLanguage !== 'en') {
        return option.localName;
      }
      return option.name;
    }
    return option.name;
  };

  const multiple = multipleSelectors.includes(optionKey);

  const value = useMemo(() => {
    if (multiple) {
      return selectedValues[stateKeys[optionKey]]?.map((x: any) => {
        if (typeof x === 'string') {
          return x;
        } else {
          return x[valueKey];
        }
      });
    } else {
      if (selectedValues[stateKeys[optionKey]]) {
        if (typeof selectedValues[stateKeys[optionKey]] === 'string') {
          return selectedValues[stateKeys[optionKey]];
        } else {
          return selectedValues[stateKeys[optionKey]][valueKey];
        }
      } else return '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiple, selectedValues]);

  const onChange = (e: any) => {
    const payload = multiple
      ? e.map((x: string) => options.find((y: any) => y[valueKey] === x))
      : options.find((y: any) => y[valueKey] === e)?.[valueKey] || '';
    dispatch({ type: actionTypes[optionKey], payload, options });
  };
  return (
    <Select
      className="tag-ds "
      label={label || t(`recruiter:GENERAL.CANDIDATES_OVERVIEW.${translationKeys[optionKey]}`)}
      multiple={multiple}
      name="select"
      placeholder={t('candidate_recruiter:GENERAL.CONTROLS.selectAnOption')}
      onChange={onChange}
      value={value}
      key={uniqueKey}
      disabled={disabled}
      filter={options.length > 6}
    >
      {options.length > 0 ? (
        options.map((x: any) => (
          <Option
            label={x?.label ? t(`${translationOptionKey}${x?.label}`) : localNameCheck(x) || x[valueKey]}
            value={x[valueKey]}
            key={x[valueKey]}
          />
        ))
      ) : (
        <Option label={t('candidate_recruiter:GENERAL.CONTROLS.noOptions')} value="0" disabled />
      )}
    </Select>
  );
};
