import React, { FC } from 'react';

export interface ViewDocumentProps {
  handleDocument: () => void;
  disabled?: boolean;
}

export const ViewDocument: FC<ViewDocumentProps> = ({ handleDocument, disabled }) => {
  return (
    <div className="tag-ds ">
      <button className="button-secondary" onClick={handleDocument} disabled={disabled}>
        <span className="material-icons">visibility</span>
      </button>
    </div>
  );
};
