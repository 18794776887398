import React, { useRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@adeccoux/tag-ds';
import { translationHelper } from '@features/selfService/helpers/translationHelper';

import DraggableComponent from '../../DraggableComponent';
import { Item } from './SingleSelectOptions';

import style from './SingleSelectOptions.module.scss';

interface Props {
  item: Item;
  onOptionDrop: (e: any, targetField: string) => void;
  handleDelete: () => void;
  handleDefaultOption: () => void;
  setItemForEdit: () => void;
  fromBackend?: boolean;
  defaultOption: boolean;
}

const SingleSelectItem: React.FC<Props> = ({
  item,
  onOptionDrop,
  handleDelete,
  setItemForEdit,
  handleDefaultOption,
  fromBackend,
  defaultOption,
}) => {
  const favoriteRef: any = useRef(null);
  const { t, i18n } = useTranslation(['entry', 'control-items']);

  const getTranslation = (key: string) => translationHelper(key, i18n, t);

  return (
    <DraggableComponent control={item} onDrop={onOptionDrop}>
      <div className={style.option__container}>
        <div className={style.option}>
          {!fromBackend && (
            <div className="material-icons" style={{ fontSize: 15 }}>
              drag_indicator
            </div>
          )}
          <p style={{ margin: 0 }} className="base">
            {fromBackend ? getTranslation(item.label || item.value) : item.value}
          </p>
          <div className={clsx('tag-ds', style['item__icon-container'])}>
            {!fromBackend && (
              <span onClick={setItemForEdit} className={clsx('material-icons-outlined', style.item__icon)}>
                edit
              </span>
            )}
            <span onClick={handleDelete} className={clsx('material-icons-outlined', style.item__icon)}>
              delete
            </span>
            {!fromBackend && (
              <span
                className={clsx('material-icons-outlined', style.item__icon)}
                onClick={handleDefaultOption}
                id="defaultOption"
                ref={favoriteRef}
              >
                {defaultOption ? 'star' : 'star_border'}
              </span>
            )}
            <Tooltip
              className="tooltip top"
              parentRef={favoriteRef}
              style={{
                position: 'absolute',
                bottom: 25,
                left: 22,
                zIndex: 2,
              }}
            >
              Set as default
            </Tooltip>
          </div>
        </div>
      </div>
    </DraggableComponent>
  );
};

export default SingleSelectItem;
