import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import { getTenantDefaultLanguage, getTenantFallbackLanguage } from '@helpers/tenant.helper';
import { SelectedLanguage } from '@store/slices/oneOnboarding.slice';

import * as translationHash from './translation-hash.json';

export async function initLocalization(languages?: Array<SelectedLanguage>): Promise<void> {
  let supportedLngs;
  let lng: string;
  const tenantId = parseInt(localStorage.getItem('tenantId')!, 10);

  const ns: string[] = [];
  const lngFromStorage = localStorage.getItem('i18nextLng') || '';
  if (languages) {
    supportedLngs = languages.sort((a, b) => Number(b.isDefault) - Number(a.isDefault)).map((i) => i.code);
    lng = supportedLngs.includes(lngFromStorage)
      ? lngFromStorage
      : languages.find((i) => i.isDefault)?.code ?? getTenantDefaultLanguage(tenantId);
  } else {
    supportedLngs = ['en', 'de', 'it', 'fr', 'es', 'nl'];
    lng = supportedLngs.includes(lngFromStorage) ? lngFromStorage : getTenantDefaultLanguage(tenantId);
  }
  const getCatalogHash = (url: string): string => (translationHash as any)[url] || '--';
  const t = await i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next

    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // fallbackLng: featureConfigurationLocalization.fallbackLanguage,
      lng,
      fallbackLng: [getTenantDefaultLanguage(tenantId), ...getTenantFallbackLanguage(tenantId)],
      debug: false,
      ns,
      // whitelist: featureConfigurationLocalization.whitelist,
      returnObjects: true,
      load: 'currentOnly',
      supportedLngs,
      detection: {
        order: ['querystring', 'localStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        caches: ['localStorage', 'cookie'],
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false,
      },
      backend: {
        loadPath: `/locales/${tenantId}/{{lng}}/{{ns}}.json`,
        request: (options: any, url: string, payload: any, callback: any) => {
          axios
            .get(url + '?ts=' + getCatalogHash(url))
            .then((res: any) => {
              callback(null, { status: 200, data: res.data });
            })
            .catch((err: any) => callback(err));
        },
      },
    });

  setLocale({
    mixed: {
      required: () => t('validation:mixed:required'),
    },
    string: {
      email: () => t('validation:string:email'),
      max: ({ max }) =>
        t('validation:string:max', {
          max,
        }),
      min: ({ min }) =>
        t('validation:string:min', {
          min,
        }),
    },
  });
}
