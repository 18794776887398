import React from 'react';
import { CircularProgress, Dialog } from '@mui/material';

export interface ActivateDeactivateCampaignModalProps {
  headerText: string;
  bodyText: string;
  buttonText: string;
  cancelButtonModal: () => void;
  isActiveModal: boolean;
  setIsActiveModal: (value: boolean) => void;
  cancelButtonText: string;
  activateCampaign: () => void;
  activating: boolean;
}

const ActivateDeactivateCampaignModal: React.FC<ActivateDeactivateCampaignModalProps> = ({
  isActiveModal,
  setIsActiveModal,
  cancelButtonModal,
  cancelButtonText,
  buttonText,
  bodyText,
  headerText,
  activateCampaign,
  activating,
}) => {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={isActiveModal}
      onClose={() => {
        setIsActiveModal(false);
        cancelButtonModal();
      }}
      style={{ overflowY: 'visible' }}
      PaperProps={{
        style: {
          borderRadius: 8,
        },
      }}
    >
      <div className="tag-ds modal docs ">
        <button
          className="modal-close-button"
          onClick={() => {
            setIsActiveModal(false);
            cancelButtonModal();
          }}
        >
          <span className="material-icons">close</span>
        </button>
        <h4 className="modal-header">{headerText}</h4>
        <div className="modal-body">{bodyText}</div>
        <div className="modal-footer">
          <button
            className="button-secondary"
            onClick={() => {
              setIsActiveModal(false);
              cancelButtonModal();
            }}
          >
            {cancelButtonText.toUpperCase()}
          </button>
          <button className="button-primary" onClick={() => activateCampaign()}>
            {activating && <CircularProgress color="primary" size={14} />}
            {!activating && buttonText.toUpperCase()}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ActivateDeactivateCampaignModal;
