import { Skeleton, Typography, useTheme } from '@mui/material';

type LinkProps = {
  text: string;
  onClick: () => void;
  openInNewWindow?: boolean;
};

export const Link: React.FC<LinkProps> = ({ text, onClick, openInNewWindow }) => {
  const theme = useTheme();
  return (
    <div onClick={onClick} className="tag-ds" style={{ cursor: 'pointer', margin: '20px' }}>
      <div style={{ width: '200px', margin: 'auto', height: '30px' }}>
        <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          <Typography
            className="tag-ds"
            style={{
              color: theme.palette.info.dark,
              fontWeight: theme.typography.fontWeightBold,
              textTransform: 'uppercase',
            }}
          >
            {text}
          </Typography>
        </span>
        <span className="material-icons" style={{ verticalAlign: 'middle', color: theme.palette.info.dark }}>
          open_in_new
        </span>
      </div>
    </div>
  );
};

type LinkSkeletonProps = {
  pleaseWaitText: string;
};

export const LinkSkeleton: React.FC<LinkSkeletonProps> = ({ pleaseWaitText }) => {
  return (
    <div className="tag-ds" style={{ margin: '20px' }}>
      <div style={{ width: '300px', margin: 'auto', height: '30px' }}>
        <span style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }}>
          <Skeleton variant="text" width={200} height={60} style={{ display: 'flex' }} />
        </span>
        <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          <Skeleton variant="circular" width={40} height={40} style={{ display: 'flex' }} />
        </span>
        <Typography className="tag-ds">{pleaseWaitText}</Typography>
      </div>
    </div>
  );
};
