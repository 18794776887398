import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import { useT } from '@features/oneOnboarding/hooks/useT';
import FoldableTreeNode from '@features/oneOnboardingAdmin/components/FoldableTreeNode/FoldableTreeNode';
import { ItemProps } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/CustomCandidateProfileSidebar';
import {
  CustomRecruiterSidebarItem,
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { useCandidateSignDocuments } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useCandidateSignDocuments';
import { useCandidateUploadsDocuments } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useCandidateUploadsDocuments';
import { useCandidateUploadsPaymentDetails } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useCandidateUploadsPaymentDetails';
import { useHandlerClass } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useHandlerClass';
import { useMatchingHandler } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useMatchingHandler';
import useRecruiterCreatesHiringDocuments from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterCreatesHiringDocuments';
import useRecruiterEntersHiringData from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterEntersHiringData';
import { useRecruiterSignsHiringDocuments } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterSignsHiringDocuments';
import { useRecruiterUploadsWorkContract } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterUploadsWorkContract';
import { useRecruiterValidatesPaymentInfo } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterValidatesPaymentInfo';
import { useRecruiterVerifiesDocuments } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterVerifiesDocuments';
import { SidebarItems } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/SidebarItems';
import { StepSeparator } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/StepSeparator';
import { PageStepContext } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/PageStepContext';

import { useCandidateDetails } from './handlerClasses/useCandidateDetails';

import styles from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/CustomCandidateProfileSidebar.module.css';

export const handlerHooks: Record<
  string,
  (params: SidebarItemStateHandlerParams) => RecruiterSidebarItemState | undefined
> = {
  recruiterEntersHiringData: useRecruiterEntersHiringData,
  matchingHandler: useMatchingHandler,
  recruiterUploadsWorkContract: useRecruiterUploadsWorkContract,
  handlerClass: useHandlerClass,
  recruiterCreatesHiringDocuments: useRecruiterCreatesHiringDocuments,
  candidateSignDocuments: useCandidateSignDocuments,
  recruiterSignsHiringDocuments: useRecruiterSignsHiringDocuments,
  recruiterVerifiesDocuments: useRecruiterVerifiesDocuments,
  candidateUploadsDocuments: useCandidateUploadsDocuments,
  candidateUploadsPaymentDetails: useCandidateUploadsPaymentDetails,
  recruiterValidatesPaymentInfo: useRecruiterValidatesPaymentInfo,
  candidateDetails: useCandidateDetails,
};
export type CustomStepProps = ItemProps & {
  item: CustomRecruiterSidebarItem;
};

export const CustomStep: FC<CustomStepProps> = ({
  item,
  index,
  steps,
  flowWrapper,
  onboarding,
  currentStep,
  currentPage,
}) => {
  const { t } = useT('stepper');

  const { setPageStepContext } = useContext(PageStepContext);
  const handlerHook = handlerHooks[item.handlerClass];
  const state = handlerHook({
    steps,
    flowWrapper,
    onboarding,
    setPageStepContext,
  });
  if (state === undefined) return null;
  const { active, disabled, completed, onClick } = state;
  const children = item.children ? (
    <>
      <StepSeparator />
      <SidebarItems
        items={item.children}
        steps={steps}
        flowWrapper={flowWrapper}
        onboarding={onboarding}
        currentStep={currentStep}
        currentPage={currentPage}
      />
    </>
  ) : undefined;

  return (
    <FoldableTreeNode
      isCollapsed={active}
      key={index}
      name={t(item.label)}
      icon={completed ? 'check_circle' : 'radio_button_unchecked'}
      disabled={disabled}
      onClick={onClick}
      arrow="right"
      className={clsx(styles['tree-view-element'], {
        [styles.success]: completed,
        [styles.inProgress]: active,
      })}
    >
      {children}
    </FoldableTreeNode>
  );
};
