import { OOFeaturesEnum, StepStatusEnum } from '@features/oneOnboarding/interfaces';
import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useMatchingHandler = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState | undefined => {
  const matchStep = flowWrapper.getAllFeatureSteps(OOFeaturesEnum.matching);
  if (matchStep.length) {
    const { step } = flowWrapper.getCurrentStepAndPage();
    const completed = step === 'MATCHING_ENDED';
    const matchStepStatus = steps.find((x) => x.name === 'MATCH')?.status ?? StepStatusEnum.notStarted;
    const showMatching = [StepStatusEnum.inProgress, StepStatusEnum.completed].includes(matchStepStatus);
    const disabled = !showMatching;
    const active = showMatching && !completed;

    return {
      active,
      disabled,
      completed,
      onClick: () => {
        const step = steps.find((x) => x.name === 'MATCH');
        const page = step?.pages?.find((x) => x.name === 'HIRE_LIST_INFO');
        setPageStepContext({ step, page });
      },
    };
  } else {
    return undefined;
  }
};
