import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomMailProps {
  sendCustomMail: (subject: string, body: string) => void;
}

const CustomMail: React.FC<CustomMailProps> = ({ sendCustomMail }) => {
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');

  const { t } = useTranslation(['recruiter']);

  return (
    <div>
      <div className="tag-ds input-wrapper" style={{ marginBottom: 16 }}>
        <label className="caption">{t('recruiter:GENERAL.MAILS.ManualMailSubject')}</label>
        <input className=" " type="text" onChange={(e) => setSubject(e.target.value)} value={subject} />
      </div>
      <div className="tag-ds input-wrapper ">
        <label className="caption">{t('recruiter:GENERAL.MAILS.ManualMailBody')}</label>
        <textarea onChange={(e) => setBody(e.target.value)} value={body} />
      </div>
      <div className="tag-ds" style={{ marginTop: 32 }}>
        <button
          onClick={() => {
            sendCustomMail(subject, body);
            setSubject('');
            setBody('');
          }}
        >
          {t('recruiter:GENERAL.MAILS.sendEmail')}
        </button>
      </div>
    </div>
  );
};

export default CustomMail;
