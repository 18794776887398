import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Typography } from '@mui/material';
import { store } from '@store/index';

import { editableCondition } from '../../hooks/useRenderCondition';
import { useT } from '../../hooks/useT';
import { OOSelectorCheckboxModelInterface } from '../../interfaces';
import { OOFlowWrapper } from '../../wrappers/FlowWrapper';
import { OOCheckbox } from './Checkbox/OOCheckbox';

export const OODefaultConsentCheckbox: React.VoidFunctionComponent<OOSelectorCheckboxModelInterface> = (props) => {
  const { t } = useT('entry');
  const flowData = store.getState().oneOnboarding.flow;
  const formik = useFormikContext<Record<string, any>>();

  const flowWrapper = useMemo(() => {
    if (!flowData) return null;
    return OOFlowWrapper.create(flowData);
  }, [flowData]);

  const isEditable = editableCondition(props, flowWrapper, formik.values) ?? true;

  return (
    <OOCheckbox
      validationKey={props.name}
      defaultValue={props.defaultValue}
      label={
        props.link ? (
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            <span className="consent-link-label">
              <Typography>
                {t(props.label)}
                {props.isMandatory ? ' *' : ''}
              </Typography>
            </span>
          </a>
        ) : (
          <span className="consent-label">
            <p className="tag-ds" style={{ height: 'auto' }}>
              {t(props.label)}
              {props.isMandatory ? ' *' : ''}
            </p>
          </span>
        )
      }
      description={Array.isArray(props.description) ? props.description?.map((x) => t(x)) : t(props.description)}
      descriptionStyle="consent-description"
      mandatory={props.isMandatory}
      checkboxProps={{
        disabled:
          !(flowWrapper?.isControlEditable(props.isEditableCandidate, props.isEditableRecruiter) ?? true) ||
          !isEditable,
      }}
    />
  );
};
