import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFlowWrapper } from '@features/oneOnboarding/hooks/useFlowWrapper';
import { OOStepElementModel } from '@features/oneOnboarding/models/StepElementModel';
import { Grid, Link } from '@mui/material';
import { RootState } from '@store/rootReducer';

export const Footer = () => {
  const { t } = useTranslation(['entry']);

  const tenant = useSelector((state: RootState) => state.tenant);
  const { currentStep } = useSelector((state: RootState) => state.oneOnboarding);
  const flowWrapper = useFlowWrapper();
  let helpLink = null;

  const elements: OOStepElementModel[] = useMemo(() => {
    if (!flowWrapper || !tenant.useOneOnboarding) {
      return [];
    }
    return flowWrapper.steps.find((step) => step.name === currentStep)?.elements ?? [];
  }, [currentStep, flowWrapper, tenant.useOneOnboarding]);

  const isElementVisible = (elName: string) => {
    helpLink = elements.length && elements.filter((element) => element.name === elName)[0]?.additionalData?.link;
    return (tenant.useOneOnboarding && elements.some((e) => e.name === elName)) || !tenant.useOneOnboarding;
  };

  let footer = <></>;

  if (isElementVisible('FOOTER')) {
    footer = (
      <Grid container className="footer__container">
        {isElementVisible('PRIVACY_POLICY') && (
          <Grid item className="footer__container-item">
            <Link href={helpLink || 'https://www.adeccogroup.com/privacy-policy/'}>{t('footer.privacyPolicy')}</Link>
          </Grid>
        )}
        {isElementVisible('TERMS_AND_CONDITIONS') && (
          <Grid item className="footer__container-item">
            <Link href={helpLink || 'https://www.adeccogroup.com/terms-and-conditions/'}>
              {t('footer.termsAndConditions')}
            </Link>
          </Grid>
        )}
        {isElementVisible('RECEIVE_INFORMATION') && (
          <Grid item className="footer__container-item">
            <Link href={helpLink || 'https://www.adeccogroup.com/receive-information/'}>
              {t('footer.receiveInformation')}
            </Link>
          </Grid>
        )}
        {isElementVisible('JOB_OFFER') && (
          <Grid item className="footer__container-item">
            <Link href={helpLink || 'https://www.adeccogroup.com/job-offer/'}>{t('footer.jobOffer')}</Link>
          </Grid>
        )}
      </Grid>
    );
  }

  return footer;
};
