export enum EventType {
  PAGE_LOADED = 'PAGE_LOADED',
  BUTTON_CLICKED = 'BUTTON_CLICKED',
  LINK_OPENED = 'LINK_OPENED',
  STEP_COMPLETED = 'STEP_COMPLETED',
  AUTOMATIC_EMAIL_SENT = 'AUTOMATIC_EMAIL_SENT',
  DOCUMENT_REJECTED = 'DOCUMENT_REJECTED',
  ONBOARDING_REJECTED = 'ONBOARDING_REJECTED',
  ONBOARDING_UNREJECTED = 'ONBOARDING_UNREJECTED',
}

export enum TargetName {
  Any = '*',
  RecruiterCandidateDetails = 'Recruiter:CandidateDetails',
  RecruiterHiringData = 'Recruiter:HiringData',
  RecruiterHire = 'Recruiter:Hire',
  Candidate = 'Candidate',
  CandidateDocumentUpload = 'Candidate:DocumentUpload',
  CandidateRegistration = 'Candidate:Registration',
  CandidateRegistrationContinue = 'Candidate:Registration:Continue',
  CandidateInvitation = 'Candidate:Invitation',
  Import = 'IMPORT',
  Registration = 'REGISTRATION',
  Invitation = 'Invitation',
  CandidateDetails = 'CANDIDATE_DETAILS',
  Hire = 'HIRE',
  DocumentUpload = 'DOCUMENT_UPLOAD',
  AwaitContract = 'AWAIT_CONTRACT',
  DocumentValidation = 'DOCUMENT_VALIDATION',
  RecruiterSignDocuments = 'RECRUITER_SIGN_DOCUMENTS',
  HireSignature = 'HIRE_SIGNATURE',
  CandidateSignDocuments = 'CANDIDATE_SIGN_DOCUMENTS',
  DocumentUpload2 = 'DOCUMENT_UPLOAD2',
  DocumentValidation2 = 'DOCUMENT_VALIDATION2',
  CandidateDocumentsSigning = 'Candidate:DocumentsSigning',
  Approved = 'APPROVED',
  DocumentSelection = 'DOCUMENT_SELECTION',
  SignatureCreate = 'Candidate:SignatureCreate',
  DocumentsToSign = 'Candidate:DocumentsToSign',
  Signature = 'SIGNATURE',
  CandidateConfirmsDocuments = 'CANDIDATE_CONFIRMS_DOCUMENTS',
  CandidateDocumentsSelection = 'Candidate:DocumentsSelection',
}

export enum TelemetryActor {
  CANDIDATE = 'CANDIDATE',
  RECRUITER = 'RECRUITER',
}

interface TelemetryBasePayload {
  tenantId: number;
  eventType: EventType;
  clientOrganizationId: string;
  configurationId: string;
  applicationId: string;
  targetName?: TargetName;
}

export interface AuthenticatedTelemetryPayload extends TelemetryBasePayload {
  candidateId?: string;
}
export interface UnauthenticatedTelemetryPayload extends TelemetryBasePayload {
  email?: string;
  actor: TelemetryActor;
  candidateId?: string;
}

export interface AuthenticatedTelemetryHookPayload {
  tenantId: number;
  eventType: EventType;
  clientOrganizationId: string | null | undefined;
  configurationId: string | null | undefined;
  applicationId: string | null | undefined;
  targetName?: TargetName;
  candidateId?: string | null | undefined;
  actor: TelemetryActor;
}

export interface UnauthenticatedTelemetryHookPayload {
  tenantId: number;
  eventType: EventType;
  clientOrganizationId: string | null | undefined;
  configurationId: string | null | undefined;
  applicationId: string | null | undefined;
  targetName?: TargetName;
  email?: string;
  actor: TelemetryActor;
  candidateId?: string | null | undefined;
}

export interface TelemetrySearch extends TelemetryBasePayload {
  actor: TelemetryActor;
  actorId?: string;
  candidateId?: string;
  timestamp: Date;
  onboardingStatus?: string;
}

export interface TelemetryList {
  results: TelemetrySearch[];
  total: number;
}

export interface TelemetrySearchParameters {
  page?: number;
  limit?: number;
  candidateId?: string;
}

export interface DescriptionObj {
  eventType: EventType;
  targetName: TargetName;
  description: string;
}

export interface TelemetryStat {
  eventType: EventType;
  targetName: TargetName;
  count: number;
}

export const descriptionMap: DescriptionObj[] = [
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.Import,
    description: 'Onboarding imported',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.Registration,
    description: 'Candidate completed registration',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.RecruiterCandidateDetails,
    description: 'Recruiter opened candidate details page',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.CandidateRegistration,
    description: 'Candidate loaded registration page',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.RecruiterHiringData,
    description: 'Recruiter opened hiring data page',
  },
  {
    eventType: EventType.LINK_OPENED,
    targetName: TargetName.CandidateInvitation,
    description: 'Candidate opened the invitation link',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.CandidateDetails,
    description: 'Candidate entered details',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.DocumentUpload2,
    description: 'Candidate uploaded payment documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.DocumentValidation2,
    description: 'Recruiter validated payment documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.Hire,
    description: 'Recruiter hired the candidate',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.DocumentUpload,
    description: 'Candidate uploaded documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.DocumentValidation,
    description: 'Recruiter validated documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.RecruiterSignDocuments,
    description: 'Recruiter signed documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.HireSignature,
    description: 'Candidate selected signature process',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.CandidateSignDocuments,
    description: 'Candidate signed documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.AwaitContract,
    description: 'Documents from DISY received',
  },
  {
    eventType: EventType.DOCUMENT_REJECTED,
    targetName: TargetName.Any,
    description: 'Recruiter rejected the document',
  },
  {
    eventType: EventType.ONBOARDING_REJECTED,
    targetName: TargetName.Any,
    description: 'Recruiter rejected the onboarding',
  },
  {
    eventType: EventType.ONBOARDING_UNREJECTED,
    targetName: TargetName.Any,
    description: 'Recruiter unrejected the onboarding',
  },
  {
    eventType: EventType.AUTOMATIC_EMAIL_SENT,
    targetName: TargetName.Any,
    description: 'Automatic email was sent',
  },
  {
    eventType: EventType.LINK_OPENED,
    targetName: TargetName.Invitation,
    description: 'Candidate has opened the invitation link',
  },
  {
    eventType: EventType.BUTTON_CLICKED,
    targetName: TargetName.CandidateRegistrationContinue,
    description: 'Candidate has left the consents and proceeded to the next page',
  },
  {
    eventType: EventType.BUTTON_CLICKED,
    targetName: TargetName.RecruiterHire,
    description: 'Recruiter clicked hire button',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.CandidateDocumentUpload,
    description: 'Candidate uploads documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.Signature,
    description: 'Candidate selects contract method',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.CandidateConfirmsDocuments,
    description: 'Candidate signs documents',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.Approved,
    description: 'Recruiter approved candidate',
  },
  {
    eventType: EventType.STEP_COMPLETED,
    targetName: TargetName.DocumentSelection,
    description: 'Candidate selected document to upload',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.SignatureCreate,
    description: 'Candidate opened signature create page',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.DocumentsToSign,
    description: 'Candidate opened documents for signature page',
  },
  {
    eventType: EventType.PAGE_LOADED,
    targetName: TargetName.DocumentSelection,
    description: 'Candidate opened document selection page',
  },
];
