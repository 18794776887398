import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandMore } from '@mui/icons-material';
import { Collapse, TextareaAutosize, TextField, Typography } from '@mui/material';

import { configFileExtension } from './adminImportHelpers';
import { AdminUploadButton } from './AdminUploadButton';

import styles from './AdminImport.module.scss';
interface AddConfigurationForANewClientProps {
  strId: string;
  className: string;
  assetBoxes: string[];
  toggleAssetBox: (strId: string) => void;
  handleAddConfig: any;
}
export const AddConfigurationForANewClient: FC<AddConfigurationForANewClientProps> = ({
  strId,
  className,
  assetBoxes,
  toggleAssetBox,
  handleAddConfig,
}) => {
  const { t } = useTranslation(['recruiter']);
  const assetBoxOpen = assetBoxes.includes(strId);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  return (
    <div className={styles.assetHolder + (className ? ' ' + className : '')}>
      <div className={styles.flexHolder + ' ' + styles.assetHeader} onClick={() => toggleAssetBox(strId)}>
        <Typography variant="h4">{t('GENERAL.CONFIGURATION.AddConfigurationForANewClient')}</Typography>
        <ExpandMore style={!assetBoxOpen ? { transform: 'rotate(180deg)' } : {}} />
      </div>
      <Collapse in={assetBoxOpen} timeout="auto" unmountOnExit>
        <div className={styles.assetBox}>
          <div>
            <span className={styles.textInput}>
              <TextField
                placeholder={t('GENERAL.CONFIGURATION.displayName')}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </span>
            <span className={styles.textInput}>
              <TextField type="text" placeholder="Id" value={id} onChange={(e) => setId(e.target.value)} />
            </span>
            <span className={styles.textInput}>
              <TextField
                type="text"
                placeholder={t('GENERAL.CONFIGURATION.displayName')}
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </span>
            <span className={styles.textInput}>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder={t('GENERAL.CONFIGURATION.description')}
                minRows={5}
                style={{ width: 300 }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </span>
          </div>
          <div>
            <br />
            <AdminUploadButton
              label={t('GENERAL.CONFIGURATION.AddConfiguration')}
              extension={configFileExtension}
              onChange={(e: any) =>
                handleAddConfig(e, { isNewClient: true, id, name, displayName, description }, false)
              }
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
};
