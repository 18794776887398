import { Tenant } from '@hooks/useComponentMapping';

export const getConfigurationAssetsPrefix = (
  assetDir: string,
  clientOrganizationId?: string,
  configurationId?: string,
) => {
  if (assetDir === 'logo' || !clientOrganizationId) {
    return 'logo/';
  }
  if (clientOrganizationId && !configurationId) {
    throw new Error('Configuration id missing!');
  }
  return `oo/${clientOrganizationId}/${configurationId}/${assetDir}/`;
};

const boxRenderingByTenant: Record<Tenant, any> = {
  [Tenant.France]: {
    Organization: false,
  },
  [Tenant.Netherlands]: {
    Organization: false,
  },
  [Tenant.Germany]: {
    Organization: true,
  },
  [Tenant.DISAGGermany]: {
    Organization: true,
  },
  [Tenant.Belgium]: {
    Organization: false,
  },
  [Tenant.Italy]: {
    Organization: false,
  },
  [Tenant.Spain]: {
    Organization: false,
  },
  [Tenant.UK]: {
    Organization: false,
  },
  [Tenant.Australia]: {
    Organization: false,
  },
  [Tenant.Unknown]: {
    Organization: false,
  },
};

export const isBoxEnabled = (tenantId: Tenant, keyToCheck: string) => {
  return boxRenderingByTenant[tenantId][keyToCheck] ?? false;
};

export const configFileExtension = 'json';
