import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Utils } from '@helpers/utils';

interface ViewOnlyContextModel {
  isViewOnly: boolean;
  setIsViewOnly: Dispatch<SetStateAction<any>>;
}

export const ViewOnlyContext = createContext<ViewOnlyContextModel>({
  isViewOnly: false,
  setIsViewOnly(viewOnly: boolean): void {
    Utils.consoleLog(`Setting view only ${viewOnly}`);
  },
});

export const ViewOnlyProvider = (props: any) => {
  const [isViewOnly, setIsViewOnly] = useState(false);

  return <ViewOnlyContext.Provider value={{ isViewOnly, setIsViewOnly }}>{props.children}</ViewOnlyContext.Provider>;
};
