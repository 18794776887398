import { FormikContextType } from 'formik';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class ConvertCaseToUpper implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.allControls = formControls ?? [];
    this.formik = formik;
  }

  onChange = (event: any) => {
    const controlValue =
      Object.prototype.toString.call(event.target.value) === '[object String]'
        ? event.target.value?.toUpperCase()
        : event.target.value;
    setTimeout(() => {
      this.formik.setFieldValue(this.control.name, controlValue);
    });
  };
}
