import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toggle } from '@adeccoux/tag-ds';
import { RootState } from '@store/rootReducer';
import { featureConfigurationSlice } from '@store/slices/featureConfiguration.slice';

export enum Version {
  v2 = 2,
  v3 = 3,
}
const VersionToggle = () => {
  const { searchOnboardingEndpointVersion } = useSelector((state: RootState) => state.featureConfiguration.appSettings);
  const dispatch = useDispatch();
  const handleChange = (version: Version) => {
    dispatch(featureConfigurationSlice.actions.setDashboardVersion(version));
  };

  return (
    <div className="tag-ds" style={{ marginBottom: 16 }}>
      <Toggle
        aria-label="Change version of dashboard"
        helperTextOff="v2"
        helperTextOn="v3"
        checked={searchOnboardingEndpointVersion === Version.v3}
        onChange={(isActive) => handleChange(isActive ? Version.v3 : Version.v2)}
      />
    </div>
  );
};
export default VersionToggle;
