import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SnackbarType = 'success' | 'warning' | 'error' | 'info';
type FlashMessage = { message: string; type?: SnackbarType; id?: string };
interface SnackbarStoreState {
  open: boolean;
  type: SnackbarType;
  message: string;
  flashMessages?: FlashMessage[];
}
const initialState: SnackbarStoreState = {
  open: false,
  type: 'success',
  message: '',
};
export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialState as SnackbarStoreState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<{ open: boolean; type: SnackbarType; message: string }>) => {
      state.open = action.payload.open;
      state.type = action.payload.type;
      state.message = action.payload.message;
      return state;
    },
    showSuccess: (state, action: PayloadAction<{ message: string }>) => {
      state.open = true;
      state.type = 'success';
      state.message = action.payload.message;
      return state;
    },
    showError: (state, action: PayloadAction<{ message: string }>) => {
      state.open = true;
      state.type = 'error';
      state.message = action.payload.message;
      return state;
    },
    showWarning: (state, action: PayloadAction<{ message: string }>) => {
      state.open = true;
      state.type = 'warning';
      state.message = action.payload.message;
      return state;
    },
    showInfo: (state, action: PayloadAction<{ message: string }>) => {
      state.open = true;
      state.type = 'info';
      state.message = action.payload.message;
      return state;
    },
    setFlash: (state, action: PayloadAction<FlashMessage>) => {
      state.flashMessages = [
        ...(state.flashMessages || []),
        {
          id: action.payload.id || uuid(),
          type: action.payload.type || 'info',
          message: action.payload.message,
        },
      ];
      localStorage.setItem('flashMessages', JSON.stringify(state.flashMessages));
      return state;
    },
    getFlash: (state, action: PayloadAction<string | undefined>) => {
      let { flashMessages } = state;
      if (!flashMessages) {
        const raw = localStorage.getItem('flashMessages');
        flashMessages = raw ? JSON.parse(raw) : [];
      }
      if (flashMessages && flashMessages.length > 0) {
        let msg;
        if (action.payload) {
          const messageIndex = flashMessages.findIndex((itm) => itm.id === action.payload);
          if (messageIndex > -1) {
            msg = flashMessages[messageIndex];
            flashMessages.splice(messageIndex, 1);
          }
        } else {
          msg = flashMessages.shift();
        }
        if (msg) {
          state.open = true;
          state.type = msg.type || 'info';
          state.message = msg.message;
          state.flashMessages = flashMessages;
        }
        if ((state.flashMessages || []).length > 0) {
          localStorage.setItem('flashMessages', JSON.stringify(state.flashMessages));
        } else {
          localStorage.removeItem('flashMessages');
        }
      }
      return state;
    },
  },
});
