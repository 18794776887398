import { FormikContextType } from 'formik';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class AddressHistoryCurrent implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  formik: FormikContextType<Record<string, any>>;

  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.allControls = formControls ?? [];
    this.formik = formik;
  }

  onChange = () => {
    const allCurrentJobCheckboxes = Object.keys(this.formik.values).filter((x: string) =>
      x.includes('ADDRESS_HISTORY_CURRENT'),
    );

    const findFieldInForm = (fieldName: string) => {
      const currentControlIndex = this.control.name.split(':')[2];

      return Object.keys(this.formik.values).find((x: string) =>
        x.includes(`${Number(currentControlIndex)}:${fieldName}`),
      );
    };

    const historyToField = findFieldInForm('ADDRESS_TO');
    const reasonForLeavingField = findFieldInForm('ADDRESS_HISTORY_CURRENT');

    allCurrentJobCheckboxes.forEach((x) => {
      this.formik.setFieldValue(x, false);
    });

    if (historyToField) {
      this.formik.setFieldValue(historyToField, null);
    }

    if (reasonForLeavingField) {
      this.formik.setFieldValue(reasonForLeavingField, '');
    }

    this.formik.setFieldValue(this.control.name, !this.formik.values[this.control.name]);
  };
}
