import { FormikContextType } from 'formik';

import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

export class SpainNationalityHandler implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  nationalIdentifierTypeControl?: OOControlModelInterface;
  formik: FormikContextType<Record<string, any>>;
  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.allControls = formControls ?? [];
    this.formik = formik;
    this.nationalIdentifierTypeControl = this.allControls.find((item) => item.name === 'NATIONAL_IDENTIFIER_TYPE');
  }

  async onChange(event: any) {
    const { value } = event.target;

    if (!this.nationalIdentifierTypeControl) {
      return;
    }

    if (value !== 'da69ac6a-fdbf-4b90-bb56-bfbd29b22ec0') {
      this.nationalIdentifierTypeControl.isEditableCandidate = true;
      this.nationalIdentifierTypeControl.isEditableRecruiter = true;
      return;
    }

    this.nationalIdentifierTypeControl.isEditableCandidate = false;
    this.nationalIdentifierTypeControl.isEditableRecruiter = false;
    setTimeout(() => {
      this.formik.setFieldValue('NATIONAL_IDENTIFIER_TYPE', 'DNI');
    });
  }
}
