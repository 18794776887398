import { TFunction } from 'i18next';
import { isEqual } from 'lodash';
import { addTranslationToConfiguration } from '@store/slices/selfService.slice';

import { Languages, SelectedConfiguration } from '../models/configurationModels';
import { checkForTranslationInConfig } from './translationHelper';

// Function is called on mounting of component and its being used for
// getting initial values for translation keys
export const getInitialTranslationValues = (
  initialValues: any,
  loadedControl: any,
  selectedConfiguration: SelectedConfiguration,
  t: TFunction,
) => {
  const result = {
    ...initialValues,
  };

  //   Used for fiinding translation value: it can be in redux or it can come from JSON translation object
  const fillWithTranslation = (fieldName: string) => {
    return selectedConfiguration?.languages.supported.reduce((acc: any, curr) => {
      // Field is array in specific cases like control header values
      if (Array.isArray(initialValues[fieldName])) {
        initialValues[fieldName].forEach((option: { label: string }) => {
          const translationFromConfig =
            checkForTranslationInConfig(`control-items:${option.label}`, curr, selectedConfiguration) ||
            checkForTranslationInConfig(option.label, curr, selectedConfiguration);

          if (translationFromConfig) {
            acc[option.label] = {
              ...acc[option.label],
              [curr]: translationFromConfig.value,
            };
          } else {
            //TODO Think more about this condition
            const prefix = fieldName === 'controls' ? 'documents' : 'control-items';

            acc[option.label] = {
              ...acc[option.label],
              [curr]: t(`${prefix}:${option.label}`, { lng: curr }),
            };
          }
        });
      } else {
        const getPrefix = (translationKey: string) => {
          let res = '';

          if (!translationKey) return '';

          if (translationKey.includes(':')) {
            res = translationKey.split(':')[0];
          } else {
            res = translationKey.split('.')[0];
          }

          return res.toLowerCase();
        };

        const translationFromConfig = checkForTranslationInConfig(
          initialValues[fieldName],
          curr,
          selectedConfiguration,
        );

        if (translationFromConfig) {
          acc[curr] = translationFromConfig.value;
        } else {
          acc[curr] = t(`${getPrefix(initialValues[fieldName])}:${initialValues[fieldName]}`, { lng: curr });
        }
      }

      return acc;
    }, {});
  };

  const label = fillWithTranslation('label');
  const description = fillWithTranslation('description');
  const staticText = fillWithTranslation('staticText');
  const minMaxValidationError = fillWithTranslation('minMaxValidationError');
  const selectOptions = fillWithTranslation('selectOptions');

  if (label && loadedControl.label) {
    result.labelTranslations = label || {};
  }

  if (staticText && loadedControl.staticText) {
    result.staticTextTranslations = staticText || {};
  }

  if (minMaxValidationError && loadedControl.minMaxValidationError) {
    result.minMaxValidationErrorTranslations = minMaxValidationError || {};
  }

  if (selectOptions && initialValues.selectOptions) {
    result.selectOptionsTranslations = selectOptions || {};
  }

  if (description && loadedControl.description) {
    result.descriptionTranslations = description || {};
  }

  return result;
};

const fieldsWithTranslations = [
  { name: 'label', translation: 'labelTranslations' },
  {
    name: 'staticText',
    translation: 'staticTextTranslations',
  },
  {
    name: 'minMaxValidationError',
    translation: 'minMaxValidationErrorTranslations',
  },
  {
    name: 'selectOptions',
    translation: 'selectOptionsTranslations',
  },
  {
    name: 'description',
    translation: 'descriptionTranslations',
  },
];

const generatePayload = (key: string, translations: any) => {
  return {
    key,
    translations,
  };
};

export const handleTranslationSubmit = (initialValues: any, values: any, dispatch: any, loadedControl: any) => {
  fieldsWithTranslations.forEach((x) => {
    if (!initialValues[x.translation] && !values[x.translation]) return;

    const nameChanged = (currentName?: string, loadedControlName?: string) => {
      if (!currentName || !loadedControlName) return false;
      return currentName !== loadedControlName;
    };

    const valueChanged = (initialValuesValue: string, currentValue: string) =>
      !isEqual(initialValuesValue, currentValue);

    Object.keys(values[x.translation]).forEach((z) => {
      if (z === '__NO_TRANSLATION') return;

      if (typeof values[x.translation][z] === 'object') {
        if (valueChanged(initialValues[x.translation][z], values[x.translation][z])) {
          const payload = generatePayload(z, values[x.translation][z]);

          dispatch(addTranslationToConfiguration(payload));
        }
      } else {
        if (valueChanged(initialValues[x.translation], values[x.translation])) {
          const payload = generatePayload(values[x.name], values[x.translation]);

          dispatch(addTranslationToConfiguration(payload));
        }
      }
    });

    if (nameChanged(values[x.name], loadedControl[x.name])) {
      const payload = generatePayload(values[x.name], values[x.translation]);

      dispatch(addTranslationToConfiguration(payload));
    }
  });
};

export const handleSecondLevelTranslationSubmit = (
  loadedDocument: any,
  availableLanguages: Languages | undefined,
  initialTranslations: any[],
  t: TFunction,
  dispatch: any,
) => {
  if (!loadedDocument?.controls.controls || !availableLanguages) return;

  const getTranslationFromJson = (label: string) => {
    const res: any = {};
    availableLanguages?.supported.forEach((lng) => {
      res[lng] = t(label, { lng });
    });

    return res;
  };

  initialTranslations.forEach((x: any) => {
    const nameChanged = x.name !== x.newName;
    const labelValuesChanged = !isEqual(getTranslationFromJson(x.labelKey), x.label);
    const minMaxValuesChanged = !isEqual(getTranslationFromJson(x.minMaxValidationErrorKey), x.minMaxValidationError);

    if (nameChanged) {
      const lblPayload = generatePayload(x.labelKey, x.label);
      const mmaxPayload = generatePayload(x.minMaxValidationErrorKey, x.minMaxValidationError);

      dispatch(addTranslationToConfiguration(lblPayload));
      if (mmaxPayload.key) {
        dispatch(addTranslationToConfiguration(mmaxPayload));
      }
    } else {
      if (labelValuesChanged) {
        const payload = generatePayload(x.labelKey, x.label);
        dispatch(addTranslationToConfiguration(payload));
      }

      if (minMaxValuesChanged && x.minMaxValidationErrorKey) {
        const payload = generatePayload(x.minMaxValidationErrorKey, x.minMaxValidationError);
        dispatch(addTranslationToConfiguration(payload));
      }
    }
  });
};
// Used only for nested document controls
export const initializeTranslationValue = (
  lookup: { [key: string]: any },
  loadedDocument: any,
  selectedConfiguration: SelectedConfiguration,
  availableLanguages: Languages,
  t: TFunction,
) => {
  const documentControls = { ...lookup[loadedDocument.type].initialValues, ...loadedDocument.controls }.controls;

  const translationValues = documentControls?.map((x: any) => {
    const parts = x.label.split('.');
    const transRoot = parts.slice(0, -1).join('.');
    const result: any = {
      name: x.name,
    };

    const existsInConfiguration = (label: string, lng: string, fallback: string) => {
      if (!label) return '';

      const res = selectedConfiguration.__translation?.find((x) => x.key === label)?.translations;
      const findValue = res?.find((x) => x.language === lng)?.value;

      return findValue ? findValue : fallback;
    };

    availableLanguages?.supported.forEach((y) => {
      result.label = {
        ...result.label,
        [y]: existsInConfiguration(x.label, y, t(x.label, { lng: y })),
      };
      result.labelKey = x.label;
      result.minMaxValidationError = {
        ...result.minMaxValidationError,
        [y]: existsInConfiguration(x.minMaxValidationError, y, t(`${transRoot}.minMaxValidationError`, { lng: y })),
      };
      result.minMaxValidationErrorKey = x.minMaxValidationError;
      result.newName = x.name;
    });

    return result;
  });

  return translationValues;
};
