import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useInjection } from '@context/inversify-context-provider';
import { useTenant } from '@hooks/useComponentMapping';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { snackbarSlice } from '@store/slices/snackbar';

import { AamBackendApi } from '../../../../libs/aamBackendApi';

type AdminCsvExportButtonProps = {
  options: string[];
  onSuccess?: any;
  className?: string;
  style?: any;
};

const AdminCsvExportButton = (props: AdminCsvExportButtonProps) => {
  const { options, className, style } = props;

  const { t } = useTranslation(['recruiter-deprecated']);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = useState(options[0] || 'ClientOrganization');

  const aamBackendApi = useInjection(AamBackendApi);
  const tenantId = useTenant();

  const handleClick = async () => {
    try {
      const { data } = await aamBackendApi.exportCsv(tenantId, selectedOption);
      if (data.size === 0) {
        dispatch(
          snackbarSlice.actions.showError({
            message: t('recruiter-deprecated:CANDIDATES_OVERVIEW.onExportError'),
          }),
        );
      } else {
        const fileName = `${selectedOption}.csv`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (err) {
      dispatch(
        snackbarSlice.actions.showError({
          message: t('recruiter-deprecated:CANDIDATES_OVERVIEW.onExportError'),
        }),
      );
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, val: string) => {
    setSelectedOption(val);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box>
      <ButtonGroup variant="outlined" size="small" component="span" ref={anchorRef} aria-label="split button">
        <Button onClick={handleClick} className={className || ''} style={style || {}}>
          {t(`recruiter:GENERAL.CONFIGURATION.download${selectedOption || options[0]}CsvList`)}
        </Button>
        <Button
          className={className || ''}
          style={style || {}}
          size="small"
          component="span"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select csv import"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon color="primary" />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
        nonce={undefined}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === selectedOption}
                      onClick={(event) => handleMenuItemClick(event, option)}
                    >
                      {t(`recruiter:GENERAL.CONFIGURATION.download${option}CsvList`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
export default AdminCsvExportButton;
