import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import {
  getPaymentInfoDocuments,
  useRecruiterValidatesPaymentInfo,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/useRecruiterValidatesPaymentInfo';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';

export const useRecruiterUploadsWorkContract = ({
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const state = useRecruiterValidatesPaymentInfo({ setPageStepContext, flowWrapper, steps });

  const { step: currentStep } = flowWrapper.getCurrentStepAndPage();
  const isVerified = getPaymentInfoDocuments(steps).every(
    (x) => x?.userDocument?.additionalData?.status === OnboardingDocumentStatus.VERIFIED,
  );
  const active = (currentStep === 'DOCUMENT_VALIDATION2' && state.completed) || currentStep === 'FEEDBACK_STEP';
  const isStepCompleted = flowWrapper.isStepCompleted('DOCUMENT_VALIDATION2');
  const completed = isStepCompleted || isVerified;
  const disabled = active || completed ? false : !isVerified;

  return {
    active,
    disabled,
    completed,
    onClick: () => {
      const step = steps.find((x) => x.name === 'DOCUMENT_UPLOAD2');
      setPageStepContext({ step, page: undefined });
    },
  };
};
