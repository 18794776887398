import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@components/app/Header/Header';
import { Container, Grid } from '@mui/material';

import { OONavigationContext } from '../context/NavigationContext';

export interface AccessRestrictedProps {
  translation?: string;
  translationKey?: string;
  isAuthenticated?: boolean;
  translationParameters?: any;
}
export const OOAccessRestricted: React.FC<AccessRestrictedProps> = ({
  translation = '',
  translationKey = '',
  isAuthenticated = true,
  translationParameters = {},
}) => {
  const navigationContext = useContext(OONavigationContext);
  const { t } = useTranslation(translation);

  return (
    <Container maxWidth="md">
      <Header onBackPress={() => navigationContext.toPrevIndex()} isAuthenticated={isAuthenticated} />
      <Grid container spacing={3} direction="column">
        <Grid item>
          <p>{t(translationKey, translationParameters)}</p>
        </Grid>
      </Grid>
    </Container>
  );
};
