import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CheckIcon } from '@components/shared/customIcons/app/CheckIcon';
import { Box, Grid, Theme, Typography, useTheme } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { OONavigationContext } from '../context/NavigationContext';
import { useFlowWrapper } from '../hooks/useFlowWrapper';
import { OOPageModel } from '../models/FormModel';
import { OOStepModelWithCompletionInfo } from '../pages/Dashboard';

type TState = 'done' | 'active' | 'hidden';

const StepperItem = ({ state, isLast, theme }: { state: TState; isLast: boolean; theme: Theme }) => (
  <Box display="flex" flexDirection="row" alignItems="center">
    <Box
      className={`client-stepper__step client-stepper__step--${state}`}
      bgcolor={state !== 'hidden' ? theme.palette.primary.main : 'transparent'}
      boxShadow={state === 'active' ? `0px 0px 10px ${theme.palette.primary.light}` : ''}
    >
      {state === 'done' && <CheckIcon />}
    </Box>
    {!isLast && (
      <Grid className="client-stepper__line" style={{ width: 17, borderTopColor: theme.palette.primary.main }} />
    )}
  </Box>
);

export type StepperInputProps = {
  isLastPage?: boolean;
  isNameVisible?: boolean;
};

export const OOStepper: React.FC<StepperInputProps> = ({ isLastPage, isNameVisible = false }) => {
  const { currentStep, form } = useSelector((state: RootState) => state.oneOnboarding);

  const navigationContext = React.useContext(OONavigationContext);
  const { t } = useTranslation(['stepper', 'approval', 'hiring']);
  const theme = useTheme();
  const flowWrapper = useFlowWrapper();

  const steps: OOStepModelWithCompletionInfo[] = useMemo(() => {
    if (!flowWrapper) return [];
    const currentIndex = flowWrapper.steps.findIndex((step) => step.name === currentStep);
    return flowWrapper.steps.map((step, index) => {
      const isCompleted = index < currentIndex;
      const isCurrent = index === currentIndex;
      return { step: step, isCompleted, isCurrent };
    });
  }, [currentStep, flowWrapper]);

  const candidateSteps = steps.filter((s) => s.step.role.includes('candidate'));

  const currentPageAndStep = useMemo(() => {
    return flowWrapper?.getCurrentStepAndFormFromNavigationPage(navigationContext.currentPage);
  }, [navigationContext, flowWrapper]);

  const isVisible = steps
    .find((s) => s.step.name === currentPageAndStep?.step)
    ?.step.elements.some((e) => e.name === 'STEPPER');

  const isStepCompleted = (
    allSteps: OOStepModelWithCompletionInfo[],
    step: OOStepModelWithCompletionInfo,
    index: number,
  ): boolean => {
    if (step.isCompleted) {
      return true;
    }

    const navigationCurStepIndex = allSteps.findIndex((s) => s.step.name === currentPageAndStep?.step);

    return index < navigationCurStepIndex;
  };

  let stepper = <></>;

  const step = flowWrapper?.getStep(currentStep);
  if (!step) {
    return <></>;
  }

  const currentStepIndex = step.pages.map((p) => p.name).indexOf(form);
  const pageForm: OOPageModel | undefined = step.pages[currentStepIndex];
  const displayName: string | undefined = pageForm?.displayName;
  const formDescription: string | undefined = pageForm?.description;
  const prefix = displayName ? displayName.split('.')[0].toLowerCase() : 'entry';

  if (isVisible) {
    stepper = (
      <Grid container justifyContent="center">
        <Typography className="client-stepper__title-text">
          {isLastPage ? t('stepper:actions.lastStep') : t('stepper:' + currentPageAndStep?.stepLabel)}
        </Typography>
        <Grid container direction="row" className="client-stepper__container">
          {steps.length > 0 && (
            <>
              <Grid style={{ flexGrow: 1 }} />
              {candidateSteps.map((step, index, array) => {
                const state =
                  step.step.name === currentPageAndStep?.step
                    ? isLastPage === true
                      ? 'done'
                      : 'active'
                    : isStepCompleted(array, step, index)
                    ? 'done'
                    : 'hidden';
                return (
                  <StepperItem key={step.step.name} state={state} isLast={index === array.length - 1} theme={theme} />
                );
              })}
              <Grid className="client-stepper__whiteline" style={{ flexGrow: 1 }} />
            </>
          )}
        </Grid>
        {isNameVisible && displayName && formDescription && (
          <Grid item xs={12}>
            <h4>{displayName && t(`${prefix}:${displayName}`)}</h4>
            <p>{formDescription && t(`${prefix}:${formDescription}`)}</p>
          </Grid>
        )}
      </Grid>
    );
  }

  return stepper;
};
