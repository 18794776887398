import {
  Component,
  ControlCategory,
  ControlType,
  OOControlModelInterface,
  OOControls,
  OODateControlModelInterface,
  OOSelectorCheckboxModelInterface,
  OOStringControlModelInterface,
} from '../interfaces';

export class OOControlModel implements OOControlModelInterface {
  public name: OOControls | string;
  public category: ControlCategory;
  public isMandatory: boolean;
  public isEditableCandidate: boolean;
  public isEditableRecruiter: boolean;
  public isVisibleCandidate: boolean;
  public isVisibleRecruiter: boolean;
  public type: ControlType;
  public label: string;
  public link: string;
  public description: string;
  public sourceClass: string;
  public sourceField: string;
  public integrationTarget: string;
  public messageField: string;
  public controlItemName?: string;
  public controlHeaderId?: string;
  public controlHeaderName?: string;
  public minLength: number | Date;
  public maxLength: number | Date;
  public regexValidation?: string;
  public validationEndpoint?: number;
  public displayFormat?: number;
  public value: any;
  public minOffset?: number;
  public maxOffset?: number;
  public renderCondition?: Array<Record<string, any>>;
  public mandatoryCondition?: Array<Record<string, any>>;
  public editableCondition?: Array<Record<string, any>>;
  public completeCondition?: Array<Record<string, any>>;
  public calculationClassUI?: { name: string };
  public validationErrors?: Record<string, any>;
  public min?: number;
  public max?: number;
  public staticText?: string;
  public imageSrc?: string;
  public controlHeaderClass?: string;
  public documentId?: string;
  public filterCondition?: Array<Record<string, any>>;
  public regExpValidationError?: Record<string, any>;
  public minMaxLengthValidationError?: Record<string, any>;
  public minMaxOffsetValidationError?: Record<string, any>;
  public minMaxValidationError?: Record<string, any>;
  public customValidationError?: Record<string, any>;
  public mandatoryValidationError?: Record<string, any>;
  public topSelectValue?: string;
  public defaultSelectValue?: string;
  public translationSelectItems?: string;
  public reducerSelectItemsMap?: Record<string, any>;
  public excludedSelectItems?: Record<string, any>;
  public parameters?: Array<Record<string, any>>;
  public populatedLabel?: string;
  public defaultValue?: string;
  public originalName?: string;
  public parentFormName?: string;
  public parentFormIndex?: number;
  public subControls?: Array<OOControlModel> | Array<Array<OOControlModel>>;
  public component: Component;
  public customStyles?: Array<string>;
  public sequence?: number;
  public version?: number;
  public documents?: any[];
  public maxSubControls?: number;

  constructor(props: OOControlModelInterface) {
    this.name = props.name;
    this.originalName = props.originalName;
    this.category = props.category;
    this.isMandatory = props.isMandatory;
    this.isEditableCandidate = props.isEditableCandidate;
    this.isEditableRecruiter = props.isEditableRecruiter;
    this.isVisibleCandidate = props.isVisibleCandidate;
    this.isVisibleRecruiter = props.isVisibleRecruiter;
    this.type = props.type;
    this.label = props.label;
    this.link = props.link;
    this.description = props.description;
    this.sourceClass = props.sourceClass;
    this.sourceField = props.sourceField;
    this.integrationTarget = props.integrationTarget;
    this.messageField = props.messageField;
    this.controlItemName = props.controlItemName;
    this.controlHeaderId = props.controlHeaderId;
    this.controlHeaderName = props.controlHeaderName;
    this.minLength = props.minLength;
    this.maxLength = props.maxLength;
    this.regexValidation = props.regexValidation;
    this.validationEndpoint = props.validationEndpoint;
    this.displayFormat = props.displayFormat;
    this.minOffset = props.minOffset;
    this.maxOffset = props.maxOffset;
    this.renderCondition = props.renderCondition;
    this.mandatoryCondition = props.mandatoryCondition;
    this.editableCondition = props.editableCondition;
    this.completeCondition = props.completeCondition;
    this.calculationClassUI = props.calculationClassUI;
    this.validationErrors = props.validationErrors;
    this.min = props.min;
    this.max = props.max;
    this.staticText = props.staticText;
    this.imageSrc = props.imageSrc;
    this.controlHeaderClass = props.controlHeaderClass;
    this.documentId = props.documentId;
    this.filterCondition = props.filterCondition;
    this.regExpValidationError = props.regExpValidationError;
    this.minMaxLengthValidationError = props.minMaxLengthValidationError;
    this.minMaxOffsetValidationError = props.minMaxOffsetValidationError;
    this.minMaxValidationError = props.minMaxValidationError;
    this.customValidationError = props.customValidationError;
    this.mandatoryValidationError = props.mandatoryValidationError;
    this.topSelectValue = props.topSelectValue;
    this.defaultSelectValue = props.defaultSelectValue;
    this.translationSelectItems = props.translationSelectItems;
    this.reducerSelectItemsMap = props.reducerSelectItemsMap;
    this.excludedSelectItems = props.excludedSelectItems;
    this.parameters = props.parameters;
    this.populatedLabel = props.populatedLabel;
    this.defaultValue = props.defaultValue;
    this.component = props.component;
    this.customStyles = props.customStyles;
    this.sequence = props.sequence;
    this.maxSubControls = props.maxSubControls;

    if (this.type === 'boolean' && props.value === undefined) {
      this.value = false;
    } else {
      this.value = props.value;
    }
    if ((props.category === 'array' || props.category === 'static') && props.subControls) {
      const subControls = (props.subControls || []).map((control, index) => {
        if (Array.isArray(control)) {
          return control.map((subControl) => {
            const newControl = new OOControlModel({
              ...subControl,
              originalName: subControl.name,
              name: OOControlModel.generateNameForSubControl(props.name, index, subControl.name),
            });
            newControl.parentFormName = props.name;
            newControl.parentFormIndex = index;

            return newControl;
          });
        } else {
          return new OOControlModel(control);
        }
      });
      this.subControls = subControls as Array<OOControlModel> | Array<Array<OOControlModel>>;
    }
  }

  public static generateNameForSubControl(parentFormName: string, index: number, subControlName: string): string {
    return `ARRAY_ELEMENT:${parentFormName}:${index}:${subControlName}`;
  }

  public static fromJson(json: any): OOControlModel {
    return new OOControlModel(json);
  }

  public isStringType(): this is OOStringControlModelInterface {
    return this.type === 'string';
  }

  public isNumberType(): this is OOStringControlModelInterface {
    return this.type === 'number';
  }

  public isDateType(): this is OODateControlModelInterface {
    return this.type === 'date';
  }

  public isSelectorCheckboxType(): this is OOSelectorCheckboxModelInterface {
    return this.type === 'boolean';
  }
}
