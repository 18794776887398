import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToastrSettings {
  message: string;
  title?: string;
}

export interface ToastrStoreState {
  error?: ToastrSettings;
  [key: string]: any;
}

const initialState: ToastrStoreState = {};

export const toastSlice = createSlice({
  name: 'assignment',
  initialState: initialState as ToastrStoreState,
  reducers: {
    showError: (state, action: PayloadAction<ToastrSettings | undefined>) => {
      state.error = action.payload;
      return state;
    },
  },
});
