import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ViewOnlyContext } from '@context/view-only-provider';
import ConditionSign from '@features/selfService/components/ConditionSign/ConditionSign';
import { translationHelper } from '@features/selfService/helpers/translationHelper';
import { Box } from '@mui/material';
import { RootState } from '@store/rootReducer';

import DraggableComponent from '../DraggableComponent';

import styles from '../../ConfigurationSetup.module.scss';

interface Props {
  document: any;
  setDocumentToSignDetails: () => void;
  isActive: boolean;
  handleDelete: () => void;
  onDragDrop: (e: any, targetField: string) => void;
}

const DocumentToSignListItem: React.FC<Props> = ({
  document,
  setDocumentToSignDetails,
  isActive,
  handleDelete,
  onDragDrop,
}) => {
  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const { t, i18n } = useTranslation(['hiring', 'approval']);
  const { isViewOnly } = useContext(ViewOnlyContext);

  const getTranslation = (key?: string) => {
    if (!key) return '';

    return translationHelper(key, i18n, t, selectedConfiguration ?? undefined);
  };

  const documentHavingCondition = document.condition?.length > 0;

  return (
    <div style={{ marginBottom: 16, position: 'relative' }}>
      <DraggableComponent control={document} onDrop={onDragDrop}>
        <div
          key={document.name}
          className={`${styles.controlListItem} ${styles.controlListItem__document}`}
          style={{ border: isActive ? '1px solid #009ACC' : '', cursor: 'pointer' }}
          onClick={setDocumentToSignDetails}
        >
          <Box display="flex" alignItems="center">
            {!isViewOnly && (
              <div className="material-icons" style={{ fontSize: 15 }}>
                drag_indicator
              </div>
            )}
            <p className={`subtitle ${styles.controlListItem__label}`} style={{ wordBreak: 'break-all' }}>
              {getTranslation(document.label)}
            </p>
          </Box>
          {!isViewOnly && (
            <Box display="flex">
              <div
                className={styles.controlListItem__button}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
              >
                <span className="material-icons-outlined">delete</span>
              </div>
            </Box>
          )}
        </div>
        {documentHavingCondition && <ConditionSign style={{ top: '50%', transform: 'translateY(-50%)' }} />}
      </DraggableComponent>
    </div>
  );
};

export default DocumentToSignListItem;
