/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjection } from '@context/inversify-context-provider';
import { OODocumentContext } from '@features/oneOnboarding/context/DocumentContext';
import { completeCondition } from '@features/oneOnboarding/hooks/useRenderCondition';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OODocumentationModel } from '@features/oneOnboarding/models/DocumentationModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { UserFlowModel } from '@features/oneOnboarding/models/UserFlow';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { useCandidateProfileData } from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/hooks';
import { OnboardingDocumentStatus } from '@helpers/OnboardingSteps';
import { useGetUserFlow } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AamBackendApi } from '../../../../../libs/aamBackendApi';
import DocumentList from './DocumentList/DocumentList';
import { RenderDocumentPage } from './DocumentPages/RenderDocumentPage';

import styles from './DocumentDetails.module.scss';

export interface DocumentValidationProps {
  step: OOStepModel;
  flowWrapper: OOFlowWrapper;
  setPage: any;
}
const DocumentDetails: FC<DocumentValidationProps> = ({ step, setPage, flowWrapper }) => {
  const [validationStepName, setValidationStepName] = useState<string>('');
  const [alert, setAlert] = useState<boolean>(false);

  const { setStep, completeStepAvailable, setCompleteStepAvailable } = useContext(OODocumentContext);
  const { t } = useTranslation(['documents', 'candidate_recruiter']);

  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();

  const queryClient = useQueryClient();

  const { steps } = useCandidateProfileData();
  const aamBackendApi = useInjection(AamBackendApi);

  const foundStep = useMemo(() => flowWrapper.getStep(step.name), [steps, step.name]);
  const compareDocumentations = (itemA: OODocumentationModel, itemB: OODocumentationModel) => {
    if (foundStep) {
      const indexA = foundStep.documentations.indexOf(itemA);
      const indexB = foundStep.documentations.indexOf(itemB);

      if (itemA.isMandatory && !itemB.isMandatory) {
        return -1;
      }

      if (itemB.isMandatory && !itemA.isMandatory) {
        return 1;
      }

      return indexA < indexB ? -1 : 1;
    } else {
      return 0;
    }
  };

  const documentsUpload2Step = flowWrapper.getDocumentStep(OOStepsEnum.documentUploadTwo)!;
  let additionalDocumentations: OODocumentationModel[] = [];
  if (step.name === OOStepsEnum.documentUpload && documentsUpload2Step?.uploadEarly) {
    additionalDocumentations = documentsUpload2Step.documentations
      .filter((item) => item.documents.some((d) => d.userDocument))
      .map((item) => ({
        ...item,
        uploadEarly: true,
        isMandatory: false,
      }));
  }
  const documentations = useMemo(
    () => (foundStep ? foundStep.documentations.concat(additionalDocumentations).sort(compareDocumentations) : []),
    [compareDocumentations, foundStep],
  );

  const hasValidationStep = useMemo(() => {
    const s = flowWrapper.steps.filter((s) => s.name === step.name);
    const index: number = flowWrapper.steps.indexOf(s[0]);
    for (const nextStep of flowWrapper.steps.slice(index)) {
      if (nextStep.name.toLowerCase().includes('validation')) {
        return true;
      }
    }
    return false;
  }, [flowWrapper]);

  useEffect(() => {
    setStep(step);
  }, [step, setStep]);

  const { data: userFlowData } = useGetUserFlow(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    true,
    (userFlow: UserFlowModel) => {
      const flow = OOFlowWrapper.create(userFlow?.selectedConfiguration?.flow);
      const s = flow.steps.filter(({ name }) => step.name === name);
      const csIndex: number = flow.steps.indexOf(s[0]);
      const validationStep = flow.steps
        .slice(csIndex)
        .find((remainingStep) => remainingStep.name.toLowerCase().includes('validation'));
      if (validationStep) {
        setValidationStepName(validationStep.name);
      }
    },
  );
  useEffect(() => {
    (async () => {
      if (userFlowData?.currentStep) {
        const mandatoryDocs = documentations.filter((d: any) => d.isMandatory);
        const allUploadedDocsVerified = mandatoryDocs
          .flatMap((d: any) => d.documents)
          .filter((d: any) => d.userDocument)
          .map((d: any) => d.userDocument.additionalData.status)
          .every((s: any) => s === OnboardingDocumentStatus.VERIFIED);
        const missingMandatoryDocumentationForUpload = mandatoryDocs.some((documentation) => {
          return documentation.documents.every((document) => !document.userDocument);
        });
        const currentStep = userFlowData?.selectedConfiguration.flow.find(
          (d: any) => d.name === userFlowData.currentStep,
        );

        if (currentStep) {
          const isStepComplete = completeCondition(currentStep, flowWrapper, {});
          if (isStepComplete) {
            setCompleteStepAvailable(false);
            setAlert(true);
          }
        }

        const data = {
          hasMandatoryDocumentsVerified: allUploadedDocsVerified && !missingMandatoryDocumentationForUpload,
          currentStep: userFlowData?.currentStep,
          currentPage: userFlowData?.currentPage ?? '',
        };
        await aamBackendApi.updatePlainUserFlow(clientOrganizationId, configurationId, userId, applicationId, data);
      }
    })();
  }, [userFlowData, completeStepAvailable]);

  const { mutate: completeStepForUser } = useMutation({
    mutationFn: (stepName: any) =>
      aamBackendApi.ooAdminCompleteStepForUser(
        clientOrganizationId,
        configurationId,
        userId,
        { stepName },
        applicationId,
      ),
    onSuccess: (resp) => {
      queryClient.refetchQueries(['getUserOOFlow']);
      queryClient.refetchQueries(['getUserFlow']);
      queryClient.refetchQueries(['getUserOOFlowDocumentUpload']);
      queryClient.invalidateQueries(['getOnboardingChecked', clientOrganizationId, configurationId, userId]);

      if (resp?.currentStep) {
        const currentStep = flowWrapper.getStep(resp.currentStep);
        if (currentStep?.name === 'HIRE' || currentStep?.name === 'MATCH') {
          setStep(currentStep);
          setPage(currentStep.pages[0]);
        }
      }
    },
  });

  return (
    <div className={styles.candidateDocumentsUploadPage}>
      <DocumentList items={documentations} hasValidationStep={hasValidationStep} />
      <Box display="flex" justifyContent="flex-end" flexDirection="column" alignItems="end">
        {alert && (
          <div className={styles.emptyFieldsAlert}>{t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.alert')}</div>
        )}
        <div className="tag-ds ">
          <button
            className=""
            disabled={!completeStepAvailable}
            onClick={() => completeStepForUser(validationStepName)}
          >
            {t('candidate_recruiter:DOCUMENTS.DOCUMENT_UPLOAD.completeStep')}
          </button>
        </div>
      </Box>
      <RenderDocumentPage />
    </div>
  );
};

export default DocumentDetails;
