import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';
import { useUserCheck } from '@hooks/apiHooks';
import { OnboardingStatus } from '@models/onboarding.model';

export const useRecruiterEntersHiringData = ({
  onboarding,
  setPageStepContext,
  flowWrapper,
  steps,
}: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  const { page, step } = flowWrapper.getCurrentStepAndPage();
  const { onboardingChecked } = useUserCheck(onboarding);
  const isStepCompleted = flowWrapper.isStepCompleted('HIRE', 'CONTRACT_INFO');

  const active = (step === 'HIRE' && page === 'CONTRACT_INFO') || onboardingChecked?.hiringEnabled === true;
  const completed = active
    ? false
    : isStepCompleted || !!onboardingChecked?.hiringEnabled || onboarding?.status === OnboardingStatus.readyForHire;

  const disabled = active || completed ? false : !onboardingChecked?.hiringEnabled;
  return {
    active,
    disabled,
    completed,
    onClick: (): void => {
      const step = steps.find((x) => x.name === 'HIRE');
      const page = step?.pages?.find((x) => x.name === 'CONTRACT_INFO');
      setPageStepContext({ step, page });
    },
  };
};
export default useRecruiterEntersHiringData;
