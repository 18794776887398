import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { getClientOrganizationId, getConfigurationId } from '@helpers/tenant.helper';
import { useAllApplicationsForUserAndClientOrgUploadLimitSize } from '@hooks/apiHooks';
import { Onboarding } from '@models/onboarding.model';
import { Box, Button, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import BottomBox from '../../../components/shared/BottomBox/BottomBox';
import { AamBackendApi } from '../../../libs/aamBackendApi';
import { CandidatePageLayout } from '../components/CandidatePageLayout/CandidatePageLayout';
import StepsOverview from '../controls/StepsOverview';
import { useFlowWrapper } from '../hooks/useFlowWrapper';
import { OOStepModel } from '../models/StepModel';
import { CheckResult } from './StartPage';

export interface OOStepModelWithCompletionInfo {
  step: OOStepModel;
  isCompleted: boolean;
  isCurrent: boolean;
}

export const Dashboard: React.VoidFunctionComponent = () => {
  const { t } = useTranslation(['stepper', 'candidate']);
  const { oneOnboarding } = useSelector((state: RootState) => state);
  const { currentStep } = useSelector((state: RootState) => state.oneOnboarding);
  const flowWrapper = useFlowWrapper();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const aamBackendApi = useInjection(AamBackendApi);
  const isFlowLastStep = flowWrapper?.isFlowLastStep(currentStep);
  const currentCandidateStep = flowWrapper?.getCurrentCandidateStep(currentStep);
  const nextCandidateStep = flowWrapper?.getNextCandidateStep(currentStep);
  const lastCandidateStep = flowWrapper?.getLastCandidateStep();
  const isLastCandidateStepAvailable = flowWrapper?.isLastCandidateStepAvailable(lastCandidateStep?.name, currentStep);
  const tenantId = useSelector((state: RootState) => state.tenant.tenantId);
  const userId = useSelector((state: RootState) => state.authApp.userId);
  const applicationId = useSelector((state: RootState) => state.oneOnboarding.applicationId);
  const stepsOverviewHidden = useSelector((state: RootState) => state.oneOnboarding.stepsOverviewHidden);

  const clientOrganizationId = useMemo(() => {
    if (oneOnboarding.clientOrganizationId) {
      return oneOnboarding.clientOrganizationId;
    }
    return getClientOrganizationId() || '';
  }, [oneOnboarding.clientOrganizationId]);

  const configurationId = useMemo(() => {
    if (oneOnboarding.selectedConfigurationId) {
      return oneOnboarding.selectedConfigurationId;
    }
    return getConfigurationId() || '';
  }, [oneOnboarding.selectedConfigurationId]);

  const legalConsents = localStorage.getItem('campaignConsents');
  const payload = legalConsents ? { legalConsent: JSON.parse(legalConsents) } : undefined;

  const { isFetching: isFetchingUpdateOneOnboarding } = useQuery<Onboarding>({
    queryKey: ['updateOnboarding', clientOrganizationId, configurationId, applicationId, userId, payload],
    queryFn: () =>
      aamBackendApi.updateOneOnboarding(
        clientOrganizationId || '',
        configurationId || '',
        userId || '',
        applicationId || '',
        payload,
      ),
    enabled: !!legalConsents,
    onSuccess: () => {
      localStorage.removeItem('campaignConsents');
    },
  });

  useQuery({
    queryKey: ['checkUser', tenantId, clientOrganizationId, configurationId, userId, isFetchingUpdateOneOnboarding],
    queryFn: () => aamBackendApi.checkUser(tenantId, userId, clientOrganizationId, configurationId),
    enabled: !!userId && !isFetchingUpdateOneOnboarding,
    onSuccess: (data: CheckResult) => {
      if (!data.legalRequiredConsentAccepted) {
        history.push('/oo/flow/consents');
      }
    },
  });

  const { data = [], isFetching } = useAllApplicationsForUserAndClientOrgUploadLimitSize(userId);

  const navigateToStep = useCallback(
    (step: OOStepModel | undefined, isLastStepFinished?: boolean) => {
      if (!step) return null;
      let stepRoute = '';

      if (step.pages.length > 0 || !!isLastStepFinished) {
        stepRoute = `${step.name.toLowerCase().replaceAll('_', '-')}-${step.pages[0].name
          .toLowerCase()
          .replaceAll('_', '-')}`;
        dispatch(oneOnboardingSlice.actions.setCurrentForm({ step: step.name, form: step.pages[0].name }));
      } else {
        stepRoute = step.name.toLowerCase().replaceAll('_', '-');
        dispatch(oneOnboardingSlice.actions.setCurrentForm({ step: step.name, form: null }));
      }
      history.push(`/oo/flow/${stepRoute}${location.search ? location.search : ''}`, { overview: false });
    },
    [history, location.search, dispatch],
  );

  const onNextPageClick = useCallback(() => {
    if (isFlowLastStep) {
      navigateToStep(lastCandidateStep, true);
    } else if (currentCandidateStep || nextCandidateStep) {
      navigateToStep(currentCandidateStep ? currentCandidateStep : nextCandidateStep);
    } else if (isLastCandidateStepAvailable) {
      navigateToStep(lastCandidateStep, false);
    }
  }, [
    currentCandidateStep,
    isFlowLastStep,
    lastCandidateStep,
    navigateToStep,
    nextCandidateStep,
    isLastCandidateStepAvailable,
  ]);

  useEffect(() => {
    if (stepsOverviewHidden) {
      onNextPageClick();
    }
  }, [onNextPageClick, stepsOverviewHidden]);

  useEffect(() => {
    queryClient.refetchQueries(['getFlowAndUpdateRedux']);
  }, [queryClient]);

  if (!flowWrapper || (isFetching && !data.length)) {
    return <Loader />;
  }

  return (
    <CandidatePageLayout hideOverview={true}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        minHeight="80vh"
        sx={{ margin: '16px auto 0 auto' }}
      >
        <Grid container spacing={3}>
          <Grid item>
            <StepsOverview />
          </Grid>
        </Grid>
        <BottomBox py={2}>
          <Button id="dashboard-next" variant="contained" color="primary" onClick={onNextPageClick}>
            {t('candidate:GENERAL.PAGES.nextPage')}
          </Button>
        </BottomBox>
      </Box>
    </CandidatePageLayout>
  );
};
