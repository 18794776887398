import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Notification, NotificationBody, NotificationHeader } from '@adeccoux/tag-ds';
import { composeTranslationPath } from '@features/selfService/helpers/translationHelper';
import { RootState } from '@store/rootReducer';

import FullScreenPage from '../../../../../components/FullScreenPage/FullScreenPage';
import { arrayMove } from '../../DraggableComponent';
import EditOptionModal from './EditOptionModal';
import SingleSelectItem from './SingleSelectItem';
import { Item } from './SingleSelectOptions';

import style from './SingleSelectOptions.module.scss';

interface Props {
  onClose: () => void;
  nameValue: any;
  items: any;
  setItems: any;
  handleFormikChange: any;
  setFieldValue: any;
  selectOptionsTranslations: any;
  defaultValue: string | string[];
  isMultiple: boolean;
}

const EditList: React.FC<Props> = ({
  onClose,
  nameValue,
  items,
  setItems,
  handleFormikChange,
  setFieldValue,
  selectOptionsTranslations,
  defaultValue,
  isMultiple,
}) => {
  const [itemForEdit, setItemForEdit] = useState<Item | null>(null);
  const [csvOptions, setCsvOptions] = useState<string>('');

  const { selectedFeature } = useSelector((store: RootState) => store.selfService);

  const onOptionDrop = (e: any, targetField: string) => {
    if (targetField === null) return;

    const currentArray = items;
    const id = e.dataTransfer.getData('name');

    const dragIndex = currentArray?.findIndex((x: any) => x.name === id) || 0;
    const targetIndex = currentArray?.findIndex((x: any) => x.name === targetField) || 0;

    const newArr = arrayMove(currentArray || [], dragIndex, targetIndex);

    const withNewSequence = newArr.map((x, i) => {
      const newSequence = i * 10;
      return { ...x, sequence: newSequence };
    });

    setItems(withNewSequence);
    handleFormikChange(withNewSequence);
  };

  const handleItemChange = (newItem: Item) => {
    const newItems = items?.map((x: any) => {
      if (x.uuid === newItem?.uuid) {
        return newItem;
      } else {
        return x;
      }
    });

    if (!newItems) return;

    setItemForEdit(newItem);
    setItems(newItems);
    handleFormikChange(newItems);
  };

  const deleteItem = (item: Item) => {
    const newItems = items?.filter((x: any) => x.label !== item.label);
    if (!newItems) return;
    setItems(newItems);
    handleFormikChange(newItems);
  };

  const handleDefaultOption = (item: Item, isActive: boolean) => {
    const newItems = items?.map((x: any) => {
      if (x.uuid === item?.uuid) {
        return { ...x, defaultOption: isActive };
      } else {
        return { ...x, defaultOption: false };
      }
    });

    if (!newItems) return;

    setItems(newItems);
    handleFormikChange(newItems);
  };

  const generateItemsOutOfCsv = () => {
    if (!selectedFeature?.featureName) return;

    const splitCsv = csvOptions.split(',');
    const getPath = composeTranslationPath(
      selectedFeature?.featureName,
      selectedFeature?.stepName,
      selectedFeature?.pageName,
    );

    const withDetails: Item[] = splitCsv.map((item: any, index: number) => {
      const removeEmptySpace = item.replaceAll(' ', '_');

      return {
        label: getPath + nameValue.toLowerCase() + '.option.' + removeEmptySpace,
        description: '',
        value: removeEmptySpace,
        name: removeEmptySpace,
        sequence: (index + 1) * 10,
        defaultOption: index === 0,
        uuid: uuid(),
      };
    });

    handleFormikChange(withDetails);

    setItems(withDetails);
  };

  return (
    <FullScreenPage onClose={onClose} backText="Setup steps">
      <>
        <Notification
          className="docs"
          data-testid="notification"
          iconClose
          info
          show
          style={{ position: 'relative', top: 0, marginBottom: 16 }}
          icon={<span className="material-icons-outlined">info</span>}
        >
          <NotificationHeader>Effortlessly Create Dropdown Menus</NotificationHeader>
          <NotificationBody>
            Add your list items by simply typing each item name each separated by a comma. When you're done, click the
            add button; your list will automatically save, so you can go back to setup steps.
          </NotificationBody>
        </Notification>
        <div className={`"tag-ds" ${style.container}`}>
          <div className="tag-ds" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="input-wrapper ">
              <textarea
                value={csvOptions}
                onChange={(e: any) => setCsvOptions(e.target.value)}
                className={style.csvInput}
                placeholder="Enter list here..."
              />
            </div>
            <button
              onClick={generateItemsOutOfCsv}
              disabled={!nameValue}
              style={{ alignSelf: 'flex-end' }}
              className="button-secondary"
              type="button"
            >
              Add
            </button>
          </div>
          {items && items?.length > 0 && (
            <div>
              <span className={clsx('base', style.dndDescription)}>Rank your items using :: icon</span>
              <button
                type="button"
                style={{ width: '100%', marginBottom: 8 }}
                onClick={() => {
                  setItems([]);
                  handleFormikChange([]);
                }}
              >
                DELETE ALL
                <span className="material-icons right">delete_forever</span>
              </button>
              {items
                .sort((a: any, b: any) => a.sequence - b.sequence)
                .map((item: any) => {
                  const defaultOption = () => {
                    let defaultOption;
                    if (isMultiple) {
                      defaultOption = item?.defaultOption || defaultValue[0] === item.value;
                    } else {
                      defaultOption = item?.defaultOption || defaultValue === item.value;
                    }

                    return defaultOption;
                  };

                  return (
                    <SingleSelectItem
                      key={item.uuid}
                      item={item}
                      handleDefaultOption={() => handleDefaultOption(item, !item.defaultOption)}
                      handleDelete={() => deleteItem(item)}
                      onOptionDrop={(e, targetField) => onOptionDrop(e, targetField)}
                      setItemForEdit={() => setItemForEdit(item)}
                      defaultOption={defaultOption()}
                    />
                  );
                })}
            </div>
          )}
        </div>
        {itemForEdit && (
          <EditOptionModal
            itemForEdit={itemForEdit}
            handleItemChange={handleItemChange}
            handleTranslationChange={(translationValues) => {
              setFieldValue('selectOptionsTranslations', {
                ...selectOptionsTranslations,
                ['control-items:' + itemForEdit.label]: translationValues,
              });
            }}
            translationValue={
              selectOptionsTranslations[itemForEdit.label] ||
              selectOptionsTranslations['control-items:' + itemForEdit.label]
            }
            closeModal={() => setItemForEdit(null)}
          />
        )}
      </>
    </FullScreenPage>
  );
};

export default EditList;
