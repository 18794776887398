import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { BoxedButton } from '@components/shared/BoxedButton/BoxedButton';
import {
  getApplicationId,
  getClientOrganizationId,
  getRedirectionUrl,
  setRedirectionUrl,
} from '@helpers/tenant.helper';
import { useAllApplicationsForUserAndClientOrgUploadLimitSize } from '@hooks/apiHooks';
import { Onboarding } from '@models/onboarding.model';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { RootState } from '@store/rootReducer';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';
import { useQueryClient } from '@tanstack/react-query';

import { OOAccessRestricted } from './AccessRestricted';

const SelectComponent: React.FC<{
  name: string;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  options: { name: string; value: string }[];
  value?: string;
  help?: string;
}> = ({ name, onChange, options, value, help }) => {
  const valueAttr = value ? { value } : {};
  return (
    <FormControl variant="standard">
      <InputLabel id={name + '-mui-select-label'}>{name}</InputLabel>
      <Select id="mui-select" labelId={name + '-mui-select-label'} {...valueAttr} onChange={onChange}>
        {options.map((option) => (
          <MenuItem key={option.name} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {help && <FormHelperText>{help}</FormHelperText>}
    </FormControl>
  );
};

export const ApplicationSelectPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { t } = useTranslation(['entry', 'candidate']);
  const { userId } = useSelector((state: RootState) => state.authApp);
  const [applicationId, setApplicationId] = useState<string>(
    `${localStorage.getItem('applicationId') || ''}|${localStorage.getItem('clientOrganizationId') || ''}|${
      localStorage.getItem('selectedConfigurationId') || ''
    }`,
  );
  const { importClientOrganizationId } = useSelector((state: RootState) => state.oneOnboarding);
  const queryClient = useQueryClient();

  const { data = [], isFetching } = useAllApplicationsForUserAndClientOrgUploadLimitSize(
    userId,
    (res: Onboarding[]) => {
      if (res.length === 1) {
        const { configurationId, applicationId, clientOrganizationId } = res[0];
        chooseApp(`${applicationId}|${clientOrganizationId}|${configurationId}`);
      } else if (state?.redirect) {
        const applicationId = getApplicationId();
        const clientOrganizationId = getClientOrganizationId() || importClientOrganizationId;
        const { configurationId } =
          res.find((i) => i.applicationId === applicationId && i.clientOrganizationId === clientOrganizationId) || {};
        if (configurationId) {
          chooseApp(`${applicationId}|${clientOrganizationId}|${configurationId}`);
        }
      }
    },
  );
  const chooseApp = async (appId?: string) => {
    const paramsString: string = appId || applicationId;
    const params = paramsString.split('|');
    if (params.length === 0) {
      return;
    }
    const [selectedAppId, clientOrganizationId, configurationId] = params;
    dispatch(oneOnboardingSlice.actions.setApplicationId(selectedAppId));
    localStorage.setItem('applicationId', selectedAppId || '');
    dispatch(oneOnboardingSlice.actions.setClientOrganizationId(clientOrganizationId));
    localStorage.setItem('clientOrganizationId', clientOrganizationId || '');
    dispatch(oneOnboardingSlice.actions.setConfigurationId(configurationId));
    localStorage.setItem('selectedConfigurationId', configurationId || '');
    queryClient.refetchQueries(['getOnboardingTheme']);

    const defaultUrl = '/oo/flow/dashboard';
    const storageRedirectUrl = getRedirectionUrl() || defaultUrl;
    const redirectUrl = storageRedirectUrl.includes('oo/recruiter') ? defaultUrl : storageRedirectUrl;
    history.push(redirectUrl);
    history.go(0);
    setRedirectionUrl('');
  };

  if (!isFetching && data.length === 0) {
    return <OOAccessRestricted translation="entry" translationKey="accessRestricted" isAuthenticated={false} />;
  }

  if ((isFetching && data.length < 2) || state?.redirect) {
    return (
      <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
      <div
        style={{
          padding: '32px',
          borderRadius: '20px',
          border: '1px solid #ddd',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.4)',
          minWidth: '350px',
        }}
      >
        {data && (
          <>
            <SelectComponent
              name={t('appSwitchLabel')}
              value={applicationId}
              onChange={(e) => setApplicationId(e.target.value)}
              options={data?.map((flow: any) => ({
                name: `${flow.clientOrganization.displayName || flow.clientOrganization.name}: ${
                  flow.applicationId || ''
                }`,
                value: `${flow.applicationId || ''}|${flow.clientOrganizationId || ''}|${flow.configurationId || ''}`,
              }))}
              help={t('chooseApp')}
            />
            <BoxedButton
              text={t('candidate:GENERAL.GENERIC.continue')}
              onClick={() => chooseApp()}
              disabled={!applicationId}
            />
          </>
        )}
      </div>
    </Box>
  );
};
