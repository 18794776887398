import React, { FC, useState } from 'react';

import { ToolTipButton, ToolTipContentArea } from '../ToolTip/ToolTip';

interface RadioButtonComponentProps {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelList?: string[];
  toolTipContent?: string;
  disabled?: boolean;
}

const RadioButtonComponent: FC<RadioButtonComponentProps> = ({
  label,
  value,
  name,
  checked,
  onChange,
  labelList,
  disabled,
  toolTipContent,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <div className="tag-ds radio-container" style={{ margin: '20px 0 20px 0' }}>
        <input
          id={`${name}_${value}`}
          type="radio"
          value={value}
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />

        {labelList && labelList.length > 0 ? (
          <label htmlFor={`${name}_${value}`}>
            <ol style={{ margin: 0, paddingTop: 0 }}>
              {labelList?.map((x, i) => (
                <li key={i} style={{ paddingTop: i === 0 ? 0 : 8 }}>
                  {x}
                </li>
              ))}
            </ol>
          </label>
        ) : (
          <label htmlFor={`${name}_${value}`}>{label}</label>
        )}
        {toolTipContent && <ToolTipButton onClick={() => setTooltipOpen((state) => !state)} isActive={tooltipOpen} />}
      </div>
      {tooltipOpen && toolTipContent && <ToolTipContentArea content={toolTipContent} />}
    </>
  );
};

export default RadioButtonComponent;
