import React, { useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { AamBackendApi } from 'src/libs/aamBackendApi';
import { ModalBody, ModalFooter, ModalHeader } from '@adeccoux/tag-ds';
import { useInjection } from '@context/inversify-context-provider';
import { useGetUrlParameter } from '@hooks/useGetUrlParameter';
import { Box, Modal } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { handlePublishedStatus } from '@store/slices/selfService.slice';
import { snackbarSlice } from '@store/slices/snackbar';

import styles from './PublishConfigurationDialog.module.scss';

interface Props {
  isVisible: boolean;
  onSave: () => void;
  onCancel: () => void;
}

const PublishConfigurationDialog: React.FC<Props> = ({ isVisible, onSave, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);
  const tenantId = localStorage.getItem('tenantId');
  const aamBackendApi = useInjection(AamBackendApi);
  const dispatch = useDispatch();
  const configurationId = useGetUrlParameter('configurationId') || '';
  const clientOrganizationId = useGetUrlParameter('clientOrganizationId') || '';

  const uploadConfiguration = async () => {
    if (!selectedConfiguration) return;

    setIsLoading(true);
    const formData: any = new FormData();

    const objJsonStr = JSON.stringify(selectedConfiguration);

    formData.append('file', objJsonStr);

    const payload: any = {
      fromClientOrganizationId: clientOrganizationId || '',
      fromConfigurationId: configurationId || '',
      toClientOrganizationId: selectedConfiguration.clientOrganization?.id || '',
      toConfigurationId: selectedConfiguration.configurationId || '',
    };

    Object.keys(payload).forEach((x) => {
      formData.append(x, payload[x]);
    });

    try {
      const response = await aamBackendApi.importCsvSelfService(Number(tenantId), formData);

      if (response?.error) {
        throw new Error(response?.error);
      }

      setIsLoading(false);
      onSave();
      dispatch(handlePublishedStatus(true));
    } catch (err) {
      let msg;

      if (err.response?.data?.error?.data) {
        const { message } = err.response.data.error.data;
        if (message) {
          msg = message;
        }
      }

      dispatch(
        snackbarSlice.actions.showError({
          message: 'Configuration upload failed: ' + (msg || err),
        }),
      );

      onCancel();
      setIsLoading(false);
    }
  };

  return (
    <Modal open={isVisible}>
      <Box className={clsx('tag-ds', styles.modal)}>
        <ModalHeader className={styles.modal__header}>
          <h4>Warning!</h4>
          <span
            className="material-icons"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!isLoading) {
                onCancel();
              }
            }}
          >
            close
          </span>
        </ModalHeader>
        <ModalBody style={{ marginBottom: 48 }}>
          Configuration will be published before continuing on the next step
        </ModalBody>
        <ModalFooter>
          <button className={clsx('button-secondary', isLoading && 'loading')} onClick={onCancel} disabled={isLoading}>
            I want to make more changes
          </button>
          <button
            className={clsx('button', isLoading && 'button_loading')}
            onClick={uploadConfiguration}
            disabled={isLoading}
          >
            Proceed
            {isLoading && <div className="spinner" style={{ marginLeft: 16 }} />}
          </button>
        </ModalFooter>
      </Box>
    </Modal>
  );
};

export default PublishConfigurationDialog;
