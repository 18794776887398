import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '@components/app/PageWrapper/PageWrapper';
import { useInjection } from '@context/inversify-context-provider';
import { getBase64EncodedStringFromFile, getBlobData } from '@helpers/fileUpload.helper';
import { useAllConfigurationsForTenant } from '@hooks/apiHooks';
import { Tenant, useTenant } from '@hooks/useComponentMapping';
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  Typography,
} from '@mui/material';
import { Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import Notification, { useNotification } from '../../../../components/admin/Notification/Notification';
import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { TranslationsManager } from '../../../../libs/TranslationsManager';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import { AddConfigurationForANewClient } from './AddConfigurationForANewClient';
import { AdminAssetDirectory } from './AdminAssetDirectory';
import { AdminAssetModal } from './AdminAssetModal';
import { AdminClientOrganization } from './AdminClientOrganization';
import { AdminDeleteBranch } from './AdminDeleteBranch';
import { AdminDeleteExistingClient } from './AdminDeleteExistingClient';
import { configFileExtension, getConfigurationAssetsPrefix, isBoxEnabled } from './adminImportHelpers';
import { AdminSimpleButton } from './AdminSimpleButton';
import { AdminUploadButton } from './AdminUploadButton';

import styles from './AdminImport.module.scss';
export const assetExtensions = ['jpg', 'jpeg', 'jpe', 'gif', 'png', 'svg', 'pdf', 'docx'];
export type AssetType =
  | {
      dir: string;
      src: string;
      name: string;
      size: number;
      clientOrganizationId?: string;
      configurationId?: string;
      [key: string]: any;
    }
  | undefined;

export const OOAdminImport: React.VoidFunctionComponent = () => {
  const tenantId = useTenant();
  const aamBackendApi = useInjection(AamBackendApi);
  const { t } = useTranslation(['recruiter']);

  type SnackAlertType =
    | {
        severity?: 'success' | 'error' | 'warning' | 'info';
        message: string;
        details?: string;
      }
    | undefined;

  const [assetBoxes, setAssetBoxes] = useState<Array<string>>([]);
  const [asset, setAsset] = useState<AssetType>();
  const [isLoading, setIsLoading] = useState(false);
  const [snackAlert, setSnackAlert] = useState<SnackAlertType>();
  const [alertDetailsModal, setAlertDetailsModal] = useState<boolean>(false);

  const translationsManager = new TranslationsManager(tenantId);

  const { data, refetch } = useQuery({
    queryKey: ['fetchClientOrganizationForTenantByTenant'],
    queryFn: () => aamBackendApi.fetchClientOrganizationForTenantByTenant(),
  });

  const { data: clientOrganizations, refetch: clientOrganizationsRefetch } = useAllConfigurationsForTenant(tenantId);

  const { data: missingTranslationData, refetch: exportTranslationsRefetch } = useQuery({
    queryKey: ['exportTranslations', tenantId],
    queryFn: () => aamBackendApi.exportTranslations(tenantId as number, 'yes'),
  });

  const tenant: any = data;

  const tenantRelatedOrganizations = tenant?.clientOrganizations || [];

  const siteFileExtension = 'csv';
  const branchFileExtension = 'csv';
  const clientOrganizationFileExtension = 'csv';

  const translationsFileExtension = 'xlsx';

  const importGeneral = async (importProps: {
    successMessage: string;
    errorMessage: string;
    formData: FormData;
    handler: (tenantId: Tenant, formData: FormData) => void;
    itemsRefetch?: () => void;
  }) => {
    try {
      setIsLoading(true);
      await importProps.handler(tenantId, importProps.formData);
      importProps.itemsRefetch
        ? await Promise.all([refetch(), importProps.itemsRefetch(), exportTranslationsRefetch()])
        : await Promise.all([refetch(), exportTranslationsRefetch()]);
      setIsLoading(false);
      setSnackAlert({
        message: importProps.successMessage,
        severity: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: `${importProps.errorMessage}:  ${err.response?.data?.error?.data?.message || err}`,
        severity: 'error',
      });
    }
  };

  const importSites = async (tenantId: Tenant, formData: FormData) => {
    try {
      setIsLoading(true);
      const resp = await aamBackendApi.importCsvSite(tenantId, formData);
      const { imported, failed } = resp;
      await refetch();
      setIsLoading(false);
      if (imported.length > 0 && failed.length === 0) {
        setSnackAlert({
          severity: 'success',
          message: t('GENERAL.CONFIGURATION.SitesWereImported'),
        });
        return;
      }
      if (imported.length > 0 && failed.length > 0) {
        setSnackAlert({
          severity: 'warning',
          message: t('GENERAL.CONFIGURATION.SomeSitesWereNotImported'),
          details: `Site codes: ${failed.map((i) => i.code).join(', ')}`,
        });
        return;
      }
      if (imported.length === 0 && failed.length > 0) {
        setSnackAlert({
          severity: 'error',
          message: t('GENERAL.CONFIGURATION.NoSitesWereImported'),
          details: `Site codes: ${failed.map((i) => i.code).join(', ')}`,
        });
        return;
      }
      if (imported.length === 0 && failed.length === 0) {
        setSnackAlert({
          severity: 'error',
          message: t('GENERAL.CONFIGURATION.NoSitesWereImported'),
        });
        return;
      }
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Sites import error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };

  const importBranch = async (tenantId: Tenant, formData: FormData) => {
    try {
      setIsLoading(true);
      const { imported, failed } = await aamBackendApi.importCsvBranch(tenantId, formData);
      await refetch();
      setIsLoading(false);
      if (imported.length > 0 && failed.length === 0) {
        setSnackAlert({
          severity: 'success',
          message: t('GENERAL.CONFIGURATION.BranchesWereImported'),
        });
        return;
      }
      if (imported.length > 0 && failed.length > 0) {
        setSnackAlert({
          severity: 'warning',
          message: t('GENERAL.CONFIGURATION.SomeBranchesWereNotImported'),
          details: `Site codes: ${failed.map((i) => i.siteCode).join(', ')}`,
        });
        return;
      }
      if (imported.length === 0 && failed.length > 0) {
        setSnackAlert({
          severity: 'error',
          message: t('GENERAL.CONFIGURATION.NoBranchesWereImported'),
          details: `Site codes: ${failed.map((i) => i.siteCode).join(', ')}`,
        });
        return;
      }
      if (imported.length === 0 && failed.length === 0) {
        setSnackAlert({
          severity: 'error',
          message: t('GENERAL.CONFIGURATION.NoBranchesWereImported'),
        });
        return;
      }
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Branches import error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };
  const handleConfigRemoval = async (configTenantId: number, clientOrganizationId: string, id: string) => {
    if (window.confirm(t('GENERAL.CONFIGURATION.ConfigurationRemovalConfirmation'))) {
      try {
        setIsLoading(true);
        await aamBackendApi.configDelete(configTenantId, clientOrganizationId, id);
        await refetch();
        await exportTranslationsRefetch();
        setIsLoading(false);
        setSnackAlert({
          message: 'Configuration successfully removed!',
          severity: 'success',
        });
      } catch (err) {
        setIsLoading(false);
        let msg;
        if (err.response?.data?.error?.data) {
          const { message, name, id } = err.response.data.error.data;
          if (message) {
            msg = message;
          }
          if (name && id) {
            msg = `Entity ${name} not found, id ${id}`;
          }
        }
        setSnackAlert({
          message: 'Configuration removal error: ' + (msg || err),
          severity: 'error',
        });
      }
    }
  };

  const handleConfigExport = async (configTenantId: number, clientOrganizationId: string, id: string) => {
    try {
      const data = await aamBackendApi.configExport(configTenantId, clientOrganizationId, id);
      const fileName = `${data.recruiterName}.json`;
      const url = window.URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      let msg;
      if (err.response?.data?.error?.data) {
        const { message } = err.response.data.error.data;
        if (message) {
          msg = message;
        }
      }
      setSnackAlert({
        message: 'Configuration export error: ' + (msg || err),
        severity: 'error',
      });
    }
  };
  const importClientOrganizations = async (formData: FormData) => {
    await importGeneral({
      successMessage: 'Client Organizations successfully imported!',
      errorMessage: 'Client Organizations import error',
      formData,
      handler: aamBackendApi.importCsvClientOrganization,
      itemsRefetch: clientOrganizationsRefetch,
    });
  };

  const handleImportSite = async (e: any) => {
    const fileList = e.target.files;
    if (!fileList?.length) {
      return;
    }

    const file = fileList[0];
    const { name } = file;
    if (name.slice(name.lastIndexOf('.') + 1).toLowerCase() !== siteFileExtension) {
      window.alert(t('Wrong file extension'));
      return;
    }

    const formData = new FormData();
    formData.append('file', file, name);

    await importSites(tenantId, formData);
  };

  const handleImportBranch = async (e: any) => {
    const fileList = e.target.files;
    if (!fileList?.length) {
      return;
    }

    const file = fileList[0];
    const { name } = file;
    if (name.slice(name.lastIndexOf('.') + 1).toLowerCase() !== branchFileExtension) {
      window.alert(t('Wrong file extension'));
      return;
    }

    const formData = new FormData();
    formData.append('file', file, name);

    await importBranch(tenantId, formData);
  };

  const handleImportClientOrganizations = async (e: any) => {
    const fileList = e.target.files;
    if (!fileList?.length) {
      return;
    }

    const file = fileList[0];
    const { name } = file;
    if (name.slice(name.lastIndexOf('.') + 1).toLowerCase() !== clientOrganizationFileExtension) {
      window.alert(t('Wrong file extension'));
      return;
    }

    const formData = new FormData();
    formData.append('file', file, name);

    await importClientOrganizations(formData);
  };

  const handleAddConfig = async (
    e: any,
    clientOrg: { isNewClient: boolean; id?: string; name?: string; displayName?: string; description?: string },
    isUpdate: boolean,
    configId?: string,
  ) => {
    const fileList = e.target.files;
    if (!fileList?.length) {
      return;
    }

    const file = fileList[0];
    const { name } = file;
    if (name.slice(name.lastIndexOf('.') + 1).toLowerCase() !== configFileExtension) {
      window.alert(t('Wrong file extension'));
      return;
    }

    const formData = new FormData();
    formData.append('entityName', 'OOSelectedConfiguration');
    formData.append('isUpdate', isUpdate ? 'true' : 'false');
    formData.append('isNewClient', clientOrg.isNewClient ? 'true' : 'false');

    if (clientOrg?.id) {
      formData.append('clientOrganizationId', clientOrg.id);
    }

    if (clientOrg?.name) {
      formData.append('clientOrganizationName', clientOrg.name);
    }

    if (clientOrg?.displayName) {
      formData.append('clientOrganizationDisplayName', clientOrg.displayName);
    }

    if (clientOrg?.description) {
      formData.append('clientOrganizationDescription', clientOrg.description);
    }

    if (configId) {
      formData.append('configurationId', configId);
    }
    const base64EncodedString = await getBase64EncodedStringFromFile(file);
    formData.append('file', base64EncodedString);

    try {
      setIsLoading(true);
      const res = await aamBackendApi.importCsvGeneral(tenantId, formData);
      if (res.error && res.error !== '') {
        setSnackAlert({
          message: 'Configuration update error',
          severity: 'error',
          details: res.error,
        });
        setIsLoading(false);
        return;
      }
      await refetch();
      await clientOrganizationsRefetch();
      await exportTranslationsRefetch();
      setIsLoading(false);
      setSnackAlert({
        message: 'Configuration successfully updated!',
        severity: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Configuration update error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };

  const handleAssetPreview = async (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<void> => {
    let path = '';
    if (clientOrganizationId) {
      if (!configurationId || !fileName) {
        window.alert(t('GENERAL.CONFIGURATION.MissingArguments'));
        return;
      }
    }
    path = getConfigurationAssetsPrefix(dir, clientOrganizationId, configurationId) + fileName;
    try {
      setIsLoading(true);
      const res = await aamBackendApi.assetGetPredefined(
        tenantId,
        dir,
        fileName,
        clientOrganizationId,
        configurationId,
      );
      setIsLoading(false);
      const { data, headers } = res;
      const contentType = headers['content-type'];
      const { imageUrl, isImage, size } = getBlobData(data, contentType);
      const assetData = {
        dir,
        name: fileName,
        size,
        isImage,
        contentType,
        path,
        src: imageUrl,
        clientOrganizationId,
        configurationId,
      };
      if (isImage) {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          const dimensions = 'W: ' + img.width + 'px H: ' + img.height + 'px';
          setAsset({ ...assetData, dimensions } as any);
        };
        return;
      }
      setAsset(assetData as any);
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Asset preview error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };

  const handleAssetUpload = async (
    e: any,
    targetDir: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<void> => {
    const { files } = e.target;
    if (!files?.length) {
      return;
    }

    for (const file of files) {
      const { name } = file;
      if (!assetExtensions.includes(name.slice(name.lastIndexOf('.') + 1).toLowerCase())) {
        window.alert(`${t('GENERAL.CONFIGURATION.WrongFileExtension')}: ${name}`);
        return;
      }
    }
    let path = '';
    if (clientOrganizationId) {
      if (!configurationId || !targetDir) {
        window.alert(t('GENERAL.CONFIGURATION.MissingArguments'));
        return;
      }
    }
    path = getConfigurationAssetsPrefix(targetDir, clientOrganizationId, configurationId);
    try {
      setIsLoading(true);
      await aamBackendApi.assetUpload(tenantId, files, path);
      await refetch();
      setIsLoading(false);
      setSnackAlert({
        message: t('GENERAL.CONFIGURATION.AssetSuccessfullyUploaded', 'Asset successfully uploaded!'),
        severity: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Asset upload error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };

  const handleAssetRemove = async (
    e: any,
    dir: string,
    fileName: string,
    clientOrganizationId?: string,
    configurationId?: string,
  ): Promise<void> => {
    try {
      setIsLoading(true);
      await aamBackendApi.assetDeletePredefined(tenantId, dir, fileName, clientOrganizationId, configurationId);
      await refetch();
      setIsLoading(false);
      setSnackAlert({
        message: t('GENERAL.CONFIGURATION.AssetSuccessfullyRemoved', 'Asset successfully removed!'),
        severity: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Asset removal error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };

  const toggleAssetBox = (str: string) => {
    const index = assetBoxes.indexOf(str);
    if (index > -1) {
      assetBoxes.splice(index, 1);
      setAssetBoxes([...assetBoxes]);
      return;
    }
    setAssetBoxes([...assetBoxes, str]);
  };

  const handleExport = async (entityName: string) => {
    try {
      setIsLoading(true);
      const { data } = await aamBackendApi.exportCsv(tenantId, entityName);
      setIsLoading(false);
      if (data.size === 0) {
        setSnackAlert({
          message: 'GENERAL.CANDIDATES_OVERVIEW.onExportError',
          severity: 'error',
        });
      } else {
        const fileName = `${entityName}.csv`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'GENERAL.CANDIDATES_OVERVIEW.onExportError',
        severity: 'error',
      });
    }
  };

  const handleAssetDownload = async (e: any, dir: string, clientOrganizationId?: string, configurationId?: string) => {
    try {
      setIsLoading(true);
      const res = await aamBackendApi.assetDownloadPredefined(tenantId, dir, clientOrganizationId, configurationId);
      setIsLoading(false);
      const fileName = `${dir}.zip`;
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setIsLoading(false);
      setSnackAlert({
        message: 'Configuration update error: ' + (err.response?.data?.error?.data?.message || err),
        severity: 'error',
      });
    }
  };

  const AddConfigurationForExistingClient = (props: any) => {
    const { strId, className } = props;
    const assetBoxOpen = assetBoxes.includes(strId);
    const [existingClientId, setExistingClientId] = useState(
      clientOrganizations && clientOrganizations.length > 0 ? clientOrganizations[0].id : '',
    );

    return (
      <div className={styles.assetHolder + (className ? ' ' + className : '')}>
        <div className={styles.flexHolder + ' ' + styles.assetHeader} onClick={() => toggleAssetBox(strId)}>
          <Typography variant="h4">{t('GENERAL.CONFIGURATION.AddConfigurationForExistingClient')}</Typography>
          <ExpandMore style={!assetBoxOpen ? { transform: 'rotate(180deg)' } : {}} />
        </div>
        <Collapse in={assetBoxOpen} timeout="auto" unmountOnExit>
          <div className={styles.assetBox}>
            <div>
              <span className={styles.textInput}>
                <Select
                  placeholder="Existing client"
                  value={existingClientId}
                  style={{ width: 300 }}
                  onChange={(e) => setExistingClientId(e.target.value as string)}
                >
                  {clientOrganizations?.map((o) => {
                    return (
                      <MenuItem key={o.id} value={o.id}>
                        {o.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </span>
            </div>
            <div>
              <br />
              <AdminUploadButton
                label={t('GENERAL.CONFIGURATION.AddConfiguration')}
                extension={configFileExtension}
                onChange={(e: any) => handleAddConfig(e, { isNewClient: false, id: existingClientId }, false)}
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  const extractParametersFromError = (error: string | undefined) => {
    if (!error) return '';

    const regex = new RegExp(`(?<=parameters":\\[).*?(?=])`, 'g');

    return JSON.stringify(error).match(regex);
  };

  const {
    isActive: translationNotificationIsActive,
    notificationType: translationNotificationType,
    notificationDetails: translationNotificationDetails,
    triggerNotification: translationTriggerNotification,
    closeNotification: translationCloseNotification,
  } = useNotification();

  const handleDeleteBranch = async (branchId: string) => {
    if (window.confirm(t('GENERAL.CONFIGURATION.BranchRemovalConfirmation'))) {
      try {
        setIsLoading(true);
        await aamBackendApi.invalidateBranch(branchId);
        await branchesRefetch();
        setIsLoading(false);
        setSnackAlert({
          message: t('GENERAL.CONFIGURATION.BranchRemoveSuccess'),
          severity: 'success',
        });
      } catch (err) {
        setIsLoading(false);
        let msg;
        if (err.response?.data?.error?.data) {
          const { message, name, id } = err.response.data.error.data;
          if (message) {
            msg = message;
          }
          if (name && id) {
            msg = `Entity ${name} not found, id ${id}`;
          }
        }
        setSnackAlert({
          message: t('GENERAL.CONFIGURATION.BranchRemoveError') + ': ' + (msg || err),
          severity: 'error',
        });
      }
    }
  };

  const { refetch: branchesRefetch } = useQuery({
    queryKey: ['fetchBranches'],
    queryFn: () => aamBackendApi.fetchBranches(),
  });

  return (
    <div className="admin">
      <PageWrapper featureName="import" pageName="import" sideMenu>
        <AdminHeader pageName={t('GENERAL.IMPORT.import')} languagePicker={true} />
        <Typography component="div">
          <Box style={{ padding: '0 32px' }}>
            <Container maxWidth="lg">
              <Box display="flex" justifyContent="flex-end" alignItems="center" columnGap="8px">
                <Typography variant="h3" className={styles.flexGrow}>
                  {t('GENERAL.CONFIGURATION.Sites')}
                </Typography>
                <span>
                  <AdminSimpleButton
                    label={t('GENERAL.CONFIGURATION.ExportSites')}
                    onClick={() => handleExport('Site')}
                  />
                </span>
                <AdminUploadButton
                  label={t('GENERAL.CONFIGURATION.ImportSites')}
                  extension={siteFileExtension}
                  onChange={(e: any) => handleImportSite(e)}
                />
              </Box>
              <br />
              <Box display="flex" justifyContent="flex-end" alignItems="center" columnGap="8px">
                <Typography variant="h3" className={styles.flexGrow}>
                  {t('GENERAL.CONFIGURATION.Branches')}
                </Typography>
                <span>
                  <AdminSimpleButton
                    label={t('GENERAL.CONFIGURATION.ExportBranches')}
                    onClick={() => handleExport('Branch')}
                  />
                </span>
                <AdminUploadButton
                  label={t('GENERAL.CONFIGURATION.ImportBranches')}
                  extension={branchFileExtension}
                  onChange={(e: any) => handleImportBranch(e)}
                />
              </Box>
              <br />
              {isBoxEnabled(tenantId, 'Organization') && (
                <>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" columnGap="8px">
                    <Typography variant="h3" className={styles.flexGrow}>
                      {t('GENERAL.CONFIGURATION.Organizations')}
                    </Typography>
                    <span>
                      <AdminSimpleButton
                        label={t('GENERAL.CONFIGURATION.ExportClients')}
                        onClick={() => handleExport('ClientOrganization')}
                      />
                    </span>
                    <AdminUploadButton
                      label={t('GENERAL.CONFIGURATION.ImportClients')}
                      extension={clientOrganizationFileExtension}
                      onChange={(e: any) => handleImportClientOrganizations(e)}
                    />
                  </Box>
                  <br />
                </>
              )}
              <Box display="flex" justifyContent="flex-end" alignItems="center" columnGap="8px">
                <Typography variant="h3" className={styles.flexGrow}>
                  {t('GENERAL.CONFIGURATION.Translations')}
                </Typography>
                <span>
                  <AdminSimpleButton
                    label={t('GENERAL.CONFIGURATION.ExportTranslations')}
                    onClick={() => {
                      translationsManager.export(
                        () =>
                          translationTriggerNotification('success', { header: 'Successfully exported translations' }),
                        () => translationTriggerNotification('error', { header: 'Error while exporting translations' }),
                      );
                    }}
                  />
                </span>
                <AdminUploadButton
                  label={t('GENERAL.CONFIGURATION.ImportTranslations')}
                  extension={translationsFileExtension}
                  onChange={(e: any) =>
                    translationsManager.import(
                      e,
                      () =>
                        translationTriggerNotification('success', {
                          header: 'Successfully imported translations',
                          body: 'Applying translation changes in 3 seconds',
                        }),
                      () => translationTriggerNotification('error', { header: 'Error while importing translations' }),
                    )
                  }
                />
              </Box>
              <br />
              <Box>
                <div className={styles.translationsMissing}>
                  <Typography variant="h4">
                    {t('GENERAL.CONFIGURATION.MissingTranslations')}:{' '}
                    {missingTranslationData?.translationsMissing
                      ? t('GENERAL.CONFIGURATION.Yes')
                      : t('GENERAL.CONFIGURATION.No')}
                    , {t('GENERAL.CONFIGURATION.Count')}:{missingTranslationData?.missingCount}
                  </Typography>
                </div>
              </Box>
              <Box>
                <>
                  <AdminAssetDirectory
                    dir="logo"
                    strId={'ad-' + tenantId}
                    className={styles.tenantAssetDir as any}
                    assetBoxes={assetBoxes}
                    toggleAssetBox={toggleAssetBox}
                    handleAssetPreview={handleAssetPreview}
                    handleAssetUpload={handleAssetUpload}
                    handleAssetDownload={handleAssetDownload}
                    handleAssetRemove={handleAssetRemove}
                  />
                  {tenantRelatedOrganizations?.length > 0 ? (
                    tenantRelatedOrganizations?.map((item: any, index: number) => (
                      <AdminClientOrganization
                        item={item}
                        key={`-trco-${index}`}
                        showAssets={true}
                        handleConfigRemoval={handleConfigRemoval}
                        handleConfigExport={handleConfigExport}
                        importBranch={importBranch}
                        handleAssetRemove={handleAssetRemove}
                        importGeneral={importGeneral}
                        importSites={importSites}
                        assetBoxes={assetBoxes}
                        handleAssetPreview={handleAssetPreview}
                        handleAssetUpload={handleAssetUpload}
                        handleAssetDownload={handleAssetDownload}
                        toggleAssetBox={toggleAssetBox}
                        handleAddConfig={handleAddConfig}
                      />
                    ))
                  ) : (
                    <div className={styles.clientOrganization}>
                      <div className={styles.clientOrganizationConfigs}>
                        <span className={styles.noConfigs}>
                          <p>{t('GENERAL.CONFIGURATION.NoClientOrganizationsForTenantFound')}</p>
                        </span>
                      </div>
                    </div>
                  )}
                </>
              </Box>
              <Box>
                <AddConfigurationForANewClient
                  strId={`addConfigurationForANewClient-${tenantId}`}
                  className={styles.tenantAssetDir as any}
                  assetBoxes={assetBoxes}
                  handleAddConfig={handleAddConfig}
                  toggleAssetBox={toggleAssetBox}
                />
              </Box>
              <Box>
                <AddConfigurationForExistingClient
                  strId={`addConfigurationForExistingClient-${tenantId}`}
                  className={styles.tenantAssetDir as any}
                />
              </Box>
              <Box>
                <AdminDeleteExistingClient
                  strId={`deleteExistingClient-${tenantId}`}
                  className={styles.tenantAssetDir as any}
                  assetBoxes={assetBoxes}
                  toggleAssetBox={toggleAssetBox}
                  clientOrganizations={clientOrganizations || []}
                />
              </Box>
              <Box>
                <AdminDeleteBranch
                  strId="deleteBranch"
                  assetBoxes={assetBoxes}
                  toggleAssetBox={toggleAssetBox}
                  handleDeleteBranch={handleDeleteBranch}
                />
              </Box>
              <br />
            </Container>
          </Box>
          <AdminAssetModal asset={asset} setAsset={setAsset} handleAssetRemove={handleAssetRemove} />
        </Typography>
        <div
          className={styles.loader}
          style={{
            display: isLoading ? 'flex' : 'none',
          }}
        >
          <CircularProgress color="secondary" />
        </div>
        <Snackbar
          open={!!snackAlert?.message}
          onClose={() => setSnackAlert((state) => ({ ...state, message: '' }))}
          autoHideDuration={3000}
          TransitionComponent={Slide}
          onAnimationEnd={() => setSnackAlert(undefined)}
        >
          <Alert
            onClose={() => {
              setSnackAlert((state) => ({ ...state, message: '' }));
              setTimeout(() => setSnackAlert(undefined), 300);
            }}
            severity={snackAlert?.severity}
            variant="filled"
            style={{ minWidth: '300px' }}
          >
            <Box display="flex">
              {snackAlert?.message}{' '}
              {!!snackAlert?.details && (
                <AdminSimpleButton onClick={() => setAlertDetailsModal(true)} label="Details" />
              )}
            </Box>
          </Alert>
        </Snackbar>
        {/* Error dialog */}
        <Dialog open={alertDetailsModal} onClose={() => setAlertDetailsModal(false)}>
          <DialogTitle>{extractParametersFromError(snackAlert?.details)}</DialogTitle>
          <DialogContent style={{ wordBreak: 'break-all' }}>
            <Typography>Full error: </Typography>
            <Box>{JSON.stringify(snackAlert?.details)}</Box>
          </DialogContent>
        </Dialog>
      </PageWrapper>
      <Notification
        type={translationNotificationType}
        details={translationNotificationDetails}
        isActive={translationNotificationIsActive}
        hide={translationCloseNotification}
      />
    </div>
  );
};
