import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OODocumentationModel } from '@features/oneOnboarding/models/DocumentationModel';
import { OODocumentModel } from '@features/oneOnboarding/models/DocumentModel';

import { DocumentActions } from '../DocumentActions/DocumentActions';
import { DocumentStatus } from '../DocumentStatus/DocumentStatus';

interface DocumentItemProps {
  document: OODocumentModel;
  documentation: OODocumentationModel;
  hasValidationStep: boolean;
}
export const DocumentItem: FC<DocumentItemProps> = ({ document, documentation, hasValidationStep }) => {
  const { t } = useTranslation(['documents', 'entry']);
  return (
    <div className="feature-candidate-profile__documents-item">
      <p className="tag-ds  medium">
        {t(`documents:${document.label}`)} {document.isMandatory ? '*' : ''}
      </p>
      <DocumentStatus document={document} hasValidationStep={hasValidationStep} />
      <DocumentActions documentModel={document} documentation={documentation} />
    </div>
  );
};
