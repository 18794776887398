import React from 'react';

interface Props {
  attributes: any;
  children: React.ReactChildren;
  element: {
    align: string;
    type: string;
    target: string;
    href?: string;
    attr?: any;
  };
}

const Element: React.FC<Props> = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };

  switch (element.type) {
    case 'strikethrough':
      return (
        <del style={style} {...attributes}>
          {children}
        </del>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'link':
      return (
        <a
          href={element.href}
          {...attributes}
          {...element.attr}
          target={element.target}
          style={{ display: 'inline-block' }}
        >
          {children}
        </a>
      );
    default:
      return (
        <span style={style} {...attributes}>
          {children}
        </span>
      );
  }
};

export default Element;
