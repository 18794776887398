import {
  RecruiterSidebarItemState,
  SidebarItemStateHandlerParams,
} from '@features/oneOnboardingAdmin/pages/NewCandidateProfilePage/CustomCandidateProfileSidebar/handlerClasses/types';

export const useHandlerClass = (params: SidebarItemStateHandlerParams): RecruiterSidebarItemState => {
  return {
    active: true,
    disabled: false,
    completed: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick: () => {},
  };
};
