import React from 'react';
import { Option } from '@adeccoux/tag-ds';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { MenuItem } from '@mui/material';
import { apiQuery, ControlItems } from '@store/apiQuery';

import { useConditionOnControlItems } from '../../hooks/useConditionOnControlItems';
import { useT } from '../../hooks/useT';
import { OOSelectorDropdownModelInterface } from '../../interfaces';
import { OOSelectWithIcon } from './OOSelectWithIcon';

export const DefaultDropdown: React.VoidFunctionComponent<OOSelectorDropdownModelInterface> = (props) => {
  const {
    topSelectValue,
    translationSelectItems,
    reducerSelectItemsMap,
    excludedSelectItems,
    onChangeCallback,
    multiple,
    checkBoxItems,
    dropdownOptions,
    sortBySequence,
  } = props;
  const { clientOrganizationId = '', configurationId = '' } = useDecodedParams();
  const translations = translationSelectItems ? ['control-items', translationSelectItems] : ['control-items'];
  const { t, translationsLoading } = useT(...translations);
  const { data, isFetching } = apiQuery.useGetDropdownsQuery({
    name: props.controlHeaderName ? props.controlHeaderName : props.name.toLowerCase(),
    clientOrganizationId,
    configurationId,
  });
  const conditionalControlItemsData = useConditionOnControlItems(dropdownOptions ?? data);
  const getReducedItems = (items: ControlItems) => {
    if (reducerSelectItemsMap) {
      const selectItemsMap = new Map<string, string[]>(Object.entries(reducerSelectItemsMap.reducerMap));
      const isReduceble = (item: string) =>
        !selectItemsMap.has(item) && [...selectItemsMap.values()].find((k) => k.includes(item));

      return items?.filter((item: any) => !isReduceble(item[reducerSelectItemsMap.reducerColumn] ?? item.label));
    }

    return items;
  };
  const getFilteredItems = (items: ControlItems) => {
    if (excludedSelectItems) {
      return items?.filter(
        (item: any) => !excludedSelectItems.excludeList.includes(item[excludedSelectItems.exludeColumn] ?? item.label),
      );
    }
    return items;
  };

  const getSortedItems = (items: ControlItems) => {
    const sorted = [...items].sort((a, b) => {
      if (sortBySequence) {
        return a.sequence - b.sequence;
      }
      return translationSelectItems
        ? t(`${translationSelectItems}:${a.label}`).localeCompare(t(`${translationSelectItems}:${b.label}`))
        : a.label.localeCompare(b.label);
    });
    if (topSelectValue) {
      const firstItem = items.find((i) => i.value === topSelectValue);
      if (firstItem) {
        sorted.unshift(firstItem);
      }
    }
    return sorted;
  };
  const renderMenuItems = () => {
    const reducedItems = getReducedItems(conditionalControlItemsData);
    const filteredItems = getFilteredItems(reducedItems);
    const resultItems = getSortedItems(filteredItems);

    return (resultItems as ControlItems | undefined)?.map((d) =>
      checkBoxItems ? (
        <Option
          key={d.value}
          label={translationSelectItems ? t(`${translationSelectItems}:${d.label}`) : t(d.label)}
          value={d.value}
        />
      ) : (
        <MenuItem key={d.value} value={d.value}>
          {translationSelectItems ? t(`${translationSelectItems}:${d.label}`) : t(d.label)}
        </MenuItem>
      ),
    );
  };

  const defaultValue = () => {
    if (multiple) {
      if (props?.defaultValue && !Array.isArray(props.defaultValue)) {
        try {
          return JSON.parse(props.defaultValue);
        } catch (err) {
          return undefined;
        }
      }
    }

    return props.defaultValue;
  };

  return (
    <OOSelectWithIcon
      validationKey={props.name}
      label={props.label}
      description={props.description}
      menuItems={renderMenuItems()}
      mandatory={props.isMandatory}
      loading={translationsLoading || isFetching}
      isEditableCandidate={props.isEditableCandidate}
      isEditableRecruiter={props.isEditableRecruiter}
      onChangeCallback={onChangeCallback}
      defaultValue={defaultValue()}
      iconType={props.iconType}
      hasHtmlDescription={props.hasHtmlDescription}
      rootProps={props}
      multiple={multiple}
      checkBoxItems={props.checkBoxItems}
    />
  );
};
