import React from 'react';

import styles from './FullScreenPage.module.scss';

interface Props {
  children: React.ReactChild;
  onClose: () => void;
  backText?: string;
}

const FullScreenPage: React.FC<Props> = ({ children, onClose, backText }) => {
  return (
    <div className={styles.container}>
      <div className={styles['main-section']}>
        <div onClick={onClose} className={styles.header}>
          <span className="material-icons">arrow_back</span>
          {backText && <span className="subtitle">{backText}</span>}
        </div>
        {children}
      </div>
    </div>
  );
};

export default FullScreenPage;
