import React from 'react';

import SelfServiceTooltip from '../Tooltip/SelfServiceTooltip';

import styles from '../Tooltip/SelfServiceTooltip.module.scss';

interface Props {
  children: React.ReactChild;
  tooltip: string;
  labelProps?: {
    classname: 'caption' | 'subtitle';
  };
}

const defaultLabel = {
  classname: 'caption',
};

const LabelWithTooltip: React.FC<Props> = ({ children, tooltip, labelProps = defaultLabel }) => {
  return (
    <div className={styles.labelWithTooltip}>
      <label className={labelProps.classname}>{children}</label>
      <SelfServiceTooltip hoverContent={tooltip} />
    </div>
  );
};

export default LabelWithTooltip;
