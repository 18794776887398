import React from 'react';
import { useTranslation } from 'react-i18next';

interface SendEmailButtonProps {
  handleSendEmail: () => void;
}

const SendEmailButton: React.FC<SendEmailButtonProps> = ({ handleSendEmail }) => {
  const { t } = useTranslation(['recruiter-deprecated']);

  return (
    <div className="tag-ds feature-mail-overview__send-button-container">
      <button onClick={handleSendEmail} className="feature-mail-overview__send-button">
        {t('CANDIDATE_PROFILE.sendEmail')}
      </button>
    </div>
  );
};

export default SendEmailButton;
