import React from 'react';

import styles from './AdminImport.module.scss';

export const AdminUploadButton = (ubProps: any) => {
  const { onChange, label, extension, multiple } = ubProps;
  const multipleProp = multiple ? { multiple: true } : {};
  return (
    <div className={styles.uploadButton}>
      <label className={styles.uploadButtonLabel}>{label || 'Upload'}</label>
      <input
        className={styles.uploadButtonInput}
        type="file"
        accept={extension ? `.${extension}` : undefined}
        onChange={onChange}
        {...multipleProp}
      />
    </div>
  );
};
