import { FormikContextType } from 'formik';
import { AppConfig } from '@helpers/appConfig';
import { Utils } from '@helpers/utils';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { ICalculationClassUI, OOControlModelInterface } from '../interfaces';

const utils = new Utils();
const appConfig = new AppConfig(utils);
const aamBackendApi = new AamBackendApi(appConfig, utils);

export class FrPostCodeCheck implements ICalculationClassUI {
  control: OOControlModelInterface;
  relevantControls: OOControlModelInterface[];
  allControls: OOControlModelInterface[];
  cityControl?: OOControlModelInterface;
  formik: FormikContextType<Record<string, any>>;
  setManualErrors?: (errors: Map<string, any>) => void;
  constructor(
    control: OOControlModelInterface,
    formControls: OOControlModelInterface[] | undefined,
    formik: FormikContextType<Record<string, any>>,
    setManualErrors?: (errors: Map<string, any>) => void,
  ) {
    this.control = control;
    this.relevantControls = [];
    this.setManualErrors = setManualErrors;
    this.allControls = formControls ?? [];
    this.formik = formik;
    this.cityControl = this.allControls.find((item) => item.name === 'CITY_STRING_READONLY')!;
  }

  async onChange(event: any) {
    const { value } = event.target;
    return this.handle(value);
  }

  async init(value?: any) {
    if (!this.cityControl) {
      return;
    }
    const cities = await aamBackendApi.getFrCitiesByZip(value);
    this.cityControl.dropdownOptions = cities.map((item: any) => ({
      label: item.cityNameUpper,
      value: item.cityNameUpper,
    }));

    const selectedCityValue = this.cityControl.value;

    if (this.cityControl.dropdownOptions?.some((item: any) => item.value === selectedCityValue)) {
      this.formik.setFieldValue('CITY_STRING_READONLY', this.cityControl.value);
    } else {
      this.formik.setFieldValue('CITY_STRING_READONLY', '');
    }
  }

  async handle(value: any) {
    if (!this.cityControl) {
      return;
    }
    const errors = new Map();
    this.formik.setErrors({});

    if (!value) {
      this.formik.setFieldValue('CITY_STRING_READONLY', '');
      this.cityControl.dropdownOptions = [];
      this.setManualErrors?.(errors);
      return;
    }

    const min = this.control.min ? +this.control.min : 0;
    const max = this.control.max ? +this.control.max : 0;

    if (!(value.length < min || value.length > max)) {
      const cities = await aamBackendApi.getFrCitiesByZip(value);
      if (cities?.length) {
        this.cityControl.dropdownOptions = cities.map((item: any) => ({
          label: item.cityNameUpper,
          value: item.cityNameUpper,
        }));

        this.formik.setFieldValue('CITY_STRING_READONLY', cities.length === 1 ? cities[0].cityNameUpper : '');

        this.setManualErrors?.(errors);
        setTimeout(() => this.formik.setErrors({ POST_CODE_STRING_CALCULATION: '' }), 0);
        return;
      } else {
        this.formik.setFieldValue('CITY_STRING_READONLY', '');
        this.cityControl.dropdownOptions = [];
        errors.set('POST_CODE_STRING_CALCULATION', 'ENTRY.CANDIDATE_DETAILS.CONTACT_INFO.postCode.frZipCodeError');
        this.setManualErrors?.(errors);
      }
    } else {
      this.formik.setFieldValue('CITY_STRING_READONLY', '');
      this.cityControl.dropdownOptions = [];
      this.setManualErrors?.(errors);
      this.formik.setErrors({});
    }
  }
}
