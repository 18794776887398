import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@store/rootReducer';

import { actionTypes } from '../DashboardFilters/DashboardFiltersConfigs';

interface AdminTableSearchProps {
  handleDispatch: (payload: { type: string; payload: string }) => void;
}

const AdminTableSearch: React.FC<AdminTableSearchProps> = ({ handleDispatch }) => {
  const { t } = useTranslation(['recruiter']);
  const { adminDashboardFilters } = useSelector((store: RootState) => store.oneOnboarding);

  const searchQueryValue = adminDashboardFilters?.searchQuery;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    handleDispatch({ type: actionTypes.SEARCH_TERM_UPDATE, payload: val });
  };

  return (
    <div className="tag-ds input-wrapper admin-table-search">
      <div className="input-container">
        <span className="material-icons input-icon-box">search</span>
        <input
          placeholder={t('recruiter:GENERAL.CANDIDATES_OVERVIEW.filterDetails')}
          type="text"
          onChange={handleSearch}
          value={searchQueryValue}
        />
      </div>
    </div>
  );
};

export default AdminTableSearch;
