import React from 'react';
import clsx from 'clsx';
import { Dropdown, DropdownButton, DropdownMenu } from '@adeccoux/tag-ds';

import style from './ConfigurationActions.module.scss';

interface Props {
  handleChange: (filter: string) => void;
  selectedFilter: string;
}

const ActivityHistoryFilter: React.FC<Props> = ({ handleChange, selectedFilter }) => {
  const filters = ['All', 'Removed', 'Added', 'Other changes'];

  return (
    <Dropdown style={{ marginTop: 20, marginBottom: 28 }}>
      <DropdownButton className="button-secondary small">
        {`Filter by ${selectedFilter}`}
        <span className="material-icons">keyboard_arrow_down</span>
      </DropdownButton>
      <DropdownMenu
        onClick={(e: any) => {
          handleChange(e.target.id);
        }}
      >
        {filters.map((filter, i) => {
          return (
            <p key={i} className={clsx(style.activityHistory__filterItem, 'base')} defaultValue={filter} id={filter}>
              {filter}
            </p>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ActivityHistoryFilter;
