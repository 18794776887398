import { Container } from 'inversify';
import { TenantContext } from '@context/tenantContext';
import { AdeccoAdAuthHelper } from '@helpers/adeccoAdAuthHelper';
import { AppConfig } from '@helpers/appConfig';
import { AuthHelper } from '@helpers/authHelper';
import { FiscalCodeValidationITHelper } from '@helpers/fiscalCodeValidationITHelperp';
import { Utils } from '@helpers/utils';

import { AamBackendApi } from './libs/aamBackendApi';
import { GoogleMapsApi } from './libs/googleMapsApi';
import { Logger } from './libs/logger';
import { UploadDocumentContex } from './libs/UploadDocumentContex';
export const container = new Container({
  skipBaseClassChecks: true,
});

// Helpers
container.bind(Utils).toSelf().inSingletonScope();
container.bind(AppConfig).toSelf().inSingletonScope();
container.bind(AuthHelper).toSelf().inSingletonScope();
container.bind(AdeccoAdAuthHelper).toSelf().inSingletonScope();
container.bind(FiscalCodeValidationITHelper).toSelf().inSingletonScope();

// Context
container.bind(TenantContext).toSelf().inSingletonScope();
container.bind(UploadDocumentContex).toSelf().inSingletonScope();

// Libs
container.bind(AamBackendApi).toSelf().inSingletonScope();
container.bind(GoogleMapsApi).toSelf().inSingletonScope();
container.bind(Logger).toSelf().inSingletonScope();
