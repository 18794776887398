import React from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { LIST_TYPES, TEXT_ALIGN_TYPES } from '../utils';

import controlsStyle from '../../Controls.module.scss';
import style from '../RichTextEditor.module.scss';

interface Props {
  format: string;
  icon: string;
  disabled: boolean;
}

const isBlockActive = (editor: any, format: string, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any) => {
        const copyn = n;
        return !Editor.isEditor(n) && SlateElement.isElement(n) && copyn[blockType] === format;
      },
    }),
  );

  return !!match;
};

const toggleBlock = (editor: any, format: string) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: any) => {
      const copyn = n;
      return (
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(copyn.type) &&
        !TEXT_ALIGN_TYPES.includes(format)
      );
    },
    split: true,
  });

  let newProperties: any;

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton: React.FC<Props> = ({ format, icon, disabled }) => {
  const editor = useSlate();
  return (
    <span
      onMouseDown={(event: any) => {
        if (disabled) return;

        event.preventDefault();
        toggleBlock(editor, format);
      }}
      className={`${style.editorOptions__item} ${
        isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')
          ? style.editorOptions__itemActive
          : ''
      } ${disabled ? controlsStyle['control--disabled'] : ''}`}
    >
      <span className="material-icons">{icon}</span>
    </span>
  );
};

export default BlockButton;
