import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';

import { AamBackendApi } from '../../../libs/aamBackendApi';

export const OneSpanCallbackPage: React.FC<any> = () => {
  const adeccoApi = useInjection(AamBackendApi);
  const location = useLocation();

  const returnToDocumentsPage = () => {
    const query = new URLSearchParams(location.search);

    const returnUrl = query.get('returnUrl');
    const filterName = query.get('filter');

    let href = `${returnUrl}?signed=true&redirectToNextStep=true`;

    if (filterName) {
      href += `&filter=${filterName}`;
    }

    window.location.href = href;
  };

  const saveDocumentAndRedirect = async (
    userId: string,
    packageId: string,
    document: any,
    candidateId: string | null,
  ) => {
    await adeccoApi.saveOneSpanSignedDocument(candidateId ? candidateId : userId, {
      document,
      packageId,
    });
    returnToDocumentsPage();
  };

  const candidateIdByReturnUrl = (returnUrl: string | null) => {
    if (!returnUrl) return null;
    const pathParams = returnUrl.split('/');
    const candidateIdIndex = pathParams.indexOf('user-flow');
    if (candidateIdIndex === -1) {
      return null;
    }

    return pathParams[candidateIdIndex + 1];
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    const packageId = query.get('package');
    const returnUrl = query.get('returnUrl');
    const document = query.get('document');
    const candidateId = query.get('candidateId') ?? candidateIdByReturnUrl(returnUrl);
    const signer = query.get('signer');
    const signerId = query.get('signerId');

    if (status === 'SIGNER_COMPLETE' && signer && packageId && returnUrl) {
      saveDocumentAndRedirect(signerId || signer, packageId, document, candidateId);
    } else {
      returnToDocumentsPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <Loader />;
};
