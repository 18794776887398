import React from 'react';

interface Props {
  name: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue?: (name: string, value: any, shouldValidate?: boolean) => void;
  customHandler?: (value: { component: string; customControlHeaders?: any }) => void;
  disabled: boolean;
}

const BooleanComponentType: React.FC<Props> = ({ name, value, setFieldValue, onChange, customHandler, disabled }) => {
  const options = [
    {
      value: 'Radio',
      label: 'Radio buttons',
      onChange: function (e: any) {
        onChange?.(e);
        setFieldValue?.('booleanComponentType', e.target.value, true);
        setFieldValue?.(
          'customControlHeaders',
          {
            name: 'YES_NO',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
                sequence: 10,
              },
              {
                label: 'No',
                value: 'No',
                sequence: 20,
              },
            ],
          },
          true,
        );

        customHandler?.({
          component: e.target.value,
          customControlHeaders: {
            name: 'YES_NO',
            options: [
              {
                label: 'Yes',
                value: 'Yes',
                sequence: 10,
              },
              {
                label: 'No',
                value: 'No',
                sequence: 20,
              },
            ],
          },
        });
      },
    },
    {
      value: 'Switch',
      label: 'Toggle',
      onChange: function (e: any) {
        onChange?.(e);
        setFieldValue?.('booleanComponentType', e.target.value, true);
        customHandler?.({ component: e.target.value });
      },
    },
    {
      value: 'Consent',
      label: 'Consent checkbox',
      onChange: function (e: any) {
        onChange?.(e);
        setFieldValue?.('booleanComponentType', e.target.value, true);
        customHandler?.({ component: e.target.value });
      },
    },
    {
      value: 'Checkbox',
      label: 'Simple checkbox',
      onChange: function (e: any) {
        onChange?.(e);
        setFieldValue?.('booleanComponentType', e.target.value, true);
        customHandler?.({ component: e.target.value });
      },
    },
  ];

  return (
    <div style={{ marginTop: 12 }}>
      <label className="caption">Boolean component type</label>
      <div className="tag-ds">
        {options.map((option, index) => {
          return (
            <div key={index} className="radio-container">
              <input
                id={option.value}
                type="radio"
                onChange={option.onChange}
                name="multipleSelectComponentType"
                checked={option.value === value}
                value={option.value}
                disabled={disabled}
              />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BooleanComponentType;
