import React, { useEffect, useState } from 'react';
import { Help, HelpOutline, Info, InfoOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';

import { HtmlCustom } from '../../HtmlCustom';

interface Props {
  name: string;
  value: string;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconType?: 'INFO' | 'HELP' | undefined;
  description?: string;
  disabled?: boolean;
  rootProps?: any;
  optionValue?: any;
  expandOnSelect?: boolean;
}

const RadioButton: React.FC<Props> = ({
  label,
  value,
  name,
  checked,
  onChange,
  iconType,
  description,
  disabled,
  rootProps,
  optionValue,
  expandOnSelect,
}) => {
  const [isIconActive, setIsIconActive] = useState(false);

  useEffect(() => {
    if (expandOnSelect) {
      setIsIconActive(checked);
    }
  }, [checked, expandOnSelect]);

  const renderIcon = () => {
    switch (iconType) {
      case 'HELP':
        return isIconActive ? <Help color="primary" /> : <HelpOutline color="primary" />;
      case 'INFO':
        return isIconActive ? <Info color="primary" /> : <InfoOutlined color="primary" />;
      default:
        return <></>;
    }
  };

  const id = `${name}_${optionValue}`;

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="tag-ds radio-container">
          <input
            id={id}
            type="radio"
            value={value}
            name={name}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <label htmlFor={id} style={{ flex: 1 }}>
            {label}
          </label>
        </div>
        <Box style={{ cursor: 'pointer' }} onClick={() => setIsIconActive((state) => !state)}>
          {renderIcon()}
        </Box>
      </div>
      {description && isIconActive && (
        <div style={{ backgroundColor: '#F8F8F9', padding: 16 }}>
          <HtmlCustom controlModel={{ ...rootProps, staticText: description }} />
        </div>
      )}
    </div>
  );
};

export default RadioButton;
