import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

import { AppButtonInterface } from './AppButton.interface';

export const AppButton: React.FC<AppButtonInterface> = ({ text, wrapperStyles, boxProps, ...buttonConfig }) => {
  return (
    <Box className="button" style={wrapperStyles} {...boxProps}>
      <Button {...buttonConfig}>{text}</Button>
    </Box>
  );
};
