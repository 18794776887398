import { OODocumentations } from '../interfaces';
import { OODocumentModel } from './DocumentModel';

interface ConstructorParams {
  name: OODocumentations;
  label: string;
  description: string;
  groupDocuments: boolean;
  isProfilePhoto: boolean;
  hideTakePhotoButton?: boolean;
  isMandatory: boolean;
  uploadEarly?: boolean;
  isMultipleDocumentsEnabled: boolean;
  uploadableByCandidate: boolean;
  documents: OODocumentModel[];
  mandatoryCondition?: [];
}

export class OODocumentationModel {
  public name: OODocumentations;
  public label: string;
  public description: string;
  public isProfilePhoto: boolean;
  public hideTakePhotoButton?: boolean;
  public groupDocuments: boolean;
  public isMandatory: boolean;
  public isMultipleDocumentsEnabled: boolean;
  public uploadableByCandidate: boolean;
  public documents: OODocumentModel[];
  public uploadEarly?: boolean;
  public mandatoryCondition?: [];

  constructor({
    name,
    label,
    description,
    isProfilePhoto,
    groupDocuments,
    isMandatory,
    isMultipleDocumentsEnabled,
    uploadableByCandidate,
    uploadEarly,
    documents,
    hideTakePhotoButton,
    mandatoryCondition,
  }: ConstructorParams) {
    this.name = name;
    this.label = label;
    this.description = description;
    this.groupDocuments = groupDocuments;
    this.isProfilePhoto = isProfilePhoto;
    this.hideTakePhotoButton = hideTakePhotoButton;
    this.isMandatory = isMandatory;
    this.isMultipleDocumentsEnabled = isMultipleDocumentsEnabled;
    this.documents = documents;
    this.uploadableByCandidate = uploadableByCandidate;
    this.uploadEarly = uploadEarly;
    this.mandatoryCondition = mandatoryCondition;
  }

  public static fromJson(json: any) {
    const {
      name,
      label,
      description,
      isProfilePhoto,
      isMandatory,
      isMultipleDocumentsEnabled,
      uploadableByCandidate,
      groupDocuments,
      hideTakePhotoButton,
      uploadEarly,
      mandatoryCondition,
    } = json;
    const documents = (json.documents || []).map(OODocumentModel.fromJson);
    return new OODocumentationModel({
      name,
      label,
      description,
      groupDocuments,
      isProfilePhoto,
      isMandatory,
      isMultipleDocumentsEnabled,
      uploadableByCandidate,
      documents,
      hideTakePhotoButton,
      uploadEarly,
      mandatoryCondition,
    });
  }
}
