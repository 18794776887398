import { createContext } from 'react';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';

export interface PageStepContextProps {
  page: OOPageModel | undefined;
  step: OOStepModel | undefined;
  setPageStepContext: (params: { page: OOPageModel | undefined; step: OOStepModel | undefined }) => void;
}

export const PageStepContext = createContext<PageStepContextProps>({
  page: undefined,
  step: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPageStepContext: (params: { page: OOPageModel | undefined; step: OOStepModel | undefined }) => {},
});
