import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ModalBody, ModalFooter, ModalHeader } from '@adeccoux/tag-ds';
import { Box, Modal } from '@mui/material';

import { AnchorType, SignatureAnchorWithUuid } from './SignatureAnchors';

import styles from './SignatureAnchors.module.scss';

interface Props {
  anchor: SignatureAnchorWithUuid;
  isActive: boolean;
  closeModal: () => void;
  handleSave: (editedAnchor: SignatureAnchorWithUuid) => void;
  anchorType: AnchorType;
}

const EditSignatureAnchor: React.FC<Props> = ({ anchor, isActive, closeModal, handleSave, anchorType }) => {
  const [draft, setDraft] = useState<null | SignatureAnchorWithUuid>(null);

  useEffect(() => {
    setDraft(anchor);
  }, [anchor]);

  const handleChange = (fieldName: string, fieldValue: any) => {
    if (draft === null) return;

    if (anchorType === AnchorType.TEXT) {
      if (fieldName === 'role') {
        setDraft((state) => {
          if (state !== null) {
            return {
              ...state,
              role: fieldValue,
            };
          }

          return state;
        });
      } else {
        setDraft((state) => {
          if (state !== null && 'extractAnchor' in state) {
            return {
              ...state,
              extractAnchor: {
                ...state.extractAnchor,
                [fieldName]: fieldValue,
              },
            };
          }
          return state;
        });
      }
    }

    if (anchorType === AnchorType.POSITION) {
      setDraft((state) => {
        if (state !== null) {
          return {
            ...state,
            [fieldName]: fieldValue,
          };
        }

        return state;
      });
    }
  };

  const renderInput = (fieldName: string, value: any) => {
    switch (fieldName) {
      case 'text':
        return (
          <input value={value} id={fieldName} type="text" onChange={(e) => handleChange(fieldName, e.target.value)} />
        );
      case 'top':
      case 'left':
      case 'page':
      case 'index':
      case 'width':
      case 'height':
      case 'topOffset':
      case 'leftOffset':
      case 'characterIndex':
        return (
          <input
            value={value}
            id={fieldName}
            type="number"
            onChange={(e) => handleChange(fieldName, Number(e.target.value))}
          />
        );
      case 'anchorPoint':
        return (
          <select
            value={value}
            id={fieldName}
            name={fieldName}
            onChange={(e) => handleChange(fieldName, e.target.value)}
          >
            <option value="BOTTOMRIGHT">Bottom right</option>
            <option value="TOPRIGHT">Top right</option>
          </select>
        );
      case 'role':
        return (
          <select
            id={fieldName}
            name={fieldName}
            value={draft?.role}
            onChange={(e) => handleChange(fieldName, e.target.value)}
          >
            <option value="candidate">candidate</option>
            <option value="recruiter">recruiter</option>
          </select>
        );
      default:
        return <div>`Unknown property - ${fieldName}`</div>;
    }
  };

  return (
    <Modal open={isActive}>
      <Box className={clsx('tag-ds', styles.modal)}>
        <ModalHeader style={{ marginBottom: 24 }}>Edit signature anchor</ModalHeader>
        {draft && (
          <ModalBody>
            {anchorType === AnchorType.TEXT && 'extractAnchor' in draft && (
              <div>
                <div style={{ marginBottom: 20 }}>
                  <label className="caption" htmlFor="role">
                    role
                  </label>
                  <select
                    id="role"
                    name="role"
                    value={draft?.role}
                    onChange={(e) => handleChange('role', e.target.value)}
                  >
                    <option value="candidate">candidate</option>
                    <option value="recruiter">recruiter</option>
                  </select>
                </div>
                {Object.keys(draft.extractAnchor).map((x) => {
                  return (
                    <div key={x} style={{ marginBottom: 20 }}>
                      <label className="caption" htmlFor={x}>
                        {x}
                      </label>
                      {renderInput(x, (draft.extractAnchor as any)[x])}
                    </div>
                  );
                })}
              </div>
            )}
            {anchorType === AnchorType.POSITION &&
              Object.keys(draft)
                .filter((x) => x !== 'extract' && x !== 'uuid')
                .map((x) => {
                  return (
                    <div key={x} style={{ marginBottom: 20 }}>
                      <label className="caption" htmlFor={x}>
                        {x}
                      </label>
                      {renderInput(x, (draft as any)[x])}
                    </div>
                  );
                })}
          </ModalBody>
        )}
        <ModalFooter>
          <button
            className="button-secondary"
            onClick={() => {
              closeModal();
              setDraft(null);
            }}
          >
            Cancel
          </button>
          <button
            className="button-primary"
            onClick={() => {
              if (draft !== null) {
                handleSave(draft);
                setDraft(null);
              }
            }}
          >
            Save changes
          </button>
        </ModalFooter>
      </Box>
    </Modal>
  );
};

export default EditSignatureAnchor;
