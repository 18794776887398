import React from 'react';
import clsx from 'clsx';
import { ModalBody, ModalFooter, ModalHeader } from '@adeccoux/tag-ds';
import { Box, Modal } from '@mui/material';

import styles from './ConfirmationDialog.module.scss';

interface NestedObjectListProps {
  isVisible: boolean;
  onSave: () => void;
  onCancel: () => void;
  onDiscard: () => void;
}

const ConfirmationDialog: React.FC<NestedObjectListProps> = ({ isVisible, onSave, onCancel, onDiscard }) => (
  <Modal open={isVisible}>
    <Box className={clsx('tag-ds', styles.modal)}>
      <ModalHeader className={styles.modal__header}>
        <h4>Unsaved changes</h4>
        <span className="material-icons" style={{ cursor: 'pointer' }} onClick={onCancel}>
          close
        </span>
      </ModalHeader>
      <ModalBody style={{ marginBottom: 48 }}>
        There are unsaved changes on the page. You can save changes, cancel to continue editing, or exit and discard
        changes.
      </ModalBody>
      <ModalFooter>
        <button className="button-secondary" onClick={onDiscard}>
          Exit and discard
        </button>
        <button className="button-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="button" onClick={onSave}>
          Save
        </button>
      </ModalFooter>
    </Box>
  </Modal>
);

export default ConfirmationDialog;
