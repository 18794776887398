import { injectable } from 'inversify';

@injectable()
export class TenantContext {
  public tenantId: number;

  public tenantAlias: string;

  constructor() {
    this.tenantId = parseInt(localStorage.getItem('tenantId')!, 10);
    this.tenantAlias = localStorage.getItem('tenantAlias')!;
  }
}
