import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';
import { RootState } from '@store/rootReducer';

import { renderFieldsForBoolean, renderFieldsForDatepicker, renderFieldsForTextfield } from './TypeOfControls';

import controlsStyle from '../Controls.module.scss';
import styles from './DocumentsControls.module.scss';

interface Props {
  item: {
    component: string;
    uuid: string;
    name: string;
    maxOffset: number;
    minOffset: number;
    min: number;
    max: number;
    isVisibleRecruiter: boolean;
    isEditableCandidate: boolean;
    isVisibleCandidate: boolean;
    isEditableRecruiter: boolean;
    isMandatory: boolean;
    minMaxValidationError: string;
    regexValidation: string;
    label: string;
    booleanComponentType: string;
    type: string;
    minMaxValidationErrorKey: string;
  };
  index: number;
  handleDelete: () => void;
  handleChange: (fieldName: string, value: any) => void;
  handleTranslation: (name: string, key: string, value: { [key: string]: string } | null, newKey?: string) => void;
  getPath: string;
  translations: any[];
  error: any;
  disabled: boolean;
}

const DocumentControl: React.FC<Props> = ({
  item,
  index,
  handleDelete,
  handleChange,
  handleTranslation,
  getPath,
  translations,
  error,
  disabled,
}) => {
  const { selectedConfiguration } = useSelector((store: RootState) => store.selfService);

  const availableLanguages = selectedConfiguration?.languages;

  const getValueForBoolean = () => {
    if (
      item.component === 'Checkbox' ||
      item.component === 'Switch' ||
      item.component === 'Consent' ||
      item.component === 'RadioGroup'
    ) {
      return 'Boolean';
    }

    return item.component;
  };

  const handleError = (fieldName: string) => {
    if (!error)
      return {
        isError: false,
        errorMessage: '',
      };

    return {
      isError: error[fieldName] ? true : false,
      errorMessage: error[fieldName],
    };
  };

  const findTranslations = translations.find((x) => x.name === item.name);

  return (
    <div style={{ marginTop: 16, position: 'relative' }} className={styles.item}>
      <div className={styles.item__header}>
        <span className={clsx('subtitle', styles['item__header-title'])}>Field {index + 1}</span>
        <div
          className={clsx(styles.item__icon, disabled ? controlsStyle['control--disabled'] : '')}
          onClick={handleDelete}
        >
          <span className="material-icons-outlined" style={{ fontSize: 16 }}>
            delete
          </span>
        </div>
      </div>
      <div className={styles.item__input}>
        <label className="caption">DOCUMENT NAME</label>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
          <input
            type="text"
            value={item.name}
            onChange={(e) => {
              const val = e.target.value.toUpperCase();

              handleChange('name', val);
              handleChange('label', `${getPath}${val.toLowerCase()}.label`);

              handleTranslation(item.name, 'name', null, val);
              handleTranslation(val, 'labelKey', null, `${getPath}${val.toLowerCase()}.label`);

              if (findTranslations?.minMaxValidationErrorKey) {
                handleChange('minMaxValidationError', `${getPath}${val.toLowerCase()}.minMaxValidationError`);

                handleTranslation(
                  val,
                  'minMaxValidationErrorKey',
                  null,
                  `${getPath}${val.toLowerCase()}.minMaxValidationError`,
                );
              }
            }}
            disabled={disabled}
          />
          <SelfServiceTooltip hoverContent="This is the technical name of this document group. It’s not the name shown to the candidate in the UI" />
        </div>
        {handleError('name').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {handleError('name').errorMessage}
          </p>
        )}
      </div>
      <div className={clsx(styles.item__input, 'input-wrapper')}>
        <label>Component</label>
        <select
          value={getValueForBoolean()}
          onChange={(e) => handleChange('component', e.target.value)}
          disabled={disabled}
        >
          <option value="Datepicker">Datepicker</option>
          <option value="Textfield">Textfield</option>
          <option value="Boolean">Boolean</option>
        </select>
      </div>
      <div className={styles.item__input}>
        <label>Label</label>
        <input type="text" value={findTranslations?.labelKey} disabled />
        {handleError('label').isError && (
          <p className="input-helper-text" style={{ margin: 0 }}>
            {handleError('label').errorMessage}
          </p>
        )}
      </div>
      <div className={styles.item__input}>
        {availableLanguages?.supported.map((language, i) => {
          return (
            <div key={i} style={{ marginBottom: 16 }}>
              <label className="caption">Label translation for {language}</label>
              <input
                type="text"
                value={(findTranslations?.label?.[language] as string) ?? ''}
                onChange={(e) => {
                  const payload: any = { ...findTranslations?.label, [language]: e.target.value };

                  for (const lang of availableLanguages.supported) {
                    if (!(lang in payload)) {
                      payload[lang] = undefined;
                    }
                  }

                  handleTranslation(item.name, 'label', payload);
                }}
                disabled={disabled}
              />
            </div>
          );
        })}
      </div>
      <div style={{ marginBottom: 24, width: 328 }}>
        <label className="subtitle" style={{ marginBottom: 8 }}>
          Visible
        </label>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <input
              type="checkbox"
              checked={item.isVisibleCandidate}
              onChange={(e) => handleChange('isVisibleCandidate', e.target.checked)}
              disabled={disabled}
            />
            <div>For candidate</div>
          </div>
          <div style={{ display: 'flex' }}>
            <input
              type="checkbox"
              checked={item.isVisibleRecruiter}
              onChange={(e) => handleChange('isVisibleRecruiter', e.target.checked)}
              disabled={disabled}
            />
            <div>For recruiter</div>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: 24, width: 328 }}>
        <label className="subtitle" style={{ marginBottom: 8 }}>
          Editable
        </label>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <input
              type="checkbox"
              checked={item.isEditableCandidate}
              onChange={(e) => handleChange('isEditableCandidate', e.target.checked)}
              disabled={disabled}
            />
            <div>For candidate</div>
          </div>
          <div style={{ display: 'flex' }}>
            <input
              type="checkbox"
              checked={item.isEditableRecruiter}
              onChange={(e) => handleChange('isEditableRecruiter', e.target.checked)}
              disabled={disabled}
            />
            <div>For recruiter</div>
          </div>
        </div>
      </div>
      {renderFieldsForDatepicker(
        { maxOffset: item.maxOffset, minOffset: item.minOffset },
        item.component,
        (value: any) => handleChange('typeOfDate', value),
        disabled,
      )}
      {renderFieldsForTextfield(
        {
          min: item.min,
          max: item.max,
          minMaxValidationError: item.minMaxValidationError,
          regexValidation: item.regexValidation,
          name: item.name,
          label: item.label,
        },
        item.component,
        handleTranslation,
        (field: string, val: any) => handleChange(field, val),
        translations,
        selectedConfiguration,
        getPath,
        handleError,
        disabled,
      )}
      {renderFieldsForBoolean(
        item.component,
        item.type,
        (value: any) => handleChange('booleanComponentType', value),
        disabled,
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 16 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            checked={item.isMandatory}
            onChange={(e) => handleChange('isMandatory', e.target.checked)}
            style={{ margin: 0, marginRight: 8 }}
            disabled={disabled}
          />
          <label>Mandatory</label>
          <SelfServiceTooltip hoverContent="If ticked, then candidate must upload this document" />
        </div>
      </div>
    </div>
  );
};

export default DocumentControl;
