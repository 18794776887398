import { useFormikContext } from 'formik';

export const useFilterCondition = (filterCondition?: Array<Record<string, any>>) => {
  const formik = useFormikContext<Record<string, any>>();
  let filterData = {};
  let isDisabled = false;
  if (filterCondition) {
    for (let i = 0; i < filterCondition.length; i++) {
      const { control, filterColumn } = filterCondition[i];
      filterData = {
        [filterColumn]: formik.values[control.name],
      };

      if (!formik.values[control.name]) {
        isDisabled = true;
      }
    }
  }
  return { filterData, isDisabled };
};
