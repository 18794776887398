import React from 'react';
import { Table, TBody, TElement, THead, TRow } from '@adeccoux/tag-ds';
import { descriptionMap, TargetName, TelemetryList, TelemetrySearch } from '@models/telemetry.model';

import AdminPagination from '../../../components/AdminPagination';

interface TelemetryTableProps {
  telemetryList: TelemetryList | undefined;
  pagination: {
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    rowsPerPage: number;
    page: number;
  };
}

const columns = [
  { field: 'timestamp', headerName: 'Timestamp' },
  { field: 'actor', headerName: 'Actor' },
  { field: 'description', headerName: 'Description' },
  { field: 'actorId', headerName: 'Actor Id' },
  { field: 'eventType', headerName: 'Event type' },
  { field: 'targetName', headerName: 'Target name' },
  { field: 'onboardingStatus', headerName: 'Onboarding status' },
];

export const TelemetryTable: React.FC<TelemetryTableProps> = ({ telemetryList, pagination }) => {
  const prepareList = (): TelemetryList => {
    const result: TelemetryList = { results: [], total: 0 };
    if (!telemetryList) {
      return result;
    }

    result.total = telemetryList.total;

    result.results = telemetryList.results;

    return result;
  };

  const mapDescription = (eventType: string, targetName: TargetName | undefined) => {
    return descriptionMap.find(
      (x) => x.eventType === eventType && (x.targetName === targetName || x.targetName === TargetName.Any),
    )?.description;
  };

  const renderList = prepareList();

  return (
    <>
      <Table>
        <THead>
          <TRow>
            {columns.map(({ field, headerName }) => (
              <TElement key={field} style={{ position: 'relative' }}>
                {headerName}
              </TElement>
            ))}
          </TRow>
        </THead>
        <TBody>
          {renderList?.total > 0 &&
            renderList.results.map((item: TelemetrySearch, index) => (
              <TRow key={index} onClick={() => navigator.clipboard.writeText(item.candidateId || '')}>
                <TElement>{new Date(item.timestamp).toLocaleString()}</TElement>
                <TElement>{item.actor}</TElement>
                <TElement>{mapDescription(item.eventType, item.targetName)}</TElement>
                <TElement>{item.actorId}</TElement>
                <TElement>{item.eventType}</TElement>
                <TElement>{item.targetName}</TElement>
                <TElement>{item.onboardingStatus}</TElement>
              </TRow>
            ))}
        </TBody>
      </Table>
      {renderList.total > 0 && (
        <AdminPagination
          {...pagination}
          total={renderList.total}
          currentPage={pagination.page}
          isRowsPerPageVisible={true}
          setPage={pagination.setPage}
        />
      )}
    </>
  );
};
