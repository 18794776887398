import React from 'react';

import { AccordionWithAddButton, AccordionWithAddButtonProps } from './AccordionWithAddButton';

type AccordionWithAddButtonOpenModalProps = AccordionWithAddButtonProps;

const AccordionWithAddButtonOpenModal: React.FC<AccordionWithAddButtonOpenModalProps> = ({ controlModel }) => {
  return <AccordionWithAddButton isOpen={true} controlModel={controlModel} />;
};

export default AccordionWithAddButtonOpenModal;
