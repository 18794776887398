import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Searchbox, Tooltip } from '@adeccoux/tag-ds';
import { Box } from '@mui/material';

import ClientsAndJourneysList from './ClientsAndJourneysList';
import CollapseButton from './CollapseButton';
import RegionsAndBranchesList from './RegionsAndBranchesList';

import styles from '../UserPermissions.module.scss';

export enum ListType {
  CLIENTS_AND_JOURNEYS = 'CLIENTS_AND_JOURNEYS',
  REGIONS_AND_BRANCHES = 'REGIONS_AND_BRANCHES',
}

interface NestedListSelectorProps {
  type: ListType;
  options: any[];
  checkedOptions: any;
  handleChange: (a: string, b: string | null) => void;
  handleSelectAll: (type: ListType, isChecked: boolean) => void;
  masterCheck: {
    set: () => void;
    get: boolean;
  };
  handleCollapse: (
    type: ListType,
    itemId: string,
  ) => {
    set: any;
    setAllCollapsed: any;
    get: any;
    isEverythingCollapsed: boolean;
  };
}

const NestedListSelector: React.FC<NestedListSelectorProps> = ({
  type,
  options,
  checkedOptions,
  handleChange,
  handleSelectAll,
  masterCheck,
  handleCollapse,
}) => {
  const [search, setSearch] = useState('');

  const iconRef = useRef<any>(null);
  const { t } = useTranslation(['recruiter']);

  const checkConditions = () => {
    if (type === ListType.CLIENTS_AND_JOURNEYS) {
      return {
        isEverythingSelected:
          checkedOptions.length ===
          options.reduce((acc, curr) => {
            acc += curr.configs.length;

            return acc;
          }, 0),
        isAnythingSelected: checkedOptions.length > 0,
        isIntermediate: function () {
          return !this.isEverythingSelected && this.isAnythingSelected;
        },
      };
    }
    if (type === ListType.REGIONS_AND_BRANCHES) {
      return {
        isEverythingSelected:
          checkedOptions.branchIds.length ===
          options.reduce((acc, curr) => {
            acc += curr.branches.length;

            return acc;
          }, 0),
        isAnythingSelected: checkedOptions.branchIds.length > 0,
        isIntermediate: function () {
          return !this.isEverythingSelected && this.isAnythingSelected;
        },
      };
    }
  };

  const filterOptions = useMemo(() => {
    let filtered = [];
    const copyOptions = [...options];
    if (search.trim() === '') {
      filtered = options;
    }
    if (search.trim() !== '') {
      const searchLowerCase = search.toLowerCase();
      if (type === ListType.CLIENTS_AND_JOURNEYS) {
        filtered = copyOptions.filter((x) => {
          const clientOrganization = x.clientOrganizationDetails.name.toLowerCase().includes(searchLowerCase);
          const journey = x.configs.find((y: any) => {
            if (y.candidateName.toLowerCase().includes(searchLowerCase)) {
              return x;
            }
            return null;
          });

          return clientOrganization || journey;
        });
      }

      if (type === ListType.REGIONS_AND_BRANCHES) {
        filtered = copyOptions.filter((x) => {
          const region = x.regionDetails.name.toLowerCase().includes(searchLowerCase);
          const branch = x.branches.find((y: any) => {
            if (y.name.toLowerCase().includes(searchLowerCase) || y.sid?.toLowerCase()?.includes(searchLowerCase)) {
              return x;
            }
            return null;
          });

          return region || branch;
        });
      }
    }

    return filtered;
  }, [search, options, type]);

  const renderList = () => {
    const list: { [key in ListType]: JSX.Element } = {
      [ListType.CLIENTS_AND_JOURNEYS]: (
        <ClientsAndJourneysList
          options={filterOptions}
          checkedJourneys={checkedOptions}
          handleChange={handleChange}
          isDisabled={masterCheck.get}
          handleCollapse={handleCollapse}
        />
      ),
      [ListType.REGIONS_AND_BRANCHES]: (
        <RegionsAndBranchesList
          options={filterOptions}
          checkedRegionsAndBranches={checkedOptions}
          handleChange={handleChange}
          isDisabled={masterCheck.get}
          handleCollapse={handleCollapse}
          userSearchBranches={search}
        />
      ),
    };

    return list[type];
  };

  return (
    <div className={styles.nestedListSelector__container} style={{ height: '100%' }}>
      <div className="tag-ds ">
        <div className="scrollbar-wrapper">
          <div className="scrollbar-box" />
        </div>
      </div>
      <Box display="flex">
        <div className="tag-ds checkbox-container">
          <input id={'master-check' + type} type="checkbox" checked={masterCheck.get} onChange={masterCheck.set} />
          <label htmlFor={'master-check' + type}>
            {type === ListType.CLIENTS_AND_JOURNEYS
              ? t('GENERAL.PERMISSIONS.allClientsAndJuorneys')
              : t('GENERAL.PERMISSIONS.allRegionsAndBranches')}
          </label>
        </div>
        <Box display="flex" alignItems="center" className={styles.toolTipContainer}>
          <span className="material-icons" ref={iconRef}>
            help_outline
          </span>
          <Tooltip className="tooltip bottom" parentRef={iconRef} renderAsPortal>
            {type === ListType.CLIENTS_AND_JOURNEYS
              ? t('GENERAL.PERMISSIONS.allClientsTooltip')
              : t('GENERAL.PERMISSIONS.allRegionsToolTip')}
          </Tooltip>
        </Box>
      </Box>
      <Searchbox
        onChange={(e) => setSearch(e.target.value)}
        // onRemove={() => setSearch('')}
        placeholder={t('GENERAL.PERMISSIONS.search')}
        value={search}
        className="tag-ds input-container"
        small
        id={styles.searchBox}
      />
      <CollapseButton handleCollapse={handleCollapse} listType={type} />
      <div className="tag-ds checkbox-container" style={{ marginBottom: 8 }}>
        <input
          id={type}
          type="checkbox"
          onChange={(e: any) => {
            handleSelectAll(type, e.target.checked);
          }}
          disabled={masterCheck.get}
          checked={checkConditions()?.isEverythingSelected || checkConditions()?.isAnythingSelected}
          className={checkConditions()?.isIntermediate() ? 'intermediate' : ''}
        />
        <label htmlFor={type}>{t('GENERAL.GENERIC.selectAll')}</label>
      </div>
      {renderList()}
    </div>
  );
};

export default NestedListSelector;
