import React, { useState } from 'react';

import FullScreenPage from '../../../../../components/FullScreenPage/FullScreenPage';
import { arrayMove } from '../../DraggableComponent';
import EditOptionModal from './EditOptionModal';
import SingleSelectItem from './SingleSelectItem';
import { Item } from './SingleSelectOptions';

import style from './SingleSelectOptions.module.scss';

interface Props {
  onClose: () => void;
  items: any;
  setItems: any;
  handleFormikChange: any;
  setFieldValue: any;
  selectOptionsTranslations: any;
}

const EditListBe: React.FC<Props> = ({
  onClose,
  items,
  setItems,
  handleFormikChange,
  setFieldValue,
  selectOptionsTranslations,
}) => {
  const [itemForEdit, setItemForEdit] = useState<Item | null>(null);

  const onOptionDrop = (e: any, targetField: string) => {
    if (targetField === null) return;

    const currentArray = items;
    const id = e.dataTransfer.getData('name');

    const dragIndex = currentArray?.findIndex((x: any) => x.name === id) || 0;
    const targetIndex = currentArray?.findIndex((x: any) => x.name === targetField) || 0;

    const newArr = arrayMove(currentArray || [], dragIndex, targetIndex);

    const withNewSequence = newArr.map((x, i) => {
      const newSequence = i * 10;
      return { ...x, sequence: newSequence };
    });

    setItems(withNewSequence);
    handleFormikChange(withNewSequence);
  };

  const handleItemChange = (newItem: Item) => {
    const newItems = items?.map((x: any) => {
      if (x.uuid === newItem?.uuid) {
        return newItem;
      } else {
        return x;
      }
    });

    if (!newItems) return;

    setItemForEdit(newItem);
    setItems(newItems);
    handleFormikChange(newItems);
  };

  const deleteItem = (item: Item) => {
    const newItems = items?.filter((x: any) => x.label !== item.label);

    if (!newItems) return;
    setItems(newItems);
    handleFormikChange(newItems);
  };

  const handleDefaultOption = (item: Item, isActive: boolean) => {
    const newItems = items?.map((x: any) => {
      if (x.uuid === item?.uuid) {
        return { ...x, defaultOption: isActive };
      } else {
        return { ...x, defaultOption: false };
      }
    });

    if (!newItems) return;

    setItems(newItems);
    handleFormikChange(newItems);
  };

  return (
    <FullScreenPage
      onClose={() => {
        // Setting some non-valid translations because its mandatory field
        setFieldValue('selectOptionsTranslations', {
          __NO_TRANSLATION: '__NO_TRANSLATION',
        });
        onClose();
      }}
      backText="Setup steps"
    >
      <>
        <div className={`"tag-ds" ${style.container}`}>
          {items && items?.length > 0 && (
            <div>
              {items
                .sort((a: any, b: any) => a.sequence - b.sequence)
                .map((item: any) => {
                  return (
                    <SingleSelectItem
                      key={item.uuid}
                      item={item}
                      handleDefaultOption={() => handleDefaultOption(item, !item.defaultOption)}
                      handleDelete={() => deleteItem(item)}
                      onOptionDrop={(e, targetField) => onOptionDrop(e, targetField)}
                      setItemForEdit={() => setItemForEdit(item)}
                      defaultOption={item?.defaultOption}
                      fromBackend
                    />
                  );
                })}
            </div>
          )}
        </div>
        {itemForEdit && (
          <EditOptionModal
            itemForEdit={itemForEdit}
            handleItemChange={handleItemChange}
            handleTranslationChange={(translationValues) => {
              setFieldValue('selectOptionsTranslations', {
                ...selectOptionsTranslations,
                ['control-items:' + itemForEdit.label]: translationValues,
              });
            }}
            translationValue={selectOptionsTranslations['control-items:' + itemForEdit.label]}
            closeModal={() => setItemForEdit(null)}
          />
        )}
      </>
    </FullScreenPage>
  );
};

export default EditListBe;
