import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { OOForm } from '@features/oneOnboarding/controls/Form';
import { useT } from '@features/oneOnboarding/hooks/useT';
import { OOPageModel } from '@features/oneOnboarding/models/FormModel';
import useBackendTranslations from '@hooks/useBackendTranslations';
import { RootState } from '@store/rootReducer';
import { handleFlowWrapper } from '@store/slices/selfService.slice';

import styles from '../../ConfigurationSetup.module.scss';

const PagePreview = () => {
  const [uiForm, setUiForm] = useState<{ form: any; flowWrapper: any }>({ form: null, flowWrapper: null });

  const { selectedConfiguration, selectedFeature } = useSelector((store: RootState) => store.selfService);
  const { i18n } = useT('control-items');

  useBackendTranslations(i18n, false);

  useEffect(() => {
    if (!selectedFeature) return;

    const getUIform = () => handleFlowWrapper(selectedConfiguration, selectedFeature as any);

    setUiForm(getUIform() as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFlowWrapper, selectedConfiguration, selectedFeature]);

  //TODO TEMPORARY SOLUTION
  // Intention of this function is to sync structure between export and flow service
  // Fix this when yuri come back
  const syncModels = useMemo(() => {
    const syncControls = uiForm.form?.controls.map((control: any) => {
      if (control.subControls?.[0].constructor !== Array && control.category === 'array') {
        control.subControls = [control.subControls];
      }

      return control;
    });

    return new OOPageModel({ ...uiForm.form, controls: syncControls });
  }, [uiForm.form]);

  if (!syncModels.controls) return <></>;

  return (
    <div className={styles.pagePreview__container}>
      <div className={styles.pagePreview__grayArea}>
        <span className={`tag-ds small-title ${styles.pagePreview__title}`}>Live preview</span>
        <div className={styles.pagePreview__formContainer}>
          {uiForm && uiForm.flowWrapper && syncModels.controls && (
            <OOForm form={syncModels} flowWrapper={uiForm.flowWrapper} fromSelfService={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PagePreview;
