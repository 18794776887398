import React from 'react';
import { Component } from '@features/oneOnboarding/interfaces';

import styles from '../PageFeature.module.scss';

interface Props {
  enabledControls: string[];
  onSelect: (selectedComponent: string) => void;
}

const NewControlSelector: React.FC<Props> = ({ enabledControls, onSelect }) => {
  const getComponentInformation = (controlName: string) => {
    const hash: any = {
      [Component.Datepicker]: {
        icon: 'date_range',
        iconOutlined: false,
        uiName: 'Datepicker',
      },
      [Component.Heading]: { icon: 'title', iconOutlined: false, uiName: 'Heading' },
      [Component.Html]: { icon: 'subject', iconOutlined: false, uiName: 'Body text' },
      [Component.Textfield]: { icon: 'text_fields', iconOutlined: false, uiName: 'Text input field' },
      [Component.MultilineTextfield]: {
        icon: 'text_fields',
        iconOutlined: false,
        uiName: 'Text input field multiline',
      },
      SingleSelect: { icon: 'radio_button_checked', iconOutlined: true, uiName: 'Single select' },
      [Component.MultipleSelect]: { icon: 'checklist', iconOutlined: false, uiName: 'Multiple Select' },
      Boolean: { icon: 'rule', iconOutlined: false, uiName: 'Boolean' },
    };

    if (!hash[controlName]) return 'variables';

    return hash[controlName];
  };

  return (
    <div>
      <div className={styles.newControlSelector__tileContainer}>
        {enabledControls.map((control, index) => {
          const { icon, iconOutlined, uiName } = getComponentInformation(control);

          return (
            <div key={index} className={styles.newControlSelector__tile} onClick={() => onSelect(control)}>
              <div
                className={`${iconOutlined ? 'material-icons-outlined' : 'material-icons'} ${
                  styles.newControlSelector__tileIcon
                } `}
              >
                {icon || 'checklist'}
              </div>
              <p className="base">{uiName || control}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewControlSelector;
