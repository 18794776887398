import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAllSteps, useSteps } from '@features/oneOnboarding/hooks/useSteps';
import { OOStepModel } from '@features/oneOnboarding/models/StepModel';
import { OOFlowWrapper } from '@features/oneOnboarding/wrappers/FlowWrapper';
import { useGetUserFlow } from '@hooks/apiHooks';
import { useDecodedParams } from '@hooks/useDecodedParams';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';

export const useCandidateProfileData = () => {
  const { clientOrganizationId, userId, applicationId, configurationId } = useDecodedParams();
  const dispatch = useDispatch();

  const { data: userFlow, isInitialLoading: fetchFlowLoading } = useGetUserFlow(
    clientOrganizationId,
    configurationId,
    userId,
    applicationId,
    false,
    (data: any) => dispatch(oneOnboardingSlice.actions.setFlowData(data)),
  );
  const flowWrapper = useMemo(
    () => (userFlow ? OOFlowWrapper.create(userFlow?.selectedConfiguration?.flow) : undefined),
    [userFlow],
  );
  const steps = useSteps(flowWrapper, userFlow?.currentStep);
  const allSteps = useAllSteps(flowWrapper, userFlow?.currentStep);
  let currentStep: OOStepModel | undefined;
  if (userFlow?.currentStep === 'DOCUMENT_VALIDATION') {
    currentStep = flowWrapper?.getStep('DOCUMENT_UPLOAD');
  } else if (userFlow?.currentStep === 'RECRUITER_SIGN_DOCUMENTS') {
    currentStep = flowWrapper?.getStep('CANDIDATE_SIGN_DOCUMENTS');
  } else {
    currentStep = flowWrapper?.getStep(userFlow?.currentStep ?? 'CANDIDATE_DETAILS');
  }

  const currentPage = currentStep?.pages?.find((page) => page.name === userFlow?.currentPage);
  return { flowWrapper, steps, allSteps, currentStep, userFlow, fetchFlowLoading, userId, currentPage };
};
