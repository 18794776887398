import { useFormikContext } from 'formik';

import { OOControlModelInterface } from '../interfaces';
import { OOControlModel } from '../models/ControlModel';
import { OOStepModel } from '../models/StepModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';

const calculateFormValues = (
  conditionalArray: Array<Record<string, any>>,
  flowWrapper: OOFlowWrapper,
  formValues: Record<string, any>,
): Record<string, any> => {
  const result: Record<string, any> = {};

  conditionalArray.forEach((condition) => {
    outer: for (const step of flowWrapper.steps) {
      for (const page of step.pages) {
        for (const control of page.controls) {
          /*
          If the control exists in the formValues object, it means it's evident on the page we're currently on, so we should not take it from the flow but from the form.
          If we try to take it from the flow, we may end up with obsolete value that may or may not satisfy the condition as of the moment.
          */
          if (control.name === condition.control?.name && !formValues[control.name]) {
            result[control.name] = control.value;
            break outer;
          }
        }
      }
    }
  });
  return Object.assign({}, result, formValues);
};
const evaluateCondition = (
  condition: Array<Record<string, any>>,
  formValues: Record<string, any>,
  isControlBeingEvaluatedASubControl?: boolean,
  parentFormName?: string,
  parentFormIndex?: number,
) => {
  return condition.reduce((previousValue, { control, value, notValue, operator }, index) => {
    let controlFromCondition = control.name;
    if (isControlBeingEvaluatedASubControl && parentFormName && parentFormIndex !== undefined) {
      controlFromCondition = OOControlModel.generateNameForSubControl(parentFormName, parentFormIndex, control.name);
    }
    const formikValue = value === false ? !!formValues[controlFromCondition] : formValues[controlFromCondition];
    const conditionResult = !(
      (typeof value !== 'undefined' && formikValue !== value) ||
      (typeof notValue !== 'undefined' && formikValue === notValue)
    );

    if (index === 0) {
      return conditionResult;
    }

    operator = operator || 'AND';

    if (operator === 'OR') {
      return previousValue || conditionResult;
    } else if (operator === 'AND') {
      return previousValue && conditionResult;
    } else {
      throw new Error(`Unsupported operator: ${operator}`);
    }
  }, false);
};
const evaluateControlCondition = (
  conditionType: 'renderCondition' | 'mandatoryCondition' | 'editableCondition',
  mainControl: OOControlModelInterface,
  formValues: Record<string, any>,
) => {
  const condition = mainControl[conditionType]!;

  const isControlBeingEvaluatedASubControl = !!(
    mainControl.parentFormName &&
    (mainControl.parentFormIndex || mainControl.parentFormIndex === 0)
  );

  return evaluateCondition(
    condition,
    formValues,
    isControlBeingEvaluatedASubControl,
    mainControl.parentFormName,
    mainControl.parentFormIndex,
  );
};

const evaluateStepCondition = (
  conditionType: 'completeCondition',
  step: OOStepModel,
  formValues: Record<string, any>,
) => {
  const condition = step[conditionType]!;

  return evaluateCondition(condition, formValues);
};

const evaluateRenderCondition = (
  c: OOControlModelInterface,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
) => {
  if (!c || !c.renderCondition || !flowWrapper) {
    return true;
  }

  const values = calculateFormValues(c.renderCondition, flowWrapper, formValues);

  const flowWrapperEditableCondition = c.editableCondition?.find(
    (condition) =>
      condition?.control?.sourceClass === 'flowWrapper' && condition?.control?.sourceField && condition?.value,
  );

  if (flowWrapperEditableCondition && flowWrapper) {
    const currentStepAndPage = flowWrapper.getCurrentStepAndPage() as any;
    const currentValue = currentStepAndPage[flowWrapperEditableCondition.control.sourceField];
    if (currentValue) {
      values[flowWrapperEditableCondition.control?.name] = currentValue;
    }
  }
  return evaluateControlCondition('renderCondition', c, values);
};
const evaluateMandatoryCondition = (
  c: OOControlModelInterface,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
): boolean | null => {
  if (!c || !c.mandatoryCondition || !flowWrapper) {
    return null;
  }

  const values = calculateFormValues(c.mandatoryCondition, flowWrapper, formValues);
  return evaluateControlCondition('mandatoryCondition', c, values);
};

const evaluateEditableCondition = (
  c: OOControlModelInterface,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
): boolean | null => {
  if (!c || !c.editableCondition || !flowWrapper) {
    return null;
  }

  const values = calculateFormValues(c.editableCondition, flowWrapper, formValues);

  const flowWrapperEditableCondition = c.editableCondition?.find(
    (condition) =>
      condition?.control?.sourceClass === 'flowWrapper' && condition?.control?.sourceField && condition?.value,
  );

  if (flowWrapperEditableCondition && flowWrapper) {
    const currentStepAndPage = flowWrapper.getCurrentStepAndPage() as any;
    const currentValue = currentStepAndPage[flowWrapperEditableCondition.control.sourceField];
    if (currentValue) {
      values[flowWrapperEditableCondition.control?.name] = currentValue;
    }
  }
  return evaluateControlCondition('editableCondition', c, values);
};

export const useRenderCondition = (c: OOControlModelInterface, flowWrapper: OOFlowWrapper | null) => {
  const formik = useFormikContext<Record<string, any>>();
  return evaluateRenderCondition(c, flowWrapper, formik.values);
};

export const renderCondition = (
  c: OOControlModelInterface,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
) => {
  return evaluateRenderCondition(c, flowWrapper, formValues);
};

export const mandatoryCondition = (
  c: OOControlModelInterface,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
) => {
  return evaluateMandatoryCondition(c, flowWrapper, formValues);
};

export const editableCondition = (
  c: OOControlModelInterface,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
) => {
  return evaluateEditableCondition(c, flowWrapper, formValues);
};

export const completeCondition = (
  step: OOStepModel,
  flowWrapper: OOFlowWrapper | null,
  formValues: Record<string, any>,
) => {
  if (!step || !step.completeCondition || !flowWrapper) {
    return null;
  }
  const values = calculateFormValues(step.completeCondition, flowWrapper, formValues);
  return evaluateStepCondition('completeCondition', step, values);
};

export const mandatoryDocumentCondition = (document: any, flow: any): boolean => {
  const conditions = document.mandatoryCondition;

  if (!conditions || conditions.length === 0) {
    return false;
  }

  const personalDetailInfo = flow.find((f: any) => f.name === 'CANDIDATE_DETAILS').pages;

  const allControlls = personalDetailInfo.reduce((all: any, curr: any) => {
    all.push(...curr.controls);
    return all;
  }, []);

  let valueMatched: boolean = false;
  let notValueMatched: boolean = false;
  let checked: boolean = false;

  conditions.forEach((condition: any) => {
    const candidateDetails = allControlls.find((c: any) => {
      return c.name === condition.control.name;
    });
    if (!candidateDetails) return;

    if (condition.value) {
      valueMatched = condition?.value === candidateDetails.value || false;
    } else if (condition.notValue) {
      notValueMatched = condition.notValue !== candidateDetails.value || false;
    }

    if (condition.operator && condition.operator === 'AND') {
      checked = valueMatched && notValueMatched;
    } else if (condition.operator && condition.operator === 'OR') {
      checked = valueMatched || notValueMatched;
    } else {
      checked = valueMatched || notValueMatched;
    }
  });

  return checked;
};
