import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Option, Select } from '@adeccoux/tag-ds';
import { Dialog } from '@mui/material';

const fonts: { id: number; fontName: string }[] = [
  { id: 1, fontName: 'Roboto, sans-serif' },
  { id: 2, fontName: 'SignPainter' },
  { id: 3, fontName: 'Bradley Hand' },
];

interface OODigitalSignatureDialogProps {
  open: boolean;
  signatureModalData: any;
  modalDataChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveSignature: (data: any) => void;
  onClose: () => void;
}

export const OODigitalSignatureDialog: React.FC<OODigitalSignatureDialogProps> = ({
  open,
  signatureModalData,
  modalDataChangeHandler,
  handleSaveSignature,
  onClose,
}) => {
  const { t } = useTranslation(['candidate_recruiter']);
  const [font, setFont] = useState<string>();
  const [error, setError] = React.useState('');

  useEffect(() => {
    if (!signatureModalData.fontFamily) {
      setFont(fonts[0].fontName);
    } else {
      setFont(signatureModalData.fontFamily);
    }
  }, [signatureModalData.fontFamily]);

  const validate = (value: string) => {
    const parts = value.split(' ');
    const validated = parts && parts.length >= 2 && parts.every((p) => p.length > 1) ? true : false;
    setError(validated ? '' : t('candidate_recruiter:APPROVAL.SIGNATURE.required'));
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={() => {
        setError('');
        onClose();
      }}
      style={{ overflowY: 'visible' }}
      PaperProps={{
        style: {
          borderRadius: 8,
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(28,48,78,48%)',
        },
      }}
    >
      <div className="tag-ds modal docs ">
        <button
          className="modal-close-button"
          onClick={() => {
            setError('');
            onClose();
          }}
        >
          <span className="material-icons">close</span>
        </button>
        <h4 className="modal-header">{t('candidate_recruiter:APPROVAL.SIGNATURE.addYourDigitalSignature')}</h4>

        <div className="modal-body">
          <p className="tag-ds  subtitle">{t('candidate_recruiter:APPROVAL.SIGNATURE.typeYourSignatureIn')}</p>
          <input
            placeholder={t('candidate_recruiter:APPROVAL.SIGNATURE.placeholder')}
            type="text"
            name="legalName"
            value={signatureModalData.legalName}
            onBlur={(e) => {
              validate(e.target.value);
            }}
            onChange={modalDataChangeHandler}
            className="input_large"
            style={{ width: '100%', fontFamily: font }}
          />
          <p className="tag-ds  small" style={{ color: 'red' }}>
            {error}
          </p>
          <p className="tag-ds  small">{t('candidate_recruiter:APPROVAL.SIGNATURE.signatureLegal')}</p>
          <Select
            className="tag-ds"
            placeholder={t('candidate_recruiter:APPROVAL.SIGNATURE.changeFont')}
            name="select"
            onChange={(e) => {
              const fontName = fonts.find((x) => x.id === e)?.fontName;
              if (!fontName) return;

              setFont(fontName);
            }}
            value={fonts.find((x) => x.fontName === font)?.id}
          >
            {fonts.map((x) => {
              return (
                <Option label={x.fontName} value={x.id} key={x.id}>
                  {x.fontName}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="modal-footer">
          <button
            className="button-primary"
            onClick={() => {
              validate(signatureModalData.legalName);
              if (!error) {
                handleSaveSignature({
                  legalName: signatureModalData.legalName,
                  fontFamily: font,
                });
              }
            }}
          >
            {t('candidate_recruiter:APPROVAL.SIGNATURE.createSignature')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};
