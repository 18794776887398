import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInjection } from '@context/inversify-context-provider';
import { OOStepElementModel } from '@features/oneOnboarding/models/StepElementModel';
import { Box, Typography } from '@mui/material';
import { store } from '@store/index';
import { RootState } from '@store/rootReducer';
import { authAppSlice } from '@store/slices/authApp.slice';
import { setApplicationId } from '@store/slices/oneOnboarding.slice';

import { AamBackendApi } from '../../../libs/aamBackendApi';

interface HeaderMenuProps {
  elements: OOStepElementModel[];
  hasMultipleOnboarding: boolean;
  isOnPreRegisterPage: boolean;
  isAuthenticated: boolean;
  showUnsavedChangesConfirmationModal?: () => Promise<boolean>;
}
export const HeaderMenu: FC<HeaderMenuProps> = ({
  elements,
  hasMultipleOnboarding,
  isOnPreRegisterPage,
  isAuthenticated,
  showUnsavedChangesConfirmationModal,
}) => {
  const { t } = useTranslation('candidate');
  const history = useHistory();
  const tenant = useSelector((state: RootState) => state.tenant);
  const aamBackendApi = useInjection(AamBackendApi);
  const isElementVisible = (elName: string) => {
    if ((elName === 'LANGUAGE' || elName === 'SETTINGS') && isOnPreRegisterPage) {
      return true;
    }

    return (tenant.useOneOnboarding && elements.some((e) => e.name === elName)) || !tenant.useOneOnboarding;
  };
  const logOut = async () => {
    if (showUnsavedChangesConfirmationModal && !(await showUnsavedChangesConfirmationModal())) {
      return;
    }
    await aamBackendApi.invalidateToken();
    store.dispatch(setApplicationId(null));
    store.dispatch(authAppSlice.actions.clear());

    if (localStorage.getItem('redirectionUrl')) {
      return localStorage.removeItem('redirectionUrl');
    }

    history.push(tenant.useOneOnboarding ? '/oo' : '/app');
  };

  const navigateTo = async (path: string) => {
    if (showUnsavedChangesConfirmationModal && !(await showUnsavedChangesConfirmationModal())) {
      return;
    }
    history.push(path);
  };
  return (
    <Box className="header__menu">
      {hasMultipleOnboarding && (
        <Typography variant="subtitle2" className="header__clickable" onClick={() => navigateTo('/oo/flow')} py={5}>
          <span className="material-icons">list</span>
          {t('candidate:GENERAL.GENERIC.chooseApplication')}
        </Typography>
      )}
      {isElementVisible('CONTACT_US') && (
        <Typography
          variant="subtitle2"
          className="header__clickable"
          onClick={() => navigateTo('/oo/flow/contact-us')}
          py={5}
        >
          <span className="material-icons">call</span>
          {t('candidate:GENERAL.CONTACT_US.contactUs')}
        </Typography>
      )}
      {isElementVisible('SETTINGS') && (
        <Typography variant="subtitle2" className="header__clickable" onClick={() => navigateTo('/oo/settings')} py={5}>
          <span className="material-icons">settings</span>
          {t('candidate:GENERAL.SETTINGS.settings')}
        </Typography>
      )}
      {isElementVisible('LOG_OUT') && isAuthenticated && (
        <Typography variant="subtitle2" className="header__clickable" onClick={logOut} py={5}>
          <span className="material-icons">logout</span>
          {t('candidate:GENERAL.GENERIC.logOut')}
        </Typography>
      )}
    </Box>
  );
};
