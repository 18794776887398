import React, { FC, ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { Trans } from 'react-i18next';
import { Accordion, AccordionContent, AccordionHeader } from '@adeccoux/tag-ds';
import { Box, FormControl, Link, Typography } from '@mui/material';
import { FormHelperText } from '@mui/material';

import { useT } from '../../../hooks/useT';
import { OOControlModelInterface } from '../../../interfaces';
import { FormControlLoader } from '../../FormControlLoader';

import styles from './Accordion.module.scss';

type OOAccordionFieldProps = {
  controlModel: OOControlModelInterface;
  children?: ReactNode;
  defaultShow?: boolean;
};

export const OOAccordion: FC<OOAccordionFieldProps> = ({ controlModel, children, defaultShow }) => {
  const { t, translationsLoading } = useT('entry');
  const markParameters = true;

  const formik = useFormikContext<Record<string, any>>();
  const validationKey = controlModel.name;
  const fieldMeta = formik.getFieldMeta(validationKey);
  const { error } = fieldMeta;
  const customStilesSpacesList: string = controlModel.customStyles ? controlModel.customStyles.join(' ') : '';

  if (translationsLoading) {
    return <FormControlLoader />;
  }
  const renderConfig = (cm: OOControlModelInterface) => {
    if (!cm) {
      return;
    }
    if (children) {
      return <>{children}</>;
    }
    const key = cm.description;
    let transParameters = {};
    for (const param of cm.parameters || []) {
      transParameters = {
        ...transParameters,
        [param.name]: markParameters && param.value ? `<bold>${param.value}</bold>` : param.value,
      };
    }
    const translate = t(key, transParameters);
    if (key === translate) return;

    const parsed = JSON.parse(JSON.stringify(translate));
    const isArray = Array.isArray(parsed?.body);
    const isArrayInHeader = Array.isArray(parsed?.header);

    return (
      <div>
        {parsed?.header &&
          (!isArrayInHeader ? (
            <div>
              <Typography variant="subtitle2">{parsed?.header}</Typography>
            </div>
          ) : (
            parsed.header.map((headerItem: string, i: number) => (
              <div key={i}>
                <Typography variant="subtitle2">{headerItem}</Typography>
              </div>
            ))
          ))}
        <div>
          <ul style={{ listStylePosition: 'outside' }}>
            {parsed?.body &&
              (isArray ? (
                parsed.body.map((listItem: string, index: number) => (
                  <li key={index}>
                    <Box pb={1}>
                      <Typography variant="subtitle2">
                        <Trans defaults={listItem} components={{ italic: <i />, bold: <strong /> }} />
                      </Typography>
                    </Box>
                  </li>
                ))
              ) : (
                <Typography variant="subtitle2">{parsed.body}</Typography>
              ))}
          </ul>
        </div>

        <div>{parsed?.footer && <Typography variant="subtitle2">{parsed?.footer}</Typography>}</div>
        {cm.link ? (
          <Link rel="noreferrer" target="_blank" href={cm.link}>
            <Typography variant="subtitle2" className={`accordian-link-button ${customStilesSpacesList}`}>
              {t(cm.description + '.link')}
            </Typography>
          </Link>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <FormControl>
      <Accordion key={controlModel.name} defaultShow={defaultShow} className="tag-ds">
        <AccordionHeader className={styles.accordionHeader}>{t(controlModel.label)}</AccordionHeader>
        <AccordionContent>
          <div>{renderConfig(controlModel)}</div>
        </AccordionContent>
      </Accordion>
      {error && <FormHelperText error={true}>{error}</FormHelperText>}
    </FormControl>
  );
};
