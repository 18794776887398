import React, { ChangeEvent, useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AdminButton } from '@components/admin/AdminButton/AdminButton';
import { useInjection } from '@context/inversify-context-provider';
import { setMomentLocale } from '@helpers/date-locales.helper';
import { getClientOrganizationId, getConfigurationId, getTenantDefaultLanguage } from '@helpers/tenant.helper';
import { useAllForDashboard, useGetUserProfile } from '@hooks/apiHooks';
import { useTenant } from '@hooks/useComponentMapping';
import { Box, MenuItem, Select } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { authAdminSlice } from '@store/slices/authAdmin.slice';
import { oneOnboardingSlice } from '@store/slices/oneOnboarding.slice';

import { AamBackendApi } from '../../../../libs/aamBackendApi';
import { OODigitalSignatureDialog } from '../../pages/OODigitalSignatureModal';

import styles from './AdminHeader.module.scss';

interface OOAdminHeaderProps {
  pageName?: string;
  languagePicker?: boolean;
  onBack?: () => any;
  customStyle?: { [key: string]: any };
  tooltip?: () => JSX.Element;
}

const AdminHeader: React.FC<OOAdminHeaderProps> = ({
  pageName = 'Page name is not defined',
  languagePicker,
  onBack,
  customStyle,
  tooltip,
}) => {
  const [showSignatureButton, setShowSignatureButton] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');

  const { languages } = useSelector((state: RootState) => state?.oneOnboarding);
  const [signatureModalOpened, setSignatureModalOpened] = useState(false);
  const [signatureModalData, setSignatureModalData] = useState({
    legalName: '',
    firstName: '',
    lastName: '',
    fontFamily: '',
  });

  const tenantId = useTenant();
  const dispatch = useDispatch();
  const { data: dashboardData } = useAllForDashboard(tenantId);

  const { configurations: tenantConfigurations } = dashboardData ?? {};

  const [refetchLanguages, setRefetchLanguages] = useState(languages?.length === 0);

  const { t } = useTranslation(['recruiter', 'candidate_recruiter']);
  const { userId: recruiterId, userProfile: recruiterData } = useSelector((state: RootState) => state.authAdmin);

  const aamBackendApi = useInjection(AamBackendApi);

  const { data: userProfile, isLoading } = useGetUserProfile(tenantId, recruiterId!);

  const openSignatureModal = () => {
    setSignatureModalOpened(true);
    setSignatureModalData({
      legalName: recruiterData?.legalName ?? '',
      firstName: recruiterData?.firstName ?? '',
      lastName: recruiterData?.lastName ?? '',
      fontFamily: recruiterData?.signature ? JSON.parse(recruiterData.signature)?.fontfamily ?? '' : '',
    });
  };

  const modalDataChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newSignature = { ...signatureModalData, [e.target.name]: e.target.value };
    setSignatureModalData(newSignature);
  };

  const changeLanguageHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang = e.target.value;
    setSelectedLanguage(lang);
    setMomentLocale(tenantId, lang);
    i18next.changeLanguage(lang);
  };

  const handleSaveSignature = (data: any): void => {
    const signature = {
      ...data,
      signature: JSON.stringify({ fontfamily: data.fontFamily }),
    };
    const asyncCall = async () => {
      const res = await aamBackendApi.updateUserProfile(tenantId, recruiterId, signature);
      if (res) {
        dispatch(authAdminSlice.actions.updateUserProfile(res));
        setSignatureModalOpened(false);
      }
    };
    asyncCall();
  };

  const uniqueLanguages = [...new Map(languages?.map((item) => [item['name'], item])).values()];

  const renderLanguagePicker = () => {
    if (uniqueLanguages && uniqueLanguages.length === 1) {
      return uniqueLanguages.map((x, i) => (
        <p className="tag-ds  caption" key={i}>
          {t(`${x.name}`).toUpperCase()}
        </p>
      ));
    } else {
      return (
        uniqueLanguages.length > 1 &&
        languagePicker && (
          <Select onChange={(e: any) => changeLanguageHandler(e)} value={selectedLanguage}>
            {uniqueLanguages &&
              uniqueLanguages.map((x, i) => {
                return (
                  <MenuItem value={x.code} key={i}>
                    {t(`${x.name}`).toUpperCase()}
                  </MenuItem>
                );
              })}
          </Select>
        )
      );
    }
  };

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('i18nextLng') ?? getTenantDefaultLanguage(tenantId));
  }, [tenantId]);

  useEffect((): any => {
    let isMounted = true;
    const asyncCall = async () => {
      if (refetchLanguages) {
        const res = await aamBackendApi.fetchSelectedLanguages(getClientOrganizationId()!, getConfigurationId()!);
        dispatch(oneOnboardingSlice.actions.setLanguages(res));
        setRefetchLanguages(false);
      }

      const hasRecruiterSignature = tenantConfigurations?.some((conf) => conf?.hasRecruiterSignature);
      if (hasRecruiterSignature) {
        if (!isLoading && isMounted) {
          if (userProfile) {
            dispatch(authAdminSlice.actions.updateUserProfile(userProfile));
          }

          setShowSignatureButton(true);
          if ((!userProfile?.signature || !userProfile?.legalName) && recruiterData?.legalName === '') {
            const legalName = userProfile?.legalName
              ? userProfile.legalName
              : (userProfile?.firstName || '') + (userProfile?.lastName || '');
            setSignatureModalData({
              legalName,
              firstName: userProfile?.firstName ?? '',
              lastName: userProfile?.lastName ?? '',
              fontFamily: recruiterData?.signature ? JSON.parse(recruiterData.signature)?.fontfamily ?? '' : '',
            });
            setSignatureModalOpened(true);
          }
        }
      }
    };
    asyncCall();
    return () => (isMounted = false);
    // eslint-disable-next-line
  }, [aamBackendApi, tenantId, recruiterId, tenantConfigurations, userProfile, isLoading]);

  return (
    <div className={`admin ${styles.adminHeader}`}>
      <div className="admin-language-header" style={customStyle}>
        {onBack ? (
          <Box style={{ borderTop: '24px' }} display="flex" alignItems="center">
            <span
              className="material-icons"
              onClick={() => onBack()}
              style={{ cursor: 'pointer', marginRight: '16px' }}
            >
              arrow_back
            </span>
            {tooltip && tooltip()}
            <p className="tag-ds  large">{pageName}</p>
          </Box>
        ) : (
          <p className="tag-ds  large">{pageName}</p>
        )}

        <div className="admin-language-header__select-language-box">
          {showSignatureButton && (
            <AdminButton
              onClick={openSignatureModal}
              text={t('candidate_recruiter:APPROVAL.SIGNATURE.updateSignature')}
              variant="text"
              color="primary"
              className={styles.signatureButton}
            />
          )}
          {renderLanguagePicker()}
        </div>
      </div>
      <OODigitalSignatureDialog
        open={signatureModalOpened}
        modalDataChangeHandler={modalDataChangeHandler}
        handleSaveSignature={handleSaveSignature}
        signatureModalData={signatureModalData}
        onClose={() => {
          setSignatureModalOpened(false);
        }}
      />
    </div>
  );
};

export default AdminHeader;
