// Catalog controls rely on UK config - SEQIRUS

export const catalog = {
  configurationId: '00000000-0000-4000-0000-000000000018',
  recruiterName: 'Opel',
  candidateName: 'Opel',
  maxUploadedFileSizeMB: '30',
  hasRecruiterSignature: true,
  features: [
    {
      name: 'ENTRY',
      isMandatory: true,
      sequence: 10,
      version: 1,
      steps: [
        {
          name: 'CANDIDATE_DETAILS',
          isMandatory: true,
          sequence: 30,
          version: 1,
          duration: 480,
          role: 'candidate-or-recruiter',
          label: 'ENTRY.CANDIDATE_DETAILS.label',
          description: 'ENTRY.CANDIDATE_DETAILS.description',
          recruiterLabel: 'ENTRY.CANDIDATE_DETAILS.recruiterLabel',
          elements: [
            'MENU',
            'SETTINGS',
            'CONTACT_US',
            'LOG_IN',
            'LOG_OUT',
            'LANGUAGE',
            'PRIVACY_POLICY',
            'TERMS_AND_CONDITIONS',
            'RECEIVE_INFORMATION',
            'JOB_OFFER',
            'STEPPER',
          ],
          pages: [
            {
              name: 'PERSONAL_INFO',
              category: 'standard',
              description: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.description',
              label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.label',
              sequence: 10,
              version: 1,
              controls: [
                {
                  name: 'BANK_TYPE',
                  category: 'static',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'string',
                  label: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-type.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-type.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.bankType',
                  integrationTarget: 'Candidate',
                  messageField: 'bankType',
                  controlHeaderName: 'BANK_TYPE',
                  version: 1,
                  sequence: 10,
                  component: 'ContentSwitcher',
                },
                {
                  name: 'WELCOME_IMAGE_UBER_DRIVER',
                  category: 'static',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: false,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  label: 'mockTranslations.welcome-image-uber-driver.label',
                  description: 'mockTranslations.welcome-image-uber-driver.description',
                  sourceClass: 'Onboarding',
                  sourceField: 'legalConsent.privacyNotice',
                  version: 1,
                  sequence: 20,
                  imageSrc: 'https://dev.myonboarding.adecco.com/ef14f252-f429-4853-9c9e-22a9cf96c2dc',
                  component: 'Image',
                },
                {
                  name: 'STUDENTS_LOANS_INFO_HTML',
                  category: 'static',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  component: 'Html',
                  version: 1,
                  staticText: 'ENTRY.CANDIDATE_DETAILS.NEW_STARTER_DECLARATION.students-loans-info.label_html',
                  sequence: 30,
                },
                {
                  name: 'Notification',
                  component: 'Notification',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'string',
                  label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.date-of-birth.minMaxOffsetValidationError',
                  sequence: 40,
                  version: 1,
                },
                {
                  name: 'PERIOD_DURING_WORKED_CLIENT',
                  category: 'custom',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  component: 'StaticText',
                  version: 1,
                  label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.period-during-continental-babenhausen.label',
                  staticText: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.label',
                  sequence: 50,
                },
                {
                  name: 'CONTACT_INFO_HEADER',
                  category: 'static',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  component: 'Heading',
                  version: 1,
                  staticText: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.label',
                  sequence: 60,
                },
                {
                  name: 'ACCEPTED_AND_COMPLETED_HEALTH_ASSESSMENT_FOR_NIGHT_WORKERS',
                  category: 'static',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  component: 'ContainerWithSubcontrols',
                  version: 1,
                  label: 'ENTRY.CANDIDATE_DETAILS.NIGHT_WORKER_HEALTH_ASSESSMENT.accepted-and-completed.label',
                  description:
                    'ENTRY.CANDIDATE_DETAILS.NIGHT_WORKER_HEALTH_ASSESSMENT.accepted-and-completed.description',
                  staticText:
                    'ENTRY.CANDIDATE_DETAILS.NIGHT_WORKER_HEALTH_ASSESSMENT.accepted-and-completed.staticText',
                  sequence: 70,
                  subControls: [
                    {
                      name: 'ACCEPTED_AND_COMPLETED_HEALTH_ASSESSMENT',
                      category: 'custom',
                      isMandatory: true,
                      isVisibleCandidate: true,
                      isEditableCandidate: true,
                      isVisibleRecruiter: true,
                      isEditableRecruiter: true,
                      type: 'selector',
                      label:
                        'ENTRY.CANDIDATE_DETAILS.NIGHT_WORKER_HEALTH_ASSESSMENT.accepted-and-completed-health-assessment.label',
                      description:
                        'ENTRY.CANDIDATE_DETAILS.NIGHT_WORKER_HEALTH_ASSESSMENT.accepted-and-completed-health-assessment.description',
                      sourceClass: 'Onboarding',
                      sourceField: 'customData.declinedCompletionNightWorkerHA',
                      integrationTarget: 'Onboarding',
                      messageField: 'props.gb.declinedCompletionNightWorkerHA',
                      sequence: 10,
                      version: 1,
                      component: 'RadioGroup',
                      controlHeaderName: 'YES_NO',
                      defaultValue: 'No',
                    },
                  ],
                },
                {
                  name: 'SURVEY_NPS_DISMISS',
                  category: 'static',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: false,
                  isVisibleRecruiter: false,
                  isEditableRecruiter: false,
                  type: 'string',
                  component: 'DismissButton',
                  label: '',
                  description: '',
                  sequence: 80,
                  version: 1,
                },
                {
                  name: 'SURVEY_NPS_SUBMIT',
                  category: 'static',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: false,
                  isVisibleRecruiter: false,
                  isEditableRecruiter: false,
                  type: 'string',
                  component: 'SubmitButton',
                  label: '',
                  description: '',
                  sequence: 90,
                  version: 1,
                },
                {
                  name: 'SURVEY_LINK',
                  category: 'static',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: false,
                  isVisibleRecruiter: false,
                  isEditableRecruiter: false,
                  type: 'string',
                  component: 'SubmitLink',
                  label: 'DOCUMENTS2.DOCUMENT_UPLOAD2.SURVEY.SURVEY_LINK.label',
                  description: '',
                  sequence: 100,
                  version: 1,
                  link: 'https://chat.adecco.com/909rLD',
                },
                {
                  name: 'IS_STUDENT',
                  category: 'standard',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  component: 'Radio',
                  type: 'boolean',
                  label: 'ENTRY.CANDIDATE_DETAILS.HEALTH_INFO.height.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.student.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'universityStudent',
                  integrationTarget: 'Candidate',
                  messageField: 'isStudent',
                  sequence: 110,
                  version: 1,
                },
                {
                  name: 'HEIGHT',
                  category: 'custom',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'selector',

                  component: 'RadioGroup',
                  controlHeaderName: 'YES_NO',
                  integrationTarget: 'Onboarding',
                  messageField: 'props.gb.conditionsWorkingatHeight',
                  label: 'ENTRY.CANDIDATE_DETAILS.HEALTH_INFO.height.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.HEALTH_INFO.height.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.height',
                  sequence: 120,
                  version: 1,
                },
                {
                  name: 'SALUTATION',
                  category: 'standard',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'selector',
                  label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.salutation.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.salutation.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'salutation',
                  integrationTarget: 'Candidate',
                  messageField: 'salutation',
                  controlHeaderName: 'SALUTATION',
                  sequence: 130,
                  version: 1,
                  component: 'Select',
                },
                {
                  name: 'TFRS',
                  category: 'custom',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  component: 'RadioWithHelp',
                  type: 'string',
                  label: 'mockTranslations.BRANCH_JOB_PURPOSE.label',
                  description: 'mockTranslations.BRANCH_JOB_PURPOSE.label',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.tfrsOption',
                  sequence: 140,
                  version: 1,
                  controlHeaderName: 'YES_NO',
                  integrationTarget: 'Candidate',
                  messageField: 'TypeCategorySelectionTFRId',
                },
                {
                  todo: 'should probably be dropdown with postcodes',
                  name: 'PREFECTURE_POST_CODE',
                  controlHeaderName: 'PREFECTURE_POST_CODE',
                  regexValidation: '^[0-9]{5}(?:-[0-9]{4})?$',
                  category: 'document',
                  isMandatory: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  isVisibleCandidate: false,
                  isEditableCandidate: false,
                  min: 5,
                  max: 5,
                  type: 'selector',
                  component: 'Select',
                  label: 'DOCUMENTS.DOCUMENT_UPLOAD.prefecture-post-code.label',
                  description: 'DOCUMENTS.DOCUMENT_UPLOAD.prefecture-post-code.description',
                  sequence: 150,
                  version: 1,
                  sourceClass: 'UserDocument',
                  sourceField: 'metadata.prefecturePostCode',
                  integrationTarget: 'Candidate',
                  messageField: 'documentData.TS_PERMANENT_ACC_UK_WITHDRAWAL_AGREEMENT.prefecturePostalCode',
                  minMaxValidationError: 'DOCUMENTS.DOCUMENT_UPLOAD.prefecture-post-code.minMaxValidationError',
                  regExpValidationError: 'DOCUMENTS.DOCUMENT_UPLOAD.prefecture-post-code.regExpValidationError',
                },
                {
                  name: 'STUDENT_LOAN_TYPE',
                  category: 'custom',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'selector',
                  component: 'MultiSelectWithCheckbox',
                  label: 'ENTRY.CANDIDATE_DETAILS.NEW_STARTER_DECLARATION.loan-type.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.NEW_STARTER_DECLARATION.loan-type.description',
                  sourceClass: 'Onboarding',
                  sourceField: 'customData.amazonPayrollRecordStudentPlanType2021',
                  messageField: 'props.gb.amazonPayrollRecordStudentPlanType2021',
                  integrationTarget: 'Onboarding',
                  controlHeaderName: 'LOAN_TYPE',
                  version: 1,
                  sequence: 160,
                },
                {
                  name: 'PRIVACY_POLICY',
                  category: 'standard',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'boolean',
                  label: 'ENTRY.REGISTRATION.CONSENTS.privacy-policy.label',
                  description: 'ENTRY.REGISTRATION.CONSENTS.privacy-policy.description',
                  link: 'https://www.adecco.fr/politique-de-confidentialite/',
                  sourceClass: 'Onboarding',
                  sourceField: 'legalConsent.privacyNotice',
                  integrationTarget: 'Candidate',
                  messageField: 'dataPrivacyConsent',
                  sequence: 170,
                  version: 1,
                  component: 'Consent',
                },
                {
                  name: '48_HR_WEEK_OPT_OUT',
                  category: 'standard',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'boolean',
                  component: 'Checkbox',
                  label: 'ENTRY.CANDIDATE_DETAILS.ARC_FORM.48-hr-week-opt-out.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.ARC_FORM.48-hr-week-opt-out.description',
                  sourceClass: 'Onboarding',
                  sourceField: 'customData.48hrWeekOptOut',
                  integrationTarget: 'Onboarding',
                  messageField: 'props.gb.x48hrWeekOptOutSigned',
                  sequence: 20,
                  version: 1,
                },
                {
                  name: 'RECRUITER_REQUIREMENTS_1',
                  category: 'custom',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'selector',
                  component: 'CheckboxMultiSelect',
                  label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.worked-client.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.worked-client.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.recruiterRequirements1',
                  controlHeaderName: 'YES_NO',
                  version: 1,
                  sequence: 180,
                },
                {
                  name: 'CITY_AUTOCOMPLETE',
                  regexValidation: '^[^0-9]+$',
                  category: 'standard',
                  type: 'selector',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  min: 1,
                  max: 10,
                  label: 'ENTRY.CANDIDATE_DETAILS.CONTACT_INFO.city.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.CONTACT_INFO.city.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'city',
                  controlHeaderClass: 'CitiesDE',
                  integrationTarget: 'Candidate',
                  messageField: 'city',
                  sequence: 190,
                  version: 1,
                  component: 'Autocomplete',
                },
                {
                  name: 'CITY',
                  label: 'ENTRY.CANDIDATE_DETAILS.CONTACT_INFO.city.label',
                  category: 'custom',
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  isMandatory: true,
                  type: 'string',
                  component: 'Textfield',
                  importIntegrationTarget: '',
                  importMessageField: '',
                  integrationTarget: 'Onboarding',
                  messageField: 'example',
                  sourceClass: 'Onboarding',
                  sourceField: 'example1',
                  version: 1,
                  sequence: 200,
                },
                {
                  name: 'FIRST_NAME',
                  category: 'standard',
                  component: 'WithInfoButton',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  min: 1,
                  max: 40,
                  regexValidation: "^[a-zA-ZÀ-ž '.-]+$",
                  type: 'string',
                  label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.first-name.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.CONTACT_INFO.city.label',
                  sourceClass: 'UserProfile',
                  sourceField: 'firstName',
                  integrationTarget: 'Candidate',
                  messageField: 'firstName',
                  minMaxValidationError: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.first-name.minMaxValidationError',
                  regExpValidationError: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.first-name.regExpValidationError',
                  sequence: 210,
                  version: 1,
                },
                {
                  name: 'FIRST_NAME',
                  category: 'standard',
                  component: 'WithHelpButton',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  min: 1,
                  max: 40,
                  regexValidation: "^[a-zA-ZÀ-ž '.-]+$",
                  type: 'string',
                  label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.first-name.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.CONTACT_INFO.city.label',
                  sourceClass: 'UserProfile',
                  sourceField: 'firstName',
                  integrationTarget: 'Candidate',
                  messageField: 'firstName',
                  minMaxValidationError: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.first-name.minMaxValidationError',
                  regExpValidationError: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.first-name.regExpValidationError',
                  sequence: 210,
                  version: 1,
                },
                {
                  name: 'MACHINERY_REASONS',
                  category: 'custom',
                  isMandatory: true,
                  sourceClass: 'Onboarding',
                  sourceField: 'customData.machineryReasons',
                  integrationTarget: 'Onboarding',
                  messageField: 'props.gb.medicationDetails',
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'string',
                  label: 'ENTRY.CANDIDATE_DETAILS.HEALTH_INFO.machinery-reasons.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.HEALTH_INFO.machinery-reasons.description',
                  version: 1,
                  sequence: 220,
                  component: 'MultilineTextfield',
                },
                {
                  name: 'NATIONAL_INSURANCE_NUMBER',
                  category: 'standard',
                  isMandatory: false,
                  regexValidation: '^[a-zA-Z]{2}\\d{6}[a-zA-Z]$',
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  min: 9,
                  max: 9,
                  type: 'string',
                  label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.national-insurance-number.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.national-insurance-number.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.nationalInsuranceNumber',
                  integrationTarget: 'Candidate',
                  messageField: 'nationalInsuranceNumber',
                  minMaxValidationError:
                    'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.national-insurance-number.regExpValidationError',
                  regExpValidationError:
                    'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.national-insurance-number.regExpValidationError',
                  component: 'TransformTextToUppercase',
                  sequence: 230,
                  version: 1,
                },
                {
                  name: 'HQ_JOB_OBJECTIVE',
                  category: 'static',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: false,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  component: 'Accordion',
                  version: 1,
                  label: 'mockTranslations.BRANCH_JOB_PURPOSE.label',
                  description: 'mockTranslations.BRANCH_JOB_PURPOSE.description',
                  sequence: 240,
                  parameters: [],
                  condition: [],
                },

                {
                  name: 'HANDBOOK',
                  category: 'static',
                  isMandatory: false,
                  isEditableCandidate: false,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: false,
                  isVisibleCandidate: true,
                  type: 'string',
                  component: 'AccordionWithSubcontrols',
                  version: 1,
                  label: 'mockTranslations.DECLARATIONS.handbook.label',
                  description: 'mockTranslations.DECLARATIONS.handbook.description',
                  staticText: 'mockTranslations.DECLARATIONS.handbook.staticText',
                  sequence: 260,
                  subControls: [
                    {
                      name: 'HANDBOOK_SUB1',
                      category: 'static',
                      component: 'StaticText',
                      isMandatory: false,
                      isEditableCandidate: false,
                      isVisibleRecruiter: true,
                      isEditableRecruiter: false,
                      isVisibleCandidate: true,
                      type: 'string',
                      version: 1,
                      staticText: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-info-introduction.staticText',
                      description: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-info-introduction.label',
                      sequence: 10,
                    },
                    {
                      name: 'HANDBOOK_SUB2',
                      category: 'static',
                      component: 'Html',
                      isMandatory: false,
                      isEditableCandidate: false,
                      isVisibleRecruiter: true,
                      isEditableRecruiter: false,
                      isVisibleCandidate: true,
                      type: 'string',
                      version: 1,
                      staticText: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-info-title.label_html',
                      description: '',
                      sequence: 20,
                    },
                    {
                      name: 'HANDBOOK_SUB3',
                      category: 'static',
                      component: 'StaticText',
                      isMandatory: false,
                      isEditableCandidate: false,
                      isVisibleRecruiter: true,
                      isEditableRecruiter: false,
                      isVisibleCandidate: true,
                      type: 'string',
                      version: 1,
                      staticText: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-info-introduction.staticText',
                      description: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.bank-info-introduction.label',
                      sequence: 30,
                    },
                  ],
                },
                {
                  name: 'PRIVACY_POLICY_TEXT_2',
                  category: 'standard',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'string',
                  label: 'mockTranslations.CONSENTS1.privacy-policy-text2.label',
                  description: 'mockTranslations.CONSENTS1.privacy-policy-text2.description',
                  link: 'https://google.com',
                  sourceClass: 'Onboarding',
                  sourceField: 'legalConsent.dataPrivacyConsent',
                  integrationTarget: 'Candidate',
                  messageField: 'dataPrivacyConsent',
                  sequence: 270,
                  version: 1,
                  component: 'ConsentWithoutCheckmark',
                },
                {
                  name: 'VALIDITY_START_DATE',
                  category: 'document',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'date',
                  component: 'Datepicker',
                  label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.date-of-birth.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.date-of-birth.description',
                  version: 1,
                  sequence: 280,
                  sourceClass: 'UserDocument',
                  sourceField: 'metadata.validityStartDate',
                  integrationTarget: 'Candidate',
                  maxOffset: 0,
                  messageField: 'documentData.UE_RESIDENT_CARD.validityStartDate',
                },
                {
                  name: 'HANDICAPPED',
                  category: 'custom',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'boolean',
                  label: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.provide-bank-details.label',
                  description: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.provide-bank-details.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.handicapped',
                  integrationTarget: 'Candidate',
                  messageField: 'customData.handicapped',
                  version: 1,
                  sequence: 290,
                  component: 'Switch',
                },
                {
                  name: 'LOONHEFFING',
                  category: 'custom',
                  isMandatory: true,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: true,
                  isEditableRecruiter: true,
                  type: 'boolean',
                  component: 'SwitchWithInfo',
                  label: 'mockTranslations.PAYMENT_INFO.loonheffing.label',
                  description: 'mockTranslations.PAYMENT_INFO.loonheffing.description',
                  sourceClass: 'UserProfile',
                  sourceField: 'customData.loonheffing',
                  sequence: 300,
                  version: 1,
                },
                {
                  name: 'SURVEY_RANKING',
                  category: 'standard',
                  isMandatory: false,
                  isVisibleCandidate: true,
                  isEditableCandidate: true,
                  isVisibleRecruiter: false,
                  isEditableRecruiter: false,
                  type: 'selector',
                  min: 0,
                  max: 10,
                  label: 'APPROVAL.FIRST_DAY.SURVEY_NPS.SURVEY_RANKING.label',
                  description: 'APPROVAL.FIRST_DAY.SURVEY_NPS.SURVEY_RANKING.description',
                  staticText: 'APPROVAL.FIRST_DAY.SURVEY_NPS.SURVEY_RANKING.description2',
                  sourceClass: 'NpsResponse',
                  sourceField: 'score',
                  integrationTarget: '',
                  messageField: 'npsScore',
                  sequence: 310,
                  version: 1,

                  component: 'Ranking',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  controlHeaders: [
    {
      name: 'JOB_TYPE',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.JOB_TYPE.full-time.label',
          value: 'Full time',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.JOB_TYPE.part-time.label',
          value: 'Part time',
          sequence: 10,
        },
      ],
    },
    {
      name: 'SALUTATION',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.SALUTATION.mr.label',
          value: 'Mr',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.SALUTATION.miss.label',
          value: 'Miss',
          sequence: 20,
        },
      ],
    },
    {
      name: 'LANGUAGE',
      items: [
        {
          label: 'ENTRY.REGISTRATION.LANGUAGE.english.label',
          value: 'en',
          sequence: 10,
        },
        {
          label: 'ENTRY.REGISTRATION.LANGUAGE.german.label',
          value: 'de',
          sequence: 20,
        },
      ],
    },
    {
      name: 'MONTHLY_HOURS',
      items: [
        {
          label: '30.33 h',
          value: '30.33',
          sequence: 10,
        },
        {
          label: '60.67 h',
          value: '60.67',
          sequence: 20,
        },
        {
          label: '75.83 h',
          value: '75.83',
          sequence: 30,
        },
        {
          label: '91 h',
          value: '91',
          sequence: 40,
        },
        {
          label: '106.17 h',
          value: '106.17',
          sequence: 50,
        },
        {
          label: '121.33 h',
          value: '121.33',
          sequence: 60,
        },
        {
          label: '135 h',
          value: '135',
          sequence: 70,
        },
        {
          label: '140 h',
          value: '140',
          sequence: 80,
        },
        {
          label: '151.67 h',
          value: '30.33',
          sequence: 90,
        },
        {
          label: '160 h',
          value: '160',
          sequence: 100,
        },
        {
          label: '173.33 h',
          value: '173.33',
          sequence: 110,
        },
      ],
    },
    {
      name: 'CALCULATE_BY',
      items: [
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.CALCULATE_BY.PER_MONTH.label',
          value: 'hoursPerMonth',
          sequence: 10,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.CALCULATE_BY.PER_DAY.label',
          value: 'hoursPerDay',
          sequence: 20,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.CALCULATE_BY.PER_WEEK.label',
          value: 'hoursPerWeek',
          sequence: 30,
        },
      ],
    },
    {
      name: 'NATIONAL_IDENTIFIER_TYPE',
      items: [
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.NATIONAL_IDENTIFIER_TYPE.passport.label',
          value: 'PASSPORT',
          sequence: 10,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.NATIONAL_IDENTIFIER_TYPE.dni.label',
          value: 'DNI',
          sequence: 20,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.NATIONAL_IDENTIFIER_TYPE.nie.label',
          value: 'NIE',
          sequence: 30,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.NATIONAL_IDENTIFIER_TYPE.community-resident.label',
          value: 'COMMUNITY_RESIDENT',
          sequence: 40,
        },
      ],
    },
    {
      name: 'PERMIT_TYPE',
      items: [
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type1.label',
          value: 0,
          sequence: 10,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type2.label',
          value: 1,
          sequence: 20,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type3.label',
          value: 2,
          sequence: 30,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type4.label',
          value: 3,
          sequence: 40,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type5.label',
          value: 4,
          sequence: 50,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type6.label',
          value: 5,
          sequence: 60,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type7.label',
          value: 6,
          sequence: 70,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type8.label',
          value: 7,
          sequence: 80,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type9.label',
          value: 8,
          sequence: 90,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type10.label',
          value: 9,
          sequence: 100,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type11.label',
          value: 10,
          sequence: 110,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type12.label',
          value: 11,
          sequence: 120,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type13.label',
          value: 12,
          sequence: 130,
        },
        {
          label: 'DOCUMENTS.DOCUMENT_UPLOAD.PERMIT_TYPE.permit-type14.label',
          value: 13,
          sequence: 140,
        },
      ],
    },
    {
      name: 'SHIFTS',
      items: [
        {
          label: 'Wechselschicht (Früh- Spätschicht)',
          value: 'Wechselschicht (Früh- Spätschicht)',
          sequence: 10,
        },
        {
          label: '3-Schicht (Früh-, Spät- und Nachtschicht)',
          value: '3-Schicht (Früh-, Spät- und Nachtschicht)',
          sequence: 20,
        },
        {
          label: '3-Schicht Gießerei',
          value: '3-Schicht Gießerei',
          sequence: 30,
        },
        {
          label: 'Dauerfrühschicht',
          value: 'Dauerfrühschicht',
          sequence: 40,
        },
        {
          label: 'Spätschicht',
          value: 'Spätschicht',
          sequence: 50,
        },
        {
          label: 'Nachtschicht',
          value: 'Nachtschicht',
          sequence: 60,
        },
        {
          label: 'Normalschicht',
          value: 'Normalschicht',
          sequence: 70,
        },
        {
          label: 'Teilzeit',
          value: 'Teilzeit',
          sequence: 80,
        },
        {
          label: '18-Schicht',
          value: '18-Schicht',
          sequence: 90,
        },
        {
          label: 'Dauernachtschicht',
          value: 'Dauernachtschicht',
          sequence: 100,
        },
        {
          label: 'Contischicht',
          value: 'Contischicht',
          sequence: 110,
        },
        {
          label: 'Gleitzeit',
          value: 'Gleitzeit',
          sequence: 120,
        },
        {
          label: '15-Schicht',
          value: '15-Schicht',
          sequence: 130,
        },
        {
          label: 'Tagschicht',
          value: 'Tagschicht',
          sequence: 140,
        },
      ],
    },
    {
      name: 'SHIFTS_GE',
      items: [
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift1.label',
          value: 'Wechselschicht (Früh- Spätschicht)',
          sequence: 10,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift2.label',
          value: '3-Schicht (Früh-, Spät- und Nachtschicht)',
          sequence: 20,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift3.label',
          value: '3-Schicht Gießerei',
          sequence: 30,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift4.label',
          value: 'Dauerfrühschicht',
          sequence: 35,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift5.label',
          value: 'Spätschicht',
          sequence: 40,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift6.label',
          value: 'Nachtschicht',
          sequence: 50,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift7.label',
          value: 'Normalschicht',
          sequence: 60,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift8.label',
          value: 'Teilzeit',
          sequence: 70,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift9.label',
          value: '18-Schicht',
          sequence: 80,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift10.label',
          value: 'Dauernachtschicht',
          sequence: 90,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift11.label',
          value: 'Contischicht',
          sequence: 100,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift12.label',
          value: 'Gleitzeit',
          sequence: 110,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift13.label',
          value: 'Schicht',
          sequence: 120,
        },
        {
          label: 'HIRING.HIRE.CONTRACT_INFO.SHIFT.shift14.label',
          value: '15-Tagschicht',
          sequence: 130,
        },
      ],
    },
    {
      name: 'YES_NO_UNKNOWN',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.YES_NO_UNKNOWN.YES.label',
          value: 'YES',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.YES_NO_UNKNOWN.NO.label',
          value: 'NO',
          sequence: 20,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.GENERAL_INFO.YES_NO_UNKNOWN.NO_INFO.label',
          value: 'NO_INFO',
          sequence: 30,
        },
      ],
    },
    {
      name: 'BANK_TYPE',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.LOAN_TYPE.bank.label',
          value: 'Bank',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PAYMENT_INFO.LOAN_TYPE.building-society.label',
          value: 'building-society',
          sequence: 20,
        },
      ],
    },
    {
      name: 'YES_NO',
      items: [
        {
          label: 'Yes',
          value: 'Yes',
          sequence: 10,
        },
        {
          label: 'No',
          value: 'No',
          sequence: 20,
        },
      ],
    },
    {
      name: 'TFRS_OPTIONS',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_1.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_1.option1.description',
          value: '1',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_2_1.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_2_1.option1.description',
          value: '2',
          sequence: 20,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_2_2.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_2_2.option1.description',
          value: '3',
          sequence: 30,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_2_3.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_2_3.option1.description',
          value: '4',
          sequence: 40,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_3_1.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_3_1.option1.description',
          value: '5',
          sequence: 50,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_4_1.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_4_1.option1.description',
          value: '6',
          sequence: 60,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_4_2.option1.label',
          description: 'ENTRY.CANDIDATE_DETAILS.RECRUITMENT_INFO.TFR_4_2.option1.description',
          value: '7',
          sequence: 70,
        },
      ],
    },
    {
      name: 'LOAN_TYPE',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.EMPLOYMENT_LOAN_INFO.LOAN_TYPE.plan1.label_html',
          value: 'Plan1',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.EMPLOYMENT_LOAN_INFO.LOAN_TYPE.plan2.label_html',
          value: 'Plan2',
          sequence: 20,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.EMPLOYMENT_LOAN_INFO.LOAN_TYPE.plan4.label_html',
          value: 'Plan4',
          sequence: 30,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.EMPLOYMENT_LOAN_INFO.LOAN_TYPE.postgraduate.label_html',
          value: 'PostGraduate',
          sequence: 40,
        },
      ],
    },
    {
      name: 'DEPENDENT_TYPE_CHECK',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.FAMILY_INFO.DEPENDENT_TYPE_CHECK.CHILD.label',
          value: 'Child',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.FAMILY_INFO.DEPENDENT_TYPE_CHECK.RELATIVE.label',
          value: 'Relative',
          sequence: 20,
        },
      ],
    },
    {
      name: 'DEPENDANT_DEDUCTIONS_OPTIONS',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.FAMILY_INFO.DEPENDANT_DEDUCTIONS_OPTIONS.fifty.label',
          value: '50',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.FAMILY_INFO.DEPENDANT_DEDUCTIONS_OPTIONS.hundred.label',
          value: '100',
          sequence: 20,
        },
      ],
    },
    {
      name: 'PREFECTURE_POST_CODE',
      items: [
        {
          label: '01 - Bourg-En-Bresse',
          value: '01-bourg-en-bresse',
        },
      ],
    },
    {
      name: 'RECRUITER_REQUIREMENTS_1',
      items: [
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.RECRUITER_REQUIREMENTS_1.unemployed-24.label',
          value: 'unemployed 24',
          sequence: 10,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.RECRUITER_REQUIREMENTS_1.single-adult.label',
          value: 'single adult',
          sequence: 20,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.RECRUITER_REQUIREMENTS_1.over-50.label',
          value: 'over 50',
          sequence: 30,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.RECRUITER_REQUIREMENTS_1.unpaid-12.label',
          value: 'unpaid 12',
          sequence: 40,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.RECRUITER_REQUIREMENTS_1.unemployed-6.label',
          value: 'unemployed 6',
          sequence: 50,
        },
        {
          label: 'ENTRY.CANDIDATE_DETAILS.PERSONAL_INFO.RECRUITER_REQUIREMENTS_1.without-a-higher-education.label',
          value: 'without a higher education',
          sequence: 60,
        },
      ],
    },
  ],
};
