import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { Utils } from '@helpers/utils';

import { OODocumentationModel } from '../models/DocumentationModel';
import { OODocumentModel } from '../models/DocumentModel';
import { OOStepModel } from '../models/StepModel';

export enum DocumentCommand {
  VIEW = 'VIEW',
  UPLOAD = 'UPLOAD',
}

type IDocumentContext = {
  documentationModel: OODocumentationModel | undefined;
  setDocumentationModel: React.Dispatch<SetStateAction<OODocumentationModel | undefined>>;
  document: OODocumentModel | undefined;
  setDocument: Dispatch<SetStateAction<OODocumentModel | undefined>>;
  command: DocumentCommand | undefined;
  setCommand: Dispatch<SetStateAction<DocumentCommand | undefined>>;
  step: OOStepModel | undefined;
  setStep: Dispatch<SetStateAction<OOStepModel | undefined>>;
  completeStepAvailable: boolean;
  setCompleteStepAvailable: Dispatch<SetStateAction<boolean>>;
  setInitialDocumentType: Dispatch<SetStateAction<string | null>>;
  initialDocumentType: null | string;
};

export const OODocumentContext = createContext<IDocumentContext>({
  setDocument(
    value: ((prevState: OODocumentModel | undefined) => OODocumentModel | undefined) | OODocumentModel | undefined,
  ): void {
    Utils.consoleLog('DocumentContext#setDocument');
  },
  setDocumentationModel(
    value:
      | ((prevState: OODocumentationModel | undefined) => OODocumentationModel | undefined)
      | OODocumentationModel
      | undefined,
  ): void {
    Utils.consoleLog('DocumentContext#setDocumentationModel');
  },
  setCommand(
    value: ((prevState: DocumentCommand | undefined) => DocumentCommand | undefined) | DocumentCommand | undefined,
  ): void {
    Utils.consoleLog('DocumentContext#setCommand');
  },
  setStep(): void {
    Utils.consoleLog('DocumentContext#setStep');
  },
  setCompleteStepAvailable(): void {
    Utils.consoleLog('DocumentContext#completeStepAvailable');
  },
  setInitialDocumentType(): void {
    Utils.consoleLog('DocumentContext#setTypePickerVisible');
  },
  document: undefined,
  documentationModel: undefined,
  command: undefined,
  step: undefined,
  completeStepAvailable: false,
  initialDocumentType: null,
});

export const OODocumentProvider = (props: any) => {
  const [documentationModel, setDocumentationModel] = useState<OODocumentationModel>();
  const [document, setDocument] = useState<OODocumentModel>();
  const [command, setCommand] = useState<DocumentCommand>();
  const [step, setStep] = useState<OOStepModel>();
  const [completeStepAvailable, setCompleteStepAvailable] = useState(false);
  const [initialDocumentType, setInitialDocumentType] = useState<null | string>(null);

  return (
    <OODocumentContext.Provider
      value={{
        document,
        setDocument,
        documentationModel,
        setDocumentationModel,
        command,
        setCommand,
        step,
        setStep,
        completeStepAvailable,
        setCompleteStepAvailable,
        initialDocumentType,
        setInitialDocumentType,
      }}
    >
      {props.children}
    </OODocumentContext.Provider>
  );
};
