import React, {useState} from 'react';

export interface ITreeElement {
  /**
   * Name of the Tree Element
   */
  name: string;
  /**
   * For disabling the Tree Element
   */
  disabled?: boolean;
  /**
   * click event on developers needs
   */
  onClick?: (e?: React.MouseEvent) => void;
  /**
   * Alternative className on Tree.Element
   */
  className?: string;
  /**
   * prop for displaying an icon if needed
   */
  icon?: string;
  /**
   * By default left sided arrow, can be passed an oposite side (both will show up if they have children)
   */
  arrow?: 'left' | 'right' | 'none';
  [others: string]: any;
}

const TreeViewElement: React.FC<ITreeElement> = ({
  children,
  name,
  onClick,
  disabled,
  className,
  arrow = 'left',
  icon,
  ...rest
}) => {
  const [showChildren, setShowChildren] = useState<boolean>(false);

  const handleClickElement = (e: React.MouseEvent) => {
    setShowChildren(!showChildren);
    if (typeof onClick === 'function') onClick(e);
  };

  const renderIcon = () => {
    if (icon) return icon;
    if (!icon && arrow === 'left') return 'arrow_right';
    return '';
  };

  return (
    <>
      <li
        role="button"
        data-disabled={disabled}
        className={`tree-element ${className || ''}`}
        onClick={handleClickElement}
        {...rest}
      >
        {(children || icon) && <span className="material-icons icon-order">{renderIcon()}</span>}
        {name}
        {arrow === 'right' &&
          children &&
          (showChildren ? (
            <span className="material-icons tree-element-icon-right">keyboard_arrow_up</span>
          ) : (
            <span className="material-icons tree-element-icon-right">keyboard_arrow_down</span>
          ))}
      </li>
      {children && showChildren && <ul className="tree-container">{children}</ul>}
    </>
  );
};

export default TreeViewElement;
