import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInjection } from '@context/inversify-context-provider';
import { CustomLogo } from '@features/oneOnboarding/controls/CustomLogo';
import { useFlowWrapper } from '@features/oneOnboarding/hooks/useFlowWrapper';
import { OOStepsEnum } from '@features/oneOnboarding/interfaces';
import { OOStepElementModel } from '@features/oneOnboarding/models/StepElementModel';
import { setMomentLocale } from '@helpers/date-locales.helper';
import { getTenantDefaultLanguage, getTenantLanguageList } from '@helpers/tenant.helper';
import { Box, Button, Container, Grid } from '@mui/material';
import { RootState } from '@store/rootReducer';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { Header } from '../Header/Header';

export const LanguageSelector: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const history = useHistory();
  const userEmail = history?.location?.state?.email || '';
  const [preRegisterLanguages, setPreRegisterLanguages] = useState<{ name: string; code: string }[]>([]);

  const tenant = useSelector((state: RootState) => state.tenant);
  const { userId } = useSelector((state: RootState) => state.authApp)!;
  const { logo, flow, step, languages } = useSelector((state: RootState) => state.oneOnboarding);
  const { t } = useTranslation(['candidate']);
  const aamBackendApi = useInjection(AamBackendApi);
  const flowWrapper = useFlowWrapper();

  useEffect(() => {
    setSelectedLanguage(getTenantDefaultLanguage(tenant.tenantId));
  }, [tenant.tenantId]);

  useEffect(() => {
    if (i18n?.services?.languageUtils) {
      const langFromStorage = localStorage.getItem('i18nextLng') || getTenantDefaultLanguage(tenant.tenantId);
      const lang = (getTenantLanguageList(tenant.tenantId) as string[]).includes(langFromStorage)
        ? langFromStorage
        : getTenantDefaultLanguage(tenant.tenantId);
      i18n.changeLanguage(lang);
      setSelectedLanguage(lang);
      setMomentLocale(tenant.tenantId, lang);
    }
  }, [tenant.tenantId]);

  useEffect(() => {
    const fetchLanguages = async () => {
      const clientOrganizationId = localStorage.getItem('clientOrganizationId');

      if (clientOrganizationId) {
        const langs = await aamBackendApi.fetchClientOrganizationSelectedLanguages(clientOrganizationId);
        const uniqueLangs = [...new Map(langs.map((item) => [item['name'], item])).values()];
        setPreRegisterLanguages(uniqueLangs.map((l) => ({ name: l.name, code: l.code })));
      }
    };

    fetchLanguages();
  }, [aamBackendApi]);

  const stepName: any = useMemo(() => {
    return step.length ? step : flow?.find((s: any) => s.elements.length > 0)?.name || OOStepsEnum.registration;
  }, [step, flow]);

  const elements: OOStepElementModel[] = useMemo(() => {
    if (!flowWrapper || !tenant.useOneOnboarding) {
      return [];
    }
    const currentStepKey: string = stepName;

    return flowWrapper.steps.find((step) => step.name === currentStepKey)?.elements ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepName, flowWrapper]);

  const changeLanguageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lang = e.target.value;
    setSelectedLanguage(lang);
    setMomentLocale(tenant.tenantId, lang);
    i18n.changeLanguage(lang);
  };

  const isElementVisible = (elName: string) => {
    if (userId === undefined || userId === null) {
      return false;
    } else {
      return (tenant.useOneOnboarding && elements.some((e) => e.name === elName)) || !tenant.useOneOnboarding;
    }
  };

  const onLanguageSubmit = () => {
    history.push('/oo/preregister', { email: userEmail });
  };

  const languageOptions = languages?.length > 0 ? languages : preRegisterLanguages;

  return (
    <>
      <Header
        isAuthenticated
        onBackPress={() => history.go(-1)}
        {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }}
      />
      <Container maxWidth="xl" style={{}}>
        <Grid container mt={6}>
          {i18n.exists('candidate:GENERAL.SETTINGS.settingsMessage') && (
            <Box mb={5}>
              <span className="tag-ds ">{t('candidate:GENERAL.SETTINGS.settingsMessage')}</span>
            </Box>
          )}
          <Grid item xs={4}>
            {(isElementVisible('LANGUAGE') || userId === undefined || userId === null) && (
              <Box mb={9}>
                <div className="tag-ds radio-group">
                  {languageOptions.map((x, i) => {
                    const isNotLastInList = languages.length !== i + 1;
                    return (
                      <div key={x.code + x.name}>
                        <Box my={4}>
                          <div className="radio-container">
                            <input
                              id={x.code}
                              type="radio"
                              value={x.code}
                              checked={x.code === selectedLanguage}
                              onChange={changeLanguageHandler}
                            />
                            <label htmlFor={x.code}>{x.name}</label>
                          </div>
                        </Box>
                        {isNotLastInList && <div className="separator" />}
                      </div>
                    );
                  })}
                </div>
              </Box>
            )}
          </Grid>
        </Grid>
        <Button variant="contained" color="primary" onClick={onLanguageSubmit}>
          {t('candidate:GENERAL.LANGUAGE.selectLanguageButton')}
        </Button>
      </Container>
    </>
  );
};
