import { BranchModel } from './branch.model';
import { OnboardingRequisitionModel } from './onboarding-requisition.model';
import { RequisitionModel } from './requisition.model';

export enum OnboardingStatus {
  new = 'new',
  eligible = 'eligible',
  completeProfile = 'completeProfile',
  readyForHire = 'readyForHire',
  hired = 'hired',
  matched = 'matched',
  rejected = 'rejected',
  approved = 'approved',
}
export interface Onboarding {
  tenantId: number;
  userId: string;
  clientOrganizationId: string;
  configurationId: string;
  customerId?: string;
  hcrId?: string;
  poNumber?: number;
  localAssociateId?: string;
  localBranchId?: string;
  hasPassedLegalCheck?: boolean;
  status?: OnboardingStatus;
  localDocSourceId?: string;
  localCandidateId?: string;
  localMoUrl?: string;
  amazonCreateDate?: Date;
  mostRecentSubstepChangeDateTime?: Date;
  nationalIDNumber?: string;
  nationalIDType?: string;
  rehireEligibilityStatus?: string;
  requisitionTitle?: string;
  sourceDetails?: string;
  sourceOther?: string;
  sourcePrimary?: string;
  stepSubstep?: string;
  uniqueTransactionId?: string;
  localEmployeeId?: string;
  requisitionId?: string;
  branchId?: string;
  branch?: BranchModel;
  coVendorId?: string;
  jobShiftTypeIds?: string[];
  supervisorLogin?: string;
  appointmentDate?: Date;
  localCustomerId?: string;
  availableFrom?: Date;
  availabilityTime: string[];
  dontAskUploadSteps: boolean | null;
  emailHistory: any;
  rejectedAt: Date | null;
  workingTimeTypeIds?: string[];
  medicalRestrictions?: string[];
  iban?: string;
  paidByCheck?: boolean;
  ssn?: string;
  requisitions?: RequisitionModel[];
  onboardingRequisitions?: OnboardingRequisitionModel[];
  resetAt?: string | null;
  createdAt: string;
  updatedAt: string;
  nationalIdentifierType: string;
  contractOption?: string;
  applicationId?: string;
  integrationState: any;
  customData?: {
    [key: string]: any;
  };
}
