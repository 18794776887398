import React from 'react';
import { Link, Typography } from '@mui/material';

import { useT } from '../../../hooks/useT';
import { OOControlModelInterface } from '../../../interfaces';

interface StaticTextProps {
  controlModel: OOControlModelInterface;
}

const StaticText: React.FC<StaticTextProps> = ({ controlModel }) => {
  const { t, i18n } = useT('entry', 'documents', 'hiring', 'candidate_recruiter');

  const { staticText, description, name, link } = controlModel;

  let prefix = staticText?.split('.')?.[0].toLowerCase() || '';
  let prefixDescription = description?.split('.')?.[0].toLowerCase() || '';
  const prefixAlias: any = {
    documents2: 'documents',
    documents3: 'documents',
    matching: 'hiring',
  };
  if (prefixAlias[prefix]) {
    prefix = prefixAlias[prefix];
  }
  if (prefixAlias[prefixDescription]) {
    prefixDescription = prefixAlias[prefixDescription];
  }
  const getDescription = () => {
    if (prefixDescription) {
      return i18n.exists(`${prefixDescription}:${description}`, { ns: prefixDescription })
        ? t(`${prefixDescription}:${description}`)
        : '';
    }
    return description ? t(description) : '';
  };

  return (
    <div>
      <Typography variant="subtitle2" className={`static-text p-0 control-element__${name}`}>
        {staticText && t(`${prefix}:${staticText}`)}
        {link ? (
          <Link rel="noreferrer" target="_blank" href={link}>
            {t(description)}
          </Link>
        ) : (
          getDescription() && <span style={{ fontWeight: 'normal' }}>{getDescription()}</span>
        )}
      </Typography>
    </div>
  );
};

export default StaticText;
