import React, { useReducer } from 'react';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';

import styles from './SelfServiceTooltip.module.scss';

type Position = 'left' | 'right' | 'top' | 'bottom';
interface Props {
  hoverContent: string;
  position?: Position;
}

const reducer = (
  state: { clicked: boolean; hovered: boolean },
  action: { active: boolean; actionType: 'click' | 'hover' },
) => {
  if (action.actionType === 'click') {
    return { ...state, clicked: action.active };
  }
  if (action.actionType === 'hover') {
    return { ...state, hovered: action.active };
  }
  return state;
};

const SelfServiceTooltip: React.FC<Props> = ({ hoverContent, position = 'bottom' }) => {
  const [tooltipState, dispatch] = useReducer(reducer, { clicked: false, hovered: false });

  const generateMargin = (direction: Position) => {
    switch (direction) {
      case 'top':
        return '0px 4px 4px 4px';
      case 'right':
        return '4px 0px 4px 4px';
      case 'bottom':
        return '4px 4px 0px 4px';
      case 'left':
        return '4px 4px 4px 0px';
      default:
        return '4px';
    }
  };

  return (
    <Tooltip
      open={tooltipState.clicked || tooltipState.hovered}
      onClose={() => dispatch({ active: false, actionType: 'hover' })}
      onOpen={() => dispatch({ active: true, actionType: 'hover' })}
      onClick={(e) => {
        e.preventDefault();

        dispatch({ active: true, actionType: 'click' });
      }}
      onBlur={() => dispatch({ active: false, actionType: 'click' })}
      title={hoverContent}
      arrow
      className={styles.tooltip}
      componentsProps={{
        tooltip: {
          style: {
            background: '#1C304B',
            fontSize: 12,
            padding: '8px 16px',
            fontWeight: 400,
            borderRadius: 8,
            margin: generateMargin(position),
          },
        },
        arrow: {
          style: {
            color: '#1C304B',
          },
        },
      }}
      placement={position}
    >
      <button className={clsx('button-icon', 'small', styles.base, 'tag-ds')}>
        <span className="material-icons" style={{ fontSize: 16, color: '#5C6573' }}>
          info_outline
        </span>
      </button>
    </Tooltip>
  );
};

export default SelfServiceTooltip;
