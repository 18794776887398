import React from 'react';
import { Component } from '@features/oneOnboarding/interfaces';

import DraggableComponent from '../../DraggableComponent';
import ControlListItem from '../PageControlListItem';

interface Props {
  control: any;
  setControlDetails: (control: any) => void;
  isSelected: boolean;
}

const RegularControl: React.FC<Props> = ({ control, setControlDetails, isSelected }) => {
  const enabledComponents = [
    Component.Datepicker,
    Component.Heading,
    Component.Html,
    Component.Textfield,
    Component.MultilineTextfield,
    Component.Radio,
    Component.Select,
    Component.MultipleSelect,
    Component.Checkbox,
    Component.Consent,
    Component.RadioGroup,
    Component.Switch,
    Component.Autocomplete,
    Component.StaticText,
    Component.CheckboxMultiSelect,
  ];

  const disabled = !enabledComponents.includes(control.component);
  const havingCondition = control.renderCondition?.length > 0 || control.mandatoryCondition?.length > 0;

  return (
    <DraggableComponent control={control} componentType="pageControl">
      <ControlListItem
        name={control.name}
        label={control.label}
        setControlDetails={() => {
          if (disabled) return;
          setControlDetails(control);
        }}
        staticText={control?.staticText}
        isActive={isSelected}
        text={control.text}
        isDisabled={disabled}
        isHavingCondition={havingCondition}
        component={control.component}
      />
    </DraggableComponent>
  );
};

export default RegularControl;
