import React from 'react';
import { FieldProps } from 'formik';
import SelfServiceTooltip from '@features/selfService/components/Tooltip/SelfServiceTooltip';

interface Props {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  isError: any;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  fieldProps?: FieldProps;
  disabled: boolean;
}

const MaximumCharacters: React.FC<Props> = ({ value, onChange, name, isError, onBlur, fieldProps, disabled }) => {
  return (
    <>
      <label className="caption">Maximum characters</label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
        <input
          type="number"
          value={value}
          onChange={onChange}
          name={name}
          className={isError ? 'error' : ''}
          onBlur={onBlur}
          disabled={disabled}
        />
        <SelfServiceTooltip hoverContent="This notifies candidates if their text input doesn't meet the minimum or maximum characters required." />
      </div>
      {isError && <p className="input-helper-text">{fieldProps?.meta.error}</p>}
    </>
  );
};

export default MaximumCharacters;
