/* eslint-disable simple-import-sort/imports */
import 'reflect-metadata';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './App';
import { InversifyContextProvider } from '@context/inversify-context-provider';
import { initApp } from '@helpers/app.helper';
import { container } from './inversify';
import * as serviceWorker from './serviceWorker';
import { store } from './store';
import { Loader } from '@components/shared/Loader/Loader';

import './styles/theme-shared.scss';
import './styles/theme1.scss';
import './styles/theme2.scss';
import './styles/theme3.scss';
import '@adeccoux/tag-ds/index.css';
import './styles/adeccoux-patches.css';
import './styles/tag-ds.scss';
import { renderOneTrustIfNeeded } from '@helpers/renderOneTrustIfNeeded';

const isLocalEnv = process.env.REACT_APP_ENV === 'a-env';
const isCordovaBuild = process.env.REACT_APP_CORDOVA_BUILD === 'true';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const AppWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      await initApp();
      serviceWorker.unregister();
      setIsLoading(false);
    };

    initializeApp().catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!isLoading) {
      renderOneTrustIfNeeded();
    }
  }, [isLoading]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      {isLocalEnv && <ReactQueryDevtools initialIsOpen={false} />}
      <InversifyContextProvider container={container}>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </InversifyContextProvider>
    </QueryClientProvider>
  );
};

const runApp = () => {
  const renderApp = () => {
    ReactDOM.render(<AppWrapper />, document.getElementById('root'));
  };

  if (isCordovaBuild) {
    document.addEventListener('deviceready', renderApp, false);
  } else {
    renderApp();
  }
};

runApp();
