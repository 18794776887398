import React, { useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, Formik } from 'formik';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { Header } from '@components/app/Header/Header';
import { Loader } from '@components/shared/Loader/Loader';
import { useInjection } from '@context/inversify-context-provider';
import { AuthHelper, AzureB2CRedirectType, getRegistrationStepInfo } from '@helpers/authHelper';
import {
  getClientOrganizationId,
  getSelectedConfigurationId,
  getTenantCountryCode,
  getTenantDefaultLanguage,
} from '@helpers/tenant.helper';
import useTelemetry from '@hooks/useTelemetry';
import { EventType, TargetName, TelemetryActor } from '@models/telemetry.model';
import { CANDIDATE_EMAIL_STATUS_IMPORTED, CANDIDATE_EMAIL_STATUS_NOT_FOUND } from '@models/user.model';
import { Button, TextField } from '@mui/material';
import { RootState } from '@store/rootReducer';
import { useQuery } from '@tanstack/react-query';

import { AamBackendApi } from '../../../libs/aamBackendApi';
import { Logger } from '../../../libs/logger';
import { CustomLogo } from '../controls/CustomLogo';
import { OOFormControl } from '../controls/FormControl';
import { OOControlModel } from '../models/ControlModel';
import { OOPageModel } from '../models/FormModel';
import { OOFlowWrapper } from '../wrappers/FlowWrapper';

export const OOPreRegisterPage = () => {
  const aamBackendApi = useInjection(AamBackendApi);
  const b2c = useSelector((state: RootState) => state.featureConfiguration.b2c);
  const userId = useSelector((state: RootState) => state.authApp.userId);
  const appSettings = useSelector((state: RootState) => state.featureConfiguration.appSettings);
  const authHelper = useInjection(AuthHelper);
  const logger = useInjection(Logger);
  const tokenApp = useSelector((state: RootState) => state.authApp.idToken);
  const authMethodApp = useSelector((state: RootState) => state.authApp.authMethod);
  const history = useHistory();
  const { tenantId } = useSelector((state: RootState) => state.tenant);
  const { t } = useTranslation(['entry', 'candidate', 'candidate_recruiter']);
  const userEmail = history.location.state.email || '';

  const { oneOnboarding } = useSelector((state: RootState) => state);
  const [fw, setFw] = useState<OOFlowWrapper | null>(null);
  const [addData, setAddData] = useState<any>(null);
  const [logo, setLogo] = useState<string>('');
  const [consentPage, setConsentPage] = useState<OOPageModel | null>(null);
  const { createTelemetryUnauthenticated } = useTelemetry();

  const clientOrganizationId = useMemo(() => {
    if (oneOnboarding.clientOrganizationId) {
      return oneOnboarding.clientOrganizationId;
    }
    return getClientOrganizationId() || '';
  }, [oneOnboarding.clientOrganizationId]);
  const configurationId = useMemo(() => {
    if (oneOnboarding.selectedConfigurationId) {
      return oneOnboarding.selectedConfigurationId;
    }
    return getSelectedConfigurationId() || '';
  }, [oneOnboarding.selectedConfigurationId]);

  const applicationId = useMemo(() => {
    if (oneOnboarding.applicationId) {
      return oneOnboarding.applicationId;
    }
  }, [oneOnboarding.applicationId]);

  const validationSchema = useMemo(() => {
    if (!fw) return null;

    return Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
        .test('account-test', 'account is not valid', async (value) => {
          if (!value) {
            return false;
          }

          const emailStatus = await aamBackendApi.getCandidateEmailStatus(tenantId, value.toLowerCase());
          if (emailStatus === CANDIDATE_EMAIL_STATUS_NOT_FOUND) {
            return new ValidationError('account unavailable', value, 'email');
          } else if (emailStatus === CANDIDATE_EMAIL_STATUS_IMPORTED) {
            return true;
          }
          return true;
        }),
      ...consentPage?.getValidationObject(fw, t),
    });
  }, [fw, consentPage, t, aamBackendApi, tenantId]);

  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || getTenantDefaultLanguage(tenantId);
  const b2cSignUp = async (values: any) => {
    let tokenHint = undefined;
    try {
      tokenHint = await aamBackendApi.getTokenHint(tenantId, values.email, clientOrganizationId);
      await createTelemetryUnauthenticated({
        eventType: EventType.BUTTON_CLICKED,
        targetName: TargetName.CandidateRegistrationContinue,
        applicationId: applicationId || '',
        clientOrganizationId: clientOrganizationId,
        configurationId: configurationId,
        email: userEmail,
        tenantId: tenantId,
        actor: TelemetryActor.CANDIDATE,
      });
    } catch (err) {
      const errorMessage = err.response?.data?.error?.data?.message || err.data?.message || err.message;
      if (errorMessage.toLowerCase().includes('user already exists')) {
        values.from = AzureB2CRedirectType.signIn;
        values.isEmailInputDisabled = false;
        const resetPasswordUrl = await authHelper.getAuthorizeUrlResetPassword(
          b2c.resetPassword,
          history.location,
          values.email,
          Object.keys(values).reduce((acc, key) => {
            acc[key] = values[key];
            return acc;
          }, values.idpOptions || {}),
        );
        authHelper.b2cNavigation(
          b2c.resetPassword,
          resetPasswordUrl + '&ui_locales=' + getCurrentLng() + '&country=' + getTenantCountryCode(tenantId),
          history,
          appSettings.useExternalBrowser,
        );
        return;
      }
      logger.log(
        `There was a problem retreiving token hint for user ${values.email}: ${err.data?.message || err.message}`,
      );
    }
    const signUpUrl = await authHelper.getAuthorizeUrlSignup(
      b2c.signUp,
      history.location,
      values.email,
      Object.keys(values).reduce((acc, key) => {
        acc[key] = values[key];
        return acc;
      }, values.idpOptions || {}),
    );
    const tenantCountryCode = getTenantCountryCode(tenantId);
    const currentLocale = getCurrentLng();
    let authUrl = `${signUpUrl}&ui_locales=${currentLocale}&country=${tenantCountryCode}&id_token_hint=${tokenHint}`;
    if (!tokenHint) {
      authUrl = `${signUpUrl}&ui_locales=${currentLocale}&country=${tenantCountryCode}&login_hint=${
        values.email ?? ''
      }`.replace('userjourney=signup', 'userjourney=walkin');
    }
    authHelper.b2cNavigation(b2c.signUp, authUrl, history, appSettings.useExternalBrowser);
  };

  const { isInitialLoading: flowLoading = true, data = null } = useQuery({
    queryKey: ['flowData', clientOrganizationId, configurationId],
    queryFn: () => aamBackendApi.fetchSelectedConfiguration(clientOrganizationId, configurationId),
    onError: () => {
      history.push('/oo/preregister', { email: userEmail });
    },
    enabled: !!clientOrganizationId,
  });

  useEffect(() => {
    if (data) {
      const flow = OOFlowWrapper.create(data.flow);
      const registrationStep = flow.steps.find((itm) => itm.name.toLowerCase().includes('regist'));
      const idpOptions =
        registrationStep?.elements.length &&
        registrationStep.elements.filter((element) => element.name === 'IDP_OPTIONS')[0]?.additionalData;

      if (
        registrationStep &&
        userEmail &&
        registrationStep.pages.length > 0 &&
        registrationStep.pages.every((i) => i.skip)
      ) {
        b2cSignUp({ email: userEmail, idpOptions });
      } else {
        setAddData(idpOptions);
        setLogo(data.logo || '');
        setFw(flow);
        const [regStepName, regPageName] =
          flow && flow.steps ? getRegistrationStepInfo({ ...flow, flow: flow.steps }) : [];
        const consentStep = flow && flow.steps ? flow.getStep(regStepName) : null;
        const page = consentStep?.pages?.find((itm) => itm.name === regPageName);
        if (page) {
          setConsentPage(page);
        }
      }
    } else if (data != null && !flowLoading) {
      history.push('/oo');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, flowLoading]);

  useEffect(() => {
    createTelemetryUnauthenticated({
      eventType: EventType.PAGE_LOADED,
      targetName: TargetName.CandidateRegistration,
      applicationId: applicationId,
      clientOrganizationId: clientOrganizationId,
      configurationId: configurationId,
      email: userEmail,
      tenantId: tenantId,
      actor: TelemetryActor.CANDIDATE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (flowLoading) {
    return <Loader />;
  }
  const consentPageInitialValues = fw ? consentPage?.getInitialValues(fw) : {};
  const initialValues = { email: userEmail, ...consentPageInitialValues };

  if (userId) {
    history.push('/oo/flow');
    return <></>;
  }

  const b2cSignIn = async () => {
    const signInUrl = await authHelper.getAuthorizeUrlSignIn(b2c.signIn, history.location, undefined, addData);
    const authUrl = userEmail
      ? `${signInUrl}&ui_locales=${getCurrentLng()}&login_hint=${encodeURIComponent(
          userEmail,
        )}&country=${getTenantCountryCode(tenantId)}`
      : `${signInUrl}&ui_locales=${getCurrentLng()}&country=${getTenantCountryCode(tenantId)}`;
    authHelper.b2cNavigation(b2c.signIn, authUrl, history, appSettings.useExternalBrowser);
  };

  const textErrors = (meta: any) => ({
    error: meta && meta.touched && Boolean(meta.error),
    helperText: meta && meta.touched && meta.error,
  });

  return (
    <div className="preRegister">
      <Header
        isAuthenticated={Boolean(tokenApp) && authMethodApp === 'b2c'}
        {...{ CustomLogo: logo ? <CustomLogo src={logo} /> : null }}
        isOnPreRegisterPage={true}
      />
      <div className="innerContainer">
        <h1 className="primaryHeader">{t('candidate:GENERAL.REGISTRATION.registerAndCompleteText')}</h1>

        <div className="row">
          <p>{t('candidate:GENERAL.CONTACT_US.email')}</p>
        </div>

        <div>
          <Formik
            validateOnMount={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await b2cSignUp({ ...values, idpOptions: addData });
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} style={{ display: 'grid', gap: 16 }}>
                <Field name="email">
                  {({ field, meta }: FieldProps<string, any>) => (
                    <TextField
                      {...field}
                      {...textErrors(meta)}
                      placeholder={t('candidate:GENERAL.REGISTRATION.emailFieldPlaceholder')}
                      disabled={!!userEmail}
                    />
                  )}
                </Field>
                <span className="tertiaryHeader">
                  {t('candidate:GENERAL.REGISTRATION.doYouHaveAProfile')}{' '}
                  <span onClick={b2cSignIn} className="secondaryHeader">
                    {t('candidate:GENERAL.REGISTRATION.logInHere')}
                  </span>
                </span>
                {consentPage?.controls
                  .filter((control) => control.isVisibleCandidate)
                  .map((control: OOControlModel) => (
                    <OOFormControl control={control} key={control.name} />
                  ))}
                <Button type="submit" variant="contained" color="primary" disabled={!props.isValid}>
                  {t('candidate_recruiter:GENERAL.GENERIC.submit')}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
