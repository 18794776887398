import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import { Menu, MenuItem } from '@mui/material';

import EditSignatureAnchor from './EditSignatureAnchor';
import SignatureItem from './SignatureItem';

import controlsStyle from '../Controls.module.scss';
import styles from './SignatureAnchors.module.scss';

interface ExtractAnchor {
  text: string;
  index: number;
  width: number;
  height: number;
  topOffset: number;
  leftOffset: number;
  anchorPoint: string;
  characterIndex: number;
}

export interface SignatureAnchorByText {
  role: 'candidate' | 'recruiter';
  extractAnchor: ExtractAnchor;
}

export interface SignatureAnchorByPosition {
  top: number;
  left: number;
  page: number;
  role: 'candidate' | 'recruiter';
  width: number;
  height: number;
  extract: boolean;
}

export interface SignatureAnchorByTextWithUuid extends SignatureAnchorByText {
  uuid: string;
}

export interface SignatureAnchorByPositionWithUuid extends SignatureAnchorByPosition {
  uuid: string;
}

export enum AnchorType {
  TEXT = 'TEXT',
  POSITION = 'POSITION',
}

export type SignatureAnchorWithUuid = SignatureAnchorByTextWithUuid | SignatureAnchorByPositionWithUuid;

interface Props {
  value?: SignatureAnchorByText[] | SignatureAnchorByPosition[];
  setFieldValue: any;
  disabled: boolean;
}

const getDefaultAnchorByType = (type: AnchorType) => {
  const defaultAnchor = {
    [AnchorType.TEXT]: {
      role: 'candidate',
      extractAnchor: {
        text: 'Placeholder text',
        index: 0,
        width: 200,
        height: 50,
        topOffset: -70,
        leftOffset: 0,
        anchorPoint: 'BOTTOMRIGHT',
        characterIndex: 0,
      },
    },
    [AnchorType.POSITION]: {
      top: 624,
      left: 413,
      page: 0,
      role: 'candidate',
      width: 200,
      height: 50,
      extract: false,
    },
  };

  return defaultAnchor[type];
};

const SignatureAnchors: React.FC<Props> = ({ value, setFieldValue, disabled }) => {
  const [activeEditAnchor, setActiveEditAnchor] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const getAnchorType = (anchor: SignatureAnchorWithUuid) => {
    if ('extractAnchor' in anchor) {
      return AnchorType.TEXT;
    } else {
      return AnchorType.POSITION;
    }
  };

  const addNewSignatureAnchor = (type: AnchorType) => {
    if (!value || disabled) return;

    setFieldValue('signatureAnchors', [...value, getDefaultAnchorByType(type)]);
    setAnchorEl(null);
  };

  const valuesWithKeys = useMemo(() => {
    return value?.map((x) => {
      return { ...x, uuid: uuid() };
    });
  }, [value]);

  const deleteAnchor = (uuid: string) => {
    if (!valuesWithKeys || disabled) return;

    const index = valuesWithKeys?.findIndex((item) => item?.uuid === uuid);

    if (index !== -1) {
      const withoutDelete = [...valuesWithKeys.slice(0, index), ...valuesWithKeys.slice(index + 1)];

      setFieldValue('signatureAnchors', withoutDelete);
    }
  };

  const getItemForEdit = valuesWithKeys?.find((x) => x.uuid === activeEditAnchor);

  const handleSaveEdit = (editedAnchor: SignatureAnchorWithUuid) => {
    if (!valuesWithKeys || disabled) return;

    const updatedAnchors = cloneDeep(valuesWithKeys);

    const index = updatedAnchors?.findIndex((item) => item?.uuid === editedAnchor.uuid);

    if (index !== -1) {
      updatedAnchors[index] = editedAnchor;

      setFieldValue('signatureAnchors', updatedAnchors);
      setActiveEditAnchor(null);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={clsx('tag-ds input-wrapper')}>
      <div className={styles.heading}>
        <label className="subtitle">Signature anchors</label>
        <div
          className={clsx(styles.heading__icon, disabled ? controlsStyle['control--disabled'] : '')}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => handleClick(e as any)}
        >
          <span className="material-icons">add</span>
        </div>
        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => addNewSignatureAnchor(AnchorType.TEXT)}>Anchor by text</MenuItem>
          <MenuItem onClick={() => addNewSignatureAnchor(AnchorType.POSITION)}>Anchor by position</MenuItem>
        </Menu>
      </div>
      {value && value?.length > 0 && (
        <div style={{ marginTop: 16 }}>
          {valuesWithKeys?.map((x, i) => {
            return (
              <SignatureItem
                key={x.uuid}
                anchor={x}
                indexValue={i}
                handleDelete={() => deleteAnchor(x.uuid)}
                setEditAnchor={() => setActiveEditAnchor(x.uuid)}
                anchorType={getAnchorType(x)}
                disabled={disabled}
              />
            );
          })}
        </div>
      )}
      {getItemForEdit && !disabled && (
        <EditSignatureAnchor
          anchor={getItemForEdit}
          isActive={Boolean(getItemForEdit)}
          closeModal={() => setActiveEditAnchor(null)}
          handleSave={handleSaveEdit}
          anchorType={getAnchorType(getItemForEdit)}
        />
      )}
    </div>
  );
};

export default SignatureAnchors;
