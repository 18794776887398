import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from '@mui/material';

import BottomBox from '../../../components/shared/BottomBox/BottomBox';
import { CandidatePageLayout } from '../components/CandidatePageLayout/CandidatePageLayout';
import StepsOverview from '../controls/StepsOverview';

export const OOUploadDocumentIntro: React.FC<{ goNext: () => void }> = ({ goNext }) => {
  const { t } = useTranslation(['documents']);

  return (
    <CandidatePageLayout hideOverview={true}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        minHeight="80vh"
        sx={{ margin: '16px auto 0 auto' }}
      >
        <Grid container spacing={3}>
          <Grid item>
            <StepsOverview />
          </Grid>
        </Grid>
        <BottomBox py={2}>
          <Button id="dashboard-next" variant="contained" color="primary" onClick={() => goNext()}>
            {t('candidate:GENERAL.PAGES.nextPage')}
          </Button>
        </BottomBox>
      </Box>
    </CandidatePageLayout>
  );
};
